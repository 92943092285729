import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import { CarPaymentMethodsConfigurationApi } from '@/api/car-engine/payment-methods-configuration.api';
import { CreatePaymentMethod, PaymentMethodResults, ConfigurationResult } from '@/api/car-engine/payment-methods-configuration.model';
import PaymentMethodsStore from './payment-methods.store';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'paymentMethodsCar'
})
class PaymentMethodsCarStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: PaymentMethodResults  = new PaymentMethodResults();
  configuration: PaymentMethodResults  = new PaymentMethodResults();
  configurationName: string = '';
  configurationId: string = '';
  selectedTab: string = 'Flight';
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';
  isCopy: boolean = false;
  errMessages: string[] = [];
  paymentErrMessages: string[] = [];
  showError: boolean = false;
  selectedPayment: ConfigurationResult | null = null;
  paymentList: any[] = [];
  isFromManage: boolean = false;
  customError: boolean = false;

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get Loading() {
    return this.loading;
  }

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  get canShowCustomError() {
    return this.customError;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.configuration = value;
  }

  @Mutation
  setIsFromManage(value) {
    this.isFromManage = value;
  }

  @Mutation
  setCurrentConfiguration(value) {
    this.currentConfiguration = new PaymentMethodResults(value);
  }

  @Mutation
  setPaymentMethodConfigurations(value) {
    this.currentConfiguration.paymentMethodConfigurations = value;
  }

  @Mutation
  clearCurrentConfiguration() {
    this.currentConfiguration = new PaymentMethodResults();
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setSelectedTab(value) {
    this.selectedTab = value;
  }

  @Mutation
  setPaymentList(value) {
    this.paymentList = value;
  }
  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setPaymentErrMessages(value) {
    this.paymentErrMessages = value;
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setSelectedPayment(value) {
    this.selectedPayment = value;
  }

  @Mutation
  addSelectedPayment(value) {
    this.paymentList.push({
      ...value
    });
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }


  @Action
  editSelectedPayment(value) {
    let oldValIndex = this.paymentList.findIndex(code => {
      return _.isEqual(code, this.selectedPayment);
    });
    if (-1 < oldValIndex) {
      this.paymentList.splice(oldValIndex, 1, value);
    }
  }

  @Action
  removeSelectedPayment(value) {
    let oldValIndex = this.paymentList.findIndex(code => {
      return _.isEqual(code, value);
    });
    if (-1 < oldValIndex) {
      this.paymentList.splice(oldValIndex, 1);
    }
  }

  @Action
  mapPaymentMethodConfigurations(configuration: any[]) {
    let payment: any = [];
    let errors: any = [];
    configuration.forEach(cfg => {
      payment.push({
        supplier: cfg.supplier,
        ...cfg,
        id: _.uniqueId()
      });
      if (cfg.error && !cfg.succeeded) {
        errors.push({
          supplier: cfg.supplier,
          error: $handleErrors(cfg.error)
        });
      }
    });
    this.setPaymentErrMessages(errors);
    this.setPaymentList(payment);
  }

  @Action
  async createPaymentMethodsConfiguration(request: CreatePaymentMethod) {
    try {
      this.setLoading(true);
      const response = await CarPaymentMethodsConfigurationApi.create(request);
      if (response && response.data) {
        this.setConfigurationId(response.data.configurationId);
      }
    } catch (error) {
      throw(error);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updatePaymentMethodsConfiguration(data) {
    try {
      PaymentMethodsStore.setLoading(true);
      let result = await CarPaymentMethodsConfigurationApi.update(data.configurationId, data.params);
      if (result && result.data) {
        this.getPaymentMethodsConfiguration(data.configurationId);
      }
    } catch (error) {
      throw(error);
    } finally {
      PaymentMethodsStore.setLoading(false);
    }
  }

  @Action
  async getPaymentMethodsConfiguration(configurationId: string) {
    try {
      this.setLoading(true);
      this.setCustomError(false);
      const result = await CarPaymentMethodsConfigurationApi.getById(configurationId);
      if (result && result.data) {
        this.setCurrentConfigurationName(result.data.name);
        this.setConfigurationId(configurationId);
        this.setConfiguration(result.data);
        if (result.data.paymentMethodConfigurations) {
          this.mapPaymentMethodConfigurations(result.data.paymentMethodConfigurations);
          this.setPaymentMethodConfigurations(result.data.paymentMethodConfigurations);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(PaymentMethodsCarStore);