import CurrencyExchangeConfiguration from '@/modules/settings/currency-exchange/CurrencyExchangeConfiguration.vue';

export default {
  path: ':configurationId?/currency-exchange-configuration',
  name: 'currency-exchange-configuration',
  component:  CurrencyExchangeConfiguration,
  displayName: 'Currency Exchange Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditCurrencyConfiguration',
    parentName: 'currency-exchange-configurations',
    depthCategory: 'currency-exchange-configurations',
    depth: 2,
  }
};