import DesignatedApprovers from '@/modules/profile/designated-approvers/DesignatedApprovers.vue';

export default {
  path: 'designated-approvers',
  name: 'designated-approvers',
  component: DesignatedApprovers,
  displayName: 'designated.designated-approvers-in-aw',
  meta: {
    permission: 'CanViewDesignatedApproversInProfile',
    icon: 'group',
  }
};