import PaymentCards from '@/modules/profile/payment-cards/PaymentCards.vue';
import { translate } from '@/i18n';

export default {
  path: 'payment',
  name: 'payment',
  component: PaymentCards,
  displayName: 'profile-payment.payment-cards',
  meta: {
    permission: 'ReadProfilePaymentCards',
    icon: 'payment',
  }
};