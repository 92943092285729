
































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { TripApi } from '@/api/trip/trip.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import BasketAirOffer from '@/modules/basket/BasketAirOffer.vue';
import NotificationSabreHistoryCompare from './NotificationSabreHistoryCompare.vue';
import { AirBookingsApi } from '@/api/air-engine/air-bookings.api';

@Component({
  components: {
    BasketAirOffer,
    NotificationSabreHistoryCompare,
  },
})
export default class NotificationPopup extends Vue {
  @Prop() itemId!: string;
  @Prop() title!: string;
  @Prop({ default: false }) popupVisible!: boolean;
  @Prop() notification!: any;

  inProgress: boolean = false;
  messageLoaded: boolean = false;
  popupErrors: any[] = [];
  messageData: any = null;
  item: any = null;
  popupTitle: string = '';
  imagesConst = '/assets/img/loader/1.gif';
  isErrorAfterConfirmSchedule: boolean = false;
  isTripItemLoading: boolean = false;
  tripItemData: any = null;
  tripItemLoadingErrors: any[] = [];
  sabreHistoryLoaded: boolean = false;
  sabreHistoryCompareErrors: any[] = [];
  sabreHistoryCompareData: any = null;



  get notificationReferences() {
    if (!this.messageData) {
      return [];
    }

    return this.messageData.providerReference;
  }

  get isAirFranceKlm() {
    if (!this.messageData) {
      return false;
    }

    return 'AirFranceKlm' === this.messageData.source;
  }

  get isAirFranceKlmFlightCancelled() {
    if (!this.isAirFranceKlm) {
      return false;
    }
    return this.title === 'Flight cancelled';
  }

  get isAirFranceKlmFlightRebooked() {
    if (!this.isAirFranceKlm) {
      return false;
    }
    return this.title === 'Flight rebooked';
  }

  get isAirFranceKlmFlightCancelledAndRebooked() {
    if (!this.isAirFranceKlm) {
      return false;
    }
    return this.title === 'Flight cancelled/Flight rebooked';
  }

  get isAirFranceKlmFlightCancelledOrRebooked() {
    return this.isAirFranceKlmFlightCancelled ||
      this.isAirFranceKlmFlightRebooked ||
      this.isAirFranceKlmFlightCancelledAndRebooked;
  }

  get isItConfirmation() {
    if (!this.messageData) {
      return false;
    }

    if (!this.messageData.scheduleWasAccepted && this.messageData.isScheduleChange) {
      return true;
    }

    return false;
  }

  get isItScheduleAccepted() {
    if (!this.messageData) {
      return false;
    }

    if (this.messageData.scheduleWasAccepted && this.messageData.isScheduleChange) {
      return true;
    }

    return false;
  }

  get shouldShowMarkAsRead() {
    if (!this.messageData) {
      return false;
    }

    if (!this.messageData.scheduleWasAccepted && this.messageData.isScheduleChange) {
      return false;
    }

    return !this.messageData.hasBeenRead;
  }

  get shouldShowMarkAsUnread() {
    if (!this.messageData) {
      return false;
    }
    return this.messageData.hasBeenRead;
  }

  get shouldShowSabreHistoryCompare() {
    return this.notification && this.notification.provider === 'Sabre';
  }



  travellerUrl(trav) {
    return sanitizeUrl('/profile/' + trav.id);
  }

  async loadTripItem() {
    this.isTripItemLoading = true;
    this.tripItemLoadingErrors = [];
    try {
      const response = await OffersApi.getOffer(this.messageData.tripItem.providerReferenceId);

      this.tripItemData = {
        ...response.data,
        pricePlusMarkup: this.item.price.total,
        travelPolicy: this.item.travelPolicy,
      };
    } catch (error) {
      this.tripItemLoadingErrors = this.$handleErrors(error);
    } finally {
      this.isTripItemLoading = false;
    }
  }

  async showMessage() {
    this.messageLoaded = false;
    this.messageData = null;
    this.popupErrors = [];
    this.inProgress = true;

    try {
      const result = await TripApi.getNotificationMessage(this.itemId);
      if (result && result.data) {
        this.messageData = result.data;
        if (this.messageData && this.messageData.message) {
          this.messageData.message = result.data.message.split('|');
        }

        if (this.messageData.tripItem && this.messageData.tripItem.providerReferenceId) {
          this.item = this.messageData.tripItem;
          this.loadTripItem();
        }
      }
      this.messageLoaded = true;
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
    } finally {
      this.inProgress = false;
    }
  }

  async confirmScheduleChange() {
    this.inProgress = true;
    this.popupErrors = [];
    try {
      const params = {
        'markAsRead': true,
        'acceptScheduleChange': true,
      };

      const initResponse = await TripApi.notificationMark(this.messageData.id, params);

      if (initResponse && initResponse.status === 204) {
        this.$emit('updateCurrentRow', true);
      }

      this.hidePopup();
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
      this.isErrorAfterConfirmSchedule = true;
    } finally {
      this.inProgress = false;
    }
  }

  async confirmScheduleChangeAfterErorrs() {
    this.inProgress = true;
    this.popupErrors = [];
    try {
      const params = {
        'markAsRead': true,
        'acceptScheduleChange': false,
      };

      const initResponse = await TripApi.notificationMark(this.messageData.id, params);

      if (initResponse && initResponse.status === 204) {
        this.isErrorAfterConfirmSchedule = false;
        this.$emit('updateCurrentRow', true);
      }

      this.hidePopup();
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
      this.isErrorAfterConfirmSchedule = true;
    } finally {
      this.inProgress = false;
    }
  }

  async confirmAndMarkAsUnread() {
    this.inProgress = true;
    this.popupErrors = [];
    try {
      const params = {
        'markAsRead': false,
        'acceptScheduleChange': false,
      };

      const initResponse = await TripApi.notificationMark(this.messageData.id, params);

      if (initResponse && initResponse.status === 204) {
        this.$emit('updateCurrentRow', false);
      }

      this.hidePopup();
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
    } finally {
      this.inProgress = false;
    }
  }

  async confirmAndMarkAsRead() {
    this.inProgress = true;
    this.popupErrors = [];
    try {
      const params = {
        'markAsRead': true,
        'acceptScheduleChange': false,
      };

      const initResponse = await TripApi.notificationMark(this.messageData.id, params);

      if (initResponse && initResponse.status === 204) {
        this.$emit('updateCurrentRow', true);
      }

      this.hidePopup();
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
    } finally {
      this.inProgress = false;
    }
  }

  async loadSabreHistoryCompare() {
    try {
      const response = await AirBookingsApi.getSabreHistoryCompare(
        this.notification.providerReferenceId,
        this.notification.id,
      );

      this.sabreHistoryCompareData = response.data;
    } catch (error) {
      this.sabreHistoryCompareErrors = this.$handleErrors(error);
    } finally {
      this.sabreHistoryLoaded = true;
    }
  }

  hidePopup() {
    this.$emit('close');
  }

  created() {
    this.showMessage();
    if (this.shouldShowSabreHistoryCompare) {
      this.loadSabreHistoryCompare();
    }
  }
}

