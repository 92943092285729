






































import settings from '@/settings';
import { Vue, Component } from 'vue-property-decorator';

import TripsPendingApprovalWidgetItem from './TripsPendingApprovalWidgetItem.vue';
import accountStore from '@/store/account.store';
import { TripApi } from '@/api/trip/trip.api';
import {
  SearchApprovals,
 } from '@/api/trip/trip.model';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({
  components: {
    TripsPendingApprovalWidgetItem,
  },
})
export default class TripsPendingApprovalWidget extends Vue {
  serverErrors: any[] = [];
  trips: SearchApprovals[] = [];
  isMoreTrips: boolean = false;

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get limitedTrips() {
    return this.trips;
  }

  get tripsCount() {
    if ( this.isMoreTrips ) {
      return this.trips.length + '+';
    }
    else {
      return this.trips.length;
    }
  }

  get redirectUrl() {
    return sanitizeUrl('/approvals?basketStatuses=PendingApproval&approvers=' + this.currentProfileId);
  }

  async loadPendingApprovalTrips() {
    try {
      const result = await TripApi.pendingApproval(this.currentProfileId, settings.widgets.tripsPendingApproval.maxItemsCount);
      this.trips = result.data.results;
      this.isMoreTrips = result.data.page.moreResultsAvailable;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error);
    } finally {

    }
  }

  created() {
    this.loadPendingApprovalTrips();
  }
}

