import ReductionReasonPerDiemRule from '@/modules/settings/expense-configuration/ReductionReasonPerDiemRule.vue';

export default {
  path: ':configurationId?/reduction-reason-rules/:itemId?/:perDiemRateId?/:isCopy?',
  name: 'reduction-reason-rule',
  component: ReductionReasonPerDiemRule,
  displayName: 'Reduction reason rule',
  meta: {
    breadCrumb: 'Reduction reason rule',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};