import { BaseTableParams } from '@/core/base-table-params';
import {  } from '@/api/expense/expense.model';

export class ExpenseTableParams extends BaseTableParams {
    reporterId: string = '';
    policyId: string = '';
    missionId: string = '';
    companyId: string = '';
    businessUnitId: string = '';

  public constructor(params: any) {
    super(params);
  }
}
