import { store } from '@/store';
import layoutStore from '@/modules/layout/layout.store';
import { SlotState } from '@/modules/layout/layout.model';

export default {
  install(Vue, options) {
    Vue.mixin({
      created() {
        if (!this.$animatedInHookEnabled) {
          return;
        }
        let parentElement = this;
        let viewName;
        while (parentElement.$parent && !viewName) {
          parentElement = parentElement.$parent;
          if (parentElement.transitionViewName) {
            viewName = parentElement.transitionViewName;
          }
        }
        if (!layoutStore.slots[viewName]) {
          return;
        }
        const unwatch = store.watch(
          () => layoutStore.slots[viewName].state,
          (value, old) => {
            if (value === SlotState.SHOWN) {
              this[this.$animatedInHookEnabled]();
              this.$animatedInHookDestroy();
              this.$animatedInHookDestroy = null;
            }
          }
        );

        this.$animatedInHookDestroy = unwatch;
      },
      destroyed() {
        if (this.$animatedInHookEnabled && this.$animatedInHookDestroy) {
          this.$animatedInHookDestroy();
        }
      },
    });
  }
};
