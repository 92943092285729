import PaymentGatewayConfigurations from '@/modules/settings/payment-gateway/PaymentGatewayConfigurations.vue';

export default {
  path: '',
  name: 'payment-gateways-configurations',
  component: PaymentGatewayConfigurations,
  displayName: 'Payment gateway Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPaymentGateways',
    depthCategory: 'payment-gateway-configurations',
    parentName: 'payment-gateway-configurations',
    depth: 1,
  }
};