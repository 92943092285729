























































import { Vue, Component, Watch } from 'vue-property-decorator';
import OfflineRequestsPopup from '@/modules/offline/OfflineRequestsPopup.vue';
import { router } from '@/router';
import AccountStore from '@/store/account.store';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import BasketItineraryEmailPopup from './BasketItineraryEmailPopup.vue';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';
import LayoutStore from '@/modules/layout/layout.store';
import BasketHeader from './BasketHeader.vue';

@Component({
  components: {
    OfflineRequestsPopup,
    SearchTripTravellers,
    BasketHeader,
    BasketItineraryEmailPopup,
  },
})
export default class BasketIntro extends Vue {
  showOfflineRequestPopup: boolean = false;
  showItineraryEmailPopup: boolean = false;

  get basket() {
    return BasketStore.basket;
  }

  get initialising() {
    return BasketStore.initialising;
  }

  get loading() {
    return BasketStore.loading;
  }

  get isInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get processing() {
    return BasketStore.processing;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get status() {
    return BasketStore.status ? BasketStore.status.status : null;
  }

  get basketExpired() {
    return router.currentRoute.name === 'basket-expired';
  }

  get canSendTripItineraryEmail() {
    return AccountStore.HasPermission('CanSendTripItineraryEmail');
  }

  get canShowItineraryShareIcon() {
    return this.status !== 'Cancelled' && !this.basketExpired && this.canSendTripItineraryEmail && !this.loading && !this.isInProgress;
  }

  showItineraryEmail() {
    this.showItineraryEmailPopup = true;
  }

  closeItineraryEmailPopup() {
    this.showItineraryEmailPopup = false;
  }

  showTravellers() {
    router.push({
      name: 'basketTravellers',
      params: { id: this.$route.params.id }
    });
  }

  
  @Watch('processing')
  onProcessingChange(value) {
    if (!value) {
      return;
    }
    this.$nextTick(() => {
      LayoutStore.scrollManager
        .scrollTo(this.$refs.bookingProgress as Element, false);
    });
  }
}
