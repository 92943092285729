






























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import accountStore from '@/store/account.store';
import BasketStore from '@/modules/basket/basket.store';
import { ProfileApi } from '@/api/profile/profile.api';
import { Permission } from '@/const/permission.enum';
import { userFullName } from '@/core/user-full-name';
import AirSearchStore from '@/modules/search/air/air-search.store';
import TrainSearchStore from '../search/train/train-search.store';

@Component({})
export default class BasketApproval extends Vue {
  @Prop() basketItemsMapped!: any;
  @Prop() displayValidationMessages!: boolean;

  levelAddons: {
    isLoading: boolean;
    phrase: string;
    options: any[];
  }[] = [
    {
      isLoading: false,
      phrase: '',
      options: [],
    },
    {
      isLoading: false,
      phrase: '',
      options: [],
    },
    {
      isLoading: false,
      phrase: '',
      options: [],
    },
    {
      isLoading: false,
      phrase: '',
      options: [],
    },
  ];
  searchActionBySourceType = [
    {
      type: 'AllApproversFromRootCompany',
      action: async (levelAddon) => {
        const query = levelAddon.phrase;
        const mainTraveller = this.basketTravellers
          .filter(traveller => traveller.isMainTraveller);
        const companyId = mainTraveller[0].companyId;

        levelAddon.isLoading = true;

        try {
          const response = await ProfileApi.searchApprovers(companyId, query, Permission.ReadTrip);
          if (response && response.data) {
            levelAddon.options = response.data.map(item => {
              return {
                ...item,
                id: item.profileId || item.id,
              };
            });
          }
        } catch (error) {
          levelAddon.options = [];
        } finally {
          levelAddon.isLoading = false;
        }
      },
    },
    {
      type: 'ApproversFromList',
      action: (levelAddon, level) => {
        const query = levelAddon.phrase;
        const filteredArray = level.allowedApprovers.filter(list => {
          return this.getNormalizedString(list.firstName).indexOf(this.getNormalizedString(query)) >= 0 ||
            this.getNormalizedString(list.middleName || '').indexOf(this.getNormalizedString(query)) >= 0 ||
            this.getNormalizedString(list.lastName).indexOf(this.getNormalizedString(query)) >= 0;
        });

        levelAddon.options = filteredArray.map(item => {
          return {
            ...item,
            id: item.profileId || item.id,
          };
        });
        levelAddon.isLoading = false;
      },
    },
    {
      type: 'TravellerDesignatedApprover',
      action: (levelAddon, level) => {
        const query = levelAddon.phrase;
        const filteredArray = level.allowedApprovers.filter(list => {
          return this.getNormalizedString(list.firstName).indexOf(this.getNormalizedString(query)) >= 0 ||
            this.getNormalizedString(list.middleName || '').indexOf(this.getNormalizedString(query)) >= 0 ||
            this.getNormalizedString(list.lastName).indexOf(this.getNormalizedString(query)) >= 0;
        });

        levelAddon.options = filteredArray.map(item => {
          return {
            ...item,
            id: item.profileId || item.id,
          };
        });
        levelAddon.isLoading = false;
      },
    },
  ];
  searchDefaultAction = {
    action: (levelAddon) => {
      levelAddon.options = [];
      levelAddon.isLoading = false;
    },
  };

  get approvalForBasket() {
    return BasketStore.approvalWorkflowForBasket;
  }

  get user() {
    return accountStore.current;
  }

  get basketTravellers() {
    if (-1 < [
      'airModification',
    ].indexOf(this.$route.name || '')) {
      return AirSearchStore.exchangeTravellers;
    }
    if (
      -1 < [
        'trainModification',
      ].indexOf(this.$route.name || '') &&
      TrainSearchStore.exchangeRequest &&
      TrainSearchStore.exchangeRequest.travellers
    ) {
      return TrainSearchStore.exchangeRequest.travellers;
    }
    return BasketStore.basketTravellers;
  }

  get basketContainsDrafts() {
    const items = this.basketItemsMapped;

    if (items) {
      return !items.every(item => item.status !== 'Draft');
    }
    return false;
  }

  get isPostTicketingApproval() {
    return BasketStore.postTicketingExtrasBooking || -1 < [
      'airModification',
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get hasMissingApprover() {
    if (!this.approvalForBasket) {
      return false;
    }

    return !!this.approvalForBasket.workflowLevels.find(level => level.approverSelectionType === 'BookerSelectsApprover' && !level.currentApprover);
  }

  get approverValidationError() {
    return this.displayValidationMessages && this.hasMissingApprover;
  }

  get workflowLevels() {
    if (!this.approvalForBasket) {
      return [];
    }

    return this.approvalForBasket.workflowLevels
      .filter(
        level => -1 < [
          'Required',
          'AutoApprove',
        ].indexOf(level.approvalWorkflowResult)
      );
  }
  
  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get isSelectApproverStep() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'SELECT_APPROVER') {
      return true;
    }

    return false;
  }



  @Watch('approverValidationError', {deep: true})
  onErrorShow(val) {
    if (val) {
      this.scrollToError();
    }
  }

  getApproverForLevel(index) {
    if (!this.approvalForBasket) {
      return null;
    }

    const level = this.workflowLevels[index];
    if (!level.currentApprover) {
      return null;
    }

    if (level.currentApprover.approverId) {
      return {
        ...level.currentApprover,
        profileId: level.currentApprover.id,
        firstName: level.currentApprover.approverFirstName,
        middleName: level.currentApprover.approverMiddleName,
        lastName: level.currentApprover.approverLastName,
        level: level.currentApprover.level,
      };
    }

    return {
      ...level.currentApprover,
      profileId: level.currentApprover.id,
    };
  }

  setApproverForLevel(value, index) {
    if (!this.approvalForBasket) {
      return;
    }

    const level = this.workflowLevels[index];
    if (!value) {
      level.currentApprover = null;
      return;
    }
    level.currentApprover = value;
    BasketStore.setSelectedApprover({
      level: level.approvalLevel,
      approver: value,
    });
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.selectApprover) {
        return;
      }
      (this.$refs.selectApprover as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  userFullName(user) {
    return userFullName(user);
  }

  findApproverNow(query: string, levelIndex: number) {
    this.levelAddons[levelIndex].phrase = query;
    this.levelAddons[levelIndex].isLoading = true;
    this.findApprover();
  }

  getAnyAllowedApproverLevelMessage(approverSourceType: string) {
    switch (approverSourceType) {
      case 'AllApproversFromRootCompany': return this.$t('basket.any-allowed-approvers-level-from-root-company');
      case 'ApproversFromList': return this.$t('basket.any-allowed-approvers-level-selected-in-configuration');
      case 'TravellerDesignatedApprover': return this.$t('settings-approval.approval-level-request-to-all');
      default: return '';
    }
  }

  getNormalizedString(str: string) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\u0142/g, 'l');
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  async findApprover() {
    this.levelAddons
      .forEach((levelAddon, levelIndex) => {
        if (!levelAddon.isLoading) {
          return;
        }
        if (!this.approvalForBasket) {
          levelAddon.isLoading = false;
          return;
        }

        const level = this.workflowLevels[levelIndex];
        const actionObj = this.searchActionBySourceType
          .find(action => action.type === level.approverSourceType) ||
          this.searchDefaultAction;

        actionObj.action(levelAddon, level);
      });
  }
}
