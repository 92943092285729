import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import settings from '@/settings';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { Theme } from '@/api/profile/themes.model';
import { ThemesApi } from '@/api/profile/themes.api';

const BASE64_STRING_PREFIX = 'data:image/' + settings.companyLogo.returnFormat + ';base64,';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'theme'
})
class ThemeStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  themeConfiguration: Theme = new Theme();
  themeConfigurations: Theme[] = [];
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  companyLogo: string = '';
  customError: boolean = false;

  get ThemeConfiguration() {
    return this.themeConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get canShowCustomError() {
    return this.customError;
  }


  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setThemeConfiguration(payload) {
    this.themeConfiguration = payload;
  }

  @Mutation
  setThemeConfigurations(payload) {
    this.themeConfigurations = payload;
  }

  @Mutation
  setName(value) {
    this.themeConfiguration.themeName = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'theme-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCompanyLogo(payload) {
    this.companyLogo = payload.length ? BASE64_STRING_PREFIX + payload[0].image : '';
  }

  @Mutation
  setCompanyLogoFromPopup(payload) {
    this.companyLogo = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  
  @Action
  clearData() {
    this.setThemeConfiguration(new Theme());
    this.setCurrentConfigurationName('');
  }

  @Action
  async getCompanyLogo(data) {
    try {
      this.setLoading(true);
      this.setShowError(false);
      if (data.companyId) {
        const result = await ThemesApi.getCompanyLogo(data.companyId, data.configurationId);
        if (result && result.data) {
          this.setCompanyLogo(result.data);
        }
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getThemeConfiguration(configurationId) {
    try {
      this.setLoading(true);
      this.setShowError(false);
      this.setCustomError(false);
      if (configurationId) {
        const result = await ThemesApi.getCompanyTheme(configurationId);
        if (result && result.data) {
          this.setThemeConfiguration(result.data);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addThemeConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result = await ThemesApi.createCompanyTheme(request);
      if (result && result.data) {
        this.setConfigurationId(result.data.id);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-tmc-environment.config-saved')
        });

        await this.getThemeConfiguration(result.data.id);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateThemeConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await ThemesApi.updateCompanyTheme(data.configurationId, data.params);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-tmc-environment.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(ThemeStore);
