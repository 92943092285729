import ExpenseCategories from '@/modules/settings/expense-configuration/ExpenseCategories.vue';

export default {
  path: ':configurationId?/expense-categories/:itemId?/:isCopy?',
  name: 'expense-categories',
  component: ExpenseCategories,
  displayName: 'Expense categories',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadExpenseCategories',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};