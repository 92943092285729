import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import axios, { CancelTokenSource } from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { store } from '@/store';
import { router } from '@/router';
import {
  ToTwoDigitStringValue,
  FromTwoDigitStringValue,
} from '@/core/utils';
import SearchStore from '@/modules/search/search.store';
import { CarApi } from '@/api/car-engine/car-search.api';
import filtersList from '@/const/filter.const';
import layoutStore from '@/modules/layout/layout.store';
import { serviceClassEnum } from '@/api/home/home.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import {
  InitCarSearchMessage,
  CarSearchLocationType,
  SortOptions,
} from '@/api/car-engine/car-search.model';
import { DistanceUnit } from '@/api/dictionary/dictionary.model';
import filtersConst from '@/const/filter.const';
import { SearchState } from '@/api/air-engine/air-search.model';
import { CarSearchStateParams } from './car-search.params';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import AccountStore from '@/store/account.store';
import BasketStore from '@/modules/basket/basket.store';
import { isCancelError } from '@/core/utils';
import EventBus from '@/services/event-handler';

const currency = {
  code: 'EUR',
  symbol: '€',
};

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'carSearch'
})
class CarSearchStore extends VuexModule {
  filters: any[] = [];
  convertedFilters: any[] = [];
  offers: any[] = [];
  offersVisible: number = 10;
  totalOffers: number = 0;
  sortOptions: SortOptions = {
    PRICE: 'Ascending',
  };
  loading: boolean = false;
  basketId: string = '';
  stateId: string = '';
  searchId: string = '';
  searchIdChanged: boolean = false;
  searchLoaded: boolean = false;
  searchInProgress: boolean = false;
  searchCompleted: boolean = false;
  searchTimeout: number = -1;
  showError: boolean = false;
  serverErrors: any[] = [];
  scrollTimeout: number = 0;
  selectedOfferId: string = '';
  selectingOffer: boolean = false;
  recommendationsCarCount: number = 0;
  agencyMarkup: number = 0;
  cancelToken: CancelTokenSource | null = null;
  filtersChanging: boolean = false;
  filtersRequest: any = null;
  filtersError: boolean = false;
  filtersRequestId: number = 0;
  searchFreezed: boolean = false;
  currency: any = null;

  get isSelectedOffer() {
    return '' !== this.selectedOfferId;
  }

  get fromLocationType() {
    return (SearchStore.getCarDefaultState.pickupLocation && SearchStore.getCarDefaultState.pickupLocation.type) ? SearchStore.getCarDefaultState.pickupLocation.type : '';
  }

  get toLocationType() {
    return (SearchStore.getCarDefaultState.returnLocation && SearchStore.getCarDefaultState.returnLocation.type) ? SearchStore.getCarDefaultState.returnLocation.type : '';
  }

  get searchLocationType() {
    if (SearchStore.getCarDefaultState.differentReturnLocation) {
      if (this.fromLocationType === 'Airport' && this.toLocationType === 'Airport') {
        return CarSearchLocationType.IataLocation;
      } else {
        return CarSearchLocationType.GeoPoint;
      }
    } else {
      if (this.fromLocationType === 'Airport') {
        return CarSearchLocationType.IataLocation;
      } else {
        return CarSearchLocationType.GeoPoint;
      }
    }
  }



  @Mutation
  clearSelectedOffer() {
    this.selectedOfferId = '';
  }

  @Mutation
  setSelectedOffer(offerId) {
    this.selectedOfferId = offerId;
  }

  @Mutation
  setSelectingOffer(val) {
    this.selectingOffer = val;
  }

  @Mutation
  setSearchInProgress(state: boolean) {
    this.searchInProgress = state;
  }

  @Mutation
  setSearchCompleted(state: boolean) {
    this.searchCompleted = state;
  }

  @Mutation
  setTotalOffers(value) {
    this.totalOffers = value;
  }

  @Mutation
  setSearchTimeout(timeout) {
    this.searchTimeout = setTimeout(timeout, 1000);
  }

  @Mutation
  clearSearchTimeout() {
    clearTimeout(this.searchTimeout);
  }

  @Mutation
  setupSearchLoaded(searchId: string) {
    if (this.searchId !== searchId) {
      this.searchIdChanged = true;
      this.searchId = searchId;
      this.searchLoaded = false;
    } else {
      this.searchIdChanged = false;
    }
  }

  @Mutation
  finishLoading() {
    if (this.searchIdChanged) {
      this.searchLoaded = true;
      this.searchIdChanged = false;
    }
  }

  @Mutation
  loader(state: boolean) {
    this.loading = state;
  }

  @Mutation
  updateOffers(data) {
    this.offers = data;
  }

  @Mutation
  setOffersVisible(data) {
    this.offersVisible = data;
  }

  @Mutation
  setSearchStateId(value: string) {
    this.stateId = value;
  }

  @Mutation
  setBasketId(data: string) {
    this.basketId = data;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  updateErrors(data, addMessagetoError?) {
    this.serverErrors = $handleErrors(data, true, undefined, addMessagetoError || false);
  }

  @Mutation
  setTimeoutInterval(state: number) {
    this.scrollTimeout = state;
  }

  @Mutation
  setNewSort({ sorterCode, isAscending = true }) {
    this.sortOptions = {
      [sorterCode]: isAscending ? 'Ascending' : 'Descending',
    };
  }

  @Mutation
  updateConvertedFilters(data) {
    this.convertedFilters = data;
  }

  @Mutation
  addConvertedFilter(data) {
    this.convertedFilters.push(data);
  }

  @Mutation
  updateFilters(data) {
    this.filters = data;
  }

  @Mutation
  setRecommendationsCarCount(data) {
    this.recommendationsCarCount = data;
  }

  @Mutation
  setNewAgencyMarkup(value) {
    this.agencyMarkup = value;
  }

  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  createCancelTokenIfNeeded() {
    if (this.cancelToken !== null) {
      return;
    }
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    this.cancelToken = cancelTokenSource;
  }

  @Mutation
  setFiltersError(value) {
    this.filtersError = value;
  }

  @Mutation
  setSearchFreezed(value: boolean) {
    this.searchFreezed = value;
  }

  @Mutation
  updateFiltersRequestId() {
    this.filtersRequestId++;
  }

  @Mutation
  setFiltersChanging(value) {
    this.filtersChanging = value;
  }

  @Mutation
  setFiltersRequest(value) {
    this.filtersRequest = value;
  }

  @Mutation
  setCurrency(value) {
    this.currency = value;
  }

  @Action
  buildSortQuery() {
    const data: string[] = [];
    for (let key in this.sortOptions) {
      if (this.sortOptions.hasOwnProperty(key)) {
        let urlParam = `Code=${key}`;
        data.push(urlParam);
        urlParam = `Direction=${this.sortOptions[key]}`;
        data.push(urlParam);
      }
    }
    return data.join('&');
  }

  @Action
  convertFiltersStats(stats) {
    this.updateConvertedFilters([]);
    filtersList.car.filters.rangeFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertRangeFilterStats(filterStats);
    });

    filtersList.car.filters.categoriesFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertCategoriesFilterStats(filterStats);
    });

    this.updateFilters(this.convertedFilters);
  }

  @Action({ rawError: true })
  convertRangeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        }
      };
      this.addConvertedFilter(converted);
    }
  }

  @Action({ rawError: true })
  convertCategoriesFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: stats.items.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
          };
        }),
      };
      this.addConvertedFilter(converted);
    }
  }

  @Action
  async initCarSearch(basketId?: string) {
    this.loader(true);
    this.updateOffers([]);
    this.setTotalOffers(0);
    this.setRecommendationsCarCount(0);
    this.setSearchCompleted(false);
    this.setSearchInProgress(true);
    this.setShowError(false);
    this.setNewSort({ sorterCode: 'PRICE', isAscending: true });
    const params = SearchStore.getCarDefaultState;
    const travellersList = SearchStore.getTravellersState;
    const hasVirtualTravellers = travellersList.travellers
      .some(traveller => !!traveller.isVirtual);
    const searchCompanyId = hasVirtualTravellers ? SearchStore.skipTravellersCompany.id : null;
    const request: InitCarSearchMessage = {
      travellers: travellersList.travellers,
      pickupDate: {
        date: typeof(params.pickupDate) === 'string' ? params.pickupDate : moment(params.pickupDate).format('YYYY-MM-DD'),
        time:  ToTwoDigitStringValue(params.departureTime as number / 60) + ':' + ToTwoDigitStringValue(params.departureTime as number % 60),
      },
      returnDate: {
        date: typeof(params.returnDate) === 'string' ? params.returnDate : moment(params.returnDate).format('YYYY-MM-DD'),
        time: ToTwoDigitStringValue(params.returnTime as number / 60) + ':' + ToTwoDigitStringValue(params.returnTime as number % 60),
      },
      pickupLocation: params.pickupLocation!,
      returnLocation: params.differentReturnLocation ? params.returnLocation! : params.pickupLocation!,
      searchCompanyId,
      extras: params.extras,
    };
    if (this.searchLocationType === CarSearchLocationType.GeoPoint) {
      request.radius = {
        amount: params.searchRadius || 1,
        unit: DistanceUnit.Kilometers
      };
    }
    setTimeout(() => {
      SearchStore.select(serviceClassEnum.Car);
    });

    router.push({
      name: 'car',
      params: { searchId: '-' }
    });

    let initResponse;

    try {
      const missionId = SearchStore.missionId;
      if (basketId && !missionId) {
        initResponse = await CarApi.addSegment(request, basketId);
        await BasketStore.getBasketMetadata(basketId);
      } else if (missionId && !basketId) {
        initResponse = await CarApi.initCarSearchForMission(request, missionId);
      } else if (missionId && basketId) {
        initResponse = await CarApi.addSegmentForMission(request, basketId, missionId);
        await BasketStore.getBasketMetadata(basketId);
      } else {
        initResponse = await CarApi.initCarSearch(request);
      }
      if (initResponse && initResponse.data && this.searchInProgress) {
        this.setSearchInProgress(false);
        clearInterval(this.scrollTimeout);
        this.setTimeoutInterval(setInterval(() => {
          if (!layoutStore.slots.intro.transitioning) {
            clearInterval(this.scrollTimeout);
            router.replace({
              name: 'car',
              params: { searchId: initResponse.data.searchId }
            });
          }
        }, 30));
      }
    } catch (error) {
      this.loader(false);
      this.setSearchInProgress(false);
      this.updateErrors(error);
      this.setShowError(true);
    }
  }

  @Action
  async getCarSearchSession(searchId) {
    try {
      const response = await CarApi.getCarSearchSession({ searchId });

      if (this.searchId && this.searchId !== searchId) {
        return;
      }

      const payload = response.data.searchParameters;
      const meta = response.data.metadata;
      let params = {
        ...payload,
        pickupDate: payload.pickupDate ? moment(payload.pickupDate.date).toDate() : null,
        returnDate: payload.returnDate ? moment(payload.returnDate.date).toDate() : null,
        departureTime: payload.pickupDate ? FromTwoDigitStringValue(payload.pickupDate.time) : null,
        returnTime: payload.returnDate ? FromTwoDigitStringValue(payload.returnDate.time) : null,
      };
      if (payload.radius && payload.radius.amount) {
        params.searchRadius = payload.radius.amount;
      } else {
        params.searchRadius = 3;
      }
      params.differentReturnLocation = !_.isEqual(payload.pickupLocation, payload.returnLocation);

      if (!BasketStore.basketMetadata! || BasketStore.basketMetadata!.basketId !== meta.basketId) {
        await BasketStore.getBasketMetadata(meta.basketId);
      }

      SearchStore.updateCarDefaultState(params);
      SearchStore.updateTravellersDefaultState(response.data.searchParameters);
      SearchStore.updateEditedTravellers(response.data.searchParameters);
      SearchStore.updateSkipTravellers(response.data.searchParameters.travellers.some(trav => {
        return trav.isVirtual ? trav.isVirtual : false;
      }));

      let isVirtualTraveller = response.data.searchParameters.travellers.find(trav => trav.isVirtual === true);

      if (isVirtualTraveller) {
        let companyModel =  {
          name: isVirtualTraveller.businessUnitName,
          code: isVirtualTraveller.companyCode,
          id: isVirtualTraveller.businessUnitId,
          isDisabled: false,
          parentId: null,
          rootId: isVirtualTraveller.companyId,
          isSelected: false,
        };                             

        SearchStore.setSkipTravellersCompany(companyModel);
        EventBus.$emit('update-skip-travellers-company');
      }

      SearchStore.selectNavigated(serviceClassEnum.Car);
      SearchStore.select(serviceClassEnum.Car);
      SearchStore.setMissionId(meta.missionId);
      SearchStore.selectBasket(meta.basketId);
      if (meta.missionId !== null) {
        await SearchStore.getMissionDetails();
      }
    } catch (error) {
      this.loader(false);
      this.updateErrors(error);
      this.setShowError(true);
    }
  }

  @Action
  getOffers(searchId) {
    this.setupSearchLoaded(searchId);
    this.loader(true);
    this.setSearchStateId('');
    this.stepLoading(searchId);
  }

  @Action
  stopSearch() {
    clearInterval(this.scrollTimeout);
    this.setupSearchLoaded('');
    this.clearSearchTimeout();
    this.loader(false);
    this.setSearchInProgress(false);
    this.finishLoading();
  }

  @Action
  async stepLoading(searchId) {
    if (this.searchId !== searchId) {
      return;
    }

    this.createCancelTokenIfNeeded();
    try {
      const response = await CarApi.getCarSearchState(
        searchId,
        this.stateId,
        this.cancelToken || undefined
      );

      if (response && response.data) {
        this.setNewAgencyMarkup(response.data.agencyMarkup.amount);
        this.setSearchStateId(response.data.stateId);
      }

      let searchCompleted = response.data.searchState === SearchState.Completed;

      if (response.data.newResultsAvailable) {
        await this.loadOffers(searchId);

        if (this.searchId !== searchId) {
          return;
        }
      }

      this.setRecommendationsCarCount(response.data.recommendationsTotalCount);

      if (searchCompleted) {
        this.setSearchCompleted(true);
        this.finishLoading();
        this.loader(false);
        return;
      }

      if (this.searchId !== searchId) {
        return;
      }

      this.clearSearchTimeout();
      this.setSearchTimeout( () => {
        this.stepLoading(searchId);
      });
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.updateErrors(error);
        this.setShowError(true);
      }
    }
  }

  @Action
  async loadOffers(searchId) {
    if (this.searchId !== searchId) {
      return;
    }
    const sortQuery = await this.buildSortQuery();
    let response;

    this.createCancelTokenIfNeeded();

    try {
      response = await CarApi.getCarOffers({
        searchId, sortQuery
      }, this.cancelToken || undefined);
      if (this.searchId !== searchId) {
        return;
      }

      if (response.data.recommendations) {
        this.setTotalOffers(response.data.filteredResultsCount);
        this.updateOffers(response.data.recommendations);
        this.setSearchFreezed(false);
      }
  
      if (response.data.statistics && !this.filtersChanging) {
        this.convertFiltersStats(response.data.statistics);
      }

      if (response.data.currency) {
        this.setCurrency(response.data.currency);
      }
    } catch (error) {
      if (!isCancelError(error)) {
        this.updateErrors(error);
        this.setShowError(true);
      }
    }
  }

  @Action
  async updateFilterOffers(data) {
    this.updateFiltersRequestId();
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.loader(true);
    this.clearSearchTimeout();
    this.setSearchFreezed(true);
    this.createCancelTokenIfNeeded();

    this.setFiltersError(false);
    try {
      const response = await CarApi.updateFilterOffers({ searchId: data.searchId }, this.filtersRequest.filtersData, this.cancelToken || undefined);
      if (response.data.statistics) {
        this.convertFiltersStats(response.data.statistics);
      }
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.updateErrors(error);
        this.setShowError(true);
      }
    }
  }

  @Action
  async selectOffer({ offer }) {
    this.setSelectingOffer(true);
    this.setSelectedOffer(offer.offerId);
    try {
      const result = await BasketItemApi.addItemAndCreateTrip({
        type: 'Car',
        searchId: router.currentRoute.params.searchId,
        selectionId: offer.offerId,
        bookerId: AccountStore.CurrentUser!.profile.id,
      });

      router.push({
        name: 'basket',
        params: {
          id: result.data.tripId,
        },
      });
    } catch (error) {
      this.setShowError(true);

      if (error && error.response && error.response.status === 404) {
        this.updateErrors(error);
        router.push({
          name: 'carDetails',
          params: router.currentRoute.params,
        });
      } else {
        this.updateErrors(error);
      }

    } finally {
      this.setSelectingOffer(false);
      this.clearSelectedOffer();
    }
  }

  @Action
  async updateAgencyMarkup(searchId) {
    CarApi.setAgencyMarkupFilters(searchId, {
      agencyMarkup: {
        amount: this.agencyMarkup,
        currency: {
            code: 'EUR'
          }
        }
    });
  }
}
export default getModule(CarSearchStore);