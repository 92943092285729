import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { TrainProviderConfigurations } from './train-provider-configuration.model';

class TrainProviderConfigurationSetApiClass {
  public create(params: TrainProviderConfigurations, permission: string): Promise<AxiosResponse<TrainProviderConfigurations>> {
    return http.execute({
      path: settings.apiRailEngine + '/configurations',
      permission,
      method: RequestMethod.POST,
    }, {}, { ...params, permission });
  }

  public getById(configurationId: string, permission: string): Promise<AxiosResponse<TrainProviderConfigurations>> {
    return http.execute({
      path: settings.apiRailEngine + '/configurations/{configurationId}',
      permission,
      method: RequestMethod.GET
    }, {
        configurationId
    }, { permission });
  }

  public getEmpty(permission: string): Promise<AxiosResponse<TrainProviderConfigurations>> {
    return http.execute({
      path: settings.apiRailEngine + '/configurations/empty',
      permission,
      method: RequestMethod.GET
    }, {}, {});
  }

  public update(configurationId: string, params: TrainProviderConfigurations, permission: string): Promise<AxiosResponse<TrainProviderConfigurations>> {
    return http.execute({
      path: settings.apiRailEngine + '/configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, {configurationId}, { ...params, permission });
  }
}

export const TrainProviderConfigurationSetApi: TrainProviderConfigurationSetApiClass = new TrainProviderConfigurationSetApiClass();