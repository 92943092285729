

















































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AccountStore from '@/store/account.store';
import AirCompareLocalStore from './air-compare.local-store';
import { FlightDetails, ProposalDetails } from '@/api/air-engine/air-search.model';
import AirFareConditions from './AirFareConditions.vue';
import AirExchangeOfferDetails from './AirExchangeOfferDetails.vue';
import AirResultsFeeEditedOffer from './AirResultsFeeEditedOffer.vue';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import moment from 'moment';
import settings from '@/settings';
import SearchConst from '@/const/search.const';
import formatPrice from '@/filters/format-price.filter';
import { SupplierName } from '@/services/supplier-name.service';
import { AirApi } from '@/api/air-engine/air-search.api';
import AirPriceDetails from './AirPriceDetails.vue';

@Component({
  components: {
    AirFareConditions,
    AirExchangeOfferDetails,
    AirResultsFeeEditedOffer,
    AirPriceDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('Do MMMM YYYY hh:mm');
    },
  }
})
export default class FlightProposal extends Vue {
  @Prop() provider!: string;
  @Prop() proposal!: ProposalDetails;
  @Prop() offer!: FlightDetails;
  @Prop() showingInPopup!: boolean;

  showFullConditionsPopup: boolean = false;
  disabledInfo: string = translate('search-air.max-selected');
  prepareOfferCheck: boolean = false;
  currentTemplateOffers: any[] = [];
  selectedConditions: number = 0;
  selectedFlightConditions: any = null;
  showTemplateModal: boolean = false;
  showExchangeDetailsPopup: boolean = false;
  transitionName: string = 'fade-flight';
  editFeeLoading: boolean = false;
  editFeeErrors: any[] = [];
  showEditFeePopup: boolean = false;
  agencyFeeValue: number = this.proposal && this.proposal.agencyFee ? this.proposal.agencyFee.amount : 0;
  initialAgencyFeeValue: number = this.agencyFeeValue;
  editAgencyMarkupLoading: boolean = false;
  editAgencyMarkupErrors: any[] = [];
  showEditAgencyMarkupPopup: boolean = false;
  agencyMarkupValue: number = this.proposal && this.proposal.agencyMarkup ? this.proposal.agencyMarkup.amount : 0;
  initialAgencyMarkupValue: number = this.agencyMarkupValue;
  exchangeSelectedProposal: any = null;
  basePriceForFee: number = this.proposal.price.amount - this.agencyFeeAmount;
  basePriceForMarkup: number = this.proposal.price.amount - this.markupAmount;
  ndcImagePath: string = '/assets/img/ndc.png';
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  showPriceDetailsPopup: boolean = false;

  get pricePlusMarkup() {
    return this.proposal.price.amount + this.proposal.agencyMarkup.amount;
  }

  get templateOffers(): any[] {
    return AirSearchStore.templateOffers;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get currentTravellers() {
    return AirSearchStore.getExchangeTravellers;
  }

  get isThisOfferSelected() {
    return this.proposal.id === AirSearchStore.currentProposalId;
  }

  get searchId() {
    return this.$route.params.searchId;
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get canAddOfferToTrip() {
    if ('airModification' === this.$route.name) {
      return this.canExchangeFlightBooking;
    }
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get modifyMode() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get totalFareTax() {
    if (!this.modifyMode) {
      return 0;
    }
    if ((this.proposal as any).taxDifference.amount < 0) {
      return (this.proposal as any).fareDifference.amount;
    }
    return Math.round(
      100 * ((this.proposal as any).fareDifference.amount +
      (this.proposal as any).taxDifference.amount)
    ) / 100;
  }

  get classes() {
    return {
      'disabled-active': AirSearchStore.currentOfferId === this.offer.id
    };
  }

  get hasExchangeSearchErrors() {
    return !!AirSearchStore.exchangeErrors.length && this.modifyMode;
  }

  get isDisabled() {
    return this.hasExchangeSearchErrors || this.loadingProposals;
  }

  get canExchangeFlightBooking() {
    return AccountStore.HasPermission('ExchangeFlightBooking');
  }

  get canExchangeFlightSearch() {
    return AccountStore.HasPermission('ExchangeFlightSearch');
  }

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get loadingProposals() {
    return this.offer.loadingProposals;
  }

  get totalPriceWithCustomFee() {
    return {
      amount: this.proposal.price.amount - this.initialAgencyFeeValue + this.agencyFeeValue,
      currency: this.proposal.price.currency
    };
  }

  get totalPriceWithCustomAgencyMarkup() {
    return {
      amount: this.proposal.price.amount - this.initialAgencyMarkupValue + this.agencyMarkupValue,
      currency: this.proposal.price.currency
    };
  }

  get feeCurrency() {
    if (this.proposal && this.proposal.agencyFee && this.proposal.agencyFee.currency) {
      return this.proposal.agencyFee.currency.symbol || this.proposal.agencyFee.currency.code;
    } else {
      return '\u20AC';
    }
  }

  get agencyMarkupCurrency() {
    if (this.proposal && this.proposal.agencyMarkup && this.proposal.agencyMarkup.currency) {
      return this.proposal.agencyMarkup.currency.symbol || this.proposal.agencyMarkup.currency.code;
    } else {
      return '\u20AC';
    }
  }

  get isLCC() {
    return this.proposal.isLowCost;
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasEditAgencyMarkupPermission() {
    return this.$hasAccess('CanEditAgencyMarkup');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get templateOffersCount() {
    if (this.localStore && this.localStore.length) {
      return this.localStore;
    } else {
      return AirSearchStore.templateOffers;
    }
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => expire.recommendationId === this.proposal.id);
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get hasCanSeePriceDetails() {
    return this.$hasAccess('CanSeePriceDetails');
  }

  get showPriceDetailsLink() {
    return this.hasCanSeePriceDetails && !this.modifyMode && this.proposal && this.proposal.passengerPrices;
  }

  get isSabreSupplier() {
    return this.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.proposal.supplier === 'Amadeus';
  }

  get agencyFeeAmount() {
    return this.proposal && this.proposal.agencyFee && this.proposal.agencyFee.amount ? this.proposal.agencyFee.amount : 0;
  }

  get markupAmount() {
    return this.proposal && this.proposal.agencyMarkup && this.proposal.agencyMarkup.amount ? this.proposal.agencyMarkup.amount : 0;
  }



  isNdc(supplier) {
    return -1 < SearchConst.ndcProviders.indexOf(supplier);
  }

  fareLabelInfo(name) {
    if (!name) {
      return '';
    }

    return translate('search-air.fare-basis-label') + '<br>' + name;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  showPriceDetails() {
    this.showPriceDetailsPopup = true;
  }

  showExchangeDetails() {
    this.exchangeSelectedProposal = this.proposal;
    this.showExchangeDetailsPopup = true;
  }

  showUpdateFeePopup() {
    this.editFeeErrors = [];
    this.showEditFeePopup = true;
  }

  closeUpdateFeePopup() {
    this.agencyFeeValue = this.proposal && this.proposal.agencyFee ? this.proposal.agencyFee.amount : 0;
    this.showEditFeePopup = false;
  }

  async agencyFeeChanged(value) {
    this.agencyFeeValue = value;
  }

  showUpdateAgencyMarkupPopup() {
    this.editAgencyMarkupErrors = [];
    this.showEditAgencyMarkupPopup = true;
  }

  closeUpdateAgencyMarkupPopup() {
    this.agencyMarkupValue = this.proposal && this.proposal.agencyMarkup ? this.proposal.agencyMarkup.amount : 0;
    this.showEditAgencyMarkupPopup = false;
  }

  async agencyMarkupChanged(value) {
    this.agencyMarkupValue = value;
  }

  formatPrice(price) {
    if (!price) {
      return '0';
    }
    return price.currency.symbol + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  tooltip(proposal) {
    let tooltip = '';

    if (proposal.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${this.formatPrice(proposal.rawProviderPrice)}</span>`;
    }
    if (proposal.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${this.formatPrice(proposal.systemMarkup)}</span>`;
    }

    if ((this.hasAdvancedOfferInfo || this.hasEditFeePermission) && proposal.providerPrice) {
      tooltip += `<span>${translate('basket.incl')} ${this.formatPrice(proposal.providerPrice)} ${translate('search-air.provider-price')}`;

      if (proposal.taxPrice !== undefined && proposal.taxPrice != null) {
        tooltip += ` (${this.formatPrice(proposal.taxPrice)} ${translate('search-air.taxes')})`;
      }

      tooltip += `</span>`;
    }

    if (proposal.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${this.formatPrice(proposal.agencyMarkup)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && proposal.agencyFee !== undefined && proposal.agencyFee !== null) {
      tooltip += `<span>${translate('basket.incl')} ${this.formatPrice(proposal.agencyFee)} ${translate('search-air.fee')}</span>`;
    }

    return tooltip;
  }

  getPriceChangeText() {
    if (this.proposal) {
      return (this.proposal.grandTotalDifference !== undefined && this.proposal.grandTotalDifference.amount < 0) ? translate('search-air.residual') + ':' : translate('search-air.change-cost');
    } else {
      return '';
    }
  }

  selectProposal() {
    this.exchangeSelectedProposal = this.proposal;
    if (this.$route.meta.mobile) {
      EventBus.$emit('mobile-selected-offer-proposal', {offer: this.offer, proposal: this.proposal});
    } else {
      EventBus.$emit('selected-offer-proposal', {offer: this.offer, proposal: this.proposal});
    }
  }

  selectCondition(index) {
    if (this.selectedConditions < index) {
      this.transitionName = 'slide-next';
    } else {
      this.transitionName = 'slide-prev';
    }

    this.selectedConditions = index;
    this.selectedFlightConditions = this.proposal.flightsConditions[index];

    const elements = (this.$refs.flightsCondition as HTMLElement[]);
    if (!elements || !elements.length) {
      return;
    }
    const element = elements[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  filterBySsid(templates) {
    return templates.filter(template => {
      return template.searchId === this.$route.params.searchId;
    });
  }

  removeElem(arr, offerId, proposalId) {
    const index = arr.findIndex(element => {
      return element.offerId === offerId && element.proposalId === proposalId;
    });

    if (-1 === index) {
      return arr;
    }

    const array = [...arr];
    array.splice(index, 1);

    return array;
  }

  uniqueProposalInArray(offer, proposal) {
    let unique = true;
    this.templateOffers.forEach((template: any) => {
      if (template.offerId === offer.id && template.proposalId === proposal.id) {
        unique = false;
      }
    });

    return unique;
  }

  checkPrepareoffer(offer, proposal: any) {
    let obj: any = {
      searchId: this.$route.params.searchId,
      offerId: offer.id,
      proposalId: proposal.id,
      offer,
      proposal,
    };
    if (proposal.prepareOfferCheck) {
      if (this.uniqueProposalInArray(offer, proposal)) {
        this.templateOffers.push(obj);
      } else {
        return;
      }

      let idList = this.travellersSearchList.travellers.map(trav => {
        return {
          companyId: trav.companyId,
          travId: trav.id,
        };
      });

      let data = {
        timeStamp: new Date(),
        isNewFlight: true,
        offerId: offer.id,
        proposalId: proposal.id,
        searchId: this.$route.params.searchId,
        searchMode: this.airSearchParameters.searchMode,
        travellers: idList,
      };
      AirCompareLocalStore.setLocalTemplateOffersStore(data);

      let templates = this.filterBySsid(this.templateOffers);
      AirSearchStore.setTemplateOffers(templates);
    } else {
      AirCompareLocalStore.removeLocalStoreBrowser({offer, proposal: this.proposal});

      let templates = this.filterBySsid(this.templateOffers);
      this.currentTemplateOffers = this.removeElem(templates, this.offer.id, this.proposal.id);
      AirSearchStore.setTemplateOffers(this.currentTemplateOffers);
    }
  }

  prepareOffers(offer, proposal) {
    if (!proposal.prepareOfferCheck) {
      proposal.prepareOfferCheck = true;
      (this.$refs.prepareOffer as HTMLInputElement).checked = true;
    }
    this.checkPrepareoffer(offer, proposal);

    if (window.screen.width <= 799.98) {
      EventBus.$emit('prepare-mobile-offer', true);
    } else {
      EventBus.$emit('prepare-offer', true);
    }
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  async saveOfferFee() {
    try {
      this.editFeeLoading = true;
      let agencyFee = {
        amount: this.agencyFeeValue,
        currency: this.proposal.agencyFee!.currency,
      };
      let response = await AirApi.updateFeeOnOffer({ searchId: this.$route.params.searchId, offerId: this.proposal.id, agencyFee });
      if (response && response.data) {
        EventBus.$emit('reload-proposals', this.proposal.id);
      }
    } catch (error) {
      this.editFeeErrors = this.$handleErrors(error, true);
    } finally {
      this.editFeeLoading = false;
      this.showEditFeePopup = false;
    }
  }

  async saveOfferAgencyMarkup() {
    try {
      this.editAgencyMarkupLoading = true;
      let agencyMarkup = {
        amount: this.agencyMarkupValue,
        currency: this.proposal.agencyMarkup!.currency,
      };
      let response = await AirApi.updateAgencyMarkupOnOffer({
        searchId: this.$route.params.searchId,
        offerId: this.proposal.id,
        agencyMarkup
      });
      if (response && response.data) {
        EventBus.$emit('reload-proposals', this.proposal.id);
      }
    } catch (error) {
      this.editAgencyMarkupErrors = this.$handleErrors(error, true);
    } finally {
      this.editAgencyMarkupLoading = false;
      this.showEditAgencyMarkupPopup = false;
    }
  }

  created() {
    AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: false});
    this.selectCondition(0);
  }
}
