import AirProviderConfigurationDetail from '@/modules/settings/configurations/AirProviderConfigurationDetail.vue';

export default {
  path: 'new',
  name: 'new-air-provider-configuration',
  component: AirProviderConfigurationDetail,
  displayName: 'New flight provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadFlightProviders',
    depthCategory: 'air-providers',
    depth: 2,
    parentName: 'air-provider-configurations',
  },
};