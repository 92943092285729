




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import LayoutStore from '@/modules/layout/layout.store';

@Component({})
export default class ScrollMenu extends Vue {
  @Prop() items!: any[];

  isDragging: boolean = false;
  dragX: number = 0;
  dragOffset: number = 0;
  destX: number = 0;
  lastSpeed: number = 0;
  scrollTimeout: number = 0;
  lastTime: number = Date.now();
  curTime: number = 0;
  touchStartTime: number = 0;
  scrollInterval = 30;
  isScrolling: boolean = false;
  curX: number = 0;
  activeItems: Object = {};
  omitScrollNow: boolean = false;

  get currentUser() {
    return AccountStore.CurrentUser;
  }

  get scrollChangeId() {
    return LayoutStore.scrollAreaId;
  }

  selectItem(event) {
    this.scrollMenuToItem(event.target.parentElement);
  }

  scrollMenuToItem(element) {
    const area = this.$refs.scrollMenu as HTMLElement;
    const pos = element.offsetLeft + element.offsetWidth / 2 - area.offsetWidth / 2;
    this.scrollTo(pos);
  }

  scrollToSection(itemName) {
    if (!itemName) {
      return;
    }
    const section = document.getElementById(itemName);

    if (section) {
      const position = section.offsetTop - 20;
      const scrollableAreas = document.getElementsByClassName('scrollable-area');
      let scrollableArea;
      if (scrollableAreas.length) {
        scrollableArea = scrollableAreas[0];
      } else {
        return;
      }
      this.omitScrollNow = true;
      setTimeout(() => {
        this.omitScrollNow = false;
      }, 500);
      this.selectMenuItem(itemName);
      if (scrollableArea.scrollTo) {
        scrollableArea.scrollTo(0, position);
      } else {
        scrollableArea.scrollTop = position;
      }
    }
  }

  touchStart(e) {
    if (Number.isNaN(e.touches[0].screenX)) {
      return;
    }

    const area = this.$refs.scrollMenu as HTMLElement;

    this.isDragging = true;
    this.dragOffset = area.scrollLeft;
    this.dragX = e.touches[0].screenX;
    this.touchStartTime = Date.now();
  }

  touchMove(e) {
    if (!this.isDragging) {
      return;
    }
    if (Date.now() - this.touchStartTime < 200) {
      return;
    }
    if (Number.isNaN(e.touches[0].screenX)) {
      return;
    }
    const curX = e.touches[0].screenX - this.dragX;
    this.scrollTo(this.dragOffset - curX);
  }

  touchEnd(e) {
    if (Date.now() - this.touchStartTime < 200) {
      this.isDragging = false;
      return;
    }
    const step = this.lastSpeed * 300 / Math.max(1, this.curTime);
    this.scrollTo(this.curX + step);
    this.isDragging = false;
  }

  scrollTo(x) {
    const now = Date.now();
    const area = this.$refs.scrollMenu as HTMLElement;

    this.lastSpeed = x - this.destX;
    this.curTime = now - this.lastTime;
    this.lastTime = now;
    this.destX = Math.max(Math.min(x, area.scrollWidth - area.offsetWidth), 0);

    if (this.isScrolling) {
      return;
    }
    this.isScrolling = true;
    clearInterval(this.scrollTimeout);
    this.scrollTimeout = setInterval(() => {
      this.scrollPositionNow(Math.round(this.curX + (this.destX - this.curX) * .1));

      if (Math.abs(this.destX - this.curX) < 1) {
        this.scrollPositionNow(this.destX);
        this.isScrolling = false;
        clearInterval(this.scrollTimeout);
      }
    }, this.scrollInterval);
  }

  scrollPositionNow(x) {
    this.curX = x;
    const area = this.$refs.scrollMenu as HTMLElement;
    if (!area) {
      return;
    }
    if (area.scrollTo) {
      area.scrollTo(x, 0);
    } else {
      area.scrollLeft = x;
    }
  }

  mouseWheel(e) {
    this.scrollTo(this.curX + e.deltaY);
  }

  isItemActive(item) {
    return this.activeItems[item.name];
  }

  selectMenuItem(name) {
    Object.keys(this.activeItems).forEach(item => {
      if (this.activeItems[item] && item !== name) {
        this.activeItems[item] = false;
      }
    });
    this.activeItems[name] = true;
    this.$forceUpdate();
  }

  @Watch('items', { immediate: true })
  onItemsChanged(items) {
    if (!items.find(item => {
      return this.activeItems[item.name];
    })) {
      this.activeItems[items[0].name] = true;
    }
  }

  @Watch('scrollChangeId')
  onScrollChange() {
    if (this.omitScrollNow) {
      return;
    }
    const filtered = this.items.filter(item => {
      const section = document.getElementById(item.name) as HTMLElement;

      if (!section) {
        return false;
      }

      return section.offsetTop + section.offsetHeight > LayoutStore.scrollTop + 20;
    });

    const firstEl = filtered[0];
    const first = this.$refs['items_' + firstEl.name] as any[];

    if (!first || !first.length) {
      return;
    }

    this.selectItem({
      target: {
        parentElement: first[0],
      },
    });
    this.selectMenuItem(firstEl.name);
  }

  beforeDestroy() {
    clearInterval(this.scrollTimeout);
  }
}

