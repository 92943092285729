































































import { Vue, Component, Watch } from 'vue-property-decorator';

import settingsStore from './settings.store';
import { translate } from '@/i18n';

@Component({})
export default class SettingsSidebar extends Vue {
  groupsToggled: any[] = ['company'];

  get currentCompany() {
    return settingsStore.currentCompany;
  }

  get companyLoaded() {
    return settingsStore.loaded;
  }

  get isAgency() {
    return settingsStore.isAgency;
  }

  get isRootCompany() {
    return settingsStore.isRootCompany;
  }

  get menuGroups() {
    return settingsStore.menuGroups
      .map(group => {
        const items = (group.items as any[])
          .map(route => {
            return {
              ...route,
              displayName: translate(route.displayName),
              selected: !!this.activeRouter(route),
            };
          });
        return {
          ...group,
          displayName: translate(group.displayName),
          items,
          toggled: !!this.groupsToggled.find(name => name === group.name),
          selected: !!items.find(route => this.activeRouter(route)),
        };
      });
  }

  toggleGroup(group) {
    const gIndex = this.groupsToggled.findIndex(name => name === group.name);
    if (-1 === gIndex) {
      this.groupsToggled.push(group.name);
      return;
    }
    
    this.groupsToggled.splice(gIndex, 1);
  }

  activeRouter(item) {
    const name = item.name ? item.name : item.meta.parentName;

    return this.$route.name === name ||
      (this.$route.meta.parentName &&
      this.$route.meta.parentName === name);
  }

  linkToDisplay(route) {
    return route.path === '' || route.path === 'company-structure' || route.path === 'community' || ((route.path === 'customers') && this.isAgency);
  }

  seleniumId(item) {
    return item.name ? item.name : item.meta.parentName;
  }

  getItemRoute(item) {
    if (item.meta.parentName === 'community') {
       return '/settings/' + this.currentCompany!.companyId + '/' + item.path + '?unitId=' +  this.currentCompany!.companyId;
    } else {
      return '/settings/' + this.currentCompany!.companyId + '/' + item.path;
    }
  }

  resetToggled(val) {
    const findSelected = val.find(group => group.selected);

    if (!findSelected) {
      this.groupsToggled = [val[0].name];
      return;
    }

    this.groupsToggled = [findSelected.name];
  }

  @Watch('isAgency')
  onFlagChange() {
    this.$forceUpdate();
  }

  @Watch('currentCompany', { immediate: true })
  onCompanyChange() {
    this.resetToggled(this.menuGroups);
  }

  @Watch('menuGroups', { immediate: true })
  onMenuChange(val, old) {
    if ((old === undefined || old.length === 0) && (val.length > 0)) {
      this.resetToggled(val);
    }
  }
}
