export interface IRestrictChoice {
  key: string;
  value: string;
}

export interface LoyaltiesProfileModel {
  id: string;
  cardNumber: string;
  expireDate: string;
  validRoutes: IValidRoutes;
  carrierCode: string;
  carrierName: string;
  programName: string;
  loyaltyType: string;
  isNumberRequired: boolean;
  isExpirationRequired: boolean;
  isRoutingRequired: boolean;
  cardNumberWithOnlyDigits?: boolean;
}

export interface IAccommodationProgramName {
  id: string;
  serviceType: string;
  carrierCode: string;
  carrierName: string;
  programName: string;
  loyaltyType: string;
  isNumberRequired: boolean;
  cardNumberWithOnlyDigits: boolean;
  isExpirationRequired: boolean;
  isRoutingRequired?: boolean;
  subscriber: string;
  cardLabel?: string;
}

export interface IValidRoutes {
  type: string | null;
  name?: string | null;
}

export interface CreateLoyaltiesModel {
  loyaltyId: string;
  cardNumber?: string | null;
  expireDate?: Date | string | null;
  validRoutes?: IValidRoutes | null;
}

export enum LoyaltyType {
  Uknown = '',
  Discount = 'Discount',
  Loyalty = 'Loyalty',
}

export interface LoyaltyTypeOption {
  type: LoyaltyType | null;
  name: string;
}

export class LoyaltyForm {
  loyaltyId: string | null;
  cardNumber: string | null;
  expireDate: string | null;
  issueDate: string | null;
  validRoutes: IValidRoutes | null;

  constructor(payload?: Partial<LoyaltyForm>) {
    this.loyaltyId = (payload && payload.loyaltyId) || null;
    this.cardNumber = (payload && payload.cardNumber) || null;
    this.expireDate = (payload && payload.expireDate) || null;
    this.issueDate = (payload && payload.issueDate) || null;
    this.validRoutes = (payload && payload.validRoutes) || null;
  }
}

export interface IUpdateLoyaltyForm {
  cardNumber: string | null;
  expireDate: string | null;
  issueDate: string | null;
  validRoutes: IValidRoutes | null;
}
