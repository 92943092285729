import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  LoyaltiesProfileModel,
  IAccommodationProgramName,
  LoyaltyForm,
  IUpdateLoyaltyForm,
} from './loyalties.model';

class LoyaltiesApiClass {
  public getLoyaltiesByService(serviceType, loyaltyType, query): Promise<AxiosResponse<LoyaltiesProfileModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties/autocomplete?serviceType={serviceType}&loyaltyType={loyaltyType}&query={query}&limit=50',
      method: RequestMethod.GET
    }, {
      serviceType,
      loyaltyType,
      query,
    }, {});
  }

  public getProfileloyaltiesCard(profileId): Promise<AxiosResponse<LoyaltiesProfileModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties?profileId={profileId}',
      method: RequestMethod.GET
    }, {
      profileId,
    }, {});
  }

  public getProfileloyaltiesCardModify(profileId, serviceType, loyaltyType): Promise<AxiosResponse<LoyaltiesProfileModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties?profileId={profileId}&serviceType={serviceType}&loyaltyType={loyaltyType}',
      method: RequestMethod.GET
    }, {
      profileId,
      serviceType,
      loyaltyType,
    }, {});
  }

  public getLoyaltyCard(profileLoyaltyId) {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties/{profileLoyaltyId}',
      method: RequestMethod.GET
    }, {
      profileLoyaltyId,
    }, {});
  }

  public createLoyaltyCard(profileId: string, params: LoyaltyForm) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/loyalties',
      method: RequestMethod.POST
    }, {
      profileId,
    }, params);
  }

  public updateLoyaltyCard(profileLoyaltyId: string, params: IUpdateLoyaltyForm) {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties/{profileLoyaltyId}',
      method: RequestMethod.PUT
    }, { profileLoyaltyId }, params);
  }

  public removeLoyaltyCard(profileLoyaltyId) {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties/{profileLoyaltyId}',
      method: RequestMethod.DELETE
    }, { profileLoyaltyId }, {});
  }

  public getLoyaltiesQuery({ serviceType, loyaltyType, carrierCode }: { serviceType: string, loyaltyType: string, carrierCode: string }): Promise<AxiosResponse<IAccommodationProgramName[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/loyalties/query?serviceType={serviceType}&loyaltyType={loyaltyType}&carrierCode={carrierCode}',
      method: RequestMethod.GET
    }, { serviceType, loyaltyType, carrierCode }, {});
  }
}

export const LoyaltiesApi: LoyaltiesApiClass = new LoyaltiesApiClass();
