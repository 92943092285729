export class ExpenseExtensionsModel {
  id: string = '';
  policyId: string = '';
  propertyType: string = '';
  expenseType: string = '';
  isActive: boolean = false;
  conditions: Condition[] = [
    {
      expenseState: 'Claimed',
      isRequired: false,
      index: 1
    },
    {
      expenseState: 'Cancelled',
      isRequired: false,
      index: 6
    },
    {
      expenseState: 'Closed',
      isRequired: false,
      index: 5
    },
    {
      expenseState: 'Rejected',
      isRequired: false,
      index: 4
    },
    {
      expenseState: 'Draft',
      isRequired: false,
      index: 0
    },
    {
      expenseState: 'Violation',
      isRequired: false,
      index: 2
    },
    {
      expenseState: 'Completed',
      isRequired: false,
      index: 3
    }
  ];

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.propertyType !== undefined) {
      this.propertyType = params.propertyType;
    }
    if (params.expenseType !== undefined) {
      this.expenseType = params.expenseType;
    }
    if (params.conditions !== undefined) {
      this.conditions.forEach(c => {
        params.conditions.forEach(p => {
          if (c.expenseState === p.expenseState) {
            c.isRequired = p.isRequired;
          }
        });
      });
    }
  }
}

export interface ExpenseExtensionsCreateModel {
  policyId: string;
  propertyType: string;
  expenseType: string;
  conditions: Condition[];
  isActive: boolean;
}

export interface ExpenseExtensionsUpdateModel {
  propertyType: string;
  expenseType: string;
  conditions: Condition[];
  isActive: boolean;
}

export interface Condition {
  expenseState: string;
  isRequired: boolean;
  index?: number;
}