














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import { CarApi } from '@/api/car-engine/car-search.api';
import BasketStore from '../basket.store';
import moment from 'moment';
import BasketItem from '../BasketItem.vue';

const ERROR_EXCEPTION_CODE = 'NO_RULE_FROM_VENDOR';

@Component({
  components: {
    BasketItem,
  }
})
export default class SabreBasketExtras extends Vue {
  @Prop({}) item!: any;
  @Prop({}) extrasVisible!: boolean;
  itemTotalPrice: number = this.item.totalPrice;

  get basket() {
    return BasketStore.basket;
  }

  get basketExtras() {
    return BasketStore.basketExtras;
  }

  get selectedExtras() {
    return BasketStore.selectedExtras;
  }

  get basketExtrasErrors() {
    return BasketStore.basketExtrasErrors;
  }

  get totalPrice() {
    return this.itemTotalPrice;
  }

  @Debounce({ delay: 300 })
  async setSelectedExtras() {
    BasketStore.setSelectedExtras();
    try {
      const response = await CarApi.getFareConditions({
        supplier: this.item.supplier,
        supplierSearchId: null,
        searchId: null,
        extras: this.selectedExtras.map(e => {
          if (e && e.isSelected) {
            return e.code;
          }
        }).filter(Boolean),
      }, this.item.car!.id);
      if (response && response.data) {
        this.itemTotalPrice = response.data.totalPrice;
        BasketStore.setBasketItemPrice({ id: this.item.id, price: response.data.totalPrice });
      }
    } catch (error) {
      if (error.response && error.response.status !== 418 && error.response.data.error.code !== ERROR_EXCEPTION_CODE) {
        BasketStore.setBasketExtrasErrors(this.$handleErrors(error));
      }
    }
  }

}
