import Trips from '@/modules/trips/Trips.vue';
import TripsIntro from '@/modules/trips/TripsIntro.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/trips',
  name: 'trips',
  components: {
    default: Trips,
    intro: TripsIntro,
  },
  meta: {
    introClass: 'intro-trips',
    permission: 'ShowTripList',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  },
};
