import Multiselect from 'vue-multiselect';
import UiSliderL from 'vue-bootstrap-slider/es/form-slider';

import UiHeader from './UiHeader.vue';
import UiBusyIndicator from './UiBusyIndicator.vue';
import UiPageTitle from './UiPageTitle.vue';
import UiFormTitle from './UiFormTitle.vue';
import UiPopUpTitle from './UiPopUpTitle.vue';
import UiSectionTitle from './UiSectionTitle.vue';
import UiLink from './UiLink.vue';
import UiIcon from './UiIcon.vue';
import UiButton from './UiButton.vue';
import UiLabel from './UiLabel.vue';
import UiInput from './UiInput.vue';
import UiSelect from './UiSelect.vue';
import UiMultiSelect from './UiMultiSelect.vue';
import UiAutocomplete from './UiAutocomplete.vue';
import UiCheckbox from './UiCheckbox.vue';
import UiRadio from './UiRadio.vue';
import UiPopup from './UiPopup.vue';
import UiColorPicker from './UiColorPicker.vue';
import UiPrice from './UiPrice.vue';
import UiForm from './UiForm.vue';
import UiCounter from './UiCounter.vue';
import UiPagination from './UiPagination.vue';
import UiPerPage from './UiPerPage.vue';
import UiTableWrapper from './UiTableWrapper.vue';
import UiTableLegendItem from './UiTableLegendItem.vue';
import UiFiltersWrapper from './UiFiltersWrapper.vue';
import UiToast from './UiToast.vue';
import UiAvatar from './UiAvatar.vue';
import UiWrapperLayout from './UiWrapperLayout.vue';
import UiTripStatus from './UiTripStatus.vue';
import UiBookingStatus from './UiBookingStatus.vue';
import UiSearchWrapper from './UiSearchWrapper.vue';
import UiToggleView from './/UiToggleView.vue';
import UiAirCarrierLogo from './UiAirCarrierLogo.vue';
import UiWonderSearch from './UiWonderSearch.vue';
import UiPlusMinusValue from './UiPlusMinusValue.vue';
import UiCollapsAbleFilter from './UiCollapsAbleFilter.vue';
import UiErrors from './UiErrors.vue';
import UiCreditCardLogo from '@/controls/UiCreditCardLogo.vue';
import UiSwitch from '@/controls/UiSwitch.vue';
import UiSlider from '@/controls/UiSlider.vue';
import UiTrainLogo from '@/controls/UiTrainLogo.vue';
import UiCustomIcon from '@/controls/UiCustomIcon.vue';
import UiTimePicker from '@/controls/UiTimePicker.vue';
import UiDatePicker from '@/controls/UiDatePicker.vue';
import UiGallery from '@/controls/UiGallery.vue';
import UiAirAutocompleteTemplate from './UiAirAutocompleteTemplate.vue';
import UiCarAutocompleteTemplate from './UiCarAutocompleteTemplate.vue';
import UiClassSelect from '@/controls/UiClassSelect.vue';
import UiRailAutocompleteTemplate from './UiRailAutocompleteTemplate.vue';
import UiHotelAutocompleteTemplate from './UiHotelAutocompleteTemplate.vue';
import UiDropdown from './UiDropdown.vue';
import UiNoOffersTemplate from './UiNoOffersTemplate.vue';
import UiTravellersSelect from './UiTravellersSelect.vue';
import UiTravellersRoomsSelect from './UiTravellersRoomsSelect.vue';
import UiTrainTravellersSelect from './UiTrainTravellersSelect.vue';
import UiGuestTravellersRoomsSelect from './UiGuestTravellersRoomsSelect.vue';
import UiCustomCheck from './UiCustomCheck.vue';
import UiHorizontalScrollSection from './UiHorizontalScrollSection.vue';
import UiCo2 from './UiCo2.vue';
import UiPCIProxyLogo from './UiPCIProxyLogo.vue';
import UiPerson from './UiPerson.vue';
import UiTextFit from './UiTextFit.vue';
import UiProcessingTile from './UiProcessingTile.vue';
import UiSupplierLabel from './UiSupplierLabel.vue';

export default {
  UiHeader,
  UiBusyIndicator,
  UiPageTitle,
  UiFormTitle,
  UiPopUpTitle,
  UiSectionTitle,
  UiLink,
  UiIcon,
  UiButton,
  UiLabel,
  UiInput,
  UiSelect,
  UiMultiSelect,
  UiAutocomplete,
  UiCheckbox,
  UiRadio,
  UiSlider,
  UiSliderL,
  UiPopup,
  UiColorPicker,
  UiPrice,
  UiForm,
  UiCounter,
  UiPagination,
  UiPerPage,
  UiTableWrapper,
  UiTableLegendItem,
  UiFiltersWrapper,
  Multiselect,
  UiToast,
  UiAvatar,
  UiWrapperLayout,
  UiTripStatus,
  UiBookingStatus,
  UiSearchWrapper,
  UiToggleView,
  UiAirCarrierLogo,
  UiWonderSearch,
  UiPlusMinusValue,
  UiCollapsAbleFilter,
  UiErrors,
  UiCreditCardLogo,
  UiSwitch,
  UiTrainLogo,
  UiCustomIcon,
  UiTimePicker,
  UiDatePicker,
  UiGallery,
  UiAirAutocompleteTemplate,
  UiCarAutocompleteTemplate,
  UiClassSelect,
  UiRailAutocompleteTemplate,
  UiHotelAutocompleteTemplate,
  UiDropdown,
  UiNoOffersTemplate,
  UiTravellersSelect,
  UiTravellersRoomsSelect,
  UiTrainTravellersSelect,
  UiGuestTravellersRoomsSelect,
  UiCustomCheck,
  UiHorizontalScrollSection,
  UiCo2,
  UiPCIProxyLogo,
  UiPerson,
  UiTextFit,
  UiProcessingTile,
  UiSupplierLabel,
};
