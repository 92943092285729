import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

export class CustomFieldsProfileApiClass {
  public getProfileCustomFields(profileId: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/custom-fields',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public updateProfileCustomField(
    profileId: string,
    request: any
  ): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/custom-fields',
      method: RequestMethod.PUT
    }, { profileId }, request);
  }
}

export const CustomFieldsProfileApi: CustomFieldsProfileApiClass
  = new CustomFieldsProfileApiClass();