import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  SecurityConfiguration,
} from './security.model';


class SecurityApiClass {
  public getConfiguration(configurationId: string): Promise<AxiosResponse<SecurityConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/security-configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public createConfiguration(params: SecurityConfiguration): Promise<AxiosResponse<{ configurationId: string }>> {
    return http.execute({
      path: settings.apiProfile + '/api/security-configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateConfiguration(configurationId: string, params: SecurityConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/security-configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }
}

export const SecurityApi: SecurityApiClass = new SecurityApiClass();