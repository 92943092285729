







import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';

@Component({})
export default class DepthTransition extends Vue {
  
  get transitionName() {
    if (layoutStore.depthDirection <= 0) {
      return 'depth-right';
    }
    return 'depth-left';
  }
}

