
























































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import ExpensePolicyStore from './expense-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import { AuditRuleModel } from '@/api/expense/expense-audit-rules.model';
import { ExpenseAuditRulesApi } from '@/api/expense/expense-audit-rules.api';
import EventHandler from '@/services/event-handler';
import { router } from '@/router';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import $handleErrors from '@/core/errors/handle-errors.service';
import ExpressionCreator from './ExpressionCreator.vue';

const actionIndicator = 'ResolutionExplanation';

@Component({
  components: {
    ExpressionCreator,
  }
})
export default class AuditRule extends Vue {
  $v;
  definition: AuditRuleModel = new AuditRuleModel();
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  scopeOptions = [
    {
      name: translate('settings-expense.mission'),
      value: 'Mission'
    },
    {
      name: translate('settings-expense.expense'),
      value: 'Expense'
    },
  ];

  severityOptions = [
    {
      name: translate('settings-expense.information'),
      value: 'Information'
    },
    {
      name: translate('settings-expense.risk'),
      value: 'Risk'
    },
    {
      name: translate('settings-expense.warning'),
      value: 'Warning'
    },
    {
      name: translate('settings-expense.violation'),
      value: 'Violation'
    }
  ];

  escalationLevelOptions = [
    {
      name: translate('settings-expense.reporter'),
      value: 'Reporter'
    },
    {
      name: translate('settings-expense.approver'),
      value: 'Approver'
    },
    {
      name: translate('settings-expense.financial-controller'),
      value: 'FinancialController'
    }
  ];

  @Validation()
  validationObject() {
    return {
      definition: {
        name: {
          required,
          maxLength: maxLength(64)
        },
        description: {
          maxLength: maxLength(1024)
        },
        resultMessage: {
          required,
          maxLength: maxLength(1024)
        },
        scope: {
          required,
        },
        severity: {
          required,
        },
        expression: {
          required,
          maxLength: maxLength(1024)
        },
        order: {},
        isActive: {},
        escalationLevel: {
          required,
        }
      },
    };
  }

  get activeTab() {
    return ExpensePolicyStore.activeWorkflowTab;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get auditRuleId() {
    return router.currentRoute.params.auditRuleId;
  }

  get auditRule() {
    return ExpensePolicyStore.auditRule;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get configurationWorkflowUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/workflow-settings/${this.expensePolicyId}`;
  }

  get currentActiveTab() {
    if (this.activeTab === 'ExpenseReportValidation') {
      return translate('settings-expense.report-approval');
    } else if (this.activeTab === 'MissionPreapproval') {
      return translate('settings-expense.mission-preapproval');
    } else {
      return translate('settings-expense.single-expense-validation');
    }
  }

  get auditRuleExpressionCreator() {
    return ExpensePolicyStore.auditRuleExpressionCreator;
  }

  remove() {
    this.errorsOnPopup = [];
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  async removeDefinition() {
    try {
      if (this.definition && this.definition.id) {
        ExpensePolicyStore.setLoading(true);
        await ExpenseAuditRulesApi.removeAuditRule(this.definition.id);
        this.removePopup = false;
        const obj = {
          type: translate('common.success'),
          title: translate('settings-expense.configuration-removed'),
          message: translate('settings-expense.policy-removed-success')
        };
        EventHandler.$emit('show-toast', obj);
      }
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    } finally {
      if (!this.errors.length) {
        this.goToConfiguration();
      }
      ExpensePolicyStore.setLoading(false);
    }
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'workflow-settings',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveAuditRule() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }
    if (this.errors && this.errors.length === 0) {
      let request = {
        expensePolicyId: this.expensePolicyId,
        name: this.definition.name,
        description: this.definition.description,
        resultMessage: this.definition.resultMessage,
        scope: this.definition.scope.value,
        stage: this.activeTab,
        severity: this.definition.severity.value,
        isActive: this.definition.isActive,
        expressionCreatorParams: this.definition.expression,
        order: parseInt(this.definition.order),
        actionIndicator: actionIndicator,
        escalationLevel: this.definition.escalationLevel.value
      };

      const isEdit = !!this.definition.id;
      if (isEdit && this.definition.id) {
        const message = {
          auditRuleId: this.definition.id,
          params: request,
        };
        await ExpensePolicyStore.updateAuditRule(message);
      } else {
        await ExpensePolicyStore.createAuditRule(request);
      }

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'workflow-settings',
          params: {
            id: this.$route.params.id,
            configurationId: this.configurationId,
            itemId: this.$route.params.itemId
          }
        });
      }
    }
  }

  async mapFormData() {
    if (this.auditRuleId) {
      await ExpensePolicyStore.getAuditRule(this.auditRuleId);
      await ExpensePolicyStore.getAuditRuleExpressionCreator(this.auditRuleId);
      if (this.auditRule) {
        this.definition = new AuditRuleModel(this.auditRule);
        this.definition.severity = this.severityOptions.find(option => option.value === this.definition.severity);
        this.definition.scope = this.scopeOptions.find(option => this.definition.scope === option.value);
        this.definition.escalationLevel = this.escalationLevelOptions.find(option => option.value === this.definition.escalationLevel);
      }

      if (this.auditRuleExpressionCreator && this.auditRuleExpressionCreator.parameters) {
        this.definition.expression = this.auditRuleExpressionCreator.parameters;
      }
    } else {
      this.definition.severity = this.severityOptions[0];
      this.definition.escalationLevel = this.escalationLevelOptions[0];

      if (this.activeTab === 'MissionPreapproval' || this.activeTab === 'ExpenseReportValidation') {
        this.definition.scope = this.scopeOptions.find(option => option.value === 'Mission');
      } else {
        this.definition.scope = this.scopeOptions.find(option => option.value === 'Expense');
      }
    }
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.configurationId === undefined) {
      this.goToConfiguration();
    } else {
      if (!this.configuration.name || Object.keys(this.configuration).length === 0) {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      if (Object.keys(this.expenseDetails).length === 0) {
        ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
      }
    }

    this.mapFormData();
  }
}
