import PerDiemRate from '@/modules/settings/expense-configuration/PerDiemRate.vue';

export default {
  path: ':configurationId?/per-diem-rate/:itemId?/:perDiemRateId?/:isCopy?',
  name: 'per-diem-rate',
  component: PerDiemRate,
  displayName: 'Per diem rate',
  meta: {
    breadCrumb: 'Per diem rate',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};