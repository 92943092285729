import FaresMapping from '@/modules/settings/fares-mapping/FaresMapping.vue';
import FaresMappingConfigurations from './fares-mapping-configurations.route';
import FaresMappingConfiguration from './fares-mapping-configuration.route';
import FareMappingAir from './fares-mapping-air.route';

export default {
  path: 'fares-mapping-configurations',
  component: FaresMapping,
  displayName: 'settings-sidebar.fares-mapping',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditFareMapping',
    icon: 'label',
    breadCrumb: 'Fares mapping',
    parentName: 'fares-mapping-configurations',
  },
  children: [
    FaresMappingConfigurations,
    FaresMappingConfiguration,
    FareMappingAir,
  ],
};