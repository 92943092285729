import Settings from '@/modules/settings/Settings.vue';
import SettingsIntro from '@/modules/settings/SettingsIntro.vue';
import SettingsSidebar from '@/modules/settings/SettingsSidebar.vue';
import settingsById from './settings-by-id.route';

export default {
  path: '/settings',
  name: 'settings',
  components: {
    default: Settings,
    intro: SettingsIntro,
    sidebar: SettingsSidebar
  },
  meta: {
    introClass: 'intro-settings',
    permission: 'AccessSettings'
  },
  children: [
    settingsById,
  ],
};
