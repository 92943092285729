export interface RoleDetailsModel {
  code: string;
  description: string;
  name: string;
  order: number;
  permissions: RoleDetailsPermissionModel[];
}

export interface RoleDetailsPermissionModel {
  code: string;
  description: string;
  name: string;
  order: number;
  isSelected: boolean;
}

export class RoleModel {
  roleId!: string;
  name!: string;
}

export class NewRoleModel {
  constructor() {
    this.permissions = [];
  }

  name!: string;
  permissions!: string[];
  companyId!: string;
}

export class RoleEditModel {
  roleId!: string;
  roleName!: string;
  permissions!: RoleDetailsModel[];
}

export class UpdateRoleModel {
  constructor() {
    this.permissions = [];
  }

  name!: string;
  permissions!: string[];
}

export class ProfileRoleScope {
  public static readonly None: string = 'None';
}

export class ProfileRoleModel {
  roleId!: string;
  roleName!: string;
  scope!: string;
  hasOwnProfileAccess!: boolean;
  includeAgencyCustomers!: boolean;
}

export class ProfileRoleDetailsModel {
  roleId!: string;
  name!: RoleModel | null;
  scope!: string;
  includeAgencyCustomers!: boolean;
  selectedBusinessUnits!: string[];
  usersCompany?: boolean;
}

export interface RoleScopeModel {
  roleId: string;
  scope: string;
}

export interface NewProfileRoleModel {
  rolesWithScopes: RoleScopeModel[];
  ProfileCompanyId: string;
  ProfileRootCompanyId: string;
}

export interface RolesModel {
  results: RoleModel[];
  page: Page;
}

export interface Page {
  pageSize: number;
  pageNo: number;
  moreResultsAvailable: boolean;
}

export class RoleModelScope {
  roles!: RoleModel[];
  availableScopes!: ScopeValue[];
}

export enum ScopeValue {
  userProfileOnly = 'UserProfileOnly',
  wholeCompanyAccess = 'WholeCompanyAccess',
  ownUnitAccess = 'OwnUnitAccess',
  selectedUnitsAccess = 'SelectedUnitsAccess',
  selectedUnitsAndBelowAccess = 'SelectedUnitsAndBelowAccess'
}
