































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';

import { translate } from '@/i18n';
import { router } from '@/router';
import { isCancelError } from '@/core/utils';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ProfileAgencyApi } from '@/api/profile/agency.api';
import { Customer } from '@/api/profile/agency.model';
import { Permission } from '@/const/permission.enum';
import { CompanyChangeStatusMessage } from '@/api/profile/company.model';
import SettingsStore from '../settings.store';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class CustomersStructure extends Vue {
  currentPage: number = 1;
  params = {
    size: Number.MAX_SAFE_INTEGER,
    sort: '',
    start: 0,
  };
  perPageOptions = [
    5,
    10,
    15,
    20,
    30,
    40,
    50,
    100,
    Number.MAX_SAFE_INTEGER,
  ];
  fields = {
    name: {
      label: translate('settings-customers.name'),
      sortable: false,
    },
    code: {
      label: translate('settings-customers.code'),
      sortable: false,
    },
    agency: {
      label: translate('settings-customers.agency'),
      sortable: false,
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  menuActive: boolean = false;
  company = this.currentCompany;
  configuration: Customer | null = null;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  routeCompanyId: string = '';
  $v;
  filter = '';
  items: any[] = [];
  agencySelectOptions: any[] = [];
  delayTimer: any | undefined = null;
  serverErrors: any[] = [];
  filterAgencyList: any[] = [];
  Form: any = {
    searchCustomers: '',
    searchAgency: ''
  };
  Queries: any = {
    customer: '',
    agency: '',
  };
  queryCustomersInProgress: boolean = false;
  queryAgencyInProgress: boolean = false;
  deactivateUnitPopup: boolean = false;
  activateUnitPopup: boolean = false;
  deleteInProgress: boolean = false;
  deactivateInProgress: boolean = false;
  deleteUnitPopup: boolean = false;
  canDeleteLoading: boolean = false;
  canDisableLoading: boolean = false;
  canEnableLoading: boolean = false;
  cancelToken: CancelTokenSource | null = null;
  canDeleteError: any[] = [];
  canDisableError: any[] = [];
  canEnableError: any[] = [];
  hasSubUnits: boolean = false;
  popupError: any[] = [];



  get exactMatches() {
    if (!this.Queries.customer && !this.Queries.agency) {
      return [];
    }
    const query = this.Queries.customer.toLowerCase();
    const agencyQuery = this.Queries.agency.toLowerCase();
    const matchNameOrCode = (item) => {
      return (item.companyName || '').toLowerCase().indexOf(query) > -1 || (item.companyCode || '').toLowerCase().indexOf(query) > -1;
    };
    const matchAgency = (item) => {
      return (item.agencyName || '').toLowerCase().indexOf(agencyQuery) > -1;
    };

    return this.items.filter(item => {
      if (!this.Queries.agency) {
        return matchNameOrCode(item);
      } else if (!this.Queries.customer) {
        return matchAgency(item);
      }

      return matchNameOrCode(item) && matchAgency(item);
    });
  }

  get exactMatchesIds() {
    return this.exactMatches.map(el => el.companyId);
  }

  get itemsFiltered() {
    if (!this.Queries.customer && !this.Queries.agency) {
      return this.items;
    }
    const exactMatches = this.exactMatches;

    const ids = exactMatches.reduce((previous, current) => {
      return this.findAllParents(current, previous);
    }, this.exactMatchesIds);

    return this.items.filter(item => {
      const parentIds = this.findAllParents(item, []);
      const hasParent = parentIds.reduce((prev, cur) => {
        return prev || !!exactMatches.find(match => match.companyId === cur);
      }, false);

      return ids.indexOf(item.companyId) > -1 || hasParent;
    }).map(item => {
      const matchedName = this.matchedItemName(item.companyName || '');
      const matchedCode = this.matchedItemName(item.companyCode || '');
      const matchedAgency = this.matchedItemAgency(item.agencyName || '');
      return {
        ...item,
        companyNamePartBefore: matchedName[0],
        companyNamePartExact: matchedName[1],
        companyNamePartAfter: matchedName[2],
        companyCodePartBefore: matchedCode[0],
        companyCodePartExact: matchedCode[1],
        companyCodePartAfter: matchedCode[2],
        agencyNamePartBefore: matchedAgency[0],
        agencyNamePartExact: matchedAgency[1],
        agencyNamePartAfter: matchedAgency[2],
      };
    });
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.itemsFiltered.length;
  }

  get canDeleteCompanies() {
    return this.$hasAccess('CanDeleteCompany');
  }

  editCompany(companyId) {
    return sanitizeUrl('/settings/' + companyId + '/?edit=true');
  }

  editAgency(agencyId) {
    return sanitizeUrl('/settings/' + agencyId + '/?edit=true');
  }

  findAllParents(item, parents) {
    if (!item.companyParentId) {
      return parents;
    }

    const parent = this.items.find(el => el.companyId === item.companyParentId);

    return this.findAllParents(parent, [...parents, item.companyParentId]);
  }

  matchedItemName(name) {
    const query = this.Queries.customer.toLowerCase();
    const index = name.toLowerCase().indexOf(query);

    if (index === -1) {
      return [name, '', ''];
    }

    const before = name.substr(0, index);
    const exact = name.substr(index, query.length);
    const after = name.substr(index + query.length);

    return [before, exact, after];
  }

  matchedItemAgency(name) {
    const query = this.Queries.agency.toLowerCase();
    const index = name.toLowerCase().indexOf(query);

    if (index === -1) {
      return [name, '', ''];
    }

    const before = name.substr(0, index);
    const exact = name.substr(index, query.length);
    const after = name.substr(index + query.length);

    return [before, exact, after];
  }

  classes(level) {
    if (level > 1) {
      return {
        paddingLeft: ((level - 1) * 30) + 'px',
      };
    }
  }

  createAgency() {
    this.$router.push('/settings/current/create-agency');
  }

  @Watch('currentCompany')
  companyChange(value) {
    // on current company change
    this.company = value;
  }

  async loadAgencyStructure() {
    this.loading = true;
    this.serverErrors = [];
    this.items = [];
    try {
      const result = await ProfileAgencyApi.getAgencyStructure(this.routeCompanyId, 0, '', '', false);
      let sortedHierarchy: Customer[] = [];
      this.prepareHierarchy(result.data.results, sortedHierarchy, 0, null);
      this.items = sortedHierarchy;
      this.loading = false;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async loadAvailableAgencies() {
    this.loading = true;
    this.serverErrors = [];
    this.items = [];
    try {
    const result = await ProfileAgencyApi.getAvailableAgencies(this.routeCompanyId);
      this.loading = false;
      this.agencySelectOptions = result.data;
      this.findAgencyAssigned('');
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  prepareHierarchy(arrayToSort, sortedArray, currentLevel, companyParentId) {
    if (arrayToSort && sortedArray) {
      let currentLevelItems = arrayToSort.filter(function (item) {
        if (!companyParentId) {
          return !item.companyParentId;
        } else {
          return item.companyParentId === companyParentId;
        }
      });
      if (currentLevelItems.length > 0) {
        currentLevelItems = this.sortByName(currentLevelItems);
        currentLevelItems.forEach(element => {
          element.level = currentLevel;
          sortedArray.push(element);
          this.prepareHierarchy(arrayToSort, sortedArray, currentLevel + 1, element.companyId);
        });
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort((a, b) => a.companyName.localeCompare(b.companyName));
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  findAgencyAssigned(query) {
    query = query.toLowerCase();
    if (this.agencySelectOptions) {
      const response = this.agencySelectOptions.filter((agency: any) => agency.value.toLowerCase().includes(query));

      this.filterAgencyList = [];

      if (response) {
        response.forEach((element) => {
          this.filterAgencyList.push(element);
        });
      } else {
        this.filterAgencyList = this.agencySelectOptions;
      }
    }
  }

  toggleConfigurationMenu(item) {
    if (this.configuration !== item) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  hideActivateUnitPopup() {
    this.activateUnitPopup = false;
    this.canEnableLoading = false;
    this.canEnableError = [];
    this.hasSubUnits = false;
  }

  hideDeactivateUnitPopup() {
    this.deactivateUnitPopup = false;
    this.canDisableLoading = false;
    this.canDisableError = [];
    this.hasSubUnits = false;
  }

  hideDeleteUnitPopup() {
    this.deleteUnitPopup = false;
    this.canDeleteLoading = false;
    this.canDeleteError = [];
    this.hasSubUnits = false;
  }

  hideMenu() {
    this.menuActive = false;
  }

  deactivateUnit(item) {
    this.hideMenu();
    this.deactivateUnitPopup = true;
  }

  activateUnit(item) {
    this.hideMenu();
    this.activateUnitPopup = true;
  }

  deleteUnit(item) {
    this.hideMenu();
    this.deleteUnitPopup = true;
  }

  async rowClicked(item) {
    router.push({
      name: 'company-info',
      params: {
        id: item.companyId,
      },
    });
  }

  async agencyClicked(item) {
    router.push({
      name: 'company-info',
      params: {
        id: item.agencyId,
      },
    });
  }

  @Watch('activateUnitPopup')
  async onActivateUnitPopupShow(value) {
    this.canEnableError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canEnableLoading = true;
    this.cancelToken = axios.CancelToken.source();
    
    try {
      const response = await ProfileCompanyApi.getCanEnableCompanyUnit(
        this.configuration!.companyId,
        Permission.WriteCompanyStructure,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canEnableLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canEnableError = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('deactivateUnitPopup')
  async onDeactivateUnitPopupShow(value) {
    this.canDisableError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canDisableLoading = true;
    this.cancelToken = axios.CancelToken.source();
    
    try {
      const response = await ProfileCompanyApi.getCanDisableCompanyUnit(
        this.configuration!.companyId,
        Permission.WriteCompanyStructure,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canDisableLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canDisableError = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('deleteUnitPopup')
  async onDeleteUnitPopupShow(value) {
    this.canDeleteError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canDeleteLoading = true;
    this.cancelToken = axios.CancelToken.source();

    try {
      const response = await ProfileCompanyApi.getCanDeleteCompanyUnit(
        this.configuration!.companyId,
        Permission.CanDeleteCompany,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canDeleteLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canDeleteError = this.$handleErrors(error, true);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'queryCustomersInProgress',
  })
  submitCustomersQuery() {
    this.Queries.customer = this.Form.searchCustomers;
    this.$nextTick(() => {
      this.queryCustomersInProgress = false;
    });
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'queryAgencyInProgress',
  })
  submitAgencyQuery() {
    this.Queries.agency = this.Form.searchAgency;
    this.$nextTick(() => {
      this.queryAgencyInProgress = false;
    });
  }

  @Watch('Form.searchCustomers')
  onSearchCustomersChange() {
    this.submitCustomersQuery();
  }

  @Watch('Form.searchAgency')
  onSearchAgencyChange() {
    this.submitAgencyQuery();
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange(route) {
    this.routeCompanyId = route.params.id ? route.params.id : this.currentCompany!.companyId;
    this.loadAgencyStructure();
    this.loadAvailableAgencies();
  }

  async changeStatus(deactivate: boolean) {
    this.deactivateInProgress = true;
    try {
      let message = new CompanyChangeStatusMessage();
      message.deactivate = deactivate;
      await ProfileCompanyApi.changeCompanyStatus(
        message,
        this.configuration!.companyId,
        Permission.WriteCompanyInfo
      );

      await Promise.all([
        this.loadAgencyStructure(),
        this.loadAvailableAgencies(),
      ]);
      this.hideDeactivateUnitPopup();
      this.activateUnitPopup = false;
    } catch (error) {
      this.popupError = this.$handleErrors(error, true);
    } finally {
      this.deactivateInProgress = false;
    }
  }

  async deleteCompanyUnit() {
    this.deleteInProgress = true;
    try {
      await ProfileCompanyApi.deleteCompanyUnit(
        this.configuration!.companyId,
        Permission.CanDeleteCompany
      );

      await Promise.all([
        this.loadAgencyStructure(),
        this.loadAvailableAgencies(),
      ]);
      this.hideDeleteUnitPopup();
    } catch (error) {
      this.popupError = this.$handleErrors(error, true);
    } finally {
      this.deleteInProgress = false;
    }
  }
}
