import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { Currency, ExchangedPrice } from './currency.model';


class CurrencyApiClass {
  public getCurrencies(): Promise<AxiosResponse<Array<Currency>>> {
    return http.execute({
      path: settings.apiCurrency + '/currencies',
      method: RequestMethod.GET
    }, {}, {});
  }

  public getExchangedPrice({sourceCurrency, sourcePrice, targetCurrency, exchangeDate}): Promise<AxiosResponse<ExchangedPrice>> {
    return http.execute({
      path: settings.apiCurrency + '/prices/{sourceCurrency}/{sourcePrice}?targetCurrency={targetCurrency}&exchangeDate={exchangeDate}',
      method: RequestMethod.GET
    }, {sourceCurrency, sourcePrice, targetCurrency, exchangeDate}, {});
  }
}

export const CurrencyApi: CurrencyApiClass = new CurrencyApiClass();
