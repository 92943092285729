
export interface SlotTransition {
  name: AnimationName;
  mode: AnimationMode;
  duration: number;
  background?: string;
}

export interface SlotMeta {
  height: number;
  width?: number;
  empty: boolean;
  component: string;
  fullscreen: boolean;
  top?: number;
}

export enum SlotState {
  INITIAL = '',           // may go to `empty` or `shown` immediately, this
                          // (depends on `shouldAnimateOnStart` store flag)
  EMPTY = 'empty',        // possible changes: `showing`
  SHOWING = 'showing',    // possible changes: `shown`, `swapping`, `hiding`,
  SHOWN = 'shown',        // possible changes: `hiding`, `swapping`
  SWAPPING = 'swapping',  // possible changes: `shown`, `showing`, `hiding`
  HIDING = 'hiding',      // possible changes: `empty`, `showing`,
}

export enum AnimationName {
  NONE = 'none',
  RIGHT = 'wm-animation-right',
  LEFT = 'wm-animation-left',
  UP = 'wm-animation-up',
  DOWN = 'wm-animation-down',
  FADE = 'wm-animation-fade',
  FADE_UP = 'wm-animation-fade-up',
  FADE_DOWN = 'wm-animation-fade-down',
}

export enum AnimationMode {
  DEFAULT = '',
  OUT_IN = 'out-in',
  IN_OUT = 'in-out',
}
