import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { ExpenseConfigurationResponse } from './expense-configuration.model';

class ExpenseConfigurationApiClass {
  public createExpensePolicyConfiguration(params, permission): Promise<AxiosResponse<ExpenseConfigurationResponse>> {
    return http.execute({
      path: settings.apiProfile + '/expensePolicy/configurations',
      permission: permission,
      method: RequestMethod.POST,
    }, { permission }, params );
  }

  public updateExpensePolicyConfiguration(configurationId, permission, params): Promise<AxiosResponse<ExpenseConfigurationResponse>> {
    return http.execute({
      path: settings.apiProfile + '/expensePolicy/configurations/{configurationId}',
      permission: permission,
      method: RequestMethod.PUT,
    }, { configurationId, permission }, params );
  }
}

export const ExpenseConfigurationsApi: ExpenseConfigurationApiClass = new ExpenseConfigurationApiClass();
