import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  BasketItemsRequest,
  BasketResult,
  BasketModel,
  BasketItemModel,
  BasketStatusModel,
  CommissionModel,
  SelectBasketPaymentCardsMessage,
  SelectBasketPaymentCardMessage,
  BasketPaymentCardDetailsResponse,
  SelectedSeatsMessage,
  SelectedAncillariesMessage,
  GuestTravellersMessage,
  GuestTravellerMessage,
  BasketMetadataResponse,
  LatestBasketModel,
  BasketFlatItemModel,
  PaymentMethodPerBasketItem,
  TravellerDateOfBirthObligation,
  TravelPolicyComplianceResult,
  TravellerDocumentsModel,
} from './basket.model';
import { Permission } from '@/const/permission.enum';

class BasketApiClass {
  public createBasket(params: BasketItemsRequest): Promise<AxiosResponse<BasketResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/items',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getBasket(basketId): Promise<AxiosResponse<BasketModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public getBasketItems(basketId): Promise<AxiosResponse<BasketItemModel[]>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public getTripItems(basketId): Promise<AxiosResponse<BasketFlatItemModel[]>> {
    return http.execute({
      path: settings.apiTrip + '/trips/{basketId}/items',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }
  
  public getBasketStatus(basketId): Promise<AxiosResponse<BasketStatusModel>> {
    return http.execute({
      path: settings.apiTrip + '/trips/{basketId}/status',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public confirmBasket(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/confirm',
      method: RequestMethod.POST,
    }, { basketId }, {});
  }

  public confirmHeldBasket(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/confirmHeld',
      method: RequestMethod.POST,
    }, { basketId }, {});
  }

  public holdBasket(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/hold',
      method: RequestMethod.POST,
    }, { basketId }, {});
  }

  public cancelBasket(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/cancel',
      method: RequestMethod.POST,
    }, { basketId }, {});
  }

  public rejectPrice(basketId, itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/rejectPriceChange',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, {});
  }

  public confirmPrice(basketId, itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/confirmPriceChange',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, {});
  }

  public retryBooking(basketId, itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/retry',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, {});
  }

  public deleteItemFromBasket(basketId, itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}',
      method: RequestMethod.DELETE,
    }, { basketId, itemId }, {});
  }

  public retryBookingItem(itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/retry',
      method: RequestMethod.PUT,
    }, { itemId }, {});
  }

  public skipBooking(basketId, itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/skip',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, {});
  }

  public skipBookingItem(itemId) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/skip',
      method: RequestMethod.PUT,
    }, { itemId }, {});
  }

  public sendTripItemErrorAnswer(actionCode: string, tripItemId: string, selectedAnswer): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/actions/{actionCode}/answer?tripItemId={tripItemId}',
      method: RequestMethod.PUT,
    }, { actionCode, tripItemId }, { selectedAnswer });
  }

  public sendTripItemAvailableResponseAnswer(actionCode: string, tripItemId: string, selectedAnswer): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/actions/{actionCode}/answer?tripItemId={tripItemId}',
      method: RequestMethod.PUT,
    }, { actionCode, tripItemId }, selectedAnswer);
  }

  public setCommission(basketId, param): Promise<AxiosResponse<CommissionModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/commission',
      method: RequestMethod.PUT,
    }, { basketId }, param);
  }

  public setBasketItemPaymentCard(basketId, itemId, param: SelectBasketPaymentCardMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/paymentCard',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, param);
  }

  public setBasketPaymentCards(basketId, param: SelectBasketPaymentCardsMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/paymentCards',
      method: RequestMethod.PUT,
    }, { basketId }, param);
  }

  public getAvailablePaymentCardsForBasketItem(basketId, itemId): Promise<AxiosResponse<BasketPaymentCardDetailsResponse>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/paymentCards',
      method: RequestMethod.GET,
    }, { basketId, itemId }, {});
  }

  public changeBasketPaymentMethod(basketId, itemId, param): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/change-payment-method',
      method: RequestMethod.PUT,
      permission: Permission.CanChangePaymentMethodForHeldBooking,
    }, { basketId, itemId }, param);
  }

  public getPreBookingData(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/pre-booking-data',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public getItemCancellationPolicy(itemId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/cancellation-conditions',
      method: RequestMethod.GET,
    }, { itemId } , {});
  }

  public getTravelPolicyCompliance(tripId: string): Promise<AxiosResponse<TravelPolicyComplianceResult>> {
    return http.execute({
      path: settings.apiTrip + '/trips/{tripId}/recalculate-pre-booking-info',
      method: RequestMethod.POST,
    }, { tripId } , {});
  }

  public getTravelPolicyComplianceForTripItem(tripId: string, tripItemId: string): Promise<AxiosResponse<TravelPolicyComplianceResult>> {
    return http.execute({
      path: settings.apiTrip + '/trips/{tripId}/recalculate-pre-booking-info?itemId={tripItemId}',
      method: RequestMethod.POST,
    }, { tripId, tripItemId } , {});
  }

  public getApprovalAndAccessData(tripId: string):  Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{tripId}/get-approval-and-access-data',
      method: RequestMethod.GET,
    }, { tripId } , {});
  }

  public getTrainCancellationPolicy(itemId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/rail-items/{itemId}/cancellation-conditions',
      method: RequestMethod.GET,
    }, { itemId } , {});
  }

  public confirmItemCancel(itemId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/cancel',
      method: RequestMethod.POST,
    }, { itemId } , {});
  }

  public getTrainTrenitaliaCancellationPolicy(itemId: string, useTechnicalCancellation: boolean) {
    return http.execute({
      path: settings.apiTrip + '/api/rail-items/{itemId}/cancellation-conditions?useTechnicalCancellation={useTechnicalCancellation}',
      method: RequestMethod.GET,
    }, { itemId, useTechnicalCancellation } , {});
  }

  public confirmTrenitaliaItemCancel(itemId: string, useTechnicalCancellation: boolean | null = null) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/cancel',
      method: RequestMethod.POST,
    }, { itemId } , {
      useTechnicalCancellation,
    });
  }

  public resetWizardSteps(basketId) {
    return http.execute({
      path: settings.apiTrip + '/wizards/{basketId}/steps/reset',
      method: RequestMethod.PUT,
    }, { basketId } , {});
  }

  public retrieveWizardSteps(basketId: string) {
    return http.execute({
      path: settings.apiTrip + '/wizards/{basketId}/steps',
      method: RequestMethod.GET,
    }, { basketId } , {});
  }

  public getSeatsMap(itemId: string, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/seat-maps',
      method: RequestMethod.GET,
    }, { itemId } , {}, false, cancelTokenSource);
  }

  public getExchangeOfferSeatsMap(itemId: string, exchangeOfferId: string, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/exchange-offer-seat-maps/{exchangeOfferId}',
      method: RequestMethod.GET,
    }, {
      itemId, 
      exchangeOfferId 
    }, {}, false, cancelTokenSource);
  }

  public setSeats(itemId: string, param: SelectedSeatsMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/seats',
      method: RequestMethod.PUT,
    }, { itemId } , param);
  }

  public setExchangeOfferSeats(itemId: string, exchangeOfferId: string, param: SelectedSeatsMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/exchange-offer-seats/{exchangeOfferId}',
      method: RequestMethod.PUT,
    }, { 
      itemId,
      exchangeOfferId
    }, param);
  }

  public getRailSeatMap(itemId: string, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/rail-items/{itemId}/seat-maps',
      method: RequestMethod.GET,
    }, { itemId }, {}, false, cancelTokenSource);
  }

  public setRailSeats(itemId: string, param: any) {
    return http.execute({
      path: settings.apiTrip + '/api/rail-items/{itemId}/seats',
      method: RequestMethod.PUT,
    }, { itemId }, param);
  }

  public getAncillaries(itemId: string, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/ancillaries',
      method: RequestMethod.GET,
    }, { itemId } , {}, false, cancelTokenSource);
  }

  public getExchangeOfferAncillaries(itemId: string, exchangeOfferId: string, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/exchange-offer-ancillaries/{exchangeOfferId}',
      method: RequestMethod.GET,
    }, {
      itemId, 
      exchangeOfferId 
    }, {}, false, cancelTokenSource);
  }

  public setAncillaries(itemId: string, param: SelectedAncillariesMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/ancillaries',
      method: RequestMethod.PUT,
    }, { itemId } , param);
  }

  public setExchangeOfferAncillaries(itemId: string, exchangeOfferId: string, param: SelectedAncillariesMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/exchange-offer-ancillaries/{exchangeOfferId}',
      method: RequestMethod.PUT,
    }, { 
      itemId,
      exchangeOfferId  
    }, param);
  }

  public updateAgencyMarkup(basketId, tripItemId, agencyMarkup) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/{tripItemId}/agency-markup',
      method: RequestMethod.PUT,
    }, {basketId, tripItemId}, agencyMarkup);
  }
  
  public canApprove(basketId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/approval/can-approve',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public saveTraveller(params: GuestTravellerMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{tripId}/traveller',
      method: RequestMethod.POST,
    }, { tripId: params.tripId }, { ...params.traveller }, true);
  }

  public getBookingCustomFields(basketId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/custom-fields',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public saveBookingCustomFields(basketId: string, data: any[]) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/custom-fields',
      method: RequestMethod.PUT,
    }, { basketId }, data, true);
  }

  public saveTravellers(params: GuestTravellersMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{tripId}/travellers',
      method: RequestMethod.PUT,
    }, { tripId: params.tripId }, { travellerInfo: params.travellerInfo }, true);
  }

  public saveTripName(tripId: string, name: string | null) {
    return http.execute({
      path: settings.apiTrip + '/trips/{tripId}/name',
      method: RequestMethod.PUT,
    }, { tripId }, { name });
  }

  public bookExtras(itemId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/book-air-extras',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public getBasketMetadata(basketId: string): Promise<AxiosResponse<BasketMetadataResponse>>  {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/metadata',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }

  public getLatestBasket(status?: string): Promise<AxiosResponse<LatestBasketModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/last?status={status}',
      method: RequestMethod.GET,
    }, { status }, {});
  }

  public saveLoyaltyCards(itemId: string, items: any[]) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/loyalty-cards',
      method: RequestMethod.POST,
    }, { itemId }, items, true);
  }

  public saveBasketTravellersData(tripId: string, params: any) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{tripId}/travellers',
      method: RequestMethod.PUT,
    }, { tripId }, params);
  }

  public recalculateFeeForBasketItem(basketId: string, itemId: string, selectedPaymentMethods: PaymentMethodPerBasketItem[]) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{itemId}/paymentCard',
      method: RequestMethod.PUT,
    }, { basketId, itemId }, { selectedPaymentMethods });
  }

  public getBasketTravellerDateOfBirth(tripId: string): Promise<AxiosResponse<TravellerDateOfBirthObligation[]>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{tripId}/travellers/dob-obligation',
      method: RequestMethod.GET,
    }, { tripId }, {});
  }

  public getBasketTravellerDocuments(basketId: string): Promise<AxiosResponse<TravellerDocumentsModel[]>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/traveller-documents',
      method: RequestMethod.GET,
    }, { basketId }, {});
  }
}

export const BasketApi: BasketApiClass = new BasketApiClass();