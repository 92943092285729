import CarProviderConfigurationDetail from '@/modules/settings/configurations/CarProviderConfigurationDetail.vue';

export default {
  path: ':configurationId',
  name: 'car-provider-configuration',
  component: CarProviderConfigurationDetail,
  displayName: 'Car provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCarProviders',
    depthCategory: 'car-providers',
    depth: 2,
    parentName: 'car-provider-configurations',
  },
};