





















import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import ExpenseStore from '../expense.store';

@Component({
  components: {}
})
export default class ExpenseListMessages extends Vue {
  missionId: string = '';
  selectedMission: any = null;  
  
  get missionNamesList() {
    return ExpenseStore.missionNamesList;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  goToMission() {
    if (this.selectedMission) {
        router.push({
        name: 'modify-mission',
        params: {
          missionId: this.selectedMission.id,
        },  
      });
    }
  }

  async initView() {
    this.missionId = this.$route.params.missionId;

    if (this.missionId) {
      const request = {
        expenseAssignmentAllowed: false,
        policyId: this.policyId
      };
      await ExpenseStore.getMissionsNames(request);
      this.selectedMission = this.missionNamesList.find(missionName => { return missionName.id === this.missionId; })!;
    }
  }

  @Watch('$route.params.missionId', { immediate: true })
  routeHandler() {
    this.initView();
  }

}
