import AddMission from '@/modules/expense/my-missions/AddMission.vue';
import ExpenseSubmenu from '@/modules/expense/ExpenseSubmenu.vue';

export default {
  path: '/expenses/missions/:missionId',
  name: 'modify-mission',
  components: {
    default: AddMission,
    submenu: ExpenseSubmenu,
  },
  meta: {
    permission: 'WriteMissionDefinition',
  },
};
