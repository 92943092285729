















































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import ExpensePerDiemStore from './expense-per-diem.store';
import { router } from '@/router';
import PerDiemRates from './PerDiemRates.vue';
import ReductionReasonPerDiemRules from './ReductionReasonPerDiemRules.vue';
import LengthOfStayPerDiemRules from './LengthOfStayPerDiemRules.vue';

@Component({
  components: {
    PerDiemRates,
    ReductionReasonPerDiemRules,
    LengthOfStayPerDiemRules,
  }
})
export default class ExpensePerDiemsSettings extends Vue {
  $v;

  @Validation()
  validationObject() {
    return {
      perDiemSettings: {
        defaultRate: {
          required
        },
      },
    };
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get errors() {
    return ExpensePerDiemStore.errMessages;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get perDiemSettings() {
    return ExpensePerDiemStore.perDiemSettings;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get activeTab() {
    return ExpensePerDiemStore.perDiemActiveTab;
  }

  get rulesOrder() {
    return this.perDiemSettings.rulesOrder === 'LengthOfStayFirst';
  }

  set rulesOrder(value) {
    if (value) {
      this.perDiemSettings.rulesOrder = 'LengthOfStayFirst';
    } else {
      this.perDiemSettings.rulesOrder = 'ReductionReasonFirst';
    }
  }

  setPerDiemActiveTab(value) {
    ExpensePerDiemStore.setPerDiemActiveTab(value);
  }

  returnToConfigurations() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'expense-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  savePerDiem() {
    this.$v.perDiemSettings.$touch();
    if (this.$v.perDiemSettings.$pending || this.$v.perDiemSettings.$error) {
      return;
    }
    ExpensePerDiemStore.savePerDiemSettings({
      policyId: this.policyId,
      params: this.perDiemSettings
    });
  }

  created() {
    ExpensePerDiemStore.clearErrMessages();
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.configuration.name) {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      ExpensePolicyStore.getExpensePolicyDetails(this.policyId);
      ExpensePerDiemStore.getPerDiemSettings(this.policyId);
    }
  }

}
