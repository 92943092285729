import ExpensePolicyConfigurations from '@/modules/settings/expense-configuration/ExpensePolicyConfigurations.vue';

export default {
  path: '',
  name: 'expense-configurations',
  component: ExpensePolicyConfigurations,
  displayName: 'Expense policy configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 1,
  }
};