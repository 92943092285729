































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { Validation } from 'vue-plugin-helper-decorator';
import _ from 'lodash';

import { router } from '@/router';
import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import { Debounce } from '@/core/decorators/debounce.decorator';
import { Permission } from '@/const/permission.enum';
import DebounceConst from '@/const/debounce.const';
import SettingsStore from '@/modules/settings/settings.store';
import ApprovalWorkflowStore from '@/modules/settings/approval-workflow/approval-workflow.store';
import {
  ServiceTypesConst,
  TravelPolicyResultTypeConst,
  ApproverSelectionTypeConst,
} from '@/const/approval-workflow.const';
import { ProfileApi } from '@/api/profile/profile.api';
import { ApproverModel } from '@/api/profile/profile.model';
import {
  ApproverSourceType,
  ApprovalWorkflowConfigurationLevel,
  RejectionReasonType,
} from '@/api/approval-workflow/approval-workflow.model';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

@Component({})
export default class ApprovalWorkflowConfiguration extends Vue {
  isReadOnly: boolean = false;
  options: ApproverModel[] = [];
  laterOptions: ApproverModel[] = [];
  isLoading: boolean = false;
  showRemovePopup: boolean = false;
  showDictionaryPopup: boolean = false;
  currentDictionaryValues: string[] = [];
  currentStringValue: string = '';
  currentLevel: ApprovalWorkflowConfigurationLevel | null = null;
  levelToDelete: number = -1;
  travellerCategoriesOptions: any[] = [];
  loadErrors: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  $v;
  levelsAddons: any[] = [{
    visible: true,
    options: [],
  }, {
    visible: true,
    options: [],
  }, {
    visible: true,
    options: [],
  }, {
    visible: true,
    options: [],
  }];
  rejectionReasonTypes = [
    {
      name: translate('settings-approval.free-text'),
      value: RejectionReasonType.FreeText,
    },
    {
      name: translate('settings-approval.value-from-dictionary'),
      value: RejectionReasonType.Dictionary,
    },
  ];

  get serviceTypes () {
    return ServiceTypesConst.map((service) => {
      return {
        ...service,
        name: translate(service.name)
      };
    });
  }

  get travelPolicyResultTypes () {
    return TravelPolicyResultTypeConst.map((policy) => {
      return {
        ...policy,
        name: translate(policy.name)
      };
    });
  }

  get approverSelectionType () {
    return ApproverSelectionTypeConst.map((approver) => {
      return {
        ...approver,
        name: translate(approver.name)
      };
    });
  }

  get approvalWorkflow() {
    return ApprovalWorkflowStore.approvalWorkflowConfiguration;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get loading() {
    return ApprovalWorkflowStore.loading;
  }

  get showError() {
    return ApprovalWorkflowStore.canShowError;
  }

  get copyVersion() {
    return ApprovalWorkflowStore.isCopy;
  }

  get errMessages() {
    return ApprovalWorkflowStore.getErrMessages;
  }

  get canShowCustomError() {
    return ApprovalWorkflowStore.canShowCustomError;
  }

  get isMaxCurrentDictionaryValues() {
    return this.currentDictionaryValues.length >= 100;
  }

  userFullName(user) {
    return userFullName(user);
  }

  toggleLevel(index) {
    this.levelsAddons[index].visible = !this.levelsAddons[index].visible;
  }

  getTravelPolicyResultType(level) {
    if (!level.isTravelPolicyDependant) {
      return null;
    }

    const result = this.travelPolicyResultTypes
      .find(item => item.value === level.travelPolicyResultType) || null;
    
    return result;
  }
  
  setTravelPolicyResultType(value, level) {
    if (!value) {
      level.travelPolicyResultType = null;
      return;
    }
    level.travelPolicyResultType = value.value;
  }

  getLevelServiceTypes(level) {
    return this.serviceTypes
      .filter(item => {
        return -1 < (level.serviceTypes || []).indexOf(item.value);
      });
  }

  setLevelServiceTypes($event, level) {
    level.serviceTypes = $event.map(item => item.value);
  }

  showConfirmRemoval(level) {
    this.levelToDelete = level;
    this.showRemovePopup = true;
  }

  confirmRemove() {
    this.approvalWorkflow.configurationLevels.splice(this.levelToDelete, 1);
    for (let index = this.levelToDelete; index < this.approvalWorkflow.configurationLevels.length; index++) {
      this.levelsAddons[index].visible = this.levelsAddons[index + 1].visible;
      this.approvalWorkflow.configurationLevels[index].approvalLevel = index + 1;
    }
    this.showRemovePopup = false;
  }

  addNextLevel() {
    const level = new ApprovalWorkflowConfigurationLevel();
    level.approvalLevel = this.approvalWorkflow.configurationLevels.length + 1;
    this.approvalWorkflow.configurationLevels.push(level);
    this.levelsAddons[level.approvalLevel - 1].visible = true;
  }

  maper(obj, data) {
    if (obj && data) {
      return obj.filter(element => element.value === data.length);
    }
  }

  levelTravellerCategories(level) {
    return level.travellerCategories.map((category) => {
      return {
        value: category,
        label: category,
      };
    });
  }

  setLevelTravellerCategories($event, level) {
    level.travellerCategories = $event.map(item => item.value);
  }

  onReasonRequiredChange(level, value) {
    if (!value) {
      level.rejectionReasonType = RejectionReasonType.NotRequired;
    } else {
      level.rejectionReasonType = RejectionReasonType.FreeText;
    }
  }

  getRejectionReasonType(level) {
    if (!level.rejectionReasonType) {
      return null;
    }

    const result = this.rejectionReasonTypes
      .find(item => item.value === level.rejectionReasonType) || null;
    
    return result;
  }
  
  setRejectionReasonType(value, level) {
    if (!value) {
      level.rejectionReasonType = null;
      return;
    }
    level.rejectionReasonType = value.value;
  }

  displayDictionary(level) {
    this.currentStringValue = '';
    this.currentLevel = level;
    this.currentDictionaryValues = [...level.rejectionReasonValues];
    this.showDictionaryPopup = true;
  }

  focusOnInput() {
    const input = this.$refs.currentStringValue as HTMLInputElement;
    if (!input) {
      return;
    }
    input.focus();
  }

  addStringToDictionary() {
    if (!this.currentStringValue) {
      return;
    }
    this.currentDictionaryValues.push(this.currentStringValue);
    this.currentStringValue = '';
    this.focusOnInput();
  }

  confirmAndClosePopup() {
    if (!this.currentLevel) {
      return;
    }
    this.currentLevel.rejectionReasonValues = this.currentDictionaryValues;
    this.showDictionaryPopup = false;
    this.currentLevel = null;
    this.currentDictionaryValues = [];
  }

  removeCurrentDictionaryItem(index) {
    this.currentDictionaryValues.splice(index, 1);
    this.focusOnInput();
  }

  addTag(newTag) {
    this.options.push(newTag);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async asyncFind(query: string, index: number) {
    this.isLoading = true;
    this.levelsAddons[index].options = [];
    let companyId = this.currentCompany!.companyId;
    const response = await ProfileApi.searchApprovers( companyId, query, Permission.ReadApprovalWorkflow );
    if (response && response.data) {
      response.data.forEach(approver => {
        approver.id = approver.profileId;
      });
      const configuration = this.approvalWorkflow.configurationLevels[index];
      let allApprovers: any = [];
      if (
        configuration.allowedApprovers &&
        configuration.allowedApprovers.length) {
        allApprovers = [...configuration.allowedApprovers, ...response.data];
      } else {
        allApprovers = response.data;
      }
      allApprovers = _.uniqBy(allApprovers, 'id');
      this.levelsAddons[index].options = allApprovers;
    }
    this.isLoading = false;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async asyncFindLater(query: string) {
    this.isLoading = true;
    this.laterOptions = [];
    let companyId = this.currentCompany!.companyId;
    const response = await ProfileApi.searchApprovers( companyId, query, Permission.ReadApprovalWorkflow );
    if (response && response.data) {
      response.data.forEach(approver => {
        approver.id = approver.profileId;
      });

      this.laterOptions = _.uniqBy(response.data, 'id');
    }
    this.isLoading = false;
  }

  @Validation()
  validationObject() {
    return {
      approvalWorkflow : {
        name: {
          required,
          maxLength: maxLength(64),
        },
        configurationLevels: {
          $each: {
            allowedApprovers: {
              required: requiredIf((item) => {
                return item.approverSourceType === ApproverSourceType.ApproversFromList;
              }),
            },
            travelPolicyResultType : {
              required: requiredIf((item) => {
                return item.isTravelPolicyDependant;
              }),
            },
            serviceTypes : {
              required: requiredIf((item) => {
                return item.isServiceTypeDependant;
              }),
            },
            rejectionReasonType : {
              required: requiredIf((item) => {
                return item.isRejectionReasonRequired;
              }),
            },
            travellerCategories : {
              required: requiredIf((item) => {
                return item.isTravellerCategoriesDependant;
              }),
            },
          },
        },
        
      },
    };
  }

  setApproverSourceType(index) {
    const levels = this.approvalWorkflow.configurationLevels;
    const level = levels[index];
    if (level.approverSourceType === ApproverSourceType.ApproversFromList) {
      return;
    }
    level.allowedApprovers = [];
  }

  prepareRequest() {
    return {
      name: this.approvalWorkflow.name,
      configurationLevels: this.approvalWorkflow.configurationLevels,
      handlingNonRefundableOffers: this.approvalWorkflow.handlingNonRefundableOffers,
      isApprovalEnabledInPostSales: this.approvalWorkflow.isApprovalEnabledInPostSales,
    };
  }

  async saveApprovalWorkflow() {
    this.$v.approvalWorkflow.$touch();
    if (this.$v.approvalWorkflow.$pending || this.$v.approvalWorkflow.$error) {
      this.approvalWorkflow.configurationLevels.forEach((item, index) => {
        const validation = this.$v.approvalWorkflow.configurationLevels.$each[index];

        if (validation.$error) {
          this.levelsAddons[index].visible = true;
        } else {
          this.levelsAddons[index].visible = false;
        }
      });
      return;
    }

    let request = this.prepareRequest();

    ApprovalWorkflowStore.setCopy(false);
    if (this.currentCompany) {
      try {
        let rootCompanyId = this.currentCompany.rootCompanyId;
        let params = {
          rootCompanyId,
          ...request
        };
        if (this.currentConfigurationId) {
          let id = this.currentConfigurationId;
          await ApprovalWorkflowStore.updateApprovalWorkflowConfiguration({id, params});
        } else {
          await ApprovalWorkflowStore.addApprovalWorkflowConfiguration({params});
        }
        this.returnToConfigurations();
      } catch (error) {

      }
    }
  }

  getAnyAllowedApproverLabel(level: ApprovalWorkflowConfigurationLevel) {
    switch (level.approverSourceType) {
      case 'AllApproversFromRootCompany': return this.$t('settings-approval.any-approver-from-root-company');
      case 'ApproversFromList': return this.$t('settings-approval.any-approver-selected-in-configuration');
      case 'TravellerDesignatedApprover': return this.$t('settings-approval.any-approver-selected-in-configuration');
      default: return '';
    }
  }

  travelPolicyEnabled(level, val) {
    if (val) {
      return;
    }
    this.approvalWorkflow.configurationLevels[level].travelPolicyResultType = null;
  }

  travellerCategoriesEnabled(level, val) {
    if (val) {
      return;
    }
    this.approvalWorkflow.configurationLevels[level].travellerCategories = [];
  }

  serviceTypeEnabled(level, val) {
    if (val) {
      return;
    }
    this.approvalWorkflow.configurationLevels[level].serviceTypes = [];
  }

  returnToConfigurations() {
    router.push({
      name: 'approval-workflow-configurations'
    });
  }

  async created() {
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.rootCompanyId);

      this.travellerCategoriesOptions = response.data.map(e => {
        return {
          label: e.name,
          value: e.name,
        };
      });
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
      return;
    }
    this.isReadOnly = !!this.$route.query.readOnly;
    ApprovalWorkflowStore.setErrMessages([]);
    ApprovalWorkflowStore.setShowError(false);
    if (this.currentConfigurationId) {
      ApprovalWorkflowStore.setCopy(false);
      ApprovalWorkflowStore.getApprovalWorkflowConfiguration(this.currentConfigurationId);
    }
  }
}
