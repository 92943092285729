



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class NotificationStatus extends Vue {
  @Prop() item!: any;
  @Prop() currentItem!: any;

  get status() {
    if (!!this.currentItem) {
      if (this.currentItem.id === this.item.id) {
        return this.currentItem.hasBeenRead;
      }
    }
    return this.item.hasBeenRead;
  }
}

