




























import { Vue, Component } from 'vue-property-decorator';

import BasketTripComment from './BasketTripComment.vue';
import BasketTripCommentEdit from './BasketTripCommentEdit.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    BasketTripComment,
    BasketTripCommentEdit,
  }
})
export default class BasketTripCommentsView extends Vue {

  get canViewComments() {
    return this.$hasAccess('CanViewTripComments');
  }

  get canAddComments() {
    return this.$hasAccess('CanAddTripComments');
  }

  get comments() {
    return BasketStore.comments;
  }

  get errors() {
    return BasketStore.commentsErrors;
  }

  get loading() {
    return BasketStore.commentsLoading;
  }

  get hasNoErrors() {
    return this.errors.length === 0;
  }



  reloadItems() {
    BasketStore.loadComments();
  }

}

