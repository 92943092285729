import CompanyStructureManageConfiguration from '@/modules/settings/company-structure/CompanyStructureManageConfiguration.vue';

export default {
  path: ':configurationId?/manage-configuration',
  name: 'manage-configuration-assignment',
  component: CompanyStructureManageConfiguration,
  displayName: 'Manage configuration assignment',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyStructure',
    depthCategory: 'company-structure',
    depth: 2,
    parentName: 'company-structure',
  }
};