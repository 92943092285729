import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import SettingsStore from '@/modules/settings/settings.store';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { Permission } from '@/const/permission.enum';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import  { ProjectsApi } from '@/api/profile/projects.api';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'projectsStore'
})
class ProjectsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  projects: any[] = [];
  project: any = {};
  expenseDetails: any = {};
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  activeTab: string = 'fields';
  projectObject: any = {};
  projectConfigurationId: string = '';
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }
  
  get companyId() {
    return SettingsStore.currentCompany!.rootCompanyId;
  }

  get retrievProjectConfigurationId() {
    return this.project.id;
  }

  get Project() {
    return this.project;
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setName(value) {
    this.project.name = value;
  }

  @Mutation
  setProjects(value) {
    this.projects = value;
  }

  @Mutation
  setProject(value) {
    this.project = value;
  }

  @Mutation
  setProjectConfigurationId(value) {
    this.project.id = value;
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setProjectObject(payload) {
    this.projectObject = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async getProjectConfiguration(data) {
    this.setLoading(true);
    if (data.configurationId) {
      const result = await ProfileConfigurationsApi.getByRootCompanyId(data.companyId, companyConfigurationType.projectsConfiguration, 0, 1, Permission.ReadProjects);
      if (result && result.data) {
        const configuration = result.data.results.find((e) => {
          return e.id === data.configurationId;
        });
        if (configuration) {
          this.setProject(configuration);
        }
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async getProjectsList(configurationId) {
    try {
      this.setLoading(true);
      this.setCustomError(false);
      const result = await ProjectsApi.getProjectsList(configurationId);
      if (result && result.data) {
        this.setProjects(result.data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getProjectObject(projectId) {
    try {
      this.setLoading(true);
      const result = await ProjectsApi.getProjectObject(projectId);
      if (result && result.data) {
        this.setProjectObject(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createProjectConfiguration(params) {
    try {
      this.setLoading(true);
      const result = await ProjectsApi.createProjectConfiguration(
        params,
        Permission.WriteProjects,
      );
      if (result && result.data) {
        this.setProject(result.data);
      }
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-projects.configuration-saved'),
        message: translate(
          'settings-projects.project-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateProjectConfiguration(data) {
    try {
      this.setLoading(true);
      await ProjectsApi.updateProjectConfiguration(
        data.configurationId,
        { name: data.name },
        Permission.WriteProjects
      );
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-projects.configuration-updated'),
        message: translate(
          'settings-projects.project-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createProjects(data) {
    try {
      this.setLoading(true);
      const result = await ProjectsApi.createProjects(data.params, data.permission);
      if (result && result.data) {
        router.push({
          name: 'projects-configuration',
          params: {
            configurationId: this.retrievProjectConfigurationId
          }
        });
      }
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-projects.configuration-saved'),
        message: translate(
          'settings-projects.definition-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
      this.setLoading(false);
    }
  }

  @Action
  async updateProjects(data) {
    try {
      this.setLoading(true);
      await ProjectsApi.updateProjects(data.projectId, data.params, data.permission);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-projects.configuration-updated'),
        message: translate(
          'settings-projects.definition-updated-message'
        )
      });

      router.push({
        name: 'projects-configuration',
        params: {
          configurationId: this.retrievProjectConfigurationId
        }
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
      this.setLoading(false);
    }
  }

  @Action
  async copyProjectConfiguration(data) {
    try {
      this.setLoading(true);
      await ProjectsApi.copyProjectConfiguration(data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

}

export default getModule(ProjectsStore);
