export const RequestStatusList = [
  {
    value: 'New',
    name: 'common.new',
  },
  {
    value: 'InProgress',
    name: 'common.in-Progress',
  },
  {
    value: 'Closed',
    name: 'common.closed',
  },
];