






























































































































import { Vue, Component } from 'vue-property-decorator';
import _ from 'lodash';

import BasketStore from './basket.store';
import BasketProcessingItem from './BasketProcessingItem.vue';
import BasketErrorGroups from './BasketErrorGroups.vue';
import BasketPaymentMethodsView from './BasketPaymentMethodsView.vue';
import BasketSabreTicketingView from './BasketSabreTicketingView.vue';
import BasketLufthansaTicketingView from './BasketLufthansaTicketingView.vue';
import BasketAmadeusTicketingView from './BasketAmadeusTicketingView.vue';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AccountStore from '@/store/account.store';

@Component({
  components: {
    BasketProcessingItem,
    BasketErrorGroups,
    BasketPaymentMethodsView,
    BasketSabreTicketingView,
    BasketLufthansaTicketingView,
    BasketAmadeusTicketingView,
  },
})
export default class BasketProcessingView extends Vue {

  get errors() {
    return BasketStore.errors;
  }

  get exchangeError() {
    return AirSearchStore.errMessages;
  }

  isVirtualCardPayment(item) {
    if (item && item.object && item.object.tripItemPaymentInfo && Array.isArray(item.object.tripItemPaymentInfo) && item.object.tripItemPaymentInfo.length > 0)
      return item.object.tripItemPaymentInfo[0].paymentMethod === 'VirtualCard';
    else
      return false;
  }

  get noErrors() {
    return !this.errors.length && !this.exchangeError.length;
  }

  get shouldShowBasketSabreStatusView() {
    return BasketStore.sabreStatusConfirmationSection;
  }

  get shouldShowBasketLufthansaStatusView() {
    return BasketStore.lufthansaStatusConfirmationSection;
  }

  get shouldShowBasketAmadeusStatusView() {
    return BasketStore.amadeusStatusConfirmationSection;
  }

  get basketStatus() {
    return BasketStore.status;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get isRetryLoading() {
    return BasketStore.retry;
  }

  get basketStatusItems() {
    if (!this.basketStatus) {
      return [];
    }
    return this.basketStatus.items.map(item => {
      const object = this.basketItems.find(i => i.id === item.id);

      if (object && object.bookingErrors && object.bookingErrors.length) {
        let groups: any[] = [];
        const groupeErrors = _.groupBy(object.bookingErrors, 'errorLevel');

        for (let itemType in groupeErrors) {
          groups.push({
            type: itemType,
            items: groupeErrors[itemType],
          });
        }

        object.groupeErrors = groups;
      }

      return {
        ...item,
        object,
      };
    });
  }

  get showRetrySection() {
    return BasketStore.showRetrySection;
  }

  get guestsSaving() {
    return BasketStore.guestsSaving;
  }

  get guestsProfileSaving() {
    return BasketStore.guestsProfileSaving;
  }

  get user() {
    return AccountStore.current;
  }

  get isSabre() {
    let isSabre: boolean = false;
    if (this.basketStatus) {
      this.basketStatus.items.forEach(data => {
        const basketItem = this.basketItems.find(i => i.id === data.id);
        if (basketItem && basketItem.supplier === 'Sabre' && basketItem.type === 'Air') {
          isSabre = true;
        }
      });
    }

    return isSabre;
  }

  get shouldShowAdditionalSupportText() {
    return this.user!.profile.isAgency === false;
  }

  get shouldShowDesynchronize() {
    return AccountStore.HasPermission('CanMarkAsDesynchronized');
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  shouldShowSkipOperationTripText(item) {
    return this.basketItems.length && item && item.pendingActions &&
      item.pendingActions[0].code === 'BOOK_TRIP_ITEM_ERROR';
  }

  shouldShowDesynchronizeText(item) {
    return this.shouldShowDesynchronize &&
      this.basketItems.length &&
      item &&
      item.pendingActions &&
      !![
        'BOOK_TRIP_ITEM_ERROR',
        'TICKETING_FAILED',
        'BOOKING_ERROR',
      ].find(a => a === item.pendingActions[0].code);
  }

  isExtrasProviderError(item) {
    return this.basketItems.length && item && item.pendingActions &&
      (-1 < [
        'EXTRAS_PROVIDER_ERROR',
      ].indexOf(item.pendingActions[0].code));
  }

  shouldShowOperationFailedSection(item) {
    return this.basketItems.length && item && item.pendingActions &&
      (-1 < [
        'BOOK_TRIP_ITEM_ERROR',
        'BOOK_ITEM_ERROR',
        'PAYMENT_ERROR',
        'EXTRAS_PROVIDER_ERROR',
        'TICKETING_FAILED',
        'BOOKING_ERROR'
      ].indexOf(item.pendingActions[0].code));
  }

  shouldShowPaymentSection(item) {
    return this.basketItems.length &&
      item &&
      item.pendingActions &&
      (
        item.pendingActions[0].code === 'PAYMENT_ERROR' ||
        (
          -1 < [
            'TICKETING_FAILED',
            'BOOKING_ERROR',
          ].indexOf(item.pendingActions[0].code) &&
          this.isSabre
        )
      );
  }

  classesForItem(item) {
    return {
      'basket-processing-view__processing-item--has-errors': item.pendingActions && item.pendingActions.length && item.object && item.object.groupeErrors && this.showRetrySection,
    };
  }
}

