import AirProviderConfigurations from '@/modules/settings/configurations/AirProviderConfigurations.vue';

export default {
  path: '',
  name: 'air-provider-configurations',
  component: AirProviderConfigurations,
  displayName: 'Flight providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadFlightProviders',
    depthCategory: 'air-providers',
    depth: 1,
    parentName: 'air-provider-configurations',
  },
};