import CarResults from '@/modules/search/car/CarResults.vue';
import CarSubintro from '@/modules/search/car/CarSubintro.vue';
import CarResultsFilters from '@/modules/search/car/CarResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';

export default {
  path: '/carResultsTravellers/:searchId',
  name: 'carTravellers',
  components: {
    default: CarResults,
    intro: SearchTravellersIntro,
    subintro: CarSubintro,
    sidebar: CarResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-car-results',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        car: AnimationName.UP,
      },
    }
  }
};
