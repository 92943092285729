var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('svg',{attrs:{"width":_vm.width,"height":_vm.height,"viewBox":_vm.viewBox}},[_c('g',{attrs:{"transform":_vm.transf}},[_c('path',{staticClass:"rail-seat-map__train-seat-icon-detail",class:{
        'unavailable': _vm.item.seatAvailability === 'Reserved',
        'restricted': _vm.item.seatAvailability === 'Restricted',
      },attrs:{"d":_vm.seatPath}}),_c('path',{staticClass:"rail-seat-map__train-seat-icon-detail",class:{
        'unavailable': _vm.item.seatAvailability === 'Reserved',
        'restricted': _vm.item.seatAvailability === 'Restricted',
      },attrs:{"d":_vm.leftArmPath}}),_c('path',{staticClass:"rail-seat-map__train-seat-icon-detail",class:{
        'unavailable': _vm.item.seatAvailability === 'Reserved',
        'restricted': _vm.item.seatAvailability === 'Restricted',
      },attrs:{"d":_vm.rightArmPath}}),_c('path',{staticClass:"rail-seat-map__train-seat-icon-detail",class:{
        'unavailable': _vm.item.seatAvailability === 'Reserved',
        'restricted': _vm.item.seatAvailability === 'Restricted',
      },attrs:{"d":_vm.backPath}}),(_vm.item.seatAvailability === 'Restricted')?_c('line',{staticClass:"rail-seat-map__train-seat-icon-restricted",attrs:{"x1":-_vm.width / 2 + 1.7 * _vm.margin,"y1":-_vm.height / 2 + 1.7 * _vm.margin,"x2":_vm.width / 2 - 1.7 * _vm.margin,"y2":_vm.height / 2 - 1.7 * _vm.margin - 5}}):_vm._e(),(_vm.item.seatAvailability === 'Restricted')?_c('line',{staticClass:"rail-seat-map__train-seat-icon-restricted",attrs:{"x1":-_vm.width / 2 + 1.7 * _vm.margin,"y1":_vm.height / 2 - 1.7 * _vm.margin - 5,"x2":_vm.width / 2 - 1.7 * _vm.margin,"y2":-_vm.height / 2 + 1.7 * _vm.margin}}):_vm._e(),(_vm.chargable)?_c('svg',{staticClass:"air-seat-map__seat-rect-extra-paid-icon",attrs:{"width":"19","height":"19","viewBox":"0 0 24 24","x":-9.5,"y":-11}},[_c('path',{staticClass:"air-seat-map__extra-paid-icon",attrs:{"d":"M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"}})]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }