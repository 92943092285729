import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  ProjectModel,
  ConfigurationModel,
} from './projects.model';

class ProjectsApiClass {
  public getProjectsList(configurationId) {
    return http.execute({
      path: settings.apiProfile + '/projects?configurationId={configurationId}',
      method: RequestMethod.GET
    }, {
      configurationId,
    }, {});
  }

  public createProjectConfiguration(params: ConfigurationModel, permission) {
    return http.execute({
      path: settings.apiProfile + '/projects/configurations',
      permission: permission,
      method: RequestMethod.POST
    }, { permission }, params );
  }

  public updateProjectConfiguration(configurationId, params, permission) {
    return http.execute({
      path: settings.apiProfile + '/projects/configurations/{configurationId}',
      permission: permission,
      method: RequestMethod.PUT
    }, { configurationId, permission }, params );
  }

  public getProjectObject(projectId) {
    return http.execute({
      path: settings.apiProfile + '/projects/{projectId}',
      method: RequestMethod.GET
    }, { projectId }, {});
  }


  public createProjects(params: ProjectModel, permission) {
    return http.execute({
      path: settings.apiProfile + '/projects',
      permission: permission,
      method: RequestMethod.POST
    }, { permission }, params);
  }

  public updateProjects(projectId, params: ProjectModel, permission) {
    return http.execute({
      path: settings.apiProfile + '/projects/{projectId}',
      permission: permission,
      method: RequestMethod.PUT
    }, { projectId, permission }, params);
  }

  public copyProjectConfiguration(message) {
    return http.execute({
      path: settings.apiProfile + '/api/configurations/{configurationId}/projects/copy',
      method: RequestMethod.POST,
    }, { configurationId: message.configurationId }, { configurationName: message.configurationName });
  }

  public deleteProject(projectId, permission: string) {
    return http.execute({
      path: settings.apiProfile + '/projects/{projectId}',
      permission: permission,
      method: RequestMethod.DELETE
    }, { projectId }, { permission });
  }

}

export const ProjectsApi: ProjectsApiClass = new ProjectsApiClass();
