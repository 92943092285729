import Security from '@/modules/settings/security/Security.vue';
import SecurityList from './security-configurations.route';
import SecurityConfig from './security-configuration.route';

export default {
  path: 'security-configurations',
  component: Security,
  displayName: 'settings-sidebar.security',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditSecurityConfiguration',
    icon: 'security',
    breadCrumb: 'Security configuration',
    parentName: 'security-configurations',
  },
  children: [
    SecurityList,
    SecurityConfig,
  ],
};