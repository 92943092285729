import CustomFieldExpense from '@/modules/settings/expense-configuration/CustomFieldExpense.vue';

export default {
  path: ':configurationId?/custom-filed/:itemId?/:expenseExtensionId?/:isCopy?',
  name: 'custom-field-expense',
  component: CustomFieldExpense,
  displayName: 'Custom fields',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};