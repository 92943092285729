









































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import {
  AvailableDateFormats,
  AvailableTimeFormats,
} from '@/api/profile/company.model';
import { translate, translationExists } from '@/i18n';

const checkIfJSON = str => {
  let err: any = null;
  
  if (str === null) {
    return false;
  }

  if (!isNaN(str)) {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (error) {
    err = error;
  }
  if (!err) {
    return true;
  }

  return false;
};

const checkIfObject = value => {
  return typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null;
};

@Component({})
export default class BasketTripHistoryItemChanges extends Vue {
  @Prop() item!: any;
  @Prop() index!: number;



  get changeParameters() {
    const sortByValue = (a, b) => {
      if (a.value < b.value) {
        return -1;
      } else if (a.value > b.value) {
        return 1;
      }

      return 0;
    };

    return this.item.changeParameters
      .filter(change => {
        return change.fieldName !== 'TripItems.TripItemStatusTag';
      })
      .map(change => {
        let valueFrom = change.valueFrom;
        let valueTo = change.valueTo;
        let mappedFromJson = false;
        let mappedToJson = false;
        let isFromArray = false;
        let isToArray = false;
        let valueFromArray: any[] = [];
        let valueToArray: any[] = [];

        if (-1 < [
          'TripItemDefinitions.SelectedSeats',
          'TripItemDefinitions.SelectedAncillaries',
        ].indexOf(change.fieldName)) {
          valueFrom = JSON.parse(valueFrom);
          valueTo = JSON.parse(valueTo);
        }

        if (checkIfJSON(change.valueFrom)) {
          valueFrom = JSON.parse(change.valueFrom);

          const isArray = Array.isArray(valueFrom);

          valueFromArray = Object.keys(valueFrom).reduce((prev: any[], cur) => {
            const value = valueFrom[cur];
            const isObject = checkIfObject(value);

            return [...prev, {
              label: cur,
              labelTranslated: translationExists('trip-history-document-fields.' + cur) ? translate('trip-history-document-fields.' + cur) : cur,
              value,
              isObject,
            }];
          }, []);

          if (isArray) {
            isFromArray = isArray;
            valueFromArray = valueFromArray.sort(sortByValue);
          }
          mappedFromJson = true;
        }

        if (checkIfJSON(change.valueTo)) {
          valueTo = JSON.parse(change.valueTo);

          const isArray = Array.isArray(valueTo);

          valueToArray = Object.keys(valueTo).reduce((prev: any[], cur) => {
            const value = valueTo[cur];
            const isObject = checkIfObject(value);

            return [...prev, {
              label: cur,
              labelTranslated: translationExists('trip-history-document-fields.' + cur) ? translate('trip-history-document-fields.' + cur) : cur,
              value,
              isObject,
            }];
          }, []);

          if (isArray) {
            isToArray = isArray;
            valueToArray = valueToArray.sort(sortByValue);
          }
          mappedToJson = true;
        }
        return {
          ...change,
          mappedFromJson,
          mappedToJson,
          isFromArray,
          isToArray,
          valueFromArray,
          valueToArray,
          valueFrom,
          valueTo,
        };
      });
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }


  getBasketItemStatusClasses(status) {
    return {
      draft: status === 'Draft',
      held: status === 'Held',
      pendingApproval: status === 'PendingApproval' || status === 'AutoApproval',
      confirmed: status === 'Confirmed',
      unconfirmed: status === 'Unconfirmed',
      cancelled: status === 'Cancelled',
      desynchronized: status === 'Desynchronized',
      refused: status === 'Refused',
      ticketed: status === 'Ticketed'
    };
  }

  filteredObjectKeys(value) {
    return Object.keys(value)
      .filter(key => value[key] !== null);
  }

  formatDate(date) {
    return moment.utc(date).local().format(this.dateTimeFormat);
  }

  formatDateLocal(date) {
    return moment(date).format(this.dateTimeFormat);
  }

  getValueTranslationOrValue(value: string, translationPrefix: string) {
    if (this.$te(translationPrefix + value)) {
      return this.$t(translationPrefix + value);
    }

    return value;
  }
}
