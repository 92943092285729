






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';
import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';

@Component({})
export default class DefaultTransition extends Vue {
  transitionViewName: string = 'default';

  get animating(): boolean {
    return layoutStore.slots.default.transitioning;
  }

  get transition() {
    return layoutStore.slots.default.transition;
  }

  get height(): number {
    return layoutStore.slots.default.meta.height;
  }

  get classes() {
    return {
      'active': this.animating,
      'toggled': !layoutStore.slots.default.meta.empty,
    };
  }

  get styles() {
    if (!this.animating) {
      return {};
    }
    return {
      height: this.height + 'px',
    };
  }

  async mounted() {
    layoutStore.setWrapper({
      slot: 'default',
      wrapper: this.$refs.wrapper as HTMLElement,
    });
    this.updateDimensions();
  }

  entered() {
    layoutStore.animationFinished({
      slot: 'default'
    });
    this.updateDimensions();
  }

  entering() {
    this.updateDimensions();
  }

  updateDimensions() {
    layoutStore.updateDimensions({
      slot: 'default',
    });
  }
}

