














































































































import { Prop, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import {
  FlightRuleConfigurationUpdateModel,
  FlightRuleCreateModel,
  CabinClass,
} from '@/api/travel-policy/travel-policy.model';
import SettingsStore from '@/modules/settings/settings.store';
import TravelPolicyFlightStore from '@/modules/settings/travel-policy/travel-policy-flight.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import { translate } from '@/i18n';

@Component({})

export default class TravelPolicyFlight extends BaseTable<FlightRuleConfigurationUpdateModel> {
  @Prop({ default: false }) formPending: boolean = false;
  @Prop({ default: false }) readOnly?;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    price : {
      sortable: false,
      label: translate('settings-travel-policy.price'),
    },
    ruleAirlines: {
      sortable: false,
      label: translate('settings-travel-policy.table-airline'),
      formatter: value => {
        if (value && value.length && value.length > 4) {
          return `${ value.length } ${translate('settings-travel-policy.lowercase-airlines')}`;
        }
        return value.map((item) => {
          return item.businessName ? item.businessName : item;
        }).join(', ');
      },
    },
    ruleCabinClasses : {
      sortable: false,
      label: translate('settings-travel-policy.table-cabin-class'),
      formatter: value => {
        if (value && value.length && value.length > 2) {
          return `${ value.length } ${translate('settings-travel-policy.lowercase-cabin-classes')}`;
        }
        return value.map((item) => {
          return item.cabinClassCode ? this.mapCabinClass(item.cabinClassCode) : this.mapCabinClass(item);
        }).join(', ');
      },
    },
    ruleLegTimeComputed: {
      sortable: false,
      label: translate('settings-travel-policy.table-leg-time'),
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    seeAll: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  $v;
  menuActive: boolean = false;
  rule: FlightRuleConfigurationUpdateModel | null = null;
  modifyPopup: boolean = false;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  itemList: FlightRuleCreateModel[] = [];

  get ruleList() {
    return TravelPolicyFlightStore.getFlightRules;
  }

  get hideNonCompliantRates() {
    return TravelPolicyFlightStore.travelPolicy.hideNonCompliantRates;
  }

  set hideNonCompliantRates(value) {
    TravelPolicyFlightStore.setHideNonCompliantRates(value);
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  get count() {
    if (!this.results) {
      return 0;
    }

    return this.results.length;
  }

  get loadingList() {
    return TravelPolicyStore.loading;
  }

  modifyRule(item) {
    TravelPolicyFlightStore.selectedFlightRule(item);
    router.push({
      name: 'flight-rule',
      params: {
        configurationId: this.currentConfigurationId,
      },
      query: {
        readOnly: this.readOnly.toString()
      }
    });
  }

  mapCabinClass(cabinClass: CabinClass) {
    let result = cabinClass.toString();

    switch (cabinClass) {
      case CabinClass.Economy:
        result = translate('settings-travel-policy.economy');
        break;
      case CabinClass.EconomyPremium:
        result = translate('settings-travel-policy.economy-premium');
        break;
      case CabinClass.BusinessClass:
        result = translate('settings-travel-policy.business-class');
        break;
      case CabinClass.FirstClass:
        result = translate('settings-travel-policy.first-class');
        break;
      default:
        result = translate('settings-travel-policy.first-class');
        break;
    }

    return result;
  }

  removeRule(selected) {
    if (selected) {
      let filterList = _.filter(this.ruleList, (item) => {
        return item.id !== selected.id;
      });
      TravelPolicyFlightStore.setFlightRules(filterList);
      this.reload();
    }
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.ruleList,
    };
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.rulePriority < b.rulePriority) {
        result = -1;
      } else if (a.rulePriority > b.rulePriority) {
        result = 1;
      }
      return result;
    });
  }

  toggleRuleMenu(item) {
    this.rule = item;
    this.menuActive = !this.menuActive;
  }

  addRule() {
    TravelPolicyFlightStore.selectedFlightRule({});
    if (this.currentConfigurationId) {
      router.push({
        name: 'flight-rule',
        params: {
          configurationId: this.currentConfigurationId
        },
      });
    } else {
      router.push({
        name: 'flight-rule',
      });
    }
  }

  onReceive() {
    this.reload();
  }

  created() {
    EventBus.$on('refresh-data', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-data', this.onReceive);
  }

  @Watch('ruleList')
  onRuleListChange() {
    this.reload();
  }
}
