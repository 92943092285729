import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import { TripApi } from '@/api/trip/trip.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'providersNotifications'
})
class ProvidersNotificationsStore extends VuexModule {
  loading: boolean = false;
  saving: boolean = false;
  currentConfigurationName: string = '';
  currentConfiguration: any | null = null;
  currentConfigurationId: string = '';
  errors: any[] = [];
  configurationData: any = null;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  @Mutation
  setLoading(value: boolean) {
    this.loading = value;
  }

  @Mutation
  setSaving(value: boolean) {
    this.saving = value;
  }

  @Mutation
  setErrors(value) {
    this.errors = value;
  }

  @Mutation
  setConfigurationData(value) {
    this.configurationData = value;
  }

  @Mutation
  setCurrentConfigurationName(name: string) {
    this.currentConfigurationName = name;
  }

  @Mutation
  clearCurrentConfiguration() {
    this.currentConfiguration = null;
  }

  @Mutation
  setCurrentConfiguration(conf) {
    this.currentConfiguration = conf;
  }

  @Mutation
  setConfigurationId(id: string) {
    this.currentConfigurationId = id;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async loadConfiguration(id: string) {
    this.setLoading(true);
    this.setCustomError(false);
    try {
      const response = await TripApi.getProvidersNotificationsConfiguration(id);

      this.setCurrentConfigurationName(response.data.name);
      this.setConfigurationData(response.data);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrors($handleErrors(error, true));
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createConfiguration(message) {
    this.setSaving(true);
    try {
      await TripApi.createProvidersNotificationsConfiguration(message);
    } catch (error) {
      this.setErrors($handleErrors(error, true));
    } finally {
      this.setSaving(false);
    }
  }

  @Action
  async updateConfiguration({ id, message }) {
    this.setSaving(true);
    try {
      await TripApi.updateProvidersNotificationsConfiguration(id, message);
    } catch (error) {
      this.setErrors($handleErrors(error, true));
    } finally {
      this.setSaving(false);
    }
  }
}

export default getModule(ProvidersNotificationsStore);