import AirSearchStore from '@/modules/search/air/air-search.store';

export default (to, from, next) => {
  let allowedRouteNames = [
    'basket',
    'airModification',
    'airModificationTravellers'
  ];

  if (allowedRouteNames.indexOf(to.name) === -1) {
    AirSearchStore.setModifyState(false);
  }
  next();
};