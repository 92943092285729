






































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import { DocumentsApi } from '@/api/profile/documents.api';
import { DocumentTypeList } from '@/const/document-type-list.const';
import { DocumentType, ProfileDocument } from '@/api/profile/documents.model';
import DocumentEditPopup from '@/modules/profile/documents/DocumentEditPopup.vue';
import DictionaryStore from '@/store/dictionary.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventHandler from '@/services/event-handler';

@Component({
  components: {
    DocumentEditPopup,
  }
})
export default class Documents extends Vue {
  documentRemovalErrorMessage: string | null = null;
  documents: ProfileDocument[] = [];
  showDocumentEditPopup: boolean = false;
  deleteDocumentPopup: boolean = false;
  editingDocument: ProfileDocument | null = null;
  documentToRemove: ProfileDocument | null = null;
  profileId: string | null = null;
  formPending: boolean = false;
  errMessages: any[] = [];



  get documentTypes() {
    return DocumentTypeList
      .map(item => {
        return {
          ...item,
          name: translate('profile-documents-by-type.' + item.value),
        };
      });
  }

  get availableDocumentTypes() {
    return this.documentTypes.filter(type => {
      return this.documents.find((doc: any) => doc.type === type.value);
    });
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get DocumentType() {
    return DocumentType;
  }

  showWarningDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  showWarningIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  getCountryName(code: string) {
    let countryFound = this.allCountries.find((country: any) => { return country.code === code; });
    if (countryFound) {
      return countryFound.countryName;
    }
    else {
      return code;
    }
  }

  documentsOfGivenType(type) {
    return this.documents.filter((doc: any) => doc.type === type.value);
  }

  onDocumentChange(reloadDocuments) {
    this.showDocumentEditPopup = false;
    this.editingDocument = null;
    if (reloadDocuments) {
      this.init();
    }
  }

  async init() {
    this.showDocumentEditPopup = false;
    this.editingDocument = null;

    try {
      this.profileId = this.$route.params.id;
      const documentsResult = await DocumentsApi.getDocuments(this.profileId);

      if (documentsResult && documentsResult.data) {
        this.documents = documentsResult.data.documents;
      }
    } catch (error) {
      this.errMessages = $handleErrors(error, true);
    }
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    this.errMessages = [];
    this.init();
  }

  formatDocumentRemoveDisplayName(document) {
    if (document) {
      let result: string = '';
      let docType = this.documentTypes.find(type => {
          return type.value === document.type;
        });

      return document.number + ' ' + docType!.name;
    }
    return '';
  }

  initRemoveDocument(document) {
    this.documentToRemove = document;
    this.deleteDocumentPopup = true;
  }

  async removeDocument() {
    if (document) {
      try {
        this.formPending = true;
        await DocumentsApi.removeDocument(this.profileId, this.documentToRemove!.id);
        this.deleteDocumentPopup = false;
        this.documents = this.documents.filter((doc: any) => doc.id !== this.documentToRemove!.id);
        EventHandler.$emit('profile-data-saved');
      } catch (error) {
        if (!error.response.data.error.details) {
          this.documentRemovalErrorMessage = error.response.data.error.message;
        }
      } finally {
        this.formPending = false;
      }
    }
  }

  editDocument(document) {
    if (document) {
      this.editingDocument = Object.assign({}, document);
      this.showDocumentEditPopup = true;
    }
  }

  showAddNewDocumentPopup() {
    this.showDocumentEditPopup = true;
  }
}

