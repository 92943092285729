



















































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, decimal } from 'vuelidate/lib/validators';

import { userFullName } from '@/core/user-full-name';
import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from '@/modules/basket/basket.store';
import {
  CancellationMethods,
  RefundDecisionMessage,
  RefundActionStatuses,
} from './air-refund.model';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AccountStore from '@/store/account.store';
import settings from '@/settings';

const eventName = 'update:show';

@Component({})
export default class AirRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;

  loadingCoupons: boolean = true;
  loadingDetails: boolean = false;
  processing: boolean = false;
  couponsLoaded: boolean = false;
  couponsStatus: any = null;
  processErrors: any[] = [];
  selectedOption: any = '';
  refundDetailsAFKLM: any = null;
  refundDetails: any[] = [];
  refundDetailsLoaded: boolean = false;
  refundDetailsLoadError: boolean = false;
  taxBreakdowns: any[] = [];
  $v;
  refundCommission: any = 0.00;
  isCommissionValid: boolean = true;
  shouldShowCommissionErrors: boolean = false;
  refundErrors: any[] = [];
  commissionMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
  });

  @Validation()
  validationObject() {
    return {
      refundCommission: {
        required,
        decimal
      }
    };
  }

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get isRefundAlwaysAvailable() {
    return 'true' === settings.isRefundAlwaysAvailable;
  }

  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get isAirFranceKlm() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'AirFranceKlm';
  }

  get travellersGroups() {
    if (!this.refundDetailsAFKLM) {
      return [];
    }

    return (this.refundDetailsAFKLM.items || []).map((item, index) => {
      return {
        ...item,
        traveller: {
          firstName: item.passengerInfo.firstName,
          passengerType: item.passengerInfo.passengerType,
          id: item.passengerInfo.profileId,
          lastName: item.passengerInfo.surname,
        }
      };
    });
  }

  get statusCount() {
    return this.couponsStatus.length;
  }

  get commissionError() {
    return this.$v.refundCommission.$error;
  }

  get canCancelTicket() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get canRefundFlightTicket() {
    return AccountStore.HasPermission('CanRefundFlightTicket');
  }

  get isVoidAvailable() {
    if ('Exchanged' === this.item.statusTag) {
      return false;
    }
    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get isRefundAvailable() {

    if (this.isRefundAlwaysAvailable) {
      return true;
    }

    for (const status of this.couponsStatus) {
      if (!status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get isMobile() {
    return window.innerWidth <= 799.98;
  }



  userFullName(user) {
    return userFullName(user);
  }

  toggleTaxBreakdown(index, name) {
    this.taxBreakdowns[index][name] = !this.taxBreakdowns[index][name];
    this.$forceUpdate();
  }

  issueDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  @Watch('actionType', { immediate: true })
  onChangeAction(val) {
    if (-1 < [
      RefundActionStatuses.VerifyRefund,
      RefundActionStatuses.VerifyConditions,
    ].indexOf(val.code)) {
      this.loadRefundDetails();
      this.processing = false;
    } else if (val.code === RefundActionStatuses.VerifyStatus) {
      this.init();
    }
  }

  async beginProcess() {
    switch (this.selectedOption) {
      case CancellationMethods.void:
        this.processErrors = [];
        this.processing = true;
        let voidResponse;
        try {
          voidResponse = await RefundBasketItemApi.startVoid(this.basketItemId);
        } catch (error) {
          this.processErrors = this.$handleErrors(error);
        } finally {
          if (voidResponse && voidResponse.status === 204) {
            EventBus.$emit('refresh-basket-status');
            this.hidePopup();
          }
          this.processing = false;
        }
      break;
      case CancellationMethods.refund:
        this.processErrors = [];
        this.processing = true;
        let initResponse;
        this.couponsLoaded = false;
        try {
          initResponse  = await RefundBasketItemApi.startRefund(this.basketItemId);
        } catch (error) {
          this.processErrors = this.$handleErrors(error);
        } finally {
          if (initResponse && initResponse.status === 204) {
            EventBus.$emit('refresh-basket-status');
          }
        }
      break;
    }
  }

  async loadRefundDetails() {
    this.loadingDetails = true;
    let refundResponse;
    try {
      if (this.isAirFranceKlm) {
        refundResponse = await RefundBasketItemApi.getRefundConditions(this.item.providerReferenceId, 'AirFranceKlm');
      } else {
        refundResponse = await RefundBasketItemApi.getRefundData(this.basketItemId);
      }
    } catch (error) {
      if (
        418 === error.response.status && error.response.data.error &&
        -1 < [
          'REFUND_NOT_AVAILABLE_ON_TICKETING_DATE',
          'REFUND_NOT_AVAILABLE',
        ].indexOf(error.response.data.error.code)
      ) {
        const message = {
          'REFUND_NOT_AVAILABLE_ON_TICKETING_DATE': translate('refund.not-available-on-ticketing-date'),
          'REFUND_NOT_AVAILABLE': translate('refund.not-available'),
        };
        this.processErrors = [{
          message: message[error.response.data.error.code],
        }];
      } else {
        this.processErrors = this.$handleErrors(error);
      }
      this.couponsLoaded = true;
      this.refundDetailsLoadError = true;
    } finally {
      if (refundResponse && refundResponse.status === 200) {
        if (this.isAirFranceKlm) {
          this.taxBreakdowns = [];
          if (refundResponse.data.items) {
            refundResponse.data.items.forEach(item => {
              this.taxBreakdowns.push({
                new: false,
                original: false,
                diff: false,
              });
            });
          }
          this.refundDetailsAFKLM = refundResponse.data;
        } else {
          this.refundDetails = refundResponse.data;
        }
        this.refundDetailsLoaded = true;
      }
      this.loadingDetails = false;
      this.processing = false;
    }
  }

  async finalizeRefund() {
    this.refundErrors = [];
    this.processing = true;
    let refundResponse;
    try {
      if (this.isAirFranceKlm) {
        refundResponse = await RefundBasketItemApi
          .acceptRefundCancellationConditions(this.basketItemId);
      } else {
        refundResponse = await RefundBasketItemApi.confirmRefundData({
          itemId: this.basketItemId,
          cancellationFee: this.refundCommission
        });
      }
    } catch (error) {
      this.refundErrors = this.$handleErrors(error);
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      this.processing = false;
    }
  }

  async exitRefundPopup() {
    if (this.refundErrors.length) {
      this.hidePopup();
    }
    this.refundErrors = [];
    let refundResponse;
    this.processing = true;
    try {
      refundResponse = await RefundBasketItemApi.rejectRefund(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');

      }
      this.processing = false;
      this.hidePopup();
    }
  }

  async answerRefundConditions(decision: boolean) {
    const message: RefundDecisionMessage = {
      itemId: this.basketItemId,
      isConfirmed: decision
    };
    try {
      await RefundBasketItemApi.sendCouponDecision(message);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async answerRefundConditionsErrors() {
    try {
      await RefundBasketItemApi.answerCouponDecisionError(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async init() {
    this.loadingCoupons = true;
    this.couponsLoaded = false;
    this.processErrors = [];
    let response;
    try {
      response = await RefundBasketItemApi.getItemCouponsStatus(this.basketItemId);
    } catch (err) {
      this.processErrors = this.$handleErrors(err);
    } finally {
      if (response && response.data) {
        this.couponsStatus = response.data;
        this.couponsLoaded = true;
      }
      this.loadingCoupons = false;
    }
  }

  @Watch('commissionError')
  onCommissionErrorChange(val) {
    this.isCommissionValid = !val;
  }

  created() {
    this.init();
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

