import DesignatedTravellers from '@/modules/profile/designated-travellers/DesignatedTravellers.vue';

export default {
  path: 'designated-travellers',
  name: 'designated-travellers',
  component: DesignatedTravellers,
  displayName: 'designated.designated-travellers-in-aw',
  meta: {
    permission: 'CanViewDesignatedTravellersForApprovalInProfile',
    icon: 'group',
  }
};