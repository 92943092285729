












































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { translate } from '@/i18n';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';

const sabreRemarkRegex5QQ = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^(?!5QQ)/g.test(value);
};

const sabreRemarkRegexCharacters = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^([a-zA-Z0-9]|\s|\,|\.|\-|\/|\¥|\¤|\*|\+|\'|\:|\@)*$/g.test(value);
};

const onlyLettersRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  return /^([a-zA-Z])*$/g.test(value);
};

@Component({})
export default class NegoFarePnrRemarksList extends Vue {
  @Prop({}) supplier!: string;
  @Prop({}) remarks!: any[];
  @Prop({}) service!: string;

  $v;
  uniqueIdPrefix: string = 'remark_id_';
  allAirline: any[] = [];
  airlines: any[] = [];

  @Validation()
  validationObject() {
    const cantBeSame = (value, model) => {
      if (!this.isAmadeus) {
        return true;
      }
      const entryCheck = entry => entry.type === model.type &&
          (entry.category || null) === model.category &&
          entry.remark === model.remark;
      const firstIndex = this.entries.findIndex(entryCheck);
      const lastIndex = this.entries.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };

    let entriesWithRQ = {};

    if (this.isAmadeus) {
      entriesWithRQ = {
        maxLength: maxLength(3),
      };
    }

    return {
      entriesWithRQ,
      entries: {
        $each: {
          remark: {
            required,
            maxLength: this.isSabre || this.isAmadeus ? maxLength(70) : maxLength(100),
            sabreRemarkRegex5QQ: (value) => {
              return (this.isSabre || this.isAmadeus) ? sabreRemarkRegex5QQ(value) : true;
            },
            sabreRemarkRegexCharacters: (value) => {
              return (this.isSabre || this.isAmadeus) ? sabreRemarkRegexCharacters(value) : true;
            },
            cantBeSame,
          },
          type: {
            required: requiredIf(() => {
              return (this.isSabre && this.isFlight) || this.isAmadeus;
            }),
            cantBeSame,
          },
          category: {
            onlyLettersRegex,
            maxLength: maxLength(2),
          },
        }
      }
    };
  }

  get amadeusRemarksTypes() {
    return [
      {
        value: 'RM',
        label: translate('settings-pnr-remarks-types.RM'),
      },
      {
        value: 'RI',
        label: translate('settings-pnr-remarks-types.RI'),
      },
      {
        value: 'RQ',
        label: translate('settings-pnr-remarks-types.RQ'),
      },
    ];
  }

  get sabreRemarksTypes() {
    return [
      {
        value: 'General',
        label: translate('settings-pnr-remarks-types.General'),
      },
      {
        value: 'AlphaCoded',
        label: translate('settings-pnr-remarks-types.AlphaCoded'),
      },
      {
        value: 'ClientAddress',
        label: translate('settings-pnr-remarks-types.ClientAddress'),
      },
      {
        value: 'Corporate',
        label: translate('settings-pnr-remarks-types.Corporate'),
      },
      {
        value: 'DeliveryAddress',
        label: translate('settings-pnr-remarks-types.DeliveryAddress'),
      },
      {
        value: 'GroupName',
        label: translate('settings-pnr-remarks-types.GroupName'),
      },
      {
        value: 'Hidden',
        label: translate('settings-pnr-remarks-types.Hidden'),
      },
      {
        value: 'Historical',
        label: translate('settings-pnr-remarks-types.Historical'),
      },
      {
        value: 'Invoice',
        label: translate('settings-pnr-remarks-types.Invoice'),
      },
      {
        value: 'Itinerary',
        label: translate('settings-pnr-remarks-types.Itinerary'),
      },
    ];
  }

  get remarksTypes() {
    if (this.isSabre) {
      return this.sabreRemarksTypes;
    }
    return this.amadeusRemarksTypes;
  }

  get isFlight() {
    return this.service === 'Flight';
  }

  get isAmadeus() {
    return this.supplier === 'Amadeus' &&
      -1 < [
        'Flight',
        'Car',
      ].indexOf(this.service);
  }

  get entries() {
    const remarksFound = this.remarks ? this.remarks : [];

    if (!this.isAmadeus && remarksFound && remarksFound.length) {
      return _.sortBy(remarksFound, (remark) => {
        return remark.seqNo;
      });
    }
    return remarksFound;
  }

  get isSabre() {
    return this.supplier === 'Sabre';
  }

  get entriesWithRQ() {
    return this.entries.filter(e => e.type === 'RQ');
  }

  
  airlinesMaper() {
    this.airlines = this.allAirline = DictionaryStore.allAirLinesSorted;
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  getItemType(item) {
    if (this.isSabre) {
      return this.sabreRemarksTypes.find(r => r.value === item.type);
    }
    return this.amadeusRemarksTypes.find(r => r.value === item.type);
  }

  setItemType(item, value) {
    item.type = value.value;
    if (item.type === 'RQ') {
      item.category = null;
    }
    this.$emit('change', this.entries);
  }

  newRemarkDisabled() {
    return this.entries[this.entries.length - 1] && this.entries[this.entries.length - 1].remark ?
        !this.entries[this.entries.length - 1].remark.length :
        this.entries.length > 0;
  }

  newRemark() {
    if (!this.newRemarkDisabled()) {
      this.entries.push({
        seqNo: this.entries.length ? Math.max(...this.entries.map(e => e.seqNo)) + 1 : 0,
        remark: '',
        type: '',
        category: null,
        airlines: null,
      });
      this.$emit('change', this.entries);
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  deleteRemark(index) {
     if (this.entries.length > 0) {
      if (-1 < index) {
        this.entries.splice(index, 1);
      }
      this.$emit('change', this.entries);
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  update() {
    this.$emit('change', this.entries);
  }

  created() {
    this.airlinesMaper();
  }
}
