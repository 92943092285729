import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

import { store } from '@/store';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import filtersList from '@/const/filter.const';
import { serviceClassEnum } from '@/api/home/home.model';
import { TrainSearchStateParams } from './train-search.params';
import { TravellersStateParams } from '../travellers.params';
import { TrainOfferResult, SearchState, SortOptions, TrainSearchProviderError } from '@/api/train-engine/train-search.model';
import { TrainApi } from '@/api/train-engine/train-search.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import layoutStore from '@/modules/layout/layout.store';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import BasketStore from '@/modules/basket/basket.store';
import accountStore from '@/store/account.store';
import _ from 'lodash';
import { TrainExchangeApi } from '@/api/train-engine/train-exchange.api';
import EventBus from '@/services/event-handler';
import { isCancelError } from '@/core/utils';

const currency = {
  code: 'EUR',
  symbol: '€',
};
@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'trainSearch'
})
class TrainSearchStore extends VuexModule {
  loading: boolean = false;
  totalOffers: number = 0;
  convertedFilters: any[] = [];
  stateId: string = '';
  searchId: string = '';
  searchIdChanged: boolean = false;
  searchLoaded: boolean = false;
  shouldStopSearch: boolean = false;
  offers: any = [];
  propertyOffersLoading: string | null = null;
  selectedOffer: TrainOfferResult[] = [];
  errMessages: string[] = [];
  showError: boolean = false;
  searchCompleted: boolean = false;
  searchTimeout: number = -1;
  selectingOffer: boolean = false;
  selectedOfferId: string = '';
  searchInProgress: boolean = false;
  scrollTimeout: number = 0;
  basketId: string = '';
  prevSelectedOffer: any = [];
  showModal: boolean = false;
  errorMessageBe: string = '';
  showErrorBE: boolean = false;
  wasSelectedFirstLeg: boolean = false;
  filters: any[] = [];
  cancelToken: CancelTokenSource | null = null;
  filtersChanged: boolean = false;
  filtersChanging: boolean = false;
  filtersRequest: any = null;
  filtersError: boolean = false;
  filtersRequestId: number = 0;
  searchFreezed: boolean = false;
  sortOptions: SortOptions = {
    DEPARTURE_TIME: 'Ascending',
  };
  recommendationsRailCount: number = 0;
  filtersOffset: number = 0;
  filtersShouldHaveOffset: boolean = false;
  exchangedOffer: any = null;
  exchangeLoaded: boolean = false;
  exchangedTripItemId: string = '';
  exchangedRecommendationId: string = '';
  exchangeRequest: any = null;
  providersErrors: TrainSearchProviderError[] = [];
  currency: any = null;
  editedFeeOffer: any = null;
  editedFeeProposal: any = null;
  selectedForExchange: any = null;
  travellers: any[] = [];
  meta: any = null;
  availableMoreActions: any[] = [];
  moreActionsErrors: any[] = [];



  get canShowError() {
    return this.showError;
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get isFranceDomesticDepatureAndOuigoAvailable() {
    return this.meta && this.meta.isFranceDomesticDepatureAndOuigoAvailable;
  }



  @Mutation
  updateFilters(data) {
    this.filters = data;
  }

  @Mutation
  setTimeoutInterval(state: number) {
    this.scrollTimeout = state;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setLoadingPropertyOffers(value) {
    this.propertyOffersLoading = value;
  }

  @Mutation
  setMeta(value) {
    this.meta = value;
  }

  @Mutation
  setAvailableMoreActions(value) {
    this.availableMoreActions = value;
  }

  @Mutation
  setMoreActionsErrors(value) {
    this.moreActionsErrors = value;
  }

  @Mutation
  setOffers(payload) {
    this.offers = payload;
  }

  @Mutation
  setSelectedOffer(payload) {
    this.selectedOffer = payload;
  }

  @Mutation
  setSelectedOfferId(payload) {
    this.selectedOfferId = payload;
  }

  @Mutation
  setErrMessages(error, addMessagetoError?) {
    this.errMessages = $handleErrors(error, true, undefined, addMessagetoError || false);
  }

  @Mutation
  setTravellers(value) {
    this.travellers = value;
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  updateSearchStateId(value: string) {
    this.stateId = value;
  }

  @Mutation
  setTotalOffers(value) {
    this.totalOffers = value;
  }

  @Mutation
  setRecommendationsRailCount(state: number) {
    this.recommendationsRailCount = state;
  }

  @Mutation
  setupSearchLoaded(searchId) {
    if (this.searchId !== searchId) {
      this.searchIdChanged = true;
      this.searchId = searchId;
      this.searchLoaded = false;
    } else {
      this.searchIdChanged = false;
    }
  }

  @Mutation
  finishLoading() {
    if (this.searchIdChanged) {
      this.searchLoaded = true;
      this.searchIdChanged = false;
    }
  }

  @Mutation
  clearSearchTimeout() {
    clearTimeout(this.searchTimeout);
  }

  @Mutation
  setSearchTimeout(timeout) {
    this.searchTimeout = setTimeout(timeout, 1000);
  }

  @Mutation
  setBasketId(data) {
    this.basketId = data;
  }

  @Mutation
  setErrorMessageBe(data) {
    this.errorMessageBe = data;
  }

  @Mutation
  setShowErrorBE(data) {
    this.showErrorBE = data;
  }

  @Mutation
  setSelectedFirstLeg(value) {
    this.wasSelectedFirstLeg = value;
  }

  @Mutation
  setNewSort({ sorterCode, isAscending = true }) {
    this.sortOptions = {
      [sorterCode]: isAscending ? 'Ascending' : 'Descending',
    };
  }

  @Mutation
  setShowModal(data) {
    this.showModal = data;
  }

  @Mutation
  setPrevSelectedOffer(data) {
    this.prevSelectedOffer = data;
  }

  @Mutation
  updateConvertedFilters(data) {
    this.convertedFilters = data;
  }

  @Mutation
  addConvertedFilter(data) {
    this.convertedFilters.push(data);
  }

  @Mutation
  setSelectingOffer(value) {
    this.selectingOffer = value;
  }

  @Mutation
  setSearchInProgress(state: boolean) {
    this.searchInProgress = state;
  }

  @Mutation
  setFiltersShouldHaveOffset(value) {
    this.filtersShouldHaveOffset = value;
  }

  @Mutation
  setFiltersOffset(value) {
    this.filtersOffset = value;
  }

  @Mutation
  setExchangedOffer(offer) {
    this.exchangedOffer = offer;
  }

  @Mutation
  setExchangeLoaded(value) {
    this.exchangeLoaded = value;
  }

  @Mutation
  setExchangedTripItemId(value) {
    this.exchangedTripItemId = value;
  }

  @Mutation
  setExchangedRecommendationId(value) {
    this.exchangedRecommendationId = value;
  }

  @Mutation
  setExchangeRequest(value) {
    this.exchangeRequest = value;
  }
  
  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  createCancelTokenIfNeeded() {
    if (this.cancelToken !== null) {
      return;
    }
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    this.cancelToken = cancelTokenSource;
  }

  @Mutation
  setFiltersError(value) {
    this.filtersError = value;
  }

  @Mutation
  setSearchFreezed(value: boolean) {
    this.searchFreezed = value;
  }

  @Mutation
  updateFiltersRequestId() {
    this.filtersRequestId++;
  }

  @Mutation
  setFiltersChanging(value) {
    this.filtersChanging = value;
  }

  @Mutation
  setFiltersChanged(value) {
    this.filtersChanged = value;
  }

  @Mutation
  setFiltersRequest(value) {
    this.filtersRequest = value;
  }

  @Mutation
  setProvidersErrors(errors: TrainSearchProviderError[]) {
    this.providersErrors = errors;
  }

  @Mutation
  setCurrency(value) {
    this.currency = value;
  }

  @Mutation
  setEditedOffer({ offer, proposal }) {
    this.editedFeeOffer = offer;
    this.editedFeeProposal = proposal;
  }

  @Mutation
  updateFeeInResults(newObject) {
    const offer = this.offers.find(offer => offer.id === this.editedFeeOffer.id);
    if (!offer) {
      return;
    }
    const proposalsWrapper = offer.proposals.find(p => {
      return p.fares.find(fare => fare.id === this.editedFeeProposal.id);
    });
    if (!proposalsWrapper) {
      return;
    }
    const proposal = proposalsWrapper.fares.find(fare => fare.id === this.editedFeeProposal.id);
    if (!proposal) {
      return;
    }
    const newProposalsWrapper = newObject.proposals.find(p => {
      return p.fares.find(fare => fare.id === this.editedFeeProposal.id);
    });
    if (!newProposalsWrapper) {
      return;
    }
    const newProposal = newProposalsWrapper.fares.find(fare => fare.id === this.editedFeeProposal.id);
    if (!newProposal) {
      return;
    }
    proposal.agencyFee = newProposal.agencyFee;
    proposal.totalPrice = newProposal.totalPrice;
    proposal.price = newProposal.price;
    proposal.travelPolicy = newProposal.travelPolicy;
    offer.minPrice = newObject.minPrice;
    offer.minAgencyFee = newObject.minAgencyFee;
    offer.travelPolicy = newObject.travelPolicy;
  }

  @Mutation
  updateAgencyMarkupInResults(newObject) {
    const offer = this.offers.find(offer => offer.id === this.editedFeeOffer.id);
    if (!offer) {
      return;
    }
    const proposalsWrapper = offer.proposals.find(p => {
      return p.fares.find(fare => fare.id === this.editedFeeProposal.id);
    });
    if (!proposalsWrapper) {
      return;
    }
    const proposal = proposalsWrapper.fares.find(fare => fare.id === this.editedFeeProposal.id);
    if (!proposal) {
      return;
    }
    const newProposalsWrapper = newObject.proposals.find(p => {
      return p.fares.find(fare => fare.id === this.editedFeeProposal.id);
    });
    if (!newProposalsWrapper) {
      return;
    }
    const newProposal = newProposalsWrapper.fares.find(fare => fare.id === this.editedFeeProposal.id);
    if (!newProposal) {
      return;
    }
    proposal.agencyMarkup = newProposal.agencyMarkup;
    proposal.totalPrice = newProposal.totalPrice;
    proposal.price = newProposal.price;
    proposal.travelPolicy = newProposal.travelPolicy;
    offer.minPrice = newObject.minPrice;
    offer.minAgencyMarkup = newObject.minAgencyMarkup;
    offer.travelPolicy = newObject.travelPolicy;
  }

  @Mutation
  setSelectedForExchange(value) {
    this.selectedForExchange = value;
  }



  @Action
  buildSortQuery() {
    const data: string[] = [];
    const paramName = 'sortOptions';
    let i = 0;
    for (let key in this.sortOptions) {
      if (this.sortOptions.hasOwnProperty(key)) {
        let urlParam = `${paramName}[${i}].Code=${key}`;
        data.push(urlParam);
        urlParam = `${paramName}[${i}].Direction=${this.sortOptions[key]}`;
        data.push(urlParam);

        i++;
      }
    }

    return data.join('&');
  }

  @Action
  public stopSearch() {
    this.finishLoading();
    this.setupSearchLoaded('');
    this.setSearchInProgress(false);
    clearInterval(this.scrollTimeout);
    this.clearSearchTimeout();
    this.setLoading(false);
  }

  @Action
  applyExchangeData(response) {
    if (!this.exchangeLoaded) {
      const request = response.data.request;
      SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
        from: request.legs[0].from,
        departureDate: request.legs[0].date,
        to: request.legs[0].to,
        returnDate: request.legs[request.legs.length - 1].date,
        isForExchange: request.legs.map(e => e.isForExchange),
        legs: request.legs,
      }));
      SearchStore.updateTravellersDefaultState(new TravellersStateParams({
        travellers: request.travellers,
      }));

      this.setExchangedTripItemId(request.railTripItemId);
      this.setExchangedRecommendationId(request.parentOrderId);
      this.setExchangeRequest(request);

      const item = response.data.parentItem;
      let journeys: any[] = [];
      let proposals: any[] = [];
      let price = 0;
      item.rail.recommendations.forEach(recommendation => {
        recommendation.journeys.forEach(journey => {
          journeys.push(journey);
        });
        recommendation.proposals.forEach(proposal => {
          proposals.push(proposal);
        });
      });

      proposals.forEach(proposal => {
        proposal.fares.forEach(fare => {
          fare.isSelected = true;
          price += fare.price.amount;
          fare.segmentKey = proposal.segmentKey;
        });
      });

      const tripItem = {
        ...item,
        rail: {
          id: item.rail.id,
          orderId: item.rail.orderId,
          offerProvider: item.rail.recommendations[0].offerProvider,
          recommendationId: item.recommendationId ? item.recommendationId : item.rail.orderId,
          journeys: journeys,
          proposals: proposals,
          price: {
            amount: price,
            currency: {
              code: 'EUR',
              symbol: '€'
            }
          },
          travelPolicy: item.travelPolicy,
        }
      };

      this.setExchangedOffer(tripItem);
      
      this.setExchangeLoaded(true);
    }
  }

  @Action
  checkIfAbsoluteNoResults(meta) {
    if (meta.recommendationsTotalCount === 0) {
      this.updateFilters([]);
      if (!layoutStore.sidebarHidden) {
        layoutStore.toggleSidebar();
      }
    }
  }

  @Action
  public async stepLoading(data) {
    if (this.searchId !== data.searchId) {
      return;
    }

    this.createCancelTokenIfNeeded();

    try {
      let response;

      if (router.currentRoute.name === 'trainModification') {
        response = await TrainExchangeApi.getSearchSession(data.searchId);
        
        this.applyExchangeData(response);
      } else {
        response = await TrainApi.getTrainSearchSession({searchId: data.searchId}, this.cancelToken || undefined);
      }
      
      if (this.searchId !== data.searchId) {
        return;
      }

      const searchCompleted = response.data.metadata.searchState === SearchState.Completed;
      const differentOffersCount = this.recommendationsRailCount < response.data.metadata.recommendationsTotalCount;
      const meta = response.data.metadata;

      this.applySessionData({ sessionResponse: response, data });
      this.setProvidersErrors(meta.providersErrors);
      this.setMeta(meta);
      this.setAvailableMoreActions(response.data.availableLoadMoreAction);
      this.setRecommendationsRailCount(response.data.metadata.recommendationsTotalCount);

      if (differentOffersCount || this.filtersChanged) {
        await this.getRecommendations({ searchId: data.searchId, legNumber: router.currentRoute.params.leg });
      }

      if (this.searchId !== data.searchId) {
        return;
      }

      if (searchCompleted) {
        this.whenSearchCompleted({
          session: response.data.request,
          meta: response.data.metadata,
        });
        return;
      }

      this.clearSearchTimeout();
      this.setSearchTimeout(() => {
        this.stepLoading(data);
      });
    } catch (error) {
      this.setLoading(false);

      if (!isCancelError(error)) {
        this.setErrMessages(error);
        this.setShowError(true);
      }
    }
  }

  @Action
  async whenSearchCompleted({ session, meta }) {
    this.checkIfAbsoluteNoResults(meta);
    if (router.currentRoute.name === 'trainModification') {
      SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
        from: session.legs[0].from,
        departureDate: session.legs[0].date,
        to: session.legs[0].to,
        returnDate: session.legs[session.legs.length - 1].date,
        isForExchange: session.legs.map(item => item.isForExchange),
        legs: session.legs,
      }));
    } else {
      SearchStore.updateTrainDefaultState(new TrainSearchStateParams(session));
    }
    SearchStore.updateTravellersDefaultState(new TravellersStateParams({
      travellers: session.travellers,
    }));
    SearchStore.updateEditedTravellers(new TravellersStateParams({
      travellers: session.travellers,
    }));
    SearchStore.updateSkipTravellers(session.travellers.some(trav => {
      return trav.isVirtual ? trav.isVirtual : false;
    }));

    let isVirtualTraveller = session.travellers!.find(trav => trav.isVirtual === true);

    if (isVirtualTraveller) {
      let companyModel =  {
        name: isVirtualTraveller.businessUnitName,
        code: isVirtualTraveller.companyCode,
        id: isVirtualTraveller.businessUnitId,
        isDisabled: false,
        parentId: null,
        rootId: isVirtualTraveller.companyId,
        isSelected: false,
      };                             

      SearchStore.setSkipTravellersCompany(companyModel);
      EventBus.$emit('update-skip-travellers-company');
    }

    SearchStore.setMissionId(meta.missionId);
    SearchStore.selectBasket(meta.basketId);
    this.setProvidersErrors(meta.providersErrors);
    if (meta.missionId !== null) {
      await SearchStore.getMissionDetails();
    }

    if (BasketStore.basketMetadata && BasketStore.basketMetadata.basketId !== meta.basketId) {
      await BasketStore.getBasketMetadata(meta.basketId);
    }
    this.finishLoading();
    this.setLoading(false);
  }

  @Action
  prepareTrainSessionRequest() {
    const params = SearchStore.getTrainDefaultState;
    const travellersList = SearchStore.getTravellersState;
    const hasVirtualTravellers = travellersList.travellers
      .some(traveller => !!traveller.isVirtual);
    const searchCompanyId = hasVirtualTravellers ? SearchStore.skipTravellersCompany.id : null;

    return {
      searchMode: params.searchMode,
      travellers: travellersList.travellers,
      from: params.from,
      to: params.to,
      departureDate: moment(params.departureDate).format('YYYY-MM-DD'),
      departureTimeSpan: {
        from: params.departureTimeSpan.value ? params.departureTimeSpan.value[0] : params.departureTimeSpan[0],
        to: params.departureTimeSpan.value ? params.departureTimeSpan.value[1] : params.departureTimeSpan[1],
      },
      returnDate: params.searchMode !== 'OneWay' ? moment(params.returnDate).format('YYYY-MM-DD') : '',
      returnTimeSpan: {
        from: params.returnTimeSpan.value ? params.returnTimeSpan.value[0] : params.returnTimeSpan[0],
        to: params.returnTimeSpan.value ? params.returnTimeSpan.value[1] : params.returnTimeSpan[1],
      },
      searchCompanyId,
      enablePackageRates: params.enablePackageRates,
      advancedSearchParametersId: params.advancedSearchParametersId,
    };
  }

  @Action
  async initTrainSearch(basketId?: string) {
    this.setAvailableMoreActions([]);
    this.setOffers([]);
    this.setProvidersErrors([]);
    this.setTotalOffers(0);
    this.setRecommendationsRailCount(0);
    this.setSelectedOffer([]);
    this.setSelectedOfferId('');
    this.setNewSort({ sorterCode: 'DEPARTURE_TIME', isAscending: true });
    this.setSearchInProgress(true);
    this.setShowError(false);
    this.setSelectedFirstLeg(false);

    try {
      const missionId = SearchStore.missionId;
      router.push({
        name: 'train',
        params: { searchId: '-', leg: '-' }
      });
      const request = await this.prepareTrainSessionRequest();
      let response;
      if (basketId && !missionId) {
        response = await TrainApi.addSegment(request, basketId);
        await BasketStore.getBasketMetadata(basketId);
      } else if (missionId && !basketId) {
        response = await TrainApi.initTrainSearchForMission(request, missionId);
      } else if (missionId && basketId) {
        response = await TrainApi.addSegmentForMission(request, basketId, missionId);
        await BasketStore.getBasketMetadata(basketId);
      } else {
        response = await TrainApi.initTrainSearch(request);
      }
      setTimeout(() => {
        SearchStore.select(serviceClassEnum.Train);
      });

      if (response && response.data && this.searchInProgress) {
        this.setSearchInProgress(false);
        clearInterval(this.scrollTimeout);
        this.setTimeoutInterval(setInterval(() => {
          if (!layoutStore.slots.intro.transitioning) {
            clearInterval(this.scrollTimeout);
            router.replace({
              name: 'train',
              params: { searchId: response.data.searchId, leg: '1'},
            });
          }
        }, 30));
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
      this.setLoading(false);
    }
  }

  @Action
  async getSearchSession(data) {
    this.setLoading(true);
    try {
      let searchId = data.searchId;
      const sessionResponse = await TrainApi.getTrainSearchSession({searchId});
      this.setSelectedOffer([]);
      if (sessionResponse.data.selectedLegs && sessionResponse.data.selectedLegs.length) {
        this.setSelectedFirstLeg(true);
        sessionResponse.data.selectedLegs.forEach(selectedLeg => {
          selectedLeg.recommendation.price = {
            amount: 0,
            currency: {
              code: 'EUR',
              symbol: '€',
            }
          };
          selectedLeg.recommendation.proposals.forEach(proposal => {
            proposal.fares.forEach(fare => {
              fare.isSelected = false;
              fare.segmentKey = proposal.segmentKey;
            });
            proposal.fares[0].isSelected = true;
          });
        });
        this.setSelectedOffer(sessionResponse.data.selectedLegs);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
      this.setLoading(false);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async deleteSelectedLegAndGetSession(data) {
    this.setLoading(true);
    try {
      const sessionResponse = await TrainApi.deleteSelectedLeg(data.searchId, data.legNumber);
      this.setSelectedOffer([]);
      if (sessionResponse.data.selectedLegs && sessionResponse.data.selectedLegs.length) {
        this.setSelectedFirstLeg(true);
        sessionResponse.data.selectedLegs.forEach(selectedLeg => {
          selectedLeg.recommendation.price = {
            amount: 0,
            currency: {
              code: 'EUR',
              symbol: '€',
            }
          };
          selectedLeg.recommendation.proposals.forEach(proposal => {
            proposal.fares.forEach(fare => fare.isSelected = false);
            proposal.fares[0].isSelected = true;
          });
        });
        this.setSelectedOffer(sessionResponse.data.selectedLegs);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
      this.setLoading(false);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  applySessionData({ sessionResponse, data }) {
    if (sessionResponse.data.selectedLegs && sessionResponse.data.selectedLegs.length) {
      this.setSelectedFirstLeg(true);
      sessionResponse.data.selectedLegs.forEach(selectedLeg => {
        selectedLeg.recommendation.price = {
          amount: 0,
          currency: {
            code: 'EUR',
            symbol: '€',
          }
        };
        selectedLeg.recommendation.proposals.forEach(proposal => {
          proposal.fares.forEach(fare => fare.isSelected = false);
          let selectedFare = _.minBy(proposal.fares, (f) => f.price.amount);

          if (selectedFare) {
            selectedFare.isSelected = true;
          }
        });
      });
      this.setSelectedOffer(sessionResponse.data.selectedLegs);
    } else if (router.currentRoute.name !== 'trainModification' && router.currentRoute.name !== 'trainFilters' && router.currentRoute.name !== 'trainResultDetailsView') {
      data.legNumber = 1;

      const isOnSearch = -1 < ['train', 'trainDetails', 'trainTravellers'].indexOf(router.currentRoute.name || '');
      const name = isOnSearch ? router.currentRoute.name : 'train';

      if (!isOnSearch || router.currentRoute.params.searchId !== data.searchId) {
        router.replace({
          name,
          params: { searchId: data.searchId, leg: '1'},
        });
      }
    }
  }

  @Action
  prepareRecommendationOffers(offers) {
    offers.forEach(offer => {
      offer.price = {
        amount: 0,
        currency: {
          code: 'EUR',
          symbol: '€',
        }
      };
      offer.selectedProposal = [];
      offer.proposals.forEach(proposal => {
        if (proposal.fares.length) {
          proposal.fares.forEach(fare => fare.isSelected = false);

          const selectedFare = _.minBy(proposal.fares, (f) => f.price.amount);

          if (selectedFare) {
            selectedFare.isSelected = true;
            offer.selectedProposal!.push(selectedFare);
          }
        }
      });
    });
    this.setOffers(offers);
  }

  @Action
  onRecommendationsResponse(offersResponse) {
    const filtersLength = this.filters.length;
    const offers = offersResponse.data.searchResults.recommendations;
    this.prepareRecommendationOffers(offers);
    this.setTotalOffers(offersResponse.data.searchResults.recommendationsFilteredCount);
    this.setRecommendationsRailCount(offersResponse.data.searchResults.recommendationsTotalCount);

    if (!this.filtersChanging) {
      if (offersResponse.data.statistics) {
        this.convertFiltersStats(offersResponse.data.statistics);
      } else {
        this.checkIfAbsoluteNoResults({ recommendationsTotalCount: 0 });
      }
      this.setFiltersChanged(false);
    }

    if (
      filtersLength === 0 &&
      this.filters.length > 0 &&
      layoutStore.sidebarHidden
    ) {
      layoutStore.toggleSidebar();
    }

    if (offersResponse.data.currency) {
      this.setCurrency(offersResponse.data.currency);
    }

    this.setSearchFreezed(false);
  }

  @Action
  async getRecommendations({ searchId, legNumber }) {
    const sortQuery = await this.buildSortQuery();
    let offersResponse;

    if (router.currentRoute.name === 'trainModification') {
      offersResponse = await TrainExchangeApi.getTrainOffers(searchId, legNumber, sortQuery);
    } else {
      offersResponse = await TrainApi.getTrainOffers({searchId, legNumber}, sortQuery, this.cancelToken || undefined);
    }
    if (offersResponse && offersResponse.data) {
      this.onRecommendationsResponse(offersResponse);
    }
  }

  @Action
  async selectOffer(data) {
    try {
      this.setSelectingOffer(true);
      this.setSelectedOfferId(data.offer.id);
      let searchId = data.searchId;

      let selectedFare: any = [];
      data.offer.proposals.forEach(proposal => {
        proposal.fares.filter(fare => {
          if ( fare.isSelected === true ) {
            selectedFare.push({
              segmentKey: proposal.segmentKey,
              fareId: fare.id
            });
          }
        });
      });

      let request = {
        legNumber: parseInt(data.leg),
        recommendationId: data.offer.id,
        fares: selectedFare
      };

      let response;

      if (router.currentRoute.name === 'trainModification') {
        response = await TrainExchangeApi.chooseExchangeOffer(searchId, request);
      } else {
        response = await TrainApi.setTrainProposal({searchId}, request);
      }
      if (response && response.data && response.data.legNumber !== 0) {
        if (router.currentRoute.name === 'trainModification') {
          router.replace({
            name: 'trainModification',
            params: {
              leg: response.data.legNumber + ''
            },
          });
        } else {
          router.replace({
            name: 'train',
            params: {
              leg: response.data.legNumber + ''
            },
          });
        }
      } else if (response && response.data && response.data.legNumber === 0) {
        if (router.currentRoute.name === 'trainModification') {
          this.setSelectedForExchange(data.offer);
          EventBus.$emit('show-check-price');
          return;
        }
        this.addOfferToCart({
          bookerId: this.currentUser!.profile.id,
          type: 'Rail',
          searchId: searchId
        });
        window.scrollTo(0, 0);
      }

      this.setSelectingOffer(false);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
      this.setSelectingOffer(false);
      this.setSelectedOfferId('');
    }
  }

  @Action
  public getOffers(data) {
    this.setLoading(true);
    this.clearSearchTimeout();
    this.setupSearchLoaded(data.searchId);
    this.updateSearchStateId('');
    this.stepLoading(data);
  }

  @Action
  async loadMoreNow({ searchId, legNumber, action }) {
    this.setNewSort({ sorterCode: 'DEPARTURE_TIME', isAscending: true });
    this.setMoreActionsErrors([]);

    try {
      if (router.currentRoute.name === 'trainModification') {
        await TrainExchangeApi.loadMoreExchangeResults(searchId, legNumber, action);
      } else {
        await TrainApi.loadMoreResults(searchId, legNumber, action);
      }
    } catch (error) {
      this.setMoreActionsErrors($handleErrors(error, true));
    }

    this.getOffers({ searchId, legNumber });
  }

  @Action
  async addOfferToCart(request) {
    try {
      const response = await BasketItemApi.addItemAndCreateTrip(request);
      if (response && response.data) {
        this.setBasketId(response.data.tripId);
        SearchStore.selectBasket(response.data.tripId);
        if (response.data.confirmationNeeded) {
          this.setPrevSelectedOffer(response.data.prevRailSelection);
          await this.getSearchSession({searchId: this.searchId});
          this.setShowModal(true);
        } else {
          this.setShowModal(false);
          this.stopSearch();
          this.setSelectedFirstLeg(false);
          this.setSelectedOffer([]);

          router.push({
            name: 'basket',
            params: { id: this.basketId }
          });
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setErrMessages(error, true);
        this.setShowError(true);
        router.push({
          name: 'trainDetails',
          params: router.currentRoute.params,
        });
      } else {
        this.setErrMessages(error);
        this.setShowError(true);
      }
    }
  }

  @Action
  convertFiltersStats(stats) {
    this.updateConvertedFilters([]);

    filtersList.train.filters.categoriesFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertCategoriesFilterStats(filterStats);
    });

    this.updateFilters(this.convertedFilters);
  }

  @Action({ rawError: true })
  convertRangeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        }
      };
      this.addConvertedFilter(converted);
    }
  }

  @Action({ rawError: true })
  convertRangeTimeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        },
        data: stats.rangesStatItems.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
            maxLimit: i.maxLimit,
            minLimit: i.minLimit,
          };
        }),
      };
      this.addConvertedFilter(converted);
    }
  }

  @Action({ rawError: true })
  convertCategoriesFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: stats.items.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
          };
        }),
      };
      this.addConvertedFilter(converted);
    }
  }

  @Action
  async updateFee(feeValue) {
    let errors: any[] = [];
    let data = null;
    try {
      const response = await TrainApi.updateSearchProposalFee(this.searchId, this.editedFeeProposal.id, {
        agencyFee: {
          amount: feeValue,
          currency: {
            code: this.editedFeeProposal.agencyFee.currency.code,
            symbol: this.editedFeeProposal.agencyFee.currency.symbol,
          }
        }
      });
      data = response.data;
      this.updateFeeInResults(data);
    } catch (error) {
      errors = $handleErrors(error, true);
    }

    return {
      data,
      errors,
    };
  }

  @Action
  async updateAgencyMarkup(agencyMarkupValue) {
    let errors: any[] = [];
    let data = null;
    try {
      const response = await TrainApi.updateSearchProposalAgencyMarkup(
        this.searchId,
        this.editedFeeProposal.id,
        {
          amount: agencyMarkupValue,
          currency: {
            ...this.editedFeeProposal.agencyMarkup.currency,
          }
        }
      );
      data = response.data;
      this.updateAgencyMarkupInResults(data);
    } catch (error) {
      errors = $handleErrors(error, true);
    }

    return {
      data,
      errors,
    };
  }

  @Action
  async updateFilterOffers(data) {
    this.updateFiltersRequestId();
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.setLoading(true);
    this.clearSearchTimeout();
    this.setSearchFreezed(true);
    this.createCancelTokenIfNeeded();

    this.setFiltersError(false);

    try {
      const response = await TrainApi.updateFilterOffers({ searchId: data.searchId }, this.filtersRequest.filtersData, this.cancelToken || undefined);
      if (response.data.statistics) {
        this.convertFiltersStats(response.data.statistics);
      }
    } catch (error) {
      this.setLoading(false);
      
      if (!isCancelError(error)) {
        this.setFiltersError(true);
        this.setErrMessages(error);
        this.setShowError(true);
      }
    }
  }
}

export default getModule(TrainSearchStore);