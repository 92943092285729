import { CompanyModel } from './company.model';
import { LanguagesModel } from './languages.model';

export interface TravellerModel {
  id: string;
  firstName: string;
  lastName: string;
  companyId?: string;
}

export interface ApproverModel {
  firstName: string;
  middleName: string;
  lastName: string;
  profileId: string;
  id: string;
}

export interface SelectedApproverModel {
  approverFirstName: string;
  approverMiddleName: string;
  approverLastName: string;
  approverId: string;
  businessUnitName?: string;
  rootCompanyName?: string;
  approvalLevel?: number;
  id?: string;
  profileId?: string;
}

export interface Currency {
  id: string;
  code: string;
  symbol: string;
  name: string;
}

export interface ProfileModel {
  id: string;
  title: Title;
  firstName: string;
  middleName: string;
  lastName: string;
  companyName?: string;
  companyCode?: string;
  companyId: string;
  defaultSearchMode: SearchInitModes;
  rootCompanyId: string;
  accountId: string;
  primaryEmail: string;
  dateOfBirth: string;
  rootCompanyName: string | null;
  isAgency: boolean;
  theme: any | null;
  displayLanguage: string;
  externalId: string | null;
  travellerType: any;
  state: string | null;
  travellerCategories: any[];
  shortDateFormat: string;
  timeFormat: string;
  primaryPhone: PrimaryPhoneModel | null;
  isGuest: any;
  company?: any;
  invoicingId: string | null;
  agencyId: string | null;
}

export interface PrimaryPhoneModel {
  code: string;
  number: string;
}


export interface ProfileFullNameModel {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface ProfileDataModel {
  id: string;
  title: Title;
  firstName: string;
  lastName: string;
  middleName: string;
  companyId: string;
  accountId: string;
  primaryEmail: string;
  companyName: string;
  companyCode: string;
  rootCompanyName: string;
  dateOfBirth: string;
  rootCompanyId: string;
  displayLanguage: string;
  theme: Theme;
  isApprover: boolean;
  state: string;
  externalId: string;
  isGuest: boolean;
  isAgency: boolean;
  travellerType: string;
  travellerGroup: string;
  company: CompanyModel;
}

export interface Theme {
  themeVersion: string;
  themeName: string;
  primaryColor: string;
  accentColor: string;
  footer: string;
}

export interface EmailConfirmedResponse {
  userName: string;
  isConfirmed: boolean;
  recoveryEmail: string;
}

export interface InvitationEmailResendResponse {
  actionStatus: ProfileResponseActionStatusType;
}

export interface CreateProfileResponse {
  id: string;
  accountAdded: ProfileResponseActionStatusType | null;
  invitationEmailSent: ProfileResponseActionStatusType | null;
  profileAdded: ProfileResponseActionStatusType | null;
  rolesAdded: ProfileResponseActionStatusType | null;
}

export interface ProfileResultsModel {
  results: ProfileModel[];
  page: PageModel;
}

export interface PageModel {
  pageSize: number;
  moreResultsAvailable: boolean;
}

export interface ProfileState {
  state: string;
}

export interface ProfileResultsModel {
  results: ProfileModel[];
  page: PageModel;
}

export interface PageModel {
  pageSize: number;
  moreResultsAvailable: boolean;
}

export interface ProfileModifiedModel {
  id: string;
  title: Title;
  firstName: string;
  lastName: string;
  companyId: string;
  accountId: string;
  primaryEmail: string;
  dateOfBirth: string;
  group: string;
  businessUnit: string;
}

export interface ApplicationModule {
  enabled: boolean;
  name: string;
}

export interface SessionProfileModel {
  profile: ProfileModel;
  canSwitchProfiles: boolean;
  isSwitched: boolean;
  originalProfileId: string;
  accountName: string;
  modules: ApplicationModule[];
  intercomSettings: IntercomSettings | null;
}

export interface IntercomSettings {
  workspaceId: string;
}
export interface Title {
  value: string;
  name: string;
}

export interface ProfileUpdateMessage {
  title: Title | any;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: any;
  primaryEmail?: string | null;
  companyId: string | null;
  company: CompanyModel | null;
  rootCompanyName: string | null;
  displayLanguage: string | null;
  externalId: string | null;
  travellerType: any;
  state?: any | string | null;
  travellerCategories: any[];
  isGuest: any | boolean;
  invoicingId: string | null;
}

export interface ProfileCreateMessage {
  title: Title;
  firstName: string;
  lastName: string;
  middleName: string;
  language: string;
  companyId: string;
  dateOfBirth: string | null;
  roles?: RoleModel[];
  state: ProfileStateEnum;
  account?: AccountModel;
  contacts: ContactProfileModel;
  travellerType: string | null;
  travellerCategories: any[];
  externalId: string;
  isGuest: boolean;
  invoicingId: string | null;
}

export interface ContactProfileModel {
  primaryEmail: string;
}

export interface AccountModel {
  userName: string;
  email: string;
  sendInvitationEmail: boolean;
}

export interface RoleModel {
  roleId: string;
  scope: string;
  selectedBusinessUnits: string;
  includeAgencyCustomers: boolean;
}

export interface ProfileAssignCompanyMessage {
  companyId: string;
}

export interface AvatarModel {
  size: {
    type: string;
    length: number;
    height: number;
    width: number;
  };
  format: string;
  image: string;
}

export interface AgenciesModel {
  id: string;
  name: string;
}

export interface AssignedAgencyMessage {
  agencyId: string;
}

export interface AvatarCollectionModel {
  id: string;
  avatar: string;
  usageTimestamp: number;
}
export interface CheckTravellersData {
  rootCompanyId: string;
  travellers: Traveller[];
}

export interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
}

export interface CheckTravellersModel {
  hasSimilarTravellers: boolean;
  existingTravellers: ExistingTraveller[];
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
}

export interface ExistingTraveller {
  id: string;
  businessUnitId: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
  businessUnitName: string;
  dateOfBirth: string;
  middleName: string;
  primaryPhoneNumber: string;
  primaryPhoneNumberCode: string;
  disabled?: boolean;
}

export class CreateAccountMessage {
  userName: string;
  email: string;
  sendInvitationEmail: boolean;

  constructor() {
    this.userName = '';
    this.email = '';
    this.sendInvitationEmail = true;
  }
}

export enum AvatarVariants {
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
}

export enum ProfileStateEnum {
  draft = 'Draft',
  active = 'Active',
  deleted = 'Deleted',
  inactive = 'Inactive',
}

export enum ProfileResponseActionStatusType {
  Successfull = 'ActionEndedSuccessfully',
  Errors = 'ActionEndedWithErrors',
  NotTaken = 'ActionNotTaken'
}

export enum LoyaltyTypeEnum {
  Discount = 'Discount',
  Loyalty = 'Loyalty',
}

export enum SearchInitModes {
  EnterNames = 'EnterNames',
  SelectGuests = 'SelectGuests',
  SelectGuestsWithNoCompanySelection = 'SelectGuestsWithNoCompanySelection',
}
