import EditRoleForm from '@/modules/settings/roles/EditRoleForm.vue';

export default {
  path: ':roleId',
  name: 'edit-role',
  component: EditRoleForm,
  displayName: 'Roles & permissions - edit role',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRoles',
    depthCategory: 'settings-roles',
    depth: 2,
    parentName: 'settings-roles',
  },
};
