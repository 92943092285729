import OfflineRequests from '@/modules/offline/OfflineRequests.vue';
import OfflineRequestsIntro from '@/modules/offline/OfflineRequestsIntro.vue';

export default {
  path: '/offline',
  name: 'offline',
  components: {
    default: OfflineRequests,
    intro: OfflineRequestsIntro,
  },
  meta: {
    permission: 'Client',
  }
};
