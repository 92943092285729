























































import { Vue, Prop, Component, Emit } from 'vue-property-decorator';

import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ServerError } from '@/api/home/home.model';

import { GetCompaniesMessage, CompanyModel, CreateCompanyMessage, UpdateCompanyMessage } from '@/api/profile/company.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Permission } from '@/const/permission.enum';

const togglePopupEvent = 'update:toggle';
const companyDataChangedEvent = 'companyDataChanged';

@Component
export default class CompanyData extends Vue {
  @Prop() companyData!: CompanyModel | null;
  @Prop() isEditing!: boolean;
  @Prop() toggle!: boolean;

  Form: UpdateCompanyMessage = {
    name: null,
    code: null,
    address: '',
    phoneNumber: '',
    faxNumber: '',
    emailAddress: '',
    parentId: null,
    isDisabled: false
  };

  serverErrors: any[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        parentId: {},
        name: { required },
        code: { required },
        isDisabled: {},
      },
    };
  }

  created() {
    if (this.companyData) {
      if (this.isEditing) {
        this.Form.name = this.companyData.name;
        this.Form.code = this.companyData.code;
        this.Form.isDisabled = this.companyData.isDisabled;
        this.Form.parentId = this.companyData.parentId;
      }
      else {
        this.Form.name = '';
        this.Form.code = '';
        this.Form.parentId = this.companyData.id;
      }
    }
  }

  async submit() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {
      if (this.isEditing) {
        await ProfileCompanyApi.updateCompany(this.Form, this.companyData!.id, Permission.WriteCompanyInfo);
      } else {
        let message = new CreateCompanyMessage();
        message.name = this.Form.name!;
        message.code = this.Form.code!;
        message.parentId = this.Form.parentId;
        message.isDisabled = this.Form.isDisabled;
        await ProfileCompanyApi.createCompany(message);
      }
      const obj = {
        type: translate('common.success'),
        title: translate('common.data-saved'),
        message: translate('settings-company.saved')
      };
      EventHandler.$emit('show-toast', obj);
      this[companyDataChangedEvent]();
      this.toggleValue = false;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      // todo: loading state
    }
  }

  @Emit()
  [togglePopupEvent](val) {
    return val;
  }

  @Emit()
  [companyDataChangedEvent]() {
    return;
  }

  get toggleValue() {
    return this.toggle;
  }

  set toggleValue(value: boolean) {
    this[togglePopupEvent](value);
  }

  close() {
    this.toggleValue = false;
  }
}

