import _ from 'lodash';
import { createDecorator } from 'vue-class-component';

export const Debounce = function (parameters) {
  return createDecorator((options, key) => {
    if (!options || !options.methods || !parameters || !parameters.delay) {
      return;
    }

    const originalMethod = options.methods[key];
    let methodThis: any | null;
    let debounceOptions: any = {};
    if (parameters.maxWait) {
      debounceOptions.maxWait = parameters.maxWait;
    }
    let methodParameters: any | null;
    const debounceMethod = _.debounce(function () {
      originalMethod.apply(methodThis, methodParameters);
    }, parameters.delay, debounceOptions);

    options.methods[key] = function () {
      methodThis = this;
      methodParameters = Array.prototype.slice.call(arguments);
      if (parameters.flag) {
        this[parameters.flag] = true;
      }

      debounceMethod();
    };

    debounceOptions = null;
  });
};
