








import { Vue, Component } from 'vue-property-decorator';

import layoutStore from './layout.store';

@Component({})
export default class FakeSidebar extends Vue {
  mounted() {
    layoutStore.registerFakeSidebar({
      ref: this.$refs.fake
    });
  }
}
