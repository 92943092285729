import Notifications from '@/modules/notifications/Notifications.vue';
import NotificationsIntro from '@/modules/notifications/NotificationsIntro.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/notifications',
  name: 'notifications',
  components: {
    default: Notifications,
    intro: NotificationsIntro,
  },
  displayName: 'Notifications',
  meta: {
    introClass: 'intro-notifications',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  },
};
