import TravelPolicy from '@/modules/settings/travel-policy/configurations/TravelPolicy.vue';
import TravelPolicyConfigurations from './travel-policy/travel-policy-configurations.route';
import TravelPolicyConfiguration from './travel-policy/travel-policy-configuration.route';
import TravelPolicyFlightRules from './travel-policy/travel-policy-flight-rule.route';
import TravelPolicyRailManageRule from './travel-policy/travel-policy-rail-rule.route';
import TravelPolicyAccomodationManageRule from './travel-policy/travel-policy-accomodation-rule.route';
import TravelPolicyCarRules from './travel-policy/travel-policy-car-rule.route';
import { translate } from '@/i18n';

export default {
  path: 'travel-policy',
  component: TravelPolicy,
  displayName: 'settings-sidebar.travel-policy',
  meta: {
    permission: 'ReadTravelPolicy',
    introClass: 'intro-settings',
    icon: 'policy',
    parentName: 'travel-policy-configurations',
  },
  children: [
    TravelPolicyConfigurations,
    TravelPolicyConfiguration,
    TravelPolicyFlightRules,
    TravelPolicyRailManageRule,
    TravelPolicyAccomodationManageRule,
    TravelPolicyCarRules,
  ],
};
