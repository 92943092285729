import ProfileSync from '@/modules/settings/profile-sync/ProfileSync.vue';
import ProfileSyncList from './profile-sync-configurations.route';
import ProfileSyncConfig from './profile-sync-configuration.route';
import { translate } from '@/i18n';

export default {
  path: 'profile-sync-configurations',
  component: ProfileSync,
  displayName: 'settings-sidebar.profile-sync',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProfilesSynchronization',
    icon: 'autorenew',
    breadCrumb: 'Profile synchronization configuration',
    parentName: 'profile-sync-configurations',
  },
  children: [
    ProfileSyncList,
    ProfileSyncConfig,
  ],
};