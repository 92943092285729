import SecurityConfigurations from '@/modules/settings/security/SecurityConfigurations.vue';

export default {
  path: '',
  name: 'security-configurations',
  component: SecurityConfigurations,
  displayName: 'Security Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditSecurityConfiguration',
    depthCategory: 'security-configurations',
    parentName: 'security-configurations',
    depth: 1,
  }
};