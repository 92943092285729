import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { router } from '@/router';
import { store } from '@/store';
import AccountStore from '@/store/account.store';
import { RolesApi } from '@/api/roles/roles.api';
import { ProfileApi } from '@/api/profile/profile.api';
import {
  ProfileRoleModel,
  RoleModel,
  ScopeValue,
  RoleModelScope

} from '@/api/roles/roles.model';
import { Permission } from '@/const/permission.enum';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'profileRoles'
})
class RolesProfileStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;

  profileCompanyRoles: RoleModel[] = [];
  availableScopes: ScopeValue[] = [];
  
  profileRoles: ProfileRoleModel[] = [];
  profileRoleDetails = null;

  serverErrors: any[] = [];
  showError: boolean = false;
  showErrorPopup: boolean = false;
  errors: any[] = [];
  closePopup: boolean = false;

  get ProfileCompanyRoles(): RoleModel[] {
    return this.profileCompanyRoles;
  }

  get AvailableScopes(): ScopeValue[] {
    return this.availableScopes;
  }

  get ProfileRoles(): ProfileRoleModel[] {
    return this.profileRoles;
  }

  get IsLoading(): boolean {
    return this.loading;
  }

  get IsLoaded(): boolean {
    return this.loaded;
  }

  @Mutation
  startLoading() {
    this.loading = true;
  }

  @Mutation
  finishLoading() {
    this.loading = false;
  }

  @Mutation
  loadingSuccess() {
    this.loaded = true;
  }

  @Mutation
  setClosePopup(value) {
    this.closePopup = value;
  }

  @Mutation
  setCurrentProfileRoles(profileRoles) {
    this.profileRoles = profileRoles;
  }

  @Mutation
  setCurrentProfileCompanyRoles(profileCompanyRoles) {
    this.profileCompanyRoles = profileCompanyRoles;
  }

  @Mutation
  setCurrentAvailableScopes(availableScopes) {
    this.availableScopes = availableScopes;
  }

  @Mutation
  setProfileRoleDetails(profileRoles) {
    this.profileRoleDetails = profileRoles;
  }

  @Mutation
  setErrMessages(error) {
    this.serverErrors = $handleErrors(error, true);
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setShowErrorPopup(value) {
    this.showErrorPopup =  value;
  }

  @Action
  public async init() {
    this.setShowError(false);
    this.startLoading();
    try {
      let currentUser = AccountStore.CurrentUser;
      let profileId = router.currentRoute.params.id;
      if (!profileId && currentUser) {
        profileId = currentUser.profile.id;
      }

      if (currentUser) {
        let profileRoles = await RolesApi.getProfileRoles(
          profileId
        );

        this.setCurrentProfileRoles(profileRoles.data);
      }

      this.loadingSuccess();
    } catch (error) {
      this.setCurrentProfileRoles(null);
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async loadProfileCompanyRoles() {
    this.startLoading();
    this.setShowError(false);
    try {
      let currentUser = AccountStore.CurrentUser;
      let profileId = router.currentRoute.params.id;
      if (!profileId && currentUser) {
        profileId = currentUser.profile.id;
      }

      if (currentUser) {
        let companyId = currentUser.profile.companyId;
        if (profileId !== currentUser.profile.id) {
          let profileResult = await ProfileApi.getById(profileId, Permission.ReadProfilePersonal);
          companyId = profileResult.data.companyId;
        }

        let profileCompanyRolesWithScopes = await RolesApi.getCompanyRolesWithScopesForSelect(
          companyId
        );

        this.setCurrentProfileCompanyRoles(profileCompanyRolesWithScopes.data.roles);
        this.setCurrentAvailableScopes(profileCompanyRolesWithScopes.data.availableScopes);
      }

      this.loadingSuccess();
    } catch (error) {
      this.setErrMessages(error);
      this.setShowErrorPopup(true);
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async loadRoles(companyId) {
    this.startLoading();
    this.setShowError(false);
    try {
      let profileCompanyRolesWithScopes = await RolesApi.getCompanyRolesWithScopesForSelect(
        companyId
      );

      this.setCurrentProfileCompanyRoles(profileCompanyRolesWithScopes.data.roles);
      this.setCurrentAvailableScopes(profileCompanyRolesWithScopes.data.availableScopes);

      this.loadingSuccess();
    } catch (error) {
      this.setErrMessages(error);
      this.setShowErrorPopup(true);
    }
  }

  @Action
  public async getProfileRoleDetails(roleId) {
    this.setShowError(false);
    let profileId = router.currentRoute.params.id;
    this.setProfileRoleDetails(null);

    try {
      let profileRoleDetails = await RolesApi.getRoleDetails(
        profileId,
        roleId
      );

      if (profileRoleDetails && profileRoleDetails.data) {
        this.setProfileRoleDetails(profileRoleDetails.data);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  public async removeRole(role: ProfileRoleModel) {
    this.setShowError(false);
    this.startLoading();
    try {
      let currentUser = AccountStore.CurrentUser;
      let profileId = router.currentRoute.params.id;
      if (!profileId && currentUser) {
        profileId = currentUser.profile.id;
      }

      if (currentUser) {
        await RolesApi.removeRoleFromProfile(
          profileId,
          role.roleId
        );

        let roleIndex = this.profileRoles.indexOf(role);
        if (roleIndex >= 0) {
          this.profileRoles.splice(roleIndex, 1);
        }
      }

      this.loadingSuccess();
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.finishLoading();
    }
  }
}

export default getModule(RolesProfileStore);
