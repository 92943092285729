import Customers from '@/modules/settings/customers/Customers.vue';
import CustomersList from './customers/customers-list.route';
import { translate } from '@/i18n';

export default {
  path: 'customers',
  component: Customers,
  displayName: 'settings-sidebar.customers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadAgencyCustomers',
    icon: 'contacts',
    parentName: 'customers',
  },
  children: [
    CustomersList,
  ],
};