import CommunityAddProfile from '@/modules/settings/community/AddProfile.vue';

export default {
  path: 'add-profile',
  name: 'community-add-profile',
  component: CommunityAddProfile,
  displayName: 'Community add profile',
  meta: {
    introClass: 'intro-settings',
    fluid: true,
    permission: 'ReadCommunity',
    depthCategory: 'settings-community',
    depth: 2,
    parentName: 'community',
  }
};
