


































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import BasketStore from './basket.store';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

// to trzeba zmienić chyba nazwę, bo to dotyczy AIR_TRAVELFUSION

@Component({
  components: {
    BasketItem,
    BasketExpirationCountdown,
  }
})
export default class BasketCustomFieldView extends Vue {
  @Prop() basketExpired!: boolean;

  get basket() {
    return BasketStore.basket;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get loading() {
    return BasketStore.loading;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'AIR_TRAVELFUSION') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }

  get errors() {
    return BasketStore.ancillariesAndPassengerDataErrors;
  }

  onBasketItemPaymentCardChanged(item) {
    let foundBasketItem = this.basketItems.find(basketItem => { return basketItem.id === item.id; });
    if (foundBasketItem) {
      foundBasketItem.selectedPaymentCard = item.selectedPaymentCard;
    }
  }

  created() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    BasketStore.resetPriceChangesForStep({step: this.bookingStep, tripItemId: stepDef.tripItemId});
    BasketStore.resetSelectedAncillaries();
  }
}

