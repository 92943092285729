import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  AuditRuleModel,
} from './expense-audit-rules.model';

class ExpenseAuditRulesApiClass {
  public getAuditRules(policyId: string, stage: string, scope: string): Promise<AxiosResponse<AuditRuleModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules?PolicyId={policyId}&Stage={stage}&Scope={scope}',
      method: RequestMethod.GET,
    }, { policyId, stage, scope }, {});
  }

  public getAuditRule(auditRuleId: string): Promise<AxiosResponse<AuditRuleModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules/{auditRuleId}',
      method: RequestMethod.GET,
    }, { auditRuleId }, {});
  }

  public getAuditRuleExpressionCreator(auditRuleId: string): Promise<AxiosResponse<AuditRuleModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules/{auditRuleId}/expression-parameters',
      method: RequestMethod.GET,
    }, { auditRuleId }, {});
  }

  public createAuditRule(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateAuditRule(auditRuleId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules/{auditRuleId}',
      method: RequestMethod.PUT,
    }, { auditRuleId }, params );
  }

  public removeAuditRule(auditRuleId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/audit-rules/{auditRuleId}',
      method: RequestMethod.DELETE,
    }, { auditRuleId }, {} );
  }

}

export const ExpenseAuditRulesApi: ExpenseAuditRulesApiClass = new ExpenseAuditRulesApiClass();
