import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { 
  PagingQuery,  
  ExpenseResult, 
  ExpenseModel, 
  CreateExpenseResult,  
  MissionResult,
  MissionModel,
  CreateMissionResult,
  ExpenseCategoryResult,
  ReceiptFileModel, 
  MissionNamesResult,
  DefaultExpensePolicyResult,
  ExpectedExpenseModel,
  ValidationResultResponseModel,
  NotificationModel,
  NotificationResponseModel,
  TripItemTypeCategoryResult,
  GetExpensesMessage,
  GetMissionsMessage,
  ExpensePolicyIdResult,
  SubmitExpenseReportResult
} from './expense.model';

class ExpenseApiClass {
  public getExpenseList(params: GetExpensesMessage): Promise<AxiosResponse<ExpenseResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/search',
      method: RequestMethod.POST
    }, {}, params);
  }

  public addExpense(params: ExpenseModel): Promise<AxiosResponse<CreateExpenseResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses',
      method: RequestMethod.POST
    }, {}, params);
  }

  public getExpenseItem(expenseId: string): Promise<AxiosResponse<ExpenseResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/{expenseId}',
      method: RequestMethod.GET,
    }, {expenseId}, {});
  }

  public updateExpenseItem(expenseId: string, params: ExpenseModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/{expenseId}',
      method: RequestMethod.PUT,
    }, {expenseId}, params);
  }

  public removeExpenseItem(expenseId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/{expenseId}',
      method: RequestMethod.DELETE
    }, {expenseId}, {});
  }

  public addExpenseFromReceipt(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/fromReceipt',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateExpenseFromReceipt(expenseId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/{expenseId}/fromReceipt',
      method: RequestMethod.PUT,
    }, {expenseId}, params);
  }

  public getExpenseReceiptImage(expenseId: string, size: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/{expenseId}/attachments/receipt?size={size}',
      method: RequestMethod.GET,
    }, {expenseId, size}, {});
  }

  public getMissionsList(params: GetMissionsMessage): Promise<AxiosResponse<MissionResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/missions/search',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getMissionsNames(profileId: string, policyId: string, expenseAssignmentAllowed: boolean): Promise<AxiosResponse<MissionNamesResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/missions/names?profileId={profileId}&policyId={policyId}&expenseAssignmentAllowed={expenseAssignmentAllowed}',
      method: RequestMethod.GET
    }, {profileId, policyId, expenseAssignmentAllowed}, {});
  }

  public addMission(params: MissionModel): Promise<AxiosResponse<CreateMissionResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/missions',
      method: RequestMethod.POST
    }, {}, params);
  }

  public getMissionItem(missionId: string): Promise<AxiosResponse<MissionResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/missions/{missionId}',
      method: RequestMethod.GET,
    }, {missionId}, {});
  }

  public updateMissionItem(missionId: string, params: MissionModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/missions/{missionId}',
      method: RequestMethod.PUT,
    }, {missionId}, params);
  }

  public removeMissionItem(missionId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/missions/{missionId}',
      method: RequestMethod.DELETE
    }, {missionId}, {});
  }

  public submitExpenseReport(missionId: string): Promise<AxiosResponse<SubmitExpenseReportResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/{missionId}/submit-expense-report',
      method: RequestMethod.PUT,
    }, {missionId}, {});
  }

  public getExpenseCategories(policyId: string): Promise<AxiosResponse<ExpenseCategoryResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/expenseCategories',
      method: RequestMethod.GET,
    }, {policyId}, {});
  }

  public getDefaultExpensePolicy(permission): Promise<AxiosResponse<ExpensePolicyIdResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/default',
      permission: permission,
      method: RequestMethod.GET,
    }, {}, {permission});
  }

  public getExpectedExpenseModel(policyId: string, expenseState: string, expenseType: string): Promise<AxiosResponse<ExpectedExpenseModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/expectedExpense?expenseState={expenseState}&expenseType={expenseType}',
      method: RequestMethod.GET,
    }, {policyId, expenseState, expenseType}, {});
  }

  public getPaymentInstrumentsDictionary(policyId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments/names?policyId={policyId}',
      method: RequestMethod.GET,
    }, {policyId}, {});
  }

  public getValidationResults(expenseId: string | undefined, missionId: string): Promise<AxiosResponse<ValidationResultResponseModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/validation-results?expenseId={expenseId}&missionId={missionId}',
      method: RequestMethod.GET,
    }, {expenseId, missionId}, {});
  }

  public updateValidationResults(validationResultId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/validation-results/{validationResultId}',
      method: RequestMethod.PUT,
    }, {validationResultId}, params);
  }

  public getNotifications(policyId: string, profileId: string): Promise<AxiosResponse<NotificationModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/notifications?policyId={policyId}&profileId={profileId}',
      method: RequestMethod.GET,
    }, {policyId, profileId}, {});
  }

  public getNotificationsDetails(notificationId): Promise<AxiosResponse<NotificationResponseModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/notifications/{notificationId}',
      method: RequestMethod.GET,
    }, {notificationId}, {});
  }

  
  public getExpensePolicies(configurationId: string): Promise<AxiosResponse<ExpenseCategoryResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies?configurationId={configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public getExpensePolicyDetails(expensePolicyId: string): Promise<AxiosResponse<ExpenseCategoryResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{expensePolicyId}',
      method: RequestMethod.GET,
    }, { expensePolicyId }, {});
  }

  public createExpensePolicy(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateExpensePolicy(expenseId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{expenseId}',
      method: RequestMethod.PUT,
    }, { expenseId }, params );
  }

  public removeExpensePolicy(expensePolicyId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{expensePolicyId}',
      method: RequestMethod.DELETE,
    }, { expensePolicyId }, {} );
  }

  public updateDefaultExpensePolicy(configurationId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/configurations/{configurationId}/default-expense-policy',
      method: RequestMethod.PUT,
    }, { configurationId }, params );
  }

  public registerAsExpense(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expenses/from-trip-item',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getCategoriesForTripType(policyId: string): Promise<AxiosResponse<TripItemTypeCategoryResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/trip-item-type-categories',
      method: RequestMethod.GET,
    }, { policyId  }, {});
  }

  public updateCategoriesForTripType(policyId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/trip-item-type-categories',
      method: RequestMethod.PUT,
    }, { policyId }, params, true );
  }

  public createManualApprovalTasks(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/manualApprovalTasks',
      method: RequestMethod.POST,
    }, {}, params );
  }

  public updateManualApprovalTasks(id: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/manualApprovalTasks/{id}',
      method: RequestMethod.PUT,
    }, { id }, params );
  }

  public getCustomFieldsCollection(policyId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/custom-fields?policyId={policyId}',
      method: RequestMethod.GET
    }, { policyId }, {});
  }

  public expressionCreator(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expression-creator',
      method: RequestMethod.POST,
    }, {}, params );
  }
}

export const ExpenseApi: ExpenseApiClass = new ExpenseApiClass();
