import { createDecorator } from 'vue-class-component';

export const AnimatedIn = function () {
  return createDecorator((options, key) => {
    if (!options) {
      return;
    }

    if (!options.computed) {
      options.computed = {};
    }
    options.computed.$animatedInHookEnabled = {
      get() {
        return key;
      },
    };
  });
};