



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import MyMissionsTable from './MyMissionsTable.vue';

@Component({
   components: {
    MyMissionsTable,
  },
})
export default class MyMissions extends Vue {

   addMission() {
    router.push({
      name: 'add-mission',
    });
  }
  
}

