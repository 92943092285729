



















































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CorporateCode, AirNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import NegoFaresStore from '@/modules/settings/nego-fare/nego-fares.store';
import NegoFaresFlightStore from '@/modules/settings/nego-fare/nego-fares-flight.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class NegoFareCodesAir extends BaseTable<CorporateCode> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: CorporateCode | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    provider: {
      sortable: false,
      label: translate('settings-nego-fare.provider'),
      thClass: 'column-width',
    },
    description: {
      sortable: false,
      label: translate('settings-nego-fare.description'),
      tdClass: 'mw-200',
      thClass: 'column-width',
    },
    code: {
      sortable: false,
      label: translate('settings-nego-fare.code'),
      tdClass: 'mw-200',
      thClass: 'column-width',
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  providerOptions = [
    AirNegoFareConfigurationSupplierEnum.Unknown,
    AirNegoFareConfigurationSupplierEnum.Sabre,
    AirNegoFareConfigurationSupplierEnum.Lufthansa,
    AirNegoFareConfigurationSupplierEnum.AirFranceKlm,
    AirNegoFareConfigurationSupplierEnum.Amadeus
  ];

  get negoFareConfigs() {
    return this.configuration.negoFaresConfigurations;
  }

  get activeTab() {
    return NegoFaresStore.selectedTab;
  }

  get entries() {
    return NegoFaresFlightStore.CorporateCodes;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return NegoFaresFlightStore.currentConfiguration;
  }

  get configurationId() {
    return router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : undefined;
  }

  get count() {
    return this.entries ? this.entries.length : 0;
  }

  get errors() {
    return NegoFaresFlightStore.errors;
  }

  getCarrierName(name) {
    return SupplierName(name);
  }

  toggleMenu(item) {
    if (item !== this.selected) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  addCode() {
    NegoFaresFlightStore.selectCorporateCode({});
    if (this.configurationId) {
      router.push({
        name: 'air-corporate-code',
        params: {
          configurationId: this.configurationId
        }
      });
    } else {
      router.push({
        name: 'air-corporate-code',
        params: {
          configurationId: 'new'
        }
      });
    }
  }

  editCode(codeItem) {
    NegoFaresFlightStore.selectCorporateCode(codeItem);
    if (this.configurationId) {
      router.push({
        name: 'air-corporate-code',
        params: {
          configurationId: this.configurationId
        }
      });
    } else {
      router.push({
        name: 'air-corporate-code',
        params: {
          configurationId: 'new'
        }
      });
    }
  }

  removeCode() {
    NegoFaresFlightStore.removeCorporateCode(this.selected);
    this.closeRemovePopup();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }
}
