






















import { Vue, Component, Prop } from 'vue-property-decorator';
import BasketStore from './basket.store';
import BasketTrenitaliaSeatSelection from './BasketTrenitaliaSeatSelection.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

@Component({
  components: {
    BasketTrenitaliaSeatSelection,
    BasketExpirationCountdown
  }
})
export default class BasketTrenitaliaSeatSelectionView extends Vue {
  @Prop() basketExpired!: boolean;

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }


  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'RAIL_TRENITALIA_SEAT_SELECTION') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }
}
