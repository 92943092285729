import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { router } from '@/router';
import { store } from '@/store';
import AccountStore from '@/store/account.store';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel } from '@/api/profile/preferences.model';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'preferencesStore'
})
class PreferencesStore extends VuexModule {
  preferences: PreferencesModel | null = null;
  serverErrors: any[] = [];
  showError: boolean = false;

  @Mutation
  setPreferences(payload) {
    this.preferences = payload;
  }

  @Mutation
  setErrMessages(error) {
    this.serverErrors = $handleErrors(error, true);
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Action
  public async loadPreferences(profileId) {
    this.setShowError(false);
    try {
      let result = await PreferencesApi.getPreferences(profileId);
      if (result && result.data) {
        this.setPreferences(result.data);
      }
    } catch (error) {
      this.setShowError(true);
      this.setErrMessages(error);
    }
  }
}

export default getModule(PreferencesStore);
