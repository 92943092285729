import ProfileById from '@/modules/profile/ProfileById.vue';
import personal from './personal.route';
import roles from './roles.route';
import contacts from './contacts.route';
import documents from './documents.route';
import payment from './payment-cards.route';
import preferences from './preferences.route';
import loyaltyPrograms from './loyalty-programs.route';
import designatedTravellers from './designated-travellers.route';
import designatedApprovers from './designated-approvers.route';
import customFields from './custom-fields.route';
import managerAssignment from './manager-assignment.route';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: ':id',
  component: ProfileById,
  redirect: ':id/personal',
  children: [
    personal,
    contacts,
    preferences,    
    documents,
    loyaltyPrograms,
    designatedTravellers,
    designatedApprovers,
    roles,
    payment,
    customFields,
    managerAssignment,
  ],
  meta: {
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  }
};
