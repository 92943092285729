























































import { Vue, Component, Watch } from 'vue-property-decorator';
import EventBus from '@/services/event-handler';
import SearchTravellers from '@/modules/search/SearchTravellers.vue';
import AccountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import settings from '@/settings';
import { TravellersStateParams } from '@/modules/search/travellers.params';

@Component({
  components: {
    SearchTravellers,
  }
})
export default class SearchChooseTravellers extends Vue {
  activeNav: boolean = false;
  skipTravellersModel: boolean = false;

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  set travellersSearchList(value) {
    SearchStore.updateTravellersDefaultState(value);
  }

  get skipTravellersModelSwitch() {
    return !this.skipTravellersModel;
  }

  set skipTravellersModelSwitch (value) {
    this.skipTravellersModel = !value;
    AccountStore.setChooseTravellerMode(true);
  }

  get canShowSkipTravellers() {
    return 'true' === settings.enableExternalSkipTravellers;
  }

  get skipTravellers() {
    return this.skipTravellersModel = SearchStore.skipTravellers;
  }

  get disabledSearchButton() {
    return this.travellersSearchList.travellers.length === 0;
  }

  goToSearch() {
    if (
      this.travellersSearchList.travellers &&
      this.travellersSearchList.travellers.length > 0
    ) {
      let next: any = {
        name: 'search',
      };
      SearchStore.selectBasket('');
      SearchStore.setMissionId('');
      if (this.$route.query.offlineRequestId) {
        next.query = {
          offlineRequestId: this.$route.query.offlineRequestId,
        };
      }
      this.$router.push(next);
    }

    setTimeout(() => {
      EventBus.$emit('focus-from', true);
    }, 500);
  }
  
  goToSearchWithSkipTrav() {
    let next: any = {
      name: 'search',
    };
    SearchStore.selectBasket('');
    SearchStore.setMissionId('');
    if (this.$route.query.offlineRequestId) {
      next.query = {
        offlineRequestId: this.$route.query.offlineRequestId,
      };
    }
    SearchStore.updateTravellersDefaultState(new TravellersStateParams({ travellers: [] }));
    this.$router.push(next);
    
    setTimeout(() => {
      EventBus.$emit('focus-from', true);
    }, 500);
  }

  @Watch('skipTravellersModel', { immediate: true })
  onSkipTravellers(val) {
    if (val) {
      SearchStore.updateSkipTravellers(this.skipTravellersModel);
      if (this.skipTravellersModel === true) {
        this.goToSearchWithSkipTrav();
      }
    }
  }

  created() {
    EventBus.$on('focus-on-search', this.goToSearch);
    setTimeout(() => {
        return this.activeNav = true;
      }, 10);
  }

  beforeDestroy() {
    EventBus.$off('focus-on-search', this.goToSearch);
    this.activeNav = false;
  }
}
