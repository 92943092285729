




















































































































































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { userFullName } from '@/core/user-full-name';
import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from '@/modules/basket/basket.store';
import {
  CancellationMethods,
  RefundDecisionMessage,
  RefundActionStatuses,
} from '@/modules/search/air/air-refund.model';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import moment from 'moment';
import AccountStore from '@/store/account.store';

const eventName = 'update:show';

@Component({})
export default class BasketAirFranceKlmRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;

  loadingCancellationInfo: boolean = true;
  loadingDetails: boolean = false;
  processing: boolean = false;
  cancellationInfoLoaded: boolean = false;
  cancellationInfo: any = null;
  processErrors: any[] = [];
  selectedOption: any = '';
  refundDetailsAFKLM: any = null;
  refundDetails: any[] = [];
  refundDetailsLoaded: boolean = false;
  refundDetailsLoadError: boolean = false;
  taxBreakdowns: any[] = [];
  $v;
  refundErrors: any[] = [];

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get warnings() {
    return this.cancellationInfo.warnings;
  }

  get hasWarnings() {
    return Array.isArray(this.warnings) && this.warnings.length > 0;
  }

  get voidDisplayOptions() {
    return {
      isVisible: this.cancellationInfo.void.isVisible,
      isEnabled: this.cancellationInfo.void.isEnabled,
      detailsText: this.cancellationInfo.void.isEnabled ? this.$t('refund.void-text') : this.$t('refund.void-text-disabled')
    };
  }

  get refundDisplayOptions() {
    if (!this.cancellationInfo.refund.isVisible) {
      return {
          isVisible: false,
          isEnabled: false,
          detailsText: ''
        };
    }

    if (this.cancellationInfo.refund.isEnabled) {
      if (this.isMobile) {
        return {
          isVisible: true,
          isEnabled: false,
          detailsText: this.$t('refund.mobile-info')
        };
      }

      return {
        isVisible: true,
        isEnabled: true,
        detailsText: this.$t('refund.refund-text')
      };
    }

    if (this.cancellationInfo.refundViaBspLink.isEnabled && !this.cancellationInfo.refundViaBspLink.isVisible) {
      return {
        isVisible: true,
        isEnabled: false,
        detailsText: this.$t('refund.refund-only-possible-via-bsp-link-but-no-permission-granted')
      };
    }

    return {
      isVisible: true,
      isEnabled: false,
      detailsText: this.$t('refund.refund-text-disabled')
    };
  }

  get refundViaBspLinkDisplayOptions() {
    if (!this.cancellationInfo.refundViaBspLink.isVisible) {
      return {
          isVisible: false,
          isEnabled: false,
          detailsText: ''
        };
    }

    if (this.cancellationInfo.refundViaBspLink.isEnabled) {
      if (this.isMobile) {
        return {
          isVisible: true,
          isEnabled: false,
          detailsText: this.$t('refund.mobile-info')
        };
      }

      return {
        isVisible: true,
        isEnabled: true,
        detailsText: this.$t('refund.refund-via-bsp-link-text')
      };
    }

    return {
      isVisible: true,
      isEnabled: false,
      detailsText: ''
    };
  }

  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCancellationInfo || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get travellersGroups() {
    if (!this.refundDetailsAFKLM) {
      return [];
    }

    return (this.refundDetailsAFKLM.items || []).map((item, index) => {
      return {
        ...item,
        traveller: {
          firstName: item.passengerInfo.firstName,
          passengerType: item.passengerInfo.passengerType,
          id: item.passengerInfo.profileId,
          lastName: item.passengerInfo.surname,
        }
      };
    });
  }

  get canCancelTicket() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get canRefundFlightTicket() {
    return AccountStore.HasPermission('CanRefundFlightTicket');
  }

  get isMobile() {
    return window.innerWidth <= 799.98;
  }

  userFullName(user) {
    return userFullName(user);
  }

  toggleTaxBreakdown(index, name) {
    this.taxBreakdowns[index][name] = !this.taxBreakdowns[index][name];
    this.$forceUpdate();
  }

  issueDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  @Watch('actionType', { immediate: true })
  onChangeAction(val) {
    if (-1 < [
      RefundActionStatuses.VerifyRefund,
      RefundActionStatuses.VerifyConditions,
    ].indexOf(val.code)) {
      this.loadRefundDetails();
      this.processing = false;
    } else if (val.code === RefundActionStatuses.VerifyStatus) {
      this.init();
    }
  }

  async beginProcess() {
    switch (this.selectedOption) {
      case CancellationMethods.void:
        await this.beginVoid();
        break;
      case CancellationMethods.refund:
        await this.beginRefund();
        break;
      case CancellationMethods.refundViaBspLink:
        await this.beginRefundViaBspLink();
        break;
    }
  }

  async beginVoid() {
    this.processErrors = [];
    this.processing = true;
    let voidResponse;
    try {
      voidResponse = await RefundBasketItemApi.startVoid(this.basketItemId);
    } catch (error) {
      this.processErrors = this.$handleErrors(error);
    } finally {
      if (voidResponse && voidResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      this.processing = false;
    }
  }

  async beginRefund() {
    this.processErrors = [];
    this.processing = true;
    let initResponse;
    this.cancellationInfoLoaded = false;
    try {
      initResponse  = await RefundBasketItemApi.startRefund(this.basketItemId);
    } catch (error) {
      this.processErrors = this.$handleErrors(error);
    } finally {
      if (initResponse && initResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
    }
  }

  async beginRefundViaBspLink() {
    this.processErrors = [];
    this.processing = true;
    let refundViaBspLinkResponse;
    this.cancellationInfoLoaded = false;
    try {
      refundViaBspLinkResponse  = await RefundBasketItemApi.startRefundViaBspLink(this.basketItemId);
    } catch (error) {
      this.processErrors = this.$handleErrors(error);
    } finally {
      if (refundViaBspLinkResponse && refundViaBspLinkResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
    }
  }

  async loadRefundDetails() {
    this.loadingDetails = true;
    let refundResponse;
    try {
      refundResponse = await RefundBasketItemApi.getRefundConditions(this.item.providerReferenceId, 'AirFranceKlm');
    } catch (error) {
      if (
        418 === error.response.status && error.response.data.error &&
        -1 < [
          'REFUND_NOT_AVAILABLE_ON_TICKETING_DATE',
          'REFUND_NOT_AVAILABLE',
        ].indexOf(error.response.data.error.code)
      ) {
        const message = {
          'REFUND_NOT_AVAILABLE_ON_TICKETING_DATE': translate('refund.not-available-on-ticketing-date'),
          'REFUND_NOT_AVAILABLE': translate('refund.not-available'),
        };
        this.processErrors = [{
          message: message[error.response.data.error.code],
        }];
      } else {
        this.processErrors = this.$handleErrors(error);
        }
      this.cancellationInfoLoaded = true;
      this.refundDetailsLoadError = true;
    } finally {
      if (refundResponse && refundResponse.status === 200) {
        this.taxBreakdowns = [];
        if (refundResponse.data.items) {
          refundResponse.data.items.forEach(item => {
            this.taxBreakdowns.push({
              new: false,
              original: false,
              diff: false,
            });
          });
        }
        this.refundDetailsAFKLM = refundResponse.data;
        this.refundDetailsLoaded = true;
      }
      this.loadingDetails = false;
      this.processing = false;
    }
  }

  async finalizeRefund() {
    this.refundErrors = [];
    this.processing = true;
    let refundResponse;
    try {
      refundResponse = await RefundBasketItemApi
        .acceptRefundCancellationConditions(this.basketItemId);
    } catch (error) {
      this.refundErrors = this.$handleErrors(error);
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      this.processing = false;
    }
  }

  async exitRefundPopup() {
    if (this.refundErrors.length) {
      this.hidePopup();
    }
    this.refundErrors = [];
    let refundResponse;
    this.processing = true;
    try {
      refundResponse = await RefundBasketItemApi.rejectRefund(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');

      }
      this.processing = false;
      this.hidePopup();
    }
  }

  async answerRefundConditions(decision: boolean) {
    const message: RefundDecisionMessage = {
      itemId: this.basketItemId,
      isConfirmed: decision
    };
    try {
      await RefundBasketItemApi.sendCouponDecision(message);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async answerRefundConditionsErrors() {
    try {
      await RefundBasketItemApi.answerCouponDecisionError(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async init() {
    this.loadingCancellationInfo = true;
    this.cancellationInfoLoaded = false;
    this.processErrors = [];
    let response;
    try {
      response = await RefundBasketItemApi.getCancellationInfo(this.basketItemId);
    } catch (err) {
      this.processErrors = this.$handleErrors(err);
    } finally {
      if (response && response.data) {
        this.cancellationInfo = response.data;
        this.cancellationInfoLoaded = true;
      }
      this.loadingCancellationInfo = false;
    }
  }

  created() {
    this.init();
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

