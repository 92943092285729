








































































































import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from './search.store';
import BasketStore from '@/modules/basket/basket.store';
import AccountStore from '@/store/account.store';
import EventBus from '@/services/event-handler';
import settings from '@/settings';
import TrainExchangeInfoSubmenu from '@/modules/search/train/TrainExchangeInfoSubmenu.vue';
import AirExchangeInfoSubmenu from '@/modules/search/air/AirExchangeInfoSubmenu.vue';
import CustomMessagesStore from '@/modules/global-settings/custom-messages/custom-messages.store';

@Component({
  components: {
    TrainExchangeInfoSubmenu,
    AirExchangeInfoSubmenu,
  }
})
export default class SearchMessages extends Vue {

  get user() {
    return AccountStore.current;
  }

  get missionId() {
    return SearchStore.missionId;
  }

  get missionDetails() {
    return SearchStore.mission;
  }

  get hasCustomMessages() {
    return CustomMessagesStore.hasMessages;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get isSearchView() {
    return this.$route.name === 'search';
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get basketMetadata() {
    return BasketStore.basketMetadata ? BasketStore.basketMetadata.metadata : null;
  }

  get externalMissionBaseUrl() {
    return settings.externalMissionBaseUrl;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get modifyAirMode() {
    return -1 < [
      'airModification',
      'airModificationTravellers',
    ].indexOf(this.$route.name || '');
  }

  get companyIdsNDCOfferWidget() {
    return settings.companyIdsNDCOfferWidget;
  }

  get canShowNDCPartnerInfo() {
    if (this.user && this.user.profile && this.user.profile.rootCompanyId && this.user.profile.isAgency && this.isSearchView) {
      if (-1 !== this.companyIdsNDCOfferWidget.indexOf(this.user.profile.rootCompanyId) ||
          -1 !== this.companyIdsNDCOfferWidget.indexOf(this.user.profile.companyId)) {
        return true;
      }
    }

    return false;
  }

  scrollToWidget() {
    EventBus.$emit('ndc-widget');
  }

  scrollToMessages() {
    EventBus.$emit('custom-messages-widget');
  }

  goToBasket() {
    router.push({
      name: 'basket',
      params: {
        id: this.basketMetadata!.basketId,
      },
    });
  }

  goToMission() {
    router.push({
      name: 'modify-mission',
      params: {
        missionId: this.missionId,
      },
    });
  }

}
