










import { Vue, Component } from 'vue-property-decorator';

import { translate } from '@/i18n';

@Component({})
export default class NoSearch extends Vue {
  errors: any[] = [];

  created() {
    this.errors = [{
      message: translate('search.no-search-permissions'),
    }];
  }
}

