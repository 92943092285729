import TravelPolicyConfiguration from '@/modules/settings/travel-policy/configurations/configuration/TravelPolicyConfiguration.vue';

export default {
  path: ':configurationId?/travel-policy-configuration',
  name: 'travel-policy-configuration',
  component: TravelPolicyConfiguration,
  displayName: 'Travel Policy Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadTravelPolicy',
    parentName: 'travel-policy-configurations',
    depthCategory: 'travel-policy',
    depth: 2,
  },
};
