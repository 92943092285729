import layoutStore from '@/modules/layout/layout.store';

export default (to, from, next) => {
  layoutStore.routeChange({
    to,
    from,
  });

  setTimeout(() => {
    layoutStore.applyDimensions();
    next();
  });
};
