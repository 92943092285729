






import { Vue, Component } from 'vue-property-decorator';

import OfflineRequestsTable from './OfflineRequestsTable.vue';

@Component({
  components: {
    OfflineRequestsTable,
  },
})
export default class OfflineRequests extends Vue {}

