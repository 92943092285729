














































import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import LayoutStore from '@/modules/layout/layout.store';
import settings from '@/settings';
import moment from 'moment';

@Component({
  components: {}
})
export default class BasketMessages extends Vue {
  currentDateTime: any = moment();
  dateTimeInterval: number = -1;
  
  get missionId() {
    return SearchStore.missionId;
  }

  get missionDetails() {
    return SearchStore.mission;
  }

  get processing() {
    return BasketStore.processing;
  }

  get externalMissionBaseUrl() {
    return settings.externalMissionBaseUrl;
  }

  get expired() {
    if (this.basketExpirationDate) {
      return this.currentDateTime.isAfter(this.basketExpirationDate);
    }
    return false;
  }

  get basketExpirationDate() {
    if (BasketStore.basketExpirationDate) {
      return BasketStore.basketExpirationDate;
    }
  }

  get isBasketDraft() {
    return BasketStore.status && BasketStore.status.status ? BasketStore.status.status === 'Draft' : false;
  }

  get isBasketInProgress() {
    return BasketStore.status ? BasketStore.status.isInProgress : false;
  }

  goToMission() {
    router.push({
      name: 'modify-mission',
      params: {
        missionId: this.missionId,
      },
    });
  }

  @Watch('processing')
  onProcessingChange(value) {
    if (!value) {
      return;
    }
    this.$nextTick(() => {
      LayoutStore.scrollManager
        .scrollTo(this.$refs.bookingProgress as Element, false);
    });
  }

  refreshDateTime() {
    this.currentDateTime = moment();
  }

  mounted() {
    this.dateTimeInterval = setInterval(this.refreshDateTime, 1000);
  }

  beforeDestroy() {
    clearInterval(this.dateTimeInterval);
  }

}
