





























































































































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import { MOBILE_NAVIGATION_HEIGHT } from '@/modules/layout/layout.const';

@Component
export default class UiAutocomplete extends Vue {
  @Prop() value: any;
  @Prop({ default: '' }) id!: string;
  @Prop({ default: '' }) openDirection!: string;
  @Prop({ default: '' }) seleniumId!: string;
  @Prop({ default: '' }) trackBy!: string;
  @Prop({ default: () => { return []; } }) options!: any[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: false }) clearOnSelect!: boolean;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: false }) taggable!: boolean;
  @Prop({ default: true }) internalSearch!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) showLabels!: boolean;
  @Prop({ default: false }) showReset!: boolean;
  @Prop({ default: false }) controlReset!: boolean;
  @Prop({ default: false }) manualReset!: boolean;
  @Prop({ default: '' }) icon!: string;

  isActive: boolean = false;
  isSimple: boolean | null = null;
  touchedIndex: number = -1;
  foundLabel: string = '';
  isInPopup: boolean = false;
  popupContainer: HTMLElement | null = null;
  popupTitle: string = '';
  blurTimeout: number = -1;
  query: string = '';
  mobileOptionsHeight: number = -1;

  get currentId() {
    return this.id || ('UiAutocomplete' + this._uid);
  }

  get listeners() {
    return {
      ...this.$listeners,
      input: value => this.input(value),
    };
  }

  get isEmptyObjectValue() {
    return Object.keys(this.value).length === 0 && this.value.constructor === Object;
  }

  get isPlaceholder() {
    if (this.placeholder && !this.isActive && (this.multiple && this.value.length === 0 || !this.multiple && !this.value )) {
      return true;
    } else {
      return false;
    }
  }

  get noResultSlotFound() {
    return !!this.$slots.noResult || !!this.$scopedSlots.noResult;
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }

  get classesContentWrapper() {
    return {
      'has-no-option': this.options && this.options.length === 0,
    };
  }

  get mobileViewClasses() {
    return {
      'ui-autocomplete__mobile-view--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile-view--has-popup-title': '' !== this.popupTitle,
      'ui-autocomplete__mobile-view--has-icon': '' !== this.icon,
      'ui-autocomplete__mobile-view--has-values':
        ((this.multiple || this.taggable) && this.value && this.value.length) ||
        (!this.multiple && !this.taggable && this.value),
    };
  }

  get filteredOptions() {
    return this.options.filter(option => {
      if (this.internalSearch && -1 === option[this.label].toLowerCase().indexOf(this.query.toLowerCase())) {
        return false;
      }
      if (this.taggable || this.multiple) {
        return !this.value || !this.value.find(opt => opt[this.trackBy] === option[this.trackBy]);
      } else {
        if (this.isSimple) {
          return !this.value || this.value !== option;
        } else {
          return !this.value || this.value[this.trackBy] !== option[this.trackBy];
        }
      }
    });
  }

  get mobileOptionsStyles() {
    if (this.mobileOptionsHeight < 0) {
      return {};
    }

    return {
      height: this.mobileOptionsHeight + 'px',
    };
  }

  @Emit('input')
  input(value) {
    return value;
  }

  @Emit('select')
  select(value) {
    return value;
  }

  activateControl() {
    if (this.disabled) {
      return;
    }
    this.isActive = true;
    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
    this.$nextTick(() => {
      return this.$refs.mobileInput && 
        (this.$refs.mobileInput as HTMLInputElement).focus();
    });
  }

  mobileClick($event) {
    const el = $event.target;
    const isUiAutocompletePopupTitle = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-popup-title');
    const isUiAutocompleteLabel = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-field-label');
    const isUiAutocompleteOption = hasSomeParentTheClass(el, 'option-item');
    const isUiAutocompleteOptionsWrapper = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-options');

    if (this.isActive && isUiAutocompleteOption) {
      const parent = getParentByClass($event.target, 'option-item');
      const index = parent.attributes.data.nodeValue;
      this.mobileSelectItem(this.filteredOptions[index], index);
      if (this.clearOnSelect) {
        this.query = '';
      }
    } else if (this.isActive && !isUiAutocompleteOption && isUiAutocompleteOptionsWrapper && !this.multiple) {
      this.confirm();
    } else if (isUiAutocompleteLabel || isUiAutocompletePopupTitle) {
      this.$nextTick(() => {
        return this.$refs.mobileInput && 
          (this.$refs.mobileInput as HTMLInputElement).focus();
      });
    }
  }

  mobileTouchStart($event) {
    const touch = $event.touches[0];
    const el = touch.target;
    const isUiAutocompleteOption = hasSomeParentTheClass(el, 'option-item');
    const isUiAutocompleteOptionsWrapper = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-options');

    if (isUiAutocompleteOption || isUiAutocompleteOptionsWrapper) {
      this.$nextTick(() => {
        return this.$refs.mobileInput && 
          (this.$refs.mobileInput as HTMLInputElement).blur();
      });
    }
  }

  resetValue() {
    this.confirm();

    this.$emit('reset');
    if (this.manualReset) {
      return;
    }
    this.input(null);
  }

  confirm() {
    this.isActive = false;
    this.query = '';
    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('unfreeze-popup', this.popupContainer);
    }
  }

  mobileSelectItem(item, index) {
    if (this.disabled || item.$isDisabled) {
      return;
    }

    if (!this.multiple && !this.taggable) {
      const newVal = item;
      this.input(newVal);
      this.select(newVal);

      setTimeout(() => {
        this.confirm();
      }, 100);
      return;
    }

    let v = this.value;
    if (!v) {
      v = [];
    }
    const newVal = [...v, item];
    
    this.input(newVal);
    this.select(newVal);
  }

  onTouchStart(index) {
    this.touchedIndex = index;
    
  }

  onTouchEnd(index) {
    if (index === this.touchedIndex) {
      this.touchedIndex = -1;
    }
  }

  classesForIndex(item, index) {
    return {
      'ui-autocomplete__mobile-option--disabled': item.$isDisabled === true,
      'ui-autocomplete__mobile-option--highlighted': item.$isDisabled !== true && this.touchedIndex === index,
    };
  }

  onWindowClick($event) {
    const navHeight = MOBILE_NAVIGATION_HEIGHT;

    if ($event.pageY < navHeight) {
      this.confirm();
    }
  }

  @Watch('isActive', { immediate: true })
  onActiveChange(value) {
    if (value) {
      setTimeout(() => {
        this.recalculateOptionsHeight();
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('resize', this.recalculateOptionsHeight);
      });
    } else {
      window.removeEventListener('click', this.onWindowClick);
      window.removeEventListener('resize', this.recalculateOptionsHeight);
    }
  }

  @Watch('options', { immediate: true })
  onOptionsChange(value) {
    this.investigateTypes(this.value);
  }

  @Watch('value', { immediate: true })
  onChange(value) {
    this.investigateTypes(value);

    setTimeout(() => {
      this.recalculateOptionsHeight();
    });
  }

  @Watch('query', { immediate: true })
  onQueryChange() {
    this.dispatchSearchChange();
    this.scrollOptionsTop();
  }

  dispatchSearchChange() {
    this.$emit('search-change', this.query);
  }

  scrollOptionsTop() {
    if (!this.$refs.options) {
      return;
    }
    if ((this.$refs.options as HTMLElement).scrollTo) {
      (this.$refs.options as HTMLElement).scrollTo(0, 0);
    } else {
      (this.$refs.options as HTMLElement).scrollTop = 0;
    }
  }

  investigateTypes(value) {
    if (this.options && this.options.length) {
      if (-1 < ['string', 'number'].indexOf(typeof this.options[0])) {
        this.isSimple = true;
        
      } else if ('object' === typeof this.options[0]) {
        this.isSimple = false;
      }
    } else if (!this.multiple && !this.taggable && value && (-1 < ['string', 'number'].indexOf(typeof value))) {
      this.isSimple = true;
    } else if (!this.multiple && !this.taggable && value && 'object' === typeof value) {
      this.isSimple = false;
    } else if (value && value.length && (-1 < ['string', 'number'].indexOf(typeof value[0]))) {
      this.isSimple = true;
    } else if (value && value.length && ('object' === typeof value[0])) {
      this.isSimple = false;
    }
  }

  getOptionText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  getValueText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  removeValue(val, $event) {
    if (this.isActive) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i][this.trackBy] === val[this.trackBy]) {
          this.value.splice(i, 1);
          break;
        }
      }
    }

    this.$nextTick(() => {
      if ($event && $event.target) {
        $event.target.blur();
      }
    });
  }

  toggleClick() {
    if (!this.multiple) {
      this.confirm();
    }
  }

  recalculateOptionsHeight() {
    if (!this.isActive) {
      return;
    }

    const mobileOptions = this.$refs.options as HTMLElement;
    const rect = mobileOptions.getBoundingClientRect();

    this.mobileOptionsHeight = window.innerHeight - rect.top;
  }

  findLabel() {
    if (this.$slots['field-label']) {
      return;
    }
    const label = (this.$refs.uiAutocompleteWrapper as Element).parentNode;

    if (
      label &&
      'label' === label.nodeName.toLowerCase() &&
      (label as HTMLElement).className &&
      -1 < (label as HTMLElement).className.indexOf('ui-label')
    ) {
      for (let i = 0; i < label.children.length; i++) {
        if ('span' === label.children[i].nodeName.toLowerCase()) {
          this.foundLabel = (label.children[i] as HTMLElement).textContent || '';
        }
      }
    }
  }

  findOutIfInPopup() {
    if (hasSomeParentTheClass(this.$refs.uiAutocompleteWrapper, 'modal-container')) {
      return;
    }
    this.isInPopup = true;
    this.$nextTick(() => {
      this.popupContainer = getParentByClass(this.$refs.uiAutocompleteWrapper, 'modal-container');
      if (!this.popupContainer) {
        return;
      }
      const titleElements = this.popupContainer.getElementsByClassName('popup-title');
      if (!titleElements.length) {
        return;
      }
      this.popupTitle = (titleElements[0] as HTMLElement).textContent || '';
    });
  }

  fixAutocompleteNope() {
    const multiselectRef = this.$refs.multiselect as Vue;

    if (!multiselectRef) {
      return;
    }

    const inputRef = multiselectRef.$refs.search as HTMLInputElement;

    if (!inputRef) {
      return;
    }

    inputRef.setAttribute('autocomplete', 'off');
  }

  mounted() {
    this.findLabel();
    this.findOutIfInPopup();
    this.fixAutocompleteNope();
  }
}

