import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

import { translate } from '@/i18n';
import { router } from '@/router';
import { store } from '@/store';
import errorMessages from '@/const/error-messages.const';
import priceModel from '@/const/price.const';
import EventBus from '@/services/event-handler';
import handleErrors from '@/core/errors/handle-errors.service';
import SearchStore from '@/modules/search/search.store';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { BasketApi } from '@/api/trip/basket.api';
import { TripApi } from '@/api/trip/trip.api';
import { ContactsApi } from '@/api/profile/contacts.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import { RefundActionStatuses } from '@/modules/search/air/air-refund.model';
import {
  OfferParameter,
  BasketParameter,
  AirLuftHansaTicketingMessage,
  RailDiscountCodesResponse,
  SabrePreEmdAnswer,
  AmadeusPreEmdAnswer,
  RailDiscountCodes,
} from '@/api/trip/basket-item.model';
import {
  SelectedApproverModel,
  CheckTravellersModel,
} from '@/api/profile/profile.model';
import {
  ApprovalWorkflowModel,
  TripComment,
  TripCommentFlow,
} from '@/api/trip/trip.model';
import {
  BasketModel,
  BasketStatusModel,
  SelectBasketPaymentCardsMessage,
  SelectBasketPaymentCardMessage,
  PreBookingData,
  WizardStepsGroup,
  StoreBasketMetadata,
  LatestBasketModel,
  BasketFlatItemModel,
  TravelPolicyComplianceResult,
  BasketRailBenerailSeatPreference,
  UpdateRailBenerailOption,
} from '@/api/trip/basket.model';
import { AirSeats } from '@/modules/search/air/air-seats.model';
import LayoutStore from '@/modules/layout/layout.store';
import AccountStore from '@/store/account.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketCancellationStore from './basket-cancellation.store';
import { isCancelError, asyncForEach } from '@/core/utils';
import { ProfileApi } from '@/api/profile/profile.api';
import { DocumentsApi } from '@/api/profile/documents.api';
import { DocumentType } from '@/api/profile/documents.model';
import { ExpenseApi } from '@/api/expense/expense.api';
import { HomeApi } from '@/api/home/home.api';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import { TrainOrdersApi } from '@/api/train-engine/train-orders.api';
import { TrainOffersApi } from '@/api/train-engine/train-offers.api';
import { AirBookingsApi } from '@/api/air-engine/air-bookings.api';
import { AirSearchStateParams } from '@/modules/search/air/air-search.params';
import { HotelSearchStateParams } from '@/modules/search/hotel/hotel-search.params';
import { TrainSearchStateParams } from '@/modules/search/train/train-search.params';
import { CarSearchStateParams } from '@/modules/search/car/car-search.params';
import hasAccessFn from '@/core/permissions/has-access.service';
import settings from '@/settings';
import { ApproverSelectionType, ApproverSourceType, RejectionReasonType } from '@/api/approval-workflow/approval-workflow.model';
import { BasketTripCommentModel } from '@/api/trip/trip.model';

const BASKET_REFRESH_INTERVAL: number = 1000;
const zeroDefault = priceModel.priceModel;


@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'basket',
})
class BasketStore extends VuexModule {
  errors: any[] = [];
  processingErrors: any[] = [];
  initialising: boolean = true;
  booking: boolean = false;
  cancelling: boolean = false;
  processing: boolean = false;
  loadingItems: boolean = false;
  showRetrySection: boolean = false;
  isRetryCancel: boolean = false;
  showRetryPopup: boolean = false;
  showRefreshButton: boolean = false;
  skip = false;
  retry = false;
  retryButtonDisabled = false;
  basketId: string = '';
  basketItemId: string = '';
  basket: BasketModel | null = null;
  status: BasketStatusModel | null = null;
  basketItems: BasketFlatItemModel[] = [];
  notificationsCount: any[] = [];
  basketTitle: string = '';
  bookingStep: number = 0;
  basketParameters: OfferParameter[] = new Array<OfferParameter>();
  validCustomParameters: boolean = true;
  approvalWorkflowForBasket: ApprovalWorkflowModel | null = null;
  selectedApprovers: {
    level: number;
    approver: SelectedApproverModel;
  }[] = [];
  wizardSteps: any[] = [];
  finalBookOption: string | null = null;
  processBasketID: number = -1;
  displayApproverEmpty: boolean = false;
  displayObligatoryConfirmationEmpty: boolean = false;
  showPriceChangePopup: boolean = false;
  showCarPriceChangePopup: boolean = false;
  showRefundDataPopup: boolean = false;
  lastBasketItemId: string = '';
  refundedItem: any | null = null;
  confirming: boolean = false;
  travellersNewDateOfBirthCollection: any[] = [];
  overwrittenTravellersNamesCollection: any[] = [];
  basketRailSeatsMap: any | null = null;
  basketRailSeatsMapErrors: any[] = [];
  basketRailSeatsMapLoading: boolean = false;
  basketRailSelectedSeats: any[] = [];
  basketRailSeatsJourney: any | null = null;
  basketRailSeatsCoach: any | null = null;
  basketSeatsMap: any[] | null = null;
  basketSeatsMapError: boolean = false;
  basketProcessingStoreSeats: boolean = false;
  basketAncillaries: any | null = null;
  basketAncillariesFlights: any[] = [];
  maxOneAncillaryPerSegment: boolean = false;
  minimumAncillaryCount: number | null = null;
  hasMaxOneAncillaryError: boolean = false;
  hasExactOneAncillaryError: boolean = false;
  showMaxOneAncillaryError: boolean = false;
  basketExtras: any | null = null;
  skipExtrasSelection: boolean = false;
  loadingSeatsMap: boolean = false;
  loadingAncillaries: boolean = false;
  loadingExtras: boolean = false;
  loadingLoyaltyPrograms: boolean = false;
  basketLoyaltyPrograms: any[] = [];
  selectedSeats: AirSeats = {
    id: '',
    seats: []
  };
  seatSelection: AirSeats = {
    id: '',
    seats: []
  };
  tripSeatSelection: AirSeats[] = [];
  allSeats: AirSeats[] = [];
  railTravellersSeatRequirements: any[] = [];
  priceChanges: any[] = [];
  selectedAncillaries: any[] = [];
  selectedExtras: any[] = [];
  basketAncillariesMessages: any[] = [];
  updatedAgencyMarkupValue: number = 0;
  canApproveTrip: boolean = false;
  hasCustomFields: boolean = false;
  hasCustomFieldsLoaded: boolean = false;
  hasCustomFieldsErrors: boolean = false;
  basketExtrasErrors: any[] = [];
  bookingStepLoading: boolean = false;
  preBookingData: PreBookingData | null = null;
  cancelToken: CancelTokenSource | null = null;
  basketTravelersUnsaved: boolean = true;
  guestBasketTravellers: any[] = [];
  guestMainTravellerIndex: number = 0;
  guestBasketTravellersChecked: boolean = false;
  guestBasketTravellersError: boolean = false;
  customerCodeSectionError: boolean = false;
  customerCodeSectionLoadingError: boolean = false;
  travellersDateOfBirthCheckFailed: boolean = false;
  loadApisInfoFailed: boolean = false;
  currentApisRules: any[] = [];
  currentMandatoryApisRules: any[] = [];
  shouldShowApisRulesValidationErrors: boolean = false;
  hasMissingApisDocuments: boolean = false;
  guestBasketTravellersData: any[] = [];
  guestBasketTravellersDataError: boolean = false;
  guestBasketTravellersDataLoading: boolean = true;
  guestsSaving: boolean = false;
  guestsProfileSaving: boolean = false;
  basketTitleErrors: any[] = [];
  postTicketingExtrasBooking: boolean = false;
  ancillariesAndPassengerDataErrors: any[] = [];
  ancillariesAndPassengerDataLoadingFailed: boolean = false;
  loyaltyProgramsLoadingFailed: boolean = false;
  ancillariesLoadingFailed: boolean = false;
  seatsLoadingFailed: boolean = false;
  railSeatsLoadingFailed: boolean = false;
  carExtrasLoadingFailed: boolean = false;
  customFields: any[] = [];
  customFieldsErrors: any[] = [];
  storePaymentErrors: any[] = [];
  expenseIdForBasket: string | null = null;
  selectedLoyalties: any[] = [];
  basketMetadata: any | null = null;
  basketRailSncfSeatPreferences: any[] = [];
  basketRailSncfSeatPreferencesError: boolean = false;
  basketRailBenerailSeatPreferences: BasketRailBenerailSeatPreference[] = [];
  basketRailBenerailSeatPreferencesError: boolean = false;
  basketRailSeatPreferencesLoading: boolean = false;
  loadingPaymentMethods: boolean = false;
  loadingPaymentMethodsFailed: boolean = false;
  basketPaymentMethods: any[] = [];
  basketPaymentMethodsError: boolean = false;
  selectedPaymentMethods: any[] = [];
  selectedCreditCardPaymentMethods: any[] = [];
  lufthansaStatusConfirmationSection: boolean = false;
  sabreStatusConfirmationSection: boolean = false;
  amadeusStatusConfirmationSection: boolean = false;
  latestBasketData: LatestBasketModel | null = null;
  customFieldsLoading: boolean = false;
  customFieldsResponded: boolean = false;
  paymentFormResponded: boolean = false;
  businessErrors: any[] = [];
  ticketsExist: boolean = false;
  ticketsData: any | null = null;
  railDiscountCodesLoadingErrors: any[] = [];
  railDiscountCodesLoading: boolean = false;
  railDiscountCodes: RailDiscountCodes[] = [];
  railDiscountCodesSaveErrors: any[] = [];
  railDiscountCodesHaveErrors: boolean = false;
  availableTravellersForSeats: any[] = [];
  pendingActionErrorTripItemId: string = '';
  pendingActionErrorTripItem: string = '';
  pendingActionCodeItem: string = '';
  railTickets: any | null = null;
  ticketsExistLoading: boolean = false;
  basketRailAncillariesLoading: boolean = false;
  railAncillariesLoadingFailed: boolean = false;
  basketDicountAppliedPopupVisible: boolean = false;
  basketDiscounts: any[] = [];
  currentDiscountResponse: any | null = null;
  lastBasketItemProviderReferenceId: string = '';
  isAvailableResponsesSections: boolean = false;
  showBasketExpedia3DSPopup: boolean = false;
  startBasketExpedia3DS: boolean = false;
  basketPaymentUnsupported: boolean = false;
  basketApproverError: boolean = true;
  basketRailSncfAncillaries: any[] = [];
  basketRailSncfAncillariesError: boolean = false;
  selectedRailAncillaries: any[] = [];
  travellersExistence: CheckTravellersModel[] | [] = [];
  hasSimilarTravellers: boolean = false;
  selectedExistingTraveller: any[] = [];
  basketEnterTravellersErrors: any[] = [];
  chooseToSelectPayment: boolean = false;
  lufthansaOfferBooking: any = null;
  sabreOfferBooking: any = null;
  amadeusOfferBooking: any = null;
  loadingUnconfirmedStatus: boolean = false;
  showTripActionErrorPopup: boolean = false;
  showPaymentTerminatedPopup: boolean = false;
  paymentTerminatedBasketId: string = '';
  showing3DSInterruptedPopup: boolean = false;
  basketItemPendingActionCode: string = '';
  expedia3DSTripItemId: string = '';
  expedia3DSUrl: string = '';
  expedia3DSSandbox: string = '';
  addedCartInBasket: boolean = false;
  loadingExchangePopup: boolean = false;
  paymentForTripId: any[] = [];
  preventShowingItems: boolean = false;
  basketApproverData: any = null;
  basketBookerData: any = null;
  showRejectionReasonPopup: boolean = false;
  rejectionReason: string = '';
  tripApprovalData: any = null;
  showApprovalConfirmationPopup: boolean = false;
  basketApprovalDecision: string = '';
  basketApprovalAction: string = '';
  isChangingPaymentMethod: boolean = false;
  bookingExtras: boolean = false;
  synchronizationCompleted: boolean = false;
  showChangeApproverPopup: boolean = false;
  showEditTrainFeePopup: boolean = false;
  showEditHotelFeePopup: boolean = false;
  showEditTrainAgencyMarkupPopup: boolean = false;
  showEditHotelAgencyMarkupPopup: boolean = false;
  editedFeeItem: any = null;
  editedFeeOffer: any = null;
  checkTravelConsent: boolean = false;
  checkModifyTravellerDetails: boolean = false;
  errorAnswer: string = '';
  sendingAnswer: boolean = false;
  loadingHotelAssignmentOptions: boolean = false;
  loadingHotelAssignmentErrors: any[] = [];
  hotelAssignmentOptions: any = null;
  isChangingPriceOnPaymentMethod: boolean = false;
  isModifyTravellersDetailsLoading: boolean = false;
  modifyTravellersLoyaltyPrograms: any[] = [];
  modifyTravellersDetails: any[] = [];
  modifyTravellersDetailsLoadingErrors: any[] = [];
  modifyTravellersDetailsSaveErrors: any[] = [];
  modifyTravellersDetailsFormStatus: any[] = [];
  modifyTravellersDetailsLoyaltiesTouched: boolean = false;
  isModifyingTravellersDetails: boolean = false;
  modifiedTravellersDetails: any[] = [];
  modifiedTripItemId: string = '';
  selectedDocumentsAsFoid: any[] = [];
  showSelectDocumentsAsFoidError: boolean = false;
  foidErrorsProfileIdsAndIndices: any[] = [];
  shouldShowUnconfirmedNotifications: boolean = false;
  showUnconfirmedNotificationsPopup: boolean = false;
  workflowError: boolean = false;
  loadingReviewTravelPolicyCompliance: boolean = false;
  loadingReviewTravelPolicyComplianceFailed: boolean = false;
  loadingApprovalDataAfterExtras: boolean = false;
  loadingApprovalDataAfterExtrasFailed: boolean = false;
  canShowSelectApproverStep: boolean = false;
  selectApproverData: any = null;
  travelPolicyCompliance: TravelPolicyComplianceResult | null = null;
  canShowTravelPolicyComplianceStep: boolean = false;
  showingMissedSavingsModal: boolean = false;
  missedSavingsTripItemId: string = '';
  basketHasCommentToSave: boolean = false;
  basketCommentToSave: TripComment | null = null;
  basketCommentsAddErrors: any[] = [];
  basketCommentsRemoveErrors: any[] = [];
  basketCommentOriginalAddError: any = null;
  comments: BasketTripCommentModel[] = [];
  commentsLoading: boolean = false;
  commentsErrors: any[] = [];
  loadingBasketTravellersDocuments: boolean = false;
  loadingBasketTravellersDocumentsErrors: any[] = [];
  basketTravellersDocuments: any[] = [];
  travellersMissingDocumentCollection: any[] = [];
  showSyncAirBookingSuccessPopup: boolean = false;
  showSyncAirBookingErrorPopup: boolean = false;



  wizardStepsDefinitions = [
    {
      condition: (code) => code === 'AIR_TRAVELFUSION' && !this.ancillariesAndPassengerDataLoadingFailed,
      action: this.saveTravelfusionParameters,
    },
    {
      condition: (code) => code === 'PAYMENT_FORM',
      action: this.savePaymentMethod,
    },
    {
      condition: (code) => code === 'AIR_SABRE_SEAT_SELECTION' && !this.seatsLoadingFailed,
      action: this.saveAirSeats,
    },
    {
      condition: (code) => code === 'RAIL_TRENITALIA_VOUCHER_SELECTION' && !this.railDiscountCodesLoadingErrors.length,
      action: this.saveTrenitaliaVouchers,
    },
    {
      condition: (code) => code === 'AIR_SABRE_ANCILLARIES',
      action: this.saveAirAncillaries,
    },
    {
      condition: (code) => code === 'CAR_SABRE_EXTRAS' && !this.carExtrasLoadingFailed,
      action: this.saveCarExtras,
    },
    {
      condition: (code) => code === 'TRAVELLERS_CHOICE',
      action: this.saveTravellersChoice,
    },
    {
      condition: (code) => code === 'TRAVELLER_DATA_CHECK',
      action: this.saveTravellersData,
    },
    {
      condition: (code) => code === 'CUSTOM_FIELDS',
      action: this.saveCustomFields,
    },
    {
      condition: (code) => code === 'LOYALTY_PROGRAMS' && !this.loyaltyProgramsLoadingFailed,
      action: this.saveLoyaltyPrograms,
    },
    {
      condition: (code) => -1 < [
        'RAIL_NTV_SEAT_SELECTION',
        'RAIL_TRENITALIA_SEAT_SELECTION',
      ].indexOf(code) && !this.railSeatsLoadingFailed,
      action: this.saveNtvTrenitaliaSeats,
    },
    {
      condition: (code) => code === 'RAIL_SNCF_SEAT_SELECTION' && !this.railSeatsLoadingFailed,
      action: this.saveSncfSeats,
    },
    {
      condition: (code) => code === 'RAIL_BENERAIL_SEAT_SELECTION',
      action: this.saveBenerailSeats,
    },
    {
      condition: (code) => code === 'RAIL_SNCF_ANCILLARIES_SELECTION',
      action: this.saveSncfAncillaries,
    },
    {
      condition: (code) => code === 'MODIFY_TRAVELLERS_DETAILS',
      action: this.saveTravellersDetails,
    },
    {
      condition: (code) => code === 'REVIEW_TP_COMPLIANCE',
      action: this.saveReviewTPCompliance,
    },
    {
      condition: (code) => code === 'SELECT_APPROVER',
      action: this.saveSelectedApprover,
    },
  ];



  get isInWizard(): boolean {
    return this.bookingStep !== 0;
  }

  get loading(): boolean {
    return this.initialising || this.processing || this.loadingItems || this.booking || this.cancelling;
  }

  get basketTravellers() {
    if (this.basket) {
      return this.basket.travellers;
    }
    return [];
  }

  get basketAncillariesGroups() {
    if (!this.basketAncillaries) {
      return [];
    }

    return this.basketAncillaries
      .map(item => {
        return {
          profileId: item.profileId,
          groups: item.ancillaryOffers
            .filter(offer => offer.group !== null)
            .filter((offer, index, array) => {
              return array.findIndex(item => item.group.code === offer.group.code) === index;
            })
            .map(offer => {
              return {
                code: offer.group.code,
                name: offer.group.name,
                maxSelectedItemsPerFlight: offer.group.maxSelectedItemsPerFlight,
              };
            })
        };
      });
  }

  get basketSelectedAncillariesGroups() {
    return this.basketAncillariesGroups
      .map(profileAndGroups => {
        return {
          ...profileAndGroups,
          groups: profileAndGroups.groups
            .filter(group => !!group.maxSelectedItemsPerFlight)
            .map(group => {
              return {
                ...group,
                flights: this.selectedAncillaries
                  .reduce((p, c) => {
                    if (!c.flights) {
                      return p;
                    }
                    return [...p, ...c.flights];
                  }, [])
                  .filter((flightId, index, array) => index === array.indexOf(flightId))
                  .map(flightId => {
                    return {
                      flightId,
                      ancillaries: this.selectedAncillaries
                        .filter(offer => {
                          return offer.profileId === profileAndGroups.profileId &&
                            offer.flights.indexOf(flightId) > -1 &&
                            offer.group && offer.group.code === group.code;
                        }),
                    };
                  })
                  .filter(flight => flight.ancillaries.length > 0),
              };
            })
            .filter(group => group.flights.length > 0),
        };
      })
      .filter(profileAndGroups => profileAndGroups.groups.length > 0);
  }

  get basketSearchCompanyId() {
    if (this.basket) {
      return this.basket.searchCompanyId;
    }
    return null;
  }

  get basketSkipTravelersMode() {
    return this.basket != null && this.basket.searchCompanyId != null && this.basket.searchCompanyId.length > 0;
  }

  get shouldShowTripTitle() {
    return !this.loading;
  }

  get shouldShowBasketItems() {
    return !this.loading && !this.isInWizard && !this.isAvailableResponsesSections;
  }

  get basketHoldDeadline() {
    if (this.preBookingData) {
      return this.preBookingData.holdDeadline;
    }
    return null;
  }

  get basketApprovalDeadline() {
    if (this.preBookingData) {
      return this.preBookingData.approvalDeadline;
    }
    return null;
  }

  get showQuickBook() {
    if (this.preBookingData) {
      return !this.preBookingData.isApprovalEnabled;
    }

    return true;
  }

  get showApprovalOptions() {
    if (!this.approvalWorkflowForBasket) {
      return false;
    }

    return this.approvalWorkflowForBasket.workflowLevels
      .find(
        level => -1 < [
          'Required',
          'AutoApprove',
        ].indexOf(level.approvalWorkflowResult)
      );
  }

  get currentApprovalLevel() {
    if (!this.tripApprovalData) {
      return false;
    }

    return this.tripApprovalData.workflowLevels
      .find(l => l.approvalLevel === this.tripApprovalData.currentApprovalLevel) || null;
  }

  get currentApprovalLevelRejectionReasonType(): RejectionReasonType {
    if (!this.currentApprovalLevel) {
      return RejectionReasonType.NotRequired;
    }

    return this.currentApprovalLevel.rejectionReasonType;
  }

  get shouldApproverProvideReason() {
    return this.currentApprovalLevelRejectionReasonType !== RejectionReasonType.NotRequired;
  }

  get shouldShowTripPrice() {
    return !this.loading;
  }

  get confirmingFlag() {
    return this.confirming;
  }

  get isFoidRequired() {
    return !!this.basketItems
      .find(item => !!item.requiresFoid);
  }

  get areFoidDocumentsFilled() {
    const knownTravellers = this.basketTravellers;
    const mappedDocuments = knownTravellers
      .filter(traveller => {
        return !!this.selectedDocumentsAsFoid
          .find(doc => {
            return doc.profileId === traveller.id;
          });
      });
    return knownTravellers.length === mappedDocuments.length;
  }

  get shouldShowBasketCustomField() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'AIR_TRAVELFUSION') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketLoyaltyProgramsView() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'LOYALTY_PROGRAMS') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketAncillaries() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'AIR_SABRE_ANCILLARIES') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketCarSabreExtrasView() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'CAR_SABRE_EXTRAS') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketSeats() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'AIR_SABRE_SEAT_SELECTION') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketEnterTravellers() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'TRAVELLERS_CHOICE') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketCheckTravellerData() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'TRAVELLER_DATA_CHECK') {
        return true;
      }
    }
    return false;
  }

  get shouldShowRailDiscountCodes() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'RAIL_TRENITALIA_VOUCHER_SELECTION') {
        return true;
      }
    }
    return false;
  }

  get errorsPresentInDiscountCodes() {
    return !!this.railDiscountCodes.find(item => {
      return item.forwardDiscountStatus === 'Error' || 
        (item.isReturnAvailable && item.returnDiscountCodeStatus === 'Error');
    });
  }

  get shouldShowBasketPaymentMethods() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];
      if (stepDef && stepDef.code === 'PAYMENT_FORM') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketModifyTravellersDetailsView() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];
      if (stepDef && stepDef.code === 'MODIFY_TRAVELLERS_DETAILS') {
        return true;
      }
    }
    return false;
  }

  get shouldShowBasketCustomFieldsView() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'CUSTOM_FIELDS'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowSelectApproverView() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'SELECT_APPROVER'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowBasketRailNtvSeatSelection() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'RAIL_NTV_SEAT_SELECTION'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowBasketRailTrenitaliaSeatSelection() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'RAIL_TRENITALIA_SEAT_SELECTION'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowBasketRailSncfSeatSelection() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'RAIL_SNCF_SEAT_SELECTION'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowBasketRailBenerailSeatSelection() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'RAIL_BENERAIL_SEAT_SELECTION'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowReviewTravelPolicyCompliance() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'REVIEW_TP_COMPLIANCE'
    ) {
      return true;
    }
    return false;
  }

  get shouldDisableContinueOnTravelPolicyCompliance() {
    return this.loadingReviewTravelPolicyCompliance ||
      (this.shouldShowReviewTravelPolicyCompliance &&
      (this.loadingReviewTravelPolicyComplianceFailed ||
      (this.travelPolicyCompliance &&
      !this.travelPolicyCompliance.isNotBlockedByTravelPolicyChanges)));
  }

  get shouldShowBasketRailSncfAncillariesSelection() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'RAIL_SNCF_ANCILLARIES_SELECTION'
    ) {
      return true;
    }
    return false;
  }

  get shouldShowAssignTravellersToRooms() {
    if (
      this.isInWizard &&
      !this.loading &&
      this.bookingStepDef.code === 'ASSIGN_TRAVELLERS_TO_ROOMS'
    ) {
      return true;
    }
    return false;
  }

  get basketItemsMapped() {
    if (!this.basketItems) {
      return [];
    }
    return this.basketItems.map(item => {
      let status = !this.status || !this.status.items ? item : this.status.items.find(def => def.id === item.id);

      if (!status) {
        status = item;
      }

       return {
        ...item,
        id: item.id,
        status: status.status
     };
    }).filter(item => 'Exchanged' !== item.status);
  }

  get totalTax() {
    if (!this.basketItems) {
      return {
        ...priceModel,
      };
    }

    let amount = 0.0;
    let symbol = zeroDefault.currency.symbol;

    return {
      amount: amount,
      symbol: symbol
    };
  }

  get totalFees() {
    if (this.loading) {
      return {
        ...priceModel,
      };
    }

    let amount = 0.0;
    let symbol = zeroDefault.currency.symbol;

    this.basketItems.forEach(element => {
      if (-1 < ['Air', 'Rail', 'Accommodation'].indexOf(element.type)) {
        amount += element.price.agencyFee ? element.price.agencyFee.amount : 0;
      }
      if (element.price.agencyFee && element.price.agencyFee.currency) {
        symbol = element.price.agencyFee.currency.symbol;
      }
    });

    return {
      amount: amount,
      symbol: symbol
    };
  }

  get totalAgencyMarkup() {
    if (this.loading) {
      return {
        ...priceModel,
      };
    }

    let amount = 0.0;
    let symbol = zeroDefault.currency.symbol;

    this.basketItems.forEach(element => {
      if (-1 < ['Air', 'Rail', 'Accommodation'].indexOf(element.type)) {
        amount += element.price.agencyMarkup ? element.price.agencyMarkup.amount : 0;
      }
      if (element.price.agencyMarkup && element.price.agencyMarkup.currency) {
        symbol = element.price.agencyMarkup.currency.symbol;
      }
    });

    return {
      amount: amount,
      symbol: symbol
    };
  }

  get totalSystemMarkup() {
    if (this.loading) {
      return {
        ...priceModel,
      };
    }

    let amount = 0.0;
    let symbol = zeroDefault.currency.symbol;

    this.basketItems.forEach(element => {
      if (-1 < ['Air', 'Rail', 'Accommodation'].indexOf(element.type)) {
        amount += element.price.systemMarkup ? element.price.systemMarkup.amount : 0;
      }
      if (element.price.systemMarkup && element.price.systemMarkup.currency) {
        symbol = element.price.systemMarkup.currency.symbol;
      }
    });

    return {
      amount: amount,
      symbol: symbol
    };
  }

  get totalPrice() {
    if (this.loading) {
      return {
        ...priceModel,
      };
    }

    let amount = 0.0;
    let symbol = zeroDefault.currency.symbol;

    this.basketItems.forEach(element => {
      
      if (element.price.total) {
        amount += element.price.total.amount;
      }

      symbol = element.price.total.currency.symbol;
    });

    
    if (this.bookingStep > 0) {
      amount += this.totalSeatsPrice.amount;
      amount += this.totalAncillariesPrice.amount;
      amount += this.totalDiscounts.amount;
    }

    return {
      amount: amount,
      symbol: symbol
    };
  }

  get shouldShowBasketBook() {
    if (this.isInWizard && !this.loading) {
      const stepDef = this.wizardSteps[this.bookingStep - 1];

      if (stepDef && stepDef.code === 'DO_TRIP') {
        return true;
      }
    }
    return false;
  }

  get isLastStep() {
    if (this.isInWizard) {
      if (this.bookingStep === this.wizardSteps.length) {
        return true;
      }
    }
    return false;
  }

  get hasDesynchronizedBooking() {
    const items = this.basketItemsMapped;
    if (items) {
      return !!items.find(item => 'Desynchronized' === item.status);
    }
    return false;
  }

  get allBookingsConfirmed() {
    const items = this.basketItemsMapped;
    if (items) {
      return items.every(item => -1 < [
        'Confirmed',
        'Ticketed',
        'Held',
      ].indexOf(item.status));
    }
    return false;
  }

  get basketHasNoItems() {
    return !this.basketItems || this.basketItems.length === 0;
  }

  get hasMissingSelectedApprover() {
    if (!this.approvalWorkflowForBasket) {
      return false;
    }
    const levelsWithRequiredApprovers = this.approvalWorkflowForBasket.workflowLevels
      .filter(level => {
        if (
          level.approverSourceType === ApproverSourceType.TravellerDesignatedApprover &&
          level.approverSelectionType === ApproverSelectionType.AnyAllowedApprover
        ) {
          return false;
        }
        return -1 < [
          'AutoApprove',
          'Required',
        ].indexOf(level.approvalWorkflowResult);
      });
    const levelsWithApprovers = levelsWithRequiredApprovers
      .filter(level => level.approverSelectionType === 'BookerSelectsApprover')
      .map(level => {
        return {
          ...level,
          hasApprover: this.selectedApprovers
            .find(item => item.level === level.approvalLevel),
        };
      });
    return !!levelsWithApprovers
      .find(item => !item.hasApprover);
  }

  get basketHasErrors() {
    if (this.basketHasNoItems || this.hasMissingSelectedApprover) {
      return true;
    }
    return !!this.basketItems.find(item => {
      const conditions = [
        () => !item.selectedPaymentCard,
        () => !!item.selectedPaymentCard && item.selectedPaymentCard.paymentMethod === null,
        () => !!item.selectedPaymentCard && !!item.selectedPaymentCard.hasErrors,
        () => !!item.selectedPaymentCard && item.selectedPaymentCard.paymentMethod === 'PaymentCard' &&
          (!item.selectedPaymentCard.transactionId || !item.selectedPaymentCard.paymentCardId),
      ];
      
      return !!conditions.find(condition => condition());
    });
  }

  get paymentBasketHasErrors() {
    let result: boolean = false;

    if (!this.selectedPaymentMethods || this.selectedPaymentMethods.length === 0 ) {
      result = true;
    }

    if (!result) {
      this.selectedPaymentMethods.forEach(item => {
        if (item.hasErrors) {
          result = true;
        }
      });
    }
    return result;
  }

  get lastBasketItem() {
    return this.basketItemsMapped.find(item => item.id === this.lastBasketItemId);
  }

  get lastSegmentName() {
    const item = this.lastBasketItem;

    if (!item) {
      return '';
    }
    return item.type;
  }

  get bookingStepDef() {
    if (this.bookingStep === 0 || this.bookingStep > this.wizardSteps.length) {
      return {};
    }
    return this.wizardSteps[this.bookingStep - 1];
  }

  get bookingStepTripItem() {
    if (!this.bookingStepDef.tripItemId) {
      return null;
    }
    return this.basketItems.find(item => {
      return item.id === this.bookingStepDef.tripItemId;
    });
  }

  get totalSeatsPrice() {
    let price = {
      ...zeroDefault,
    };

    const summer = seat => {
      if (!seat.price) {
        return;
      }
      price.amount += seat.price.amount;
      price.currency = seat.price.currency;
    };

    this.selectedSeats.seats.forEach(summer);
    this.basketRailSelectedSeats.forEach(summer);
    this.priceChanges.filter(item => item.type === 'seats')
      .forEach(change => {
        change.prices.forEach(p => {
          price.amount += p.amount;
          price.currency = p.currency;
        });
      });

    return price;
  }

  get totalAncillariesPrice() {
    let price = {
      ...zeroDefault,
    };

    this.selectedAncillaries
      .filter(entry => {
        return !entry.isAlreadyBooked;
      })
      .forEach((item: any) => {
        if (!item.totalPrice) {
          return;
        }
        price.amount += item.totalPrice.amount;
        price.currency = item.totalPrice.currency;
      });
    this.selectedRailAncillaries.forEach(item => {
      if (!item.price) {
        return;
      }
      price.amount += item.price.amount;
    });

    this.priceChanges
      .filter(item => -1 < ['ancillaries', 'railAncillaries'].indexOf(item.type))
      .forEach(change => {
        change.prices.forEach(p => {
          price.amount += p.amount;
          price.currency = p.currency;
        });
      });

    return price;
  }

  get totalExtrasPrice() {
    let price = {
      ...zeroDefault,
    };

    this.selectedExtras.forEach(item => {
      if (!item.subTotalPrice) {
        return;
      }
      price.amount += item.subTotalPrice.amount;
      price.currency = item.subTotalPrice.currency;
    });

    this.priceChanges
      .filter(item => item.type === 'extras' && !!item.prices)
      .forEach(item => {
        item.prices.forEach(p => { 
          price.amount += p.amount;
          price.currency = p.currency; 
        });
      });
    return price;
  }

  get totalDiscounts() {
    let price = {
      ...zeroDefault,
    };

    this.priceChanges
      .filter(item => item.type === 'discounts' && !!item.prices)
      .forEach(item => {
        item.prices.forEach(p => price.amount += p.amount);
      });
    return price;
  }

  get travellersState() {
    return SearchStore.getTravellersState;
  }

  get basketIsCancelled() {
    return this.status && this.status.status === 'Cancelled';
  }

  get travellersChoiceWizardStep() {
    return {
      tripId: this.wizardSteps && this.wizardSteps.length ? this.wizardSteps[0].tripId : null,
      tripItemId: null,
      isMandatory: this.wizardSteps[0] && this.wizardSteps[0].isMandatory ? this.wizardSteps[0].isMandatory : false,
      selected: this.wizardSteps[0] && this.wizardSteps[0].selected === false ? false : true,
      code: 'TRAVELLERS_CHOICE',
      index: 0
    } as {
      tripId: null | string,
      tripItemId: null | string,
      travellerIds?: any,
      isMandatory: boolean,
      selected: boolean,
      code: string,
      index: number,
    };
  }

  get travellerDataCheckWizardStep() {
    return {
      tripId: this.wizardSteps && this.wizardSteps.length ? this.wizardSteps[0].tripId : null,
      tripItemId: null,
      isMandatory: this.wizardSteps[1] && this.wizardSteps[1].isMandatory ? this.wizardSteps[1].isMandatory : false,
      selected: this.wizardSteps[1] && this.wizardSteps[1].selected === false ? false : true,
      code: 'TRAVELLER_DATA_CHECK',
      index: 1
    };
  }

  get travellersCheckCombined() {
    return {
      tripId: this.wizardSteps && this.wizardSteps.length ? this.wizardSteps[0].tripId : null,
      travellerId: null,
      tripItemId: null,
      selected: this.wizardSteps[0] && this.wizardSteps[0].selected === false ? false : true,
      code: 'TRAVELLERS_CHECK_COMBINED',
      isMandatory: this.wizardSteps[0] && this.wizardSteps[0].isMandatory !== undefined ? this.wizardSteps[0].isMandatory : true,
      index: 0,
    };
  }

  get travellersCheckCombinedSteps() {
    const separateSteps = [this.travellersChoiceWizardStep, this.travellerDataCheckWizardStep];

    return this.wizardSteps[0] && this.wizardSteps[0].code === 'TRAVELLERS_CHOICE' ? separateSteps : [this.travellersCheckCombined];
  }

  get travellersCheckCombinedMaxIndex() {
    const combineIndexNumber = this.wizardSteps.filter(item => {
      return -1 !== [
        'TRAVELLER_DATA_CHECK',
        'TRAVELLERS_CHOICE',
      ].indexOf(item.code);
    }).length;

    return this.wizardSteps[0] && this.wizardSteps[0].code === 'TRAVELLERS_CHOICE' ? this.wizardSteps.length : combineIndexNumber;
  }

  get hasReviewTPCompliance() {
    return !!this.wizardSteps.find(step => step.code === 'REVIEW_TP_COMPLIANCE');
  }

  get wizardStepsGroups(): WizardStepsGroup[] {
    return [{
      steps: this.travellersCheckCombinedSteps,
      code: 'TRAVELLERS_CHECK_COMBINED',
      travellerId: null,
      tripItemId: null,
      tripItem: null,
      maxIndex: this.travellersCheckCombinedMaxIndex,
    }].concat(
      this.wizardSteps
        .reduce((prev, cur, index) => {
          const stepItem = {
            ...cur,
            index,
          };
          const group = prev
            .find(item => {
              return item.tripItemId !== null &&
                item.tripItemId === cur.tripItemId &&
                item.travellerId === cur.travellerId;
            });
          if (!group || cur.code === 'CUSTOM_FIELDS') {
            return [...prev, {
              tripItemId: cur.tripItemId,
              travellerId: cur.travellerId,
              steps: [stepItem],
              code: stepItem.code,
              tripItem: cur.tripItemId === null ? null : this.basketItemsMapped.find(item => {
                return item.id === cur.tripItemId;
              }),
            }];
          }

          group.steps.push(stepItem);
          group.steps = group.steps
            .filter(item => (item.code !== 'REVIEW_TP_COMPLIANCE' || this.canShowTravelPolicyComplianceStep) &&
              (item.code !== 'SELECT_APPROVER' || this.canShowSelectApproverStep));
          return [...prev];
        }, [])
        .filter(group => group.code !== 'REVIEW_TP_COMPLIANCE' || this.canShowTravelPolicyComplianceStep)
        .filter(group => -1 === [
          'TRAVELLER_DATA_CHECK',
          'TRAVELLERS_CHOICE',
        ].indexOf(group.code))
    );
  }

  get pciProxyMockEnabled() {
    return settings.pciProxy_MockEnabled;
  }

  get choosenGroupSteps() {
    return this.wizardStepsGroups
      .map(group => {
        const steps = group.steps
          .filter(item => item.isMandatory || item.selected);

        if (!steps.length) {
          return {
            ...group,
            empty: true,
          };
        }

        return {
          empty: false,
          ...group,
          steps,
        };
      })
      .filter(group => !group.empty);
  }

  get basketExpirationDate() {
    if (this.basket && this.basket.expirationDate) {
      return moment(this.basket.expirationDate).utc(false);
    }
    return false;
  }

  get clientTimezoneOffset() {
    return moment.parseZone(new Date()).utcOffset() as any;
  }

  get basketExpirationTime() {
    if (this.basketExpirationDate) {
      let time = this.basketExpirationDate.utcOffset(this.clientTimezoneOffset).format('HH:mm');
      let zoneOffset = this.basketExpirationDate.utcOffset(this.clientTimezoneOffset).format('Z');
      return `${time} (GMT ${zoneOffset})`;
    }
  }

  get isBasketExpired() {
    if (this.basketExpirationDate) {
      return moment().utc(false).isAfter(this.basketExpirationDate);
    }
    return false;
  }

  get canContinueOnSeatsStep() {
    const rules = [
      !this.bookingStepDef || this.bookingStepDef.code !== 'AIR_SABRE_SEAT_SELECTION',
      !this.bookingStepDef.isMandatory,
      this.seatsLoadingFailed,
      !this.basketSeatsMap || !this.basketSeatsMap.length,
      !this.basket,
      !this.selectedSeats || !this.selectedSeats.seats,
    ];

    if (!!rules.find(rule => !!rule) || !this.basketSeatsMap) {
      return true;
    }

    const flightsWithSeatsMap = this.basketSeatsMap
      .filter(seatsMap => !!seatsMap.decks);
    const seatsExpected = flightsWithSeatsMap
      .reduce((prev, current) => {
        return prev + current.availableSeatsForTravellers.length;
      }, 0);

    return this.selectedSeats.seats.length === seatsExpected;
  }

  get itemsToCheckForApisValidation() {
    if (!this.basketItems.length) {
      return [];
    }

    return this.basketItems
      .filter(item => item.type === 'Air');
  }

  get queryStringForApisValidation() {
    if (!this.itemsToCheckForApisValidation.length) {
      return '';
    }

    return this.itemsToCheckForApisValidation
      .map(item => 'recommendationIds=' + item.providerReferenceId)
      .join('&');
  }

  get hasApprovalWorkflowAction() {
    return !this.isInWizard && !this.basketIsCancelled &&
      !this.showRetrySection && this.status && this.status.pendingActions
        .find(obj => obj.code ===  'APPROVAL_WORKFLOW_ACTION') !== undefined;
  }

  get modifyTravellersDetailsFormInvalid() {
    return !!this.modifyTravellersDetailsFormStatus
      .find(item => item.form === true);
  }

  get modifyTravellersDetailsLoyaltiesInvalid() {
    return !!this.modifyTravellersDetailsFormStatus
      .find(item => item.loyalty === true);
  }

  get modifyTravellersDetailsNoSectionToModify() {
    return !this.modifyTravellersDetailsFormStatus
      .find(item => item.noSection === false);
  }

  get canShowObligatoryConfirmation() {
    const canBookWithoutConsent = AccountStore.HasPermission('CanBookWithoutConsent');
    const basketStatus = this.status && this.status.status ? this.status.status : '';
    const isDraft = basketStatus && basketStatus === 'Draft';
    return !canBookWithoutConsent && isDraft && !this.showRefreshButton;
  }



  @Mutation
  clearBasket() {
    this.basketId = '';
    this.basket = null;
    this.basketItems = [];
    this.notificationsCount = [];
    this.basketParameters = [];
    this.status = null;
    this.processing = false;
    this.initialising = true;
    this.booking = false;
    this.cancelling = false;
    this.basketTitle = '';
    this.bookingStep = 0;
    this.wizardSteps = [];
    this.finalBookOption = null;
    this.errors = [];
    this.workflowError = false;
    this.processingErrors = [];
    this.displayApproverEmpty = false;
    this.displayObligatoryConfirmationEmpty = false;
    this.showRetrySection = false;
    this.isRetryCancel = false;
    this.showRetryPopup = false;
    this.showPriceChangePopup = false;
    this.showCarPriceChangePopup = false;
    this.showRefundDataPopup = false;
    this.skip = false;
    this.retry = false;
    this.retryButtonDisabled = false;
    this.showRefreshButton = false;
    this.confirming = false;
    this.travellersNewDateOfBirthCollection = [];
    this.overwrittenTravellersNamesCollection = [];
    this.basketSeatsMap = null;
    this.basketSeatsMapError = false;
    this.basketProcessingStoreSeats = false;
    this.basketAncillaries = null;
    this.maxOneAncillaryPerSegment = false;
    this.minimumAncillaryCount = null;
    this.hasMaxOneAncillaryError = false;
    this.hasExactOneAncillaryError = false;
    this.showMaxOneAncillaryError = false;
    this.basketAncillariesMessages = [];
    this.showing3DSInterruptedPopup = false;
    this.selectedSeats = {
      id: '',
      seats: [],
    };
    this.selectedApprovers = [];
    this.canApproveTrip = false;
    this.allSeats = [];
    this.selectedAncillaries = [];
    this.priceChanges = [];
    this.bookingStepLoading = false;
    this.loadingLoyaltyPrograms = false;
    this.preBookingData = null;
    this.basketTravelersUnsaved = true;
    this.guestBasketTravellers = [];
    this.guestMainTravellerIndex = 0;
    this.guestBasketTravellersData = [];
    this.postTicketingExtrasBooking = false;
    this.skipExtrasSelection = false;
    this.customFields = [];
    this.customFieldsErrors = [];
    this.basketExtrasErrors = [];
    this.basketTitleErrors = [];
    this.businessErrors = [];
    this.storePaymentErrors = [];
    this.ancillariesAndPassengerDataErrors = [];
    this.ancillariesAndPassengerDataLoadingFailed = false;
    this.loyaltyProgramsLoadingFailed = false;
    this.ancillariesLoadingFailed = false;
    this.seatsLoadingFailed = false;
    this.railSeatsLoadingFailed = false;
    this.carExtrasLoadingFailed = false;
    this.basketPaymentMethodsError = false;
    this.guestBasketTravellersError = false;
    this.customerCodeSectionError = false;
    this.customerCodeSectionLoadingError = false;
    this.travellersDateOfBirthCheckFailed = false;
    this.loadApisInfoFailed = false;
    this.currentApisRules = [];
    this.currentMandatoryApisRules = [];
    this.shouldShowApisRulesValidationErrors = false;
    this.guestBasketTravellersChecked = false;
    this.guestBasketTravellersDataError = true;
    this.guestBasketTravellersDataLoading = true;
    this.basketLoyaltyPrograms = [];
    this.basketRailSncfSeatPreferences = [];
    this.basketRailSncfSeatPreferencesError = false;
    this.basketRailBenerailSeatPreferences = [];
    this.basketRailBenerailSeatPreferencesError = false;
    this.basketRailSeatPreferencesLoading = false;
    this.basketRailSeatsMap = null;
    this.basketRailSeatsMapErrors = [];
    this.basketRailSeatsMapLoading = false;
    this.basketRailSelectedSeats = [];
    this.customFieldsLoading = false;
    this.customFieldsResponded = false;
    this.paymentFormResponded = false;
    this.lufthansaStatusConfirmationSection = false;
    this.ticketsExist = false;
    this.ticketsData = null;
    this.railTickets = null;
    this.ticketsExistLoading = false;
    this.railDiscountCodesLoadingErrors = [];
    this.railDiscountCodesSaveErrors = [];
    this.railDiscountCodesLoading = false;
    this.railDiscountCodes = [];
    this.railDiscountCodesHaveErrors = false;
    this.availableTravellersForSeats = [];
    this.sabreStatusConfirmationSection = false;
    this.amadeusStatusConfirmationSection = false;
    this.basketMetadata = null;
    this.pendingActionErrorTripItemId = '';
    this.pendingActionErrorTripItem = '';
    this.pendingActionCodeItem = '';
    this.basketDicountAppliedPopupVisible = false;
    this.basketDiscounts = [];
    this.currentDiscountResponse = null;
    this.seatSelection = {
      id: '',
      seats: []
    };
    this.isAvailableResponsesSections = false;
    this.tripSeatSelection = [];
    this.basketRailAncillariesLoading = false;
    this.railAncillariesLoadingFailed = false;
    this.basketRailSncfAncillaries = [];
    this.basketRailSncfAncillariesError = false;
    this.basketApproverError = true;
    this.showRejectionReasonPopup = false;
    this.rejectionReason = '';
    this.tripApprovalData = null;
    this.chooseToSelectPayment = false;
    this.basketEnterTravellersErrors = [];
    this.selectedExistingTraveller = [];
    this.hasSimilarTravellers = false;
    this.travellersExistence = [];
    this.showBasketExpedia3DSPopup = false;
    this.startBasketExpedia3DS = false;
    this.expedia3DSTripItemId = '';
    this.expedia3DSUrl = '';
    this.expedia3DSSandbox = '';
    this.lufthansaOfferBooking = null;
    this.sabreOfferBooking = null;
    this.amadeusOfferBooking = null;
    this.loadingUnconfirmedStatus = false;
    this.addedCartInBasket = false;
    this.paymentForTripId = [];
    this.loadingExchangePopup = false;
    this.preventShowingItems = false;
    this.basketApproverData = null;
    this.basketBookerData = null;
    this.showApprovalConfirmationPopup = false;
    this.basketApprovalDecision = '';
    this.basketApprovalAction = '';
    this.isChangingPaymentMethod = false;
    this.bookingExtras = false;
    this.synchronizationCompleted = false;
    this.showChangeApproverPopup = false;
    this.showEditTrainFeePopup = false;
    this.showEditTrainAgencyMarkupPopup = false;
    this.showEditHotelAgencyMarkupPopup = false;
    this.showEditHotelFeePopup = false;
    this.editedFeeItem = null;
    this.editedFeeOffer = null;
    this.hasCustomFields = false;
    this.hasCustomFieldsLoaded = false;
    this.hasCustomFieldsErrors = false;
    this.checkTravelConsent = false;
    this.checkModifyTravellerDetails = false;
    this.errorAnswer = '';
    this.sendingAnswer = false;
    this.shouldShowUnconfirmedNotifications = false;
    this.showUnconfirmedNotificationsPopup = false;
    this.loadingPaymentMethods = false;
    this.loadingPaymentMethodsFailed = false;
    this.loadingHotelAssignmentOptions = false;
    this.hotelAssignmentOptions = null;
    this.loadingHotelAssignmentErrors = [];
    this.isChangingPriceOnPaymentMethod = false;
    this.isModifyTravellersDetailsLoading = false;
    this.modifyTravellersLoyaltyPrograms = [];
    this.modifyTravellersDetails = [];
    this.modifyTravellersDetailsLoadingErrors = [];
    this.modifyTravellersDetailsSaveErrors = [];
    this.modifyTravellersDetailsFormStatus = [];
    this.modifyTravellersDetailsLoyaltiesTouched = false;
    this.isModifyingTravellersDetails = false;
    this.modifiedTravellersDetails = [];
    this.modifiedTripItemId = '';
    this.selectedDocumentsAsFoid = [];
    this.foidErrorsProfileIdsAndIndices = [];
    this.loadingReviewTravelPolicyCompliance = false;
    this.travelPolicyCompliance = null;
    this.canShowTravelPolicyComplianceStep = false;
    this.loadingReviewTravelPolicyComplianceFailed = false;
    this.loadingApprovalDataAfterExtras = false;
    this.loadingApprovalDataAfterExtrasFailed = false;
    this.canShowSelectApproverStep = false;
    this.selectApproverData = null;
    this.showingMissedSavingsModal = false;
    this.missedSavingsTripItemId = '';
    this.basketHasCommentToSave = false;
    this.basketCommentToSave = null;
    this.basketCommentsRemoveErrors = [];
    this.basketCommentsAddErrors = [];
    this.basketCommentOriginalAddError = null;
    this.comments = [];
    this.commentsLoading = false;
    this.commentsErrors = [];
    this.loadingBasketTravellersDocuments = false;
    this.loadingBasketTravellersDocumentsErrors = [];
    this.basketTravellersDocuments = [];
    this.travellersMissingDocumentCollection = [];
    this.showSyncAirBookingSuccessPopup = false;
    this.showSyncAirBookingErrorPopup = false;
  }

  @Mutation
  setShowSyncAirBookingSuccessPopup(value: boolean) {
    this.showSyncAirBookingSuccessPopup = value;
  }

  @Mutation
  setShowSyncAirBookingErrorPopup(value: boolean) {
    this.showSyncAirBookingErrorPopup = value;
  }

  @Mutation
  showMissedSavingsModal(tripItemId) {
    this.missedSavingsTripItemId = tripItemId;
    this.showingMissedSavingsModal = true;
  }

  @Mutation
  updateMissedSavingsReason({ tripItemId, value }) {
    const item = this.basketItems.find(item => item.id === tripItemId);

    if (!item) {
      return;
    }

    item.missedSavingReason = value;
  }

  @Mutation
  toggleMissedSavingsModal(value) {
    this.showingMissedSavingsModal = value;
  }

  @Mutation
  setBasketRailCoach(coach) {
    this.basketRailSeatsCoach = coach;
  }

  @Mutation
  setBasketRailJourney(journey) {
    this.basketRailSeatsJourney = journey;
  }

  @Mutation
  setAvailableTravellersForSeats(travellers) {
    this.availableTravellersForSeats = travellers.map(item => {
      return {
        segmentId: item.segmentId,
        travellers: item.assignedSeats.map(seat => {
          return seat.travellerId;
        }),
      };
    });
  }

  @Mutation
  setTicketsExist(value) {
    this.ticketsExist = value;
  }

  @Mutation
  setRailTravellersSeatRequirements(value) {
    this.railTravellersSeatRequirements = value;
  }

  @Mutation
  setTicketsExistLoading(value) {
    this.ticketsExistLoading = value;
  }

  @Mutation
  setRailTickets(value) {
    this.railTickets = value;
  }

  @Mutation
  setChangesInLoyaltyCards(value) {
    const itemIndex = this.modifyTravellersLoyaltyPrograms.findIndex(item => item.profileId === value.profileId);

    if (itemIndex === -1) {
      this.modifyTravellersLoyaltyPrograms.push(value);
      return;
    }

    this.modifyTravellersLoyaltyPrograms[itemIndex] = value;
  }

  @Mutation
  deselectSelectedRailSeat(seat) {
    const sameSeatIndex = this.basketRailSelectedSeats.findIndex(other => {
      return other.segmentId === seat.segmentId &&
        other.coachNumber === seat.coachNumber &&
        other.seatNumber === seat.seatNumber &&
        other.profileId === seat.profileId;
    });

    if (-1 === sameSeatIndex) {
      return;
    }

    this.basketRailSelectedSeats.splice(sameSeatIndex, 1);
  }

  @Mutation
  setSelectedRailSeat(seat) {
    const anyOtherIndex = this.basketRailSelectedSeats.findIndex(other => {
      return other.segmentId === seat.segmentId &&
        other.coachNumber === seat.coachNumber &&
        other.seatNumber === seat.seatNumber &&
        other.profileId !== seat.profileId;
    });
    const anySeatIndex = this.basketRailSelectedSeats.findIndex(old => {
      return old.segmentId === seat.segmentId && old.profileId === seat.profileId;
    });

    if (-1 === anySeatIndex && -1 === anyOtherIndex) {
      this.basketRailSelectedSeats.push(seat);
    } else if (-1 < anySeatIndex && -1 === anyOtherIndex) {
      this.basketRailSelectedSeats[anySeatIndex].coachNumber = seat.coachNumber;
      this.basketRailSelectedSeats[anySeatIndex].seatNumber = seat.seatNumber;
      this.basketRailSelectedSeats[anySeatIndex].price = seat.price;
      if (seat.alternateNumber) {
        this.basketRailSelectedSeats[anySeatIndex].alternateNumber = seat.alternateNumber;
      }
    } else if (-1 === anySeatIndex && -1 < anyOtherIndex) {
      this.basketRailSelectedSeats[anyOtherIndex].profileId = seat.profileId;
    } else {
      const swapped = {
        ...this.basketRailSelectedSeats[anySeatIndex],
      };
      this.basketRailSelectedSeats[anySeatIndex].profileId = this.basketRailSelectedSeats[anyOtherIndex].profileId;
      this.basketRailSelectedSeats[anyOtherIndex].profileId = swapped.profileId;
    }
  }

  @Mutation
  setBookingStepLoading(value) {
    this.bookingStepLoading = value;
  }

  @Mutation
  setInitialising(value) {
    this.initialising = value;
  }

  @Mutation
  setProcessing(value) {
    this.processing = value;
  }

  @Mutation
  setBooking(value) {
    this.booking = value;
  }

  @Mutation
  setCancelling(value) {
    this.cancelling = value;
  }

  @Mutation
  setLoadingItems(value) {
    this.loadingItems = value;
  }

  @Mutation
  setLoadingUnconfirmedStatus(value) {
    this.loadingUnconfirmedStatus = value;
  }

  @Mutation
  setShowRefreshButton(value) {
    this.showRefreshButton = value;
  }

  @Mutation
  setLoadingSeatsMap(value) {
    this.loadingSeatsMap = value;
  }

  @Mutation
  setLoadingLoyaltyPrograms(value) {
    this.loadingLoyaltyPrograms = value;
  }

  @Mutation
  setBasketLoyaltyPrograms(value) {
    this.basketLoyaltyPrograms = value;
  }

  @Mutation
  setSelectedLoyalties(values) {
    this.selectedLoyalties = values;
  }

  @Mutation
  setSelectedLoyalty(value) {
    let loyalty = this.selectedLoyalties.find(item => {
      return item.tripItemId === value.tripItemId &&
        item.travellerProfileId === value.travellerProfileId;
    });

    if (!loyalty) {
      this.selectedLoyalties.push(value);
    } else {
      loyalty.loyaltyCardId = value.loyaltyCardId;
    }
  }

  @Mutation
  unsetSelectedLoyalty({ tripItemId, travellerProfileId }) {
    const index = this.selectedLoyalties.findIndex(item => {
      return item.tripItemId === tripItemId &&
        item.travellerProfileId === travellerProfileId;
    });

    if (-1 === index) {
      return;
    }

    this.selectedLoyalties.splice(index, 1);
  }

  @Mutation
  resetSelectedLoyalties({ tripItemId }) {
    this.selectedLoyalties = this.selectedLoyalties.filter(item => {
      return item.tripItemId !== tripItemId;
    });
  }

  @Mutation
  setLoadingAncillaries(value) {
    this.loadingAncillaries = value;
  }

  @Mutation
  setLoadingPaymentMethods(value) {
    this.loadingPaymentMethods = value;
  }

  @Mutation
  setLoadingPaymentMethodsFailed(value) {
    this.loadingPaymentMethodsFailed = value;
  }

  @Mutation
  setLoadingExtras(value) {
    this.loadingExtras = value;
  }

  @Mutation
  setPostTicketingExtrasBooking(value) {
    this.postTicketingExtrasBooking = value;
  }

  @Mutation
  setBasketSeatsMapError(value: boolean) {
    this.basketSeatsMapError = value;
  }

  @Mutation
  setBasketProcessingStoreSeats(value: boolean) {
    this.basketProcessingStoreSeats = value;
  }

  @Mutation
  setShouldShowUnconfirmedNotifications(value) {
    this.shouldShowUnconfirmedNotifications = value;
  }

  @Mutation
  setShowUnconfirmedNotificationsPopup(value) {
    this.showUnconfirmedNotificationsPopup = value;
  }

  @Mutation
  setBasketId(value) {
    this.basketId = value;
  }

  @Mutation
  setBasket(value) {
    this.basket = value;
  }

  @Mutation
  setBasketStatus(value) {
    this.status = value;
  }

  @Mutation
  setBasketTitle(value) {
    this.basketTitle = value;
  }

  @Mutation
  setBasketOriginalValue(value) {
    if (!this.basket) {
      return;
    }
    this.basket.name = value;
  }

  @Mutation
  setSelectedApprover({
    approver,
    level,
  }) {
    const approvalLevel = this.selectedApprovers.find(item => item.level === level);
    if (!approvalLevel) {
      this.selectedApprovers.push({
        level,
        approver,
      });
    } else {
      approvalLevel.approver = approver;
    }
  }

  @Mutation
  resetSelectedApprovers() {
    this.selectedApprovers = [];
  }

  @Mutation
  setValidCustomParameters(value) {
    this.validCustomParameters = value;
  }

  @Mutation
  setTravellersDateOfBirthCheckFailed(value) {
    this.travellersDateOfBirthCheckFailed = value;
  }

  @Mutation
  setLoadApisInfoFailed(value) {
    this.loadApisInfoFailed = value;
  }

  @Mutation
  setCurrentApisRules(value) {
    this.currentApisRules = value;
  }

  @Mutation
  setCurrentMandatoryApisRules(value) {
    this.currentMandatoryApisRules = value;
  }

  @Mutation
  setShouldShowApisRulesValidationErrors(value) {
    this.shouldShowApisRulesValidationErrors = value;
  }

  @Mutation
  setHasMissingApisDocuments(value) {
    this.hasMissingApisDocuments = value;
  }

  @Mutation
  clearBasketParameters() {
    this.basketParameters = new Array<OfferParameter>();
  }

  @Mutation
  updateItemCustomParameters(parameters: OfferParameter[]) {
    this.basketParameters = parameters;
  }

  @Mutation
  setCommentToSave(comment: TripComment) {
    this.basketCommentToSave = comment;
  }

  @Mutation
  setCommentFlowToSave(commentFlow) {
    if (!this.basketCommentToSave) {
      return;
    }
    this.basketCommentToSave.commentFlow = commentFlow;
  }

  @Mutation
  setHasCommentToSave(value: boolean) {
    this.basketHasCommentToSave = value;
  }

  @Mutation
  setBasketCommentsRemoveErrors(value) {
    this.basketCommentsRemoveErrors = value;
  }

  @Mutation
  setBasketCommentsAddErrors(value) {
    this.basketCommentsAddErrors = value;
  }

  @Mutation
  setComments(value) {
    this.comments = value;
  }

  @Mutation
  setCommentsLoading(value) {
    this.commentsLoading = value;
  }

  @Mutation
  setCommentsErrors(value) {
    this.commentsErrors = value;
  }

  @Mutation
  setBasketCommentOriginalAddError(value) {
    this.basketCommentOriginalAddError = value;
  }

  @Mutation
  formatItemCustomParameterValue(p: OfferParameter) {
    if (p.isDateFormat && p.value) {
        let format = p.momentJsDateFormat ? p.momentJsDateFormat : 'DD/MM/YYYY';
        p.value = moment(p.value, format).format('YYYY-MM-DD');
    }
  }

  @Mutation
  updateItemParameterValue(parameter: OfferParameter) {
    let existingParameter = this.basketParameters.find(x => x.itemId === parameter.itemId && x.key === parameter.key && x.profileId === parameter.profileId);
    if (existingParameter) {
      existingParameter.value = parameter.value;
    }
  }

  @Mutation
  setApprovalWorkflowForBasket(payload) {
    this.approvalWorkflowForBasket = payload;
  }

  @Mutation
  setWorkflowError(value) {
    this.workflowError = value;
  }

  @Mutation
  resetTravellersNewDateOfBirthCollection() {
    this.travellersNewDateOfBirthCollection = [];
  }

  @Mutation
  updateTravellersNewDateOfBirth({ id, dateOfBirth }) {
    const item = this.travellersNewDateOfBirthCollection.find(element => element.id === id);

    if (item) {
      item.dateOfBirth = dateOfBirth;
    } else {
      this.travellersNewDateOfBirthCollection.push({ id, dateOfBirth });
    }
  }

  @Mutation
  resetOverwrittenTravellersNamesCollection() {
    this.overwrittenTravellersNamesCollection = [];
  }

  @Mutation
  saveOverwrittenTravellersNames({ id, firstName, middleName, lastName }) {
    const item = this.overwrittenTravellersNamesCollection.find(element => element.id === id);

    if (item) {
      item.firstName = firstName;
      item.middleName = middleName;
      item.lastName = lastName;
    } else {
      this.overwrittenTravellersNamesCollection.push({ id, firstName, middleName, lastName });
    }
  }

  @Mutation
  setErrors(errors) {
    this.errors = errors;
  }

  @Mutation
  setProcessingErrors(errors) {
    this.processingErrors = errors;
  }

  @Mutation
  setDisplayApproverEmpty(value) {
    this.displayApproverEmpty = value;
  }

  @Mutation
  setDisplayObligatoryConfirmationEmpty(value) {
    this.displayObligatoryConfirmationEmpty = value;
  }

  @Mutation
  setShowPriceChangePopup(value) {
    this.showPriceChangePopup = value;
  }

  @Mutation
  setShowCarPriceChangePopup(value) {
    this.showCarPriceChangePopup = value;
  }

  @Mutation
  setShowRefundDataPopup(value) {
    this.showRefundDataPopup = value;
  }

  @Mutation
  setRefundedItem(value) {
    this.refundedItem = value;
  }

  @Mutation
  setShowRetrySection(value) {
    this.showRetrySection = value;
    if (value) {
      this.processing = true;
    }
  }

  @Mutation
  setShowing3DSInterruptedPopup(value: boolean) {
    this.showing3DSInterruptedPopup = value;
  }

  @Mutation
  setIsRetryCancel(value) {
    this.isRetryCancel = value;
  }

  @Mutation
  setShowRetryPopup(value) {
    this.showRetryPopup = value;
  }

  @Mutation
  setShowTripActionErrorPopup(value) {
    this.showTripActionErrorPopup = value;
  }

  @Mutation
  setSkip(value) {
    this.skip = value;
  }

  @Mutation
  setRetry(value) {
    this.retry = value;
  }

  @Mutation
  setRetryButtonDisabled(value) {
    this.retryButtonDisabled = value;
  }

  @Mutation
  setBookingStep(value) {
    this.bookingStep = value;
  }

  @Mutation
  setWizardSteps(value) {
    this.wizardSteps = value;
  }

  @Mutation
  setSelectedDocumentsAsFoid({ profileId, documentId, document }) {
    const profileAttachment = this.selectedDocumentsAsFoid.find(item => item.profileId === profileId);
    if (!profileAttachment) {
      this.selectedDocumentsAsFoid.push({
        profileId,
        documentId,
        document,
      });
      return;
    }
    profileAttachment.documentId = documentId;
    profileAttachment.document = document;
  }

  @Mutation
  removeSelectedDocumentsAsFoid(documentId) {
    const sameSelectedDocumentIndex = this.selectedDocumentsAsFoid.findIndex(item => {
      return item.documentId === documentId;
    });

    if (-1 === sameSelectedDocumentIndex) {
      return;
    }

    this.selectedDocumentsAsFoid.splice(sameSelectedDocumentIndex, 1);
  }

  @Mutation
  setShowSelectDocumentsAsFoidError(value) {
    this.showSelectDocumentsAsFoidError = value;
  }

  @Mutation
  clearSelectedDocumentsAsFoid() {
    this.selectedDocumentsAsFoid = [];
  }

  @Mutation
  setFoidErrorForProfileIdAndIndex({ profileId, index }) {
    const existing = this.foidErrorsProfileIdsAndIndices
      .find(item => item.profileId === profileId);
    
    if (existing) {
      existing.index = index;
      return;
    }

    this.foidErrorsProfileIdsAndIndices.push({
      profileId,
      index,
    });
  }

  @Mutation
  clearFoidErrorsProfileIdsAndIndices() {
    this.foidErrorsProfileIdsAndIndices = [];
  }

  @Mutation
  updateWizardSteps({ steps, code }) {
    const newIndex = steps.findIndex(step => step.code === code);

    this.wizardSteps = steps;

    if (newIndex > -1) {
      this.bookingStep = newIndex + 1;
    }
  }

  @Mutation
  setWizardStepSelected({ index, value }) {
    if (index >= this.wizardSteps.length) {
      return;
    }
    this.wizardSteps[index].selected = value;
  }

  @Mutation
  removeWizardStepByCode(code) {
    let index = this.wizardSteps.findIndex(step => {
      return step.code === code;
    });
    if (-1 < index) {
      this.wizardSteps.splice(index, 1);
    }
  }

  @Mutation
  setFinalBookOption(value) {
    this.finalBookOption = value;
  }

  @Mutation
  setBasketSeatsMap(value) {
    this.basketSeatsMap = value;
  }

  @Mutation
  setBasketAncillaries(value) {
    this.basketAncillaries = value;
  }

  @Mutation
  setBasketAncillariesFlights(value) {
    this.basketAncillariesFlights = value;
  }

  @Mutation
  setBasketAncillariesMessages(value) {
    this.basketAncillariesMessages = value;
  }

  @Mutation
  setMaxOneAncillaryPerSegment(value) {
    this.maxOneAncillaryPerSegment = value;
  }

  @Mutation
  setMinimumAncillaryCount(value) {
    this.minimumAncillaryCount = value;
  }

  @Mutation
  setHasExactOneAncillaryError(value) {
    this.hasExactOneAncillaryError = value;
  }

  @Mutation
  setHasMaxOneAncillaryError(value) {
    this.hasMaxOneAncillaryError = value;
  }

  @Mutation
  setShowMaxOneAncillaryError(value) {
    this.showMaxOneAncillaryError = value;
  }

  @Mutation
  setLoadingBasketTravellersDocuments(value) {
    this.loadingBasketTravellersDocuments = value;
  }

  @Mutation
  setLoadingBasketTravellersDocumentsErrors(value) {
    this.loadingBasketTravellersDocumentsErrors = value;
  }

  @Mutation
  setBasketTravellersDocuments(value) {
    this.basketTravellersDocuments = value;
  }

  @Mutation
  resetTravellersMissingDocumentCollection() {
    this.travellersMissingDocumentCollection = [];
  }

  @Mutation
  setTravellersMissingDocumentCollection({ profileId, documents }) {
    const existing = this.travellersMissingDocumentCollection
      .find(item => item.profileId === profileId);

    if (existing) {
      existing.documents = documents;
      return;
    }

    this.travellersMissingDocumentCollection.push({
      profileId,
      documents,
    });
  }

  @Mutation
  setLoadingReviewTravelPolicyCompliance(value) {
    this.loadingReviewTravelPolicyCompliance = value;
  }

  @Mutation
  setTravelPolicyCompliance(value) {
    this.travelPolicyCompliance = value;
  }

  @Mutation
  setCanShowTravelPolicyComplianceStep(value) {
    this.canShowTravelPolicyComplianceStep = value;
  }

  @Mutation
  setLoadingReviewTravelPolicyComplianceFailed(value) {
    this.loadingReviewTravelPolicyComplianceFailed = value;
  }

  @Mutation
  setCanShowSelectApproverStep(value) {
    this.canShowSelectApproverStep = value;
  }

  @Mutation
  setLoadingApprovalDataAfterExtras(value) {
    this.loadingApprovalDataAfterExtras = value;
  }

  @Mutation
  setLoadingApprovalDataAfterExtrasFailed(value) {
    this.loadingApprovalDataAfterExtrasFailed = value;
  }

  @Mutation
  setSelectApproverData(data) {
    this.selectApproverData = data;
  }

  @Mutation
  setPaymentMethods(value) {
    this.basketPaymentMethods = value;
  }

  @Mutation
  setHasCustomFields(value) {
    this.hasCustomFields = value;
  }

  @Mutation
  setHasCustomFieldsLoaded(value) {
    this.hasCustomFieldsLoaded = value;
  }

  @Mutation
  setHasCustomFieldsErrors(value) {
    this.hasCustomFieldsErrors = value;
  }

  @Mutation
  setCanApproveTrip(value) {
    this.canApproveTrip = value;
  }
  
  @Mutation
  setBasketExtras(value) {
    this.basketExtras = value;
  }

  @Mutation
  setBasketExtrasErrors(value) {
    this.basketExtrasErrors = value;
  }

  @Mutation
  setSkipExtrasSelection(value) {
    this.skipExtrasSelection = value;
  }

  @Mutation
  setSelectedExistingTraveller(values) {
    this.selectedExistingTraveller = values;
  }

  @Mutation
  setBasketItems(items: BasketFlatItemModel[]) {
    this.basketItems = items;
  }

  @Mutation
  setBasketNotificationsCount(notifications) {
    this.notificationsCount = notifications;
  }

  @Mutation
  setBasketItemPrice({ id, price }) {
    const foundItem = this.basketItems.find(item => {
      return item.id === id;
    });
    if (foundItem) {
      foundItem.price = price;
    }
  }

  @Mutation
  setProcessBasketID(value) {
    this.processBasketID = value;
  }

  @Mutation
  setBasketItemId(id: string) {
    this.basketItemId = id;
  }

  @Mutation
  setLastBasketItemId(id: string) {
    this.lastBasketItemId = id;
  }

  @Mutation
  setExpedia3DSTripItemId(id: string) {
    this.expedia3DSTripItemId = id;
  }

  @Mutation
  setExpedia3DSUrl(url: string) {
    this.expedia3DSUrl = url;
  }

  @Mutation
  setExpedia3DSSandbox(sandbox) {
    this.expedia3DSSandbox = sandbox;
  }

  @Mutation
  setBasketItemPendingActionCode(value) {
    this.basketItemPendingActionCode = value;
  }

  @Mutation
  setLastBasketItemProviderReferenceId(id: string) {
    this.lastBasketItemProviderReferenceId = id;
  }

  @Mutation
  setSendingAnswer(value: boolean) {
    this.sendingAnswer = value;
  }

  @Mutation
  setBasketItem(payload) {
    if (!this.basketItems.length || !this.basketItems.find(item => item.id === payload.id)) {
      this.basketItems.push(payload.data);
    }
    this.basketItems = this.basketItems.map(item => {
      if (item.id !== payload.id) {
        return item;
      }
      
      return payload.data;
    });
  }

  @Mutation
  setConfirmingFlag(value) {
    this.confirming = value;
  }

  @Mutation
  resetTemporaryData() {
    this.selectedSeats.seats = [];
    this.basketRailSelectedSeats = [];
    this.selectedAncillaries = [];
    this.selectedRailAncillaries = [];
  }

  @Mutation
  resetPriceChanges() {
    this.priceChanges = [];
  }

  @Mutation
  resetPriceChangesForSteps({ from, to }) {
    this.priceChanges = this.priceChanges.filter(change => {
      if (change.step >= from && change.step <= to) {
        return false;
      }
      return true;
    });
  }

  @Mutation
  setSelectedSeats(value) {
    this.selectedSeats = value;
  }

  @Mutation
  setSeatSelection(value) {
    this.seatSelection = value;
  }

  @Mutation
  setSeatSelectionForItem(value) {
    let index = this.tripSeatSelection.findIndex((item: any) => {
      return item.id === value.id;
    });
    if (-1 < index) {
      this.tripSeatSelection.splice(index, 1, value);
    } else {
      this.tripSeatSelection.push(value);
    }
  }

  @Mutation
  setAncillarySelectedMutation({ offer, value, profileId }) {
    const ancillaryIndex = this.selectedAncillaries
      .findIndex(a => a.id === offer.id && a.profileId === profileId);

    if (value) {
      if (-1 === ancillaryIndex) {
        this.selectedAncillaries.push({
          ...offer,
          profileId,
        });
      }
    } else {
      if (-1 < ancillaryIndex) {
        this.selectedAncillaries.splice(ancillaryIndex, 1);
      }
    }
  }

  @Mutation
  setRailAncillarySelected({ offer, segmentId, value, profileId }) {
    const ancillaryIndex = this.selectedRailAncillaries
      .findIndex(a => a.product.code === offer.product.code && a.profileId === profileId && a.segmentId === segmentId);

    if (value) {
      if (-1 === ancillaryIndex) {
        this.selectedRailAncillaries.push({
          ...offer,
          segmentId,
          profileId,
        });
      }
    } else {
      if (offer.product.forAllPassengers) {
        this.selectedRailAncillaries = this.selectedRailAncillaries.filter(selected => selected.product.code !== offer.product.code || selected.segmentId !== segmentId);
      } else {
        if (-1 < ancillaryIndex) {
          this.selectedRailAncillaries.splice(ancillaryIndex, 1);
        }
      }
    }
  }

  @Mutation
  resetSelectedSeats() {
    this.selectedSeats = {
      id: '',
      seats: []
    };
  }

  @Mutation
  resetSeatSelection() {
    this.seatSelection = {
      id: '',
      seats: [],
    };
  }
  
  @Mutation
  resetSelectedRailSeats() {
    this.basketRailSelectedSeats = [];
  }

  @Mutation
  setSelectedAncillaries(values) {
    this.selectedAncillaries = values;
  }

  @Mutation
  resetSelectedAncillaries() {
    this.selectedAncillaries = [];
  }

  @Mutation
  setSelectedRailAncillaries(values) {
    this.selectedRailAncillaries = values;
  }

  @Mutation
  resetSelectedRailAncillaries() {
    this.selectedRailAncillaries = [];
  }

  @Mutation
  setSelectedExtras() {
    this.selectedExtras = this.basketExtras.map(e => {
      if (e && e.isSelected) {
        return e;
      }
    }).filter(Boolean);
  }

  @Mutation 
  resetSelectedExtras() {
    this.selectedExtras = [];
  }

  @Mutation
  resetPriceChangesForStep({ step, tripItemId }) {
    const stepChanges = this.priceChanges.find(item => item.step === step && item.tripItemId === tripItemId);
    if (!stepChanges) {
      return;
    }
    stepChanges.prices = [];
  }

  @Mutation
  setPriceChangesForStep({ step, tripItemId, prices, type }) {
    const stepChanges = this.priceChanges.find(item => item.step === step && item.tripItemId === tripItemId);
    if (stepChanges) {
      stepChanges.prices = prices;
      stepChanges.type = type;
    } else {
      this.priceChanges.push({
        tripItemId,
        step,
        prices,
        type,
      });
    }
  }

  @Mutation 
  resetIsChangingPriceOnPaymentMethod() {
    this.isChangingPriceOnPaymentMethod = false;
  }

  @Mutation
  updateAgencyMarkupValue(value) {
    this.updatedAgencyMarkupValue = value;
  }

  @Mutation
  setExpenseIdForBasket(value) {
    this.expenseIdForBasket = value;
  }

  @Mutation
  setPreBookingData(value) {
    this.preBookingData = value;
  }

  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  updateBasketTravelersUnsaved(value) {
    this.basketTravelersUnsaved = value;
  }

  @Mutation
  setGuestBasketTravellers(value) {
    this.guestBasketTravellers = value;
  }

  @Mutation
  setGuestBasketTraveller({ traveller, index }) {
    this.guestBasketTravellers.splice(index, 1, traveller);
  }

  @Mutation
  setGuestMainTravellerIndex(value) {
    this.guestMainTravellerIndex = value;
  }

  @Mutation
  setGuestBasketTravellerData(params) {
    let dataIndex = this.guestBasketTravellersData.findIndex(data => {
      return params.id === data.id;
    });
    if (-1 < dataIndex) {
      this.guestBasketTravellersData.splice(dataIndex, 1, params);
    } else {
      this.guestBasketTravellersData.push(params);
    }
  }

  @Mutation
  setGuestBasketTravellerDocument({ id, document }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest) {
      guest.documents.push(document);
    }
    else {
      this.guestBasketTravellersData.push({ id, profileId: undefined, loyalties: [], documents: [document] });
    }
  }

  @Mutation
  setGuestBasketTravellerLoyalty({ id, loyalty }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest) {
      guest.loyalties.push(loyalty);
    }
    else {
      this.guestBasketTravellersData.push({ id, profileId: undefined, loyalties: [loyalty], documents: [] });
    }
  }

  @Mutation
  setGuestBasketTravellerIsVirtual({ isVirtual, index, wasVirtual }) {
    this.guestBasketTravellers[index].isVirtual = isVirtual;
    if (wasVirtual) {
      this.guestBasketTravellers[index].wasVirtual = wasVirtual;
    }
  }
  
  @Mutation
  setGuestBasketTravellerId({ id, index }) {
    this.guestBasketTravellersData.forEach(data => {
      if (data.id === this.guestBasketTravellers[index].id) {
        data.id = id;
      }
    });
    this.guestBasketTravellers[index].id = id;
    this.guestBasketTravellers[index].profileId = id;
  }

  @Mutation
  normalizeGuestBasketTravellers() {
    this.guestBasketTravellers = this.guestBasketTravellers.map((trav, index) => {
      let traveller = {
        ...trav,
      };
      if (trav.isVirtual) {
        traveller.contacts = {
          primary: {
            email: trav.email,
            phone: {
              code: trav.phoneCode ? trav.phoneCode.phoneCode : null,
              number: trav.phoneNumber,
            }
          }
        };
      } else {
        traveller.profileId = trav.id === traveller.id ? trav.profileId : trav.id;
      }
      return traveller;
    });
  }

  @Mutation
  updateGuestBasketTravellerDocument({ id, document }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest && guest.documents) {
      let index = guest.documents.findIndex(doc => {
        return doc.tempId === document.tempId;
      });
      if (-1 < index) {
        guest.documents.splice(index, 1, document);
      }
    }
  }

  @Mutation
  updateGuestBasketTravellerLoyalty({ id, loyalty }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest && guest.loyalties) {
      let index = guest.loyalties.findIndex(doc => {
        return doc.tempId === loyalty.tempId;
      });
      if (-1 < index) {
        guest.loyalties.splice(index, 1, loyalty);
      }
    }
  }

  @Mutation
  removeGuestBasketTravellerDocument({ id, documentId }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest && guest.documents) {
      let index = guest.documents.findIndex(doc => {
        return doc.tempId === documentId;
      });
      if (-1 < index) {
        guest.documents.splice(index, 1);
      }
    }
  }
  
  @Mutation
  removeGuestBasketTravellerLoyalty({ id, loyaltyId }) {
    let guest = this.guestBasketTravellersData.find(data => {
      return data.id === id;
    });
    if (guest && guest.loyalties) {
      let index = guest.loyalties.findIndex(doc => {
        return doc.tempId === loyaltyId;
      });
      if (-1 < index) {
        guest.loyalties.splice(index, 1);
      }
    }
  }

  @Mutation
  resetGuestBasketTravellerData() {
    this.guestBasketTravellersData = [];
  }

  @Mutation
  setGuestBasketTravellersError(value) {
    this.guestBasketTravellersError = value;
  }

  @Mutation
  setCustomerCodeSectionError(value) {
    this.customerCodeSectionError = value;
  }

  @Mutation
  setCustomerCodeSectionLoadingError(value) {
    this.customerCodeSectionLoadingError = value;
  }

  @Mutation
  setIsChangingPaymentMethod(value) {
    this.isChangingPaymentMethod = value;
  }

  @Mutation
  setIsModifyTravellersDetailsLoading(value) {
    this.isModifyTravellersDetailsLoading = value;
  }

  @Mutation
  setModifyTravellersDetails(value) {
    this.modifyTravellersDetails = value;
  }

  @Mutation
  setModifyTravellersDetailsLoadingErrors(value) {
    this.modifyTravellersDetailsLoadingErrors = value;
  }

  @Mutation
  setModifyTravellersDetailsSaveErrors(value) {
    this.modifyTravellersDetailsSaveErrors = value;
  }

  @Mutation
  resetModifyTravellersDetailsFormStatus() {
    this.modifyTravellersDetailsFormStatus = [];
  }

  @Mutation
  setModifyTravellersDetailsFormStatus({ travellerId, form, loyalty, noSection }) {
    const existing = this.modifyTravellersDetailsFormStatus
      .find(item => item.travellerId === travellerId);
    
    if (!existing) {
      this.modifyTravellersDetailsFormStatus.push({
        travellerId,
        form,
        loyalty,
        noSection,
      });
      return;
    }

    if (form !== null) {
      existing.form = form;
    }
    if (loyalty !== null) {
      existing.loyalty = loyalty;
    }
    if (noSection !== null) {
      existing.noSection = noSection;
    }
  }

  @Mutation
  setModifyTravellersDetailsLoyaltiesTouched(value) {
    this.modifyTravellersDetailsLoyaltiesTouched = value;
  }

  @Mutation
  setIsModifyingTravellersDetails(value) {
    this.isModifyingTravellersDetails = value;
  }

  @Mutation
  setModifiedTravellersDetails(value) {
    this.modifiedTravellersDetails = value;
  }

  @Mutation
  filterOutNotEditedDetails() {
    this.modifiedTravellersDetails = this.modifiedTravellersDetails
      .filter(item => item != null);
  }

  @Mutation
  setModifiedTraveller({
    data, index,
  }) {
    this.modifiedTravellersDetails[index] = data;
  }

  @Mutation
  setModifiedTripItemId(value) {
    this.modifiedTripItemId = value;
  }

  @Mutation
  setBookingExtras(value) {
    this.bookingExtras = value;
  }
  @Mutation
  setGuestBasketTravellersChecked(value) {
    this.guestBasketTravellersChecked = value;
  }

  @Mutation
  setGuestBasketTravellersDataError(value) {
    this.guestBasketTravellersDataError = value;
  }

  @Mutation
  setGuestBasketTravellersDataLoading(value) {
    this.guestBasketTravellersDataLoading = value;
  }

  @Mutation
  setGuestsSaving(value) {
    this.guestsSaving = value;
  }

  @Mutation
  setGuestsProfileSaving(value) {
    this.guestsProfileSaving = value;
  }

  @Mutation
  setBasketTitleErrors(errors) {
    this.basketTitleErrors = errors;
  }

  @Mutation
  setCustomFields(fields) {
    this.customFields = fields;
  }

  @Mutation
  clearCustomFieldsErrors() {
    this.customFieldsErrors = [];
  }

  @Mutation
  addCustomFieldsError(error) {
    this.customFieldsErrors.push(error);
  }

  @Mutation
  setBasketMetadata(params: StoreBasketMetadata | null) {
    this.basketMetadata = params ? { basketId: params.basketId, metadata: params.metadata } : null;
  }

  @Mutation
  setBasketRailSeatsMap(seats) {
    this.basketRailSeatsMap = seats;
  }

  @Mutation
  setBasketRailSeatsErrors(errors) {
    this.basketRailSeatsMapErrors = errors;
  }

  @Mutation
  setBasketRailLoading(value) {
    this.basketRailSeatsMapLoading = value;
  }

  @Mutation
  setShowPaymentTerminatedPopup(value) {
    this.showPaymentTerminatedPopup = value;
    if (!value) {
      this.paymentTerminatedBasketId = '';
    }
  }

  @Mutation
  setPaymentTerminatedBasketId(value) {
    this.paymentTerminatedBasketId = value;
  }

  @Mutation
  setBasketPaymentMethodsError(value) {
    this.basketPaymentMethodsError = value;
  }

  @Mutation
  setSelectedPaymentMethods(value) {
    const index = this.selectedPaymentMethods.findIndex(e => {
      return e.tripItemId === value.tripItemId;
    });
    if (-1 === index) {
      this.selectedPaymentMethods.push(value);
    } else {
      this.selectedPaymentMethods.splice(index, 1, value);
    }
  }

  @Mutation
  setLoadingHotelAssignmentOptions(value) {
    this.loadingHotelAssignmentOptions = value;
  }

  @Mutation
  resetHotelAssignmentOptions() {
    this.hotelAssignmentOptions = null;
  }

  @Mutation
  setHotelAssignmentOptions(value) {
    this.hotelAssignmentOptions = value;
  }

  @Mutation
  setLoadingHotelAssignmentErrors(value) {
    this.loadingHotelAssignmentErrors = value;
  }

  @Mutation
  changeHotelAssignmentOptions(params) {
    if (null == params.roomIndex || params.roomIndex >= this.hotelAssignmentOptions.length) {
      return;
    }
    const room = this.hotelAssignmentOptions[params.roomIndex];

    if (!room) {
      return;
    }
    
    if (null == params.index || params.index >= room.slots.length) {
      return;
    }
    const slot = room.slots[params.index];
    if (!slot) {
      return;
    }

    slot.profileId = params.profileId;
  }

  @Mutation
  removeHotelAssignmentSlot(params) {
    if (null == params.roomIndex || params.roomIndex >= this.hotelAssignmentOptions.length) {
      return;
    }
    const room = this.hotelAssignmentOptions[params.roomIndex];

    if (!room) {
      return;
    }
    
    if (null == params.index || params.index >= room.slots.length) {
      return;
    }

    room.slots.splice(params.index, 1);
  }

  @Mutation
  addHotelAssignmentSlot(params) {
    if (null == params.roomIndex || params.roomIndex >= this.hotelAssignmentOptions.length) {
      return;
    }
    const room = this.hotelAssignmentOptions[params.roomIndex];

    if (!room) {
      return;
    }
    
    room.slots.push({
      profileId: null,
    });
  }

  @Mutation
  setSelectedCreditCardPaymentMethodsMutation(value) {
    const index = this.selectedCreditCardPaymentMethods.findIndex(e => {
      return e.tripItemId === value.tripItemId;
    });
    if (-1 === index) {
      this.selectedCreditCardPaymentMethods.push(value);
    } else {
      this.selectedCreditCardPaymentMethods.splice(index, 1, value);
    }
  }

  @Mutation
  resetSelectedPaymentMethods() {
    this.basketPaymentMethods = [];
    this.selectedPaymentMethods = [];
  }

  @Mutation
  resetSelectedCreditCardPaymentMethods() {
    this.selectedCreditCardPaymentMethods = [];
  }

  @Mutation
  setLufthansaStatusConfirmationSection(payload) {
    this.lufthansaStatusConfirmationSection = payload;
  }

  @Mutation
  setSabreStatusConfirmationSection(payload) {
    this.sabreStatusConfirmationSection = payload;
  }

  @Mutation
  setAmadeusStatusConfirmationSection(payload) {
    this.amadeusStatusConfirmationSection = payload;
  }

  @Mutation
  setIsAvailableResponsesSections(payload) {
    this.isAvailableResponsesSections = payload;
  }

  @Mutation
  setShowBasketExpedia3DSPopup(payload) {
    this.showBasketExpedia3DSPopup = payload;
  }

  @Mutation
  setStartBasketExpedia3DS(payload) {
    this.startBasketExpedia3DS = payload;
  }

  @Mutation
  setLatestBasketData(payload) {
    this.latestBasketData = payload;
  }

  @Mutation
  setCustomFieldsLoading(value: boolean) {
    this.customFieldsLoading = value;
  }
  
  @Mutation
  setCustomFieldsResponded(value: boolean) {
    this.customFieldsResponded = value;
  }

  @Mutation
  setStorePaymentErrors(value) {
    this.storePaymentErrors = value;
  }

  @Mutation
  setAncillariesAndPassengerDataErrors(value) {
    this.ancillariesAndPassengerDataErrors = value;
  }

  @Mutation
  setAncillariesAndPassengerDataLoadingFailed(value) {
    this.ancillariesAndPassengerDataLoadingFailed = value;
  }

  @Mutation
  setLoyaltyProgramsLoadingFailed(value) {
    this.loyaltyProgramsLoadingFailed = value;
  }

  @Mutation
  setAncillariesLoadingFailed(value) {
    this.ancillariesLoadingFailed = value;
  }

  @Mutation
  setSeatsLoadingFailed(value) {
    this.seatsLoadingFailed = value;
  }

  @Mutation
  setPaymentFormResponded(value: boolean) {
    this.paymentFormResponded = value;
  }
  
  @Mutation
  setBasketRailSncfSeatPreferences(value) {
    this.basketRailSncfSeatPreferences = value;
  }

  @Mutation
  setBasketRailSncfSeatPreferencesError(value) {
    this.basketRailSncfSeatPreferencesError = value;
  }

  @Mutation
  setBasketRailBenerailSeatPreferencesError(value) {
    this.basketRailBenerailSeatPreferencesError = value;
  }

  @Mutation
  setBasketRailBenerailSeatPreferences(value) {
    this.basketRailBenerailSeatPreferences = value || [];
  }

  @Mutation
  setBasketRailSncfRailAncillaries(value) {
    this.basketRailSncfAncillaries = value;
  }

  @Mutation
  setBasketRailSeatPreferencesLoading(value) {
    this.basketRailSeatPreferencesLoading = value;
  }

  @Mutation
  setBasketRailAncillariesLoading(value) {
    this.basketRailAncillariesLoading = value;
  }

  @Mutation
  setBusinessErrors(errors) {
    this.businessErrors = errors;
  }
  
  @Mutation
  setRailSeatsLoadingFailed(value) {
    this.railSeatsLoadingFailed = value;
  }

  @Mutation
  setRailAncillariesLoadingFailed(value) {
    this.railAncillariesLoadingFailed = value;
  }

  @Mutation
  setCarExtrasLoadingFailed(value) {
    this.carExtrasLoadingFailed = value;
  }

  @Mutation
  updateCurrentWizardStepTravellerId(profileId) {
    if (this.bookingStep === 0 || this.bookingStep > this.wizardSteps.length) {
      return;
    }
    this.wizardSteps[this.bookingStep - 1].travellerId = profileId;
  }

  @Mutation
  updateBasketTravellerId({ oldId, profileId }) {
    if (!this.basket) {
      return;
    }
    const traveller = this.basket.travellers.find(item => item.id === oldId);
    if (!traveller) {
      return;
    }
    traveller.id = profileId;
  }

  @Mutation
  updateBasketDicountAppliedPopupVisible(value) {
    this.basketDicountAppliedPopupVisible = value;
  }

  @Mutation
  updateCurrentDiscountResponse(value) {
    this.currentDiscountResponse = value;
  }

  @Mutation
  setPendingActionErrorTripItemId(value) {
    this.pendingActionErrorTripItemId = value;
  }

  @Mutation
  setPendingActionErrorTripItem(value) {
    this.pendingActionErrorTripItem = value;
  }

  @Mutation
  setPendingActionCodeItem(value) {
    this.pendingActionCodeItem = value;
  }

  @Mutation
  setRailDiscountCodesLoadingErrors(values) {
    this.railDiscountCodesLoadingErrors = values;
  }

  @Mutation
  setRailDiscountCodesSaveErrors(values) {
    this.railDiscountCodesSaveErrors = values;
  }

  @Mutation
  setRailDiscountCodesLoading(value) {
    this.railDiscountCodesLoading = value;
  }

  @Mutation
  setRailDiscountCodes(values) {
    this.railDiscountCodes = values;
  }

  @Mutation
  setTravellersForwardDiscountCode({ code, travellerId }) {
    const codes = this.railDiscountCodes.find(item => item.id === travellerId);

    if (!codes) {
      return;
    }
    codes.forwardDiscountCode = code;
  }

  @Mutation
  setBasketApprovalDecision(value) {
    this.basketApprovalDecision = value;
  }

  @Mutation
  setBasketApprovalAction(value) {
    this.basketApprovalAction = value;
  }

  @Action
  setTravellersRailDiscountCodeObject({ codeObject, travellerId }) {
    const codesIndex = this.railDiscountCodes.findIndex(item => item.id === travellerId);

    if (-1 === codesIndex) {
      return;
    }
    let codes = JSON.parse(JSON.stringify(this.railDiscountCodes));
    codes.splice(codesIndex, 1, codeObject);
    this.setRailDiscountCodes(codes);
  }

  @Mutation
  setTravellersReturnDiscountCode({ code, travellerId }) {
    const codes = this.railDiscountCodes.find(item => item.id === travellerId);

    if (!codes) {
      return;
    }
    codes.returnDiscountCode = code;
  }

  @Mutation
  setRailDiscountCodesHaveErrors(value) {
    this.railDiscountCodesHaveErrors = value;
  }

  @Mutation
  setBasketPaymentUnsupported(value) {
    this.basketPaymentUnsupported = value;
  }

  @Mutation
  setBasketApproverError(value) {
    this.basketApproverError = value;
  }

  @Mutation
  setTravellersExistence(value) {
    this.travellersExistence = value;
  }

  @Mutation
  setHasSimilarTravellers(travellers) {
    if (travellers && travellers.length) {
      let existSimilar = travellers.find(traveller => traveller.hasSimilarTravellers === true);

      if (existSimilar) {
        this.hasSimilarTravellers = true;
      }
    }
  }

  @Mutation
  resetHasSimilarTravellers() {
    this.hasSimilarTravellers = false;
  }

  @Mutation
  setBasketEnterTravellersErrors(value) {
    this.basketEnterTravellersErrors = value;
  }

  @Mutation
  setChooseToSelectPayment(value) {
    this.chooseToSelectPayment = value;
  }

  @Mutation
  setLufthansaBookingDetail(value) {
    this.lufthansaOfferBooking = value;
  }

  @Mutation
  serSabreOfferBooking(value) {
    this.sabreOfferBooking = value;
  }

  @Mutation
  setAmadeusOfferBooking(value) {
    this.amadeusOfferBooking = value;
  }

  @Mutation
  setAddedCartInBasket(value) {
    this.addedCartInBasket = value;
  }

  @Mutation
  setPaymentForTripId(value) {
    if (this.paymentForTripId && this.paymentForTripId.length) {
      let index = this.paymentForTripId.map(trip => trip.tripItemId).indexOf(value.tripItemId);
      if (index !== -1) {
        this.paymentForTripId[index] = value;
      } else {
        this.paymentForTripId.push(value);
      }
    } else {
      this.paymentForTripId.push(value);
    }
  }

  @Mutation
  setTravelPolicyForBasketItem({ itemId, compliance }) {
    let item = this.basketItems.find(entry => {
      return itemId === entry.id;
    });
    if (item) {
      item.travelPolicy = compliance;
    }
  }

  @Mutation
  setLoadingExchangePopup(value) {
    this.loadingExchangePopup = value;
  }

  @Mutation
  setPreventShowingItems(value) {
    this.preventShowingItems = value;
  }

  @Mutation
  updatePricesForPaymentMethods(pricesResponse) {
    let items = pricesResponse;
    const itemsToCheck = [
      'agencyFee',
      'providerPrice',
      'agencyMarkup',
      'systemMarkup',
    ];
    const applyMethod = (newItem, method, field) => {
      if (newItem[field]) {
        method.price[field].amount = newItem[field].amount;
      }
    };
    items.forEach(newItem => {
      let method = this.basketPaymentMethods.find(entry => {
        return newItem.id === entry.id;
      });
      
      if (method) {
        if (method.price) {
          if (method.price.total.amount !== newItem.price.amount) {
            this.isChangingPriceOnPaymentMethod = true;
          }
          if (method.price.agencyFee && newItem.agencyFee) {
            method.price.agencyFee.amount = newItem.agencyFee.amount;
          }
          if (newItem.providerPrice) {
            method.price.providerPrice.amount = newItem.providerPrice.amount;
          }
          if (newItem.agencyMarkup) {
            method.price.agencyMarkup.amount = newItem.agencyMarkup.amount;
          }
          if (newItem.systemMarkup) {
            method.price.systemMarkup.amount = newItem.systemMarkup.amount;
          }
          itemsToCheck.forEach(field => {
            applyMethod(newItem, method, field);
          });
          method.price.total.amount = newItem.price.amount;
        }
        method.travelPolicy = newItem.travelPolicyCompliance;
        method.agencyFeePaymentInfo = newItem.agencyFeePaymentInfo;
      }
    });
  }

  @Mutation
  updatePriceForBasketItem({ item, newItem }) {
    let feeValue = 0;
    let agencyMarkupValue = 0;
    let systemMarkupValue = 0;
    let totalPrice = 0;

    if (item.price) {
      if (item.price.total.amount !== newItem.price.amount) {
        this.isChangingPriceOnPaymentMethod = true;
      }
      if (item.price.agencyFee && newItem.agencyFee) {
        feeValue = newItem.agencyFee.amount;
        item.price.agencyFee.amount = feeValue;
      }
      if (item.price.agencyMarkup && newItem.agencyMarkup) {
        agencyMarkupValue = newItem.agencyMarkup.amount;
        item.price.agencyMarkup.amount = agencyMarkupValue;
      }
      if (item.price.systemMarkup && newItem.systemMarkup) {
        systemMarkupValue = newItem.systemMarkup.amount;
        item.price.systemMarkup.amount = systemMarkupValue;
      }
      if (newItem.providerPrice) {
        if (item.price.providerPrice) {
          item.price.providerPrice.amount = newItem.providerPrice.amount;
        } else {
          item.price.providerPrice = {
            ...newItem.providerPrice,
          };
        }
      }
      if (newItem.price) {
        totalPrice = newItem.price.amount;
        item.price.total.amount = totalPrice;
      }
    }
    item.travelPolicy = newItem.travelPolicyCompliance;

    EventBus.$emit('offer-fee-edited', {
      offerId: item.id,
      feeValue,
      agencyMarkupValue,
      systemMarkupValue,
      totalPrice,
      travelPolicy: newItem.travelPolicyCompliance,
    });
  }

  @Mutation
  setBasketApproverData(value) {
    this.basketApproverData = value;
  }

  @Mutation
  setBasketBookerData(value) {
    this.basketBookerData = value;
  }

  @Mutation
  setShowRejectionReasonPopup(value) {
    this.showRejectionReasonPopup = value;
  }

  @Mutation
  setRejectionReason(value) {
    this.rejectionReason = value;
  }

  @Mutation
  setTripApprovalData(value) {
    this.tripApprovalData = value;
  }

  @Mutation
  setShowApprovalConfirmationPopup(value) {
    this.showApprovalConfirmationPopup = value;
  }

  @Mutation
  setSynchronizationCompleted(value) {
    this.synchronizationCompleted = value;
  }

  @Mutation
  setShowChangeApproverPopup(value) {
    this.showChangeApproverPopup = value;
  }

  @Mutation
  setShowEditTrainFeePopup(value) {
    this.showEditTrainFeePopup = value;
  }

  @Mutation
  setShowEditTrainAgencyMarkupPopup(value) {
    this.showEditTrainAgencyMarkupPopup = value;
  }

  @Mutation
  setShowEditHotelFeePopup(value) {
    this.showEditHotelFeePopup = value;
  }

  @Mutation
  setShowEditHotelAgencyMarkupPopup(value) {
    this.showEditHotelAgencyMarkupPopup = value;
  }

  @Mutation
  setEditedFeeItem(value) {
    this.editedFeeItem = value;
  }

  @Mutation
  setEditedFeeOffer(value) {
    this.editedFeeOffer = value;
  }

  @Mutation
  setCheckTravelConsent(value) {
    this.checkTravelConsent = value;
  }

  @Mutation
  setCheckModifyTravellerDetails(value) {
    this.checkModifyTravellerDetails = value;
  }

  @Mutation
  setAnswer(value) {
    this.errorAnswer = value;
  }


  @Action
  async loadRailDiscountCodes() {
    const item = this.bookingStepTripItem;

    if (!item) {
      return;
    }
    this.setRailDiscountCodesLoadingErrors([]);
    this.setRailDiscountCodesLoading(true);

    try {
      const response = await BasketItemApi.getRailDiscountCodes(item.providerReferenceId || '', item.supplier || '');
      const codes: RailDiscountCodesResponse = response.data;
      this.setRailDiscountCodes(codes.data);
    } catch (errors) {
      this.setRailDiscountCodesLoadingErrors(handleErrors(errors));
    } finally {
      this.setRailDiscountCodesLoading(false);
    }
  }

  @Action
  setAncillarySelected({ offer, value, profileId }) {
    this.setAncillarySelectedMutation({ offer, value, profileId });
    this.checkMaxAncillariesInGroups();
  }

  @Action
  checkMaxAncillariesInGroups() {
    (this.basketSelectedAncillariesGroups || [])
      .forEach(profileAndGroups => {
        profileAndGroups.groups
          .forEach(group => {
            group.flights
              .forEach(flight => {
                if (flight.ancillaries.length <= group.maxSelectedItemsPerFlight) {
                  return;
                }

                const howMany = flight.ancillaries.length - group.maxSelectedItemsPerFlight;
                const flightAncillaries = [...flight.ancillaries];
                for (let index = 0; index < howMany; index++) {
                  const offer = flightAncillaries[index];
                  
                  this.setAncillarySelectedMutation({
                    offer,
                    value: false,
                    profileId: profileAndGroups.profileId
                  });
                }
              });
          });
      });
  }

  @Action
  updatePricesForBasketItems(pricesResponse) {
    pricesResponse.forEach(newItem => {
      let item = this.basketItems.find((singleItem: any) => {
        return newItem.id === singleItem.id;
      });
      if (!item) {
        return;
      }

      this.updatePriceForBasketItem({ item, newItem });
    });
  }
  
  @Action
  updatePricesForBasketItemsAndPaymentMethods(pricesResponse) {
    this.updatePricesForBasketItems(pricesResponse);
    this.updatePricesForPaymentMethods(pricesResponse);
  }

  @Action
  async saveRailDiscountCodes() {
    if (!this.bookingStepTripItem) {
      return;
    }
    this.setBookingStepLoading(true);
    this.setRailDiscountCodesHaveErrors(false);
    this.setRailDiscountCodesSaveErrors([]);

    const item = this.bookingStepTripItem;

    try {
      const response = await BasketItemApi.saveRailDiscountCodes(
        item.providerReferenceId || '',
        item.supplier || '',
        this.railDiscountCodes
      );
      const codes: RailDiscountCodesResponse = response.data;
      this.setRailDiscountCodes(codes.data);

      this.setRailDiscountCodesHaveErrors(this.errorsPresentInDiscountCodes);

      if (!this.errorsPresentInDiscountCodes && codes.discount && codes.discount.amount > 0) {
        this.updateCurrentDiscountResponse(codes);
        this.setPriceChangesForStep({
          step: this.bookingStep,
          tripItemId: item.id,
          prices: [{
            ...codes.discount,
            amount: -codes.discount.amount,
          }],
          type: 'discounts'
        });

        this.updateBasketDicountAppliedPopupVisible(true);
        this.setRailDiscountCodes([]);
      }
      
    } catch (errors) {
      this.setRailDiscountCodesSaveErrors(handleErrors(errors, true));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async setSelectedCreditCardPaymentMethods(value) {
    this.setSelectedCreditCardPaymentMethodsMutation(value);
    const needSubmit = this.selectedPaymentMethods.filter(item => item.needSubmit);
    const submitedItems = this.selectedCreditCardPaymentMethods.filter(item => item.isValid);
    if (
      needSubmit.length === submitedItems.length ||
      this.bookingStepDef.tripItemId !== null
    ) {
      this.setStorePaymentErrors([]);
      await this.savePaymentCardsData();
      this.setBookingStepLoading(false);
      if (!this.storePaymentErrors.length) {
        const wasLastStep = this.isLastStep;
        this.nextStep();
        if (wasLastStep) {
          this.continue();
        }

        if (this.pendingActionCodeItem && -1 < [
          'TICKETING_FAILED',
          'BOOKING_ERROR',
        ].indexOf(this.pendingActionCodeItem)) {
          this.retryBookingItem();
        }

        if (this.errorAnswer && this.pendingActionErrorTripItem && this.pendingActionErrorTripItem === 'PAYMENT_ERROR') {
          this.sendTripItemError(this.errorAnswer);
        }
      }
    } else {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  findExchangeBasketItemId(id) {
    if (this.basketItems) {
      const basketItem = this.basketItems.find(e => {
         return e.type === 'Air' && e.providerReferenceId === id || false;
      });

      if (basketItem) {
        this.setBasketItemId(basketItem.id);
      }
    }
  }

  @Action
  openRefundPopup({id, conditions}) {
    this.setRefundedItem({
      id,
      type: '',
      conditions,
    });

    this.setShowRefundDataPopup(true);
  }

  @Action
  async saveBasketName() {
    this.setBasketTitleErrors([]);
    try {
      const { data: result } = await BasketApi.saveTripName(this.basketId, this.basketTitle);

      if (result) {
        this.setBasketTitle(result.name);
      }
      this.setBasketOriginalValue(this.basketTitle);
    } catch (error) {
      this.setBasketTitleErrors(handleErrors(error, true));
      if (this.basket) {
        this.setBasketTitle(this.basket.name);
      }
    }
  }

  @Action
  async loadPreBookingData() {
    const { data: preBookingData } = await BasketApi.getPreBookingData(this.basketId);
    this.setPreBookingData(preBookingData);

    if (preBookingData.isApprovalEnabled) {
      if (preBookingData.isApprovalPossible) {
        this.setFinalBookOption('approval');
      } else if (preBookingData.isHoldPossible && preBookingData.isHoldEnabled) {
        this.setFinalBookOption('hold');
      } else {
        this.setFinalBookOption(null);
      }
    } else {
      if (preBookingData.isHoldPossible && preBookingData.isHoldEnabled) {
        this.setFinalBookOption('hold');
      } else {
        this.setFinalBookOption('book');
      }
    }
  }

  @Action
  async initBasket(basketId: string) {
    if (this.basketId !== basketId) {
      this.clearBasket();
    }
    this.setInitialising(true);
    this.setBasketId(basketId);
    this.setErrors([]);
    AirSearchStore.setCustomError([]);

    this.setFinalBookOption(null);
    try {
      const [
        { data: basket },
        { data: status },
      ] = await Promise.all([
        BasketApi.getBasket(this.basketId),
        BasketApi.getBasketStatus(this.basketId),
      ]);

      if (
        status &&
        !status.isInProgress &&
        status.status !== 'Draft' &&
        hasAccessFn('CanViewTripComments')
      ) {
        setTimeout(this.loadComments);
      }

      const bookerData = basket.booker ? {
        id: basket.booker.bookerId,
        firstName: basket.booker.bookerFirstName,
        middleName: basket.booker.bookerMiddleName,
        lastName: basket.booker.bookerLastName,
        companyName: basket.booker.businessUnitName,
      } : null;
      this.setBasketBookerData(bookerData);

      SearchStore.updateTravellersDefaultState(basket);
      SearchStore.updateEditedTravellers(basket);
      SearchStore.updateSkipTravellers(
        basket.travellers.some((traveller: any) => {
          return !!traveller.isVirtual;
        })
      );

      this.setBasket(basket);
      this.setBasketStatus(status);
      this.setBasketTitle(basket.name);
      SearchStore.setMissionId(basket.missionId);
      if (basket.missionId !== null) {
        await SearchStore.getMissionDetails();
      }

      if (this.status && this.status.isInProgress) {
        this.setErrors([]);
        this.setBookingStep(0);
        this.setProcessing(true);
        clearTimeout(this.processBasketID);
        this.setProcessBasketID(setTimeout(
          this.processBasket,
          BASKET_REFRESH_INTERVAL,
        ));
      } else {
        this.setErrors([]);
        this.loadBasketItems();
        await this.loadBasketApproverData();
        if (status.pendingActions && 0 < status.pendingActions.length 
          && !!status.pendingActions.find(pendingAction => pendingAction.code === 'START_BOOKING_WIZARD') && AccountStore.HasPermission('BookTrip')) {

          await this.getWizardSteps();

        }
      }

      if ( status.pendingActions &&
        0 < status.pendingActions.length &&
        status.pendingActions.find(action => !![
          'APPROVAL_WORKFLOW_ACTION',
          'HOLD',
        ].find(item => item === action.code))
      ) {
        await this.loadPreBookingData();
        await this.loadBasketApproverData();
      }

      this.setInitialising(false);

    } catch (error) {
      this.setErrors(handleErrors(error));
    }
  }

  @Action
  async loadTravellersDocuments() {
    this.setLoadingBasketTravellersDocuments(true);

    await this.updateBasket();

    try {
      const documentsResult = await BasketApi.getBasketTravellerDocuments(this.basketId);

      if (documentsResult && documentsResult.data) {
        this.setBasketTravellersDocuments(documentsResult.data);
      }
    } catch (error) {
      this.setLoadingBasketTravellersDocumentsErrors(handleErrors(error, true));
    } finally {
      this.setLoadingBasketTravellersDocuments(false);
    }
  }

  @Action
  async updateBasket() {
    const { data: basket } = await BasketApi.getBasket(this.basketId);
    if (basket) {
      SearchStore.updateTravellersDefaultState({
        travellers: basket.travellers
      });
      SearchStore.updateEditedTravellers({
        travellers: basket.travellers
      });
    }
    this.setBasket(basket);
  }

  @Action
  async loadComments() {
    this.setCommentsLoading(true);
    try {
      const response = await TripApi.getTripComments(this.basketId);
      this.setComments(response.data);
    } catch (error) {
      this.setCommentsErrors(handleErrors(error));
      this.setComments([]);
    } finally {
      this.setCommentsLoading(false);
    }
  }

  @Action
  async processBasket() {
    this.setProcessing(true);
    const wasPending = this.status ? this.status.isInProgress : false;

    try {
      const { data: status } = await BasketApi.getBasketStatus(this.basketId);
      let shouldStop = false;
      this.setBasketStatus(status);

      let refreshRequired = status && status.pendingActions && !!status.pendingActions.find(pendingAction => pendingAction.code === 'REFRESH_REQUIRED');

      if (status && (status.isInProgress || refreshRequired)) {
        // checking status errors
        if (refreshRequired) {
          let error: any[] = [];
          let processError = status.pendingActions.find(pendingAction => pendingAction.code === 'REFRESH_REQUIRED');
          let errorMessageCode = 'common-error.camunda-error';
          if (processError && processError.additionalData && processError.additionalData.statusCode) {
              errorMessageCode = `${errorMessageCode}-${processError.additionalData.statusCode}`;
          }
          error.push({ message: translate(errorMessageCode) + 
            translate(
              errorMessages.serviceTagPrefix) +
              processError!.additionalData.serviceTag +
              errorMessages.serviceTagSufix
          });
          this.setErrors(error);
          this.loadBasketItems();
          this.setPreventShowingItems(true);
          shouldStop = true;
          this.setShowRefreshButton(true);
          setTimeout(() => {
            LayoutStore.toggleAside(true);
          });          
        }

        if (status.pendingActions && status.pendingActions.length && !!status.pendingActions.find(pendingAction => pendingAction.code === 'WORKFLOW_ERROR')) {
          let error: any[] = [];
          let processError = status.pendingActions.find(pendingAction => pendingAction.code === 'WORKFLOW_ERROR');
          error.push({
            errorMessage: translate('common-error.camunda-workflow-error'),
            serviceTag: processError!.additionalData.serviceTag,
            expanded: true,
          });
          this.setWorkflowError(true);
          this.setProcessingErrors(error);
          this.loadBasketItems();
          this.setPreventShowingItems(true);
          shouldStop = true;
          this.setShowRefreshButton(true);
          setTimeout(() => {
            LayoutStore.toggleAside(true);
          });
        }

        if (status.pendingActions && status.pendingActions.length && !!status.pendingActions.find(pendingAction => pendingAction.code === 'REDIRECT_TO_URL_PAYMENT_GATEWAY')) {
          let urlTR = settings.apiTrip + '/api/payment-gateways-order/create-order-and-redirect?tripId=' + this.basketId;

          window.open(urlTR, '_self');
        }

        // checking items status & errors
        if (status.items.length) {
          this.setPendingActionCodeItem('');
          await asyncForEach(status.items, async statusItem => {
            let anyPendingAction = statusItem.pendingActions && statusItem.pendingActions.length > 0;
            let pendingAction = anyPendingAction ? statusItem.pendingActions[0] : null;
            let newBasketItem: any | null = null;
            let isAvailableResponses = pendingAction && pendingAction.availableResponses && pendingAction.availableResponses.length > 0;
            const isNonStopingAction = pendingAction && -1 < [
              'SABRE_UNCONFIRMED_STATUS',
              'LHG_UNCONFIRMED_STATUS',
              'AMADEUS_UNCONFIRMED_STATUS',
            ].indexOf(pendingAction.code);
            const isNonStopingActionFirstTime = isNonStopingAction && !this.basketItems
              .find(item => item.id === statusItem.id);

            if (anyPendingAction && !isNonStopingAction) {
              shouldStop = true;
            }

            if (
              anyPendingAction &&
              (!isNonStopingAction || !!isNonStopingActionFirstTime) &&
              !isAvailableResponses &&
              pendingAction.code !== 'REDIRECT_TO_URL'
            ) {
              const { data } = await BasketItemApi.getFlatBasketItem(statusItem.id);

              newBasketItem = data;
              this.setBasketItem({ id: statusItem.id, data });
            }

            if (pendingAction && !isAvailableResponses) {
              if (pendingAction.code === 'PRICE_CHANGED') {
                this.setLastBasketItemId(statusItem.id);
                this.setLastBasketItemProviderReferenceId(newBasketItem.providerReferenceId);

                const item = this.basketItemsMapped
                  .find(i => i.id === statusItem.id);

                if (item && item.type === 'Car') {
                  this.setShowCarPriceChangePopup(true);
                } else {
                  this.setShowPriceChangePopup(true);
                }
                return false;
              }

              if (pendingAction.code === 'REDIRECT_TO_URL') {
                const { data } = await BasketItemApi.getTripItemPaymentInfo2RedirectUrl(statusItem.id, pendingAction.additionalData.paymentMethodId);

                if (data && data.isUsed) {
                  this.setShowing3DSInterruptedPopup(true);
                } else if (data && !data.isUsed && data.redirectUrl !== null) {
                  window.open(data.redirectUrl, '_self');
                }
                return false;
              }

              if (
                -1 < [
                  'TICKETING_FAILED',
                  'BOOKING_ERROR',
                ].indexOf(pendingAction.code)
              ) {
                this.setLastBasketItemId(statusItem.id);
                this.setPendingActionCodeItem(pendingAction.code);
                this.setShowRetrySection(true);
                setTimeout(() => {
                  LayoutStore.toggleAside(true);
                });
                this.setSkip(false);
                this.setRetry(false);
                this.setRetryButtonDisabled(false);
                return false;
              }

              if (pendingAction.code === 'CANCEL_ERROR') {
                this.setLastBasketItemId(statusItem.id);
                this.setPendingActionErrorTripItemId(statusItem.id);
                this.setPendingActionErrorTripItem(pendingAction.code);
                this.setShowRetrySection(true);
                this.setIsRetryCancel(true);
                setTimeout(() => {
                  LayoutStore.toggleAside(true);
                });
                this.setSkip(false);
                this.setRetry(false);
                this.setRetryButtonDisabled(false);
                return false;
              }

              if (pendingAction.code === 'EXTRAS_PROVIDER_ERROR') {
                this.setLastBasketItemId(statusItem.id);
                this.setPendingActionErrorTripItemId(statusItem.id);
                this.setPendingActionErrorTripItem(pendingAction.code);
                this.setShowRetrySection(true);
                setTimeout(() => {
                  LayoutStore.toggleAside(true);
                });

                this.setSkip(false);
                this.setRetry(false);
                this.setRetryButtonDisabled(false);

                return false;
              }

              if (
                -1 < [
                  'BOOK_TRIP_ITEM_ERROR',
                  'BOOK_ITEM_ERROR',
                  'PAYMENT_ERROR',
                ].indexOf(pendingAction.code)
              ) {
                this.setLastBasketItemId(statusItem.id);
                this.setPendingActionErrorTripItemId(statusItem.id);
                this.setPendingActionErrorTripItem(pendingAction.code);
                this.setShowRetrySection(true);
                this.setSkip(false);
                this.setRetry(false);
                this.setRetryButtonDisabled(false);

                setTimeout(() => {
                  LayoutStore.toggleAside(true);
                });
                return false;
              }

              if (pendingAction.code === 'LHG_UNCONFIRMED_STATUS') {
                this.setLastBasketItemId(statusItem.id);
                this.setProcessing(true);
                this.setLufthansaStatusConfirmationSection(true);
                return false;
              }

              if (pendingAction.code === 'SABRE_UNCONFIRMED_STATUS') {
                this.setLastBasketItemId(statusItem.id);
                this.setProcessing(true);
                this.setSabreStatusConfirmationSection(true);
                return false;
              }

              if (pendingAction.code === 'AMADEUS_UNCONFIRMED_STATUS') {
                this.setLastBasketItemId(statusItem.id);
                this.setProcessing(true);
                this.setAmadeusStatusConfirmationSection(true);
                return false;
              }

              if (pendingAction.code  && -1 < [
                RefundActionStatuses.VerifyRefund,
                RefundActionStatuses.VerifyStatus,
                RefundActionStatuses.VerifyError,
                RefundActionStatuses.VerifyConditions,
                RefundActionStatuses.VerifyScreen,
                RefundActionStatuses.LHGCheckConditions,
              ].indexOf(pendingAction.code as RefundActionStatuses)) {
                if (pendingAction.code  === RefundActionStatuses.VerifyScreen) {
                  BasketCancellationStore.getSabreCurrentRefundProcess(statusItem.id);
                }
                this.setRefundedItem({
                  id: statusItem.id,
                  type: pendingAction,
                });
                this.setShowRefundDataPopup(true);

                return false;
              }

              if (pendingAction.code === 'EXP_SETUP_3DS') {
                this.setShowBasketExpedia3DSPopup(true);
                this.setStartBasketExpedia3DS(true);
                this.setBasketItemPendingActionCode(pendingAction.code);
                this.setExpedia3DSTripItemId(statusItem.id);
                if (pendingAction.additionalData && pendingAction.additionalData.IFrameSource) {
                  this.setExpedia3DSUrl(pendingAction.additionalData.IFrameSource);
                }
                if (pendingAction.additionalData && pendingAction.additionalData.IFrameAttributes === 'sandbox') {
                  this.setExpedia3DSSandbox('allow-scripts allow-forms allow-same-origin');
                } else {
                  this.setExpedia3DSSandbox(undefined);
                }
              }

              if (pendingAction.code === 'AIR_PROVIDER_SYNCHRONIZATION_SUCCESS') {
                this.setShowSyncAirBookingSuccessPopup(true);
              }

              if (pendingAction.code === 'AIR_PROVIDER_SYNCHRONIZATION_ERROR') {
                this.setShowSyncAirBookingErrorPopup(true);
              }
              
            } else if (pendingAction && pendingAction.code !== 'EXP_INIT_PAYMENT_SESSION' && pendingAction.code !== 'EXP_CHALLENGE' && isAvailableResponses) {
              this.setIsAvailableResponsesSections(true);
              this.setLastBasketItemId(statusItem.id);
              this.setProcessing(true);
              
              return false;

            } else if (pendingAction && pendingAction.code === 'EXP_INIT_PAYMENT_SESSION' && isAvailableResponses) {
              if (!this.expedia3DSTripItemId) {
                this.setShowBasketExpedia3DSPopup(true);
                this.setStartBasketExpedia3DS(false);
                this.setBasketItemPendingActionCode(pendingAction.code);
                this.setExpedia3DSTripItemId(statusItem.id);

              } else {
                this.setBasketItemPendingActionCode(pendingAction.code);
                this.setExpedia3DSTripItemId(statusItem.id);
                EventBus.$emit('3DS-init-payment-session');
              }
              
            } else if (pendingAction && pendingAction.code === 'EXP_CHALLENGE' && isAvailableResponses) {
              if (!this.expedia3DSTripItemId) {
                this.setShowBasketExpedia3DSPopup(true);
                this.setStartBasketExpedia3DS(false);
                this.setBasketItemPendingActionCode(pendingAction.code);
                this.setExpedia3DSTripItemId(statusItem.id);

              } else {
                this.setBasketItemPendingActionCode(pendingAction.code);
                this.setExpedia3DSTripItemId(statusItem.id);
                EventBus.$emit('3DS-challenge');
              }
            }
          });
        } else {
          shouldStop = true;
        }


        if (!shouldStop) {
          this.setBasketStatus(status);

          clearTimeout(this.processBasketID);
          this.setProcessBasketID(setTimeout(
            this.processBasket,
            BASKET_REFRESH_INTERVAL,
          ));
        }
      } else if (
        status &&
        !status.isInProgress &&
        status.status !== 'Draft' &&
        hasAccessFn('CanViewTripComments')
      ) {
        setTimeout(this.loadComments);
      }
      
      if (status && (!status.isInProgress || shouldStop)) {
        this.setBasketStatus(status);

        if ( status.pendingActions &&
          0 < status.pendingActions.length &&
          status.pendingActions.find(action => !![
            'APPROVAL_WORKFLOW_ACTION',
            'HOLD',
          ].find(item => item === action.code))
        ) {
          await this.loadPreBookingData();
        }

        if (!status.isInProgress && wasPending) {
          this.setShowRefundDataPopup(false);
        }

        if (this.booking) {
          this.setBooking(false);
        } else if (this.cancelling) {
          this.setCancelling(false);
        }

        if (!status.isInProgress) {
          this.setShowRetrySection(false);
          this.setSkip(false);
          this.setRetry(false);
          this.setRetryButtonDisabled(false);
          await this.updateBasket();
          this.loadBasketItems();
          await this.loadBasketApproverData();

          if (
            status.pendingActions && status.pendingActions.length &&
            !!status.pendingActions.find(pendingAction => pendingAction.code === 'START_BOOKING_WIZARD') && AccountStore.HasPermission('BookTrip')
          ) {
            await this.getWizardSteps();
          }
        }
        this.setBookingStep(0);
        this.setProcessing(false);
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    }
  }

  @Action
  async loadBasketItems(setLoading = true) {
    if (setLoading !== false) {
      this.setLoadingItems(true);
    }
    try {
      const response = await BasketApi.getTripItems(this.basketId);

      this.setBasketItems(response.data);

      await this.getLatestBasketData();

      if (hasAccessFn('ReadEventNotification')) {
        const { data: notifications } = await TripApi.getNotificationsCount(this.basketId);
        this.setBasketNotificationsCount(notifications);
      }

      if (this.synchronizationCompleted) {
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          message: translate('basket.synchronization-completed')
        });
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    }
    this.setLoadingItems(false);
  }

  @Action
  postponeProcess() {
    clearTimeout(this.processBasketID);
    this.setProcessBasketID(setTimeout(
      this.processBasket,
      BASKET_REFRESH_INTERVAL,
    ));
  }

  @Action
  destroyRefreshBasket() {
    clearTimeout(this.processBasketID);
    this.setProcessBasketID(null);
  }

  @Action
  async confirmTrip() {
    this.setErrors([]);
    this.setShouldShowUnconfirmedNotifications(false);
    try {
      await BasketApi.confirmHeldBasket(this.basketId);

      this.setBooking(true);
      this.setProcessing(true);
      this.postponeProcess();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'UNCONFIRMED_NOTIFICATION'
      ) {
        this.setShouldShowUnconfirmedNotifications(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    }
  }

  @Action
  async cancelTrip() {
    this.setErrors([]);
    this.setShouldShowUnconfirmedNotifications(false);
    try {
      await BasketApi.cancelBasket(this.basketId);

      this.setCancelling(true);
      this.setProcessing(true);
      this.postponeProcess();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'UNCONFIRMED_NOTIFICATION'
      ) {
        this.setShouldShowUnconfirmedNotifications(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    }
  }

  @Action
  async storeRailSncfSeatPreferences() {
    this.setErrors([]);
    try {
      this.setBookingStepLoading(true);
      let item = this.basketItems.find(e => e.id === this.bookingStepDef.tripItemId);
      if (item && item.providerReferenceId) {
        this.setPriceChangesForStep({
          tripItemId: this.bookingStepDef.tripItemId,
          step: this.bookingStep,
          type: 'seats',
          prices: this.basketRailSelectedSeats
            .filter(item => item.price)
            .map(item => item.price),
        });
  
        this.resetSelectedRailSeats();
        await BasketItemApi.updateRailSncfSeatPreferences(item.providerReferenceId, item.supplier, this.basketRailSncfSeatPreferences.map(pref => {
          let result: any = {
            segmentId: pref.segmentId
          };
          if (pref.selectedPreferenceType.value === 'Specified') {
            result.closeToPreferences = {
              coachNumber: pref.coachNumber,
              seatNumber: pref.seatNumber,
            };
            return result;
          } else if (pref.selectedPreferenceType.value === 'SeatMap') {
            result.seatMapPreferences = {
              assignments: pref.seatSelections.map(seat => {
                const {
                  coachNumber,
                  profileId,
                  seatNumber,
                  price,
                } = seat;
                if (seatNumber !== undefined) { // WMI-21760 in case of traveller without seat such passanger is removed
                  const passengerRank = this.travellersState.travellers.findIndex(traveller => traveller.id === profileId) + 1;
                  return {
                    coachNumber: '' + coachNumber,
                    passengerRank,
                    seatNumber: '' + seatNumber,
                    price,
                  };
                }
                else {
                  return null;
                }
              }).filter(Boolean),
            };
            return result;
          } else if (pref.selectedPreferenceType.value === 'Assignment') {
            result.assignmentsPreferences = {
              assignments: pref.selectedPreferences.map(selection => {
                if (selection.value.code) {
                  return {
                    category: selection.name,
                    assignmentCode: selection.value.code
                  };
                } else {
                  return null;
                }
              }).filter(Boolean)
            };
            return result;
          } else {
            return null;
          }
        }).filter(Boolean));
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async storeBenerailSeatPreferences() {
    this.setBookingStepLoading(true);
    try {
      const basketItem = this.basketItems.find(e => e.id === this.bookingStepDef.tripItemId);
      let options: UpdateRailBenerailOption[] = [];
      this.basketRailBenerailSeatPreferences.forEach((item) => {
        item.options.forEach((option) => {
          options = options.concat({
            legNumber: item.legNumber,
            segmentId: item.segmentId,
            offerPartId: option.offerPartId,
            isIncluded: option.isIncluded,
            preferences: option.preferences ? option.preferences.reduce((prev, { value }) => {
              if (value) {
                return [...prev, value];
              }
              return prev;
            }, [] as string[]) : option.preferences,
          });
        });
      });
      if (basketItem && basketItem.providerReferenceId) {
        await BasketItemApi.updateRailBenerailSeatPreferences(basketItem.providerReferenceId, basketItem.supplier, { options });
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async storeRailSeats() {
    this.setBookingStepLoading(true);

    try {
      await BasketApi.setRailSeats(this.bookingStepDef.tripItemId, {
        seatSelections: this.basketRailSelectedSeats.map(seat => {
          let alternateNumber = undefined;

          if (seat.alternateNumber) {
            alternateNumber = seat.alternateNumber;
          }
          return {
            alternateNumber,
            profileId: seat.profileId,
            segmentId: seat.segmentId,
            coachNumber: seat.coachNumber,
            seatNumber: seat.seatNumber,
          };
        }),
      });

      this.setPriceChangesForStep({
        tripItemId: this.bookingStepDef.tripItemId,
        step: this.bookingStep,
        type: 'seats',
        prices: this.basketRailSelectedSeats
          .filter(item => item.price)
          .map(item => item.price),
      });

      this.resetSelectedRailSeats();
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async storeSeats() {
    if (!this.canContinueOnSeatsStep || !this.bookingStepTripItem || this.bookingStepTripItem.type !== 'Air') {
      return;
    }
    
    this.setErrors([]);
    
    this.setBookingStepLoading(true);
    
    let flights: any[] = [];
    this.selectedSeats.seats.forEach(seat => {
      flights.push({
        flightId: seat.flightId,
        profileSeatSelections: [{
          profileId: seat.travellerId,
          seatRow: seat.row,
          seatColumn: seat.column,
          offerItemId: seat.offerItemId
        }]
      });
    });

    this.setSeatSelection(this.selectedSeats);

    try {
      await BasketApi.setSeats(this.bookingStepDef.tripItemId, {
        seatSelections: flights,
      });
      this.setSeatSelectionForItem(this.selectedSeats);

      this.setPriceChangesForStep({
        tripItemId: this.bookingStepDef.tripItemId,
        step: this.bookingStep,
        type: 'seats',
        prices: (this.selectedSeats.seats as any[])
          .filter(item => item.price)
          .map(item => item.price),
      });

      this.resetSelectedSeats();

      // 'Review TP' step must be loaded after 'Seat selection' only when it is the last step.
      // Seat selection is the last step for 'Adding extras' to AirFranceKlm offer.
      // Otherwise, 'Review TP' step is loaded after 'Select payment methods' step.
      // See 'savePaymentCardsData' method
      if (this.hasReviewTPCompliance && 
          this.bookingExtras &&
          this.bookingStepTripItem &&
          this.bookingStepTripItem.supplier === 'AirFranceKlm'
      ) {
        await this.loadReviewTravelPolicyComplianceForTripItem(this.bookingStepDef.tripItemId);
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async processStoreSeats(tripItemId) {
    this.setErrors([]);
    this.setBasketProcessingStoreSeats(true);
    let flights: any[] = [];
    this.selectedSeats.seats.forEach(seat => {
      flights.push({
        flightId: seat.flightId,
        profileSeatSelections: [{
          profileId: seat.travellerId,
          seatRow: seat.row,
          seatColumn: seat.column,
          offerItemId: seat.offerItemId
        }]
      });
    });

    this.setSeatSelection(this.selectedSeats);
    try {
      await BasketApi.setSeats(tripItemId, {
        seatSelections: flights,
      });
      this.setSeatSelectionForItem(this.selectedSeats);
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBasketProcessingStoreSeats(false);
    }
  }

  @Action
  async getWizardSteps(addNewOrBeforeSelectedSteps: boolean = false) {
    const { data: steps } = await BasketApi.retrieveWizardSteps(this.basketId);
    if (!addNewOrBeforeSelectedSteps) {
      this.setWizardSteps(steps.map(item => {
        return {
          ...item,
          selected: true,
        };
      }));
    } else {
      let mappedSteps = steps.map(item => {
        const existing = this.wizardSteps
          .find(step => { return item.code === step.code && item.tripItemId === step.tripItemId; });
        let selected = false;

        if (existing) {
          selected = existing.selected;
        }

        return {
          ...item,
          selected,
        };
      });
      this.setWizardSteps(mappedSteps);
    }
  }

  @Action
  async reloadWizardSteps() {
    let firstSkippedMandatoryStepCode = '';
    const { data: steps } = await BasketApi.retrieveWizardSteps(this.basketId);

    let mappedSteps = steps.map(item => {
      const existing = this.wizardSteps
        .find(step => { return item.code === step.code && item.tripItemId === step.tripItemId; });

      if (!firstSkippedMandatoryStepCode && item.isMandatory &&
        (!existing || (existing && (!existing.selected || !existing.isMandatory)))) {
        firstSkippedMandatoryStepCode = item.code;
      }

      let selected = false;
      if (existing) {
        selected = existing.selected || item.isMandatory;
      }

      return {
        ...item,
        selected,
      };
    });

    this.setWizardSteps(mappedSteps);

    if (this.bookingStep > 0 && firstSkippedMandatoryStepCode) {
      let newIndex = this.wizardSteps.findIndex(s => s.code === firstSkippedMandatoryStepCode);
      if (newIndex > -1 && newIndex < this.bookingStep) {
        this.goBackToStep(newIndex);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('basket.warning-tooltip'),
          message: translate('basket.payment-changed-wizard-reloaded-step-moved-back')
        });
      }
    }
  }

  @Action
  updateTravellersIdsInWizardSteps() {
    this.wizardSteps[1].travellerIds = this.guestBasketTravellers.map(traveller => traveller.id);
  }

  @Action
  async continue() {
    if (this.bookingStepLoading) {
      return;
    }

    this.cancelAndNullifyToken();

    const currentStep = this.bookingStep > 0 && this.wizardSteps.length > this.bookingStep - 1 ?
      this.wizardSteps[this.bookingStep - 1] : null;
    const actionsByConditions = [
      {
        condition: this.bookingStep === 0,
        action: this.resetStepsAndStartWizard,
      },
      {
        condition: currentStep && currentStep.code !== 'DO_TRIP',
        action: this.doRegularStep,
      },
      {
        condition: currentStep && currentStep.code === 'DO_TRIP',
        action: this.doTripStep,
      },
    ];

    const actionItem = actionsByConditions.find(item => item.condition === true);
    
    if (actionItem) {
      const shouldContinue = await actionItem.action();
      if (!shouldContinue) {
        return;
      }
    }

    this.postTicketingStep();
  }

  @Action
  async resetStepsAndStartWizard() {
    this.setBookingStepLoading(true);
    try {
      await BasketApi.resetWizardSteps(this.basketId);
    } catch (error) {
      // nothing to do
    }
    this.nextStep();
    this.setBookingStepLoading(false);

    return true;
  }

  @Action
  async saveTravelfusionParameters() {
    this.setBookingStepLoading(true);
    this.setValidCustomParameters(true);
    EventBus.$emit('custom-parameter-form');
    if (!this.validCustomParameters) {
      this.setBookingStepLoading(false);
      return false;
    }
    
    this.setConfirmingFlag(true);
    this.setValidCustomParameters(true);
    this.setAncillariesAndPassengerDataErrors([]);

    let saveResponse;
    try {
      saveResponse = await this.saveBasketCustomParameters();
    } catch (error) {
      this.setAncillariesAndPassengerDataErrors(handleErrors(error, true));
      this.setBooking(false);
      this.setBookingStepLoading(false);
      return false;
    }
    if (saveResponse && saveResponse.status !== 204) {
      this.setBooking(false);
      this.setBookingStepLoading(false);
      return false;
    }

    this.setPriceChangesForStep({
      tripItemId: this.bookingStepDef.tripItemId,
      step: this.bookingStep,
      type: 'ancillaries',
      prices: (this.selectedAncillaries)
        .filter(entry => !entry.isAlreadyBooked)
        .map(item => item.totalPrice),
    });
    this.resetSelectedAncillaries();
    this.setBookingStepLoading(false);

    return true;
  }

  @Action
  async savePaymentMethod() {
    this.storePaymentForm(null);
    return false;
  }

  @Action
  async saveAirSeats() {
    if (!this.canContinueOnSeatsStep) {
      this.setBasketSeatsMapError(true);
      return false;
    }
    this.setBasketSeatsMapError(false);
    await this.storeSeats();
    if (this.errors.length) {
      return false;
    }

    return true;
  }

  @Action
  async saveAirAncillaries() {
    if (
      this.maxOneAncillaryPerSegment &&
      ((this.minimumAncillaryCount &&
      this.minimumAncillaryCount === 1 &&
      this.hasExactOneAncillaryError) ||
      this.hasMaxOneAncillaryError)
    ) {
      this.setShowMaxOneAncillaryError(true);
      return false;
    }
    this.setShowMaxOneAncillaryError(false);
    let ancillariesSelections: any[] = [];

    if (
      this.bookingStepTripItem && this.bookingStepTripItem.type === 'Air' &&
      (!this.basketAncillariesMessages || this.basketAncillariesMessages.length === 0) &&
      !this.ancillariesLoadingFailed
    ) {
      this.setBookingStepLoading(true);
      ancillariesSelections = this.selectedAncillaries
        .filter((item: any) => {
          return !item.isAlreadyBooked;
        })
        .map(item => {
          return {
            profileId: item.profileId,
            ancillaryOfferId: item.id,
            quantity: 1,
          };
        });

      try {
        await BasketApi.setAncillaries(this.bookingStepDef.tripItemId, {
          ancillarySelections: ancillariesSelections,
        });

        this.setPriceChangesForStep({
          tripItemId: this.bookingStepDef.tripItemId,
          step: this.bookingStep,
          type: 'ancillaries',
          prices: (this.selectedAncillaries)
            .filter(entry => !entry.isAlreadyBooked)
            .map(item => item.totalPrice),
        });

        this.resetSelectedAncillaries();
        this.setBookingStepLoading(false);
      } catch (error) {
        this.setBookingStepLoading(false);
        this.setErrors(handleErrors(error));
        return false;
      }
    }

    return true;
  }

  @Action
  async saveTrenitaliaVouchers() {
    await this.saveRailDiscountCodes();
    if (this.railDiscountCodesSaveErrors.length || this.railDiscountCodesHaveErrors) {
      return false;
    }

    return true;
  }

  @Action
  async saveCarExtras() {
    if (this.bookingStepTripItem && this.bookingStepTripItem.type === 'Car') {
      const requestExtras = this.selectedExtras.map(e => {
        if (e && e.isSelected) {
          return e.code;
        }
      }).filter(Boolean);
      if (!this.skipExtrasSelection) {
        try {
          await BasketItemApi.setCarTripItemExtras(this.bookingStepDef.tripItemId, requestExtras);
          this.setPriceChangesForStep({
            tripItemId: this.bookingStepDef.tripItemId,
            step: this.bookingStep,
            type: 'extras',
            prices: (this.selectedExtras)
              .map(item => {
                return {
                  ...item,
                  amount: item.subTotalPrice.amount,
                };
              }),
          });

          this.resetSelectedExtras();
        } catch (error) {
          this.setBookingStepLoading(false);
          this.setErrors(handleErrors(error));
          return false;
        }
      }
    }

    return true;
  }

  @Action
  async saveTravellersChoice() {
    this.setGuestBasketTravellersChecked(false);
    this.setBookingStepLoading(true);
    EventBus.$emit('validate-guests');

    if (this.guestBasketTravellersError || !this.guestBasketTravellersChecked) {
      this.setBookingStepLoading(false);
      return false;
    }
    SearchStore.updateSkipTravellers(true);
    EventBus.$emit('save-guests');
    return false;
  }

  @Action
  async performSaveTravellerData() {
    let guestsExistingProfiles = this.guestBasketTravellersData.filter(item => {
      return !item.oldId;
    });

    const currentStep = this.bookingStepDef;

    let existingTravellersMessage = {
      tripId: currentStep.tripId,
      travellerInfo: guestsExistingProfiles.map(item => {
        if (!item.isClean) {
          return { 
            ...item.request,
            title: item.request.title && item.request.title.value ? item.request.title.value : '',
          };
        } else {
          return false;
        }
      }).filter(Boolean)
    };

    try {
      if (existingTravellersMessage.travellerInfo.length) {
        await BasketApi.saveTravellers(existingTravellersMessage);
      }
      if (this.isFoidRequired) {
        const documents = this.selectedDocumentsAsFoid
          .map(item => {
            return {
              travellerId: item.profileId,
              type: item.document.type,
              number: item.document.number,
            };
          });
        this.basketItems
          .filter(item => item.requiresFoid)
          .forEach(async item => {
            await OffersApi.saveDocumentsAsFoid(
              item.providerReferenceId,
              documents,
            );
          });
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
      return false;
    } finally {
      this.setGuestsSaving(false);
      this.setGuestsProfileSaving(false);
    }

    return true;
  }

  @Action
  async saveTravellersData() {
    if (this.guestBasketTravellersDataLoading || this.guestsProfileSaving) {
      return false;
    }
    this.setShouldShowApisRulesValidationErrors(false);
    this.setHasMissingApisDocuments(false);
    this.resetGuestBasketTravellerData();
    EventBus.$emit('assign-travellers-data');
    this.setShowSelectDocumentsAsFoidError(this.isFoidRequired && !this.areFoidDocumentsFilled);
    if (
      this.guestBasketTravellersDataError ||
      this.customerCodeSectionError ||
      this.hasMissingApisDocuments ||
      (this.isFoidRequired && !this.areFoidDocumentsFilled)
    ) {
      if (
        !this.guestBasketTravellersDataError &&
        !this.customerCodeSectionError
      ) {
        this.clearFoidErrorsProfileIdsAndIndices();
        EventBus.$emit('report-foid-errors');
        const topMostIndexItem = this.foidErrorsProfileIdsAndIndices
          .reduce((prev, cur) => {
            if (cur.index < prev.index) {
              return cur;
            }
            return prev;
          }, {
            index: this.basketTravellers.length,
          });
        const profileId = topMostIndexItem.profileId;

        if (!!profileId) {
          EventBus.$emit('scroll-to-foid-error', profileId);
        }
      }
      this.setShouldShowApisRulesValidationErrors(true);
      this.setBookingStepLoading(false);
      return false;
    }

    this.setErrors([]);
    this.setGuestsSaving(true);
    this.setGuestsProfileSaving(true);

    const result = await this.performSaveTravellerData();

    return result;
  }

  @Action
  async saveCustomFields() {
    this.setCustomFieldsResponded(false);
    this.clearCustomFieldsErrors();
    EventBus.$emit('assign-custom-fields');

    if (!this.customFieldsResponded || this.customFieldsLoading || this.customFieldsErrors.length) {
      this.setBookingStepLoading(false);
      return false;
    }

    try {
      await BasketApi.saveBookingCustomFields(this.basketId, this.customFields.map(field => {
        return {
          code: field.customField.code,
          value: field.value,
          valueCode: field.valueCode,
          travellerId: field.travellerId,
        };
      }));
    } catch (error) {
      this.setErrors(handleErrors(error));
      this.setBookingStepLoading(false);
      return false;
    }

    return true;
  }

  @Action
  async saveLoyaltyPrograms() {
    await this.storeLoyaltyPrograms();
    if (this.errors.length) {
      return false;
    }

    return true;
  }

  @Action
  async saveNtvTrenitaliaSeats() {
    await this.storeRailSeats();
    if (this.errors.length) {
      return false;
    }

    return true;
  }

  @Action
  async saveSncfSeats() {
    EventBus.$emit('validate-sncf-seats');
    if (this.basketRailSncfSeatPreferencesError) {
      this.setBookingStepLoading(false);
      return false;
    } else {
      await this.storeRailSncfSeatPreferences();
      if (this.errors.length) {
        return false;
      }
    }

    return true;
  }

  @Action
  async saveBenerailSeats() {
    EventBus.$emit('validate-benerail-seats');
    if (this.basketRailBenerailSeatPreferencesError) {
      this.setBookingStepLoading(false);
      return false;
    }
    await this.storeBenerailSeatPreferences();
    return !this.errors.length;
  }

  @Action
  async saveSncfAncillaries() {
    let ancillariesRailSelections: any[] = [];

    if (
      this.bookingStepTripItem && this.bookingStepTripItem.type === 'Rail' &&
      (!this.basketAncillariesMessages || this.basketAncillariesMessages.length === 0) &&
      !this.ancillariesLoadingFailed
    ) {
      this.setBookingStepLoading(true);
      ancillariesRailSelections = this.selectedRailAncillaries
        .map(item => {
          return {
            travellerId: item.profileId,
            ancillaryCode: item.product.code,
            segmentId: item.segmentId,
          };
        });

      try {

        let item = this.basketItems.find(e => e.id === this.bookingStepDef.tripItemId);
        if (item && item.providerReferenceId) {
          await TrainOffersApi.updateRailAncillariesPreferences(item.providerReferenceId, item.supplier, ancillariesRailSelections);

          this.setPriceChangesForStep({
            tripItemId: this.bookingStepDef.tripItemId,
            step: this.bookingStep,
            type: 'railAncillaries',
            prices: (this.selectedRailAncillaries)
              .map(a => a.price),
          });
        }

        this.resetSelectedRailAncillaries();
        this.setBookingStepLoading(false);
      } catch (error) {
        this.setBookingStepLoading(false);
        this.setErrors(handleErrors(error));
        return false;
      }
    }

    return true;
  }

  @Action
  async saveTravellersDetails() {
    this.storeModifyTravellersDetails();
    return false;
  }

  @Action
  async saveReviewTPCompliance() {
    if (
      this.loadingReviewTravelPolicyCompliance ||
      this.loadingReviewTravelPolicyComplianceFailed ||
      !this.travelPolicyCompliance ||
      !this.travelPolicyCompliance.isNotBlockedByTravelPolicyChanges
    ) {
      return false;
    }

    if (this.bookingStepDef.tripItemId) {
      await this.loadApprovalDataAfterExtras();

      if (this.loadingApprovalDataAfterExtrasFailed) {
        return false;
      }
    }

    return true;
  }

  @Action
  async saveSelectedApprover() {
    if (!this.canShowSelectApproverStep) {
      return true;
    }
    if (
      this.loadingApprovalDataAfterExtras ||
      this.loadingApprovalDataAfterExtrasFailed
    ) {
      return false;
    }

    if (
      this.showApprovalOptions &&
      this.hasMissingSelectedApprover &&
      this.basketHasErrors
    ) {
      this.setDisplayApproverEmpty(true);
      return false;
    }
    if (this.showApprovalOptions && !this.showQuickBook) {
      this.setDisplayApproverEmpty(true);
    }

    await this.setApproversForBasket(this.selectedApprovers);

    return true;
  }

  @Action
  async doRegularStep() {
    const currentStep = this.bookingStepDef;
    const definitionMatching = this.wizardStepsDefinitions
      .find(item => item.condition(currentStep.code));

    if (definitionMatching) {
      const shouldContinue = await definitionMatching.action.call(this);
      if (!shouldContinue) {
        return false;
      }
    }

    this.nextStep();

    return true;
  }

  @Action
  async saveComment() {
    this.setBasketCommentsAddErrors([]);
    if (!this.basketCommentToSave) {
      return;
    }
    try {
      await TripApi.addTripComment(this.basketCommentToSave);
    } catch (error) {
      this.setBasketCommentOriginalAddError(error);
      this.setBasketCommentsAddErrors(handleErrors(error));
    }
  }

  @Action
  async removeTripComment(id) {
    this.setBasketCommentsRemoveErrors([]);
    try {
      await TripApi.deleteTripComment(id);
    } catch (error) {
      this.setBasketCommentsRemoveErrors(handleErrors(error));
    }
  }

  @Action
  async doTripStep() {
    if (this.processing) {
      return false;
    }

    const isObligatoryConfirmationEmpty = this.canShowObligatoryConfirmation && !this.checkTravelConsent;
    const isApproverEmpty = this.showApprovalOptions && this.hasMissingSelectedApprover && this.basketHasErrors;
    if (isApproverEmpty || isObligatoryConfirmationEmpty) {
      this.setDisplayObligatoryConfirmationEmpty(isObligatoryConfirmationEmpty);
      this.setDisplayApproverEmpty(isApproverEmpty);
      return false;
    }

    if (this.showApprovalOptions && !this.showQuickBook) {
      this.setDisplayApproverEmpty(true);
    }

    try {
      if (
        this.showApprovalOptions &&
        !this.hasMissingSelectedApprover
      ) {
        await this.setApproversForBasket(this.selectedApprovers);
      }

      // if there some errors do not continue
      if (this.errors.length) {
        return false;
      }

      if (hasAccessFn('CanAddTripComments')) {
        EventBus.$emit('basket-comment-validate');
        if (this.basketHasCommentToSave) {
          if (-1 === ['approval', 'book'].indexOf(this.finalBookOption || '')) {
            this.setCommentFlowToSave(TripCommentFlow.Holding);
          }
          this.saveComment();
          if (this.basketCommentsAddErrors.length) {
            throw this.basketCommentOriginalAddError;
          }
        }
      }

      this.setProcessing(true);
      this.setConfirmingFlag(true);
      this.setBooking(true);

      if (-1 < ['approval', 'book'].indexOf(this.finalBookOption || '')) {
        await BasketApi.confirmBasket(this.basketId);
      } else { // hold
        await BasketApi.holdBasket(this.basketId);
      }
      this.setBookingStep(0);

      this.postponeProcess();
      this.resetPriceChanges();
      this.setBasketItems([]);
    } catch (error) {
      if (error.response && error.response.data.error.code === 'TRIP_EXPIRED') {
        this.setProcessing(false);
        this.setBooking(false);
        this.setConfirmingFlag(false);
        this.setLoadingItems(false);
        this.setCancelling(false);
        setTimeout(() => {
          router.push({
            name: 'basket-expired',
            params: router.currentRoute.params,
          });
        });
        this.setBookingStep(0);
        return false;
      }
      this.setErrors(handleErrors(error));

      this.setShowRefreshButton(true);
      setTimeout(() => {
        LayoutStore.toggleAside(true);
      });
    }

    return true;
  }

  @Action
  async postTicketingStep() {
    // post ticketing --> step > lastStep
    if (this.bookingStep <= this.wizardSteps.length || this.processing) {
      return;
    }

    this.setProcessing(true);
    this.setConfirmingFlag(true);
    this.setBooking(true);

    try {

      if (!this.isChangingPaymentMethod && !this.isModifyingTravellersDetails) {
        await BasketApi.bookExtras(this.wizardSteps[this.wizardSteps.length - 1].tripItemId);
      } else {
        this.setIsChangingPaymentMethod(false);
      }
      
      this.setBookingExtras(false);

      this.setBookingStep(0);

      this.setWizardSteps([]);
      this.setPostTicketingExtrasBooking(false);

      this.postponeProcess();
      this.resetPriceChanges();
    } catch (error) {
      this.setErrors(handleErrors(error));
    }
  }

  @Action
  cancelAndNullifyToken() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
  }

  @Action
  async saveGuestsNextStep() {
    this.normalizeGuestBasketTravellers();
    this.setBookingStepLoading(false);

    this.nextStep();
  }

  @Action
  nextStep() {
    let newBookingStep = this.bookingStep + 1;

    if (newBookingStep - 1 < this.wizardSteps.length) {
      const stepsToSkipConditionaly = [
        {
          condition: (code) => code === 'REVIEW_TP_COMPLIANCE' &&
            !this.canShowTravelPolicyComplianceStep
        },
        {
          condition: (code) => code === 'SELECT_APPROVER' &&
            !this.canShowSelectApproverStep
        },
      ];

      while (
        this.wizardSteps[newBookingStep - 1] && (
          !this.wizardSteps[newBookingStep - 1].selected ||
          stepsToSkipConditionaly.find(item => item.condition(this.wizardSteps[newBookingStep - 1].code))
        )
      ) {
        newBookingStep++;
      }
    }
    this.setBookingStep(newBookingStep);
    LayoutStore.scrollUp();
  }

  @Action
  prevStep() {
    let newBookingStep = this.bookingStep - 1;
    const stepsToSkipConditionaly = [
      {
        condition: (code) => code === 'REVIEW_TP_COMPLIANCE' &&
          !this.canShowTravelPolicyComplianceStep
      },
      {
        condition: (code) => code === 'SELECT_APPROVER' &&
          !this.canShowSelectApproverStep
      },
    ];

    while (
      newBookingStep > 0 &&
      (
        !this.wizardSteps[newBookingStep - 1].selected ||
        stepsToSkipConditionaly.find(item => item.condition(this.wizardSteps[newBookingStep - 1].code))
      )
    ) {
      newBookingStep--;
    }

    this.setCustomerCodeSectionLoadingError(false);
    
    if (newBookingStep === 0 && this.postTicketingExtrasBooking) {
      this.setWizardSteps([]);
      this.setPostTicketingExtrasBooking(false);
    }
    this.setBookingStep(newBookingStep);
    LayoutStore.scrollUp();
  }

  @Action
  goBackToStep(index) {
    if (!this.wizardSteps[index].selected) {
      return;
    }

    if (this.isModifyingTravellersDetails) {
      this.setIsModifyingTravellersDetails(false);
    }

    this.setCustomerCodeSectionLoadingError(false);
    this.resetTemporaryData();
    this.resetPriceChangesForSteps({ from: index + 1, to: this.bookingStep });

    this.cancelAndNullifyToken();
    this.setErrors([]);
    this.setCheckTravelConsent(false);
    this.setCheckModifyTravellerDetails(false);
    this.setGuestBasketTravellersError(false);
    this.setCustomerCodeSectionError(false);
    this.setGuestBasketTravellersDataError(false);
    this.setBookingStep(index + 1);
    LayoutStore.scrollUp();
  }

  @Action
  goBack() {
    if (this.isModifyingTravellersDetails) {
      this.setIsModifyingTravellersDetails(false);
    }
    if (this.bookingStep === 1) {
      this.setBookingExtras(false);
    }
    if (this.hasSimilarTravellers && this.travellersExistence && this.travellersExistence.length && this.bookingStep === 1) {
      this.setTravellersExistence([]);
      this.resetHasSimilarTravellers();
      this.setSelectedExistingTraveller([]);
      return;
    }
    this.cancelAndNullifyToken();
    this.setIsChangingPaymentMethod(false);
    this.resetIsChangingPriceOnPaymentMethod();
    this.setErrors([]);
    this.setCheckTravelConsent(false);
    this.setDisplayObligatoryConfirmationEmpty(false);
    this.setDisplayApproverEmpty(false);
    this.setCheckModifyTravellerDetails(false);
    this.setGuestBasketTravellersError(false);
    this.setGuestBasketTravellersDataError(false);
    this.setCustomerCodeSectionError(false);
    this.setGuestBasketTravellersDataLoading(true);
    this.prevStep();
  }

  @Action
  async loadBasketSeats(itemId: string) {
    this.cancelAndNullifyToken();
    this.setBasketSeatsMapError(false);
    this.setLoadingSeatsMap(true);
    this.setErrors([]);
    this.setBusinessErrors([]);
    this.setSeatsLoadingFailed(false);
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      this.updateCancelToken(cancelTokenSource);
      const { data: items } = await BasketApi.getSeatsMap(itemId, cancelTokenSource);
      this.setBasketSeatsMap(items);
    } catch (error) {
      if (!isCancelError(error)) {
        this.setSeatsLoadingFailed(true);
        if (error.response && error.response.status >= 400 && error.response.status < 500) {
          const item = this.basketItemsMapped.find(item => {
            return item.id === itemId;
          });
          if (error.response.data.error.code === 'NO_SEAT_AVAILABILITY' && item!.supplier === 'AirFranceKlm') {
            this.setBusinessErrors([]);
          } else {
            this.setBusinessErrors([error.response.data.error]);
          }
          
          this.setBasketSeatsMap([]);
        } else {
          this.setErrors(handleErrors(error));
        }
      }
    }
    this.setLoadingSeatsMap(false);
  }

  @Action
  setSelectedRailSeats(segments) {
    this.resetSelectedRailSeats();
    segments.forEach(segment => {
      const segmentId = segment.segmentId;

      segment.assignedSeats
        .filter(seat => seat.coachNumber !== null && seat.seatNumber !== null)
        .forEach(seat => {
          const coachNumber = seat.coachNumber;
          const seatNumber = seat.seatNumber;
          const profileId = seat.travellerId;
          const coachFound = segment.coaches.find(coach => coach.number === coachNumber) || null;

          if (!coachFound) {
            return;
          }

          const seatDefFound = coachFound.seatMapItems.find(seatDef => seatDef.seatNumber === seatNumber) || null;

          if (!seatDefFound) {
            return;
          }

          const alternateNumber = seatDefFound.alternateNumber;

          let price = null;
          if (seatDefFound.prices !== null) {
            const priceElement = seatDefFound.prices.find(p => p.travellerId === profileId) || null;

            if (priceElement) {
              price = priceElement.price;
            }
          }

          this.setSelectedRailSeat({
            segmentId,
            coachNumber,
            seatNumber,
            alternateNumber,
            profileId,
            price,
          });
        });
    });
  }

  @Action
  async loadRailSeatsMap(itemId: string) {
    this.setBasketRailLoading(true);
    this.setRailSeatsLoadingFailed(false);
    this.setAvailableTravellersForSeats([]);
    try {
      const response = await BasketApi.getRailSeatMap(itemId);
      this.setBasketRailSeatsMap(response.data);
      this.setAvailableTravellersForSeats(response.data);
      this.setSelectedRailSeats(response.data);
    } catch (error) {
      this.setRailSeatsLoadingFailed(true);
      this.setBasketRailSeatsErrors(handleErrors(error));
    } finally {
      this.setBasketRailLoading(false);
    }
  }

  @Action
  selectAlreadyBookedAncillaries(ancillariesList) {
    let ancillariesToSave: any[] = [];
    if (!ancillariesList || !ancillariesList.length) {
      return;
    }
    ancillariesList.forEach(ancillary => {
      let bookedOffers = ancillary.ancillaryOffers.filter(item => {
        return item.isAlreadyBooked;
      }).map(entry => {
        return {
          profileId: ancillary.profileId,
          offer: entry,
          value: entry.isAlreadyBooked,
        };
      });
      if (bookedOffers.length) {
        ancillariesToSave.push(...bookedOffers);
      }
    });
    ancillariesToSave.forEach(itemToSave => {
      this.setAncillarySelected(itemToSave);
    });
  }

  @Action
  async loadBasketAncillaries(itemId: string) {
    this.cancelAndNullifyToken();
    this.setLoadingAncillaries(true);
    this.setBasketAncillaries([]);
    this.setErrors([]);
    this.setBusinessErrors([]);
    this.setAncillariesLoadingFailed(false);
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      this.updateCancelToken(cancelTokenSource);
      const { data } = await BasketApi.getAncillaries(itemId, cancelTokenSource);

      this.setBasketAncillaries(data.passengerAncillaries);

      data.passengerAncillaries.forEach(traveller => {
        traveller.ancillaryOffers.forEach(ancillary => {
          if (ancillary.isMandatory) {
            this.setAncillarySelected({
              offer: ancillary,
              value: true,
              profileId: traveller.profileId,
            });
          }
        });
      });
      this.selectAlreadyBookedAncillaries(data.passengerAncillaries);
      this.setBasketAncillariesFlights(data.flights);
      this.setBasketAncillariesMessages(data.messages);
      this.setMaxOneAncillaryPerSegment(data.maxOneAncillaryPerSegment);
      this.setMinimumAncillaryCount(data.minimumAncillaryCount);
    } catch (error) {
      if (!isCancelError(error)) {
        this.setAncillariesLoadingFailed(true);
        if (error.response && error.response.status >= 400 && error.response.status < 500) {
          this.setBusinessErrors([error.response.data.error]);
          this.setBasketAncillaries([]);
          this.setMaxOneAncillaryPerSegment(false);
          this.setMinimumAncillaryCount(null);
          this.setBasketAncillariesFlights([]);
          this.setBasketAncillariesMessages(null);
        } else {
          this.setErrors(handleErrors(error));
        }
      }
    }
    this.setLoadingAncillaries(false);
  }

  @Action
  async loadReviewTravelPolicyCompliance() {
    this.setLoadingReviewTravelPolicyCompliance(true);
    this.setLoadingReviewTravelPolicyComplianceFailed(false);
    this.setErrors([]);

    try {
      const { data } = await BasketApi.getTravelPolicyCompliance(this.basketId);
      this.setTravelPolicyCompliance(data);

      this.setCanShowTravelPolicyComplianceStep(data.isAnyTravelPolicyChanged || !data.isNotBlockedByTravelPolicyChanges);
      if (!data.isAnyTravelPolicyChanged && data.isNotBlockedByTravelPolicyChanges) {
        setTimeout(() => {
          this.continue();
        });
      }
    } catch (error) {
      this.setLoadingReviewTravelPolicyComplianceFailed(true);
      this.setErrors(handleErrors(error));
      this.setCanShowTravelPolicyComplianceStep(true);
    }
    this.setLoadingReviewTravelPolicyCompliance(false);
  }

  @Action
  async loadReviewTravelPolicyComplianceForTripItem(tripItemId) {
    this.setLoadingReviewTravelPolicyCompliance(true);
    this.setLoadingReviewTravelPolicyComplianceFailed(false);
    this.setErrors([]);

    try {
      const { data } = await BasketApi.getTravelPolicyComplianceForTripItem(this.basketId, tripItemId);
      this.setTravelPolicyCompliance(data);

      this.setCanShowTravelPolicyComplianceStep(data.isAnyTravelPolicyChanged || !data.isNotBlockedByTravelPolicyChanges);
      if (!data.isAnyTravelPolicyChanged && data.isNotBlockedByTravelPolicyChanges) {
        await this.loadApprovalDataAfterExtras();
        setTimeout(() => {
          this.continue();
        });
      }
    } catch (error) {
      this.setLoadingReviewTravelPolicyComplianceFailed(true);
      this.setErrors(handleErrors(error));
      this.setCanShowTravelPolicyComplianceStep(true);
    }
    this.setLoadingReviewTravelPolicyCompliance(false);
  }

  @Action
  async loadApprovalDataAfterExtras() {
    let hasNoSelectApproverStep = true;
    this.setLoadingApprovalDataAfterExtras(true);
    this.setLoadingApprovalDataAfterExtrasFailed(false);
    this.setErrors([]);

    try {
      const { data } = await BasketApi.getApprovalAndAccessData(this.basketId);

      this.setSelectApproverData(data);
      this.setApprovalWorkflowForBasket(data.tripApprovalWorkflowResult);
      if (data.isApprovalPossible && data.isApprovalEnabled) {
        this.setCanShowSelectApproverStep(true);
        hasNoSelectApproverStep = false;
      }

    } catch (error) {
      this.setLoadingApprovalDataAfterExtrasFailed(true);
    }
    this.setLoadingApprovalDataAfterExtras(false);

    return hasNoSelectApproverStep;
  }

  @Action
  async loadPaymentMethods() {
    this.setLoadingPaymentMethods(true);
    this.setLoadingPaymentMethodsFailed(false);
    this.setErrors([]);
    try {
      const { data } = await TripApi.getPaymentMethods(this.basketId);
      if (this.basketItemsMapped && this.basketItemsMapped.length) {
        const itemsWithPaymentMethods = this.basketItemsMapped.map(item => {
          return {
            ...item,
            paymentMethods: {
              ...data.find(payment => item.id === payment.tripItemId),
            },
          };
        });

        this.setPaymentMethods(itemsWithPaymentMethods);
      }
    } catch (error) {
      this.setLoadingPaymentMethodsFailed(true);
      this.setErrors(handleErrors(error));
    }
    this.setLoadingPaymentMethods(false);
  }

  @Action
  async loadItemsFeePaymentInfo() {
    await Promise.all(this.basketPaymentMethods.map(async (method) => {
      if (!method.selectedPaymentCard) {
        return method;
      }
      const response = await BasketItemApi.getTripItemFeePaymentInfo(method.id, method.selectedPaymentCard.paymentMethod);
      if (response && response.data) {
        return {
          ...method,
          agencyFeePaymentInfo: response.data
        };
      }
    })).then((result) => { this.setPaymentMethods(result); });
  }

  @Action
  nextPaymentAction(answer) {
    const wasLastStep = this.isLastStep;
    this.nextStep();

    if (wasLastStep) {
      this.continue();
    }

    if (this.pendingActionCodeItem && -1 < [
      'TICKETING_FAILED',
      'BOOKING_ERROR',
    ].indexOf(this.pendingActionCodeItem)) {
      this.retryBookingItem();
    }

    if (answer && this.pendingActionErrorTripItem && this.pendingActionErrorTripItem === 'PAYMENT_ERROR') {
      this.sendTripItemError(answer);
    }
  }

  @Action
  async submitPayment(answer) {
    const needSubmit = this.selectedPaymentMethods.filter(item => item.needSubmit);
    if (needSubmit.length) {
      needSubmit.forEach (item => {
        EventBus.$emit('paymentSubmit', item.tripItemId);
      });
    } else {
      this.setStorePaymentErrors([]);
      await this.savePaymentCardsData();
      this.setBookingStepLoading(false);
      if (!this.storePaymentErrors.length) {
        this.nextPaymentAction(answer);
      }
    }
  }

  @Action
  async storePaymentForm(answer) {
    this.resetSelectedCreditCardPaymentMethods();
    this.setBookingStepLoading(true);
    this.setPaymentFormResponded(false);
    EventBus.$emit('paymentValidation');
    if (this.paymentBasketHasErrors || !this.paymentFormResponded || this.basketPaymentUnsupported) {
      if (this.paymentBasketHasErrors) {
        EventBus.$emit('paymentValidationErrors', true);
      }
      this.setBookingStepLoading(false);
      return;
    }
    EventBus.$emit('paymentValidationErrors', false);
    this.setAddedCartInBasket(false);

    if (this.selectedPaymentMethods.length) {
      this.submitPayment(answer);
    }
  }

  @Action
  setValidationErrorAfterSubmit() {
    if (this.paymentBasketHasErrors) {
      EventBus.$emit('paymentValidationErrors', true);
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async canApprove() {
    try {
      const data = await BasketApi.canApprove(this.basketId);
      this.setCanApproveTrip(data.data.canApprove);
    } catch (error) {
      this.setCanApproveTrip(false);
    }
  }

  @Action
  public async loadItemCustomParameters(itemId: string): Promise<OfferParameter[]> {
    this.cancelAndNullifyToken();
    let currentStore = this;
    this.clearBasketParameters();
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    this.updateCancelToken(cancelTokenSource);
    this.setAncillariesAndPassengerDataLoadingFailed(false);
    this.setAncillariesAndPassengerDataErrors([]);
    try {
      let response = await BasketItemApi.getItemCustomParameters(itemId, cancelTokenSource);
      if (response && response.data) {
        let parameters: OfferParameter[] = [];

        response.data.forEach(p => {
          p.itemId = itemId;

          this.formatItemCustomParameterValue(p);

          if (p.isPerTraveler && !p.profileId && currentStore.basket) {
            currentStore.basket.travellers.forEach(traveller => {
              let par: OfferParameter = {
                ...p,
                profileId: traveller.id
              };
              parameters.push(par);
            });
          } else {
            parameters.push(p);
          }
        });

        this.updateItemCustomParameters(parameters);
        return parameters;
      }
    } catch (error) {
      this.setAncillariesAndPassengerDataErrors(handleErrors(error, true));
      this.setAncillariesAndPassengerDataLoadingFailed(true);
    }

    return [];
  }

  @Action
  async saveBasketCustomParameters(): Promise<any> {
    let parametersToSave = this.basketParameters.map(p => {
      let format  = p.momentJsDateFormat ? p.momentJsDateFormat : 'DD/MM/YYYY';

      let formatedValue;
      if (p.isDateFormat) {
        formatedValue = p.value ? moment(p.value).format(format) : null;
      } else {
        formatedValue = p.value;
      }

      let basketParameter = new BasketParameter();
      basketParameter.itemId = p.itemId;
      basketParameter.key = p.key;
      basketParameter.value = formatedValue;
      basketParameter.profileId = p.profileId;
      return basketParameter;
    });

    let basketId = router.currentRoute.params.id;
    return BasketItemApi.saveBasketCustomParameters(basketId, parametersToSave);
  }

  @Action
  async registerAsExpense(params) {
    this.setErrors([]);
    try {
      const result = await ExpenseApi.registerAsExpense(params);
      if (result && result.data) {
        this.setExpenseIdForBasket(result.data.expenseId);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  async saveConnectionTripExpense(data) {
    this.setErrors([]);
    try {
      await BasketItemApi.saveConnectionTripExpense(data.itemId, data.expenseId);
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  async getApprovalWorkflowForBasket(basketId) {
    this.resetSelectedApprovers();
    this.setApprovalWorkflowForBasket(null);
    this.setErrors([]);
    try {
      this.setBookingStepLoading(true);
      const result = await TripApi.getApprovalWorkflowForBasket({basketId});
      if (result && result.data) {
        result.data.workflowLevels
          .sort((a, b) => a.approvalLevel - b.approvalLevel);
        result.data.workflowLevels
          .forEach(level => {
            if (level.currentApprover) {
              this.setSelectedApprover({
                level: level.approvalLevel,
                approver: level.currentApprover,
              });
            } else if (
              level.approverSelectionType === 'BookerSelectsApprover' &&
              level.approverSourceType === 'ApproversFromList' &&
              level.approvalWorkflowResult !== 'NotRequired' &&
              level.allowedApprovers.length === 1
            ) {
              level.currentApprover = level.allowedApprovers[0];
              this.setSelectedApprover({
                level: level.approvalLevel,
                approver: level.allowedApprovers[0],
              });
            }
          });
        this.setApprovalWorkflowForBasket(result.data);
        this.setBasketApproverError(false);
      }
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async rejectApproval() {
    const basketId = this.basketId;
  
    this.setErrors([]);
    this.setShouldShowUnconfirmedNotifications(false);
    try {
      await TripApi.rejectApproval(basketId, this.rejectionReason);

      this.setProcessing(true);
      this.postponeProcess();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'UNCONFIRMED_NOTIFICATION'
      ) {
        this.setShouldShowUnconfirmedNotifications(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    }
  }

  @Action
  async acceptApproval() {
    const basketId = this.basketId;

    this.setErrors([]);
    this.setShouldShowUnconfirmedNotifications(false);
    try {
      await TripApi.acceptApproval(basketId);

      this.setProcessing(true);
      this.postponeProcess();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'UNCONFIRMED_NOTIFICATION'
      ) {
        this.setShouldShowUnconfirmedNotifications(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    }
  }

  @Action
  async changeApprover(params) {
    const basketId = this.basketId;

    this.setErrors([]);
    try {
      this.setProcessing(true);
      let response = await TripApi.changeApprover(basketId, params.approverId, params.sendEmail);
      if (response && response.status === 204) {
        this.postponeProcess();
      }

    } catch (error) {
      this.setErrors(handleErrors(error, true));
      this.setProcessing(false);
    }
  }

  @Action
  async setApproversForBasket(approvers) {
    const basketId = this.basketId;
  
    this.setErrors([]);
    try {
      const result = await TripApi.setApproversForBasket(
        basketId,
        approvers.map(a => {
          return {
            approverId: a.approver.id,
            approvalLevel: a.level,
          };
        }),
      );
      if (result && result.data) {
        this.setApprovalWorkflowForBasket(result.data);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  retrievePaymentMethodId() {
    if (!this.status) {
      return null;
    }

    let paymentMethodId = null;
    const tripItem = this.status.items
      .find(item => item.pendingActions && !!item.pendingActions.find(el => {
        return el.additionalData && el.additionalData.paymentMethodId;
      }));
    if (tripItem && tripItem.pendingActions) {
      const pendingAction = tripItem.pendingActions.find(action => {
        return action.additionalData && action.additionalData.paymentMethodId;
      });
      if (pendingAction) {
        paymentMethodId = pendingAction.additionalData.paymentMethodId;
      }
    }

    return paymentMethodId;
  }

  @Action
  prepareSavePaymentCardsRequest(paymentMethodId) {
    let paymentCardsNumber = 0;
    let cards: any[] = [];
    let request = {
      basketItemsPaymentCards: {}
    } as SelectBasketPaymentCardsMessage;

    if (this.bookingStepDef.tripItemId && this.bookingStepDef.tripItemId !== null) {
      const itemFound = this.basketItems.find(item => item.id === this.bookingStepDef.tripItemId);

      if (itemFound && itemFound.selectedPaymentCard) {
        paymentCardsNumber++;
        const element = {
          paymentMethodId,
          paymentMethod: itemFound.selectedPaymentCard.paymentMethod,
          cardId: itemFound.selectedPaymentCard.paymentCardId,
          cardTypeOverride: itemFound.selectedPaymentCard.type,
          saveTypeOverrideIntoCardDefinition: itemFound.selectedPaymentCard.updateCardTypeInProfile,
          transactionId: itemFound.selectedPaymentCard.transactionId
        } as SelectBasketPaymentCardMessage;
        cards.push(element);
        request.basketItemsPaymentCards[itemFound.id] = element;
      }
    } else {
      this.basketItems.forEach(item => {
        if (item.selectedPaymentCard && !item.selectedPaymentCard.hasErrors) {
          paymentCardsNumber++;
          const element = {
            paymentMethodId,
            paymentMethod: item.selectedPaymentCard.paymentMethod,
            cardId: item.selectedPaymentCard.paymentCardId,
            cardTypeOverride: item.selectedPaymentCard.type,
            saveTypeOverrideIntoCardDefinition: item.selectedPaymentCard.updateCardTypeInProfile,
            transactionId: item.selectedPaymentCard.transactionId
          } as SelectBasketPaymentCardMessage;
          cards.push(element);
          request.basketItemsPaymentCards[item.id] = element;
        }
      });
    }

    return {
      paymentCardsNumber,
      cards,
      request,
    };
  }

  @Action
  async loadHotelAssignmentOptions(itemId) {
    this.setHotelAssignmentOptions(null);
    this.setLoadingHotelAssignmentOptions(true);
    this.setLoadingHotelAssignmentErrors([]);

    try {
      // todo

      // this.setHotelAssignmentOptions(...);

      // mock
      this.setHotelAssignmentOptions([{
        name: 'Double',
        maxPax: 2,
      }, {
        name: 'Twin',
        maxPax: 2,
      }].map(item => {
        return {
          ...item,
          slots: [{
            profileId: null,
          }],
        };
      }));
    } catch (error) {
      this.setLoadingHotelAssignmentErrors(handleErrors(error));
    } finally {
      this.setLoadingHotelAssignmentOptions(false);
    }
  }

  @Action
  async savePaymentCardsData() {
    this.setErrors([]);

    if (!this.basketItems || !this.status || this.basketItems.length === 0) {
      return;
    }

    const paymentMethodId = await this.retrievePaymentMethodId();

    const {
      paymentCardsNumber,
      cards,
      request,
    } = await this.prepareSavePaymentCardsRequest(paymentMethodId);

    if (!paymentCardsNumber) {
      return;
    }
    try {
      let basketId = router.currentRoute.params.id;

      if (this.isChangingPaymentMethod) {
        await BasketApi.changeBasketPaymentMethod(basketId, this.bookingStepDef.tripItemId, {
          paymentMethod: cards[0].paymentMethod,
          cardId: cards[0].cardId,
          cardTypeOverride: cards[0].cardTypeOverride,
          transactionId: cards[0].transactionId,
          recalculateFee: true,
        });
      } else {
        await BasketApi.setBasketPaymentCards(basketId, request);

        if (this.hasReviewTPCompliance) {
          if (this.bookingStepDef.tripItemId) {
            await this.loadReviewTravelPolicyComplianceForTripItem(this.bookingStepDef.tripItemId);
          } else {
            await this.loadReviewTravelPolicyCompliance();
          }
        }
      }
    } catch (error) {
      this.setStorePaymentErrors(handleErrors(error, true));
    }
  }

  @Action
  async removeBasketItem(itemId) {
    this.setErrors([]);
    try {
      this.setProcessing(true);

      await BasketApi.deleteItemFromBasket(
        router.currentRoute.params.id,
        itemId,
      );
      if (this.basketItemsMapped && this.basketItemsMapped.length === 1) {
        await this.getLatestBasketData();

        SearchStore.updateSkipTravellers(false);
        AccountStore.setChooseTravellerMode(false);
        SearchStore.resetTravellerSearchState();
        SearchStore.selectBasket('');
        router.push({ name: 'home' });
        return;
      }

      const { data: basket } = await BasketApi.getBasket(this.basketId);
      this.setBasketTitle(basket.name);
      await this.getLatestBasketData();

      clearTimeout(this.processBasketID);
      this.processBasket();
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  async rejectPrice() {
    if (this.sendingAnswer) {
      return;
    }
    this.setSendingAnswer(true);
    try {
      const basketId = router.currentRoute.params.id;
      const itemId = this.lastBasketItemId;

      this.setProcessing(true);

      await BasketApi.rejectPrice(basketId, itemId);

      this.setShowPriceChangePopup(false);
      this.setShowCarPriceChangePopup(false);
      clearTimeout(this.processBasketID);
      this.processBasket();
    } catch (error) {
      if (error && error.response && error.response.data.error.code === 'TRIP_ACTION_ALREADY_COMPLETED') {
        this.setShowPriceChangePopup(false);
        this.setShowCarPriceChangePopup(false);
        this.setShowTripActionErrorPopup(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    } finally {
      this.setSendingAnswer(false);
    }
  }

  @Action
  async acceptPrice() {
    if (this.sendingAnswer) {
      return;
    }
    this.setSendingAnswer(true);
    try {
      const basketId = router.currentRoute.params.id;
      const itemId = this.lastBasketItemId;
      
      this.setProcessing(true);

      await BasketApi.confirmPrice(basketId, itemId);

      this.setShowPriceChangePopup(false);
      this.setShowCarPriceChangePopup(false);
      clearTimeout(this.processBasketID);
      this.processBasket();
    } catch (error) {
      if (error && error.response && error.response.data.error.code === 'TRIP_ACTION_ALREADY_COMPLETED') {
        this.setShowPriceChangePopup(false);
        this.setShowCarPriceChangePopup(false);
        this.setShowTripActionErrorPopup(true);
      } else {
        this.setErrors(handleErrors(error, true));
      }
    } finally {
      this.setSendingAnswer(false);
    }
  }

  @Action
  async retryBooking() {
    if (this.chooseToSelectPayment) {
      this.storePaymentForm(null);
    } else {
      this.retryBookingItem();
    }
  }

  @Action
  async retryBookingItem() {
    if (this.sendingAnswer) {
      return;
    }
    this.setSendingAnswer(true);
    this.setRetry(true);
    this.setRetryButtonDisabled(true);

    const itemId = this.lastBasketItemId;
    LayoutStore.toggleAside(false);
    try {
      this.setProcessing(true);

      await BasketApi.retryBookingItem(itemId);

      clearTimeout(this.processBasketID);
      this.setShowRetrySection(false);
      this.postponeProcess();
    } catch (error) {
      if (error && error.response && error.response.data.error.code === 'TRIP_ACTION_ALREADY_COMPLETED') {
        this.setShowRetryPopup(true);
      }
    } finally {
      this.setSendingAnswer(false);
      this.setChooseToSelectPayment(false);
    }
  }

  @Action
  async skipBooking() {
    if (this.sendingAnswer) {
      return;
    }
    this.setSendingAnswer(true);
    this.setSkip(true);
    this.setRetryButtonDisabled(true);
    const itemId = this.lastBasketItemId;
    LayoutStore.toggleAside(false);
    this.setPendingActionCodeItem('');
    try {
      this.setProcessing(true);

      await BasketApi.skipBookingItem(itemId);

      clearTimeout(this.processBasketID);
      this.setShowRetrySection(false);
      this.postponeProcess();
    } catch (error) {
      if (error && error.response && error.response.data.error.code === 'TRIP_ACTION_ALREADY_COMPLETED') {
        this.setShowRetryPopup(true);
      }
    } finally {
      this.setSendingAnswer(false);
    }
  }

  @Action
  async sendTripItemErrorAnswer(answer) {
    this.setAnswer(answer);
    if (this.chooseToSelectPayment) {
      this.storePaymentForm(answer);
    } else {
      this.sendTripItemError(answer);
    }
  }

  @Action
  async sendTripItemError(answer) {
    if (this.sendingAnswer) {
      return;
    }
    this.setSendingAnswer(true);
    this.setSkip(true);
    this.setRetryButtonDisabled(true);
    LayoutStore.toggleAside(false);
    this.setErrors([]);
    this.setProcessing(true);
    try {
      await BasketApi.sendTripItemErrorAnswer(
        this.pendingActionErrorTripItem,
        this.pendingActionErrorTripItemId,
        answer,
      );
      this.setShowRetrySection(false);
      this.setIsRetryCancel(false);
      this.processBasket();
      this.setPendingActionErrorTripItemId('');
      this.setPendingActionErrorTripItem('');
      this.resetTravellersNewDateOfBirthCollection();
      this.resetOverwrittenTravellersNamesCollection();
    } catch (error) {
      if (error && error.response && error.response.data.error.code === 'TRIP_ACTION_ALREADY_COMPLETED') {
        this.setShowRetryPopup(true);
      } else {
        this.setErrors(handleErrors(error));
        this.setSkip(false);
        this.setRetryButtonDisabled(false);
        LayoutStore.toggleAside(true);
      }
    } finally {
      this.setSendingAnswer(false);
    }
  }

  @Action
  async sendTripItemAvailableResponseAnswer(item) {
    this.setErrors([]);
    this.setProcessing(true);
    try {
      await BasketApi.sendTripItemAvailableResponseAnswer(item.actionCode, item.tripItemId, item.answer);
      this.setShowRetrySection(false);
      this.processBasket();
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setIsAvailableResponsesSections(false);
    }
  }

  @Action
  async refreshStatus() {
    this.setShowBasketExpedia3DSPopup(false);
    this.setExpedia3DSTripItemId('');
    this.setErrors([]);
    this.setProcessing(true);
    setTimeout(
      this.processBasket,
      BASKET_REFRESH_INTERVAL,
    );
  }

  @Action
  async updateAgencyMarkup(basketId) {
    const setAgencyMarkupMessage = {
      agencyMarkup: {
        amount: this.updatedAgencyMarkupValue,
        currency: {
          code: 'EUR'
        }
      }
    };

    await BasketApi.updateAgencyMarkup(basketId, this.lastBasketItemId, setAgencyMarkupMessage);
  }

  @Action
  async updateCarSearchDefaultStateBasingOnAir({
    basketItem,
    location,
    startDate,
    endDate,
  }) {
    try {
      const { data } = await OffersApi.getOffer(basketItem.providerReferenceId);

      const firstLeg = data.legFlights[0];
      const lastLeg = data.legFlights[data.legFlights.length - 1];
      const lastFlightOfFirstLeg = firstLeg.flights[firstLeg.flights.length - 1];
      const firstFlightOfLastLeg = lastLeg.flights[0];
      const minPickupTime = moment(lastFlightOfFirstLeg.arrival).add(1, 'hour');
      let maxDropOffTime = moment(firstFlightOfLastLeg.departure).add(-1, 'hour');
      const minPickupTimeSplit = minPickupTime.format('HH:mm').split(':');
      const maxDropOffTimeSplit = maxDropOffTime.format('HH:mm').split(':');
      const minPickupNumber = Number(minPickupTimeSplit[0]) * 60 + Number(minPickupTimeSplit[1]);
      let maxDropOffNumber = Number(maxDropOffTimeSplit[0]) * 60 + Number(maxDropOffTimeSplit[1]);

      if (data.legFlights.length < 2) {
        maxDropOffTime = moment(minPickupTime).add(1, 'day');
        maxDropOffNumber = 990;
      }

      SearchStore.updateCarDefaultState(new CarSearchStateParams({
        departureTime: minPickupNumber,
        returnTime: maxDropOffNumber,
        pickupLocation: location,
        returnLocation: location,
        pickupDate: minPickupTime.format('YYYY-MM-DD'),
        returnDate: maxDropOffTime.format('YYYY-MM-DD'),
      }));
    } catch (error) {
      SearchStore.updateCarDefaultState(new CarSearchStateParams({
        pickupLocation: location,
        returnLocation: location,
        pickupDate: startDate,
        returnDate: endDate,
      }));
    }
  }

  @Action
  async updateCarSearchDefaultStateBasingOnRail({
    basketItem,
    location,
    startDate,
    endDate,
  }) {
    try {
      const { data } = await BasketItemApi.getBasketItem(basketItem.id);

      if (data.rail && data.rail.recommendations) {
        let journeys: any[] = [];

        data.rail.recommendations.forEach(recommendation => {
          recommendation.journeys.forEach(journey => {
            journeys.push(journey);
          });
        });
        const firstLeg = journeys[0];
        const lastLeg = journeys[journeys.length - 1];
        const lastTrainOfFirstLeg = firstLeg.trains[firstLeg.trains.length - 1];
        const firstTrainOfLastLeg = lastLeg.trains[0];
        
        const minPickupTime = moment(lastTrainOfFirstLeg.arrival).add(1, 'hour');
        let maxDropOffTime = moment(firstTrainOfLastLeg.departure).add(-1, 'hour');
        const minPickupTimeSplit = minPickupTime.format('HH:mm').split(':');
        const maxDropOffTimeSplit = maxDropOffTime.format('HH:mm').split(':');
        const minPickupNumber = Number(minPickupTimeSplit[0]) * 60 + Number(minPickupTimeSplit[1]);
        let maxDropOffNumber = Number(maxDropOffTimeSplit[0]) * 60 + Number(maxDropOffTimeSplit[1]);

        if (journeys.length < 2) {
          maxDropOffTime = moment(minPickupTime).add(1, 'day');
          maxDropOffNumber = 990;
        }

        SearchStore.updateCarDefaultState(new CarSearchStateParams({
          departureTime: minPickupNumber,
          returnTime: maxDropOffNumber,
          pickupLocation: location,
          returnLocation: location,
          pickupDate: minPickupTime.format('YYYY-MM-DD'),
          returnDate: maxDropOffTime.format('YYYY-MM-DD'),
        }));
      } else {
        throw new Error('default behaviour needed');
      }
    } catch (error) {
      SearchStore.updateCarDefaultState(new CarSearchStateParams({
        pickupLocation: location,
        returnLocation: location,
        pickupDate: startDate,
        returnDate: endDate,
      }));
    }
  }

  @Action
  async updateSearchDefaultStates() {
    if (this.basketItemsMapped.length) {
      let basketItem = this.basketItemsMapped[this.basketItemsMapped.length - 1];

      const departureDate = moment(basketItem.endDate).format('YYYY-MM-DD');
      const returnDate = moment(departureDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

      const cityName: any = basketItem.location;

      const languageCode = AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
      const responseTrain = await HomeApi.findRailLocation(cityName, languageCode);
      const responseAccomodation = await DictionaryApi.findHotelToLocation(cityName, languageCode);
      const responseCar = await DictionaryApi.findHotelToLocation(cityName, languageCode);
      const responseAir = await HomeApi.findAirLocation(cityName, languageCode);

      const startDate = moment(basketItem.startDate).format('YYYY-MM-DD');
      const endDate = moment(startDate).isSame(departureDate, 'date') ? returnDate : departureDate;
      
      if (basketItem.type === 'Air') {
        this.updateCarSearchDefaultStateBasingOnAir({
          basketItem,
          location: responseCar.data[0],
          startDate,
          endDate,
        });

        const airState = new AirSearchStateParams({
          searchMode: 'RoundTrip',
          departureDate: departureDate,
          returnDate: returnDate,
        });
        SearchStore.updateAirDefaultState(airState);
        SearchStore.updateAirCurrentState(airState);

        SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
          searchMode: 'RoundTrip',
          from: responseTrain.data[0],
          departureDate: departureDate,
          returnDate: returnDate,
        }));

        SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
          to: responseAccomodation.data[0],
          checkInDate: startDate,
          checkOutDate: endDate,
        }));

      } else if (basketItem.type === 'Rail') {
        this.updateCarSearchDefaultStateBasingOnRail({
          basketItem,
          location: responseCar.data[0],
          startDate,
          endDate,
        });

        const airState = new AirSearchStateParams({
          searchMode: 'RoundTrip',
          departureDate: departureDate,
          returnDate: returnDate,
          from: responseAir.data[0],
        });
        SearchStore.updateAirDefaultState(airState);
        SearchStore.updateAirCurrentState(airState);

        SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
          to: responseAccomodation.data[0],
          checkInDate: startDate,
          checkOutDate: endDate,
        }));

        SearchStore.updateTrainDefaultState(new TrainSearchStateParams({}));

      } else if (basketItem.type === 'Accommodation') {
        const airState = new AirSearchStateParams({
          searchMode: 'RoundTrip',
          to: responseAir.data[0],
          departureDate: startDate,
          returnDate: endDate,
        });
        SearchStore.updateAirDefaultState(airState);
        SearchStore.updateAirCurrentState(airState);

        SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
          searchMode: 'RoundTrip',
          to: responseTrain.data[0],
          departureDate: startDate,
          returnDate: endDate,
        }));

        SearchStore.updateCarDefaultState(new CarSearchStateParams({
          pickupLocation: responseCar.data[0],
          returnLocation: responseCar.data[0],
          pickupDate: startDate,
          returnDate: endDate,
        }));

        SearchStore.updateHotelDefaultState(new HotelSearchStateParams({}));

      } else if (basketItem.type === 'Car') {
        const airState = new AirSearchStateParams({
          searchMode: 'RoundTrip',
          to: responseAir.data[0],
          departureDate: startDate,
          returnDate: endDate,
        });
        SearchStore.updateAirDefaultState(airState);
        SearchStore.updateAirCurrentState(airState);

        SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
          searchMode: 'RoundTrip',
          to: responseTrain.data[0],
          departureDate: startDate,
          returnDate: endDate,
        }));

        SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
          to: responseAccomodation.data[0],
          checkInDate: startDate,
          checkOutDate: endDate,
        }));

        SearchStore.updateCarDefaultState(new CarSearchStateParams({}));
      }
    }
  }

  @Action
  async mapGuestTravellerData(travellers) {
    return travellers.map((trav, index) => {
      let traveller;
      traveller = {
        ...trav,
        id: this.travellersState.travellers[index].id,
        isMainTraveller: this.travellersState.travellers[index].isMainTraveller,
        firstName: trav.firstName,
        middleName: trav.middleName || '',
        lastName: trav.lastName,
        companyId: trav.companyId,
        companyName: trav.companyName,
        businessUnitId: trav.businessUnitId,
        businessUnitName: trav.businessUnitName,
        passengerTypeCode: trav.passengerTypeCode,
        isVirtual: trav.isVirtual,
      };
      if (trav.isVirtual) {
        traveller.contacts = {
          primary: {
            email: trav.email,
            phone: {
              code: trav.phoneCode.phoneCode,
              number: trav.phoneNumber,
            }
          }
        };
      } else {
        traveller.profileId = trav.id === traveller.id ? trav.profileId : trav.id;
      }
        return traveller;
    });
  }

  @Action
  async mapGuestTravellerDataForRequest(traveller) {
    return {
      ...traveller,
      id: traveller.id,
      profileId: '',
      isMainTraveller: traveller.isMainTraveller,
      firstName: traveller.firstName,
      middleName: traveller.middleName || '',
      lastName: traveller.lastName,
      companyId: traveller.companyId,
      companyName: traveller.companyName,
      businessUnitId: traveller.businessUnitId,
      businessUnitName: traveller.businessUnitName,
      passengerTypeCode: traveller.passengerTypeCode,
      isVirtual: true,
      contacts: traveller.contacts,
      title: traveller.title && traveller.title.value ? traveller.title.value : (traveller.title || ''),
    };
  }

  @Action
  async saveGuestTravellersProfiles() {
    let newProfiles = this.guestBasketTravellersData.filter(trav => {
      return trav.profileId === undefined;
    });

    if (newProfiles.length) {
      this.setGuestsProfileSaving(true);
      newProfiles.forEach(async profile => {
        await Promise.all([
          ProfileApi.updateProfile(profile.id, profile.personal),
          ContactsApi.updateContacts(profile.id, profile.contacts),
        ]);
        if (profile.documents && profile.documents.length) {
          await profile.documents.forEach(async doc => {
            let request = doc;
            delete request.tempId;
            await this.saveGuestDocument({ id: profile.id, document: request });
          });
        }
      });
      this.setGuestsProfileSaving(false);
    }
  }

  @Action
  async saveGuestDocument({ id, document }) {
    switch (document.type) {
      case DocumentType.NationalId:
      case DocumentType.Passport:
        await DocumentsApi.createNationalIdDocument(id, document);
        break;
      case DocumentType.Visa:
      case DocumentType.Eta:
      case DocumentType.Esta:
      case DocumentType.EVisitor:
        await DocumentsApi.createVisaDocument(id, document);
        break;
      case DocumentType.DrivingLicense:
        await DocumentsApi.createDocumentsDrivingLicenses(id, document);
        break;
      case DocumentType.KnownTraveller:
      case DocumentType.Redress:
        await DocumentsApi.createKnownTravellerDocument(id, document);
        break;
      default:
        return;
    }
  }

  @Action
  startNdcExtrasWizard({
    basketItemId,
    supplier
  }) {
    const ancillariesWizardStep = {
      tripId: this.basketId,
      tripItemId: basketItemId,
      isMandatory: false,
      selected: true,
      code: 'AIR_SABRE_ANCILLARIES'
    };
    const seatsWizardStep = {
      tripId: this.basketId,
      tripItemId: basketItemId,
      isMandatory: false,
      selected: true,
      code: 'AIR_SABRE_SEAT_SELECTION'
    };
    const postTicketingSteps = [ancillariesWizardStep, seatsWizardStep];

    if (
      -1 < [
        'Lufthansa',
        'Sabre',
        'Amadeus',
        'AirFranceKlm'
      ].indexOf(supplier)
    ) {
      const paymentMethodWizardStep = {
        tripId: this.basketId,
        tripItemId: basketItemId,
        isMandatory: true,
        selected: true,
        code: 'PAYMENT_FORM',
      };

      postTicketingSteps.push(paymentMethodWizardStep);
    }

    const reviewTpComplianceWizardStep = {
      tripId: this.basketId,
      tripItemId: basketItemId,
      isMandatory: false,
      selected: true,
      code: 'REVIEW_TP_COMPLIANCE'
    };
    const selectApproverWizardStep = {
      tripId: this.basketId,
      tripItemId: basketItemId,
      isMandatory: false,
      selected: true,
      code: 'SELECT_APPROVER'
    };

    postTicketingSteps.push(reviewTpComplianceWizardStep, selectApproverWizardStep);

    this.setCanShowTravelPolicyComplianceStep(false);
    this.setCanShowSelectApproverStep(false);
    this.setBookingExtras(true);
    this.setBookingStepLoading(true);
    this.setApprovalWorkflowForBasket(null);
    this.setPostTicketingExtrasBooking(true);
    this.setWizardSteps(postTicketingSteps);
    this.setBookingStep(1);
    this.setFinalBookOption('seat-extras');
    this.setBookingStepLoading(false);
    LayoutStore.toggleAside(true);
  }

  @Action
  startChangePaymentWizard(id) {
    const paymentMethodWizardStep = {
      tripId: this.basketId,
      tripItemId: id,
      isMandatory: true,
      selected: true,
      code: 'PAYMENT_FORM',
    };

    this.setIsChangingPaymentMethod(true);

    this.setBookingStepLoading(true);
    this.setApprovalWorkflowForBasket(null);

    this.setPostTicketingExtrasBooking(true);
    this.setWizardSteps([paymentMethodWizardStep]);
    this.setBookingStep(1);
    this.setFinalBookOption('seat-extras');

    this.setBookingStepLoading(false);

    LayoutStore.toggleAside(true);
  }

  @Action
  startModifyTravellersDetails(tripItemId) {
    const modifyTravellersDetailsWizardStep = {
      tripId: this.basketId,
      tripItemId,
      isMandatory: true,
      selected: true,
      code: 'MODIFY_TRAVELLERS_DETAILS',
    };
    this.setIsModifyingTravellersDetails(true);

    this.setBookingStepLoading(true);
    this.setApprovalWorkflowForBasket(null);
    this.resetModifyTravellersDetailsFormStatus();

    this.setPostTicketingExtrasBooking(true);
    this.setWizardSteps([modifyTravellersDetailsWizardStep]);
    this.setBookingStep(1);
    this.setFinalBookOption('seat-extras');

    this.setBookingStepLoading(false);

    LayoutStore.toggleAside(true);
  }

  @Action
  async loadModifyTravellersDetails({ itemId, recommendationId }) {
    this.setModifyTravellersDetailsSaveErrors([]);
    this.setModifyTravellersDetailsLoadingErrors([]);
    this.setIsModifyTravellersDetailsLoading(true);
    this.setModifiedTripItemId(itemId);
    try {
      const item = this.basketItems.find(item => item.id === itemId);
      const supplier = item && item.supplier || '';
      const response = await AirBookingsApi.getTravellersDetails(supplier.toLowerCase(), recommendationId);

      if (response.data && response.data.passengers) {
        this.setModifyTravellersDetails(response.data.passengers);
      }
    } catch (error) {
      this.setModifyTravellersDetailsLoadingErrors(handleErrors(error, false));
    } finally {
      this.setIsModifyTravellersDetailsLoading(false);
    }
  }

  @Action
  storeModifyTravellersDetails() {
    const passengers = new Array(this.modifyTravellersDetails.length);
    this.setModifiedTravellersDetails(passengers);
    EventBus.$emit('assign-modify-travellers');
    EventBus.$emit('update-modified-details');
    
    if (
      this.modifyTravellersDetailsFormInvalid ||
      this.modifyTravellersDetailsLoyaltiesInvalid
    ) {
      return;
    }

    this.filterOutNotEditedDetails();
    this.resetModifyTravellersDetailsFormStatus();
    this.setModifyTravellersDetailsLoyaltiesTouched(false);
    this.modifyTravellersDetailsNow({
      itemId: this.modifiedTripItemId,
      passengers: this.modifiedTravellersDetails,
    });
  }

  @Action
  async modifyTravellersDetailsNow({
    itemId,
    passengers,
  }) {
    if (
      this.bookingStepTripItem &&
      this.bookingStepTripItem.supplier === 'Sabre' &&
      !this.checkModifyTravellerDetails
    ) {
      EventBus.$emit('scroll-to-mobile-modify-travellers-consent');
      return;
    }
    this.setModifyTravellersDetailsSaveErrors([]);
    this.setIsModifyTravellersDetailsLoading(true);
    this.setBookingStepLoading(true);
    try {
      await TripApi.modifyTravellersDetails(itemId, passengers);
      this.setIsModifyingTravellersDetails(false);
      this.setBookingStep(0);
      this.setPostTicketingExtrasBooking(false);
      this.resetPriceChanges();
      this.setBasketItems([]);
      this.processBasket();
    } catch (error) {
      this.setModifyTravellersDetailsSaveErrors(handleErrors(error, true));
    } finally {
      this.setIsModifyTravellersDetailsLoading(false);
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async getBasketMetadata(basketId: string) {
    if (!hasAccessFn('ReadTrip')) {
      return;
    }
    const response = await BasketApi.getBasketMetadata(basketId);
    if (response && response.data) {
      this.setBasketMetadata({ basketId: basketId, metadata: response.data.metadata });
    }
  }

  @Action
  async getLatestBasketData(status: string = 'Draft') {
    try {
      const response = await BasketApi.getLatestBasket(status);
      if (response && response.data) {
        this.setLatestBasketData(response.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        this.setLatestBasketData(null);
      }
    }
  }

  @Action
  async loadLoyaltyPrograms({tripItemId}) {
    const item: any = this.bookingStepTripItem;

    if (!item) {
      return;
    }
    this.setBookingStepLoading(true);
    this.setErrors([]);

    try {
      const results = await BasketItemApi.getLoyaltyCardOptions(
        tripItemId
      );

      this.setBasketLoyaltyPrograms(results.data);

    } catch (error) {
      this.setLoyaltyProgramsLoadingFailed(true);
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async storeLoyaltyPrograms() {
    const item: any = this.bookingStepTripItem;

    if (!item) {
      return;
    }
    this.setBookingStepLoading(true);
    
    try {
      const response = await BasketApi.saveLoyaltyCards(
        item.id,
        this.selectedLoyalties
          .filter(({ tripItemId }) => tripItemId === item.id)
          .map(({ travellerProfileId, loyaltyCardId }) => {

            const loyaltyCard = this.basketLoyaltyPrograms
              .find(basketLoyaltyProgram => basketLoyaltyProgram.id === loyaltyCardId && basketLoyaltyProgram.ownerId === travellerProfileId);

            return {
              travellerProfileId,
              loyaltyCardId,
              loyaltyCardNumber: loyaltyCard.cardNumber,
              loyaltyCarrierCode: loyaltyCard.loyalty.carrierCode
            };
          }),
      );

      if (response.data.success) {
        this.setErrors([]);
      } else {
        const invalidLoyaltyCards = response.data.invalidLoyaltyCards
          .map(invalidLoyaltyCard => {
            return {
              traveller: this.basketTravellers
                .find(basketTraveller => basketTraveller.id === invalidLoyaltyCard.travellerProfileId),
              loyaltyCard: this.basketLoyaltyPrograms
                .find(basketLoyaltyProgram => basketLoyaltyProgram.id === invalidLoyaltyCard.loyaltyCardId && basketLoyaltyProgram.ownerId === invalidLoyaltyCard.travellerProfileId)
            };
          })
          .map(invalidLoyaltyCard => `${invalidLoyaltyCard.traveller.firstName} ${invalidLoyaltyCard.traveller.lastName} (${invalidLoyaltyCard.loyaltyCard.cardNumber})`)
          .join(', ');

          let errors: any = [];
          errors.push({ message: `${translate('basket.loyalty-card-rejected-by-provider')} ${invalidLoyaltyCards}. ${translate('basket.provide-valid-loyalty-card-or-resign-from-loyalty-card')}`});
          this.setErrors(errors);
      }

    } catch (error) {
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setBookingStepLoading(false);
    }
  }

  @Action
  async getLufthansaBookingDetail(id) {
    this.setLoadingUnconfirmedStatus(true);
    try {
      const response = await OffersApi.getLufthansaOfferBooking(id);
      if (response && response.data) {
        this.setLufthansaBookingDetail(response.data);
      }
    } catch (error) {
      this.setLufthansaStatusConfirmationSection(false);
    } finally {
      this.setLoadingUnconfirmedStatus(false);
    }
  }

  @Action
  async getSabreOfferBooking(id) {
    this.setLoadingUnconfirmedStatus(true);
    try {
      const response = await OffersApi.getSabreOfferBooking(id);
      if (response && response.data) {
        this.serSabreOfferBooking(response.data);
      }
    } catch (error) {
      this.setLufthansaStatusConfirmationSection(false);
    } finally {
      this.setLoadingUnconfirmedStatus(false);
    }
  }

  @Action
  async getAmadeusOfferBooking(id) {
    this.setLoadingUnconfirmedStatus(true);
    try {
      const response = await OffersApi.getAmadeusOfferBooking(id);
      if (response && response.data) {
        this.setAmadeusOfferBooking(response.data);
      }
    } catch (error) {
      this.setAmadeusStatusConfirmationSection(false);
    } finally {
      this.setLoadingUnconfirmedStatus(false);
    }
  }

  @Action
  async answerLufthansaTicketing(answer: AirLuftHansaTicketingMessage) {
    try {
      await BasketItemApi.answerLufthansaTicketing(this.lastBasketItemId, answer);
      this.processBasket();
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setLufthansaStatusConfirmationSection(false);
    }
  }

  @Action
  async answerSabreTicketing(answer: SabrePreEmdAnswer) {
    try {
      await BasketItemApi.answerSabreTicketing(this.lastBasketItemId, answer);
      this.processBasket();
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setSabreStatusConfirmationSection(false);
    }
  }

  @Action
  async answerAmadeusTicketing(answer: AmadeusPreEmdAnswer) {
    try {
      await BasketItemApi.answerAmadeusTicketing(this.lastBasketItemId, answer);
      this.processBasket();
    } catch (error) {
      this.setErrors(handleErrors(error));
    } finally {
      this.setAmadeusStatusConfirmationSection(false);
    }
  }

  @Action
  async getBasketRailSncfSeatPreferences(data: { recommendationId: string; railSupplier: string; }) {
    this.setRailSeatsLoadingFailed(false);
    try {
      this.setBasketRailSeatPreferencesLoading(true);
      const response = await BasketItemApi.getRailSncfSeatPreferences(data.recommendationId, data.railSupplier);
      if (response && response.data) {
        let preferences: any[] = [];
        response.data.forEach(pref => {
          preferences.push({
            segmentId: pref.train.segmentId,
            coachNumber: '',
            seatNumber: '',
            seatSelections: [],
            assignmentPreferences: pref.preferencesCategories.map(category => category),
            selectedPreferenceType: {
              value: null,
              label: translate('basket.no-preference')
            },
            hasError: null,
            selectedPreferences: pref.preferencesCategories.map(e => {
              return {
                name: e.name,
                value: {
                  code: null,
                  label: translate('basket.no-preference')
                },
              };
            }),
            train: pref.train,
          });
        });
        this.setBasketRailSncfSeatPreferences(preferences);
      }
    } catch (error) {
      this.setRailSeatsLoadingFailed(true);
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setBasketRailSeatPreferencesLoading(false);
    }
  }

  @Action
  async getBasketRailBenerailSeatPreferences({ id, supplier }: { id: string, supplier: string }) {
    try {
      this.setBasketRailSeatPreferencesLoading(true);
      const { data } = await BasketItemApi.getRailBenerailSeatPreferences(id, supplier);
      return data;
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setBasketRailSeatPreferencesLoading(false);
    }
  }

  @Action
  async getRailTickets(data) {
    try {
      const response = await TrainOrdersApi.getTickets(data.orderId, data.railSupplier);
      if (response && response.data) {
        this.setRailTickets(response.data);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  async getTicketsExist(data) {
    this.setTicketsExistLoading(true);
    this.setTicketsExist(false);
    try {
      const response = await TrainOrdersApi.getTicketsExist(data.orderId, data.railSupplier);
      if (response && response.status === 200) {
        this.setTicketsExist(true);
        this.setTicketsExistLoading(false);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setTicketsExistLoading(false);
    }
  }

  @Action
  async getRailTravellersSeatRequirements(data) {
    try {
      const response = await TrainOffersApi.getTravellersSeatRequirements(data.offerId, data.railSupplier);
      if (response && response.data) {
        this.setRailTravellersSeatRequirements(response.data);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  async getRailAncillariesOffers(data: { recommendationId: string; railSupplier: string; }) {
    this.setRailAncillariesLoadingFailed(false);
    try {
      this.setBasketRailAncillariesLoading(true);
      const response = await TrainOffersApi.getRailAncillariesOffers(data.recommendationId, data.railSupplier, this.basketId);
      if (response && response.data) {
        this.setBasketRailSncfRailAncillaries(response.data);
      }
    } catch (error) {
      this.setRailAncillariesLoadingFailed(true);
      this.setErrors(handleErrors(error, true));
    } finally {
      this.setBasketRailAncillariesLoading(false);
    }
  }

  @Action
  async checkTravellersExistence(travellers) {
    try {
      const response = await ProfileApi.checkTravellersExistence(travellers);
      if (response && response.data) {
        let travellersExistence = response.data;
        travellersExistence.forEach(traveller => {
          if (traveller.existingTravellers && traveller.existingTravellers.length) {
            traveller.existingTravellers.forEach(existTrav => {
              existTrav.disabled = false;
            });
          }
        });
        this.setTravellersExistence(travellersExistence);
        this.setHasSimilarTravellers(response.data);
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }

  @Action
  prepareRecalculatePaymentCardsRequest() {
    let paymentCardsNumber = 0;
    let request = {
      basketItemsPaymentCards: {}
    } as SelectBasketPaymentCardsMessage;

    if (this.bookingStepDef.tripItemId !== null) {
      const item = this.basketPaymentMethods.find(method => method.id === this.bookingStepDef.tripItemId);
      if (item && item.selectedPaymentCard) {
        paymentCardsNumber++;
        request.basketItemsPaymentCards[item.id] = {
          paymentMethod: item.selectedPaymentCard.paymentMethod,
          cardId: item.selectedPaymentCard.paymentCardId,
          cardTypeOverride: item.selectedPaymentCard.type,
          transactionId: item.selectedPaymentCard.transactionId,
          recalculateFee: item.status === 'Draft',
        } as SelectBasketPaymentCardMessage;
      }
    } else {
      this.basketPaymentMethods
        .filter(method => {
          return method.selectedPaymentCard && !method.selectedPaymentCard.hasErrors;
        })
        .forEach(item => {
          paymentCardsNumber++;
          request.basketItemsPaymentCards[item.id] = {
            paymentMethod: item.selectedPaymentCard.paymentMethod,
            cardId: item.selectedPaymentCard.paymentCardId,
            cardTypeOverride: item.selectedPaymentCard.type,
            transactionId: item.selectedPaymentCard.transactionId,
            recalculateFee: item.status === 'Draft',
          } as SelectBasketPaymentCardMessage;
      });
    }

    return {
      paymentCardsNumber,
      request,
    };
  }

  @Action
  async recalculateBasketPaymentMethodsPrices() {
    this.setErrors([]);

    if (!this.basketItems || this.basketItems.length === 0 || (this.status && this.status.status !== 'Draft')) {
      return;
    }

    const {
      paymentCardsNumber,
      request,
    } = await this.prepareRecalculatePaymentCardsRequest();

    if (!paymentCardsNumber) {
      return;
    }
    try {
      this.resetIsChangingPriceOnPaymentMethod();
      let basketId = router.currentRoute.params.id;
      const response = await BasketApi.setBasketPaymentCards(basketId, request);
      if (response && response.data && response.data.items) {
        this.updatePricesForBasketItemsAndPaymentMethods(response.data.items);
        if (response.status === 200 && this.isChangingPriceOnPaymentMethod) {
          EventBus.$emit('show-toast', {
            type: translate('common.success'),
            message: translate('settings-fees.prices-updated')
          });
        }
      }
      EventBus.$emit('reload-item-details-after-price-recalculation');
    } catch (error) {
      this.setStorePaymentErrors(handleErrors(error, true));
    }
  }

  @Action
  async loadBasketApproverData() {
    if (!hasAccessFn('ReadTrip')) {
      return;
    }
    this.setTripApprovalData(null);
    const response = await TripApi.getApprovalWorkflowForBasket({ basketId: this.basketId});

    if (response && response.data) {
      const approval = response.data;
      this.setTripApprovalData(approval);

      const currentLevel = approval.workflowLevels.find(item => item.approvalLevel === approval.currentApprovalLevel);

      if (!!currentLevel) {
        const currentApprover = currentLevel.currentApprover;
        const curentApproverData = (currentLevel.approverSelectionType === 'BookerSelectsApprover' && currentApprover) ? {
          id: currentApprover.approverId,
          firstName: currentApprover.approverFirstName,
          middleName: currentApprover.approverMiddleName,
          lastName: currentApprover.approverLastName,
          companyName: currentApprover.businessUnitName,
          level: currentApprover.approvalLevel
        } : null;
        
        const finalApproverData = approval.finalApprover;
        const finalApprover = finalApproverData ? {
          id: finalApproverData.approverId,
          firstName: finalApproverData.approverFirstName,
          middleName: finalApproverData.approverMiddleName,
          lastName: finalApproverData.approverLastName,
          companyName: finalApproverData.businessUnitName,
          level: finalApproverData.approvalLevel,
        } : null;

        this.setBasketApproverData({
          level: currentLevel.approvalLevel,
          levelCount: approval.approvalLevelsCount,
          approverSelectionType: currentLevel.approverSelectionType,
          approverSourceType: currentLevel.approverSourceType,
          currentApprover: curentApproverData,
          finalApprover: finalApprover
        });
      }
    }
  }

  @Action
  async initItemBookingDataSync(item) {
    try {
      const response = await BasketItemApi.initItemBookingDataSync(item.id);
      if (response && response.status === 204) {
        this.setSynchronizationCompleted(item.supplier !== 'Amadeus' && item.supplier !== 'AirFranceKlm');
        this.processBasket();
      }
    } catch (error) {
      this.setErrors(handleErrors(error, true));
    }
  }
}

export default getModule(BasketStore);
