import { Table3DataRow } from './table3';
import { Table3Params } from './table3.params';
import Data2 from '../data2.json';

export class Table3ItemsService {
  maxCount: number = Data2.length;

  filter(params: Table3Params, items: Table3DataRow[]): Table3DataRow[] {
    // filtering
    const filtered = items
      .filter((item) => {
        if (params.name && params.name.length) {
          if (item.name.toLowerCase().indexOf(params.name.toLowerCase()) < 0) {
            return false;
          }
        }
        if (params.email && params.email.length) {
          if (item.email.toLowerCase().indexOf(params.email.toLowerCase()) < 0) {
            return false;
          }
        }
        return true;
      });
    this.maxCount = filtered.length;
    return filtered;
  }

  sort(params: Table3Params, items: Table3DataRow[]): Table3DataRow[] {
    const sorted = items.sort((a, b) => {
      if (!params || !params.sort) {
        return 1;
      }
      const decision = (key) => {
        if (a[key] === b[key]) {
          return 0;
        }
        const result = a[key] > b[key];
        if (params.desc) {
          return result ? -1 : 1;
        }
        return result ? 1 : -1;
      };
      return decision(params.sort);
    });
    return sorted;
  }

  page(params: Table3Params, items: Table3DataRow[]): Table3DataRow[] {
    if (!params || undefined === params.page || !params.size) {
      return items;
    }
    // paging
    const paged = items
      .slice(params.page, params.page + params.size);
    return paged;
  }

  async get(params: Table3Params) {
    await new Promise(resolve => setTimeout(resolve, 500));

    let items = this.filter(params, Data2);
    items = this.sort(params, items);
    items = this.page(params, items);

    const res = {
      items: items,
      hasMoreData: params.size + params.page < this.maxCount,
    };

    return res;
  }
}