




































































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CustomFieldsConfigurationsDataRow } from './customFieldsConfigurations.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import { Permission } from '@/const/permission.enum';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import CustomFieldsStore from './custom-fields.store';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class CustomFieldsConfigurations extends BaseTable<CustomFieldsConfigurationsDataRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  removePopup: boolean = false;
  errors: any[] = [];
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.companyId + '/custom-fields/' + item.id + '/field-configuration/false');
  }

  rowClicked(item, isCopy, preventRedirect = false) {
    if (item) {
      const name = isCopy ? item.name + ' (copy)' : item.name;
      CustomFieldsStore.setConfiguration({
        ...item,
        name,
      });
      if (!preventRedirect) {
        this.resetData();
        router.push({
          name: 'field-configuration',
          params: {
            configurationId: item.id,
            isCopy: isCopy
          }
        });
      }
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  resetData() {
    CustomFieldsStore.clearData();
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    try {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.customFieldDefinitionConfiguration,
        this.params.size,
        this.params.page,
        Permission.ReadCustomFieldDefinitionsConfiguration,
        request.searchName
      );
      if (result && result.data) {
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.errors = $handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.WriteCustomFieldDefinitionsConfiguration);
      this.reload();

      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-custom-fields.configuration-removed-message')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
      this.loading = false;
    } finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  toggleConfigurationMenu(item) {
    if (this.configuration !== item) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }
}
