import PnrRemarksConfigurations from '@/modules/settings/pnr-remarks/PnrRemarksConfigurations.vue';

export default {
  path: '',
  name: 'pnr-remarks-configurations',
  component: PnrRemarksConfigurations,
  displayName: 'PNR Remarks Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPnrRemarks',
    depthCategory: 'pnr-remarks-configurations',
    depth: 1,
    parentName: 'pnr-remarks-configurations',
  }
};