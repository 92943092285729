

















































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import SearchStore from '@/modules/search/search.store';
import BasketStore from './basket.store';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, numeric, maxValue } from 'vuelidate/lib/validators';
import BasketTravellersFillDateOfBirth from './BasketTravellersFillDateOfBirth.vue';
import BasketOverwriteTravellersNames from './BasketOverwriteTravellersNames.vue';
import BasketTravellersSecurityDataMissing from './BasketTravellersSecurityDataMissing.vue';
import BasketTravellersMissingDocument from './BasketTravellersMissingDocument.vue';
import BasketProcessSeatsView from './BasketProcessSeatsView.vue';
import BasketProcessAncillariesView from './BasketProcessAncillariesView.vue';

const errorsIcon = {
  Unknown: 'error',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

const errorLevelTooltips = {
  Unknown: translate('basket.unknown-issue-tooltip'),
  Error: translate('basket.error-tooltip'),
  Warning: translate('basket.warning-tooltip'),
  Info: translate('basket.info-tooltip'),
};

@Component({
  filters: {
    dateFilter(date) {
      return moment.parseZone(date).format('Do MMMM YYYY, HH:mm');
    },
  },
  components: {
    BasketTravellersFillDateOfBirth,
    BasketOverwriteTravellersNames,
    BasketProcessSeatsView,
    BasketTravellersSecurityDataMissing,
    BasketTravellersMissingDocument,
    BasketProcessAncillariesView,
  },
})
export default class BasketAvailableResponsesView extends Vue {
  availableResponsesSent: boolean = false;
  $v;

  get basketStatus() {
    return BasketStore.status;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get basketStatusItems() {
    if (!this.basketStatus) {
      return [];
    }
    return this.basketStatus.items.map(item => {
      const object = this.basketItems.find(i => i.id === item.id);

      if (object && object.bookingErrors && object.bookingErrors.length) {
        object.bookingErrors.forEach(element => {
          element.isOpen = false;
        });

        object.groupeErrors = _.groupBy(object.bookingErrors, 'errorLevel');
      }

      let pendingActions = item.pendingActions;
      if (pendingActions) {
        pendingActions = pendingActions.map(action => {
          const availableResponses = action.availableResponses;
          availableResponses.reverse();
          return {
            ...action,
            availableResponses,
          };
        });
      }

      return {
        maximumAllowedPriceDifference: 10,
        ...item,
        pendingActions,
        object,
      };
    });
  }

  get showRetrySection() {
    return BasketStore.showRetrySection;
  }

  get lastBasketItemId() {
    return BasketStore.lastBasketItemId;
  }

  get showFillDateOfBirth() {
    return !!this.basketStatus && !!this.basketStatus.items.find(item => {
      return item.pendingActions && !!item.pendingActions
        .find(action => -1 < ['DATE_OF_BIRTH_TO_VERIFY', 'DATE_OF_BIRTH_MISSING'].indexOf(action.code));
    });
  }

  get showDocumentMissing() {
    return !!this.basketStatus && !!this.basketStatus.items.find(item => {
      return item.pendingActions && !!item.pendingActions
        .find(action => -1 < ['DOCUMENT_MISSING'].indexOf(action.code));
    });
  }

  get showSecurityDataMissing() {
    return !!this.basketStatus && !!this.basketStatus.items.find(item => {
      return item.pendingActions && !!item.pendingActions
        .find(action => -1 < ['SECURITY_IDENTIFICATION_MISSING'].indexOf(action.code));
    });
  }

  get showOverwriteTravellersNames() {
    return !!this.basketStatus && !!this.basketStatus.items.find(item => {
      return item.pendingActions && !!item.pendingActions
        .find(action => -1 < ['TRAVELLERS_NAMES_TOO_LONG'].indexOf(action.code));
    });
  }

  get anyItemHasGatewayPaymentPending() {
    return this.basketStatusItems.find(this.itemHasGatewayPaymentPending);
  }

  checkForStatusCode(item, code) {
    if (item.pendingActions && item.pendingActions.find(obj => obj.code ===  code) !== undefined) {
      return true;
    }
    return false;
  }

  betterPrice(price) {
    return {
      amount: price.Amount,
      currency: {
        code: price.Currency.Code,
        symbol: price.Currency.Symbol,
      },
    };
  }

  itemHasHaltedPendingAction(item) {
    return this.checkForStatusCode(item, 'HALTED_ON_STATUS');
  }

  itemHasGatewayPaymentPending(item) {
    return this.checkForStatusCode(item, 'PAYMENT_GATEWAY_SUMMARY');
  }

  itemHasExchangeError(item) {
    return this.checkForStatusCode(item, 'EXCHANGE_ERROR');
  }
  
  itemWaitToBeReadyForExchangeTicketing(item) {
    return this.checkForStatusCode(item, 'NOT_READY_FOR_EXCHANGE_TICKETING');
  }

  itemHasSeatMapError(item) {
    return this.checkForStatusCode(item, 'CHANGE_SEAT');
  }

  itemHasChangeAncillary(item) {
    return this.checkForStatusCode(item, 'CHANGE_ANCILLARY');
  }

  @Validation()
  validationObject() {
    return { 
      basketStatusItems: {
        $each: {
          maximumAllowedPriceDifference: {
            numeric,
            required,
            maxValue: maxValue(100),
          }
        }
      }
    };
  }

  showErrorInfo(error) {
    error.isOpen = !error.isOpen;
    this.$forceUpdate();
  }

  iconErrorClass(error) {
    return {
      'error-icon': error.errorLevel === 'Unknown' || error.errorLevel === 'Error',
      'warning-icon': error.errorLevel === 'Warning',
      'info-icon': error.errorLevel === 'Info',
    };
  }

  errorClasses(error) {
    return {
      'error': error.errorLevel === 'Unknown' || error.errorLevel === 'Error',
      'warning': error.errorLevel === 'Warning',
      'info-level': error.errorLevel === 'Info',
    };
  }

  errorIcons(error) {
    return errorsIcon[error.errorLevel];
  }

  errorTooltips(error) {
    return errorLevelTooltips[error.errorLevel];
  }

  pendingActionClass(item) {
    return {
      'basket-processing-view__processing-item--has-confirmation': item.pendingActions && item.pendingActions.length > 0 && !this.itemHasGatewayPaymentPending(item),
      'basket-processing-view__processing-item--has-pending-payment': this.itemHasGatewayPaymentPending(item),
    };
  }

  async availableResponseAnswer(availableResponse, item, pendingAction) {
    if (this.availableResponsesSent) {
      return;
    }
    this.availableResponsesSent = true;

    if (pendingAction === 'HALTED_ON_STATUS') {
      this.$v.basketStatusItems.$touch();

      if (this.$v.basketStatusItems.$pending || this.$v.basketStatusItems.$error) {
        return;
      }
    } else if (
      pendingAction === 'CHANGE_SEAT' &&
      availableResponse === 'UPDATE_AND_RETRY'
    ) {
      await BasketStore.processStoreSeats(item.id);
    } else if (
      pendingAction === 'CHANGE_ANCILLARY' &&
      availableResponse === 'UPDATE_AND_RETRY'
    ) {
      try {
        const saveResponse = await BasketStore.saveBasketCustomParameters();
      } catch (error) {
      }
    }

    let availableResponseAnswer: any | null = null;

    if (pendingAction === 'HALTED_ON_STATUS') {
      
      availableResponseAnswer = {
        tripItemId: item.id,
        actionCode: pendingAction,
        answer: {
          selectedAnswer: availableResponse,
          data: {
            maximumAllowedPricePercentageDifference: item.maximumAllowedPriceDifference,
          }
        }
      };
    } else {
      let travellers: any[] = [];
      const actions = [
        {
          condition: () => this.showFillDateOfBirth && availableResponse === 'SAVE_AND_CONTINUE',
          action: () => {
            EventBus.$emit('basket-travellers-fill-date-of-birth');
            SearchStore.updateTravellersBirthDates(BasketStore.travellersNewDateOfBirthCollection);
            travellers = BasketStore.travellersNewDateOfBirthCollection;
          },
        },
        {
          condition: () => this.showDocumentMissing && availableResponse === 'SAVE_AND_CONTINUE',
          action: () => {
            EventBus.$emit('basket-travellers-fill-missing-documents');
            travellers = BasketStore.travellersMissingDocumentCollection;
          },
        },
        {
          condition: () => this.showOverwriteTravellersNames && availableResponse === 'SAVE_AND_CONTINUE',
          action: () => {
            EventBus.$emit('basket-save-overwritten-travellers-names');
            travellers = BasketStore.overwrittenTravellersNamesCollection;
          },
        },
      ];

      const action = actions.find(action => action.condition());

      if (action) {
        action.action();
      }

      availableResponseAnswer = {
        tripItemId: item.id,
        actionCode: pendingAction,
        answer: {
          selectedAnswer: availableResponse,
          data: {
            travellers: travellers && travellers.length ? travellers : undefined,
          },
        }
      };
    }

    BasketStore.sendTripItemAvailableResponseAnswer(availableResponseAnswer);
  }
}

