import BasketStore from '@/modules/basket/basket.store';
import { TravellersStateParams } from '@/modules/search/travellers.params';
import settings from '@/settings';

export default async (to, from, next) => {
  if (to.name === 'home') {
    BasketStore.setBasketMetadata(null);
  }
  next();
};
