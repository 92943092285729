
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UiCustomCheck extends Vue {
  @Prop() value!: boolean;
  @Prop() name!: string;

}

