import ClientEmailNotificationsConfigurations from '@/modules/settings/client-email-notifications/ClientEmailNotificationsConfigurations.vue';

export default {
  path: '',
  name: 'client-email-notifications-configurations',
  component: ClientEmailNotificationsConfigurations,
  displayName: `Client email notifications configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditClientEmailNotifications',
    parentName: 'clientEmailNotifications',
    depthCategory: 'clientEmailNotifications',
    depth: 1,
  }
};