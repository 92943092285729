import AccommodationProviders from '@/modules/settings/configurations/AccommodationProviders.vue';
import accommodationProvidersList from './accommodation-providers/accommodation-providers-list.route';
import newAccommodationProvider from './accommodation-providers/new-accommodation-provider.route';
import editAccommodationProvider from './accommodation-providers/edit-accommodation-provider.route';
import { translate } from '@/i18n';

export default {
  path: 'accommodation-provider-configurations',
  component: AccommodationProviders,
  displayName: 'settings-sidebar.accommodation-providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadAccommodationProviders',
    icon: 'hotel',
    parentName: 'accommodation-provider-configurations',
  },
  children: [
    accommodationProvidersList,
    newAccommodationProvider,
    editAccommodationProvider,
  ],
};
