import Basket from '@/modules/basket/Basket.vue';
import BasketIntro from '@/modules/basket/BasketIntro.vue';
import BasketMessages from '@/modules/basket/BasketMessages.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/basket-expired/:id',
  name: 'basket-expired',
  component: Basket,
  components: {
    default: Basket,
    intro: BasketIntro,
    messages: BasketMessages,
  },
  meta: {
    introClass: 'intro-basket',
    permission: 'ReadTrip',
    transitions: {
      intro: {
        basketAddSegment: AnimationName.DOWN,
      },
      default: {
        basket: AnimationName.LEFT,
      }
    },
  },
};
