












































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';
import EventBus from '@/services/event-handler';
import SearchStore from '@/modules/search/search.store';
import CarResultsRow from './CarResultsRow.vue';
import CarSearchStore from './car-search.store';

@Component({
  components: {
    CarResultsRow,
    VclCode,
  }
})
export default class CarResults extends Vue {
  searchId: string = '';

  get offers() {
    return CarSearchStore.offers;
  }

  get filters() {
    return CarSearchStore.filters;
  }

  get loading() {
    return CarSearchStore.loading;
  }

  get showError() {
    return CarSearchStore.showError;
  }

  get serverErrors() {
    return CarSearchStore.serverErrors;
  }

  get offersVisible() {
    return CarSearchStore.offersVisible;
  }

  get renderShowMoreOffers() {
    return this.offers && this.offers.length > this.offersVisible;
  }

  get travellers() {
    return SearchStore.getTravellersState.travellers;
  }

  showMoreOffer() {
    let currently = this.offersVisible;
    currently += 10;
    CarSearchStore.setOffersVisible(currently);
  }

  async initView() {
    this.searchId = this.$route.params.searchId;
    CarSearchStore.clearSelectedOffer();
    CarSearchStore.loader(true);
    if (this.searchId && this.searchId !== '-') {
      await CarSearchStore.getCarSearchSession(this.searchId);

      if (this.$route.params.searchId !== this.searchId) {
        return;
      }
      CarSearchStore.getOffers(this.searchId);
    } else if (this.searchId === '-' && !this.travellers.length) {
      this.$router.push({
        name: 'home'
      });
    }
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  @Watch('errMessages', {deep: true})
  onErrorShow() {
    this.scrollToError();
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    CarSearchStore.updateOffers([]);
    CarSearchStore.setFiltersChanging(false);
    this.initView();
  }

  beforeDestroy() {
    CarSearchStore.stopSearch();
  }
}
