import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';
import CarResults from '@/modules/search/car/CarResults.vue';
import CarSubintro from '@/modules/search/car/CarSubintro.vue';
import CarResultsFilters from '@/modules/search/car/CarResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/car-results-travellers-list',
  name: 'carResultsTravellersList',
  components: {
    default: CarResults,
    intro: SearchTravellersIntro,
    subintro: CarSubintro,
    sidebar: CarResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-car-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
