

























































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import ExpensePerDiemStore  from './expense-per-diem.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { CityCountryModel, LanguageCode } from '@/api/dictionary/dictionary.model';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, requiredIf, between } from 'vuelidate/lib/validators';

@Component({})
export default class ReductionReasonPerDiemRule extends Vue {
  $v;
  locationOptions: CityCountryModel[] = [];
  isLoading: boolean = false;
  serverErrors: any[] = [];
  definition: any = {
    name: null,
    description: null,
    reductionReason: null,
    isActive: true,
    reductionPercent: 0,
    fixedReductionAmount: null
  };

  reductionReasonOptions = ['Accommodation', 'Breakfast', 'Lunch', 'Supper'];

  @Validation()
  validationObject() {
    return {
      definition: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        description: {
        },
        reductionReason: {
          required,
        },
        isActive: {
        },
        reductionPercent: {
          required: requiredIf(() => {
            return this.reductionValue;
          }),
          between: between(0, 100),
        },
        fixedReductionAmount: {
          required: requiredIf(() => {
            return !this.reductionValue;
          }),
        }
      },
    };
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get perDiemRateId() {
    return router.currentRoute.params.perDiemRateId;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get errors() {
    return ExpensePerDiemStore.errMessages;
  }

  get showError() {
    return ExpensePerDiemStore.showError;
  }

  get expensesList() {
    return ExpensePolicyStore.configurations;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get displayLocationOptions() {
    return this.locationOptions.map(location => ({ ...location, uniqId: location.cityId + location.countryCode }));
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get perDiemSettingsUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/per-diems/${this.expensePolicyId}`;
  }

  get reductionReasonPerDiemRule() {
    return ExpensePerDiemStore.reductionReasonPerDiemRule;
  }

  get reductionValue() {
    if (this.definition.reductionPercent === null) {
      return false;
    } else {
      return true;
    }
  }

  set reductionValue(value) {
    if (!value) {
      this.definition.fixedReductionAmount = 0;
      this.definition.reductionPercent = null;
    } else {
      this.definition.reductionPercent = 0;
      this.definition.fixedReductionAmount = null;
    }
  }

  onSelectLocation() {
    const selection = this.$refs.PerDiem_location as Vue;
    const selectionEl = selection.$el.getElementsByTagName('input')[0] as HTMLElement;

    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    }
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    this.goToSelectedTab();
    router.push({
      name: 'per-diems',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  goToSelectedTab() {
    ExpensePerDiemStore.setPerDiemActiveTab('ReductionReason');
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findCityCountry(query: string) {
    if (query && query.length > 2) {
      try {
        const result = await DictionaryApi.findCityCountry(query, LanguageCode.EN);
        this.locationOptions = result.data.filter(item => item.type === 'City');
      } catch (error) {
        this.serverErrors = this.$handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
      this.locationOptions = [];
    }
  }

  async saveDefinition() {
    ExpensePerDiemStore.setShowError(false);
    ExpensePerDiemStore.setErrMessages([]);
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }
    if (this.errors && this.errors.length === 0) {
      let request = {
        policyId: this.expensePolicyId,
        name: this.definition.name,
        reductionReason: this.definition.reductionReason,
        description: this.definition.description,
        reductionPercent: this.definition.reductionPercent,
        isActive: this.definition.isActive,
        fixedReductionAmount: this.definition.fixedReductionAmount
      };

      const isEdit = !!this.perDiemRateId;
      if (isEdit) {
        const message = {
          perDiemRateId: this.perDiemRateId,
          params: request,
        };
        await ExpensePerDiemStore.updateReductionReasonPerDiemRule(message);
      } else {
        await ExpensePerDiemStore.createReductionReasonPerDiemRule(request);
      }

      this.goToSelectedTab();

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'per-diems',
          params: {
            id: this.$route.params.id,
            configurationId: this.configurationId,
            itemId: this.$route.params.itemId
          }
        });
      }
    }
  }

  async mapFormData() {
    if (this.perDiemRateId) {
      await ExpensePerDiemStore.getReductionReasonPerDiemRule(this.perDiemRateId);
      if (this.reductionReasonPerDiemRule) {
        this.definition = {
          name: this.reductionReasonPerDiemRule.name,
          description: this.reductionReasonPerDiemRule.description,
          reductionReason: this.reductionReasonPerDiemRule.reductionReason,
          isActive: this.reductionReasonPerDiemRule.isActive,
          reductionPercent: this.reductionReasonPerDiemRule.reductionPercent,
          fixedReductionAmount: this.reductionReasonPerDiemRule.fixedReductionAmount
        };
      }
    }
  }

  created() {
    ExpensePerDiemStore.clearErrMessages();
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
    }
    this.mapFormData();
  }
}
