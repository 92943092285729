



















































































































































import { Vue, Component, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, email } from 'vuelidate/lib/validators';

@Component({})
export default class Form extends Vue {
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        text1: { required, isName: this.isName },
        text2: { required },
        text3: { required },
        text4: { required, email},
        singleValue: { required }
      },
    };
  }

  isName(value) {
    if (!value) {
      return true;
    }
    return value === 'Input1';
  }

  Form = {
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    radioGroupPicked1: null,
    radioGroupPicked2: null,
    singleValue: null,
    multiValues: null,
  };

  options = [
    {
      label: 'option 1',
      value: 1,
    },
    {
      label: 'option 2',
      value: 2,
    },
    {
      label: 'option 3',
      value: 3,
    },
    {
      label: 'option 4',
      value: 4,
    },
    {
      label: 'option 5',
      value: 5,
    },
  ];

  @Emit()
  close() {}

  submit() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }
    alert('Form submitted');
  }
}

