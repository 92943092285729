








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiCo2 extends Vue {
  @Prop() value: any;
  @Prop({ default: 'right' }) side!: string;

  get displayImg() {
    return '/assets/img/co2.png';
  }
}
