import RailProviderConfigurationDetail from '@/modules/settings/configurations/RailProviderConfigurationDetail.vue';

export default {
  path: 'new',
  name: 'new-rail-provider-configuration',
  component: RailProviderConfigurationDetail,
  displayName: 'New rail provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRailProviders',
    depthCategory: 'rail-providers',
    depth: 2,
    parentName: 'rail-provider-configurations',
  },
};