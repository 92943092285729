import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CompanyModel,
  CompanyDataModel,
  CreateCompanyMessage,
  RootCompanyModel,
  UpdateCompanyMessage,
  CompanyChangeStatusMessage,
  CanDisableOrDeleteCompanyUnitResponse,
} from './company.model';

class ProfileCompanyApiClass {

  public createCompany(params: CreateCompanyMessage): Promise<AxiosResponse<CompanyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateCompany(params: UpdateCompanyMessage, companyId: string, permission: string): Promise<AxiosResponse<CompanyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}',
      method: RequestMethod.PUT,
      permission,
    }, 
    {
      companyId: companyId
    }, { ...params, permission });
  }

  public changeCompanyStatus(params: CompanyChangeStatusMessage, companyId: string, permission): Promise<AxiosResponse<CompanyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/changestatus',
      method: RequestMethod.PUT,
      permission,
    }, 
    {
      companyId: companyId
    }, { ...params, permission });
  }

  public getById(companyId, permission): Promise<AxiosResponse<CompanyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}',
      permission,
      method: RequestMethod.GET
    }, { companyId }, { permission });
  }

  public getRootByCompanyId(companyId): Promise<AxiosResponse<RootCompanyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/root',
      method: RequestMethod.GET
    }, { companyId }, {});
  }

  public autocompleteCompanies(phrase: string) {
    return http.execute({
      path: settings.apiProfile + '/api/companies?phrase={phrase}',
      method: RequestMethod.GET,
    }, {
      phrase: phrase
    }, {});
  }

  public autocompleteCompaniesIncludingProfileAgency(phrase: string) {
    return http.execute({
      path: settings.apiProfile + '/api/companies/search?phrase={phrase}',
      method: RequestMethod.GET,
    }, {
      phrase: phrase
    }, {});
  }

  public getCompanyUnits(rootId: string, phrase: string, permission: string) {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{rootId}/units?phrase={phrase}',
      permission: permission,
      method: RequestMethod.GET,
    }, {
      rootId: rootId,
      phrase: phrase
    }, { permission });
  }

  public getCompaniesStructure(rootId: string) {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{rootId}/structure',
      method: RequestMethod.GET,
    }, {
      rootId: rootId
    }, {});
  }

  public getCompanyDataById(companyId, permission): Promise<AxiosResponse<CompanyDataModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/edit',
      permission,
      method: RequestMethod.GET
    }, { companyId }, { permission });
  }

  public updateCompanyDataById(params: CompanyDataModel, companyId: string, permission: string): Promise<AxiosResponse<CompanyDataModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/edit',
      method: RequestMethod.PUT,
      permission,
    }, 
    {
      companyId: companyId
    }, { ...params, permission });
  }

  public getCanEnableCompanyUnit(companyId: string, permission: string, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<CanDisableOrDeleteCompanyUnitResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/can-activate',
      permission,
      method: RequestMethod.GET
    }, { companyId }, { permission }, false, cancelTokenSource);
  }

  public getCanDisableCompanyUnit(companyId: string, permission: string, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<CanDisableOrDeleteCompanyUnitResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/can-disable',
      permission,
      method: RequestMethod.GET
    }, { companyId }, { permission }, false, cancelTokenSource);
  }

  public getCanDeleteCompanyUnit(companyId: string, permission: string, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<CanDisableOrDeleteCompanyUnitResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/can-delete',
      permission,
      method: RequestMethod.GET
    }, { companyId }, { permission }, false, cancelTokenSource);
  }

  public deleteCompanyUnit(companyId: string, permission: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}',
      permission,
      method: RequestMethod.DELETE
    }, { companyId }, { permission });
  }
}

export const ProfileCompanyApi: ProfileCompanyApiClass = new ProfileCompanyApiClass();
