







































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import TrainEditedResultsRow from '@/modules/search/train/TrainEditedResultsRow.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    TrainEditedResultsRow,
  },
})
export default class BasketEditTrainItemAgencyMarkupPopup extends Vue {
  @Prop() show!: boolean;



  loading: boolean = false;
  isAgencyMarkupValueChanging: boolean = false;
  agencyMarkupValue: any = null;
  errors: any[] = [];
  initialAgencyMarkupValue: number = 0;



  get item() {
    return BasketStore.editedFeeItem;
  }

  get offer() {
    return BasketStore.editedFeeOffer;
  }

  get feeCurrency() {
    if (this.item && this.item.price && this.item.price.agencyMarkup && this.item.price.agencyMarkup.currency) {
      return this.item.price.agencyMarkup.currency.symbol ? this.item.price.agencyMarkup.currency.symbol : this.item.price.agencyMarkup.currency.code; 
    }
    return '\u20AC';
  }

  get basePrice() {
    if (this.item && this.item.price && this.item.price.total) {
      return this.item.price.total.amount - this.initialAgencyMarkupValue;
    }
    return 0;
  }

  get totalPrice() {
    return {
      amount: this.agencyMarkupValue + this.basePrice,
      currency: {
        ...this.item.price.agencyMarkup.currency,
      },
    };
  }

  get basketId() {
    return this.$route.params.id;
  }



  @Emit('update:show')
  closePopup() {
    BasketStore.setEditedFeeItem(null);
    BasketStore.setEditedFeeOffer(null);
    return false;
  }

  async save() {
    if (this.loading || this.isAgencyMarkupValueChanging) {
      return;
    }
    this.loading = true;
    try {
      let agencyMarkup = {
        amount: this.agencyMarkupValue,
        currency: this.item.price.agencyMarkup.currency,
      };
      const response = await BasketItemApi.updateAgencyMarkupOnItem({
        basketId: this.basketId,
        tripItemId: this.item.id,
        agencyMarkup,
      });
      if (response && response.data && response.data.items) {
        BasketStore.updatePricesForBasketItemsAndPaymentMethods(response.data.items);
        await BasketStore.reloadWizardSteps();
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          message: translate('settings-fees.prices-updated')
        });
      }

      this.closePopup();
    } catch (errors) {
      this.errors = this.$handleErrors(errors, true);
    } finally {
      this.loading = false;
    }
  }



  @Watch('show')
  onShow(value) {
    if (!value || !this.offer || !this.item) {
      return;
    }
    this.agencyMarkupValue = this.item.price.agencyMarkup.amount;
    this.initialAgencyMarkupValue = this.agencyMarkupValue;
  }
}

