


























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, numeric, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { IAccommodationProgramName, LoyaltyForm, LoyaltyType } from '@/api/profile/loyalties.model';
import { BasketItemType } from '@/api/trip/trip.model';
import { AccommodationSubscriber } from '@/const/loyalty-const';

const CarrierCode = 'Gekko';
const CardNumberFormat = {
  [AccommodationSubscriber.ibis]: '3081432',
  [AccommodationSubscriber.favorite]: '3085163',
};
const CardNumberFormatRegex = (value: string, subscriber: string) => {
  const regex = new RegExp(`^${CardNumberFormat[subscriber]}.*`);
  return regex.test(value);
};

@Component
export default class LoyaltyAccommodationForm extends Vue {
  @Prop() value!: LoyaltyForm;
  @Prop() card!: any;
  @Prop() dirty!: boolean;

  AccommodationSubscriber = AccommodationSubscriber;
  CardNumberFormat = CardNumberFormat;
  selectedProgramName: IAccommodationProgramName | null = null;
  programNameList: IAccommodationProgramName[] = [];
  programNameLoading: boolean = false;
  serverErrors: any[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      value: {
        loyaltyId: { required },
        issueDate: {
          required: requiredIf(() => this.programName && this.programName.subscriber !== AccommodationSubscriber.club),
        },
        expireDate: {
          required: requiredIf(() => this.programName && this.programName.subscriber !== AccommodationSubscriber.club),
        },
        cardNumber: {
          required,
          numeric,
          cardNumberFormat: (value) => {
            if (this.programName && this.programName.subscriber !== AccommodationSubscriber.club) {
              return value ? CardNumberFormatRegex(value, this.programName.subscriber) : true;
            }
            return true;
          },
        },
      },
    };
  }

  get programName() {
    return this.selectedProgramName;
  }
  set programName(value) {
    this.selectedProgramName = value;
    this.$emit('input', { loyaltyId: value && value.id });
  }
  get issueDate() {
    return this.value.issueDate ? moment(this.value.issueDate) : null;
  }
  set issueDate(value) {
    this.$emit('input', {
      ...this.value,
      issueDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }
  get expireDate() {
    return this.value.expireDate ? moment(this.value.expireDate) : null;
  }
  set expireDate(value) {
    this.$emit('input', {
      ...this.value,
      expireDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }

  get tomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  async created() {
    try {
      this.programNameLoading = true;
      const { data } = await LoyaltiesApi.getLoyaltiesQuery({
        serviceType: BasketItemType.Accommodation,
        loyaltyType: LoyaltyType.Loyalty,
        carrierCode: CarrierCode,
      });
      this.programNameList = data;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.programNameLoading = false;
    }
    if (this.card) {
      this.selectedProgramName = this.card.loyalty;
    }
    if (this.dirty) {
      this.$v.$touch();
    }
  }
}
