































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketStore from './basket.store';
import BasketModifyTravellersDetails from './BasketModifyTravellersDetails.vue';
import BasketModifySabreTravellersDetails from './BasketModifySabreTravellersDetails.vue';
import BasketModifyAmadeusTravellersDetails from './BasketModifyAmadeusTravellersDetails.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

@Component({
  components: {
    BasketModifyTravellersDetails,
    BasketModifySabreTravellersDetails,
    BasketModifyAmadeusTravellersDetails,
    BasketExpirationCountdown,
  }
})
export default class BasketModifyTravellersDetailsView extends Vue {
  @Prop() basketExpired!: boolean;

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'MODIFY_TRAVELLERS_DETAILS') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }

  get isAirFranceProvider() {
    if (this.item) {
      return (this.item as any).supplier === 'AirFranceKlm';
    }
    return false;
  }

  get isSabreProvider() {
    if (this.item) {
      return (this.item as any).supplier === 'Sabre';
    }
    return false;
  }

  get isAmadeusProvider() {
    if (this.item) {
      return (this.item as any).supplier === 'Amadeus';
    }
    return false;
  }

}
