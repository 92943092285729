import Preferences from '@/modules/profile/preferences/Preferences.vue';
import { translate } from '@/i18n';

export default {
  path: 'preferences',
  name: 'preferences',
  component: Preferences,
  displayName: 'profile-preferences.preferences',
  meta: {
    permission: 'ReadProfilePreferences',
    icon: 'room_preferences',
  }
};