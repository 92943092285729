import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  WonderModel,
  WonderResponseModel
} from './wonder-search.model';

class WonderSearchApiClass {
  public wonderFind(query: WonderModel): Promise<AxiosResponse<WonderResponseModel>> {
    return http.execute({
      path: settings.apiWonderSearch + '/api/wondersearch',
      method: RequestMethod.POST,
    }, {}, query);
  }
}

export const WonderSearchApi: WonderSearchApiClass = new WonderSearchApiClass();
