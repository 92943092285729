import Approvals from '@/modules/approvals/Approvals.vue';
import ApprovalsRequestsIntro from '@/modules/approvals/ApprovalsIntro.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/approvals',
  name: 'approvals',
  components: {
    default: Approvals,
    intro: ApprovalsRequestsIntro,
  },
  meta: {
    introClass: 'intro-approvals',
    permission: 'ShowPendingValidationTripList',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  }
};
