import ApprovalWorkflowConfiguration from '@/modules/settings/approval-workflow/ApprovalWorkflowConfiguration.vue';

export default {
  path: ':configurationId?/configuration',
  name: 'configuration',
  component: ApprovalWorkflowConfiguration,
  displayName: 'Approval Workflow Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadApprovalWorkflow',
    parentName: 'approval-workflow-configurations',
    depthCategory: 'approval-workflow',
    depth: 2,
  }
};