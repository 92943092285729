


















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';

@Component({})
export default class HotelFiltersSubintro extends Vue {

  hideMobileFilters() {
    if (this.$router.currentRoute.query.fromMap) {
      router.push({
        name: 'hotelMap',
        params: {
          searchId: this.$router.currentRoute.params.searchId
        }  
      });
    } else {
      router.push({
        name: 'hotel',
        params: this.$router.currentRoute.params
      });
    }
  }

}
