import BillingConfiguration from '@/modules/settings/billing/BillingConfiguration.vue';

export default {
  path: ':configurationId?/billing-configuration',
  name: 'billing-configuration',
  component: BillingConfiguration,
  displayName: 'Billing Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadBackOfficeConfiguration',
    parentName: 'billing-configurations',
    depthCategory: 'billing-configurations',
    depth: 2,
  }
};