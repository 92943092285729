

















































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';

import EventHandler from '@/services/event-handler';
import { NewRoleModel } from '@/api/roles/roles.model';
import rolesStore from './roles.store';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';

@Component({})
export default class CreateRoleForm extends Vue {
  $v;
  formPending: boolean = false;

  get errors() {
    return rolesStore.errors;
  }

  get canManageSystem() {
    return AccountStore.HasPermission('CanManageSystem');
  }

  get permissions() {
    if (!rolesStore.Permissions) {
      return [];
    }
    return rolesStore.Permissions
      .map(item => {
        return {
          ...item,
          show: !(item.name === 'System' && !this.canManageSystem),
          permissions: item.permissions.map(p => {
            return {
              ...p,
              isSelected: false,
            };
          }),
        };
      });
  }

  beforeCreate() {
    rolesStore.loadCompanyPermissions();
  }

  selectAll() {
    if (!this.permissions) {
      return;
    }
    this.permissions.forEach((section) => {
      section.permissions.forEach(p => {
        p.isSelected = true;
      });
    });
    this.$forceUpdate();
  }

  deselectAll() {
    if (!this.permissions) {
      return;
    }
    this.permissions.forEach((section) => {
      section.permissions.forEach(p => {
        p.isSelected = false;
      });
    });
    this.$forceUpdate();
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        name: { required }
      },
    };
  }

  Form: {
    name: string
  } = {
      name: ''
    };

  close() {
    this.$router.push({
      name: 'settings-roles',
    });
  }

  async submit() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }
    this.formPending = true;

    if (this.permissions != null) {
      let newRole = new NewRoleModel();
      newRole.name = this.Form.name;

      this.permissions.forEach(catPermission => {
        catPermission.permissions.filter(x => x.isSelected).forEach(perm => {
          newRole.permissions.push(perm.code);
        });
      });

      try {
        await rolesStore.addNewRole(newRole);
        EventHandler.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('common.data-saved')
        });

        this.close();
      } finally {
        this.formPending = false;
      }
    }
  }
}
