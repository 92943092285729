import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { CurrencyExchangeApi } from '@/api/profile/currency-exchange.api';
import {
  CurrencyExchangeConfiguration,
} from '@/api/profile/currency-exchange.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'currency'
})
class CurrencyExchange extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  currencyExchangeConfiguration: CurrencyExchangeConfiguration = new CurrencyExchangeConfiguration();
  errMessages: string[] = [];
  showError: boolean = false;

  get CurrencyExchangeConfiguration() {
    return this.currencyExchangeConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setCurrencyExchangeConfiguration(payload) {
    this.currencyExchangeConfiguration = payload;
  }

  @Mutation
  setName(value) {
    this.currencyExchangeConfiguration.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'currency-exchange-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }
  
  @Action
  clearData() {
    this.setCurrencyExchangeConfiguration(new CurrencyExchangeConfiguration());
    this.setCurrentConfigurationName('');
  }

  @Action
  async getCurrencyExchangeConfiguration(configurationId) {
    try {
      this.setLoading(true);
      this.setShowError(false);
      if (configurationId) {
        const result = await CurrencyExchangeApi.getConfiguration(configurationId);
        if (result && result.data) {
          this.setCurrencyExchangeConfiguration(result.data);
        }
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addCurrencyExchangeConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result: any = await CurrencyExchangeApi.createConfiguration(request);
      if (result && result.data) {
        this.setConfigurationId(result.data.configurationId);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-currency-exchange.config-saved')
        });

        this.getCurrencyExchangeConfiguration(result.data.configurationId);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCurrencyExchangeConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await CurrencyExchangeApi.updateConfiguration(data.configurationId, data.params);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-currency-exchange.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(CurrencyExchange);
