



















































import { Vue, Component } from 'vue-property-decorator';
import ExpenseStore from '../expense.store';
import { NotificationModel } from '@/api/expense/expense.model';
import NotificationItem from '@/modules/expense/notifications/NotificationItem.vue';
import { Permission } from '@/const/permission.enum';

@Component({
  components: {
    NotificationItem,
  },
})
export default class Notifications extends Vue {
  loading: boolean = false;
  loadingForm: boolean = false;
  shouldShowMoreDetails: boolean = false;
  notificationItem: NotificationModel | null = null;

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get notifications() {
    return ExpenseStore.notifications;
  }

  async created() {
    this.loading = true;
    await ExpenseStore.getDefaultExpensePolicy(Permission.ReadNotifications);
    await ExpenseStore.getNotifications();
    this.loading = false;
  }

  async refreshList() {
    this.loadingForm = true;
    await ExpenseStore.getDefaultExpensePolicy(Permission.ReadNotifications);
    await ExpenseStore.getNotifications();
    this.loadingForm = false;
  }
  
}

