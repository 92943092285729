





























































































import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class ExpenseCustomFields extends Vue {
  @Prop() customFields!: any;
  @Prop() v!: Object;
  @Prop() disabled!: boolean;
}

