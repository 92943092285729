


















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';

@Component({})
export default class CarFiltersSubintro extends Vue {

  hideMobileFilters() {
    router.push({
      name: 'car',
    });
  }

}
