

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  filters: {
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
  }
})
export default class BasketFlightPart extends Vue {
  @Prop() flight!: any;
}

