





























































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ApprovalWorkflowConfigurationsDataRow } from './approvalWorkflowConfigurations.model';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import ApprovalWorkflowStore from '@/modules/settings/approval-workflow/approval-workflow.store';
import SettingsStore from '@/modules/settings/settings.store';
import EventHandler from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class ApprovalWorkflowConfigurations extends BaseTable<ApprovalWorkflowConfigurationsDataRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  serverErrorsMessages = {
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  serverErrors: any = [];
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  get addNewUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/approval-workflow/configuration');
  }

  getAWUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/approval-workflow/' + item.id + '/configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;

      if (!preventRedirect && this.$hasAccess('WriteApprovalWorkflow')) {
        router.push({
          name: 'configuration',
          params: {
            configurationId: item.id
          }
        });
      } else if (!preventRedirect && !this.$hasAccess('WriteApprovalWorkflow')) {
        router.push({
          name: 'configuration',
          params: {
            configurationId: item.id
          },
          query: {
            readOnly: 'true'
          }
        });
      }
    }
  }

  addNewConfiguration() {
    this.resetData();
    router.push({
      name: 'configuration',
    });
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  copy(item) {
    if (item) {
      this.resetData();
      this.configuration = item;
      ApprovalWorkflowStore.setCopy(true);
      ApprovalWorkflowStore.getApprovalWorkflowConfiguration(item.id);
      router.push({
        name: 'configuration',
      });
    }
  }

  resetData() {
    ApprovalWorkflowStore.clearData();
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.WriteApprovalWorkflow);
      this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-approval.configuration-removed'),
        message: translate('settings-approval.info-removed')
      });
    }
    catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    }
    finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    this.serverErrors = [];
    let request = {
      searchName: this.Form.searchName,
    };

    try {
      this.loading = true;
      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.approvalWorkflowConfiguration,
        this.params.size,
        this.params.page,
        Permission.ReadApprovalWorkflow,
        request.searchName
      );

      if (result && result.data) {
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  toggleApprovalWorkflowConfigurationMenu(item) {
    if (this.configuration !== item) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }
}

