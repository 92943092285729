import CarProviders from '@/modules/settings/configurations/CarProviders.vue';
import carProvidersList from './car-providers/car-providers-list.route';
import newCarProvider from './car-providers/new-car-provider.route';
import editCarProvider from './car-providers/edit-car-provider.route';
import { translate } from '@/i18n';

export default {
  path: 'car-provider-configurations',
  component: CarProviders,
  displayName: 'settings-sidebar.car-providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCarProviders',
    icon: 'directions_car',
    parentName: 'car-provider-configurations',
  },
  children: [
    carProvidersList,
    newCarProvider,
    editCarProvider,
  ],
};
