























































































































import { Vue, Component } from 'vue-property-decorator';
import ProjectsStore from './projects.store';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import ProjectDefinitions from './ProjectDefinitions.vue';

@Component({
  components: {
    ProjectDefinitions,
  }
})
export default class ProjectsConfiguration extends Vue {
  $v;
  formHasErrors: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      configuration: {
        name: {
          required,
          maxLength: maxLength(64)
        }
      }
    };
  }

  get errors() {
    return ProjectsStore.errMessages;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ProjectsStore.project;
  }

  get loading() {
    return ProjectsStore.loading;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get configIsEdit() {
    return !!this.configuration.id;
  }

  get canShowCustomError() {
    return ProjectsStore.canShowCustomError;
  }

  returnToConfigurations() {
    router.push({
      name: 'projects-configurations'
    });
  }

  addProjectsDefinition() {
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      return;
    }
    if (this.configuration.name) {
      ProjectsStore.setName(this.configuration.name);
    }
    router.push({
      name: 'definition',
      params: {
        configurationId: this.$route.params.configurationId
      }
    });
  }

  copyConfiguration() {
    let formHasErrors = false;
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      formHasErrors = true;
    }
    if (!formHasErrors) {
      ProjectsStore.copyProjectConfiguration({ configurationId: this.configurationId, configurationName: this.configuration.name });
      if (!this.errors.length) {
        const obj = {
          type: translate('common.success'),
          title: translate('settings-projects.configuration-copied'),
          message: translate('settings-projects.configuration-copied-message')
        };
        EventHandler.$emit('show-toast', obj);

        this.returnToConfigurations();
      }
    }
  }

  saveData() {
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      return;
    }
    if (this.configIsEdit) {
      ProjectsStore.updateProjectConfiguration({
        configurationId: this.configurationId,
        name: this.configuration.name
      });
    } else {
      ProjectsStore.createProjectConfiguration({
        rootCompanyId: this.currentCompany!.rootCompanyId,
        name: this.configuration.name,
      });
    }
  }

  async created() {
    ProjectsStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.isCopy) {
        try {
          if (!Object.keys(ProjectsStore.Project).length || this.$route.params.configurationId !== ProjectsStore.Project.id) {
            await ProjectsStore.getProjectConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
          }
        } catch (error) {
          ProjectsStore.setErrMessages(this.$handleErrors(error, true));
          ProjectsStore.setShowError(true);
        }
      }
    }
  }
}
