












import { Vue, Component } from 'vue-property-decorator';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AccountStore from '@/store/account.store';
import AirCompareLocalStore from '@/modules/search/air/air-compare.local-store';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class CompareOfferBox extends Vue {

  get templateOffers() {
    if (this.localStore && this.localStore.length) {
      return this.localStore;
    } else {
      return AirSearchStore.templateOffers;
    }
  }

  get templateOffersCount() {
    if (this.templateOffers.length) {
      return translate('search-air.selected-offers') + ' ' + this.templateOffers.length;
    }
    return '';
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get filters() {
    return AirSearchStore.filters;
  }

  get showTemplateOffersModal() {
    return AirSearchStore.showTemplateOffersModal;
  }

  get canShowBox() {
    return -1 !== [
      'airDetails',
      'airResultsTravellersList',
      'airFilters',
      'airModificationTravellers',
      'airModification',
      'airResultDetails',
      'airTravellers',
      'air',
    ].indexOf(this.$route.name || '') && this.filters && this.filters.length > 0 && !this.showTemplateOffersModal;
  }

  showCompareOffer() {
    EventBus.$emit('prepare-offer', true);
  }

  unselectCompareOffer() {
    AirSearchStore.setTemplateOffers([]);
    localStorage.removeItem('Air_compare_offer');
    AirCompareLocalStore.resetLocalStore();
    EventBus.$emit('template-offer', true);
    this.getLocalStore();
  }

  getLocalStore() {
    AirCompareLocalStore.getLocalStore();
  }

  created() {
    this.getLocalStore();
  }
}
