
























































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';

import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

@Component({})
export default class TravellerCategories extends Vue {
  imagesConst: string = '/assets/img/loader/1.gif';
  loadingPage: boolean = true;
  travellerCategories: any = [];
  errors: any = [];
  saving: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      travellerCategories: {
        $each: {
          name: {
            maxLength: maxLength(100),
            required,
          },
        },
      }
    };
  }

  removeCategory(index) {
    this.travellerCategories.splice(index, 1);
  }

  addCategory() {
    this.travellerCategories.push({
      name: '',
    });
  }

  async saveCategories() {
    if (this.$v.travellerCategories.$error) {
      this.$v.travellerCategories.$touch();
      return;
    }
    this.errors = [];
    this.saving = true;
    try {
      await TravellerCategoriesApi.saveCompanyTravellerCategories(this.$route.params.id, this.travellerCategories);
      
      EventBus.$emit('show-toast', {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('common.data-saved'),
      });
    } catch (error) {
      if (
        error.response.data.error &&
        error.response.data.error.code === 'TRAVELLER_CATEGORY_IN_USE'
      ) {
        this.errors = [{
          message: translate('settings-traveller-categories.traveller-category-is-used'),
        }];
        this.loadData();
      } else {
        this.errors = this.$handleErrors(error);
      }
    } finally {
      this.saving = false;
    }
  }

  async loadData() {
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.$route.params.id);

      this.travellerCategories = response.data;
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loadingPage = false;
    }
  }

  created() {
    this.loadData();
  }
}
