































































































































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator';
import { translate } from '@/i18n';
import SearchStore from '@/modules/search/search.store';
import _ from 'lodash';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import UiGuestTravellersTravellerRoom from './UiGuestTravellersTravellerRoom.vue';

@Component({
  components: {
    UiGuestTravellersTravellerRoom,
  },
})
export default class UiGuestTravellersRoomsSelect extends Vue {
  @Model('change') modelValue!: any;
  @Prop({ default: () => {
    return [];
  } }) travellers!: any[];
  @Prop() disabled!: boolean;
  cleanOptions: any = null;
  travellersSum: number = 0;
  travellersSumTemp: number = 0;
  showFull: boolean = false;
  isInPopup: boolean = false;
  isActive: boolean = false;
  popupContainer: HTMLElement | null = null;
  blurTimeout: number = -1;
  touchedIndex: number = -1;
  rooms: any[] = [];

  get initialTravellersList() {
    return SearchStore.getTravellersState;
  }

  get wrapperClasses() {
    return {
      'ui-travellers-select--show-full': this.showFull,
      'disabled': this.disabled,
    };
  }

  get currentRoomNumber() {
    return this.modelValue
      .filter(item => !!item.profiles.length)
      .length;
  }

  get currentTravellersNumber() {
    return this.modelValue
      .reduce((prev, cur) => {
        return prev + cur.profiles.length;
      }, 0);
  }

  get error() {
    return this.currentTravellers.length >= 10;
  }

  get currentTravellers() {
    return this.rooms.reduce((prev, cur) => {
      return [...prev, ...cur.profiles];
    }, []);
  }

  get hasChildrenWithoutAge() {
    const children = this.currentTravellers
      .filter(i => i.passengerTypeCode === 'CHD');

    return !(!children.length || !children.find(i => {
      return i.childAge == null;
    }));
  }

  get hasErrors() {
    return !this.currentTravellers.length || this.hasChildrenWithoutAge;
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }


  get mobileClasses() {
    return {
      'ui-autocomplete__mobile--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile--has-values': 
        this.modelValue && this.modelValue.length,
      'ui-autocomplete__mobile--active': this.isActive,
    };
  }



  mobileFocus() {
    if (this.disabled) {
      return;
    }
    clearTimeout(this.blurTimeout);
    this.isActive = true;
    this.cleanOptions = _.cloneDeep(this.rooms);

    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  mobileClick() {
    if (!this.isActive) {
      this.$nextTick(() => {
        return this.$refs.uiTravellerMobileInput &&
          (this.$refs.uiTravellerMobileInput as HTMLInputElement).focus();
      });
    }
  }

  onClick(e: Event) {
    if (this.disabled) {
      return;
    }
    this.showFull = true;

    const target = e.target as HTMLElement;
    if (target.className.includes('fake-input')) {
      this.cleanOptions = _.cloneDeep(this.rooms);
      return;
    }
  }

  applyOptions() {
    this.$emit('change', _.cloneDeep(this.rooms));
    this.showFull = false;
  }

  applyOptionsMobile() {
    this.$emit('change', _.cloneDeep(this.rooms));
    this.isActive = false;
  }

  cancel() {
    this.rooms = _.cloneDeep(this.cleanOptions);
    this.showFull = false;
    this.isActive = false;
  }

  windowOnClick(e) {
    if (window.innerWidth < 800) {
      return;
    }
    let target = e.target as HTMLInputElement;

    if (
      this.showFull &&
      !this.$el.contains(target) &&
      (target && target.offsetParent && !target.offsetParent.className.includes('ui-travellers-select__class-box'))
    ) {
      setTimeout(() => {
        if (this.hasErrors) {
          this.cancel();
          return;
        }
        this.applyOptions();
      });
    }
  }

  addRoom() {
    this.rooms.push({
      profiles: [{
        isVirtual: true,
        passengerTypeCode: 'ADT',
        childAge: null,
        age: null,
      }],
    });
  }

  removeRoom(index) {
    this.rooms.splice(index, 1);
  }

  checkGuestRooms() {
    let checkedPositive = false;
    if (this.modelValue && this.modelValue.length) {
      const numberOfProfiles = this.modelValue.reduce((prev, cur) => {
        return cur.profiles.length + prev;
      }, 0);
      if (this.travellers && numberOfProfiles === this.travellers.length) {
        const roomOptionsTravellers = this.modelValue.reduce((prev, cur) => {
          return [...prev, ...cur.profiles];
        }, []);
        const adultNumber = roomOptionsTravellers
          .filter(i => i.passengerTypeCode === 'ADT')
          .length;
        const childNumber = roomOptionsTravellers
          .filter(i => i.passengerTypeCode === 'CHD')
          .length;

        const adultTravellers = this.travellers
          .filter(i => i.passengerTypeCode === 'ADT')
          .length;
        const childTravellers = this.travellers
          .filter(i => i.passengerTypeCode === 'CHD')
          .length;

        if (
          adultNumber === adultTravellers &&
          childNumber === childTravellers
        ) {
          checkedPositive = true;
          this.rooms = _.cloneDeep(this.modelValue);
        }
      }
    }

    if (!checkedPositive && this.travellers.length) {
      // create rooms basing on travellers list
      const adultProfiles = this.travellers
        .filter(profile => {
          const age = profile.age;

          return (age && age >= 18) ||
            -1 < ['ADT', 'SRC', 'SEA'].indexOf(profile.passengerTypeCode);
        });
      const otherProfiles = this.travellers
        .filter(profile => !adultProfiles.includes(profile))
        .map(profile => {
          const age = profile.age;

          return {
            ...profile,
            passengerTypeCode: 'CHD',
            childAge: age,
            age,
          };
        });
      
      let rooms: any[] = [];
      if (adultProfiles.length === 0) {
        rooms = [{
          profiles: [],
        }];
      } else if (adultProfiles.length <= 3) {
        rooms = adultProfiles.map(profile => {
          return {
            profiles: [{
              ...profile,
              passengerTypeCode: 'ADT',
              childAge: null,
              age: null,
            }],
          };
        });
      } else {
        rooms = [{
          profiles: [],
        }, {
          profiles: [],
        }, {
          profiles: [],
        }];

        adultProfiles.forEach((profile, index) => {
          const idx = index % rooms.length;
          rooms[idx].profiles.push(profile);
        });
      }

      otherProfiles.forEach((profile, index) => {
        const idx = index % rooms.length;
        rooms[idx].profiles.push(profile);
      });
      const travellers = rooms.reduce((prev, cur) => {
        return [...prev, ...cur.profiles];
      }, []);
      SearchStore.updateEditedTravellers({
        travellers,
      });
      this.$emit('change', rooms);
      this.rooms = _.cloneDeep(rooms);
    } else if (!checkedPositive) {
      const rooms: any[] = [{
        profiles: [{
          passengerTypeCode: 'ADT',
          isVirtual: true,
          isMainTraveller: true,
          childAge: null,
          age: null,
        }],
      }];

      const travellers = rooms.reduce((prev, cur) => {
        return [...prev, ...cur.profiles];
      }, []);

      SearchStore.updateEditedTravellers({
        travellers,
      });
      this.$emit('change', rooms);
      this.rooms = _.cloneDeep(rooms);
    }
  }

  @Watch('hasChildrenWithoutAge', { immediate: true })
  onValidate(value) {
    this.$emit('invalid', value);
  }

  @Emit('change')
  onChange(value) {
    return value;
  }

  onFocus() {
    if (this.disabled) {
      return;
    }
    this.showFull = true;
    this.cleanOptions = _.cloneDeep(this.rooms);
  }

  created() {
    this.checkGuestRooms();
    window.addEventListener('click', this.windowOnClick);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.windowOnClick);
  }
}
