

































































































































































































































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { TrainSearchStateParams } from './train-search.params';
import { TravellersStateParams } from '../travellers.params';
import SearchStore from '@/modules/search/search.store';
import LayoutStore from '@/modules/layout/layout.store';
import TrainSearchStore from '@/modules/search/train/train-search.store';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';
import RailTrenitaliaExchangeModifyForm from '@/modules/basket/rail/RailTrenitaliaExchangeModifyForm.vue';
import RailSncfExchangeModifyForm from '@/modules/basket/rail/RailSncfExchangeModifyForm.vue';
import RailNtvExchangeModifyForm from '@/modules/basket/rail/RailNtvExchangeModifyForm.vue';
import TrainExchangePricingForm from './TrainExchangePricingForm.vue';
import EventBus from '@/services/event-handler';
import TrainResultsBasketRow from '@/modules/search/train/TrainResultsBasketRow.vue';
import { TrainExchangeApi } from '@/api/train-engine/train-exchange.api';
import AccountStore from '@/store/account.store';

@Component({
  components: {
    SearchTripTravellers,
    RailTrenitaliaExchangeModifyForm,
    RailSncfExchangeModifyForm,
    TrainResultsBasketRow,
    TrainExchangePricingForm,
    RailNtvExchangeModifyForm,
  }
})
export default class TrainSearchParamsOnResults extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  @Prop() trainSearchParameters!: TrainSearchStateParams;

  fromFirstVisible: boolean = false;
  toFirstVisible: boolean = false;
  fromSecondVisible: boolean = false;
  toSecondVisible: boolean = false;
  showModifyPopup: boolean = false;
  showCheckPriceTrenitaliaPopup: boolean = false;
  trenitaliaPricingPopupTitle: string = '';

  get templateOffers() {
    return [];
  }

  get legNumber() {
    return this.$route.params.leg ? parseInt(this.$route.params.leg as string) : 1;
  }

  get wasSelectedFirstLeg() {
    return TrainSearchStore.wasSelectedFirstLeg;
  }

  get user() {
    return AccountStore.current;
  }

  get dateFormat() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return 'D MMMM';
    }

    return 'Do MMMM';
  }

  get departureDate() {
    return this.modifyMode && this.trainSearchParameters.legs[0] ? 
      moment(
        this.trainSearchParameters.legs[0].date,
        'YYYY-MM-DD',
      ).format(this.dateFormat)
    : moment(
      this.trainSearchParameters.departureDate,
      'YYYY-MM-DD'
    ).format(this.dateFormat);
  }

  get departureDay() {
    return this.modifyMode && this.trainSearchParameters.legs[0] ? 
      moment(
        this.trainSearchParameters.legs[0].date,
        'YYYY-MM-DD',
      ).format('dddd')
    : moment(
      this.trainSearchParameters.departureDate,
      'YYYY-MM-DD'
    ).format('dddd');
  }

  get returnDate() {
    return this.modifyMode && this.trainSearchParameters.legs[1] ?
    moment(
        this.trainSearchParameters.legs[1].date,
        'YYYY-MM-DD',
      ).format(this.dateFormat)
    : moment(
      this.trainSearchParameters.returnDate,
      'YYYY-MM-DD'
    ).format(this.dateFormat);
  }

  get returnDay() {
    return this.modifyMode && this.trainSearchParameters.legs[1] ?
    moment(
        this.trainSearchParameters.legs[1].date,
        'YYYY-MM-DD',
      ).format('dddd')
    : moment(
      this.trainSearchParameters.returnDate,
      'YYYY-MM-DD'
    ).format('dddd');
  }

  get isStationFromNull() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.from
    ) {
      return this.trainSearchParameters.from.stationName === null;
    }

    return false;
  }

  get isStationToNull() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.to
    ) {
      return this.trainSearchParameters.to.stationName === null;
    }

    return false;
  }

  get placeCodeFrom() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.from
    ) {
      let code = this.trainSearchParameters.from.countryCode;

      return (
        code
      );
    }

    return '';
  }

  get placeCodeTo() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.to
    ) {
      let code = this.trainSearchParameters.to.countryCode;

      return (
        code
      );
    }

    return '';
  }

  get placeFrom() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.from
    ) {
      let from = this.trainSearchParameters.from.type === 'City' ? this.trainSearchParameters.from.cityName : this.trainSearchParameters.from.stationName;

      return (
        from
      );
    }

    return '';
  }

  get placeFromExchange() {
    if (this.modifyMode && this.trainSearchParameters.legs[0]) {
      return this.trainSearchParameters.legs[0].from.type === 'City' ? this.trainSearchParameters.legs[0].from.cityName : this.trainSearchParameters.legs[0].from.stationName;
    } else {
      return '';
    }
  }

  get returnPlaceFromExchange() {
    if (this.modifyMode && this.trainSearchParameters.legs[1]) {
      return this.trainSearchParameters.legs[1].from.type === 'City' ? this.trainSearchParameters.legs[1].from.cityName : this.trainSearchParameters.legs[1].from.stationName;
    } else {
      return '';
    }
  }

  get placeTo() {
    if (
      this.trainSearchParameters &&
      this.trainSearchParameters.to
    ) {
      let to = this.trainSearchParameters.to.type === 'City' ? this.trainSearchParameters.to.cityName : this.trainSearchParameters.to.stationName;

      return (
        to
      );
    }

    return '';
  }

  get placeToExchange() {
    if (this.modifyMode && this.trainSearchParameters.legs[0]) {
      return this.trainSearchParameters.legs[0].to.type === 'City' ? this.trainSearchParameters.legs[0].to.cityName : this.trainSearchParameters.legs[0].to.stationName;
    } else {
      return '';
    }
  }

  get returnPlaceToExchange() {
    if (this.modifyMode && this.trainSearchParameters.legs[1]) {
      return this.trainSearchParameters.legs[1].to.type === 'City' ? this.trainSearchParameters.legs[1].to.cityName : this.trainSearchParameters.legs[1].to.stationName;
    } else {
      return '';
    }
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get modifySearchLoaded() {
    return TrainSearchStore.exchangeLoaded;
  }

  get modifyItem() {
    return {
      ...TrainSearchStore.exchangedOffer,
      id: TrainSearchStore.exchangedTripItemId,
      providerReferenceId: TrainSearchStore.exchangedRecommendationId,
      recommendationId: TrainSearchStore.exchangedRecommendationId,
    };
  }

  get isNtv() {
    return this.modifyItem.supplier === 'Ntv';
  }

  get isTrenitalia() {
    return this.modifyItem.supplier === 'Trenitalia';
  }

  get isSncf() {
    return this.modifyItem.supplier === 'Sncf';
  }

  get isOuigo() {
    return this.modifyItem.supplier === 'Ouigo';
  }

  get basketId() {
    if (!this.modifyMode) {
      return '';
    }
    return this.$route.params.basketId;
  }

  get tripTravellers() {
    return this.travellersSearchList.travellers;
  }

  get modifyItemAdjusted() {
    if (!this.modifyItem) {
      return null;
    }

    return {
      price: {
        price: this.modifyItem.totalPrice,
        agencyFee: this.modifyItem.agencyFee,
        agencyMarkup: this.modifyItem.agencyMarkup,
        systemMarkup: this.modifyItem.systemMarkup,
        rawProviderPrice: this.modifyItem.grossRawProviderPrice,
        providerPrice: this.modifyItem.providerPrice,
      },
    };
  }

  hideRailModifyModal() {
    this.showModifyPopup = false;
  }

  toggleSidebar() {
    LayoutStore.toggleSidebar();
  }

  showExchangePopup() {
    this.showModifyPopup = true;
  }

  criteriaClick() {
    if (this.modifyMode) {
      this.showExchangePopup();
      return;
    }
    this.criteria();
  }

  showCheckPricePopup() {
    this.trenitaliaPricingPopupTitle = '';
    this.showCheckPriceTrenitaliaPopup = true;
  }

  hideCheckPriceTrenitaliaPopup() {
    const searchId = this.$route.params.searchId;
    const legNumber = this.$route.params.leg;
    TrainExchangeApi.deleteLeg(searchId, legNumber);
    this.showCheckPriceTrenitaliaPopup = false;
    TrainSearchStore.setSelectingOffer(false);
    TrainSearchStore.setSelectedOfferId('');
  }

  checkItemWidth() {
    const FromFirst = this.$refs.placeFromFirstWith as HTMLElement;
    const ToFirst = this.$refs.placeToFirstWith as HTMLElement;
    const FromSecond = this.$refs.placeToSecondWith as HTMLElement;
    const ToSecond = this.$refs.placeFromSecondWith as HTMLElement;

    if (FromFirst && FromFirst.clientWidth > 180) {
      this.fromFirstVisible = true;
    } else {
      this.fromFirstVisible = false;
    }

    if (ToFirst && ToFirst.clientWidth > 180) {
      this.toFirstVisible = true;
    } else {
      this.toFirstVisible = false;
    }

    if (FromSecond && FromSecond.clientWidth > 180) {
      this.fromSecondVisible = true;
    } else {
      this.fromSecondVisible = false;
    }

    if (ToSecond && ToSecond.clientWidth > 180) {
      this.toSecondVisible = true;
    } else {
      this.toSecondVisible = false;
    }
  }

  @Watch('trainSearchParameters', { deep: true, immediate: true})
  onPlaceShow() {
    this.$nextTick(
      this.checkItemWidth
    );
  }

  @Emit()
  travellers() {}

  @Emit()
  criteria() {}

  created() {
    EventBus.$on('show-train-exchange-popup', this.showExchangePopup);
    EventBus.$on('show-check-price', this.showCheckPricePopup);
  }

  beforeDestroy() {
    EventBus.$off('show-train-exchange-popup', this.showExchangePopup);
    EventBus.$off('show-check-price', this.showCheckPricePopup);
  }
}
