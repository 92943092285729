import CommunityProfiles from '@/modules/settings/community/CommunityProfiles.vue';

export default {
  path: '',
  name: 'community',
  component: CommunityProfiles,
  displayName: 'Community',
  meta: {
    introClass: 'intro-settings',
    fluid: true,
    permission: 'ReadCommunity',
    depthCategory: 'settings-community',
    depth: 1,
    parentName: 'community',
  }
};
