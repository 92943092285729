import Vue from 'vue';

import controls from '@/controls';

export default () => {
  Object.keys(controls).forEach(key => {
    const control = controls[key];
    Vue.component(key, control);
  });
};
