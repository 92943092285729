import CustomersList from '@/modules/settings/customers/CustomersList.vue';

export default {
  path: '',
  name: 'customers',
  component: CustomersList,
  displayName: 'Customers',
  meta: {
    introClass: 'intro-settings',
    fluid: true,
    permission: 'ReadAgencyCustomers',
    depthCategory: 'settings-customers',
    depth: 1,
    parentName: 'Customers',
  }
};
