import settings from '@/settings';
import AccountStore from '@/store/account.store';

export default {
  links: [
    {
      path: '/',
      name: 'home',
      canShow: true
    },
    {
      path: '/trips',
      name: 'trips',
      meta: {
        permission: 'ShowTripList',
      },
      canShow: true
    }, 
    {
      path: '/approvals',
      name: 'approvals',
      meta: {
        permission: 'ShowPendingValidationTripList',
      },
      canShow: true
    },
    {
      path: '/bookings',
      name: 'bookings',
      meta: {
        permission: 'ShowTripList',
      },
      canShow: true
    },
    {
      path: '/my-pools',
      name: 'my-pools',
      meta: {
        permission: 'ReadCompanyStructure',
      },
      canShow: () => 'true' === settings.enableExternalSalesPictures
    },      
    {
      path: '/offline',
      name: 'offline',
      meta: {
        permission: 'Client',
      },
      canShow: () => 'true' === settings.enableExternalOffline
    },
    {
      path: '/expenses/missions',
      name: 'expense',
      meta: {
        permission: 'ReadMissionDefinition',
      },
      canShow: () => AccountStore.isExpenseModuleEnabled,
    },
    {
      path: '/notifications',
      name: 'notifications',
      meta: {
        permission: 'ReadEventNotification',
      },
      canShow: true,
    },
    {
      path: '/settings',
      name: 'settings',
      meta: {
        permission: 'AccessSettings'
      },
      canShow: true
    }
  ]
};
