import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  RoleModel,
  RoleDetailsModel,
  NewRoleModel,
  ProfileRoleModel,
  NewProfileRoleModel,
  RoleEditModel,
  UpdateRoleModel,
  RoleScopeModel,
  RolesModel,
  RoleModelScope
} from '@/api/roles/roles.model';

class RolesApiClass {
  public getPermissions(): Promise<AxiosResponse<RoleDetailsModel[]>> {
    return http.execute({
      path: settings.apiPermissions + `/api/permissions`,
      method: RequestMethod.GET
    }, {}, {});
  }

  public getCompanyRolesWithScopesForSelect(companyId: string): Promise<AxiosResponse<RoleModelScope>> {
    return http.execute({
      path: settings.apiPermissions + `/api/v2/roles/select?companyId=${companyId}`,
      method: RequestMethod.GET
    }, {}, {});
  }

  public getRolePermissionsById(roleId: string): Promise<AxiosResponse<RoleDetailsModel[]>> {
    return http.execute({
      path: settings.apiPermissions + `/api/roles/${roleId}/permissions`,
      method: RequestMethod.GET
    }, {}, {});
  }

  public getRoleById(roleId: string): Promise<AxiosResponse<RoleEditModel>> {
    return http.execute({
      path: settings.apiPermissions + `/api/roles/${roleId}`,
      method: RequestMethod.GET
    }, {}, {});
  }

  public addNewRole(role: NewRoleModel): Promise<AxiosResponse<RoleModel>> {
    return http.execute({
      path: settings.apiPermissions + `/api/roles`,
      method: RequestMethod.POST
    }, {}, role);
  }

  public updateRole(roleId: string, role: UpdateRoleModel): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiPermissions + `/api/roles/${roleId}`,
      method: RequestMethod.PUT
    }, {}, role);
  }

  public removeRole(roleId: string): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiPermissions + `/api/roles/${roleId}`,
      method: RequestMethod.DELETE
    }, {}, {});
  }

  public getProfileRoles(profileId: string): Promise<AxiosResponse<ProfileRoleModel[]>> {
    return http.execute({
      path: settings.apiPermissions + `/api/profiles/${profileId}/roles`,
      method: RequestMethod.GET
    }, {}, {});
  }

  public addNewRoleToProfile(profileId: string, role: NewProfileRoleModel): Promise<AxiosResponse<ProfileRoleModel[]>> {
    return http.execute({
      path: settings.apiPermissions + `/api/profiles/${profileId}/roles`,
      method: RequestMethod.POST
    }, {}, role);
  }

  public getRoleDetails(profileId: string, roleId: string): Promise<AxiosResponse<ProfileRoleModel[]>> {
    return http.execute({
      path: settings.apiPermissions + '/api/profiles/{profileId}/roles/{roleId}',
      method: RequestMethod.GET
    }, { profileId, roleId }, {});
  }

  public updateRoleToProfile(profileId: string, roleId: string, role: RoleScopeModel): Promise<AxiosResponse<ProfileRoleModel[]>> {
    return http.execute({
      path: settings.apiPermissions + '/api/profiles/{profileId}/roles/{roleId}',
      method: RequestMethod.PUT
    }, { profileId, roleId }, role);
  }

  public removeRoleFromProfile(profileId: string, roleId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiPermissions + `/api/profiles/${profileId}/roles/${roleId}`,
      method: RequestMethod.DELETE
    }, {}, {});
  }

  public getCompanyRoles(companyId: string, pageSize: number, pageNo: number, phrase: string): Promise<AxiosResponse<RolesModel>> {
    return http.execute({
      path: settings.apiPermissions + '/api/v2/roles?companyId={companyId}&pageSize={pageSize}&pageNo={pageNo}&phrase={phrase}',
      method: RequestMethod.GET
    }, {
      companyId,
      pageSize,
      phrase,
      pageNo,
    }, {});
  }
}

export const RolesApi: RolesApiClass = new RolesApiClass();
