import ProfileSyncConfigurations from '@/modules/settings/profile-sync/ProfileSyncConfigurations.vue';

export default {
  path: '',
  name: 'profile-sync-configurations',
  component: ProfileSyncConfigurations,
  displayName: 'Profile Synchronization Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProfilesSynchronization',
    depthCategory: 'profile-sync-configurations',
    parentName: 'profile-sync-configurations',
    depth: 1,
  }
};