import Theme from '@/modules/settings/theme/Theme.vue';
import ThemeConfigurations from './theme-configurations.route';
import ThemeConfiguration from './theme-configuration.route';

export default {
  path: 'theme',
  component: Theme,
  displayName: 'settings-sidebar.tmc-environment',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyTheme',
    icon: 'style',
    breadCrumb: 'Theme',
    parentName: 'theme-configurations',
  },
  children: [
    ThemeConfigurations,
    ThemeConfiguration,
  ],
};