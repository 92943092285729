






import { Vue, Component } from 'vue-property-decorator';

import ApprovalsTable from './ApprovalsTable.vue';

@Component({
  components: {
    ApprovalsTable,
  },
})
export default class Approvals extends Vue {}

