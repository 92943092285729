















































































































































import { Component } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';
import { router } from '@/router';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { ExpenseApi } from '@/api/expense/expense.api';
import { ExpenseTableParams } from './expense-table.params';
import { translate } from '@/i18n';
import ExpenseStore from '../expense.store';
import { ExpenseResult, ExpenseState } from '@/api/expense/expense.model';
import { Permission } from '@/const/permission.enum';

const sourceTypeIcon = {
  ManualEntry: 'account_balance_wallet',
  OcrReceipt: 'account_balance_wallet', 
  TripItem: 'card_travel', 
  BankTransaction: 'payments',
};

@Component({})
export default class ExpenseTable extends BaseTable<ExpenseResult> {
  useQueryParams: boolean = true;
  params: ExpenseTableParams = new ExpenseTableParams({});
  fields = {
    type: {
      label: translate('expense-list.type'),
      class: 'expense-table__column--type',
    },
    name: {
      label: translate('expense-list.name'),
      class: 'expense-table__column--name',
    },
    state: {
      label: translate('expense-list.state'),
      class: 'expense-table__column--state',
    },
    incurrenceDate: {
      label: translate('expense-list.date'),
      class: 'expense-table__column--date',
    },
    merchant: {
      label: translate('expense-list.merchant'),
      class: 'expense-table__column--merchant',
    },
    sourceAmount: {
      class: 'expense-table__column--total-price',
      label: translate('expense-list.source-amount'),
    },
    mission: {
      class: 'expense-table__column--mission',
      label: translate('expense-list.mission'),
    },
    createdDate: {
      class: 'expense-table__column--created-date',
      label: translate('expense-list.created-date'),
    },
    actionsColumn: {
      label: '',
      class: 'expense-table__column--actions',
    },
  };
  menuActive: boolean = false;
  errors: any[] = [];
  expense: ExpenseResult | null = null;
  deleteExpensePopup: boolean = false;
  expenseRemovalErrorMessage: string | null = null;
  expenseToRemove: ExpenseResult | null = null;
  formPending: boolean = false;

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get companyId() {
    return ExpenseStore.companyId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get expenseList() {
    return ExpenseStore.expenseList;
  }

  get errMessages() {
    return ExpenseStore.errMessages;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }

  async getItems(params: ExpenseTableParams): Promise<GetItemsResult<ExpenseResult>> {
    ExpenseStore.clearErrMessages();
    let missionId = this.$route.params.missionId;
    await ExpenseStore.getDefaultExpensePolicy(Permission.ReadExpense);

    let searchParams = {
      filters: {
        reporterId: this.currentProfileId,
        policyId: this.policyId,
        missionId: missionId,
        companyId: params.companyId,
        businessUnitId: params.businessUnitId
      },
      paging: {
        offset: params.start,
        size: params.size,
      }
    };
    await ExpenseStore.getExpenseList(searchParams);
    return {
      page: {
        pageSize: params.size,
        moreResultsAvailable: false,
        pageNo: 1
      },
      results: this.expenseList,
    };
  }

  toggleExpenseMenu(item) {
    if (this.expense !== item) {
      this.expense = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  modifyExpense(item) {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: item.id,
      },
    });
  }

  initRemoveExpense(expense) {
    this.deleteExpensePopup = true;
    this.expenseToRemove = expense;
    this.menuActive = false;
    this.expenseRemovalErrorMessage = null;
  }

  async removeExpense() {
    if (this.expenseToRemove) {
      try {
        this.formPending = true;
        await ExpenseApi.removeExpenseItem(this.expenseToRemove.id);
        this.deleteExpensePopup = false;
        let obj = {
          type: translate('common.success'),
          title: translate('expense-list.remove-expense-title'),
          message: translate('expense-list.remove-expense-message')
        };
        EventHandler.$emit('show-toast', obj);
        this.counted = 0;
        this.reload();
      } catch (error) {
        if (!error.response.data.error.details) {
          this.expenseRemovalErrorMessage = error.response.data.error.message;
        }
      } finally {
        this.formPending = false;
      }
    }
  }

  refreshList() {
    this.resetList(); 
    this.reload();
  }

  stateClasses(state) {
    return {
      'counter--empty': ExpenseState.Draft === state,
      'counter--orange': ExpenseState.Claimed === state,
      'counter--grey': ExpenseState.Cancelled === state,
      'counter--green': ExpenseState.Completed === state,
      'counter--green-grey': ExpenseState.Closed === state,
      'counter--red': ExpenseState.Rejected === state,
    };
  }

  sourceTypeIcons(sourceType) {
    return sourceTypeIcon[sourceType];
  }
}
