






























































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import {
  required,
  maxLength,
  minLength,
  email,
  requiredIf,
} from 'vuelidate/lib/validators';
import moment from 'moment';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { CompanyModel } from '@/api/profile/company.model';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileCreateMessage, ProfileStateEnum, ProfileResponseActionStatusType } from '@/api/profile/profile.model';
import { ScopeValue } from '@/api/roles/roles.model';
import { TravellerTypeOptions } from '@/const/profile-personal.const';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import rolesProfileStore from '@/modules/profile/roles/roles.store';
import accountStore from '@/store/account.store';
import settingsStore from '../../settings/settings.store';
import EventHandler from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { router } from '@/router';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

@Component({})
export default class AddProfile extends Vue {
  travellerGroupOptions: any = [
    {
      value: null,
      color: '',
    },
    {
      value: 'A',
      color: '#66CC00',
    },
    {
      value: 'B',
      color: '#0080FF',
    },
    {
      value: 'C',
      color: '#ffcc00',
    },
    {
      value: 'D',
      color: '#7F00FF',
    },
    {
      value: 'E',
      color: '#990000',
    },
  ];
  guestOptions: any[] = [
    {
      value: true,
      name: translate('profile-personal.guest'),
    },
    {
      value: false,
      name: translate('profile-personal.regular'),
    },
  ];
  Form: any = {
    title: null,
    firstName: '',
    lastName: '',
    userName: '',
    middleName: '',
    dateOfBirth: null,
    primaryEmail: '',
    createAccount: false,
    passwordRecoveryEmail: '',
    unit: null,
    unitsLoading: false,
    language: '',
    role: null,
    includeAgencyCustomers: false,
    scope: {
      name: translate('profile-roles-scopes.UserProfileOnly'),
      value: ScopeValue.userProfileOnly
    },
    sendInvitationEmail: null,
    selectedBusinessUnits: [],
    travellerType: null,
    travellerCategories: [],
    externalId: '',
    invoicingId: null,
    isGuest: this.guestOptions[1],
  };
  travellerCategoriesOptions: any[] = [];
  saving = false;
  profileCreated: boolean = false;
  showError = false;
  delayTimer: any | undefined = null;
  serverErrors: any[] = [];
  preloading: boolean = false;
  languageOptions: LanguagesModel[] = [];
  $v;
  titleOptions = [
    {
      value: 'Mr',
      label: translate('common.mr'),
    },
    {
      value: 'Mrs',
      label: translate('common.mrs'),
    },
    {
      value: 'Miss',
      label: translate('common.miss'),
    },
  ];
  
  unitSelectOptions: any[] = [];
  companyData: CompanyModel | null = null;
  canEditTravellerCategories: boolean = false;

  get currentCompany() {
    return settingsStore.currentCompany;
  }

  get scopeValue() {
    let scopes  = [
      {
        name: translate('profile-roles-scopes.UserProfileOnly'),
        value: ScopeValue.userProfileOnly,
      },
      {
        name: translate('profile-roles-scopes.OwnUnitAccess'),
        value: ScopeValue.ownUnitAccess,
      },
      {
        name: translate('profile-roles-scopes.SelectedUnitsAndBelowAccess'),
        value: ScopeValue.selectedUnitsAndBelowAccess,
      }
    ];

    if (rolesProfileStore.availableScopes.some(s => s === 'WholeCompanyAccess')) {
      scopes.push({
        name: translate('profile-roles-scopes.WholeCompanyAccess'),
        value: ScopeValue.wholeCompanyAccess,
      });
    }

    return scopes;
  }

  get titleRequired() {
    return this.Form.travellerType && -1 === [
      'InfantWithoutSeat',
      'Child',
    ].indexOf(this.Form.travellerType.value);
  }

  @Validation()
  validationObject() {
    let Form: any = {
      title: {
        required: requiredIf(() => this.titleRequired),
      },
      firstName: {
        required,
        isNameValid,
        maxLength: maxLength(100),
      },
      middleName: {
        isNameValid: (value) => (value ? isNameValid(value) : true),
        maxLength: maxLength(100),
      },
      lastName: {
        required,
        isNameValid,
        maxLength: maxLength(255),
      },
      userName: {
        required: requiredIf(() => {
          return this.Form.createAccount;
        }),
        minLength: minLength(5),
        maxLength: maxLength(255),
      },
      unit: {
        required,
      },
      role: {
        required: requiredIf(() => {
          return this.Form.createAccount;
        }),
      },
      selectedBusinessUnits: {
        required: requiredIf((item) => {
          return (item.value === ScopeValue.selectedUnitsAccess ||
            item.value === ScopeValue.selectedUnitsAndBelowAccess);
        })
      },
      scope: {
        required: requiredIf(() => {
          return this.Form.createAccount;
        }),
      },
      travellerType: {
        required,
      },
      primaryEmail: {
        required,
        email,
        maxLength: maxLength(320),
      },
      externalId: {
        maxLength: maxLength(100),
      },
      invoicingId: {
        maxLength: maxLength(128)
      },
      sendInvitationEmail: {
        required: requiredIf(() => {
          return this.Form.createAccount;
        }),
      },
      isGuest: {}
    };
    if (!this.Form.createAccount) {
      Form.passwordRecoveryEmail = {};
    } else {
      Form.passwordRecoveryEmail = {
        email,
        maxLength: maxLength(320),
      };
    }

    return {
      Form,
    };
  }

  get companyRoles() {
    return rolesProfileStore.ProfileCompanyRoles;
  }

  get language() {
    if (this.languageOptions) {
      if (this.Form && this.Form.language && this.Form.language !== '') {
        return this.languageOptions.find(option => option.code === this.Form.language);
      } else if (this.companyData && this.companyData.defaultLanguage) {
        return this.languageOptions.find(option => option.code === this.companyData!.defaultLanguage);
      } else {
        return this.languageOptions.find(option => option.code === 'en');
      }
    }
  }

  set language(option) {
    if (option) {
      this.Form.language = option.code;
    }
  }

  get companyId() {
    return this.$route.params.id;
  }

  get createAccount() {
    return this.Form.userName && this.Form.userName.length >= 5;
  }

  get companyIdForAutocomplete() {
    return settingsStore.currentCompany ? settingsStore.currentCompany.companyId : accountStore.current!.profile.companyId;
  }

  get travellerTypeOptions() {
    return TravellerTypeOptions.map(traveller => {
      return {
        ...traveller,
        name: translate(traveller.name)
      };
    });
  }

  get profileResponse() {
    return accountStore.createProfileResponse;
  }

  get canCreateAccount() {
    return this.$hasAccess('CanCreateAccount');
  }

  get user() {
    return accountStore.current;
  }

  get isLanguageFr() {
    return this.user!.profile.displayLanguage === 'fr';
  }

  get communityUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.companyId + '/community?unitId=' + this.$route.query.unitId);
  }

  hasProfileResponseAnyError() {
    return this.profileResponse ? this.profileResponse.accountAdded === ProfileResponseActionStatusType.Errors || this.profileResponse.invitationEmailSent === ProfileResponseActionStatusType.Errors : false;
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  async loadLanguages() {
    this.serverErrors = [];
    try {
      const result = await LanguagesApi.getLanguages();

      let options: LanguagesModel[] = [];
      options = result.data;

      this.languageOptions = options;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async loadTravellerCategories() {
    try {
      const response = await TravellerCategoriesApi.getAvailableTravellerCategoriesForCompany(this.$route.query.unitId as string);

      this.canEditTravellerCategories = response.data.canEditTravellerCategories;
      this.travellerCategoriesOptions = response.data.travellerCategories.map(e => {
        return {
          label: e.name,
          value: e.name,
          id: e.id,
        };
      });
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async mounted() {
    if (this.$hasAccess('CanAssignTravellerCategories')) {
      await this.loadTravellerCategories();
    } else {
      this.canEditTravellerCategories = false;
    }
    this.preloading = true;
    let companyId = this.$route.query.unitId ? this.$route.query.unitId : this.companyId;
    await rolesProfileStore.loadRoles(this.companyId);
    this.loadCompanyInfo();
    this.loadLanguages();
    const response = await ProfileCompanyApi.getById(companyId, Permission.AccessSettings);

    this.Form.unit = response.data;
    this.Form.travellerType = this.travellerTypeOptions[0];
    const result = await ProfileCompanyApi.getCompanyUnits(response.data.rootId, '', Permission.ManageProfile);

    let sortedHierarchy: CompanyModel[] = [];
    this.prepareHierarchy(result.data, sortedHierarchy, 0, null, false);

    this.unitSelectOptions = sortedHierarchy;
    this.preloading = false;
  }

  async resetProfileForm() {
    this.$v.$reset();
    let companyId = this.$route.query.unitId ? this.$route.query.unitId : this.companyId;
    const response = await ProfileCompanyApi.getById(companyId, Permission.AccessSettings);
    this.Form = {
      title: null,
      firstName: '',
      lastName: '',
      userName: '',
      middleName: '',
      dateOfBirth: null,
      primaryEmail: '',
      createAccount: false,
      passwordRecoveryEmail: '',
      unit: response.data,
      unitsLoading: false,
      language: '',
      role: null,
      scope: {
        name: translate('profile-roles-scopes.UserProfileOnly'),
        value: ScopeValue.userProfileOnly
      },
      sendInvitationEmail: null,
      selectedBusinessUnits: [],
      travellerType: null,
      travellerCategories: [],
    };
    this.profileCreated = false;
    accountStore.setCreateProfileResponse(null);
  }

  createProfileMessage() {
    accountStore.setCreateProfileResponse(null);
    this.profileCreated = false;

    this.showError = false;
    let profileData: ProfileCreateMessage = {
      title: this.Form.title ? this.Form.title.value : '',
      firstName: this.Form.firstName,
      companyId: this.Form.unit.id,
      lastName: this.Form.lastName,
      middleName: this.Form.middleName,
      language: this.Form.language,
      roles: this.Form.createAccount ? [
        {
          roleId: this.Form.role ? this.Form.role.roleId : null,
          includeAgencyCustomers: this.Form.includeAgencyCustomers,
          scope: this.Form.scope ? this.Form.scope.value : ScopeValue.userProfileOnly,
          selectedBusinessUnits: this.Form.scope && 
            (this.Form.scope.value === ScopeValue.selectedUnitsAccess ||
            this.Form.scope.value === ScopeValue.selectedUnitsAndBelowAccess) && 
            this.Form.selectedBusinessUnits && 
            this.Form.selectedBusinessUnits.length
            ? this.Form.selectedBusinessUnits.map(element => { return element.id; })
            : []
        }
      ] : [],
      contacts: {
        primaryEmail: this.Form.primaryEmail,
      },
      dateOfBirth: this.Form.dateOfBirth ? moment(this.Form.dateOfBirth).format('YYYY-MM-DD') : null,
      state: ProfileStateEnum.active,
      travellerType: this.Form.travellerType ? this.Form.travellerType!.value : null,
      travellerCategories: this.Form.travellerCategories,
      externalId: this.Form.externalId,
      invoicingId: this.Form.invoicingId,
      isGuest: this.Form.isGuest ? this.Form.isGuest!.value : false,
    };

    if (this.Form.createAccount && this.Form.userName) {
      profileData.account = {
        userName: this.Form.userName,
        email: this.Form.passwordRecoveryEmail
          ? this.Form.passwordRecoveryEmail
          : this.Form.primaryEmail,
        sendInvitationEmail: this.Form.sendInvitationEmail.value
      };
    }

    return profileData;
  }

  async createProfile(request) {
      const response = await ProfileApi.createProfile(request);
      this.profileCreated = true;
      accountStore.setCreateProfileResponse(response.data);
  }

  async submitAndNext() {
    try {
      this.serverErrors = [];
      this.$v.Form.$touch();
      if (this.$v.Form.$pending || this.$v.Form.$error) {
        return;
      }
      this.saving = true;
      const profileData = this.createProfileMessage();
      await this.createProfile(profileData);
      if (!this.hasProfileResponseAnyError()) {
        this.resetProfileForm();
      } else {
        this.goToProfile(this.profileResponse.id);
      }
    } catch (error) {
      if (error.response.status === 418 && error.response.data.error.code === 'COMPANY_DISABLED') {
        this.serverErrors = [{
          message: translate('settings-community.add-profile-company-disabled'),
        }];
      } else {
        this.serverErrors = this.$handleErrors(error, true);
      }
    } finally {
      this.saving = false;
    }
  }

  async submitAndGoToCommunity() {
    try {
      this.serverErrors = [];
      this.$v.Form.$touch();
      if (this.$v.Form.$pending || this.$v.Form.$error) {
        return;
      }
      this.saving = true;
      const profileData = this.createProfileMessage();
      await this.createProfile(profileData);
      this.success();
      if (!this.hasProfileResponseAnyError()) {
        router.push({
          name: 'community',
          query: {
            unitId: this.Form.unit && this.Form.unit.id ? this.Form.unit.id : ''
          },
          params: {
            id: this.$route.params.id,
          },
        });
        
      } else {
        this.goToProfile(this.profileResponse.id);
      }
    } catch (error) {
      if (error.response.status === 418 && error.response.data.error.code === 'COMPANY_DISABLED') {
        this.serverErrors = [{
          message: translate('settings-community.add-profile-company-disabled'),
        }];
      } else {
        this.serverErrors = this.$handleErrors(error, true);
      }
    } finally {
      this.saving = false;
    }
  }

  async submitAndEditProfile() {
    try {
      this.serverErrors = [];
      this.$v.Form.$touch();
      if (this.$v.Form.$pending || this.$v.Form.$error) {
        return;
      }
      this.saving = true;
      const profileData = this.createProfileMessage();
      await this.createProfile(profileData);
      if (this.serverErrors.length) {
        return;
      }
      this.success();
      this.goToProfile(this.profileResponse.id);
    } catch (error) {
      if (error.response.status === 418 && error.response.data.error.code === 'COMPANY_DISABLED') {
        this.serverErrors = [{
          message: translate('settings-community.add-profile-company-disabled'),
        }];
      } else {
        this.serverErrors = this.$handleErrors(error, true);
      }
    } finally {
      this.saving = false;
    }
  }

  goToProfile(id) {
    this.$router.push({
      name: 'personal',
      params: {
        id,
      },
    });
  }

  success() {
    EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('common.data-saved')
    });
  }

  async loadCompanyInfo() {
    this.serverErrors = [];
    try {
      const result = await ProfileCompanyApi.getById(this.companyId, Permission.AccessSettings);

      if (result && result.data) {
        this.companyData = result.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async loadCompanies(phrase: string) {
    clearTimeout(this.delayTimer);
    this.serverErrors = [];
    this.delayTimer = setTimeout(async () => {
      try {
        const result = await ProfileCompanyApi.getCompanyUnits(this.Form.unit.rootId, phrase, Permission.ManageProfile);
        let sortedHierarchy: CompanyModel[] = [];
        this.prepareHierarchy(result.data, sortedHierarchy, 0, null, false);

        if (phrase.length > 0) {
          this.unitSelectOptions = this.sortByName(result.data);
        } else {
          this.unitSelectOptions = sortedHierarchy;
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    }, 200);
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  focusOnChecbox() {
    setTimeout(() => {
      const el = ((this.$refs.createAccount as Vue).$el as HTMLInputElement);
      el.focus({
        preventScroll: true
      });
    }, 50);
  }

  prepareHierarchy(arrayToSort, sortedArray, currentLevel, parentId, withinCurrentLevelHierarchy) {
    if (arrayToSort && sortedArray) {
      let currentLevelItems = arrayToSort.filter(function (item) {
        if (!parentId) {
          return !item.parentId;
        } else {
          return item.parentId === parentId;
        }
      });
      if (currentLevelItems.length > 0) {
        currentLevelItems = this.sortByName(currentLevelItems);
        currentLevelItems.forEach(element => {
          element.level = currentLevel;
          if (element.id === this.companyIdForAutocomplete || withinCurrentLevelHierarchy) {  // only current company and its child hierarchy should be available to select
            sortedArray.push(element);
            this.prepareHierarchy(arrayToSort, sortedArray, currentLevel + 1, element.id, true);
          } else {
            this.prepareHierarchy(arrayToSort, sortedArray, currentLevel + 1, element.id, withinCurrentLevelHierarchy);
          }
        });
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.name < b.name) {
        result = -1;
      }
      else if (a.name > b.name) {
        result = 1;
      }
      return result;
    });
  }
}

