import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';
import TrainResults from '@/modules/search/train/TrainResults.vue';
import TrainSubintro from '@/modules/search/train/TrainSubintro.vue';
import TrainResultsFilters from '@/modules/search/train/TrainResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/train-results-travellers-list',
  name: 'trainResultsTravellersList',
  components: {
    default: TrainResults,
    intro: SearchTravellersIntro,
    subintro: TrainSubintro,
    sidebar: TrainResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-train-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
