









import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Carrier } from '@/api/air-engine/air-search.model';
import moment from 'moment';

@Component({})
export default class UiAirCarrierLogo extends Vue {
  @Prop() carrier!: Carrier;
  @Prop({ default: 'small' }) size!: String;

  loaded = false;
  imagesConst = '/assets/img/logos/__CODE__.png';

  get source() {
    return this.imagesConst.replace('__CODE__', this.carrier.code);
  }

  get classes() {
    if (this.loaded) {
      const el = this.$refs.image as HTMLElement;
      const proportions = el.offsetWidth / el.offsetHeight > 1.3 ? 'landscape' : 'portrait';
      return ['loaded', proportions].join(' ');
    }
    return 'loading';
  }

  imageLoaded() {
    this.loaded = true;
  }

  @Watch('source')
  onChange() {
    this.loaded = false;
  }

}
