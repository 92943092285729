import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  FeesConfiguration,
  FeesConfig,
  FeeConfigurationResult
} from './fees-configuration.model';
import { Permission } from '@/const/permission.enum';

class FeesConfigurationApiClass {
  public create(params: FeesConfig): Promise<AxiosResponse<FeeConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/fees-configurations',
      method: RequestMethod.POST,
      permission: Permission.CanEditFeesConfiguration,
    }, {}, params);
  }

  public getById(configurationId: string): Promise<AxiosResponse<FeesConfig>> {
    return http.execute({
      path: settings.apiAirEngine + '/fees-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.CanEditFeesConfiguration,
    }, { configurationId }, {});
  }

  public update(configurationId: string, params: FeesConfig): Promise<AxiosResponse<FeeConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/fees-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.CanEditFeesConfiguration,
    }, { configurationId }, params);
  }
}

export const AirFeesConfigurationApi: FeesConfigurationApiClass = new FeesConfigurationApiClass();