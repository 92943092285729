
















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';

import {
  ProfileDocument,
  CreateVisaMessage,
} from '@/api/profile/documents.model';
import DictionaryStore from '@/store/dictionary.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { LocationModel, LocationType, LanguageCode } from '@/api/dictionary/dictionary.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import moment from 'moment';
import _ from 'lodash';
import BasketStore from '@/modules/basket/basket.store';
import EventBus from '@/services/event-handler';

@Component({})
export default class VisaDocumentData extends Vue {
  @Prop({ default: false }) documentType: any;
  @Prop({ default: null }) document: any;
  @Prop({ default: null }) profileId: any;
  @Prop({}) isGuestMode!: boolean;
  @Prop({ default: false }) withoutSaving!: boolean;
  
  Form: ProfileDocument | null = null;
  serverErrors: any[] = [];
  errMessage: string = '';
  wasSubmitted: boolean = false;
  citiesLoading: boolean = false;
  cities: LocationModel[] = [];
  delayTimer: number | undefined = undefined;
  formPending: boolean = false;

  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        number: {
          required,
          maxLength: maxLength(16),
        },
        expireDate: {
          required,
        },
        issueDate: {
          required,
        },
        issueCountry: {
          required,
        },
        applicableCountry: {
          countryName: {
            required,
          }
        },
      },
    };
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isEditing() {
    return !!this.document;
  }

  get checkExpDate() {
    if (this.Form!.issueDate) {
      if (new Date(this.Form!.issueDate).getTime() < new Date().getTime()) {
        return new Date();
      } else {
        return new Date(this.Form!.issueDate);
      }
    } else {
      return new Date();
    }
  }

  get minimalExpireDate() {
    if (this.Form!.issueDate) {
      return new Date(moment(this.Form!.issueDate).toDate());
    }
    return null;
  }

  validDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  validDateIssueDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days', true) > 0) {
      return true;
    }

    return false;
  }

  maxIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  onIssueDateChange(date) {
    let result = moment(date).isAfter(moment(this.Form!.expireDate));
    if (result) {
      this.Form!.expireDate = '';
    }
  }

  async created() {
    if (this.isEditing) {
      this.Form = new ProfileDocument();
      this.Form.type = this.documentType.value;
      this.Form.number = this.document.number;
      this.Form.id = this.document.id;
      this.Form.issueCountryCode = this.document.issueCountryCode;
      this.Form.applicableCountryCode = this.document.applicableCountryCode;
      this.Form.issueCountry = this.allCountries!.find(country => { return country.code === this.document.issueCountryCode; })!;
      this.Form.applicableCountry = this.allCountries!.find(country => { return country.code === this.document.applicableCountryCode; })!;
      this.Form.issueCityCode = this.document.issueCityCode;
      this.Form.issueCityName = this.document.issueCityName;
      this.Form.issueDate = moment(this.document.issueDate).toDate();
      this.Form.expireDate = moment(this.document.expireDate).toDate();
      this.Form.tempId = this.document.tempId;

      if (this.Form.issueCityCode && this.Form.issueCityCode.length === 3 && this.Form.issueCityCode !== '   ') {
        let result = await DictionaryApi.getCity(this.Form.issueCityCode, LanguageCode.EN);
        this.Form.issueCity = {
          type: LocationType.City,
          code: result.data.iataCode,
          cityName: result.data.name,
          countryCode: result.data.countryCode,
          airportName: ''
        };
        this.cities = [this.Form.issueCity];
      } else if (this.Form.issueCityName) {
        this.Form.issueCity = {
          code: null,
          cityName: this.Form.issueCityName,
          type: LocationType.City,
          airportName: '',
          countryCode: ''
        };
        this.cities = [this.Form.issueCity];
      }
    } else {
      this.Form = new ProfileDocument();
    }
  }

  notifyParent(reloadDocuments, document) {
    this.$emit('document-changed', reloadDocuments, document);
  }

  requestData() {
    return {
      type: this.documentType.value,
      issueCountryCode: this.Form!.issueCountry && this.Form!.issueCountry.code ? this.Form!.issueCountry.code : '',
      issueCityCode: this.Form!.issueCity ? this.Form!.issueCity.code : null,
      issueCityName: this.Form!.issueCity ? this.Form!.issueCity.cityName : null,
      number: this.Form!.number,
      applicableCountryCode: this.Form!.applicableCountry.code,
      issueDate:  moment(this.Form!.issueDate).format('YYYY-MM-DD'),
      expireDate:  this.Form!.expireDate ? moment(this.Form!.expireDate).format('YYYY-MM-DD') : null,
    };
  }

  async saveDetails() {
    this.serverErrors = [];
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error || this.validDate(this.Form!.expireDate) || this.validDateIssueDate(this.Form!.issueDate) || this.maxIssueDate(this.Form!.issueDate, this.Form!.expireDate)) {
      return;
    }

    let request: CreateVisaMessage = this.requestData();

    if (this.withoutSaving) {
      this.notifyParent(true, request);
      return;
    }

    try {
      this.formPending = true;
      if (this.isEditing) {
        if (this.isGuestMode) {
          let document = {
            ...request,
            tempId: this.Form!.tempId,
          };
          BasketStore.updateGuestBasketTravellerDocument({ id: this.profileId, document: document});
        } else {
          await DocumentsApi.updateVisaDocument(this.profileId, this.document.id, request);
          this.notifyParent(true, null);
        }
      } else {
        this.wasSubmitted = true;
        if (this.isGuestMode) {
          let document: any = request;
          document.tempId = _.uniqueId();
          BasketStore.setGuestBasketTravellerDocument({ id: this.profileId, document: document });
        } else {
          await DocumentsApi.createVisaDocument(this.profileId, request);
          this.notifyParent(true, null);
        }
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'citiesLoading',
  })
  async loadCities(phrase: string) {
    if (phrase && phrase.length > 0) {
      this.citiesLoading = true;
      try {
        const result = await DictionaryApi.findLocation(phrase, LanguageCode.EN);
        this.cities = result.data.filter((location: LocationModel) => {
          return location.type === LocationType.City;
        });
        this.citiesLoading = false;
      } catch (error) {
        this.citiesLoading = false;
        this.serverErrors = this.$handleErrors(error, true);
      }
    } else {
      this.citiesLoading = false;
      this.cities = [];
    }
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.backButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  onTypeChanged() {
    ((this.$refs.firstInput as Vue).$refs.input as HTMLElement).focus();
  }

  mounted() {
    EventBus.$on('document-type-changed', this.onTypeChanged);
  }

  beforeDestroy() {
    EventBus.$off('document-type-changed', this.onTypeChanged);
  }
}
