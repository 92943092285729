

















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

const currentPageSyncName = 'update:currentPage';
const perPageSyncName = 'update:perPage';

@Component({})
export default class UiTableWrapper extends Vue {
  @Prop() currentPage!: number;
  @Prop() perPageOptions!: any[];
  @Prop() perPage!: number;
  @Prop() count!: number;
  @Prop() loading!: boolean;
  @Prop({ default: () => { return []; } }) errors!: any[];
  @Prop() noEmptyMessage!: boolean;
  @Prop({ default: true }) showPerPage!: boolean;

  imagesConst: string = '/assets/img/loader/1.gif';

  get value() {
    return this.currentPage;
  }

  get perPageValue() {
    return this.perPage;
  }

  set perPageValue(val) {
    this[perPageSyncName](val);
  }

  @Emit()
  [currentPageSyncName](val) {
    return val;
  }

  @Emit()
  [perPageSyncName](val) {
    return val;
  }
}

