import { SeatModel } from '@/api/trip/basket.model';

export default {
  margin: 4,
  rowHeight: 21,
  colWidth: 21,
  extendedColWidth: 46,
  iconHeight: 20,
  iconWidth: 20,
  mobileMargin: 5,
  mobileRowHeight: 30,
  mobileColWidth: 30,
  mobileExtendedColWidth: 56,
  mobileIconHeight: 25,
  mobileIconWidth: 25,

  legend: {
    available: {
      isFree: true,
    } as SeatModel,
    paidExtra: {
      isFree: true,
      isChargeable: true,
    } as SeatModel,
    reserved: {
      isFree: false,
    } as SeatModel,
    lavatory: {
      isFree: true,
      facility: 'Lavatory',
    } as SeatModel,
    colset: {
      isFree: true,
      facility: 'Closet',
    } as SeatModel,
    notAvailable: {
      isFree: true,
      column: ' ',
      noSeat: true,
    } as SeatModel,
  },
};
