






































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import { MOBILE_NAVIGATION_HEIGHT } from '@/modules/layout/layout.const';

@Component
export default class UiSelect extends Vue {
  @Prop() value: any;
  @Prop({ default: '' }) seleniumId!: string;
  @Prop({ default: '' }) trackBy!: string;
  @Prop({ default: [] }) options!: any[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) allowEmpty!: boolean;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) id!: string;
  @Prop({}) customLabel!: Function;
  @Prop({ default: false }) showLabels!: boolean;
  @Prop({ default: '' }) placeholder!: string;

  isActive: boolean = false;
  isSimple: boolean | null = null;
  touchedIndex: number = -1;
  chosenIndex: number = -1;
  foundLabel: string = '';
  isInPopup: boolean = false;
  popupContainer: HTMLElement | null = null;
  popupTitle: string = '';

  get currentId() {
    return this.id || ('UiSelect' + this._uid);
  }

  get listeners() {
    return {
      ...this.$listeners,
      input: value => this.input(value),
    };
  }

  get mobileViewClasses() {
    return {
      'ui-select__mobile-view--has-popup-title': '' !== this.popupTitle,
    };
  }

  @Emit('input')
  input(value) {
    return value;
  }

  @Emit('select')
  select(value) {
    return value;
  }

  mobileFieldClick() {
    if (this.disabled) {
      return;
    }

    this.isActive = true;
    if (this.isInPopup) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  confirm() {
    this.isActive = false;
    if (this.isInPopup) {
      EventBus.$emit('unfreeze-popup', this.popupContainer);
    }
  }

  mobileOptionsClick($event) {
    if (hasSomeParentTheClass($event.target, 'option-item')) {
      const parent = getParentByClass($event.target, 'option-item');
      const index = parent.attributes.data.nodeValue;
      this.mobileSelectItem(this.options[index], index, $event);
    } else {
      this.confirm();
    }
  }

  mobileSelectItem(item, index, $event) {
    if (this.disabled || item.$isDisabled) {
      return;
    }
    if (this.allowEmpty && (this.chosenIndex === Number(index))) {
      this.input(null);
      this.select(null);
    } else {
      this.input(item);
      this.select(item);
    }
    this.confirm();
  }

  onTouchStart(index) {
    this.touchedIndex = index;
    
  }

  onTouchEnd(index) {
    if (index === this.touchedIndex) {
      this.touchedIndex = -1;
    }
  }

  classesForIndex(item, index) {
    return {
      'ui-select__mobile-option--disabled': item.$isDisabled === true,
      'ui-select__mobile-option--selected': item.$isDisabled !== true && this.chosenIndex === index,
      'ui-select__mobile-option--highlighted': item.$isDisabled !== true && this.touchedIndex === index,
    };
  }

  investigateTypes(value) {
    if (this.options.length) {
      if (-1 < ['string', 'number'].indexOf(typeof this.options[0])) {
        this.isSimple = true;
        if (value != null) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i] === value) {
              this.chosenIndex = i;
              break;
            }
          }
        } else {
          this.chosenIndex = -1;
        }
      } else if ('object' === typeof this.options[0]) {
        this.isSimple = false;
        if ('' !== this.trackBy && value != null) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i][this.trackBy] === value[this.trackBy]) {
              this.chosenIndex = i;
              break;
            }
          }
        } else {
          this.chosenIndex = -1;
        }
      }
    } else if (-1 < ['string', 'number'].indexOf(typeof value)) {
      this.isSimple = true;
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i] === value) {
          this.chosenIndex = i;
          break;
        }
      }
    } else if ('object' === typeof value) {
      this.isSimple = false;
      if (value != null) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i] === value) {
            this.chosenIndex = i;
            break;
          }
        }
      } else {
        this.chosenIndex = -1;
      }
    }
  }

  getOptionText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      if (this.customLabel) {
        return this.customLabel(item);
      }
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  getValueText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      if (this.customLabel) {
        return this.customLabel(item);
      }
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  onWindowClick($event) {
    let navHeight = MOBILE_NAVIGATION_HEIGHT;
    if ($event.pageY < navHeight) {
      this.confirm();
    }
  }

  @Watch('isActive', { immediate: true })
  onActiveChange(value) {
    if (value) {
      setTimeout(() => {
        window.addEventListener('click', this.onWindowClick);
      });
    } else {
      window.removeEventListener('click', this.onWindowClick);
    }
  }

  @Watch('options', { immediate: true })
  onOptionsChange(value) {
    this.investigateTypes(this.value);
  }

  @Watch('value', { immediate: true })
  onChange(value) {
    this.investigateTypes(value);
  }

  mounted() {
    if (!this.$slots['field-label']) {
      const label = (this.$refs.uiSelectWrapper as Element).parentNode;

      if (
        label &&
        'label' === label.nodeName.toLowerCase() &&
        (label as HTMLElement).className &&
        -1 < (label as HTMLElement).className.indexOf('ui-label')
      ) {
        for (let i = 0; i < label.children.length; i++) {
          if ('span' === label.children[i].nodeName.toLowerCase()) {
            this.foundLabel = (label.children[i] as HTMLElement).textContent || '';
          }
        }
      }
    }

    if (hasSomeParentTheClass(this.$refs.uiSelectWrapper, 'modal-container')) {
      this.isInPopup = true;
      this.$nextTick(() => {
        this.popupContainer = getParentByClass(this.$refs.uiSelectWrapper, 'modal-container');
        if (this.popupContainer) {
          const titleElements = this.popupContainer.getElementsByClassName('popup-title');
          if (titleElements.length) {
            this.popupTitle = (titleElements[0] as HTMLElement).textContent || '';
          }
        }
      });
    }
  }
}

