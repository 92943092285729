import AccommodationProviderConfigurationDetail from '@/modules/settings/configurations/AccommodationProviderConfigurationDetail.vue';

export default {
  path: 'new',
  name: 'new-accommodation-provider-configuration',
  component: AccommodationProviderConfigurationDetail,
  displayName: 'New accommodation provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadAccommodationProviders',
    depthCategory: 'accommodation-providers',
    depth: 2,
    parentName: 'accommodation-provider-configurations',
  },
};