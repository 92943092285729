













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiTripStatus extends Vue {
  @Prop({ default: 'Draft' }) status!: string;

  get classStatus() {
    return {
      'counter--green': 'Confirmed' === this.status,
      'counter--grey': 'Cancelled' === this.status,
      'counter--empty': 'Draft' === this.status,
      'counter--orange': 'PendingApproval' === this.status || 'Held' === this.status || 'AttentionRequired' === this.status,
      'counter--desynchronized': 'Desynchronized' === this.status,
    };
  }
}

