import {
  serviceClassEnum,
  cabinClassEnum,
} from '@/api/home/home.model';
import { AirSearchAllianceEnum } from '@/api/air-engine/air-search.model';

export default {
  serviceLinks: [
    {
      name: serviceClassEnum.Flight,
      label: 'search.flight',
      patch: 'air',
      icon: 'airplanemode_active',
      disabled: false,
      permission: 'SearchOffersAir',
    },
    {
      name: serviceClassEnum.Train,
      label: 'search.train',
      patch: 'train',
      icon: 'train',
      disabled: false,
      permission: 'SearchOffersRail',
    },
    {
      name: serviceClassEnum.Hotel,
      label: 'search.hotel',
      patch: 'hotel',
      icon: 'hotel',
      disabled: false,
      permission: 'SearchOffersAccomodation',
    },
    {
      name: serviceClassEnum.Car,
      label: 'search.car',
      patch: 'car',
      icon: 'directions_car',
      disabled: false,
      permission: 'SearchOffersCar',
    },
    {
      name: serviceClassEnum.Pkp,
      label: 'search.pkp',
      patch: 'pkp',
      icon: 'train',
      disabled: false,
      permission: '',
    },
  ],
  alliances: [
    {
      label: '',
      value: null,
    },
    {
      label: 'search-air.alliance-star-alliance',
      value: AirSearchAllianceEnum.StarAlliance,
    },
    {
      label: 'search-air.alliance-one-world',
      value: AirSearchAllianceEnum.OneWorld,
    },
    {
      label: 'search-air.alliance-sky-team',
      value: AirSearchAllianceEnum.SkyTeam,
    },
  ],
  cabinClasses: [
    {
      label: 'search-air.classes-economy',
      value: cabinClassEnum.Economy,
    },
    {
      label: 'search-air.classes-premium-economy',
      value: cabinClassEnum.PremiumEconomy,
    },
    {
      label: 'search-air.classes-business',
      value: cabinClassEnum.Business,
    },
    {
      label: 'search-air.classes-first',
      value: cabinClassEnum.First,
    },
  ],
  guestTravellerOptions: [
    {
      label: 'search.adult',
      code: 'ADT',
      value: 1,
      tooltip: '',
      trainAge: false,
      ageOptions: [],
    },
    {
      label: 'search.youth',
      code: 'YTH',
      value: 0,
      tooltip: '',
      trainAge: true,
      ageOptions: [12, 25],
    },
    {
      label: 'search.child',
      code: 'CHD',
      value: 0,
      tooltip: '',
      trainAge: true,
      ageOptions: [4, 18],
    },
    {
      label: 'search.infant',
      code: 'INF',
      value: 0,
      tooltip: '',
      trainAge: true,
      ageOptions: [0, 3],
    },
    {
      label: 'search.senior',
      code: 'SRC',
      value: 0,
      tooltip: '',
      trainAge: true,
      ageOptions: [60, 65],
    },
    {
      label: 'search.seaman',
      code: 'SEA',
      value: 0,
      tooltip: 'search.seaman-tooltip',
      trainAge: false,
      ageOptions: [],
    },
    {
      label: 'search.military',
      code: 'MLT',
      value: 0,
      tooltip: '',
      trainAge: false,
      ageOptions: [],
    }
  ],
  searchTimeValues: [
    {
      label: '4:00',
      timeRange: [
        240,
        480,
      ],
      disabled: false,
      wide: false,
      value: 240,
    },
    {
      label: '6:00',
      timeRange: [
        360,
        600,
      ],
      disabled: false,
      wide: false,
      value: 360,
    },
    {
      label: '8:00',
      timeRange: [
        480,
        720,
      ],
      disabled: false,
      wide: false,
      value: 480,
    },
    {
      label: '10:00',
      timeRange: [
        600,
        840,
      ],
      disabled: false,
      wide: false,
      value: 600,
    },
    {
      label: '12:00',
      timeRange: [
        720,
        960,
      ],
      disabled: false,
      wide: false,
      value: 720,
    },
    {
      label: '14:00',
      timeRange: [
        840,
        1080,
      ],
      disabled: false,
      wide: false,
      value: 840,
    },
    {
      label: '16:00',
      timeRange: [
        960,
        1200,
      ],
      disabled: false,
      wide: false,
      value: 960,
    },
    {
      label: '18:00',
      timeRange: [
        1080,
        1320,
      ],
      disabled: false,
      wide: false,
      value: 1080,
    },
    {
      label: '20:00',
      timeRange: [
        1200,
        1439,
      ],
      disabled: false,
      wide: false,
      value: 1200,
    },
    {
      label: '22:00',
      timeRange: [
        1320,
        1439,
      ],
      disabled: false,
      wide: false,
      value: 1320,
    },
    {
      label: 'common.any-hour',
      timeRange: [
        0,
        1439,
      ],
      disabled: false,
      wide: true,
      value: -1,
    },
  ],
  railSearchTimeValues: [
    {
      label: '0:00',
      timeRange: [
        0,
        120,
      ],
      disabled: false,
      wide: false,
      value: 240,
    },
    {
      label: '2:00',
      timeRange: [
        120,
        240,
      ],
      disabled: false,
      wide: false,
      value: 240,
    },
    {
      label: '4:00',
      timeRange: [
        240,
        360,
      ],
      disabled: false,
      wide: false,
      value: 240,
    },
    {
      label: '6:00',
      timeRange: [
        360,
        480,
      ],
      disabled: false,
      wide: false,
      value: 360,
    },
    {
      label: '8:00',
      timeRange: [
        480,
        600,
      ],
      disabled: false,
      wide: false,
      value: 480,
    },
    {
      label: '10:00',
      timeRange: [
        600,
        720,
      ],
      disabled: false,
      wide: false,
      value: 600,
    },
    {
      label: '12:00',
      timeRange: [
        720,
        840,
      ],
      disabled: false,
      wide: false,
      value: 720,
    },
    {
      label: '14:00',
      timeRange: [
        840,
        960,
      ],
      disabled: false,
      wide: false,
      value: 840,
    },
    {
      label: '16:00',
      timeRange: [
        960,
        1080,
      ],
      disabled: false,
      wide: false,
      value: 960,
    },
    {
      label: '18:00',
      timeRange: [
        1080,
        1200,
      ],
      disabled: false,
      wide: false,
      value: 1080,
    },
    {
      label: '20:00',
      timeRange: [
        1200,
        1320,
      ],
      disabled: false,
      wide: false,
      value: 1200,
    },
    {
      label: '22:00',
      timeRange: [
        1320,
        1439,
      ],
      disabled: false,
      wide: false,
      value: 1320,
    },
  ],
  airSearchTimeWindows: [
    {
      label: 'Any hour',
      disabled: false,
      value: -1,
      timeRange: [
        0,
        1439
      ]
    },
    {
      label: '00:00',
      disabled: false,
      value: 0,
      timeRange: [
        0,
        120
      ]
    },
    {
      label: '00:15',
      disabled: false,
      value: 15,
      timeRange: [
        0,
        135
      ]
    },
    {
      label: '00:30',
      disabled: false,
      value: 30,
      timeRange: [
        0,
        150
      ]
    },
    {
      label: '00:45',
      disabled: false,
      value: 45,
      timeRange: [
        0,
        165
      ]
    },
    {
      label: '01:00',
      disabled: false,
      value: 60,
      timeRange: [
        0,
        180
      ]
    },
    {
      label: '01:15',
      disabled: false,
      value: 75,
      timeRange: [
        0,
        195
      ]
    },
    {
      label: '01:30',
      disabled: false,
      value: 90,
      timeRange: [
        0,
        210
      ]
    },
    {
      label: '01:45',
      disabled: false,
      value: 105,
      timeRange: [
        0,
        225
      ]
    },
    {
      label: '02:00',
      disabled: false,
      value: 120,
      timeRange: [
        0,
        240
      ]
    },
    {
      label: '02:15',
      disabled: false,
      value: 135,
      timeRange: [
        15,
        255
      ]
    },
    {
      label: '02:30',
      disabled: false,
      value: 150,
      timeRange: [
        30,
        270
      ]
    },
    {
      label: '02:45',
      disabled: false,
      value: 165,
      timeRange: [
        45,
        285
      ]
    },
    {
      label: '03:00',
      disabled: false,
      value: 180,
      timeRange: [
        60,
        300
      ]
    },
    {
      label: '03:15',
      disabled: false,
      value: 195,
      timeRange: [
        75,
        315
      ]
    },
    {
      label: '03:30',
      disabled: false,
      value: 210,
      timeRange: [
        90,
        330
      ]
    },
    {
      label: '03:45',
      disabled: false,
      value: 225,
      timeRange: [
        105,
        345
      ]
    },
    {
      label: '04:00',
      disabled: false,
      value: 240,
      timeRange: [
        120,
        360
      ]
    },
    {
      label: '04:15',
      disabled: false,
      value: 255,
      timeRange: [
        135,
        375
      ]
    },
    {
      label: '04:30',
      disabled: false,
      value: 270,
      timeRange: [
        150,
        390
      ]
    },
    {
      label: '04:45',
      disabled: false,
      value: 285,
      timeRange: [
        165,
        405
      ]
    },
    {
      label: '05:00',
      disabled: false,
      value: 300,
      timeRange: [
        180,
        420
      ]
    },
    {
      label: '05:15',
      disabled: false,
      value: 315,
      timeRange: [
        195,
        435
      ]
    },
    {
      label: '05:30',
      disabled: false,
      value: 330,
      timeRange: [
        210,
        450
      ]
    },
    {
      label: '05:45',
      disabled: false,
      value: 345,
      timeRange: [
        225,
        465
      ]
    },
    {
      label: '06:00',
      disabled: false,
      value: 360,
      timeRange: [
        240,
        480
      ]
    },
    {
      label: '06:15',
      disabled: false,
      value: 375,
      timeRange: [
        255,
        495
      ]
    },
    {
      label: '06:30',
      disabled: false,
      value: 390,
      timeRange: [
        270,
        510
      ]
    },
    {
      label: '06:45',
      disabled: false,
      value: 405,
      timeRange: [
        285,
        525
      ]
    },
    {
      label: '07:00',
      disabled: false,
      value: 420,
      timeRange: [
        300,
        540
      ]
    },
    {
      label: '07:15',
      disabled: false,
      value: 435,
      timeRange: [
        315,
        555
      ]
    },
    {
      label: '07:30',
      disabled: false,
      value: 450,
      timeRange: [
        330,
        570
      ]
    },
    {
      label: '07:45',
      disabled: false,
      value: 465,
      timeRange: [
        345,
        585
      ]
    },
    {
      label: '08:00',
      disabled: false,
      value: 480,
      timeRange: [
        360,
        600
      ]
    },
    {
      label: '08:15',
      disabled: false,
      value: 495,
      timeRange: [
        375,
        615
      ]
    },
    {
      label: '08:30',
      disabled: false,
      value: 510,
      timeRange: [
        390,
        630
      ]
    },
    {
      label: '08:45',
      disabled: false,
      value: 525,
      timeRange: [
        405,
        645
      ]
    },
    {
      label: '09:00',
      disabled: false,
      value: 540,
      timeRange: [
        420,
        660
      ]
    },
    {
      label: '09:15',
      disabled: false,
      value: 555,
      timeRange: [
        435,
        675
      ]
    },
    {
      label: '09:30',
      disabled: false,
      value: 570,
      timeRange: [
        450,
        690
      ]
    },
    {
      label: '09:45',
      disabled: false,
      value: 585,
      timeRange: [
        465,
        705
      ]
    },
    {
      label: '10:00',
      disabled: false,
      value: 600,
      timeRange: [
        480,
        720
      ]
    },
    {
      label: '10:15',
      disabled: false,
      value: 615,
      timeRange: [
        495,
        735
      ]
    },
    {
      label: '10:30',
      disabled: false,
      value: 630,
      timeRange: [
        510,
        750
      ]
    },
    {
      label: '10:45',
      disabled: false,
      value: 645,
      timeRange: [
        525,
        765
      ]
    },
    {
      label: '11:00',
      disabled: false,
      value: 660,
      timeRange: [
        540,
        780
      ]
    },
    {
      label: '11:15',
      disabled: false,
      value: 675,
      timeRange: [
        555,
        795
      ]
    },
    {
      label: '11:30',
      disabled: false,
      value: 690,
      timeRange: [
        570,
        810
      ]
    },
    {
      label: '11:45',
      disabled: false,
      value: 705,
      timeRange: [
        585,
        825
      ]
    },
    {
      label: '12:00',
      disabled: false,
      value: 720,
      timeRange: [
        600,
        840
      ]
    },
    {
      label: '12:15',
      disabled: false,
      value: 735,
      timeRange: [
        615,
        855
      ]
    },
    {
      label: '12:30',
      disabled: false,
      value: 750,
      timeRange: [
        630,
        870
      ]
    },
    {
      label: '12:45',
      disabled: false,
      value: 765,
      timeRange: [
        645,
        885
      ]
    },
    {
      label: '13:00',
      disabled: false,
      value: 780,
      timeRange: [
        660,
        900
      ]
    },
    {
      label: '13:15',
      disabled: false,
      value: 795,
      timeRange: [
        675,
        915
      ]
    },
    {
      label: '13:30',
      disabled: false,
      value: 810,
      timeRange: [
        690,
        930
      ]
    },
    {
      label: '13:45',
      disabled: false,
      value: 825,
      timeRange: [
        705,
        945
      ]
    },
    {
      label: '14:00',
      disabled: false,
      value: 840,
      timeRange: [
        720,
        960
      ]
    },
    {
      label: '14:15',
      disabled: false,
      value: 855,
      timeRange: [
        735,
        975
      ]
    },
    {
      label: '14:30',
      disabled: false,
      value: 870,
      timeRange: [
        750,
        990
      ]
    },
    {
      label: '14:45',
      disabled: false,
      value: 885,
      timeRange: [
        765,
        1005
      ]
    },
    {
      label: '15:00',
      disabled: false,
      value: 900,
      timeRange: [
        780,
        1020
      ]
    },
    {
      label: '15:15',
      disabled: false,
      value: 915,
      timeRange: [
        795,
        1035
      ]
    },
    {
      label: '15:30',
      disabled: false,
      value: 930,
      timeRange: [
        810,
        1050
      ]
    },
    {
      label: '15:45',
      disabled: false,
      value: 945,
      timeRange: [
        825,
        1065
      ]
    },
    {
      label: '16:00',
      disabled: false,
      value: 960,
      timeRange: [
        840,
        1080
      ]
    },
    {
      label: '16:15',
      disabled: false,
      value: 975,
      timeRange: [
        855,
        1095
      ]
    },
    {
      label: '16:30',
      disabled: false,
      value: 990,
      timeRange: [
        870,
        1110
      ]
    },
    {
      label: '16:45',
      disabled: false,
      value: 1005,
      timeRange: [
        885,
        1125
      ]
    },
    {
      label: '17:00',
      disabled: false,
      value: 1020,
      timeRange: [
        900,
        1140
      ]
    },
    {
      label: '17:15',
      disabled: false,
      value: 1035,
      timeRange: [
        915,
        1155
      ]
    },
    {
      label: '17:30',
      disabled: false,
      value: 1050,
      timeRange: [
        930,
        1170
      ]
    },
    {
      label: '17:45',
      disabled: false,
      value: 1065,
      timeRange: [
        945,
        1185
      ]
    },
    {
      label: '18:00',
      disabled: false,
      value: 1080,
      timeRange: [
        960,
        1200
      ]
    },
    {
      label: '18:15',
      disabled: false,
      value: 1095,
      timeRange: [
        975,
        1215
      ]
    },
    {
      label: '18:30',
      disabled: false,
      value: 1110,
      timeRange: [
        990,
        1230
      ]
    },
    {
      label: '18:45',
      disabled: false,
      value: 1125,
      timeRange: [
        1005,
        1245
      ]
    },
    {
      label: '19:00',
      disabled: false,
      value: 1140,
      timeRange: [
        1020,
        1260
      ]
    },
    {
      label: '19:15',
      disabled: false,
      value: 1155,
      timeRange: [
        1035,
        1275
      ]
    },
    {
      label: '19:30',
      disabled: false,
      value: 1170,
      timeRange: [
        1050,
        1290
      ]
    },
    {
      label: '19:45',
      disabled: false,
      value: 1185,
      timeRange: [
        1065,
        1305
      ]
    },
    {
      label: '20:00',
      disabled: false,
      value: 1200,
      timeRange: [
        1080,
        1320
      ]
    },
    {
      label: '20:15',
      disabled: false,
      value: 1215,
      timeRange: [
        1095,
        1335
      ]
    },
    {
      label: '20:30',
      disabled: false,
      value: 1230,
      timeRange: [
        1110,
        1350
      ]
    },
    {
      label: '20:45',
      disabled: false,
      value: 1245,
      timeRange: [
        1125,
        1365
      ]
    },
    {
      label: '21:00',
      disabled: false,
      value: 1260,
      timeRange: [
        1140,
        1380
      ]
    },
    {
      label: '21:15',
      disabled: false,
      value: 1275,
      timeRange: [
        1155,
        1395
      ]
    },
    {
      label: '21:30',
      disabled: false,
      value: 1290,
      timeRange: [
        1170,
        1410
      ]
    },
    {
      label: '21:45',
      disabled: false,
      value: 1305,
      timeRange: [
        1185,
        1425
      ]
    },
    {
      label: '22:00',
      disabled: false,
      value: 1320,
      timeRange: [
        1200,
        1439
      ]
    },
    {
      label: '22:15',
      disabled: false,
      value: 1335,
      timeRange: [
        1215,
        1439
      ]
    },
    {
      label: '22:30',
      disabled: false,
      value: 1350,
      timeRange: [
        1230,
        1439
      ]
    },
    {
      label: '22:45',
      disabled: false,
      value: 1365,
      timeRange: [
        1245,
        1439
      ]
    },
    {
      label: '23:00',
      disabled: false,
      value: 1380,
      timeRange: [
        1260,
        1439
      ]
    },
    {
      label: '23:15',
      disabled: false,
      value: 1395,
      timeRange: [
        1275,
        1439
      ]
    },
    {
      label: '23:30',
      disabled: false,
      value: 1410,
      timeRange: [
        1290,
        1439
      ]
    },
    {
      label: '23:45',
      disabled: false,
      value: 1425,
      timeRange: [
        1305,
        1439
      ]
    }
  ],
  railSearchTimeWindows: [
    {
      label: 'Any hour',
      disabled: false,
      value: -1,
      timeRange: [
        0,
        1439
      ]
    },
    {
      label: '00:00',
      disabled: false,
      value: 0,
      timeRange: [
        0,
        120
      ]
    },
    {
      label: '00:15',
      disabled: false,
      value: 15,
      timeRange: [
        0,
        135
      ]
    },
    {
      label: '00:30',
      disabled: false,
      value: 30,
      timeRange: [
        0,
        150
      ]
    },
    {
      label: '00:45',
      disabled: false,
      value: 45,
      timeRange: [
        0,
        165
      ]
    },
    {
      label: '01:00',
      disabled: false,
      value: 60,
      timeRange: [
        0,
        180
      ]
    },
    {
      label: '01:15',
      disabled: false,
      value: 75,
      timeRange: [
        0,
        195
      ]
    },
    {
      label: '01:30',
      disabled: false,
      value: 90,
      timeRange: [
        0,
        210
      ]
    },
    {
      label: '01:45',
      disabled: false,
      value: 105,
      timeRange: [
        0,
        225
      ]
    },
    {
      label: '02:00',
      disabled: false,
      value: 120,
      timeRange: [
        0,
        240
      ]
    },
    {
      label: '02:15',
      disabled: false,
      value: 135,
      timeRange: [
        15,
        255
      ]
    },
    {
      label: '02:30',
      disabled: false,
      value: 150,
      timeRange: [
        30,
        270
      ]
    },
    {
      label: '02:45',
      disabled: false,
      value: 165,
      timeRange: [
        45,
        285
      ]
    },
    {
      label: '03:00',
      disabled: false,
      value: 180,
      timeRange: [
        60,
        300
      ]
    },
    {
      label: '03:15',
      disabled: false,
      value: 195,
      timeRange: [
        75,
        315
      ]
    },
    {
      label: '03:30',
      disabled: false,
      value: 210,
      timeRange: [
        90,
        330
      ]
    },
    {
      label: '03:45',
      disabled: false,
      value: 225,
      timeRange: [
        105,
        345
      ]
    },
    {
      label: '04:00',
      disabled: false,
      value: 240,
      timeRange: [
        120,
        360
      ]
    },
    {
      label: '04:15',
      disabled: false,
      value: 255,
      timeRange: [
        135,
        375
      ]
    },
    {
      label: '04:30',
      disabled: false,
      value: 270,
      timeRange: [
        150,
        390
      ]
    },
    {
      label: '04:45',
      disabled: false,
      value: 285,
      timeRange: [
        165,
        405
      ]
    },
    {
      label: '05:00',
      disabled: false,
      value: 300,
      timeRange: [
        180,
        420
      ]
    },
    {
      label: '05:15',
      disabled: false,
      value: 315,
      timeRange: [
        195,
        435
      ]
    },
    {
      label: '05:30',
      disabled: false,
      value: 330,
      timeRange: [
        210,
        450
      ]
    },
    {
      label: '05:45',
      disabled: false,
      value: 345,
      timeRange: [
        225,
        465
      ]
    },
    {
      label: '06:00',
      disabled: false,
      value: 360,
      timeRange: [
        240,
        480
      ]
    },
    {
      label: '06:15',
      disabled: false,
      value: 375,
      timeRange: [
        255,
        495
      ]
    },
    {
      label: '06:30',
      disabled: false,
      value: 390,
      timeRange: [
        270,
        510
      ]
    },
    {
      label: '06:45',
      disabled: false,
      value: 405,
      timeRange: [
        285,
        525
      ]
    },
    {
      label: '07:00',
      disabled: false,
      value: 420,
      timeRange: [
        300,
        540
      ]
    },
    {
      label: '07:15',
      disabled: false,
      value: 435,
      timeRange: [
        315,
        555
      ]
    },
    {
      label: '07:30',
      disabled: false,
      value: 450,
      timeRange: [
        330,
        570
      ]
    },
    {
      label: '07:45',
      disabled: false,
      value: 465,
      timeRange: [
        345,
        585
      ]
    },
    {
      label: '08:00',
      disabled: false,
      value: 480,
      timeRange: [
        360,
        600
      ]
    },
    {
      label: '08:15',
      disabled: false,
      value: 495,
      timeRange: [
        375,
        615
      ]
    },
    {
      label: '08:30',
      disabled: false,
      value: 510,
      timeRange: [
        390,
        630
      ]
    },
    {
      label: '08:45',
      disabled: false,
      value: 525,
      timeRange: [
        405,
        645
      ]
    },
    {
      label: '09:00',
      disabled: false,
      value: 540,
      timeRange: [
        420,
        660
      ]
    },
    {
      label: '09:15',
      disabled: false,
      value: 555,
      timeRange: [
        435,
        675
      ]
    },
    {
      label: '09:30',
      disabled: false,
      value: 570,
      timeRange: [
        450,
        690
      ]
    },
    {
      label: '09:45',
      disabled: false,
      value: 585,
      timeRange: [
        465,
        705
      ]
    },
    {
      label: '10:00',
      disabled: false,
      value: 600,
      timeRange: [
        480,
        720
      ]
    },
    {
      label: '10:15',
      disabled: false,
      value: 615,
      timeRange: [
        495,
        735
      ]
    },
    {
      label: '10:30',
      disabled: false,
      value: 630,
      timeRange: [
        510,
        750
      ]
    },
    {
      label: '10:45',
      disabled: false,
      value: 645,
      timeRange: [
        525,
        765
      ]
    },
    {
      label: '11:00',
      disabled: false,
      value: 660,
      timeRange: [
        540,
        780
      ]
    },
    {
      label: '11:15',
      disabled: false,
      value: 675,
      timeRange: [
        555,
        795
      ]
    },
    {
      label: '11:30',
      disabled: false,
      value: 690,
      timeRange: [
        570,
        810
      ]
    },
    {
      label: '11:45',
      disabled: false,
      value: 705,
      timeRange: [
        585,
        825
      ]
    },
    {
      label: '12:00',
      disabled: false,
      value: 720,
      timeRange: [
        600,
        840
      ]
    },
    {
      label: '12:15',
      disabled: false,
      value: 735,
      timeRange: [
        615,
        855
      ]
    },
    {
      label: '12:30',
      disabled: false,
      value: 750,
      timeRange: [
        630,
        870
      ]
    },
    {
      label: '12:45',
      disabled: false,
      value: 765,
      timeRange: [
        645,
        885
      ]
    },
    {
      label: '13:00',
      disabled: false,
      value: 780,
      timeRange: [
        660,
        900
      ]
    },
    {
      label: '13:15',
      disabled: false,
      value: 795,
      timeRange: [
        675,
        915
      ]
    },
    {
      label: '13:30',
      disabled: false,
      value: 810,
      timeRange: [
        690,
        930
      ]
    },
    {
      label: '13:45',
      disabled: false,
      value: 825,
      timeRange: [
        705,
        945
      ]
    },
    {
      label: '14:00',
      disabled: false,
      value: 840,
      timeRange: [
        720,
        960
      ]
    },
    {
      label: '14:15',
      disabled: false,
      value: 855,
      timeRange: [
        735,
        975
      ]
    },
    {
      label: '14:30',
      disabled: false,
      value: 870,
      timeRange: [
        750,
        990
      ]
    },
    {
      label: '14:45',
      disabled: false,
      value: 885,
      timeRange: [
        765,
        1005
      ]
    },
    {
      label: '15:00',
      disabled: false,
      value: 900,
      timeRange: [
        780,
        1020
      ]
    },
    {
      label: '15:15',
      disabled: false,
      value: 915,
      timeRange: [
        795,
        1035
      ]
    },
    {
      label: '15:30',
      disabled: false,
      value: 930,
      timeRange: [
        810,
        1050
      ]
    },
    {
      label: '15:45',
      disabled: false,
      value: 945,
      timeRange: [
        825,
        1065
      ]
    },
    {
      label: '16:00',
      disabled: false,
      value: 960,
      timeRange: [
        840,
        1080
      ]
    },
    {
      label: '16:15',
      disabled: false,
      value: 975,
      timeRange: [
        855,
        1095
      ]
    },
    {
      label: '16:30',
      disabled: false,
      value: 990,
      timeRange: [
        870,
        1110
      ]
    },
    {
      label: '16:45',
      disabled: false,
      value: 1005,
      timeRange: [
        885,
        1125
      ]
    },
    {
      label: '17:00',
      disabled: false,
      value: 1020,
      timeRange: [
        900,
        1140
      ]
    },
    {
      label: '17:15',
      disabled: false,
      value: 1035,
      timeRange: [
        915,
        1155
      ]
    },
    {
      label: '17:30',
      disabled: false,
      value: 1050,
      timeRange: [
        930,
        1170
      ]
    },
    {
      label: '17:45',
      disabled: false,
      value: 1065,
      timeRange: [
        945,
        1185
      ]
    },
    {
      label: '18:00',
      disabled: false,
      value: 1080,
      timeRange: [
        960,
        1200
      ]
    },
    {
      label: '18:15',
      disabled: false,
      value: 1095,
      timeRange: [
        975,
        1215
      ]
    },
    {
      label: '18:30',
      disabled: false,
      value: 1110,
      timeRange: [
        990,
        1230
      ]
    },
    {
      label: '18:45',
      disabled: false,
      value: 1125,
      timeRange: [
        1005,
        1245
      ]
    },
    {
      label: '19:00',
      disabled: false,
      value: 1140,
      timeRange: [
        1020,
        1260
      ]
    },
    {
      label: '19:15',
      disabled: false,
      value: 1155,
      timeRange: [
        1035,
        1275
      ]
    },
    {
      label: '19:30',
      disabled: false,
      value: 1170,
      timeRange: [
        1050,
        1290
      ]
    },
    {
      label: '19:45',
      disabled: false,
      value: 1185,
      timeRange: [
        1065,
        1305
      ]
    },
    {
      label: '20:00',
      disabled: false,
      value: 1200,
      timeRange: [
        1080,
        1320
      ]
    },
    {
      label: '20:15',
      disabled: false,
      value: 1215,
      timeRange: [
        1095,
        1335
      ]
    },
    {
      label: '20:30',
      disabled: false,
      value: 1230,
      timeRange: [
        1110,
        1350
      ]
    },
    {
      label: '20:45',
      disabled: false,
      value: 1245,
      timeRange: [
        1125,
        1365
      ]
    },
    {
      label: '21:00',
      disabled: false,
      value: 1260,
      timeRange: [
        1140,
        1380
      ]
    },
    {
      label: '21:15',
      disabled: false,
      value: 1275,
      timeRange: [
        1155,
        1395
      ]
    },
    {
      label: '21:30',
      disabled: false,
      value: 1290,
      timeRange: [
        1170,
        1410
      ]
    },
    {
      label: '21:45',
      disabled: false,
      value: 1305,
      timeRange: [
        1185,
        1425
      ]
    },
    {
      label: '22:00',
      disabled: false,
      value: 1320,
      timeRange: [
        1200,
        1439
      ]
    },
    {
      label: '22:15',
      disabled: false,
      value: 1335,
      timeRange: [
        1215,
        1439
      ]
    },
    {
      label: '22:30',
      disabled: false,
      value: 1350,
      timeRange: [
        1230,
        1439
      ]
    },
    {
      label: '22:45',
      disabled: false,
      value: 1365,
      timeRange: [
        1245,
        1439
      ]
    },
    {
      label: '23:00',
      disabled: false,
      value: 1380,
      timeRange: [
        1260,
        1439
      ]
    },
    {
      label: '23:15',
      disabled: false,
      value: 1395,
      timeRange: [
        1275,
        1439
      ]
    },
    {
      label: '23:30',
      disabled: false,
      value: 1410,
      timeRange: [
        1290,
        1439
      ]
    },
    {
      label: '23:45',
      disabled: false,
      value: 1425,
      timeRange: [
        1305,
        1439
      ]
    }
  ],
  ndcProviders: [
    'AirFranceKlm',
    'Lufthansa',
  ],
  legNumberData: [
    'LEG_1_DEPART_TIME',
    'LEG_2_DEPART_TIME',
    'LEG_3_DEPART_TIME',
    'LEG_4_DEPART_TIME',
    'LEG_5_DEPART_TIME',
    'LEG_6_DEPART_TIME',
  ]
};
