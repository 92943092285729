import PnrRemarksConfiguration from '@/modules/settings/pnr-remarks/PnrRemarksConfiguration.vue';

export default {
  path: ':configurationId?/pnr-remarks-configuration',
  name: 'pnr-remarks-configuration',
  component: PnrRemarksConfiguration,
  displayName: 'PNR Remarks Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPnrRemarks',
    parentName: 'pnr-remarks-configurations',
    depthCategory: 'pnr-remarks-configurations',
    depth: 2,
  }
};