import ThemeConfigurations from '@/modules/settings/theme/ThemeConfigurations.vue';

export default {
  path: '',
  name: 'theme-configurations',
  component: ThemeConfigurations,
  displayName: 'Company theme',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyTheme',
    parentName: 'theme-configurations',
    depthCategory: 'theme-configurations',
    depth: 1,
  },
};