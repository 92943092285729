






import { Vue, Component } from 'vue-property-decorator';

import BookingsTable from './BookingsTable.vue';

@Component({
  components: {
    BookingsTable,
  },
})
export default class Bookings extends Vue {}

