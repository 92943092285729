




















































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import ExpensePerDiemStore  from './expense-per-diem.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { CityCountryModel, LanguageCode } from '@/api/dictionary/dictionary.model';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

@Component({})
export default class PerDiemRate extends Vue {
  $v;
  locationOptions: CityCountryModel[] = [];
  isLoading: boolean = false;
  serverErrors: any[] = [];
  definition: any = {
    location: null,
    validFrom: {
      day: null,
      month: null
    },
    validTo: {
      day: null,
      month: null
    },
    rate: null
  };

  @Validation()
  validationObject() {
    return {
      definition: {
        location: {
          required,
        },
        validFrom: {
          day: {
            required,
          },
          month: {
            required,
          }
        },
        validTo: {
          day: {
            required,
          },
          month: {
            required,
          }
        },
        rate: {
          required
        },
      },
    };
  }

  get dayOptions() {
    const days: any[] = [];
    const dateStart: any = moment();
    const dateEnd = moment().add(30, 'days');
    while (dateEnd.diff(dateStart, 'days') >= 0) {
      days.push(dateStart.format('D'));
      dateStart.add(1, 'days');
    }

    return days.sort((a, b) => { return a - b; });
  }

  get monthOptions() {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get perDiemRateId() {
    return router.currentRoute.params.perDiemRateId;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get errors() {
    return ExpensePerDiemStore.errMessages;
  }

  get showError() {
    return ExpensePerDiemStore.showError;
  }

  get expensesList() {
    return ExpensePolicyStore.configurations;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get displayLocationOptions() {
    return this.locationOptions.map(location => ({ ...location, uniqId: location.type + location.countryCode + location.cityCode }));
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get perDiemSettingsUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/per-diems/${this.expensePolicyId}`;
  }

  get perDiemRate() {
    return ExpensePerDiemStore.perDiemRate;
  }

  onSelectLocation() {
    const selection = this.$refs.PerDiem_location as Vue;
    const selectionEl = selection.$el.getElementsByTagName('input')[0] as HTMLElement;

    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    }
  }

  goToSelectedTab() {
    ExpensePerDiemStore.setPerDiemActiveTab('PerDiemRates');
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    this.goToSelectedTab();

    router.push({
      name: 'per-diems',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findCityCountry(query: string) {
    if (query && query.length > 2) {
      try {
        const result = await DictionaryApi.findCityCountry(query, LanguageCode.EN);
        this.locationOptions = result.data;
      } catch (error) {
        this.serverErrors = this.$handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
      this.locationOptions = [];
    }
  }

  async saveDefinition() {
    ExpensePerDiemStore.setShowError(false);
    ExpensePerDiemStore.setErrMessages([]);
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }
    if (this.errors && this.errors.length === 0) {
      let request = {
        policyId: this.expensePolicyId,
        location: this.definition.location,
        validFrom: this.definition.validFrom,
        validTo: this.definition.validTo,
        rate: {
          amount: this.definition.rate,
          currency: {
            code: this.expenseDetails.currency
          }
        }
      };

      const isEdit = !!this.perDiemRateId;
      if (isEdit) {
        const message = {
          perDiemRateId: this.perDiemRateId,
          params: request,
        };
        await ExpensePerDiemStore.updatePerDiemRate(message);
      } else {
        await ExpensePerDiemStore.createPerDiemRate(request);
      }

      this.goToSelectedTab();

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'per-diems',
          params: {
            id: this.$route.params.id,
            configurationId: this.configurationId,
            itemId: this.$route.params.itemId
          }
        });
      }
    }
  }

  async mapFormData() {
    if (this.perDiemRateId) {
      await ExpensePerDiemStore.getPerDiemRate(this.perDiemRateId);
      if (this.perDiemRate) {
        this.definition = {
          location: this.perDiemRate.location,
          validFrom: {
            day: this.perDiemRate.validFrom!.day,
            month: this.perDiemRate.validFrom!.month
          },
          validTo: {
            day: this.perDiemRate.validTo!.day,
            month: this.perDiemRate.validTo!.month
          },
          rate: this.perDiemRate.rate!.amount
        };
      }
    }
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.configuration.name) {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
    }
    this.mapFormData();
  }
}
