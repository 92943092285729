












































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { RailLocationModel} from '@/api/home/home.model';

@Component
export default class UiRailAutocompleteTemplate extends Vue {
  @Prop() props!: RailLocationModel;
}

