









































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import HotelOfferConditions from './HotelOfferConditions.vue';

import { router } from '@/router';
import HotelSearchStore from './hotel-search.store';
import SearchStore from '@/modules/search/search.store';
import { 
  AccommodationOfferSearchResult,
  Offer,
} from '@/api/accommodation-engine/accommodation-search.model';
import HotelDetailsSection from './HotelDetailsSection.vue';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import EditHotelFeePopup from './EditHotelFeePopup.vue';
import EditHotelAgencyMarkupPopup from './EditHotelAgencyMarkupPopup.vue';
import EventBus from '@/services/event-handler';

const amenitiesMap = {
  NON_SMOKING: 'smoke_free',
  FREE_WIFI: 'wifi',
  DAILY_HOUSEKEEPING: 'house',
  PHONE: 'phone',
  AIR_CONDITIONING: 'ac_unit',
  ACCESSIBLE: 'accessible',
  FREE_BREAKFAST: 'local_dining',
  NON_SMOKING_ROOMS: 'smoke_free',
  RESTAURANTS: 'restaurant',
  GREEN_AREAS: 'local_florist',
  MEETING_ROOM: 'meeting_room',
  AIRPORT_SHUTTLE: 'airport_shuttle',
  ROOM_SERVICE: 'room_service',
  FITNESS_CENTER: 'fitness_center',
  PARKING: 'local_parking',
};

@Component({
  components: {
    HotelOfferConditions,
    HotelDetailsSection,
    EditHotelFeePopup,
    EditHotelAgencyMarkupPopup,
  }
})
export default class HotelDetails extends Vue {
  propertyOffers: AccommodationOfferSearchResult | null = null;
  filteredOffers: Offer[] = [];
  activeTab: string = 'Rooms';
  selectedOffer?: any = null;
  offerConditionsVisible: boolean = false;
  offersLoaded: boolean = false;
  propertyContent: any = {};
  errors: any[] = [];
  loading: boolean = false;

  get offer() {
    return Object.keys(HotelSearchStore.detailedOffer).length !== 0 ?
    HotelSearchStore.detailedOffer : false;
  }

  get hasNoOffers() {
    return this.offersLoaded && 0 === this.filteredOffers.length;
  }

  get searchId() {
    return this.$route.params.searchId;
  }

  get mockedHotelUrls() {
    return this.offer ? [
      this.offer.property.mainImageUrl,
      this.offer.property.mainImageUrl,
      this.offer.property.mainImageUrl,
    ] : [];
  }

  get hotelPhotos() {
    if (this.propertyContent && this.propertyContent.images) {
      return this.propertyContent.images.map(photo => {
        if (photo && photo.url) {
          return photo.url;
        }
      });
    } else {
      return [];
    }
  }

  get isBasketRoute() {
    return this.$route.name === 'hotelDetailsBasket';
  }

  get shouldShowGoBack() {
    return this.$route.name !== 'hotelDetailsBasket';
  }

  get result() {
    return HotelSearchStore.offers.find(item => {
      return item.property.id === this.$route.params.propertyId;
    });
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  @Watch('$route.params.propertyId', { immediate: true})
  async propertyIdChange() {
    await this.getPropertyContent();
  }

  amenityIcon(amenity: string) {
    return amenitiesMap[amenity.toUpperCase().replace(/\s|([()-/])/g, '_')] ?
            amenitiesMap[amenity.toUpperCase().replace(/\s|([()-/])/g, '_')] :
            'check';
  }

  goBack() {
    router.push({
      name: 'hotel',
      params: { searchId: this.$route.params.searchId }
    });
  }

  selectOffer(offer) {
    HotelSearchStore.selectOffer(offer);
  }

  showConditions(offer) {
    this.selectedOffer = offer;
    this.offerConditionsVisible = true;
  }

  showOnMap(offer) {

  }

  async loadOffers() {
    try {
      this.loading = true;
      this.propertyOffers = await HotelSearchStore.loadPropertyOffers({
        searchId: this.$route.params.searchId,
        propertyId: this.$route.params.propertyId,
      });
      
      HotelSearchStore.updateOfferStats({
        offerId: this.$route.params.propertyId,
        stats: this.propertyOffers.propertySearchStatistics,
      });
      this.filteredOffers = this.propertyOffers.offers;
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
      this.offersLoaded = true;
    }
  }

  async initView() {
    if (this.searchId && !this.isBasketRoute) {
      try {
        this.loading = true;
        HotelSearchStore.getSearchSession(this.searchId);
        HotelSearchStore.search(this.searchId);
        await this.loadOffers();
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.loading = false;
      }
    }
  }

  async getPropertyContent() {
    if (this.$route.params.propertyId) {
      let content;
      try {
        this.loading = true;
        content = await AccommodationSearchApi.getPropertyContent(this.$route.params.propertyId);
      } catch (error) {
        this.errors = this.$handleErrors(error, false, {
          codes: {
            [404]: error.response.data.error.message
          }
        });
      } finally {
        if (content && content.data) {
          this.propertyContent = content.data;
        }
        this.loading = false;
      }
    }
  }

  onHotelRoomEditFee(offer) {
    if (!this.propertyOffers || !this.propertyOffers.offers) {
      return;
    }
    const index = this.propertyOffers.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-fee-popup');
  }

  onHotelRoomEditAgencyMarkup(offer) {
    if (!this.propertyOffers || !this.propertyOffers.offers) {
      return;
    }
    const index = this.propertyOffers.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-agency-markup-popup');
  }

  async mounted() {
    try {
      if (!this.isBasketRoute) {
        const sessionResponse = await AccommodationSearchApi.getSearchSession(this.$route.params.searchId);
        const meta = sessionResponse.data.metadata;
        SearchStore.setMissionId(meta.missionId);
        if (meta.missionId !== null) {
          await SearchStore.getMissionDetails();
        }
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  created() {
    EventBus.$on('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$on('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
  }

  beforeDestroy() {
    EventBus.$off('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$off('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
  }
}
