import {
  CompanyConfiguration,
} from '@/api/profile/company.model';

export interface CustomFieldResponseModel {
  rootCompanyId: string;
  name: string;
  description: string;
  typeId: string;
  validationRegExpRule: string;
  regExpValidationMessage: string;
  formatGuidanceText: string;
  code: string;
  customFieldDictionaryId?: string;
  isActive: boolean;
}

export interface UpdateFieldMessage {
  customFieldId: string;
  field: CustomFieldResponseModel;
}

export interface FieldRequestMessage {
  companyId: string;
  configurationId: string;
}

export interface UpdateFieldConfigurationMessage {
  rootCompanyId: string;
  name: string;
}

export interface CopyFieldConfigurationMessage {
  configurationId: string;
  configurationName: string;
}

export interface CustomFieldValueModel {
  code: string;
  value: string;
}

export interface CustomFieldTypeModel {
  id: string;
  type?: string;
  value?: string;
}

export interface CustomFieldValueResponseModel {
  id: string;
  profileId: string;
  code: string;
  value: string;
}

export interface CustomFieldDictionaryModel {
  rootCompanyId: string;
  name: string;
  description: string;
  isActive: boolean;
}

export interface CreateCustomFieldDictionaryMessaage {
  rootCompanyId: string;
  Name: string;
  Description: string;
  IsActive: boolean;
  configurationId: string;
}

export interface EditedCustomFieldDictionary {
  Name: string;
  Description: string;
  IsActive: boolean;
}

export interface EditedCustomFieldDictionaryItem {
  code: string;
  description: string;
  value?: string;
  isActive: boolean;
}

export interface UpdateCustomFieldDictionaryItemMessage {
  customFieldDictionaryItemId: string;
  item: EditedCustomFieldDictionaryItem;
}

export interface UpdateCustomFieldDictionaryMessage {
  customFieldDictionaryId: string;
  dictionary: EditedCustomFieldDictionary;
}

export interface CustomFieldSimpleModel {
  id: string;
  name: string;
}

export interface ProviderMappingCustomFieldModel {
  id: string;
  name: string;
  type: SimpleCustomFieldType;
  code: string;
}

export interface ProviderMappingModel {
  id: string;
  configurationId: string;
  provider: string;
  statisticalFieldType: string;
  customField: ProviderMappingCustomFieldModel;
  prefix: string;
  suffix: string;
  remarkType: string;
}

export interface CreateProviderMappingMessage {
  configurationId?: string;
  customFieldDefinitionId: string;
  provider: string;
  statisticalFieldType: string;
  prefix: string;
  suffix: string;
  remarkType: string;
}

export interface CreateProviderMappingResponse {
  id: string;
}

export interface TravelAssignmentCustomFieldModel {
  id: string;
  name: string;
  code: string;
  type: SimpleCustomFieldType;
}

export interface TravelAssignmentModel {
  id: string;
  configurationId: string;
  assignmentLevel: TravelAssignmentLevel;
  customField: CustomFieldSimpleModel;
  isActive: boolean;
  defaultValue: any;
  customFieldDictionaryItemId: any;
  order: number;
}

export interface CreateTravelAssignmentMessage {
  configurationId?: string;
  customFieldDefinitionId?: string;
  order: number;
  isActive: boolean;
  defaultValue: any;
  customFieldDictionaryItemId?: string;
  assignmentLevel: TravelAssignmentLevel;
  usageContext: string;
  modificationContexts: TravelAssignmentModificationContextItem[];
}

export interface CreateTravelAssignmentResponse {
  travelCustomFieldAssignmentId: string;
}

export interface TravelAssignmentDetails {
  id: string;
  configurationId: string;
  customField: TravelAssignmentCustomFieldModel;
  order: number;
  isActive: boolean;
  defaultValue: any;
  customFieldDictionaryItemId?: string;
  assignmentLevel: TravelAssignmentLevel;
  usageContext: string;
  modificationContexts: TravelAssignmentModificationContextItem[];
}

export interface TravelAssignmentModificationContextItem {
  mandatory: boolean;
  canBeModified: boolean;
  modificationContext: string;
}

export enum TravelAssignmentLevel {
  Reservation = 'Reservation',
  Profile = 'Profile',
}

export enum TravelAssignmentModificationContext {
  Booking = 'Booking',
  Approval = 'Approval',
}

export interface DictionaryModel {
  id: string;
  rootCompanyId: string;
  configurationId: string;
  name: string;
  description: string;
  isActive: boolean;
}

export interface CustomFieldModel {
  id: string;
  rootCompanyId: string;
  configurationId: string;
  name: string;
  description: string;
  typeId: string;
  validationRegExpRule: string;
  regExpValidationMessage: string;
  formatGuidanceText: string;
  code: string;
  customFieldDictionaryId: string;
  isActive: boolean;
}

export interface SimpleCustomFieldModel {
  id: string;
  name: string;
  validationRegExpRule: string;
  regExpValidationMessage: string;
  customFieldDictionaryItems: CustomFieldDictionaryItem[];
  type: SimpleCustomFieldType;
}

export interface CustomFieldDictionaryItem {
  id: string;
  name: string;
}

export interface SimpleCustomFieldType {
  id: string;
  type: string;
}

export class CustomFieldDictionaryItemEntry {
  id?: string;
  name: string = '';
  description: string = '';
  code: string = '';
  value: string = '';
  isActive: boolean = true;

  constructor(entry?) {
    if (!entry) {
      return;
    }
    if (entry.id !== undefined) {
      this.id = entry.id;
    }
    if (entry.name !== undefined) {
      this.name = entry.name;
    }
    if (entry.code !== undefined) {
      this.code = entry.code;
    }
    if (entry.description !== undefined) {
      this.description = entry.description;
    }
    if (entry.value !== undefined) {
      this.value = entry.value;
    }
    if (entry.isActive !== undefined) {
      this.isActive = entry.isActive;
    }
  }
}

export class CustomFieldDefinitionModel {
  id?: string = '';
  name: string = '';
  description: string = '';
  code: string = '';
  isActive: boolean = true;
  collectionModes: any = {};
  valueType: CustomFieldTypeModel | null = null;
  typeId?: string;
  validationRegExpRule: string = '';
  regExpValidationMessage: string = '';
  dictionary: DictionaryModel | null = null;
  customFieldDictionaryId: string | null = null;
  formatGuidanceText: string = '';

  constructor(field?) {
    if (!field) {
      return;
    }
    if (field.id !== undefined) {
      this.id = field.id;
    }
    if (field.name !== undefined) {
      this.name = field.name;
    }
    if (field.description !== undefined) {
      this.description = field.description;
    }
    if (field.code !== undefined) {
      this.code = field.code;
    }
    if (field.isActive !== undefined) {
      this.isActive = field.isActive;
    }
    if (field.collectionModes !== undefined) {
      this.collectionModes = field.collectionModes;
    }
    if (field.valueType !== undefined) {
      this.valueType = field.valueType;
    }
    if (field.typeId !== undefined) {
      this.typeId = field.typeId;
    }
    if (field.validationRegExpRule !== undefined) {
      this.validationRegExpRule = field.validationRegExpRule;
    }
    if (field.regExpValidationMessage !== undefined) {
      this.regExpValidationMessage = field.regExpValidationMessage;
    }
    if (field.dictionary !== undefined) {
      this.dictionary = field.dictionary;
    }
    if (field.customFieldDictionaryId !== undefined) {
      this.customFieldDictionaryId = field.customFieldDictionaryId;
    }
    if (field.formatGuidanceText !== undefined) {
      this.formatGuidanceText = field.formatGuidanceText;
    }
  }
}

export class CustomFieldConfigurationModel {
  id: string = '';
  rootCompanyId: string = '';
  configurationType: string = '';
  name: string = '';

  constructor(config?: CompanyConfiguration) {
    if (config) {
      if (config.id !== undefined) {
        this.id = config.id;
      }
      if (config.rootCompanyId !== undefined) {
        this.rootCompanyId = config.rootCompanyId;
      }
      if (config.configurationType !== undefined) {
        this.configurationType = config.configurationType;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
    }
  }
}


export class CustomFieldDictionaryDefinitionModel {
  id?: string = '';
  name: string = '';
  description: string = '';
  isActive: boolean = true;
  items: CustomFieldDictionaryItemEntry[] = [];

  constructor(definition?) {
    if (!definition) {
      return;
    }
    if (definition.id !== undefined) {
      this.id = definition.id;
    }
    if (definition.name !== undefined) {
      this.name =  definition.name;
    }
    if (definition.description !== undefined) {
      this.description =  definition.description;
    }
    if (definition.isActive !== undefined) {
      this.isActive =  definition.isActive;
    }
    if (definition.items !== undefined) {
      this.items =  definition.items;
    }
  }
}

export enum CustomFieldValueType {
  String = 'Text',
  Integer = 'Numeric (integer)',
  Double = 'Numeric (double)',
  Bool = 'Logical',
  Dictionary = 'Dictionary',
}

export enum CustomFieldValueTypeTranslation {
  String = 'settings-custom-fields.text',
  Integer = 'settings-custom-fields.numeric-integer',
  Double = 'settings-custom-fields.numeric-double',
  Bool = 'settings-custom-fields.logical',
  Dictionary = 'settings-custom-fields.dictionary',
}

export enum ProviderMappingProvider {
  Teldar = 'Teldar',
  HCorpo = 'HCorpo',
  Amadeus = 'Amadeus',
}