import HotelSearchShortParams from '@/modules/search/hotel/HotelSearchShortParams.vue';
import HotelResultDetails from '@/modules/search/hotel/HotelResultDetails.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotel-results-offer-details/:searchId/:propertyId',
  name: 'hotelResultDetails',
  components: {
    default: HotelResultDetails,
    intro: HotelSearchShortParams,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-hotel-results',
    permission: 'SearchOffers',
    mobile: true,
    preserveSidebar: {
      hotelDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        hotelDetails: AnimationName.DOWN,
      },
      subintro: {
        hotel: AnimationName.UP,
      },
    }
  }
};
