import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

export interface InitCarSearchMessage {
  travellers: Traveller[];
  pickupDate: {
    date: string;
    time: string;
  };
  returnDate: {
    date: string;
    time: string;
  };
  pickupLocation: HotelLocationModel;
  returnLocation: HotelLocationModel;
  radius?: {
    amount: number;
    unit: string;
  };
  searchCompanyId: string | null;
  extras: string[];
}

export interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Car {
  id: string;
  recommendation: any;
}

export interface SortOptions {
  START_DATE?: string;
  PRICE?: string;
}

export interface OffersQuery {
  searchId: string;
  sortQuery: string;
}

export interface FareConditionsMessage {
  supplier: string;
  supplierSearchId: string | null;
  searchId: string | null;
  extras: string[] | null;
}

export enum CarSearchLocationType {
  Unknown = 'Unknown',
  IataLocation = 'IataLocation',
  GeoPoint = 'GeoPoint',
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface AgencyMarkup {
  amount: number;
  currency: Currency;
}


export enum CarType {
  B = '2-3 Door',
  C = '2-4 Door',
  D = '4-5 Door',
  W = 'Wagon/Estate',
  V = 'Passenger Van',
  L = 'Limousine',
  S = 'Sport',
  T = 'Convertible',
  F = 'SUV',
  J = 'Open Air All Terrain',
  X = 'Special',
  P = 'Pick up Regular Cab',
  Q = 'Pick up Extended Cab',
  Z = 'Special Offer Car',
  E = 'Coupe',
  M = 'Monospace (European use)',
  H = 'Motor Home',
  Y = '2 Wheel Vehicle',
  N = 'Roadster'
}
