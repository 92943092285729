import PluginsConfigurations from '@/modules/settings/plugins/PluginsConfigurations.vue';

export default {
  path: '',
  name: 'plugins-configurations',
  component: PluginsConfigurations,
  displayName: 'Plugins Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPlugins',
    depthCategory: 'plugins-configurations',
    parentName: 'plugins-configurations',
    depth: 1,
  }
};