
export default {
  unknown: 'common-error.unable-process',
  unavailable: 'common-error.unavailable',
  noPermission: 'common-error.permissions',
  notFound: 'common-error.could-not-find',
  serviceTagPrefix: 'common-error.service-tag',
  expired: 'common-error.expired',
  offline: 'common-error.offline',
  serviceTagSufix: ')',
};
