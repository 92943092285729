import { Vue, Component, Watch } from 'vue-property-decorator';
import { BaseTableParams } from './base-table-params';
import consts from '@/const/table.const';
import { router } from '@/router';
import { SearchPageModel } from '@/api/trip/trip.model';

export interface GetItemsResult<T> {
  results: T[];
  page: SearchPageModel;
}

@Component({})
export class BaseTable<T> extends Vue {
  loading: boolean = false;
  busy: boolean = false;
  results: T[] = [];
  page: SearchPageModel | null = null;
  hasMoreData: boolean = true;
  counted: number = 0;
  params: BaseTableParams = new BaseTableParams();
  useQueryParams: boolean = false;
  defaultSort: string = '';
  defaultDesc: boolean = true;
  fillingParams: boolean = false;
  pageSize: number = 10;

  fillQuery(query: any): any {
    // to override when extending
  }

  async fillParameters(query: any): Promise<void> {
    // to override when extending
  }

  async getItems(params: any): Promise<any> {
    // to override when extending
  }

  set currentPage(value: number) {
    this.params.page = value;
  }

  get currentPage(): number {
    return this.params.page;
  }

  get perPageOptions() {
    return consts.perPageOptions;
  }

  startLoading() {
    this.hasMoreData = false;
    this.loading = true;
  }

  errorHappened() {
    this.hasMoreData = false;
    this.loading = false;
  }

  updateData({ params, data }) {
    if (this.params.size !== params.size) {
      this.counted = 0;
    }
    this.params = params;
    this.loading = false;
    this.results = data.results;
    this.page = data.page;
    this.hasMoreData = data.page.moreResultsAvailable;
    this.counted = Math.max(this.counted, params.page + data.results.length);
  }

  get count() { // dynamic counter of the data loaded
    let result = Math.max(
      this.params.page + Math.min(this.results.length, this.params.size),
      this.counted
    );
    if (this.loading) {
      result = this.params.page + this.params.size;
    }
    if (this.hasMoreData) {
      result += 1;
    }
    return result;
  }

  async load() {
    if (this.fillingParams) {
      this.hasMoreData = true;
      return [];
    }
    if (!this.getItems) {
      this.hasMoreData = false;
      return [];
    }
    if (this.params.sort === null) {
      this.params.sort = this.defaultSort;
    }
    this.startLoading();
    if (this.useQueryParams) {
      this.fillQueryParams();
    }
    let data: GetItemsResult<T>;
    try {
      data = await this.getItems(this.params);

      this.updateData({
        params: this.params,
        data,
      });
    } catch {
      this.errorHappened();
      return;
    }

    return data.results;
  }

  fillQueryParams() {
    let queryParameters: any = {};
    if (!this.params.usePageParam) {
      if (this.params.start > 0) {
        queryParameters.start = this.params.start;
      }
    } else {
      if (this.params.page && this.params.page > 1) {
        queryParameters.page = this.params.page;
      }
    }
    if (this.params.size !== consts.defaultPerPage) {
      queryParameters.size = this.params.size;
    }
    if (this.params.sort !== this.defaultSort) {
      queryParameters.sort = this.params.sort;
    }
    if (this.params.desc !== this.defaultDesc) {
      queryParameters.desc = this.params.desc;
    }

    if (this.fillQuery) {
      queryParameters = this.fillQuery(queryParameters) || queryParameters;
    }

    for (const key in queryParameters) {
      if (queryParameters.hasOwnProperty(key)) {
        const element = queryParameters[key];
        if (element == null || element.length === 0) {
          delete queryParameters[key];
        }
      }
    }

    router.push({
      name: router.currentRoute.name,
      params: router.currentRoute.params,
      query: queryParameters
    });
  }

  resetList(page?: number) {
    this.counted = 0;
    if (this.params.usePageParam) {
      this.params.page = page ? page : 1;
    } else {
      this.params.start = page ? page : 0;
    }
  }

  async reload() {
    this.$nextTick(() => {
      const table = this.$refs.table as any;
      if (table && table.refresh) {
        table.refresh();
      }
    });
  }

  @Watch('params.size', { immediate: true })
  onSizeChange(newValue, oldValue) {
    if (oldValue < newValue) {
      const lastItemsIndex = oldValue * this.params.page;
      this.params.page = Math.round(lastItemsIndex / newValue);
      if (this.params.page === 0) {
        this.params.page += 1;
      }
    }

    this.pageSize = newValue;
  }

  @Watch('$route', { immediate: true })
  async onRouteChange() {
    if (!this.useQueryParams) {
      return;
    }
    const query = this.$route.query;
    if (this.params.usePageParam) {
      this.params.page = query.page ? parseInt(query.page as string) : 1;
    } else {
      this.params.start = query.start ? parseInt(query.start as string) : 0;
    }
    this.params.size = query.size ? parseInt(query.size as string) || consts.defaultPerPage : consts.defaultPerPage;

    this.counted = 0;
    if (this.fillParameters) {
      this.fillingParams = true;
      await this.fillParameters(query);
    }
    this.fillingParams = false;
    this.$nextTick(() => {
      this.reload();
    });
  }
}