































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import BasketStore from './basket.store';
import BasketPaymentMethods from './paymentmethods/BasketPaymentMethods.vue';
import TrainFareDiscount from '@/modules/search/train/TrainFareDiscount.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

import EventBus from '@/services/event-handler';

@Component({
  components: {
    BasketItem,
    BasketPaymentMethods,
    TrainFareDiscount,
    BasketExpirationCountdown,
  }
})
export default class BasketPaymentMethodsView extends Vue {
  @Prop() pendingActions?: any;
  @Prop() basketExpired!: boolean;
  @Prop() itemId!: string;
  @Prop({ default: true }) showHeader!: boolean;

  segmentsMeta: any[] = [];
  showDiscountInfoPopup: boolean = false;

  get basket() {
    return BasketStore.basket;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get loading() {
    return BasketStore.loadingPaymentMethods;
  }

  get bookingStepDef() {
    return BasketStore.bookingStepDef;
  }

  get basketPaymentMethods() {
    if (!this.bookingStepDef.tripItemId) {
      return BasketStore.basketPaymentMethods;
    }

    return BasketStore.basketPaymentMethods.filter(item => {
      return item.id === this.bookingStepDef.tripItemId;
    });
  }

  get errors() {
    return BasketStore.storePaymentErrors;
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get showTitle() {
    return this.basketPaymentMethods.find(item => item.paymentMethods && item.paymentMethods.selectedPaymentMethod && item.paymentMethods.selectedPaymentMethod !== 'CentralPayment') || this.isInWizard;
  }

  get currentItem() {
    return this.basketPaymentMethods.find(item => item.id === this.itemId);
    }

  get isSabreCardPaymentError() {
    return this.currentItem &&
      this.pendingActions &&
      this.currentItem.paymentMethods &&
      -1 === [
        'CentralPayment',
        'VirtualCard',
      ].indexOf(this.currentItem.paymentMethods.selectedPaymentMethod);
  }


  showDiscountInfo() {
    this.showDiscountInfoPopup = true;
  }

  async onDropdownChange($event, index) {
    this.segmentsMeta[index].opened = $event;
    await BasketStore.recalculateBasketPaymentMethodsPrices();
    EventBus.$emit('basket-item-fee-changed');
    if ((this.segmentsMeta[index].paymentMethod && this.segmentsMeta[index].paymentMethod !== this.basketPaymentMethods[index].selectedPaymentCard.paymentMethod) || (this.basketPaymentMethods[index].selectedPaymentCard && this.basketPaymentMethods[index].selectedPaymentCard.paymentMethod)) {
      this.basketPaymentMethods[index].selectedPaymentCard.recalculateFee = true;
    } else {
      if (this.basketPaymentMethods[index] && this.basketPaymentMethods[index].selectedPaymentCard) {
        this.basketPaymentMethods[index].selectedPaymentCard.recalculateFee = false;
      }
    }
    if (!this.segmentsMeta[index].paymentMethod || (this.segmentsMeta[index] && this.segmentsMeta[index].paymentMethod && this.basketPaymentMethods[index] && this.basketPaymentMethods[index].selectedPaymentCard)) {
      this.segmentsMeta[index].paymentMethod = this.basketPaymentMethods[index].selectedPaymentCard ? this.basketPaymentMethods[index].selectedPaymentCard.paymentMethod : undefined;
    }
  }

  onBasketItemPaymentCardChanged(item) {
    let selectedItem = this.basketItems.find(basketItem => basketItem.id === item.id);
    if (selectedItem) {
      selectedItem.selectedPaymentCard = item.selectedPaymentCard;
    }
  }

  @Watch('basketPaymentMethods')
  onMethodsChange(value) {
    if (value) {
      this.segmentsMeta = [];
      value.forEach(item => {
        this.segmentsMeta.push({
          opened: false,
        });
      });
    }
  }

  async created() {
    BasketStore.resetSelectedPaymentMethods();
    await BasketStore.loadPaymentMethods();
    await BasketStore.loadItemsFeePaymentInfo();
  }
}

