import { BaseTableParams } from '@/core/base-table-params';
import { Range } from '@/api/trip/trip.model';
import {
  OfflineRequestStatus,
  OfflineRequestType,
} from '@/api/trip/offline-request.model';

export class OfflineRequestsTableParams extends BaseTableParams {
  requestTypes: any[] = [];
  agents: any[] = [];
  associatedTripCode: string = '';
  requestDate: any = {
    from: '',
    to: '',
  };
  requestStatuses: any[] = [];
  requestors: any[] = [];
  companies: any[] = [];

  public constructor(params: any) {
    super(params);
  }
}
