








import { Vue, Component, Prop } from 'vue-property-decorator';
import { RequestStatusList } from '@/const/request-status-list.const';
import { translate } from '@/i18n';

@Component({})
export default class OfflineRequestStatus extends Vue {
  @Prop({ default: 'New' }) status!: string;
  @Prop({ default: false }) right!: boolean;

  get statusText() {
    let text = 'New';
    const item = RequestStatusList.find(x => x.value === this.status);
    if (item) {
      text = translate(item.name);
    }
    return text;
  }

  get statusClass() {
    return this.status.toLowerCase();
  }
}
