

















































































































































































import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class Button extends Vue {

  loading = false;

  anim() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    });
  }

}
