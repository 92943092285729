









import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class UiTrainLogo extends Vue {
  @Prop() name!: string;
  @Prop({ default: 'small' }) size!: String;

  loaded = false;
  imagesConst = '/assets/img/raillogos/__CODE__.png';

  get source() {
    return this.imagesConst.replace('__CODE__', this.name);
  }

  get classes() {
    if (this.loaded) {
      const el = this.$refs.image as HTMLElement;
      const proportions = el.offsetWidth / el.offsetHeight > 1.3 ? ( this.name === 'SNCF' ? 'short-landscape' : 'long-landscape') : 'portrait';
      return ['loaded', proportions].join(' ');
    }
    return 'loading';
  }

  imageLoaded() {
    this.loaded = true;
  }

  @Watch('source')
  onChange() {
    this.loaded = false;
  }

}
