import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { CreateConfiguration, Configuration, PnrRemarksConfiguration, SsrCodes } from '@/api/pnr-remarks/pnr-remarks.model';
import { PnrRemarksApi } from '@/api/pnr-remarks/pnr-remarks.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'pnrRemarks'
})
class PnrRemarksStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  configurationId: string = '';
  pnrRemarksConfiguration: PnrRemarksConfiguration = new PnrRemarksConfiguration();
  airRemarks: Configuration[] = [];
  carRemarks: Configuration[] = [];
  accommodationRemarks: Configuration[] = [];
  airSsrCodes: SsrCodes[] = [];
  selectedTab: string = 'Flight';
  errMessages: string[] = [];
  showError: boolean = false;
  uniqueIdPrefix: string = 'remark_id_';
  customError: boolean = false;


  get canShowCustomError() {
    return this.customError;
  }

  get PnrRemarksConfiguration() {
    return this.pnrRemarksConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }


  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setPnrRemarksConfiguration(value) {
    this.pnrRemarksConfiguration = value;
  }

  @Mutation
  setAirRemarks(value) {
    this.airRemarks = value;
  }

  @Mutation
  setCarRemarks(value) {
    this.carRemarks = value;
  }

  @Mutation
  setAccommodationRemarks(value) {
    this.accommodationRemarks = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
    router.push({
      name: 'pnr-remarks-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setSelectedTab(value) {
    this.selectedTab = value;
  }

  @Mutation
  addAirRemark({supplier, pnrSsrCodes}) {
    let remarkObject = this.airRemarks.find(e => {
      return e.supplier === supplier;
    });

    if (remarkObject) {
      remarkObject.pnrRemarks.push({
        id: _.uniqueId(this.uniqueIdPrefix),
        seqNo: remarkObject.pnrRemarks.length ? Math.max(...remarkObject.pnrRemarks.map(e => e.seqNo)) + 1 : 0,
        remark: '',
        type: '',
        category: null,
        airlines: null,
      });
    } else {
      this.airRemarks.push({
        supplier,
        pnrRemarks: [{
          id: _.uniqueId(this.uniqueIdPrefix),
          seqNo: 0,
          remark: '',
          type: '',
          category: null,
          airlines: null,
        }],
        pnrSsrCodes: pnrSsrCodes,
      });
    }
  }

  @Mutation
  addAirSsrCode({supplier, pnrRemarks}) {
    let remarkObject = this.airRemarks.find(e => {
      return e.supplier === supplier;
    });

    if (remarkObject && remarkObject.pnrSsrCodes) {
      remarkObject.pnrSsrCodes.push({
        airlines: null,
        type: '',
        value: '',
      });
    } else {
      this.airRemarks.push({
        supplier,
        pnrSsrCodes: [{
          airlines: null,
          type: '',
          value: '',
        }],
        pnrRemarks: pnrRemarks,
      });
    }
  }

  @Mutation
  addCarRemark(supplier) {
    let remarkObject = this.carRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject) {
      remarkObject.pnrRemarks.push({
        id: _.uniqueId(this.uniqueIdPrefix),
        seqNo: remarkObject.pnrRemarks.length ? Math.max(...remarkObject.pnrRemarks.map(e => e.seqNo)) + 1 : 0,
        remark: '',
        type: '',
        category: null,
      });
    } else {
      this.carRemarks.push({
        supplier,
        pnrRemarks: [{
          id: _.uniqueId(this.uniqueIdPrefix),
          seqNo: 0,
          remark: '',
          type: '',
          category: null,
        }]
      });
    }
  }

  @Mutation
  addAccommodationRemark(supplier) {
    let remarkObject = this.accommodationRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject) {
      remarkObject.pnrRemarks.push({
        id: _.uniqueId(this.uniqueIdPrefix),
        seqNo: remarkObject.pnrRemarks.length ? Math.max(...remarkObject.pnrRemarks.map(e => e.seqNo)) + 1 : 0,
        remark: '',
      });
    } else {
      this.accommodationRemarks.push({
        supplier,
        pnrRemarks: [{
          id: _.uniqueId(this.uniqueIdPrefix),
          seqNo: 0,
          remark: '',
        }]
      });
    }
  }

  @Mutation
  removeAirSsrCode({ index, supplier }) {
    let remarkObject = this.airRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject && remarkObject.pnrSsrCodes) {
      if (-1 < index) {
        remarkObject.pnrSsrCodes.splice(index, 1);
      }
    }
  }

  @Mutation
  removeCarRemark({ index, supplier }) {
    let remarkObject = this.carRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject && remarkObject.pnrRemarks) {
      if (-1 < index) {
        remarkObject.pnrRemarks.splice(index, 1);
      }
    }
  }

  @Mutation
  removeAccommodationRemark({ index, supplier }) {
    let remarkObject = this.accommodationRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject && remarkObject.pnrRemarks) {
      if (-1 < index) {
        remarkObject.pnrRemarks.splice(index, 1);
      }
    }
  }

  @Mutation
  removeAirRemark({ index, supplier }) {
    let remarkObject = this.airRemarks.find(e => {
      return e.supplier === supplier;
    });
    if (remarkObject && remarkObject.pnrRemarks) {
      if (-1 < index) {
        remarkObject.pnrRemarks.splice(index, 1);
      }
    }
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  addRemark({ service, supplier, pnrSsrCodes } ) {
    if (service === 'Flight') {
      this.addAirRemark({ supplier, pnrSsrCodes });
    } else if (service === 'Car') {
      this.addCarRemark(supplier);
    } else if (service === 'Accommodation') {
      this.addAccommodationRemark(supplier);
    }
  }

  @Action
  removeRemark({ index, supplier }) {
    if (this.selectedTab === 'Flight') {
      this.removeAirRemark({ index, supplier });
    } else if (this.selectedTab === 'Car') {
      this.removeCarRemark({ index, supplier });
    } else if (this.selectedTab === 'Accommodation') {
      this.removeAccommodationRemark({ index, supplier });
    }
  }
  
  @Action
  clearData() {
    this.setPnrRemarksConfiguration(new PnrRemarksConfiguration());
    this.setCurrentConfigurationName('');
    this.setAirRemarks([]);
    this.setCarRemarks([]);
    this.setAccommodationRemarks([]);
  }

  @Action
  async getAirConfiguration(configurationId: string) {
    const response = await PnrRemarksApi.getAir(configurationId);
    if (response && response.data) {
      this.setPnrRemarksConfiguration(response.data);
      this.setCurrentConfigurationName(response.data.name);
      this.setConfigurationId(configurationId);
      this.setAirRemarks(response.data.configurations);
    }
  }

  @Action
  async getCarConfiguration(configurationId: string) {
    const response = await PnrRemarksApi.getCar(configurationId);
    if (response && response.data) {
      this.setCurrentConfigurationName(response.data.name);
      this.setCarRemarks(response.data.configurations);
    }
  }

  @Action
  async getAccommodationConfiguration(configurationId: string) {
    const response = await PnrRemarksApi.getAccommodation(configurationId);
    if (response && response.data) {
      this.setCurrentConfigurationName(response.data.name);
      this.setAccommodationRemarks(response.data.configurations);
    }
  }

  @Action
  async getAll(configurationId: string) {
    this.setCustomError(false);
    try {
      this.setLoading(true);
      await this.getAirConfiguration(configurationId);
      await this.getCarConfiguration(configurationId);
      await this.getAccommodationConfiguration(configurationId);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createAirConfiguration(request: CreateConfiguration) {
    const result = await PnrRemarksApi.createAir(request);
    if (result && result.data) {
      this.setConfigurationId(result.data.configurationId);
      this.setCurrentConfigurationName(request.name);
    }
  }

  @Action
  async updateAir({ configurationId, request }) {
    await PnrRemarksApi.updateAir(configurationId, request);
  }

  @Action
  async updateCar({ configurationId, request }) {
    await PnrRemarksApi.updateCar(configurationId, request);
  }

  @Action
  async updateAccommodation({ configurationId, request }) {
    await PnrRemarksApi.updateAccommodation(configurationId, request);
  }

  @Action
  async createAll({ air, car, accommodation }) {
    try {
      this.setLoading(true);
      this.setCustomError(false);
      await this.createAirConfiguration(air);
      if (this.configurationId) {
        await this.updateCar({ configurationId: this.configurationId, request: car });
        await this.updateAccommodation({ configurationId: this.configurationId, request: accommodation });
      }

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-pnr-remarks.config-saved')
      });
      await this.getAll(this.configurationId);

    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateAll({ air, car, accommodation }) {
    try {
      this.setLoading(true);

      await this.updateAir({ configurationId: this.configurationId, request: air });
      await this.updateCar({ configurationId: this.configurationId, request: car });
      await this.updateAccommodation({ configurationId: this.configurationId, request: accommodation });

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-pnr-remarks.config-saved')
      });
      await this.getAll(this.configurationId);

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(PnrRemarksStore);