import CustomFieldsConfigurations from '@/modules/settings/custom-fields-configuration/CustomFieldsConfigurations.vue';

export default {
  path: '',
  name: 'fields-configuration',
  component: CustomFieldsConfigurations,
  displayName: 'Custom fields configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCustomFieldDefinitions',
    parentName: 'custom-fields',
    depthCategory: 'custom-fields',
    depth: 1,
  }
};