import ExternalMissionRedirect from '@/modules/expense/external-missions/ExternalMissionRedirect.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/external-mission/:missionId',
  name: 'external-mission',
  components: {
    default: ExternalMissionRedirect,
  },
  meta: {
  },
};
