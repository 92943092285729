import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CreatePaymentMethod,
  PaymentMethodResults,
  PaymentMethodConfiguration,
} from './payment-methods-configuration.model';

class PaymentMethodsConfigurationApiClass {
  public create(params: CreatePaymentMethod): Promise<AxiosResponse<PaymentMethodResults>> {
    return http.execute({
      path: settings.apiAirEngine + '/payment-method-configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getById(configurationId): Promise<AxiosResponse<PaymentMethodConfiguration>> {
    return http.execute({
      path: settings.apiAirEngine + '/payment-method-configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public update(configurationId: string, params: CreatePaymentMethod): Promise<AxiosResponse<PaymentMethodResults>> {
    return http.execute({
      path: settings.apiAirEngine + '/payment-method-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, params);
  }
}

export const AirPaymentMethodsConfigurationApi: PaymentMethodsConfigurationApiClass = new PaymentMethodsConfigurationApiClass();