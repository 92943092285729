export class WorkflowSettingModel {
  policyId: string = '';
  id: string = '';
  stage: string = '';
  rejectionOffset: number | null = null;
  manualApprovalEnabled: boolean = false;
  manualAmendmentPossible: boolean = true;
  assignedManualApprovers: AssignedManualApprover[] = [];

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.stage !== undefined) {
      this.stage = params.stage;
    }
    if (params.rejectionOffset !== undefined) {
      this.rejectionOffset = params.rejectionOffset;
    }
    if (params.manualApprovalEnabled !== undefined) {
      this.manualApprovalEnabled = params.manualApprovalEnabled;
    }
    if (params.manualAmendmentPossible !== undefined) {
      this.manualAmendmentPossible = params.manualAmendmentPossible;
    }
    if (params.assignedManualApprovers !== undefined) {
      this.assignedManualApprovers = params.assignedManualApprovers;
    }
  }
}

export class AssignedManualApprover {
  profileId: string = '';
  name?: string = '';
  description: string = '';
  approvalOrder: number | null = null;

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.profileId !== undefined) {
      this.profileId = params.profileId;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.approvalOrder !== undefined) {
      this.approvalOrder = params.approvalOrder;
    }
  }
}