import ExpensePaymentIntrument from '@/modules/settings/expense-configuration/ExpensePaymentIntrument.vue';

export default {
  path: ':configurationId?/payment-method/:itemId?/:paymentInstrumentId?/:isCopy?',
  name: 'payment-method',
  component: ExpensePaymentIntrument,
  displayName: 'Payment instrument',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadPaymentOptionDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};