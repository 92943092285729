



















import { Vue, Component } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import appConst from '@/const/app.const';

@Component({})
export default class AppBrand extends Vue {

  get companyLogo() {
    return AccountStore.initialCompanyLogo;
  }

  get tmcName() {
    return AccountStore.themeName || appConst.defaultAppName;
  }
}
