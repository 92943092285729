






















































import { Vue, Component } from 'vue-property-decorator';
import ExpensePolicyStore from './expense-policy.store';
import { TripItemTypeCategoryResult } from '@/api/expense/expense.model';
import { router } from '@/router';

@Component({})
export default class DefaultCategoryAssigments extends Vue {
  validationExist: boolean = false;
  serverErrors: any[] = [];
  categoryAssigments = [
    {
      tripItemType: 'Flight',
      category: null
    },
    {
      tripItemType: 'Rail',
      category: null
    },
    {
      tripItemType: 'Accommodation',
      category: null
    },
    {
      tripItemType: 'Car',
      category: null
    },
  ];

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get categoriesForTripType() {
    return ExpensePolicyStore.categoriesForTripType;
  }

  get expenseCategories() {
    return ExpensePolicyStore.expenseCategories.filter(cat => cat.isActive);
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'expense-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveCategoryAssigments() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);

    let request: any[] = [];
    this.categoryAssigments.forEach((cat: TripItemTypeCategoryResult) => {
      if (cat.category && cat.category.id) {
        request.push({
          tripItemType: cat.tripItemType,
          categoryId: cat.category.id
        });
      }
    });

    const message = {
      policyId: this.expensePolicyId,
      params: request,
    };
    await ExpensePolicyStore.updateCategoriesForTripType(message);
  }

  async mapFormData() {
    this.categoriesForTripType.forEach(data => {
      this.categoryAssigments.forEach(cat => {
        if (data.tripItemType === cat.tripItemType) {
          cat.category = data.category;
        }
      });
    });
  }

  async created() {
    ExpensePolicyStore.clearErrMessages();
    await ExpensePolicyStore.getCategoriesForTripType(this.expensePolicyId);
    if (this.categoriesForTripType.length) {
      this.mapFormData();
    }
  }
}
