import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  SearchOfflineRequestsMessage,
  OfflineRequestsSearchResult,
  OfflineRequestSummary,
  OfflinePagingQuery,
  OfflineRequest,
  CreateOfflineRequestMessage,
  UpdateOfflineRequestMessage,
} from './offline-request.model';


class OfflineRequestApiClass {
  public search(query: OfflinePagingQuery, params: SearchOfflineRequestsMessage): Promise<AxiosResponse<OfflineRequestsSearchResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/offlinerequests/search?start={start}&size={size}&UserId={userId}',
      method: RequestMethod.POST,
    }, query, params);
  }

  public summary(limit: number): Promise<AxiosResponse<Array<OfflineRequestSummary>>> {
    return http.execute({
      path: settings.apiTrip + '/api/offlinerequests/summary?Limit={limit}',
      method: RequestMethod.GET,
    }, { limit }, {});
  }

  public get(id: string): Promise<AxiosResponse<OfflineRequest>> {
    return http.execute({
      path: settings.apiTrip + '/api/offlinerequests/{id}',
      method: RequestMethod.GET,
    }, { id }, {});
  }

  public create(message: CreateOfflineRequestMessage): Promise<AxiosResponse<OfflineRequest>>  {
    return http.execute({
      path: settings.apiTrip + '/api/offlinerequests',
      method: RequestMethod.POST,
    }, {}, message);
  }

  public update(id: string, message: UpdateOfflineRequestMessage): Promise<AxiosResponse<OfflineRequest>> {
    return http.execute({
      path: settings.apiTrip + '/api/offlinerequests/{id}',
      method: RequestMethod.PUT,
    }, { id }, message);
  }
}

export const OfflineRequestApi: OfflineRequestApiClass = new OfflineRequestApiClass();