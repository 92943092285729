













































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { LoyaltiesProfileModel } from '@/api/profile/loyalties.model';
import EventHandler from '@/services/event-handler';
import LoyaltyPopup from './LoyaltyPopup.vue';
import LoyaltyRemovePopup from './LoyaltyRemovePopup.vue';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';
import { translate } from '@/i18n';

@Component({
  components: {
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
export default class LoyaltyPrograms extends Vue {
  @Prop({ default: '' }) profileId!: string;
  @Prop({ default: '' }) serviceType!: string;
  @Prop({ default: '' }) loyaltyType!: string;
  showLoyaltyCardPopup: boolean = false;
  deleteLoyaltyCardPopup: boolean = false;
  editingCard: any = null;
  formPending: boolean = false;
  serverErrors: any[] = [];
  availableLoyalties: LoyaltiesProfileModel[] = [];

  get availableDocumentByTypes() {
    return _.uniqBy(this.availableLoyalties, 'loyalty.serviceType');
  }

  isNotAirLoyalty(loyalty) {
    if (
      loyalty.loyaltyType === 'Loyalty' &&
      loyalty.serviceType === 'Air'
    ) {
      return false;
    }

    return true;
  }

  getCarrierName(name) {
    return SupplierName(name);
  }

  getLoyaltyTypeName(name) {
    let type = name.toLowerCase();
    return translate('profile-loyalty.' + type);
  }

  showAddNewLoyaltyCardPopup() {
    this.editingCard = null;
    this.showLoyaltyCardPopup = true;
  }

  filterLoyaltiesByType(type) {
    return this.availableLoyalties.filter((doc: any) => doc.loyalty.serviceType === type.loyalty.serviceType);
  }

  async loadProfileLoyalties() {
    try {
      let profileId = this.$route.params.id;
      let result = await LoyaltiesApi.getProfileloyaltiesCard(profileId);
      if (result && result.data) {
        this.availableLoyalties = result.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  editLoyalties(card) {
    this.editingCard = card;
    this.showLoyaltyCardPopup = true;
  }

  removeLoyalties(card) {
    this.editingCard = card;
    this.deleteLoyaltyCardPopup = true;
  }

  onReceive() {
    this.loadProfileLoyalties();
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    this.serverErrors = [];
    this.loadProfileLoyalties();
  }

  created() {
    EventHandler.$on('reload-loyalty', this.onReceive);
  }

  beforeDestroy() {
    EventHandler.$off('reload-loyalty', this.onReceive);
  }
}

