import AirProviders from '@/modules/settings/configurations/AirProviders.vue';
import airProvidersList from './air-providers/air-providers-list.route';
import newAirProvider from './air-providers/new-air-provider.route';
import editAirProvider from './air-providers/edit-air-provider.route';
import { translate } from '@/i18n';

export default {
  path: 'air-provider-configurations',
  component: AirProviders,
  displayName: 'settings-sidebar.flight-providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadFlightProviders',
    icon: 'flight',
    parentName: 'air-provider-configurations',
  },
  children: [
    airProvidersList,
    newAirProvider,
    editAirProvider,
  ],
};
