import LodgeCardsConfigurations from '@/modules/settings/lodge-cards/LodgeCardsConfigurations.vue';

export default {
  path: '',
  name: 'lodge-card-configurations',
  component: LodgeCardsConfigurations,
  displayName: 'Lodge Card Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyPaymentCards',
    depthCategory: 'lodge-cards-configurations',
    depth: 1,
    parentName: 'lodge-cards-configurations',
  }
};