

























































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import moment from 'moment';

import { translate } from '@/i18n';
import { LegDetail } from '@/api/air-engine/offers.model';
import SearchStore from '@/modules/search/search.store';
import { technicalStopTooltip } from '@/modules/search/air/technical-tooltip.service';
import { userFullName } from '@/core/user-full-name';

@Component({
  components: {
    VueContentLoading
  },
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class BasketAirLegTimelineDetails extends Vue {
  @Prop() leg!: any;
  @Prop() proposal!: any;
  @Prop() offerDetails!: LegDetail;
  @Prop({default: false}) isInBasket!: boolean;

  infoHeights: any[] = [];

  passengerTypeMap = {
    ADT: translate('passenger-types.adt'),
    YTH: translate('passenger-types.yth'),
    CHD: translate('passenger-types.chd'),
    CNN: translate('passenger-types.chd'),
    INF: translate('passenger-types.inf'),
    SRC: translate('passenger-types.src'),
    SEA: translate('passenger-types.sea'),
    MLT: translate('passenger-types.mlt'),
  };

  get travellers() {
    return SearchStore.getTravellersState;
  }

  get legFlights() {
    return this.leg.flights.map(flight => {
      return {
        ...flight,
        travellers: this.proposal.passengers
          .map(passenger => {
            const traveller = this.travellers.travellers.find(t => {
              return t.id === passenger.profileId;
            });

            if (!traveller) {
              return null;
            }
            return {
              ...traveller,
              seat: flight.seats.find(seat => seat.profileId === traveller.id) || null,
              ancillaries: passenger.ancillaries.filter(item => item.flightIds.find(id => id === flight.id)),
              hasFoidData: !!passenger.foidNumber && !! passenger.foidType,
              foidNumber: passenger.foidNumber,
              foidType: passenger.foidType,
            };
          })
          .filter(traveller => traveller !== null)
          .filter(traveller => traveller.seat !== null || traveller.ancillaries.length > 0 || traveller.hasFoidData),
      };
    });
  }

  get isSabreSupplier() {
    return this.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.proposal.supplier === 'Amadeus';
  }



  getTranslationOfDocumentType(type) {
    return translate('profile-documents-by-type.' + type);
  }

  baggageLabelInfoDetails(detail) {
    const pieces = detail.freeItems === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = this.isWeightGiven(detail) ? ' ' + translate('search-air.weight-up') + ' ' + detail.allowedQuantity + ' ' + detail.unit : ' ';

    return translate('search-air.passenger-type') + ' ' + '"' + this.getTranslatedPassengerType(detail.passengerType) + '":' + ' ' + detail.freeItems + ' ' + pieces + ' ' + translate('search-air.of-baggage') + weight;
  }

  baggageLabelInfo() {
    const baggage = this.leg.flights[0].baggageAllowance;
    const pieces = baggage.allowedQuantity === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = baggage.unit !== null && baggage.unit !== 'pc';

    if (weight && baggage.weight) {
      return baggage.allowedQuantity + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.weight-up') + ' ' + baggage.weight + ' ' + baggage.unit + ' ' + translate('search-air.per-passenger');
    } else if (weight) {
      return baggage.allowedQuantity + ' ' + baggage.unit + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.per-passenger');
    }
    return baggage.allowedQuantity + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.per-passenger');
  }

  isWeightGiven(detail) {
    if (-1 < ['kg', 'lb'].indexOf(detail.unit)) {
      return true;
    }

    return false;
  }

  userFullName(user) {
    return userFullName(user);
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  applyMinHeight() {
    this.$nextTick(() => {
      const fareDetails = this.$refs.fareDetails as HTMLElement[];
      const fareName = this.$refs.fareName as HTMLElement[];

      if (!fareDetails || !fareDetails.length) {
        return;
      }

      this.infoHeights = [];

      fareDetails.forEach((fareDetailsItem, index) => {
        const fareDetailsRect = fareDetailsItem.getBoundingClientRect();
        const fareNameRect = fareName[index].getBoundingClientRect();

        this.infoHeights.push(fareNameRect.height + fareDetailsRect.height);
      });
    });
  }

  flagClasses(flight) {
    return {
      'flag__public': 'Public' === flight.fareInfo.fareType,
      'flag__nego': 'Nego' === flight.fareInfo.fareType,
      'flag__private': 'Private' === flight.fareInfo.fareType,
    };
  }

  flightDetails(flight) {
    if (!this.offerDetails || !this.offerDetails.segments || this.offerDetails.error) {
      return null;
    }
    if (flight.type === 'Stop') {
      return null;
    }

    return this.offerDetails.segments.find((segment) => segment.carrierCode === flight.flightInfo.marketingCarrier.code && segment.flightNumber === flight.flightInfo.number);      
  }


  technicalStopTooltip(stop) {
    return technicalStopTooltip(stop);
  }

  getTranslatedPassengerType(type) {
    if (!this.passengerTypeMap[type]) {
      return type;
    }

    return translate(this.passengerTypeMap[type]);
  }





  mounted() {
    this.applyMinHeight();
  }
}
