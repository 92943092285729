
































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import { MOBILE_NAVIGATION_HEIGHT } from '@/modules/layout/layout.const';

@Component
export default class UiMultiSelect extends Vue {
  @Prop() value: any;
  @Prop({ default: '' }) seleniumId!: string;
  @Prop({ default: '' }) trackBy!: string;
  @Prop({ default: [] }) options!: any[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) id!: string;
  @Prop({ default: false }) showLabels!: boolean;
  @Prop({ default: '' }) placeholder!: string;

  isActive: boolean = false;
  isSimple: boolean | null = null;
  touchedIndex: number = -1;
  foundLabel: string = '';
  isInPopup: boolean = false;
  popupContainer: HTMLElement | null = null;
  popupTitle: string = '';

  get currentId() {
    return this.id || ('UiMultiSelect' + this._uid);
  }

  get listeners() {
    return {
      ...this.$listeners,
      input: value => this.input(value),
    };
  }

  get mobileViewClasses() {
    return {
      'ui-select__mobile-view--has-popup-title': '' !== this.popupTitle,
    };
  }

  @Emit('input')
  input(value) {
    return value;
  }

  @Emit('select')
  select(value) {
    return value;
  }

  mobileFieldClick() {
    if (this.disabled) {
      return;
    }

    this.isActive = true;
    if (this.isInPopup) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  confirm() {
    this.isActive = false;
    if (this.isInPopup) {
      EventBus.$emit('unfreeze-popup', this.popupContainer);
    }
  }

  mobileOptionsClick($event) {
    if (hasSomeParentTheClass($event.target, 'option-item')) {
      const parent = getParentByClass($event.target, 'option-item');
      const index = parent.attributes.data.nodeValue;
      this.mobileSelectItem(this.options[index], index);
    } else {
      this.confirm();
    }
  }

  mobileSelectItem(item, index) {

    if (this.disabled || item.$isDisabled) {
      return;
    }
    if (this.isSelected(Number(index))) {
      let idx = -1;

      if (this.isSimple) {
        idx = this.value.indexOf(item);
      } else {
        this.value.forEach((vi, index) => {
          if (vi[this.trackBy] === item[this.trackBy]) {
            idx = index;
          }
        });
      }
      if (-1 === idx) {
        return;
      }

      let newVal = [...this.value];
      newVal.splice(idx, 1);
      this.input(newVal);
      this.select(newVal);
    } else {
      const newVal = [...this.value, item];
      
      this.input(newVal);
      this.select(newVal);
    }
  }

  onTouchStart(index) {
    this.touchedIndex = index;
    
  }

  onTouchEnd(index) {
    if (index === this.touchedIndex) {
      this.touchedIndex = -1;
    }
  }

  classesForIndex(item, index) {
    return {
      'ui-multi-select__mobile-option--disabled': item.$isDisabled === true,
      'ui-multi-select__mobile-option--selected': item.$isDisabled !== true && this.isSelected(index),
      'ui-multi-select__mobile-option--highlighted': item.$isDisabled !== true && this.touchedIndex === index,
    };
  }

  @Watch('options', { immediate: true })
  onOptionsChange(value) {
    this.investigateTypes(this.value);
  }

  @Watch('value', { immediate: true })
  onChange(value) {
    this.investigateTypes(value);
  }

  investigateTypes(value) {
    if (this.options && this.options.length) {
      if (-1 < ['string', 'number'].indexOf(typeof this.options[0])) {
        this.isSimple = true;
        
      } else if ('object' === typeof this.options[0]) {
        this.isSimple = false;
      }
    } else if (value && value.length && (-1 < ['string', 'number'].indexOf(typeof value[0]))) {
      this.isSimple = true;
    } else if (value && value.length && ('object' === typeof value[0])) {
      this.isSimple = false;
    }
  }

  isSelected(index) {
    if (this.isSimple) {
      return this.value.indexOf(this.options[index]) > -1;
    } else {
      let fnd = false;

      this.value.forEach(item => {
        if (item[this.trackBy] === this.options[index][this.trackBy]) {
          fnd = true;
        }
      });
      return fnd;
    }
  }

  getOptionText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  getValueText(item) {
    if (item != null && !this.isSimple && '' !== this.label) {
      return item[this.label];
    }
    if (item != null && item.label && '' === this.label) {
      return item.label;
    }
    return item;
  }

  onWindowClick($event) {
    let navHeight = MOBILE_NAVIGATION_HEIGHT;
    if ($event.pageY < navHeight) {
      this.confirm();
    }
  }

  @Watch('isActive', { immediate: true })
  onActiveChange(value) {
    if (value) {
      setTimeout(() => {
        window.addEventListener('click', this.onWindowClick);
      });
    } else {
      window.removeEventListener('click', this.onWindowClick);
    }
  }

  mounted() {
    if (!this.$slots['field-label']) {
      const label = (this.$refs.uiMultiSelectWrapper as Element).parentNode;

      if (
        label &&
        'label' === label.nodeName.toLowerCase() &&
        (label as HTMLElement).className &&
        -1 < (label as HTMLElement).className.indexOf('ui-label')
      ) {
        for (let i = 0; i < label.children.length; i++) {
          if ('span' === label.children[i].nodeName.toLowerCase()) {
            this.foundLabel = (label.children[i] as HTMLElement).textContent || '';
          }
        }
      }
    }

    if (hasSomeParentTheClass(this.$refs.uiMultiSelectWrapper, 'modal-container')) {
      this.isInPopup = true;
      this.$nextTick(() => {
        this.popupContainer = getParentByClass(this.$refs.uiMultiSelectWrapper, 'modal-container');
        if (this.popupContainer) {
          const titleElements = this.popupContainer.getElementsByClassName('popup-title');
          if (titleElements.length) {
            this.popupTitle = (titleElements[0] as HTMLElement).textContent || '';
          }
        }
      });
    }
  }
}

