import AddExpense from '@/modules/expense/my-expenses/AddExpense.vue';
import ExpenseSubmenu from '@/modules/expense/ExpenseSubmenu.vue';
import ExpenseMenuRoute from './expense-menu.route';
import ExpenseMessages from '@/modules/expense/my-expenses/ExpenseMessages.vue';

export default {
  path: '/expenses/edit/:expenseId',
  name: 'modify-expense',
  components: {
    default: AddExpense,
    submenu: ExpenseSubmenu,
    messages: ExpenseMessages,
  },
  meta: {
    permission: 'Client',
  },
};
