import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import { PaymentGatewayConfiguration } from '@/api/trip/payment-gateway.model';
import { PaymentGatewayConfigurationApi } from '@/api/trip/payment-gateway.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'paymentGateway'
})
class PaymentGatewayStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: PaymentGatewayConfiguration = new PaymentGatewayConfiguration();
  configurationName: string = '';
  configurationId: string = '';
  errMessages: any[] = [];
  showError: boolean = false;

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get errors() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get Loading() {
    return this.loading;
  }


  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.currentConfiguration = new PaymentGatewayConfiguration(value);
  }

  @Mutation
  clearConfiguration() {
    this.currentConfiguration = new PaymentGatewayConfiguration();
  }

  @Mutation
  setConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setErrors(value) {
    this.errMessages = $handleErrors(value, true);
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  clearErrors() {
    this.showError = false;
    this.errMessages = [];
  }

  
  @Action
  clearData() {
    this.setConfiguration(new PaymentGatewayConfiguration());
    this.setConfigurationId('');
    this.setConfigurationName('');
  }

  @Action
  async createConfiguration(request: PaymentGatewayConfiguration) {
    try {
      this.setLoading(true);
      const response = await PaymentGatewayConfigurationApi.createConfiguration(request);
      if (response && response.data) {
        this.setConfigurationId(response.data.configurationId);
      }
    } catch (error) {
      this.setErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateConfiguration(data) {
    try {
      this.setLoading(true);
      const response = await PaymentGatewayConfigurationApi.updateConfiguration(data);
      if (response && response.data) {
        this.getConfiguration(data.configurationId);
      }
    } catch (error) {
      this.setErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getConfiguration(configurationId: string) {
    try {
      this.setErrors([]);
      this.setShowError(false);
      this.setLoading(true);
      const result = await PaymentGatewayConfigurationApi.getConfiguration(configurationId);
      if (result && result.data) {
        this.setConfiguration(result.data);
        this.setConfigurationId(configurationId);
      }
    } catch (error) {
      this.setErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(PaymentGatewayStore);
