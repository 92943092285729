import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { AirProviderConfigurations } from './air-provider-configuration.model';

class AirProviderConfigurationSetApiClass {
  public create(params: AirProviderConfigurations): Promise<AxiosResponse<AirProviderConfigurations>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getById(configurationId): Promise<AxiosResponse<AirProviderConfigurations>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/configurations/{configurationId}',
      method: RequestMethod.GET
    }, {
        configurationId
    }, {});
  }

  public getEmpty(): Promise<AxiosResponse<AirProviderConfigurations>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/configurations/empty',
      method: RequestMethod.GET
    }, {}, {});
  }

  public update(configurationId: string, params: AirProviderConfigurations): Promise<AxiosResponse<AirProviderConfigurations>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, {configurationId}, params);
  }
}

export const AirProviderConfigurationSetApi: AirProviderConfigurationSetApiClass = new AirProviderConfigurationSetApiClass();