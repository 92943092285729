





















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class UiInput extends Vue {
  @Prop() value: any;
  @Prop() placeholder!: string;
  @Prop() maxlength!: string;
  @Prop({
    default: false
  }) disabled!: boolean;
  @Prop({ default: false }) showReset!: boolean;
  @Prop() autocomplete!: string;

  get listeners() {
    return {
      ...this.$listeners,
      input: event => this.Input(event.target.value),
    };
  }

  @Emit('input')
  Input(value) {
    return value;
  }

  resetValue() {
    this.Input('');
    this.$emit('reset-event');
  }
}
