


















import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

const eventName = 'change';

@Component
export default class UiSwitch extends Vue {
  @Model(eventName) modelValue!: any;
  @Prop() value: any;
  @Prop() name: any;
  @Prop({ default: '' }) valueKey!: string;
  @Prop({ default: false }) disabled!: boolean;

  get isChecked() {
    if (this.modelValue instanceof Array) {
      return this.modelValue.includes(this.value);
    }
    return this.modelValue === true;
  }

  @Emit(eventName)
  changeCheckbox(event) {
    if (this.modelValue instanceof Array) {
      let newValue = [...this.modelValue];

      if (event.target.checked) {
        newValue.push(this.value);
      } else {
        newValue.splice(newValue.indexOf(this.value), 1);
      }
      return newValue;
    }
    return event.target.checked;
  }
}

