
























import { Vue, Component, Watch } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import AirSearchStore from './air-search.store';

@Component({})
export default class AirExchangeInfoSubmenu extends Vue {
  get basketId() {
    return AirSearchStore.modificationBasketId;
  }

  get canShowEditLink() {
    return null !== AirSearchStore.exchangedOffer;
  }

  editRequest() {
    EventBus.$emit('show-air-exchange-popup');
  }
}
