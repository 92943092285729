import TravelPolicyCarManageRule from '@/modules/settings/travel-policy/configurations/configuration/TravelPolicyCarManageRule.vue';

export default {
  path: ':configurationId?/car-rule:readOnly?',
  name: 'car-rule',
  component: TravelPolicyCarManageRule,
  displayName: 'Car Rule',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadTravelPolicy',
    parentName: 'travel-policy-configurations',
    depthCategory: 'travel-policy',
    depth: 3,
  }
};