import DemoLayoutIntro from '@/modules/demo/layout/DemoLayoutIntro.vue';
import DemoLayoutHeader from '@/modules/demo/layout/DemoLayoutHeader.vue';
import DemoLayoutContent from '@/modules/demo/layout/DemoLayoutContent.vue';

export default {
  path: '/demo-layout-2',
  name: 'demo-layout-2',
  components: {
    default: DemoLayoutContent,
    header: DemoLayoutHeader,
    intro: DemoLayoutIntro,
  },
};
