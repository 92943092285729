























































































































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';
import { VclCode } from 'vue-content-loading';

import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import TrainSearchStore from './train-search.store';
import { TrainOfferResult, Price, Fare, Train } from '@/api/train-engine/train-search.model';
import { SupplierName } from '@/services/supplier-name.service';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import formatPrice from '@/filters/format-price.filter';
import TrainTimeline from './TrainTimeline.vue';
import TrainDetailsBasket from './TrainDetailsBasket.vue';
import TrainFareDiscount from './TrainFareDiscount.vue';
import BasketTrainPriceDetails from './BasketTrainPriceDetails.vue';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { trainLogo } from '@/const/train-logo-mappings.const';

@Component({
  components: {
    TrainTimeline,
    TrainDetailsBasket,
    TrainFareDiscount,
    VclCode,
    HeightTransition,
    BasketTrainPriceDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    }
  }
})
export default class TrainResultsBasketRow extends Vue {
  @Prop() offer!: TrainOfferResult;
  @Prop() item?: any;
  @Prop() totalPrice!: Price;
  @Prop() missingAmount!: Price;
  @Prop() itemTravellers!: any;
  @Prop({ default: false }) isInPopup!: boolean;
  @Prop({ default: '' }) basketStatus!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: '' }) basketProviderStatus!: string;
  @Prop({ default: false }) disableFeeEdit!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop({ default: false }) disablePriceDetails!: boolean;

  showDetails: boolean = false;
  isOpen: boolean = false;
  selectedIndex: number = 0;
  showDiscountInfoPopup: boolean = false;
  height: any | null = null;
  showOffer: boolean = false;
  showPriceDetailsPopup: boolean = false;
  imageProcessed: boolean = false;
  imageLoading: boolean = true;
  imageVisible: boolean = true;



  get styles() {
    if (this.height) {
      return {
        height: this.height + 'px',
      };
    }
    return {};
  }

  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get timelinesCollapsed() {
    return false;
  }

  get offerBasket() {
    return {
      ...this.offer,
      itemTravellers: this.itemTravellers,
      basketItemStatus: this.basketStatus
    };
  }

  get basketItemTravellers() {
    if (!BasketStore.basket || !BasketStore.basket.travellers || !this.item || !this.item.travellers) {
      return [];
    }
    return BasketStore.basket.travellers.filter(t => {
      return this.item.travellers.find(traveller => traveller.profileId === t.id);
    });
  }

  get feeValue() {
    if (!this.item || !this.item.price || !this.item.price.agencyFee) {
      return null;
    }

    return this.item.price.agencyFee;
  }

  get agencyMarkupValue() {
    if (!this.item || !this.item.price || !this.item.price.agencyMarkup) {
      return null;
    }

    return this.item.price.agencyMarkup;
  }

  get systemMarkupValue() {
    if (!this.item || !this.item.price || !this.item.price.systemMarkup) {
      return null;
    }

    return this.item.price.systemMarkup;
  }

  get trainLegList() {
    let trains: Train[] = [];
    this.offer.journeys.forEach(journey => {
      journey.trains.forEach(train => {
        if (train && train.type === 'Train') {
          trains.push(train);
        }
      });
    });
    return trains;
  }

  get uniqTrainById() {
    return this.offer.journeys.forEach(journey => {
      return _.uniqBy(journey.trains, 'segmentKey');
    });
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get legNumber() {
    return this.$route.params.leg ? parseInt(this.$route.params.leg) : 1;
  }

  get providerClass() {
    if (this.offer && this.offer.supplier) {
      return 'flag flag__' + (this.offer.supplier).toLowerCase();
    }
  }

  get canShowEditFeeByState() {
    return 'Draft' === this.item.status && !this.item.isInProgress && this.item.bookingErrors && this.item.bookingErrors.length === 0;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get exchangeRequest() {
    if (!this.modifyMode) {
      return null;
    }
    return TrainSearchStore.exchangeRequest;
  }

  get basketMode() {
    return -1 < [
      'basket',
    ].indexOf(this.$route.name || '') &&
    -1 === [
      'Confirmed',
      'Cancelled',
      'Held',
      'PendingApproval',
    ].indexOf(this.item.status);
  }

  get canShowPriceDetails() {
    if (!this.item) {
      return false;
    }

    return (this.$hasAccess('CanSeePriceDetails') && 'Ntv' !== this.item.supplier) && !this.modifyMode && !this.showingInPopup;
  }

  get isAgencyFeeEditable() {
    if (
      !this.offer ||
      !this.offer.recommendations ||
      !this.offer.recommendations.length
    ) {
      return null;
    }

    return this.offer.recommendations[0].isAgencyFeeEditable;
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get tooltipPrice() {
    let tooltip = '';

    if (!this.item || !this.item.price) {
      return '';
    }

    if (this.item.price.rawProviderPrice) {
      tooltip += `<div>${translate('basket.raw-provider-price')} ${this.item.price.rawProviderPrice.currency.symbol || this.item.price.rawProviderPrice.currency.code} ${this.formatPrice(this.item.price.rawProviderPrice.amount)}</div>`;
    }
    if (this.item.price.systemMarkup) {
      tooltip += `<div class="mb-3">${translate('basket.system-markup')} ${this.item.price.systemMarkup.currency.symbol || this.item.price.systemMarkup.currency.code} ${this.formatPrice(this.item.price.systemMarkup.amount)}</div>`;
    }

    if ((this.$hasAccess('CanEditBookingFee') || this.hasAdvancedOfferInfo) && this.item.price.providerPrice) {
      tooltip += `<div>${translate('basket.incl')} ${this.item.price.providerPrice.currency.symbol || this.item.price.providerPrice.currency.code} ${this.formatPrice(this.item.price.providerPrice.amount)} ${translate('search-air.provider-price')}`;
      tooltip += `</div>`;
    }

    if (this.item.price.agencyMarkup) {
      tooltip += `<div>${translate('basket.incl')} ${this.item.price.agencyMarkup.currency.symbol || this.item.price.agencyMarkup.currency.code} ${this.formatPrice(this.item.price.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</div>`;
    }
    if ((this.$hasAccess('CanEditBookingFee') || this.hasAdvancedOfferInfo) && this.item.price.agencyFee !== undefined && this.item.price.agencyFee !== null) {
      tooltip += `<div>${translate('basket.incl')} ${this.item.price.agencyFee.currency.symbol || this.item.price.agencyFee.currency.code} ${this.formatPrice(this.item.price.agencyFee.amount)} ${translate('search-air.fee')}</div>`;
    }

    return tooltip;
  }

  get trainTypeInfo() {
    return this.offer.journeys[0].trains.filter(train => train.type !== 'Stop');
  }

  get offerPrice() {
    let selectedFares: Fare[] = [];
    let price = 0;
      this.offer.proposals.forEach(proposal => {
        proposal.fares.forEach(fare => {
          selectedFares.push(fare);
        });
      });
    if (selectedFares.length) {
      selectedFares.forEach(s => {
        if (s) {
          price += s.price.amount;
        }
      });
    }

    return price;
  }

  displayImg(ride) {
    if (!this.imageLoading || !ride || !ride.trainInfo) {
      return '';
    }
    const key = this.offer.supplier === 'Benerail' ? ride.trainInfo.trainCode : ride.trainInfo.trainType;
    return trainLogo[key];
  }

  filterTrains(trains) {
    const filterTrain = trains
      .filter(item => item.type !== 'Stop')
      .filter((item, index, array) => {
        const i = array.findIndex(el => el.trainInfo.trainType === item.trainInfo.trainType);
        if (i < index) {
          return false;
        }

        return true;
      });

    return filterTrain;
  }



  getTrainLogoName(journey, offer) {
    if (journey.trains && journey.trains.length && journey.trains[0].trainInfo && journey.trains[0].trainInfo.trainType
          && (journey.trains[0].trainInfo.trainType === 'EUROSTAR' || journey.trains[0].trainInfo.trainType === 'THALYS' )) {
      return journey.trains[0].trainInfo.trainType;
    } else {
      return offer.offerProvider.name;
    }
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  shouldFadeLeg(legNumber) {
    if (!this.modifyMode || !this.exchangeRequest) {
      return false;
    }

    const leg = this.exchangeRequest.legs.find(l => l.legNumber === legNumber);
    if (!leg) {
      return false;
    }

    return !leg.isForExchange;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  imageError(ride) {
    if ('' === this.displayImg(ride)) {
      this.imageProcessed = true;
      return;
    }

    this.imageVisible = false;
    this.imageProcessed = true;
  }

  @Watch('$route', { deep: true, immediate: true })
  routeHandler() {
    this.isOpen = false;
  }

  getProposalList(train) {
    return this.offer.proposals.find(proposal => {
      return proposal.segmentKey === train.segmentKey;
    });
  }

  getTrainClasses(proposal: any) {
    if (proposal.fares && proposal.fares.length) {
      return proposal.fares.map(fare => {
        if (fare.class) {
          return fare.class;
        }
      }).join(', ');
    } else {
      return '';
    }
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
    this.isOpen = true;
  }

  toggleOffer() {
    this.showOffer = !this.showOffer;
  }

  showDiscountInfo() {
    this.showDiscountInfoPopup = true;
  }

  showPriceDetails() {
    this.showPriceDetailsPopup = true;
  }

  editBookingFee() {
    if (this.disableFeeEdit) {
      return;
    }
    BasketStore.setEditedFeeItem(this.item);
    BasketStore.setEditedFeeOffer(this.offer);
    BasketStore.setShowEditTrainFeePopup(true);
  }

  editAgencyMarkup() {
    if (this.disableFeeEdit) {
      return;
    }
    BasketStore.setEditedFeeItem(this.item);
    BasketStore.setEditedFeeOffer(this.offer);
    BasketStore.setShowEditTrainAgencyMarkupPopup(true);
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {
      this.showDetails = false;
      this.showOffer = true;

      if (window.innerWidth > 800 && !this.height) {
        this.$nextTick(() => {
          const rect = (this.$refs.mainCard as HTMLElement).getBoundingClientRect();
          this.height = rect.height;
        });
      }
    }
  }

  onOfferFeeEdited({
    offerId,
    travelPolicy,
  }) {
    if (this.item.id !== offerId || !this.offer) {
      return;
    }

    this.offer.travelPolicy = travelPolicy;
  }

  created() {
    EventBus.$on('offer-fee-edited', this.onOfferFeeEdited);
  }

  beforeDestroy() {
    EventBus.$off('offer-fee-edited', this.onOfferFeeEdited);
  }
}
