import Vue from 'vue';

import { router } from '@/router';
import { store } from '@/store';
import config from '@/config';
import { i18n } from '@/i18n';
import '@/styles/styles.scss';
import 'croppie/croppie.css';

config.forEach(cfg => cfg());

import App from '@/App.vue';

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
