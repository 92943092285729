import Demo from '@/modules/demo/Demo.vue';
import DemoIntro from '@/modules/demo/DemoIntro.vue';
import texts from './texts.route';
import buttons from './buttons.route';
import inputs from './inputs.route';
import popups from './popups.route';
import tables from './tables/tables.route';

export default {
  path: '/demo',
  name: 'demo',
  components: {
    default: Demo,
    intro: DemoIntro,
  },
  children: [
    texts,
    buttons,
    inputs,
    popups,
    tables,
  ],
};
