









































import { Vue, Component } from 'vue-property-decorator';

import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { Table2DataRow } from './table2';
import { Table2Params } from './table2.params';
import { Table2ItemsService } from './table2-items.service';

@Component({})
export default class Table2 extends BaseTable<Table2DataRow> {
  itemsService: Table2ItemsService = new Table2ItemsService();
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  fields = {
    name: {
      sortable: true,
    },
    email: {
      sortable: true,
    },
    details: {
      sortable: false,
    }
  };

  async getItems(params: Table2Params) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    let items = this.itemsService.filter(params);
    items = this.itemsService.sort(params, items);
    items = this.itemsService.page(params, items);

    const res = {
      items: items,
      hasMoreData: params.size + params.page < this.itemsService.maxCount,
    };

    return res;
  }
}
