











































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import BillingStore from './billing.store';
import { BillingConfigurationModeEnum } from '@/api/billing/billing.model';
import { translate } from '@/i18n';
import { BillingApi } from '@/api/billing/billing.api';

const urlRegex = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&/=]*)/.test(value);
};

@Component({})
export default class BillingConfiguration extends Vue {
  $v;
  loading: boolean = false;
  formPending: boolean = false;
  Form: any = {
    configurationId: '',
    mode: null,
    notificationEnabled: false,
    backofficeConfiguration: {
      billingAccountId: '',
      notificationMode: null,
      url: '',
      login: '',
      password: '',
    },
    gestourConfiguration: {
      secretApiKey: '',
    }
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  errors: any[] = [];

  backOfficeModeOptions = [
    {
      label: translate('settings-back-office.label-backoffice'),
      value: BillingConfigurationModeEnum.BackOffice
    },
    {
      label: translate('settings-back-office.label-gestour'),
      value: BillingConfigurationModeEnum.Gestour
    }
  ];

  notificationModeOptions = [
    {
      value: 'NotifyTrip',
      label: translate('settings-back-office.notify-with-data')
    },
    {
      value: 'NotifyChange',
      label: translate('settings-back-office.notify-event')
    },
    {
      value: 'IGA',
      label: translate('settings-back-office.iga-endpoint'),
    },
    {
      value: 'Viaxoft',
      label: translate('settings-back-office.viaxoft-endpoint'),
    },
  ];

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        mode: {
          required,
        },
        backofficeConfiguration: {
          url: {
            required: requiredIf(() => {
              return this.Form.notificationEnabled;
            }),
            urlRegex: (value) => {
              return value && this.Form.mode && this.Form.mode.value === 'BackOffice' ? urlRegex(value) : true;
            },
            maxLength: (value) => {
              return value && this.Form.mode && this.Form.mode.value === 'BackOffice' ? value.length <= 2000 : true;
            }
          },
          login: {
            required: requiredIf(() => {
              return this.Form.notificationEnabled &&
                this.Form.backofficeConfiguration.notificationMode &&
                (this.Form.backofficeConfiguration.notificationMode.value === 'IGA' || this.Form.backofficeConfiguration.notificationMode.value === 'Viaxoft');
            }),
          },
          password: {
            required: requiredIf(() => {
              return this.Form.notificationEnabled &&
                this.Form.backofficeConfiguration.notificationMode &&
                this.Form.backofficeConfiguration.notificationMode.value === 'IGA';
            }),
          },
          key: {
            required: requiredIf(() => {
              return this.Form.notificationEnabled &&
                this.Form.backofficeConfiguration.notificationMode &&
                this.Form.backofficeConfiguration.notificationMode.value === 'Viaxoft';
            })
          },
          notificationMode: {
            required: requiredIf(() => {
              return this.Form.notificationEnabled;
            })
          }
        },
        gestourConfiguration: {
          secretApiKey: {
            required: requiredIf(() => {
              return this.isModeGestour;
            }),
            maxLength: (value) => {
              return value && this.Form.mode && this.Form.mode.value === 'Gestour' ? value.length <= 128 : true;
            }
          },
        }
      }
    };
  }
  
  get canReadNotWrite() {
    return this.$hasAccess('ReadBackOfficeConfiguration') && !this.$hasAccess('WriteBackOfficeConfiguration');
  }

  get inEditMode() {
    return !!(this.$route.params.configurationId);
  }

  get errMessages() {
    return BillingStore.errMessages;
  }

  get showErrMessages() {
    return BillingStore.showError;
  }

  get isModeBackOffice() {
    return this.Form.mode ? this.Form.mode.value === BillingConfigurationModeEnum.BackOffice : false;
  }

  get isModeGestour() {
    return this.Form.mode ? this.Form.mode.value === BillingConfigurationModeEnum.Gestour : false;
  }

  get formLoading() {
    return BillingStore.loading;
  }

  get canShowCustomError() {
    return BillingStore.canShowCustomError;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'billing-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    BillingStore.setErrMessages([]);
    BillingStore.setShowError(false);
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await BillingStore.addBillingConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await BillingStore.updateBillingConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  backofficeConfiguration() {
    return this.Form.notificationEnabled ? {
      ...this.Form.backofficeConfiguration,
      notificationMode: this.Form.backofficeConfiguration.notificationMode ? this.Form.backofficeConfiguration.notificationMode.value : 'disabled',
      billingAccountId: this.Form.backofficeConfiguration.billingAccountId,
    } : {
      notificationMode: 'disabled',
      url: null,
      billingAccountId: this.Form.backofficeConfiguration.billingAccountId,
    };
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        configurationId: this.Form.configurationId,
        params: {
          name: this.Form.name,
          mode: this.Form.mode.value,
        }
      };
      if (this.Form.mode.value === BillingConfigurationModeEnum.BackOffice) {
        request.params.backofficeConfiguration = this.backofficeConfiguration();
      } else if (this.Form.mode.value === BillingConfigurationModeEnum.Gestour) {
        request.params.gestourConfiguration = this.Form.gestourConfiguration;
      }
    } else {
      request = {
        rootCompanyId: this.$route.params.id,
        mode: this.Form.mode.value,
        name: this.Form.name,
      };
      if (this.Form.mode.value === BillingConfigurationModeEnum.BackOffice) {
        request.backofficeConfiguration = {
          ...this.Form.backofficeConfiguration,
          notificationMode: this.Form.backofficeConfiguration.notificationMode ? this.Form.backofficeConfiguration.notificationMode.value : 'disabled'
        };
      } else if (this.Form.mode.value === BillingConfigurationModeEnum.Gestour) {
        request.gestourConfiguration = this.Form.gestourConfiguration;
      }
    }
    
    return request;
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        name: data.name || BillingStore.currentConfigurationName,
        configurationId: data.configurationId,
        mode: this.backOfficeModeOptions.find(mode => {
          return mode.value === data.mode;
        }),
        notificationEnabled: !!(data.backofficeConfiguration && data.backofficeConfiguration.url && data.backofficeConfiguration.notificationMode),
        backofficeConfiguration: data.backofficeConfiguration ?  {
          ...data.backofficeConfiguration,
          notificationMode: this.notificationModeOptions.find(mode => {
            return mode.value === data.backofficeConfiguration.notificationMode;
          }),
        } : {
          billingAccountId: '',
          notificationMode: null,
          url: '',
        },
        gestourConfiguration: data.gestourConfiguration ? data.gestourConfiguration : {
          secretApiKey: '',
        },
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        mode: null,
        notificationEnabled: false,
        backofficeConfiguration: {
          billingAccountId: '',
          notificationMode: null,
          url: '',
        },
        gestourConfiguration: {
          secretApiKey: '',
        }
      };
    }
  }

  async created() {
    BillingStore.setShowError(false);
    if (this.inEditMode) {
      try {
        this.loading = true;
        const result = await BillingApi.getBillingConfiguration(this.$route.params.configurationId);
        if (result && result.data) {
          this.mapFormData(result.data);
        }
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.loading = false;
      }
    } else {
      this.mapFormData();
    }
  }
}
