import axios, { CancelTokenSource } from 'axios';

import {
  HTTPInterface,
  Requests,
  Request,
  RequestMethod,
} from './http.model';

export const http: HTTPInterface = {
  execute(
    request: Request,
    query: any,
    params: any,
    preventParamDestructure?: boolean,
    cancelTokenSource?: CancelTokenSource
  ) {
    const regex = /{(\s*[\w\.]+\s*)}/g;
    let path = request.path;
    let result;
    let names: Array<any> = [];
    let headers: any = {};

    while (result = regex.exec(request.path)) {
      names.push(result);
    }

    names.forEach(result => {
      const [match, name] = result;
      const replaceRegex = new RegExp(match, 'g');
      path = path.replace(replaceRegex, encodeURIComponent(query[name]));
    });

    if (request && request.headers) {
      headers = request.headers;
    }

    if (request && request.permission) {
      headers = {
        ...headers,
        permission: request.permission,
      };
    }

    return axios({
      method: request.method,
      url: path,
      headers,
      data: preventParamDestructure ? params : {
        ...params,
      },
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
    });
  }
};
