import CreateAgency from '@/modules/settings/CreateAgency.vue';
import { translate } from '@/i18n';

export default {
  path: 'create-agency',
  name: 'create-agency',
  component: CreateAgency,
  displayName: 'settings-sidebar.create-company',
  meta: {
    introClass: 'intro-settings',
    permission: 'CreateAgencyCustomers'
  }
};
