import { BaseTableParams } from '@/core/base-table-params';

export class Table3Params extends BaseTableParams {
  name: string = '';
  email: string = '';

  public constructor(params: any) {
    super(params);
    
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.email !== undefined) {
      this.email = params.email;
    }
  }
}
