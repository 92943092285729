import ExpensePolicyConfiguration from '@/modules/settings/expense-configuration/ExpensePolicyConfiguration.vue';

export default {
  path: ':configurationId?/configuration/:isCopy?',
  name: 'expense-configuration',
  component: ExpensePolicyConfiguration,
  displayName: 'Expense policy configuration',
  meta: {
    breadCrumb: 'Expense policy configuration',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 2,
  }
};