import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import _ from 'lodash';
import { store } from '@/store';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import { ExpenseApi } from '@/api/expense/expense.api';
import { ExpenseResult } from '@/api/expense/expense.model';
import { CurrencyApi } from '@/api/currency/currency.api';
import accountStore from '@/store/account.store';
import { MissionResult, MissionNamesResult, } from '@/api/expense/expense.model';
import { ProfileApi } from '@/api/profile/profile.api';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { SimpleCustomFieldModel } from '@/api/custom-fields/custom-fields.model';
import { AxiosResponse } from 'axios';
import { CompanyConfigurationsApi } from '@/api/profile/company-configurations.api';
import { Permission } from '@/const/permission.enum';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { companyConfigurationType } from '@/api/profile/configurations.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'ExpenseStore'
})
class ExpenseStore extends VuexModule {
  errMessages: string[] = [];
  policyId: string = ' ';
  showError: boolean = false;
  missionNamesList: any[] = [];
  showExpenseImagePopup: boolean = false;
  expenseList: ExpenseResult[] | [] = [];
  currencyOptions: any[] = [];
  categoryOptions: any[] = [];
  paymentInstrumentsOptions: any[] = [];
  projectsOptions: any[] = [];
  missionsList: MissionResult[] = [];
  expenseType: string = 'Expenditure';
  basicExtensions: any[] = [];
  showPaymentInstrument: boolean = false;
  ifPaymentInstrumentRequired: boolean = false;
  showProject: boolean = false;
  ifProjectRequired: boolean = false;
  ifPaymentInstrumentRequiredToClaimed: boolean = false;
  ifProjectRequiredToClaimed: boolean = false;
  showClaimedExpensePopup: boolean = false;
  customFieldConditions: any[] = [];
  customFieldTransitionRules: any[] = [];
  expensePolicyConfigurationType: string = 'ExpensePoliciesConfiguration';
  projectsConfigurationType: string = 'ProjectsConfiguration';
  showConfigurationError: boolean = false;
  missionReporter: any = null;
  manualApprovalTasks: any[] = [];
  approverList: any[] = [];
  projectList: any[] = [];
  notifications: any[] = [];
  notificationDetails: any = null;
  showMissionToApprovalPopPup: boolean = false;
  selectedMissionIdForExpense: any = null;
  selectedMissionForExpense: MissionNamesResult | null = null;
  validationResults: any | null = null;
  submitExpenseReportResult: any | null = null;

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get companyId() {
    return accountStore.CurrentUser!.profile.companyId;
  }

  get rootCompanyId() {
    return accountStore.CurrentUser!.profile.rootCompanyId;
  }

  get ifPaymentInstrument() {
    return this.showPaymentInstrument;
  }

  get paymentInstrumentRequired() {
    return this.ifPaymentInstrumentRequired;
  }

  get ifProject() {
    return this.showProject;
  }

  get projectRequired() {
    return this.ifProjectRequired;
  }

  @Mutation
  setExpenseList(payload) {
    this.expenseList = payload;
  }

  @Mutation
  setCurrencyOptions(value) {
    this.currencyOptions = value;
  }

  @Mutation
  setCategoryOptions(value) {
    this.categoryOptions = value;
  }

  @Mutation
  setPaymentInstrumentsOptions(value) {
    this.paymentInstrumentsOptions = value;
  }

  @Mutation
  setProjectsOptions(value) {
    this.projectsOptions = value;
  }

  @Mutation
  setMissionNamesList(payload) {
    this.missionNamesList = payload;
  }

  @Mutation
  setPolicyId(value) {
    this.policyId = value;
  }

  @Mutation
  setShowPaymentInstrument(value) {
    this.showPaymentInstrument = value;
  }

  @Mutation
  setPaymentInstrumentRequired(value) {
    this.ifPaymentInstrumentRequired = value;
  }

  @Mutation
  setShowProject(value) {
    this.showProject = value;
  }

  @Mutation
  setProjectRequired(value) {
    this.ifProjectRequired = value;
  }

  @Mutation
  setPaymentInstrumentRequiredToClaimed(value) {
    this.ifPaymentInstrumentRequiredToClaimed = value;
  }

  @Mutation
  setProjectRequiredToClaimed(value) {
    this.ifProjectRequiredToClaimed = value;
  }

  @Mutation
  setCustomFieldConditions(value) {
    this.customFieldConditions = value;
  }

  @Mutation
  setCustomFieldTransitionRules(value) {
    this.customFieldTransitionRules = value;
  }

  @Mutation
  setShowExpenseImagePopup(value) {
    this.showExpenseImagePopup = value;
  }

  @Mutation
  setShowClaimedExpensePopup(value) {
    this.showClaimedExpensePopup = value;
  }

  @Mutation
  setShowMissionToApprovalPopPup(value) {
    this.showMissionToApprovalPopPup = value;
  }

  @Mutation
  setBasicExtensions(value) {
    this.basicExtensions = value;
  }

  @Mutation
  setMissionsList(value) {
    this.missionsList = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = $handleErrors(error, true);
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setShowConfigurationError(value) {
    this.showConfigurationError = value;
  }

  @Mutation
  setMissionReporter(value) {
    this.missionReporter = value;
  }

  @Mutation 
  setManualApprovalTasks(value) {
    this.manualApprovalTasks = value;
  }

  @Mutation
  setApproverList(value) {
    this.approverList = value;
  }

  @Mutation
  setProjectList(value) {
    this.projectList = value;
  }

  @Mutation
  setMissionForExpense(value) {
    this.selectedMissionForExpense = value;
  }

  @Mutation
  clearMissionForExpense() {
    this.selectedMissionForExpense = null;
  }

  @Action
  async getApprovers(value) {
    let companyId = this.companyId;
    const response = await ProfileApi.searchApprovers( companyId, value, Permission.ReadApprovalWorkflow );
    if (response && response.data) {
      this.setApproverList(response.data);
    }
  }

  @Action
  async getProjectList() {
    let companyId = this.companyId;
    const result = await ProfileConfigurationsApi.getByRootCompanyId(
      companyId,
      companyConfigurationType.projectsConfiguration,
      10,
      1,
      Permission.ReadProjects
    );
    if (result && result.data) {
      this.setProjectList(result.data);
    }
  }

  @Mutation
  setNotifications(value) {
    this.notifications = value;
  }

  @Mutation
  setNotificationDetails(value) {
    this.notificationDetails = value;
  }

  @Mutation
  setValidationResults(value) {
    this.validationResults = value;
  }

  @Mutation
  setSubmitExpenseReportResult(value) {
    this.submitExpenseReportResult = value;
  }

  @Action
  async getMissionsNames(request) {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getMissionsNames(this.currentProfileId, request.policyId, request.expenseAssignmentAllowed);
      this.setMissionNamesList(response.data);
    } catch (error) {
      this.setShowError(true);
      this.setErrMessages(error);
    }
  }

  @Action
  async getDefaultExpensePolicy(permission) {
    this.clearErrMessages();
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getDefaultExpensePolicy(permission);
      if (response && response.data) {
        this.setPolicyId(response.data.expensePolicyId);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getMissionsList(searchParams) {
    this.setShowError(false);
    try {
      const result = await ExpenseApi.getMissionsList(searchParams);
      this.setMissionsList(result.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getExpenseList(searchParams) {
    this.setShowError(false);
    try {
      const result = await ExpenseApi.getExpenseList(searchParams);
      this.setExpenseList(result.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getUser(id) {
    const user = await ProfileApi.getById(id, Permission.ReadProfilePersonal);
    if (user && user.data) {
      this.setMissionReporter(user.data);
      return user.data;
    }
    return null;
  }


  @Action
  async getCurrencies() {
    this.setShowError(false);
    try {
      const response = await CurrencyApi.getCurrencies();
      this.setCurrencyOptions(response.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getExpenseCategory(params) {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getExpenseCategories(params.policyId);
      let categoryOptions;
      if (params.disabledForm) {
        categoryOptions = response.data;
      } else {
        categoryOptions = response.data.filter(item => item.isActive === true);
      }
      
      this.setCategoryOptions(categoryOptions);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getPaymentInstruments() {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getPaymentInstrumentsDictionary(this.policyId);
      this.setPaymentInstrumentsOptions(response.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }
  
  @Action
  async getProjects() {
    this.setShowError(false);
    this.setShowConfigurationError(false);
    try {
      const configurationItem = await CompanyConfigurationsApi.getCompanyConfigurations(this.companyId, this.projectsConfigurationType);
      if (configurationItem.data && configurationItem.data.length) {
        const configurationId = configurationItem.data[0].configurationId;
        try {
          const response = await ProfileApi.getProjectsDictionary(configurationId);
          this.setProjectsOptions(response.data);
        } catch (error) {
          this.setErrMessages(error);
          this.setShowError(true);
        }
      } else if (this.showProject) {
        this.setShowConfigurationError(true);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getExpectedExpenseModel(state) {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getExpectedExpenseModel(this.policyId, state, this.expenseType);
      await this.getCustomFieldConditions(response.data.customFieldConditions);
      this.setBasicExtensions(response.data.basicExtensions);
      this.getBasicExtensions(response.data.basicExtensions);
      this.getTransitionRules(response.data.transitionRules);
      this.setCustomFieldTransitionRules(response.data.customFieldTransitionRules);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  getBasicExtensions(value) {
    if (value && value.length) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].propertyType === 'PaymentInstrument') {
          this.setShowPaymentInstrument(true);
          this.setPaymentInstrumentRequired(value[i].isRequired);
        } else if (value[i].propertyType === 'Project') {
          this.setShowProject(true);
          this.setProjectRequired(value[i].isRequired);
        }
      }
    }
  }

  @Action
  getTransitionRules(value) {
    if (value && value.length) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].targetState === 'Claimed') {
            if (value[i].propertyType === 'PaymentInstrument') {
            this.setPaymentInstrumentRequiredToClaimed(value[i].isRequired);
          } else if (value[i].propertyType === 'Project') {
            this.setProjectRequiredToClaimed(value[i].isRequired);
          }
        }
      }
    }
  }

  @Action
  async getCustomFieldConditions(value) {
    if (value && value.length) {
      let a: any[] | any = value;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: any[] = await Promise.all(
        a.map(field => CustomFieldsApi.getSimpleCustomField(field.customFieldDefinitionId))
      );

      const fullResult = result.map(res => res.data);
      const customFieldDefinition = fullResult.map(res => {
         return {
          ...res,
          ...value.find(field => res.id === field.customFieldDefinitionId),
          value: '',
        };
      });
      customFieldDefinition.sort((a, b) => a.order - b.order);
      this.setCustomFieldConditions(customFieldDefinition);
    }
  }

  @Action
  async getManualApprovalTasks(searchParams) {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getMissionsList(searchParams);
      const mapUser = await Promise.all(
        response.data.map(app => this.getUser(app.reporterId))
      );
      const mapResponse = mapUser.map(
        (item, index) => {
          return {
            ...response.data[index],
            submitter: item,
          };
        }
      );
      this.setManualApprovalTasks(mapResponse);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }
  
  @Action
  async getNotifications() {
    this.setShowError(false);
    try {
      const response = await ExpenseApi.getNotifications(this.policyId, this.currentProfileId);
      this.setNotifications(response.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getNotificationDetails(notificationId) {
    this.setShowError(false);
    this.setNotificationDetails(null);
    try {
      const response = await ExpenseApi.getNotificationsDetails(notificationId);
      let dataToSort = response.data;

      if (dataToSort.validationResults && dataToSort.validationResults.length) {
        for (let i = 0; i < dataToSort.validationResults.length; i++) {
          let order = 4;
          if (dataToSort.validationResults[i].severity === 'Violation') {
            order = 0;
          } else if (dataToSort.validationResults[i].severity === 'Warning') {
            order = 1;
          } else if (dataToSort.validationResults[i].severity === 'Risk') {
            order = 2;
          } else if (dataToSort.validationResults[i].severity === 'Information') {
            order = 3;
          } 
          dataToSort.validationResults[i].order = order;
        }
        const sortedValidationResults = _.sortBy(dataToSort.validationResults, (validationResult) => {
          return validationResult.order;
        });
        dataToSort.validationResults = sortedValidationResults;
      }
  
      this.setNotificationDetails(dataToSort);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getValidationsResults(params) {
    this.setShowError(false);
    this.setValidationResults(null);
    try {
      const response = await ExpenseApi.getValidationResults(params.expenseId, params.missionId);
      let dataToSort = response.data;

      if (dataToSort && dataToSort.length) {
        for (let i = 0; i < dataToSort.length; i++) {
          let order = 4;
          if (dataToSort[i].severity === 'Violation') {
            order = 0;
          } else if (dataToSort[i].severity === 'Warning') {
            order = 1;
          } else if (dataToSort[i].severity === 'Risk') {
            order = 2;
          } else if (dataToSort[i].severity === 'Information') {
            order = 3;
          } 
          dataToSort[i].order = order;
        }
        const sortedValidationResults = _.sortBy(dataToSort, (validationResult) => {
          return validationResult.order;
        });
        dataToSort = sortedValidationResults;
      }
  
      this.setValidationResults(dataToSort);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action 
  async submitExpenseReport(missionId) {
    this.clearErrMessages();
    this.setSubmitExpenseReportResult(null);
    try {
      const response = await ExpenseApi.submitExpenseReport(missionId);
      this.setSubmitExpenseReportResult(response.data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }
}

export default getModule(ExpenseStore);
