import consts from '@/const/table.const';

export class BaseTableParams {
  page: number = 1;
  start: number = 0;
  size: number = consts.defaultPerPage;
  sort: string = '';
  desc: boolean = true;
  usePageParam: boolean = false;

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.usePageParam !== undefined) {
      this.usePageParam = params.usePageParam;
    }
    if (params.page !== undefined) {
      this.page = params.page;
      if (!params.usePageParam) {
        this.start = params.page;
      }
    }
    if (params.start !== undefined) {
      this.start = params.start;
    }
    if (params.size !== undefined) {
      this.size = params.size;
    }
    if (params.sort !== undefined) {
      this.sort = params.sort;
    }
    if (params.desc !== undefined) {
      this.desc = params.desc;
    }

    if (params.perPage) {
      this.size = params.perPage;
    }
    if (params.currentPage) {
      this.page = params.currentPage;
    }
    if (params.sortBy !== undefined) {
      this.sort = params.sortBy;
    }
    if (params.sortDesc !== undefined) {
      this.desc = params.sortDesc;
    }
  }
}
