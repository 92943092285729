










import { Vue, Component, Prop } from 'vue-property-decorator';

import accountStore from '@/store/account.store';
import settings from '@/settings';
import { ProfileApi } from '@/api/profile/profile.api';

@Component
export default class AppMissingPermissions extends Vue {
  get canBackToMainProfile() {
    return accountStore.CurrentUser && accountStore.CurrentUser.profile.id !== accountStore.CurrentUser.originalProfileId;
  }
  
  logout() {
      location.replace(settings.logout);
  }

  async backToMainProfile() {
    await ProfileApi.backToMainProfile();
    location.replace('/');
  }
}
