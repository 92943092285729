import LodgeCardForm from '@/modules/settings/lodge-cards/LodgeCardForm.vue';

export default {
  path: ':configurationId?/lodge-card',
  name: 'lodge-card-form',
  component: LodgeCardForm,
  displayName: 'Lodge Card',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyPaymentCards',
    parentName: 'lodge-cards-configurations',
    depthCategory: 'lodge-cards-configurations',
    depth: 3,
  }
};