import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';
import HotelResults from '@/modules/search/hotel/HotelResults.vue';
import HotelSubintro from '@/modules/search/hotel/HotelSubintro.vue';
import HotelResultsFilters from '@/modules/search/hotel/HotelResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotel-results-travellers-list',
  name: 'hotelResultsTravellersList',
  components: {
    default: HotelResults,
    intro: SearchTravellersIntro,
    subintro: HotelSubintro,
    sidebar: HotelResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-car-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
