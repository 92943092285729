







































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { PreferencesModel } from '@/api/profile/preferences.model';
import FlightPreferencesPopup from './FlightPreferencesPopup.vue';
import _ from 'lodash';
import EventBus from '@/services/event-handler';
import { SupplierName } from '@/services/supplier-name.service';

@Component({
  components: {
    FlightPreferencesPopup,
  }
})
export default class FlightPreferencesSection extends Vue {
  @Prop() preferences!: PreferencesModel;
  openPopup: boolean = false;

  editFlight() {
    this.openPopup = !this.openPopup;
  }

  closePopup() {
    this.openPopup = !this.openPopup;
    EventBus.$emit('refresh-preferences');
  }

  getCarrieName(name) {
    return SupplierName(name);
  }
}
