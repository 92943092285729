
























































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import EventHandler from '@/services/event-handler';
import SettingsStore from '../settings.store';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import {
  ConfigurationRow,
  companyConfigurationType
} from '@/api/profile/configurations.model';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import PaymentMethodsStore from './payment-methods.store';
import PaymentMethodsTrainStore from './payment-methods-train.store';
import PaymentMethodsCarStore from './payment-methods-car.store';
import PaymentMethodsAccommodationStore from './payment-methods-accommodation.store';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class PaymentMethodsConfigurations extends BaseTable<ConfigurationRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  editMode: boolean = false;
  loading: boolean = false;
  fields = {
    name: {
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: '',
      class: 'settings-subpage__actions-column',
    }
  };
  items: ConfigurationRow[] = [];
  selectedSet: ConfigurationRow | null = null;
  selectedSetId: string | null = null;
  company = this.currentCompany;
  errors: any[] = [];
  errorsOnPopup: any[] = [];
  itemToRemove: ConfigurationRow | null = null;
  removalConfigurationMessage: any[] = [];
  removePopup: boolean = false;
  menuActive: boolean = false;
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.items.length;
  }

  get isInRootBusinessUnit() {
    return SettingsStore.currentCompany ? SettingsStore.currentCompany.isRoot : false;
  }

  get addNewConfigurationUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/payment-methods-configurations/payment-methods-configuration');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/payment-methods-configurations/' + item.id + '/payment-methods-configuration');
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  toggleConfigurationSetMenu(item) {
    if (!this.selectedSet || this.selectedSet.id !== item.id) {
      this.selectedSet = item;
      this.menuActive = true;
    } else {
      this.selectedSet = item;
      this.menuActive = !this.menuActive;
    }
  }

  addConfigrations() {
    PaymentMethodsStore.setCurrentConfigurationName('');
    PaymentMethodsStore.clearCurrentConfiguration();
    PaymentMethodsStore.setConfigurationId('');
    PaymentMethodsStore.setConfiguration({});
    PaymentMethodsStore.setPaymentMethodConfigurations([]);
    PaymentMethodsStore.setPaymentList([]);
    PaymentMethodsStore.setPaymentErrMessages([]);
    PaymentMethodsTrainStore.setCurrentConfigurationName('');
    PaymentMethodsTrainStore.clearCurrentConfiguration();
    PaymentMethodsTrainStore.setConfigurationId('');
    PaymentMethodsTrainStore.setConfiguration({});
    PaymentMethodsTrainStore.setPaymentMethodConfigurations([]);
    PaymentMethodsTrainStore.setPaymentList([]);
    PaymentMethodsTrainStore.setPaymentErrMessages([]);
    PaymentMethodsCarStore.setCurrentConfigurationName('');
    PaymentMethodsCarStore.clearCurrentConfiguration();
    PaymentMethodsCarStore.setConfigurationId('');
    PaymentMethodsCarStore.setConfiguration({});
    PaymentMethodsCarStore.setPaymentMethodConfigurations([]);
    PaymentMethodsCarStore.setPaymentList([]);
    PaymentMethodsCarStore.setPaymentErrMessages([]);
    PaymentMethodsAccommodationStore.setCurrentConfigurationName('');
    PaymentMethodsAccommodationStore.clearCurrentConfiguration();
    PaymentMethodsAccommodationStore.setConfigurationId('');
    PaymentMethodsAccommodationStore.setConfiguration({});
    PaymentMethodsAccommodationStore.setPaymentMethodConfigurations([]);
    PaymentMethodsAccommodationStore.setPaymentList([]);
    PaymentMethodsAccommodationStore.setPaymentErrMessages([]);
  }

  editConfigurations(item, preventRedirect = false) {
    PaymentMethodsStore.setIsFromManage(false);
    PaymentMethodsTrainStore.setIsFromManage(false);
    PaymentMethodsCarStore.setIsFromManage(false);
    PaymentMethodsAccommodationStore.setIsFromManage(false);
    if (!preventRedirect) {
      this.$router.push({
        name: 'payment-methods-configuration',
        params: {
          configurationId: item.id,
        },
      });
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    if (this.company) {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      try {
        const result = await ProfileConfigurationsApi.getByRootCompanyId(
          this.company.rootCompanyId,
          companyConfigurationType.paymentMethodConfiguration,
          this.params.size,
          this.params.page,
          Permission.ReadPaymentMethodConfiguration,
          request.searchName
        );

        if (result && result.data) {
          this.items = result.data.results;
          this.loading = false;

          return {
            page: result.data.page,
            results: result.data.results,
          };
        }
      } catch (error) {
        this.errors = this.$handleErrors(error, true);
        this.loading = false;
      }
    }
  }

  async removeConfiguration() {
    try {
      this.errors = [];
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.selectedSet!.id, Permission.WritePaymentMethodConfiguration);
      await this.reload();

      const obj = {
        type: translate('common.success'),
        title: translate('settings-payment-methods.configuration-removed'),
        message: translate('settings-payment-methods.configuration-removed-message')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
      this.loading = false;
    } finally {
      this.removePopup = false;
    }
  }
}
