






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasketStore from './basket.store';
import BasketRailDiscountCodes from './BasketRailDiscountCodes.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

@Component({
  components: {
    BasketRailDiscountCodes,
    BasketExpirationCountdown,
  }
})
export default class BasketRailDiscountCodesView extends Vue {
  @Prop() basketExpired!: boolean;

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'RAIL_TRENITALIA_VOUCHER_SELECTION') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  @Watch('bookingStep', { immediate: true })
  onBookingStepChange() {
    BasketStore.resetPriceChangesForStep({
      step: this.bookingStep,
      tripItemId: this.itemId,
    });
  }
}
