











































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import TrainCabinNormalSeat from './TrainCabinNormalSeat.vue';
import TrainCabinBulkhead from './TrainCabinBulkhead.vue';
import TrainCabinExit from './TrainCabinExit.vue';
import TrainCabinLavatory from './TrainCabinLavatory.vue';
import TrainCabinLuggage from './TrainCabinLuggage.vue';
import TrainCabinTable from './TrainCabinTable.vue';
import TrainCabinWindow from './TrainCabinWindow.vue';
import SeatMapConst from '@/const/rail-seat-map.const';

@Component({
  components: {
    TrainCabinNormalSeat,
    TrainCabinBulkhead,
    TrainCabinExit,
    TrainCabinLavatory,
    TrainCabinLuggage,
    TrainCabinTable,
    TrainCabinWindow,
  },
})
export default class RailSeatMapLegend extends Vue {
  consts: any = SeatMapConst;
}
