export interface PagingQuery {
  start: number;
  offset: number;
}

export interface Price {
  amount?: number;
  currency?: Currency;
}

export interface MissionPrice {
  amount?: number;
  currency?: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface Type {
  id: number;
  name: string;
}

export interface ExpenseAmount {
  netValue?: Price;
  grossValue?: Price;
  vatRate: number;
  vatAmount: Price;
}

export interface SourceAmount {
  grossProvided: boolean;
  netValue?: Price;
  grossValue?: Price;
  vatRate: number;
  vatAmount: Price;
}

export interface ExpenseResult {
  id: string;
  name: string;
  state: string;
  description: string;
  incurrenceDate: string;
  sourceAmount: SourceAmount;
  expenseAmount: ExpenseAmount;
  merchant: string;
  code: string;
  created: string;
  reporterId: string;
  policyId: string;
  categoryId: string;
  isReimbursable: boolean;
  isBillable: boolean;
  companyId: string;
  businessUnitId: string;
  sourceType: string;
}

export interface ExpenseModel {
  name: string;
  state: string;
  description: string;
  incurrenceDate: string;
  sourceAmount: Price;
  reporterId: string;
  policyId: string;
}

export interface CreateExpenseResult {
  expenseId: string;
}

export interface CreateMissionResult {
  missionId: string;
}

export interface MissionResult {
  id: string;
  name: string;
  state: string;
  description: string;
  missionBudget: MissionPrice;
  missionEnd: string;
  missionStart: string;
  reporterId: string;
  policyId: string;
  destinations: any;
  submitter?: string;
}

export interface MissionModel {
  name: string;
  state: string;
  description: string;
  missionBudget: MissionPrice;
  missionEnd: string;
  missionStart: string;
  reporterId: string;
  policyId: string;
  destinations: any;
}

export interface ExpenseCategoryResult {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
}

export interface ImageSizeModel {
  type: string;
  length: number;
  width: number;
  height: number;
}

export interface ReceiptFileModel {
  size: ImageSizeModel;
  format: string;
  file: string;
}

export interface MissionNamesResult {
  id: string;
  name: string;
}

export interface DefaultExpensePolicyResult {
  id: string;
  name: string;
  description: string;
  currency: string;
}

export interface ExpensePolicyIdResult {
  expensePolicyId: string;
}

export interface BasicExtension {
  propertyType?: string;
  isRequired?: boolean;
}

export interface CustomFieldCondition {
  customFieldDefinitionId?: string;
  order?: number;
  isRequired?: boolean;
}

export interface ExpenseTransitionRule {
  targetState?: string;
  propertyType?: string;
  isRequired?: string;
}

export interface CustomFieldTransitionRule {
  targetState?: string;
  customFieldDefinitionId?: string;
  isRequired?: string;
}

export interface ExpectedExpenseModel {
  basicExtensions: BasicExtension[];
  customFieldConditions: CustomFieldCondition[];
  transitionRules: ExpenseTransitionRule[];
  customFieldTransitionRules: CustomFieldTransitionRule[];
}

export interface ValidationResultResponseModel {
  id: string;
  resolutionExplanation: string;
  resultMessage: string;
  expenseId: string;
  missionId: string;
  expensePolicyId: string;
  auditRuleId: string;
  validationTransactionId: string;
  detected: string;
  resolved: string;	
  severity: string;
  order?: number;
}

export interface NotificationModel {
  id: string;
  title: string;
  message: string;
  severity: string;
  createdAt: string;
  recipientId: string;
  policyId:	string;
  delivered: boolean;
}

export interface ExpenseValidationResult {
  id?: string;
  name?: string;
  state?: string;
}

export interface MissionValidationResult {
  id?: string;
  name?: string;
  state?: string;
}

export interface NotificationValidationResultModel {
  id?:	string;
  resultMessage?: string;
  expense?: ExpenseValidationResult;
  mission?: MissionValidationResult;
  detected?: string;
  scope?:	string;
  severity?: string;
  order?: number;
}

export interface NotificationResponseModel {
  id: string;
  title: string;
  message: string;
  severity: string;
  createdAt:	string;
  recipientId:	string;
  policyId:	string;
  delivered: boolean;
  validationResults?: NotificationValidationResultModel[];
}

export interface TripItemTypeCategoryResult {
  tripItemType: string;
  category: Category | null;
}

export interface Category {
  id: string;
  name: string;
}

export interface GetExpensesMessageFilters {
  reporterId?: string;
  policyId?: string;
  missionId?: string;
  companyId?: string;
  businessUnitId?: string;
}

export interface GetMissionsMessageFilters {
  profileId?: string;
  policyId?: string;
  approverId?: string;
  companyId?: string;
  businessUnitId?: string;
}

export interface GetExpensesMessage {
  filters: GetExpensesMessageFilters;
  paging: PagingQuery;
}

export interface GetMissionsMessage {
  filters: GetMissionsMessageFilters;
  paging: PagingQuery;
}

export interface SubmitExpenseReportResult {
  missionId: string;
  expensesNotCompleted: ExpenseNameModel[];
}

export interface ExpenseNameModel {
  id: string;
  name: string;
  state: string;
}

export class ExpenseDefinitionModel {
  id: string = '';
  name: string = '';
  description: string = '';
  currency: Currency | null = null;
  companyId: string = '';
  validFrom: Date | string = '';
  validTo: Date | string = '';
  isDefault: boolean = false;
  isActive: boolean = false;
  configurationId: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.currency !== undefined) {
      this.currency = params.currency;
    }
    if (params.companyId !== undefined) {
      this.companyId = params.companyId;
    }
    if (params.validFrom !== undefined) {
      this.validFrom = params.validFrom ? new Date(params.validFrom) : '';
    }
    if (params.validTo !== undefined) {
      this.validTo = params.validTo ? new Date(params.validTo) : '';
    }
    if (params.isDefault !== undefined) {
      this.isDefault = params.isDefault;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.configurationId !== undefined) {
      this.configurationId = params.configurationId;
    }
  }
}

export class SearchExpense {
  name: string = '';
  state: string = 'Draft';
  description: string = '';
  categoryId: string = '';
  missionId: string = '';
  mission!: any;
  incurrenceDate: any = new Date();
  merchant: string = '';
  sourceAmount: any = {
    grossProvided: false,
    value: {
      amount: null,
      currency: null,
    },
    vatRate: null,
  };
  isReimbursable: boolean = false;
  isBillable: boolean = false;
  reporterId: string = '';
  policyId: string = '';
  companyId: string = '';
  businessUnitId: string = '';
  paymentInstrumentId: string = '';
  projectId: string = '';
  customFieldValues: any = [];
  countryCode: string = '';
  hasReceipt: boolean = false;

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.name !== undefined) {
      this.name = params.name;
    }

    if (params.state !== undefined) {
      this.state = params.state;
    }

    if (params.description !== undefined) {
      this.description = params.description;
    }

    if (params.incurrenceDate !== undefined) {
      if (params.incurrenceDate !== null) {
        this.incurrenceDate = new Date(params.incurrenceDate);
      }
      else {
        this.incurrenceDate = '';
      }
    }

    if (params.sourceAmount !== undefined) {
      this.sourceAmount.grossProvided = params.sourceAmount.grossProvided;

      if (params.sourceAmount.grossValue !== null || params.sourceAmount.netValue !== null) {
        this.sourceAmount = params.sourceAmount;

        if (params.sourceAmount.grossProvided === true) {
          this.sourceAmount.value = params.sourceAmount.grossValue;
        } else {
          this.sourceAmount.value = params.sourceAmount.netValue;
        }
      }
    }  

    if (params.merchant !== undefined) {
      this.merchant = params.merchant;
    }  

    if (params.categoryId !== undefined) {
      this.categoryId = params.categoryId;
    }  

    if (params.mission !== undefined) {
      this.mission = params.mission;
    }  

    if (params.reporterId !== undefined) {
      this.reporterId = params.reporterId;
    } 

    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    } 

    if (params.isReimbursable !== undefined) {
      this.isReimbursable = params.isReimbursable;
    } 

    if (params.isBillable !== undefined) {
      this.isBillable = params.isBillable;
    } 

    if (params.companyId !== undefined) {
      this.companyId = params.companyId;
    } 

    if (params.businessUnitId !== undefined) {
      this.businessUnitId = params.businessUnitId;
    } 

    if (params.paymentInstrumentId !== undefined) {
      this.paymentInstrumentId = params.paymentInstrumentId;
    } 

    if (params.projectId !== undefined) {
      this.projectId = params.projectId;
    } 

    if (params.customFieldValues !== undefined) {
      this.customFieldValues = params.customFieldValues;
    }
    
    if (params.countryCode !== undefined) {
      this.countryCode = params.countryCode;
    } 
    
    if (params.hasReceipt !== undefined) {
      this.hasReceipt = params.hasReceipt;
    } 
  
  }

}
export class SearchMission {
  name: string = '';
  state: string = 'Draft';
  description: string = '';
  missionBudget: any = {
    amount: 0,
    currency: {
      code: 'EUR',
      symbol: null
    }
  };
  missionEnd: any = null;
  missionStart: any = new Date();
  reporterId: string = '';
  companyId: string = '';
  policyId: string = '';
  destinations: any = [];
  businessUnitId: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.name !== undefined) {
      this.name = params.name;
    }

    if (params.state !== undefined) {
      this.state = params.state;
    }

    if (params.description !== undefined) {
      this.description = params.description;
    }

    if (params.missionStart !== null && params.missionStart !== undefined) {
      this.missionStart = new Date(params.missionStart);
    }

    if (params.missionEnd !== null && params.missionEnd !== undefined) {
      this.missionEnd = new Date(params.missionEnd);
    }

    if (params.missionBudget !== undefined) {
      this.missionBudget = params.missionBudget;
    }

    if (params.companyId !== undefined) {
      this.companyId = params.companyId;
    }

    if (params.businessUnitId !== undefined) {
      this.businessUnitId = params.businessUnitId;
    } 

    if (params.reporterId !== undefined) {
      this.reporterId = params.reporterId;
    }

    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }

    if (params.destinations !== undefined) {
      this.destinations = params.destinations;
    }
  }
}

export class ApprovalTaskModel {
  name: string = '';
  state: string = 'Draft';
  stage: string = '';
  description: string = '';
  missionBudget: any = {
    amount: 0,
    currency: {
      code: 'EUR',
      symbol: null
    }
  };
  missionEnd: any = null;
  missionStart: any = null;
  reporterId: string = '';
  policyId: string = '';
  project: any = null;
  destinations: any = [];
  customFieldOne: any = null;
  customFieldTwo: any = null;

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.name !== undefined) {
      this.name = params.name;
    }

    if (params.state !== undefined) {
      this.state = params.state;
    }

    if (params.stage !== undefined) {
      this.stage = params.stage;
    }

    if (params.description !== undefined) {
      this.description = params.description;
    }

    if (params.missionStart !== null && params.missionStart !== undefined) {
      this.missionStart = new Date(params.missionStart);
    }

    if (params.missionEnd !== null && params.missionEnd !== undefined) {
      this.missionEnd = new Date(params.missionEnd);
    }

    if (params.missionBudget !== undefined) {
      this.missionBudget = params.missionBudget;
    }

    if (params.reporterId !== undefined) {
      this.reporterId = params.reporterId;
    }

    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }

    if (params.project !== undefined) {
      this.project = params.project;
    }

    if (params.customFieldOne !== undefined) {
      this.customFieldOne = params.customFieldOne;
    }

    if (params.customFieldTwo !== undefined) {
      this.customFieldTwo = params.customFieldTwo;
    }

    if (params.destinations !== undefined) {
      this.destinations = params.destinations;
    }
  }
}

export enum ExpenseState {
  Draft = 'Draft',
  Claimed = 'Claimed', 
  Completed = 'Completed', 
  Cancelled = 'Cancelled', 
  Violation = 'Violation',
  Closed = 'Closed', 
  Rejected = 'Rejected',
}

export class AuditRuleModel {
  id: string = '';
  expensePolicyId: string = '';
  name: string = '';
  description: string = '';
  resultMessage: string = '';
  scope: string = '';
  processType: string = '';
  severity: string = '';
  isActive: boolean = false;
  expression: string = '';
  order: number = 0;
  actionIndicator: string = '';
  escalationLevel: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.expensePolicyId !== undefined) {
      this.expensePolicyId = params.expensePolicyId;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.resultMessage !== undefined) {
      this.resultMessage = params.resultMessage;
    }
    if (params.scope !== undefined) {
      this.scope = params.scope;
    }
    if (params.processType !== undefined) {
      this.processType = params.processType;
    }
    if (params.severity !== undefined) {
      this.severity = params.severity;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.expression !== undefined) {
      this.expression = params.expression;
    }
    if (params.order !== undefined) {
      this.order = params.order;
    }
    if (params.actionIndicator !== undefined) {
      this.actionIndicator = params.actionIndicator;
    }
    if (params.escalationLevel !== undefined) {
      this.escalationLevel = params.escalationLevel;
    }
  }
}

export enum MissionState {
  Unknown = 'Unknown',
  Draft = 'Draft',
  MissionRequestSubmitted = 'MissionRequestSubmitted',
  MissionRequestInApproval = 'MissionRequestInApproval', 
  MissionApproved = 'MissionApproved', 
  MissionRequestRejected = 'MissionRequestRejected',
  AmendmentNeeded = 'AmendmentNeeded', 
  MissionCancelled = 'MissionCancelled', 
  ExpenseReportSubmitted = 'ExpenseReportSubmitted', 
  ExpenseReportApproved = 'ExpenseReportApproved', 
  ExpenseReportInApproval = 'ExpenseReportInApproval', 
  ExpenseReportRejected = 'ExpenseReportRejected', 
  ExpenseReportCancelled = 'ExpenseReportCancelled',
  ReportAmendmentNeeded = 'ReportAmendmentNeeded',
}

export enum ApprovalTasksStage {
  Unknown = 'Unknown', 
  MissionPreapproval = 'MissionPreapproval', 
  SingleExpenseValidation = 'SingleExpenseValidation', 
  ExpenseReportValidation = 'ExpenseReportValidation'

}