







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import {
  BasketTripCommentModel,
  TripCommentType,
} from '@/api/trip/trip.model';
import { userFullName } from '@/core/user-full-name';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import BasketStore from './basket.store';

@Component({
  components: {
  }
})
export default class BasketTripComment extends Vue {
  @Prop() comment!: BasketTripCommentModel;

  showingConfirmRemoveComment: boolean = false;


  get user() {
    if (!this.comment || !this.comment.profile || !this.comment.profile.profileId) {
      return null;
    }
    return {
      id: this.comment.profile.profileId,
      firstName: this.comment.profile.firstName,
      middleName: this.comment.profile.middleName,
      lastName: this.comment.profile.lastName,
      companyName: this.comment.profile.companyName,
    };
  }

  get commentIcon() {
    if (this.comment.commentType === TripCommentType.Internal) {
      return 'visibility_off';
    }

    return 'visibility';
  }

  get commentTypeText() {
    if (this.comment.commentType === TripCommentType.Internal) {
      return translate('basket-comments.comment-not-visible');
    }
    return translate('basket-comments.comment-visible');
  }

  get canViewComments() {
    return this.$hasAccess('CanViewTripComments');
  }

  get canAddComments() {
    return this.$hasAccess('CanAddTripComments');
  }
  
  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }

  get removeErrors() {
    return BasketStore.basketCommentsRemoveErrors;
  }



  userFullName(user) {
    return userFullName(user);
  }

  formatDate(date) {
    return moment(date).format(this.dateTimeFormat);
  }

  async removeNow() {
    await BasketStore.removeTripComment(this.comment.id);

    if (this.removeErrors.length) {
      return;
    }

    this.showingConfirmRemoveComment = false;
    this.$emit('remove');
  }
}

