import { CancelTokenSource } from 'axios';

export interface HTTPInterface {
  execute(
    request: Request,
    query: any,
    params: any,
    preventParamDestructure?: boolean,
    cancelTokenSource?: CancelTokenSource
  );
}

export interface Requests {
  [name: string]: Request;
}

export interface Request {
  method: RequestMethod;
  path: string;
  permission?: string;
  headers?: any;
}

export enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  HEAD = 'head',
}
