





























import { Vue, Component, Watch } from 'vue-property-decorator';
import settings from '@/settings';

@Component({})
export default class MessagesWidget extends Vue {
  canShowMore: boolean = true;
  elemHight = 0;

  get getTextMessagesWidget() {
    return settings.enableExternalMessagesWidgetText;
  }

  get countNumber() {
    return this.getTextMessagesWidget ? 1 : 0;
  }

  get classes() {
    return {
      'widget-text-max': this.canShowMore,
      'widget-text-shadow': !this.canShowMore && this.elemHight > 90
    };
  }

  showMore() {
    this.canShowMore = true;
  }

  hideMore() {
    this.canShowMore = false;
    this.$forceUpdate();
  }

  mounted() {
    const el = this.$refs.widgetText as HTMLElement;
    if (el) {
      this.elemHight = el.offsetHeight;
    }
  }
}
