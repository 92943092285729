







































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { PreferencesModel } from '@/api/profile/preferences.model';
import RailPreferencesPopup from './RailPreferencesPopup.vue';
import _ from 'lodash';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    RailPreferencesPopup,
  }
})
export default class RailPreferencesSection extends Vue {
  @Prop() preferences!: PreferencesModel;
  openPopup: boolean = false;

  editRail() {
    this.openPopup = !this.openPopup;
  }

  closePopup() {
    this.openPopup = !this.openPopup;
    EventBus.$emit('refresh-preferences');    
  }
}
