import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  AccommodationProviderConfigurations
} from './accommodation-provider-configuration.model';

class AccommodationProviderConfigurationSetApiClass {
  public create(params: AccommodationProviderConfigurations): Promise<AxiosResponse<AccommodationProviderConfigurations>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getById(configurationId): Promise<AxiosResponse<AccommodationProviderConfigurations>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/configurations/{configurationId}',
      method: RequestMethod.GET
    }, {
        configurationId
    }, {});
  }

  public getEmpty(): Promise<AxiosResponse<AccommodationProviderConfigurations>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/configurations/empty',
      method: RequestMethod.GET
    }, {}, {});
  }

  public update(configurationId: string, params: AccommodationProviderConfigurations): Promise<AxiosResponse<AccommodationProviderConfigurations>> {
  return http.execute({
      path: settings.apiAccommodationEngine + '/configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, {configurationId}, params);
  }
}

export const AccommodationProviderConfigurationSetApi: AccommodationProviderConfigurationSetApiClass = new AccommodationProviderConfigurationSetApiClass();