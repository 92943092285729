import FareMappingAir from '@/modules/settings/fares-mapping/FareMappingAirRule.vue';

export default {
  path: ':configurationId?/fares-mapping-rule-air',
  name: 'fares-mapping-rule-air',
  component: FareMappingAir,
  displayName: 'Air fares mapping configuration',
  meta: {
    breadCrumb: 'Fares mapping configuration',
    introClass: 'intro-settings',
    permission: 'CanEditFareMapping',
    parentName: 'fares-mapping-configurations',
    depthCategory: 'fares-mapping-configurations',
    depth: 3,
  },
};