













































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { Recommendation, Traveller } from '@/api/air-engine/air-search.model';
import AirSearchStore from '@/modules/search/air/air-search.store';

@Component({
})
export default class AirExchangeOfferDetails extends Vue {
  @Prop() offer!: Recommendation;
  @Prop() travellers!: Traveller[];
  @Prop() show!: boolean;
  @Prop() showButton!: boolean;
  @Prop() selectedProposal!: any;

  selectedPassengers: any[] = [];

  get travellersMapped() {
    return this.travellers.map(traveller => {
      if (!(this.originalOffer as any).passengers) {
        return traveller;
      }
      const priceInfo = (this.originalOffer as any).passengers
        .find(p => p.profileId === traveller.id);

      return {
        ...traveller,
        isToggled: -1 < this.selectedPassengers.findIndex(p => p === traveller.id),
        priceInfo,
      };
    });
  }

  get initErrors() {
    return AirSearchStore.exchangeInitErrors;
  }

  get canShowTravellersDetails() {
    if (this.isNewFlightDisplay) {
      if (this.selectedProposal) {
        return -1 === [
        'Lufthansa',
        'AirFranceKlm',
      ].indexOf(this.selectedProposal.supplier);
      } else {
        return false;
      }
    } else {
      return -1 === [
        'Lufthansa',
        'AirFranceKlm',
      ].indexOf(this.offer.proposal.supplier);
    }
  }



  get originalOffer() {
    return AirSearchStore.exchangedOffer;
  }

  get totalBookingCost() {
    if (!this.isNewFlightDisplay) {
      const p = this.offer.proposal as any;
      return p.price.amount;
    } else if (this.isNewFlightDisplay && this.selectedProposal) {
      return this.selectedProposal.price.amount;
    } else {
      return false;
    }
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }



  userFullName(user) {
    return userFullName(user);
  }

  clickPassenger(passenger) {
    const idx = this.selectedPassengers.findIndex(p => p === passenger.id);
    if (idx === -1) {
      this.selectedPassengers.push(passenger.id);
    } else {
      this.selectedPassengers.splice(idx, 1);
    }
  }

  formatPrice(price) {
    if (!price) {
      return '';
    }
    return price.currency.symbol + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }

  @Emit('update:show')
  hide() {
    return false;
  }
}

