import CompanyStructureAddEdit from '@/modules/settings/company-structure/CompanyStructureAddEdit.vue';

export default {
  path: ':configurationId?/configuration',
  name: 'company-structure-configuration',
  component: CompanyStructureAddEdit,
  displayName: 'Manage configuration assignment',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyStructure',
    depthCategory: 'company-structure',
    depth: 2,
    parentName: 'company-structure',
  }
};