






import { Vue, Component } from 'vue-property-decorator';

import Table1 from './Table1.vue';

@Component({
  components: {
    Table1,
  },
})
export default class Table1View extends Vue {}

