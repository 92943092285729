import { render, staticRenderFns } from "./NegoFarePnrSsrCodes.vue?vue&type=template&id=0fb94874"
import script from "./NegoFarePnrSsrCodes.vue?vue&type=script&lang=ts"
export * from "./NegoFarePnrSsrCodes.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports