import { Page } from '../roles/roles.model';
export interface AgencyModel {
  page: Page;
  results: Customer[];
}

export interface Customer {
  companyId: string;
  companyRootId: string;
  companyParentId: string;
  companyName: string;
  companyCode: string;
  agencyId: string;
  agencyName: string;
}
export class CreateAgencyCustomerMessage {
  code: string;
  name: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  parentId: string | null;
  agencyId: string | null;

  public constructor() {
    this.name = '';
    this.code = '';
    this.address = '';
    this.phoneNumber = '';
    this.faxNumber = '';
    this.emailAddress = '';
    this.parentId = null;
    this.agencyId = null;
  }
}
export interface AvailableAgencies {
  key: string;
  value: string;
}

export interface AgencyCustomerModel {
  id: string;
  code: string;
  name: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  isDisabled: boolean;
  parentId: string;
  rootId: string;
  isAgency: boolean;
}