import moment from 'moment';
import accountStore from '@/store/account.store';
import { AvailableTimeFormats } from '@/api/profile/company.model';

export default (value) => {
  if (value) {
    if (accountStore.CurrentUser!.profile.timeFormat) {
      return moment(String(value)).format(accountStore.CurrentUser!.profile.timeFormat);
    } 
    return moment(String(value)).format(AvailableTimeFormats.AvailableTimeFormat1);
  }
};
