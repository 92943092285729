














import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import AirSearchStore from '@/modules/search/air/air-search.store';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import Search from '@/modules/search/Search.vue';
import { BasketModel } from '@/api/trip/basket.model';
import { AirApi } from '@/api/air-engine/air-search.api';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    Search,
  }
})
export default class BasketAddSegment extends Vue {
  searchId: string | null = null;
  basket: BasketModel | null = null;

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get loadingItems() {
    return BasketStore.loadingItems;
  }

  hideCriteria() {
    EventBus.$emit('add-segment', false);
    router.push({
      name: 'basket',
      params: router.currentRoute.params,
    });
  }

  @Watch('loadingItems', { immediate: true })
  onChange(value) {
    if (value === false) {
      BasketStore.updateSearchDefaultStates();
    }
  }
}
