import TrainFiltersSubintro from '@/modules/search/train/TrainFiltersSubintro.vue';
import TrainSearchShortParams from '@/modules/search/train/TrainSearchShortParams.vue';
import TrainResultsFilters from '@/modules/search/train/TrainResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/train-results-filters/:searchId/:leg',
  name: 'trainFilters',
  components: {
    default: TrainResultsFilters,
    intro: TrainSearchShortParams,
    subintro: TrainFiltersSubintro,
  },
  meta: {
    introClass: 'intro-train-results',
    permission: 'SearchOffers',
    mobile: true,
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        trainDetails: AnimationName.DOWN,
      },
      subintro: {
        train: AnimationName.UP,
      },
    }
  }
};
