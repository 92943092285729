








































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import { SupplierName } from '@/services/supplier-name.service';
import {
  translate,
  translationExists,
} from '@/i18n';

@Component({
  filters: {
    priceFix(price) {
      return price ? Number.parseFloat(price).toFixed(2) : ' ';
    }
  }
})
export default class CategoriesFilter extends Vue {
  @Prop() code!: string;
  @Prop() label!: string;
  @Prop() categories;
  @Prop({ default: () => { return {}; } }) meta: any;
  @Prop() maxCategoriesInCollapsedMode!: number;
  @Prop({ default: false }) showImagesForValues!: boolean;

  expanded = false;
  visible = true;
  checked = this.getSelected();
  checkedUnwatch: any = null;
  imagesLoaded: any[] = [];
  imagesVisible: any[] = [];

  get mustHaveCategories() {
    if (!this.meta.customHideOptions) {
      return [];
    }
    return this.categories
      .filter(category => category.shouldHide === false);
  }

  get customCategories() {
    return this.categories
      .filter(category => -1 < this.checked.indexOf(category.code) ||
          this.mustHaveCategories.find(c => c.code === category.code));
  }

  get regularCategories() {
    const mixedCategory = this.categories.find(category => {
      return category.isMixedModeModifier;
    });

    let counter = this.maxCategoriesInCollapsedMode - this.checked.length;
    let list: any[] = [];

    if (mixedCategory && -1 < this.checked.indexOf(mixedCategory.code)) {
      counter++;
    }

    this.categories
      .filter(category => !category.isMixedModeModifier)
      .forEach(category => {
        if (-1 < this.checked.indexOf(category.code)) {
          list.push(category);
        } else if (counter > 0) {
          list.push(category);
          counter--;
        }
      });
    
    if (mixedCategory) {
      return [...list, mixedCategory];
    }
    return list;
  }

  get currentCategoriesShort() {
    if (!this.categories) {
      return [];
    }
    if (this.meta.customHideOptions) {
      return this.customCategories;
    }
    return this.regularCategories;
  }

  get currentCategories() {
    if (!this.categories) {
      return [];
    }
    if (this.expanded) {
      return this.categories;
    }
    return this.currentCategoriesShort;
  }

  get mixinExistInCategories() {
    return this.categories.find(category => category.code === '_MIXED') ? 1 : 0;
  }

  get showExpand() {
    if (!this.categories) {
      return false;
    }
    if (this.expanded) {
      return this.categories.length > (this.maxCategoriesInCollapsedMode + this.mixinExistInCategories) &&
        this.currentCategoriesShort.length < this.categories.length;
    }
    return this.categories.length > this.currentCategories.length;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  categoryTranslation(name) {
    const key: string = 'search-filters-values.' + name;

    if (translationExists(key)) {
      return translate(key);
    }

    return name;
  }

  categoryClass(category) {
    return {
      'categories-filter__label--bold': category.isMixedModeModifier,
      'categories-filter__stars-result': category.stars
    };
  }

  getSelected(categories?) {
    if (!categories) {
      categories = this.categories ? this.categories : [];
    }
    return categories
      .filter(x => x.selected)
      .map(c => c.code);
  }

  categoryVisible(categoryIdx) {
    if (this.meta.customHideOptions) {
      return this.categories[categoryIdx].shouldHide === false || this.expanded;
    }
    return categoryIdx < this.maxCategoriesInCollapsedMode || this.expanded;
  }

  imageVisible(category) {
    return !this.imagesVisible
      .find(item => item.code === category.code && !item.visible);
  }

  imageProcessing(category) {
    return !this.imagesLoaded.find(c => c === category.code);
  }

  imageLoaded(category) {
    this.imagesLoaded.push(category.code);
  }

  imageError(category) {
    this.imagesVisible.push({
      code: category.code,
      visible: false,
    });
  }

  getSupplierImagePath(category) {
    return '/assets/img/providers/' + category.code.toLowerCase() + '.png';
  }

  clearSelection() {
    this.checked = [];
  }

  refresh(categoriesData) {
    this.checked = this.getSelected(categoriesData);

    this.createCheckedWatcher();
  }

  checkedChangedHandler(checkedValues) {
    if (this.categories) {
      this.categories.forEach(c => {
        const selected = checkedValues.includes(c.code);
        if (c.selected !== selected) {
          c.selected = selected;
        }
      });
    }

    this.$emit('change', {
      code: this.code,
      data: this.categories
    });
  }

  createCheckedWatcher() {
    if (this.checkedUnwatch) {
      this.checkedUnwatch();
    }
    this.checkedUnwatch = this.$watch('checked', this.checkedChangedHandler, {
      deep: true,
    });
  }

  refreshWhenNeeded(params) {
    if (this.code !== params.code) {
      return;
    }

    this.refresh(params.data);
  }



  created() {
    this.createCheckedWatcher();

    EventBus.$on('clear-filters', this.clearSelection);
    EventBus.$on('refresh-filter-component', this.refreshWhenNeeded);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearSelection);
    EventBus.$off('refresh-filter-component', this.refreshWhenNeeded);
    if (this.checkedUnwatch) {
      this.checkedUnwatch();
    }
  }
}

