






import { Vue, Component } from 'vue-property-decorator';

import NotificationsTable from './NotificationsTable.vue';

@Component({
  components: {
    NotificationsTable,
  },
})
export default class Notifications extends Vue {}

