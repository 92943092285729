import air from './air/air.route';
import airModification from './air/air-modification.route';
import airDetails from './air/air-details.route';
import airTravellers from './air/air-travellers.route';
import airModificationTravellers from './air/air-modification-travellers.route';
import airFilters from './air/air-filters.route';
import airResultDetails from './air/air-result-details.route';
import airResultDetailsView from './air/air-result-details-view.route';
import airResultsTravellers from './air/air-results-travellers.route';
import hotel from './hotel/hotel.route';
import hotelResultsMap from './hotel/hotel-map.route';
import hotelResultsSearch from './hotel/hotel-results-search.route';
import hotelDetails from './hotel/hotel-details.route';
import hotelDetailsBasketRoute from './hotel/hotel-details-basket.route';
import hotelDetailsSearch from './hotel/hotel-details-search.route';
import hotelDetailsTravellers from './hotel/hotel-details-travellers.route';
import hotelTravellers from './hotel/hotel-travellers.route';
import hotelFilters from './hotel/hotel-filters.route';
import hotelResultDetails from './hotel/hotel-result-details.route';
import hotelResultsTravellers from './hotel/hotel-results-travellers.route';
import train from './train/train.route';
import trainDetails from './train/train-details.route';
import trainTravellers from './train/train-travellers.route';
import trainResultsTravellers from './train/train-results-travellers.route';
import trainModification from './train/train-modification.route';
import trainFilters from './train/train-filters.route';
import trainResultDetailsView from './train/train-result-details-view.route';
import car from './car/car.route';
import carDetails from './car/car-details.route';
import carTravellers from './car/car-travellers.route';
import carResultsTravellers from './car/car-results-travellers.route';
import carFilters from './car/car-filters.route';
import demo from './demo/demo.route';
import demoLayout from './demo/demo-layout.route';
import demoLayout2 from './demo/demo-layout-2.route';
import home from './home/home.route';
import myPools from './home/my-pools.route';
import profile from './profile/profile.route';
import search from './home/search.route';
import searchTravellers from './home/search-travellers.route';
import settings from './settings/settings.route';
import globalSettings from './global-settings/global-settings.route';
import basket from './basket/basket.route';
import basketPayment from './basket/basket-payment.route';
import basketAddSegment from './basket/basket-add-segment.route';
import basketTravellers from './basket/basket-travellers.route';
import yourLatestBasket from './basket/your-latest-basket.route';
import basketExpired from './basket/basket-expired.route';
import trips from './trips.route';
import stillLoading from './loading.route';
import showTicket from './show-ticket.route';
import bookings from './bookings.route';
import offline from './offline.route';
import approvals from './approvals/approvals.route';
import resultsTravellers from './home/results-travellers.route';
import expense from './expense/expense.route';
import addExpense from './expense/add-expense.route';
import modifyExpense from './expense/modify-expense.route';
import addMission from './expense/add-mission.route';
import modifyMission from './expense/modify-mission.route';
import expenseListFiltered from './expense/expense-list-filtered.route';
import approvalTask from './expense/approval-task.route';
import externalMission from './expense/external-mission.route';
import notifications from './notifications.route';

export default [
  home,
  myPools,
  search,
  searchTravellers,
  air,
  airModification,
  airModificationTravellers,
  airDetails,
  airTravellers,
  airFilters,
  hotelResultsMap,
  airResultDetails,
  airResultDetailsView,
  airResultsTravellers,
  hotel,
  hotelResultsSearch,
  hotelDetails,
  hotelDetailsBasketRoute,
  hotelDetailsSearch,
  hotelDetailsTravellers,
  hotelTravellers,
  hotelFilters,
  hotelResultDetails,
  hotelResultsTravellers,
  train,
  trainDetails,
  trainTravellers,
  trainResultsTravellers,
  trainModification,
  trainFilters,
  trainResultDetailsView,
  car,
  carDetails,
  carTravellers,
  carResultsTravellers,
  carFilters,
  basketExpired,
  trips,
  stillLoading,
  showTicket,
  bookings,
  offline,
  approvals,
  demo,
  demoLayout,
  demoLayout2,
  settings,
  globalSettings,
  profile,
  basket,
  basketPayment,
  basketAddSegment,
  basketTravellers,
  yourLatestBasket,
  expense,
  addExpense,
  modifyExpense,
  addMission,
  modifyMission,
  expenseListFiltered,
  approvalTask,
  externalMission,
  notifications,
  resultsTravellers,
];
