







































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf, maxLength } from 'vuelidate/lib/validators';

import {
  TripComment,
  TripCommentFlow,
  TripCommentType,
} from '@/api/trip/trip.model';
import AccountStore from '@/store/account.store';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
  }
})
export default class BasketTripCommentEdit extends Vue {
  comment: TripComment = {
    tripId: this.$route.params.id,
    type: TripCommentType.External,
    commentFlow: this.isBooking ? TripCommentFlow.Booking : TripCommentFlow.AfterBooking,
    comment: '',
    sendEmail: true,
  };
  saving: boolean = false;
  $v;



  get isBooking() {
    return BasketStore.status && BasketStore.status.status === 'Draft';
  }

  get user() {
    return AccountStore.current;
  }

  get isInternal() {
    return this.comment.type === TripCommentType.Internal;
  }

  get canViewComments() {
    return this.$hasAccess('CanViewTripComments');
  }

  get canAddComments() {
    return this.$hasAccess('CanAddTripComments');
  }

  get addErrors() {
    return BasketStore.basketCommentsAddErrors;
  }



  @Validation()
  validationObject() {
    return { 
      comment: {
        comment: {
          requiredIf: requiredIf(() => {
            return !this.isBooking;
          }),
          maxLength: maxLength(1000),
        }
      }
    };
  }

  async saveComment() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.saving = true;
    BasketStore.setHasCommentToSave(true);
    BasketStore.setCommentToSave(this.comment);
    await BasketStore.saveComment();
    this.saving = false;
    if (BasketStore.basketCommentsAddErrors.length) {
      return;
    }
    this.$emit('edit');

    this.$nextTick(() => {
      this.$v.$reset();
      this.comment = {
        tripId: this.$route.params.id,
        type: TripCommentType.External,
        commentFlow: this.isBooking ? TripCommentFlow.Booking : TripCommentFlow.AfterBooking,
        comment: '',
        sendEmail: true,
      };
    });
  }

  validateComment() {
    BasketStore.setHasCommentToSave(!!this.comment.comment);

    if (!!this.comment.comment) {
      BasketStore.setCommentToSave(this.comment);
    }
  }

  created() {
    EventBus.$on('basket-comment-validate', this.validateComment);
  }

  beforeDestroy() {
    EventBus.$off('basket-comment-validate', this.validateComment);
  }
}

