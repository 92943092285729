











import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchChooseTravellers from '@/modules/search/SearchChooseTravellers.vue';
import Search from '@/modules/search/Search.vue';
import SearchStore from '@/modules/search/search.store';

@Component({
  components: {
    SearchChooseTravellers,
    Search,
  }
})
export default class SearchHome extends Vue {

  get canSearch() {
    return this.$hasAccess('SearchOffers');
  }

  get canChooseTravellers() {
    return this.$hasAccess('SelectTravellersForSearch');
  }

  @Watch('canChooseTravellers', { immediate: true })
  onChange(value) {
    if (!value) {
      SearchStore.selectBasket('');
      SearchStore.setMissionId('');
    }
  }
}
