





















import { Vue, Component, Prop } from 'vue-property-decorator';

import SeatMapConst from '@/const/seat-map.const';

@Component
export default class SeatPassanger extends Vue {
  @Prop({ default: 0 }) x!: number;
  @Prop({ default: 0 }) y!: number;
  @Prop({ default: '1' }) text!: string;

  consts: any = SeatMapConst;

  get margin() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileMargin;
    } else {
      return SeatMapConst.margin;
    }
  }

  get rowHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileRowHeight;
    } else {
      return SeatMapConst.rowHeight;
    }
  }

  get colWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileColWidth;
    } else {
      return SeatMapConst.colWidth;
    }
  }

  get extendedColWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileExtendedColWidth;
    } else {
      return SeatMapConst.extendedColWidth;
    }
  }
}
