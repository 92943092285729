export class PluginsConfigurationSetting {
  settingKey!: string;
  settingValue!: string;
}

export class PluginsConfigurationEntry {
  provider!: string;
  isEnabled!: boolean;
  hasErrors!: boolean;
  configurationSettings!: PluginsConfigurationSetting[];
}

export class PluginsConfiguration {
  configurationId?: string = '';
  name?: string = '';
  rootCompanyId?: string = '';
  providerConfigurations: PluginsConfigurationEntry[] = [];

  constructor(data?: any) {
    if (!data) {
      return;
    } else {
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.providerConfigurations !== undefined) {
        this.providerConfigurations = data.providerConfigurations;
      }
    }
  }
}