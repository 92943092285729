









































import { Vue, Component, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';

@Component({})
export default class RangeFilter extends Vue {
  @Prop() code!: string;
  @Prop({ default: 5 }) sliderStep!: number;
  @Prop() label!: string;
  @Prop({ default: 0 }) initMinLimit!: number;
  @Prop() initMaxLimit!: number;
  @Prop({ default: 0 }) initMin!: number;
  @Prop() initMax!: number;
  @Prop() unit!: string;
  @Prop({ default: true }) unitAsPrefix!: boolean;

  minValue = Math.floor(this.initMin);
  maxValue = Math.ceil(this.initMax);
  minLimit = Math.floor(this.initMinLimit);
  maxLimit = Math.ceil(this.initMaxLimit);
  minInputCursorPosition = 1;
  maxInputCursorPosition = 1;
  timeout: number | null = null;
  visible = true;
  lastMinValue: number = this.minValue;
  lastMaxValue: number = this.maxValue;
  lastCode: string = this.code;

  get sliderValue() {
    return [this.minValue, this.maxValue];
  }

  set sliderValue(value) {
    this.minValue = value[0];
    this.maxValue = value[1];

    this.emitChange();
  }

  get minInput() {
    return this.formatValue(this.minValue);
  }

  set minInput(value) {
    this.unformatValue(
      value,
      this.minLimit,
      Math.min(this.maxValue, this.maxLimit),
      false,
      v => this.minValue = v,
      () => {
        const input = this.$refs.minInput as HTMLInputElement;
        input.selectionEnd = this.minInputCursorPosition;
      }
    );
  }

  get maxInput() {
    return this.formatValue(this.maxValue);
  }

  set maxInput(value) {
    this.unformatValue(
      value,
      Math.max(this.minLimit, this.minValue),
      this.maxLimit,
      false,
      v => this.maxValue = v,
      () => {
        const input = this.$refs.maxInput as HTMLInputElement;
        input.selectionEnd = this.maxInputCursorPosition;
      }
    );
  }

  emitChange() {
    if (
      this.minValue === this.lastMinValue &&
      this.maxValue === this.lastMaxValue &&
      this.lastCode === this.code
    ) {
      return;
    }

    this.lastMinValue = this.minValue;
    this.lastMaxValue = this.maxValue;
    this.lastCode = this.code;

    this.$emit('change', {
      code: this.code,
      data: {
        min: this.minValue,
        max: this.maxValue
      }
    });
  }

  regExEscape(str) {
    if (str !== null) {
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    } else {
      return null;
    }
  }

  handleMinInput(e) {
    this.minInputCursorPosition = e.target.selectionStart;
  }

  handleMaxInput(e) {
    this.maxInputCursorPosition = e.target.selectionStart;
  }

  touchMinValue(e) {
    const value = e.target.value;
    this.unformatValue(
      value,
      this.minLimit,
      Math.min(this.maxValue, this.maxLimit),
      true,
      v => {
        this.minValue = v;
        e.target.value = this.formatValue(v);
      },
      () => {
        const input = this.$refs.minInput as HTMLInputElement;
        input.selectionEnd = this.minInputCursorPosition;
      }
    );
  }

  touchMaxValue(e) {
    const value = e.target.value;
    this.unformatValue(
      value,
      Math.max(this.minLimit, this.minValue),
      this.maxLimit,
      true,
      v => {
        this.maxValue = v;
        e.target.value = this.formatValue(v);
      },
      () => {
        const input = this.$refs.maxInput as HTMLInputElement;
        input.selectionEnd = this.maxInputCursorPosition;
      }
    );
  }

  formatValue(value) {
    if (this.unit !== undefined && this.unit !== null) {
      if (this.unitAsPrefix === true) {
        return this.unit + ' ' + value;
      } else {
        return value + ' ' + this.unit;
      }
    } else {
      return this.unit;
    }
  }

  unformatValue(input, min, max, fixMinMax, setter, cursorPositionSetter) {
    let regexPattern: string | null = null;
      if (this.unit !== undefined && this.unit !== null) {
        if (this.unitAsPrefix === true) {
          regexPattern = `^(?:${this.regExEscape(this.unit)})?\\s*((?:[-+]?)\\d+(?:(?:\.|,)\\d{1,2})?)$`;
        } else {
          regexPattern = `^((?:[-+]?)\\d+(?:(?:\\.|,)\d{1,2})?)(?:\\s*(?:${this.regExEscape(this.unit)}))?$`;
        }
      } else {
        regexPattern = '^((?:[-+]?)\\d+(?:(?:\\.|,)\\d{1,2})?)$';
      }

      const regex = new RegExp(regexPattern);
      
      const parseResult = regex.exec(input);
      if (parseResult !== null) {
        const rawValue = parseResult[1].replace(',', '.');
        let value = parseFloat(rawValue);

        if (fixMinMax === true) {
          if (value < min) {
            value = min;
          } else {
            if (value > max) {
              value = max;
            }
          }
        }

        if (value >= min && value <= max) {
          setter(value);
          this.emitChange();
        }
      }

      if (this.unitAsPrefix === undefined || this.unitAsPrefix === false) {
        this.$nextTick(cursorPositionSetter);
      }      
  }

  refresh(data) {
    if (data) {
      this.minLimit = Math.floor(data.minLimit);
      this.maxLimit = Math.ceil(data.maxLimit);
      this.minValue = Math.floor(data.minValue);
      this.maxValue = Math.ceil(data.maxValue);
    }
  }

  clearFilter() {
    this.minValue = Math.min(this.minLimit, this.initMinLimit);
    this.maxValue = Math.max(this.maxLimit, this.initMaxLimit);
    this.emitChange();
  }

  refreshWhenNeeded(params) {
    if (this.code !== params.code) {
      return;
    }

    this.refresh(params.data);
  }



  created() {
    EventBus.$on('clear-filters', this.clearFilter);
    EventBus.$on('refresh-filter-component', this.refreshWhenNeeded);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearFilter);
    EventBus.$off('refresh-filter-component', this.refreshWhenNeeded);
  }

}
