


































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import tinycolor from 'tinycolor2';

@Component
export default class UiColorPicker extends Vue {
  @Prop() value!: string;
  @Prop({ default: false }) disabled!: boolean;

  colorFocused: boolean = false;
  saturationFocused: boolean = false;
  hueFocused: boolean = false;
  shouldHide: boolean = false;
  lastH: number = 1;
  rect = {
    width: 100,
    height: 50,
  };
  hueDragRef = 0;
  saturationDragRefX = 0;
  saturationDragRefY = 0;

  get color() {
    return tinycolor(this.value);
  }

  set color(val) {
    this.emitInput(val.toHexString());
  }

  get hsvColor() {
    return this.color.toHsv();
  }

  get colorStyles() {
    return {
      backgroundColor: this.value,
    };
  }

  get saturationStyles() {
    return {
      backgroundColor: tinycolor({
        h: this.lastH || this.hsvColor.h,
        s: 1,
        v: 1,
        a: 1,
      }).toHexString(),
    };
  }

  get saturationPointerStyles() {
    return {
      left: this.hsvColor.s * this.rect.width + 'px',
      top: (100 - this.hsvColor.v * 100) * this.rect.height / 100 + 'px',
      borderColor: this.hsvColor.v * 100 < 50 ? '#fff' : '#000',
    };
  }

  get huePointerStyles() {
    return {
      left: this.rect.width * this.lastH / 360 + 'px',
    };
  }

  get focused() {
    return this.colorFocused || this.saturationFocused || this.hueFocused;
  }

  get dropdownStyles() {
    return {
      display: this.focused ? 'block' : 'none',
    };
  }

  updateHex($event) {
    const value = $event.target.value;
    const regex = /#[0-9A-Fa-f]{6}/g;
    const tested = regex.test(value);
    if (tested) {
      this.color = tinycolor(value);
    }
  }

  onColorDown() {
    if (this.colorFocused) {
      this.shouldHide = true;
      
    }
  }

  onColorClick() {
    if (this.shouldHide) {
      this.shouldHide = false;
      this.$nextTick(() => {
        (this.$refs.input as HTMLInputElement).focus();
      });
    }
  }

  onColorFocus() {
    if (this.disabled) {
      return;
    }
    this.colorFocused = true;
    this.$nextTick(() => {
      this.setRect();
    });
  }

  onColorBlur() {
    this.colorFocused = false;
  }

  saturationMouseDown($event) {
    if (1 !== $event.buttons) {
      return;
    }
    this.saturationFocused = true;
    this.saturationDragRefX = $event.screenX - $event.offsetX;
    this.saturationDragRefY = $event.screenY - $event.offsetY;
    this.saturationMouseMove($event);
  }

  saturationMouseMove($event) {
    if (!this.saturationFocused) {
      return;
    }
    $event.preventDefault();
    const news = Math.max(0, Math.min(1,
      ($event.screenX  - this.saturationDragRefX) / this.rect.width
    ));
    const newv = Math.max(0, Math.min(1,
      1 - ($event.screenY - this.saturationDragRefY) / this.rect.height
    ));
    const newColor = tinycolor({
      h: this.lastH,
      s: news,
      v: newv,
      a: this.hsvColor.a,
    }).toHexString();
    this.emitInput(newColor);
  }

  mouseUp($event) {
    if (this.hueFocused) {
      this.hueMouseUp($event);
    } else if (this.saturationFocused) {
      this.saturationMouseUp($event);
    }
  }

  mouseMove($event) {
    if (this.hueFocused) {
      this.hueMouseMove($event);
    } else if (this.saturationFocused) {
      this.saturationMouseMove($event);
    }
  }

  saturationMouseUp($event) {
    (this.$refs.colorIndicator as HTMLElement).focus();
    this.saturationFocused = false;
  }

  hueMouseDown($event) {
    if (1 !== $event.buttons) {
      return;
    }
    this.hueFocused = true;
    this.hueDragRef = $event.screenX - $event.offsetX;
    this.hueMouseMove($event);
  }

  hueMouseMove($event) {
    if (!this.hueFocused) {
      return;
    }
    $event.preventDefault();
    const newh = Math.max(0, Math.min(360, 
      ($event.screenX - this.hueDragRef) * 360 / this.rect.width
    ));
    this.lastH = newh;
    const newColor = tinycolor({
      h: newh,
      s: this.hsvColor.s,
      v: this.hsvColor.v,
      a: this.hsvColor.a,
    }).toHexString();
    this.emitInput(newColor);
  }

  hueMouseUp($event) {
    (this.$refs.colorIndicator as HTMLElement).focus();
    this.hueFocused = false;
  }

  setRect() {
    const r = (this.$refs.containerSaturation as HTMLElement).getBoundingClientRect();
    this.rect = {
      width: r.width,
      height: r.height,
    };
  }

  @Emit('input')
  emitInput(value) {
    return value;
  }

  @Watch('value', { immediate: true })
  onValueChange(value) {
    if (!this.hueFocused) {
      this.lastH = tinycolor(value).toHsv().h;
    }
  }

  mounted() {
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('mousemove', this.mouseMove);
    this.setRect();
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);
  }
}

