import CurrencyExchange from '@/modules/settings/currency-exchange/CurrencyExchange.vue';
import CurrencyExchangeList from './currency-exchange-configurations.route';
import CurrencyExchangeConfig from './currency-exchange-configuration.route';

export default {
  path: 'currency-exchange-configurations',
  component: CurrencyExchange,
  displayName: 'settings-sidebar.currency-exchange',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditCurrencyConfiguration',
    icon: 'attach_money',
    breadCrumb: 'Currency exchange configuration',
    parentName: 'currency-exchange-configurations',
  },
  children: [
    CurrencyExchangeList,
    CurrencyExchangeConfig,
  ],
};