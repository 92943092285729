import CompanyInfo from '@/modules/settings/company-info/CompanyInfo.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '',
  name: 'company-info',
  component: CompanyInfo,
  displayName: 'Company info',
  meta: {
    permission: 'AccessSettings',
    introClass: 'intro-settings',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  }
};
