




















import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import accountStore from '@/store/account.store';
import layoutStore from './layout.store';
import routes from '@/router/routes';

import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';

@Component({
  components: {
  }
})
export default class SubMenuTransition extends Vue {
  transitionViewName: string = 'submenu';

  get animating(): boolean {
    return -1 < [
      SlotState.SHOWING,
      SlotState.HIDING,
      SlotState.SWAPPING,
    ].indexOf(layoutStore.slots.submenu.state);
  }

  get transition() {
    return layoutStore.slots.submenu.transition;
  }

  get height(): number {
    return layoutStore.slots.submenu.meta.height;
  }

  get classes() {
    return {
      'active': this.animating,
      'toggled': !layoutStore.slots.submenu.meta.empty,
    };
  }

  get styles() {
    if (!this.animating) {
      return {};
    }
    return {
      height: this.height + 'px',
    };
  }

  async mounted() {
    layoutStore.setWrapper({
      slot: 'submenu',
      wrapper: this.$refs.wrapper as HTMLElement,
    });
    this.updateDimensions();
  }

  entered() {
    layoutStore.animationFinished({
      slot: 'submenu'
    });
  }

  entering() {
    this.updateDimensions();
  }

  updateDimensions() {
    layoutStore.updateDimensions({
      slot: 'submenu',
    });
  }

}
