import ProvidersNotificationConfiguration from '@/modules/settings/providers-notifications/ProvidersNotificationsConfiguration.vue';

export default {
  path: ':configurationId',
  name: 'edit-providers-notification',
  component: ProvidersNotificationConfiguration,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProvidersNotificationConfigurations',
    depthCategory: 'providers-notifications',
    depth: 2,
    parentName: 'providers-notifications',
  },
};