



















































import settings from '@/settings';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';
import accountStore from '@/store/account.store';
import { ExpenseApi } from '@/api/expense/expense.api';
import { translate } from '@/i18n';
import { router } from '@/router';
import VueContentLoading from 'vue-content-loading';
import ExpenseStore from '../expense.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    VueContentLoading,
  }
})
export default class ExpenseImagePopup extends Vue {
  @Prop({ default: false }) showPopup: boolean = false;
  url: string = '';
  image?: any = null;
  errors: any[] = [];
  base64StringSize: number = settings.expenseImage.base64SizeLimitInB;
  formPending: boolean = false;
  fileName: string = '';
  fileType: string = '';
  imageHeight: any = settings.expenseImage.height;
  imageWidth: any = settings.expenseImage.width;
  returnFormat: string = settings.expenseImage.returnFormat;
  expenseId: string | null = null;
  loading: boolean = false;

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get companyId() {
    return ExpenseStore.companyId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  selectFile() {
    let input = this.$refs.imageUploadInput as HTMLElement;
    input.click();
  }

  onFileChange(e) { 
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) {
      return;
    }
    this.image = null;
    this.errors = [];
    this.fileName = '';
    this.fileType = '';
    this.createImage(files[0]);
  }

  createImage(file) {
    let fileType = file;
    this.fileType = fileType.type;
    if (this.fileType === 'application/pdf' || this.fileType === 'image/png' || this.fileType === 'image/jpg' || this.fileType === 'image/jpeg') {
      let image = new Image();
      let reader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        if (e.target) {
          let fileReader = e.target as FileReader;
          this.image = fileReader.result;
          image.onload = () => {
            this.imageHeight = image.height;
            this.imageWidth = image.width;
          };
          image.src = this.image;
        }
        if (this.fileType === 'application/pdf') {
          this.fileName = fileType.name;
        }
      };
      reader.readAsDataURL(file);
    } else {
      this.errors.push({ message: translate('expense-list.error-file') });
      this.formPending = false;
    }
    
  }

  async upload() {
    if (this.formPending) {
      return;
    }

    if (this.fileType !== 'application/pdf' && this.fileType !== 'image/png' &&  this.fileType !== 'image/jpg' && this.fileType !== 'image/jpeg') {
      return;
    }

    this.expenseId = this.$route.params.expenseId;
    this.formPending = true;
    this.loading = true;
    this.errors = [];
    if (!this.image) {
      this.errors.push({ message: translate('expense-list.no-file') });
      this.formPending = false;
      return;
    }
    if (this.image) {
      try {
        const receiptObject = this.prepareExpenseImageRequest(this.image);

        if (receiptObject) {
          let imageResponse;
          let expectedExpenseId;
          if (this.expenseId) {
            imageResponse = await ExpenseApi.updateExpenseFromReceipt(this.expenseId, receiptObject);
            expectedExpenseId = this.expenseId;
            EventBus.$emit('refresh-expense');
          } else {
            imageResponse = await ExpenseApi.addExpenseFromReceipt(receiptObject); 
            expectedExpenseId =  imageResponse.data.expenseId;
          }
          if (imageResponse.status === 201 || imageResponse.status === 204) {
            router.push({
              name: 'modify-expense',
              params: {
                expenseId: expectedExpenseId,
              },
            });

          }
          this.closeExpenseImagePopup();
        } else {
          this.errors.push({ message: translate('expense-list.file-to-large') });
        }
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.formPending = false;
        this.loading = false;
      }
    }
  }

  closeExpenseImagePopup() {
    this.image = null;
    this.fileName = '';
    this.fileType = '';
    this.errors = [];
    ExpenseStore.setShowExpenseImagePopup(false);
    this.close();
  }

  @Emit()
  close() {
  }

  getBase64StringSize(base64string: string) {
    let padding: number, sizeInBytes: number;
    if (base64string.endsWith('==')) {
      padding = 2;
    } else if (base64string.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    sizeInBytes = ( 3 * base64string.length / 4 ) - padding;
    return sizeInBytes;
  }

  prepareExpenseImageRequest(expenseItemImage: string) {
    if (this.fileType === 'image/jpg' || this.fileType === 'image/jpeg') {
      this.returnFormat = 'jpg';
    } else if (this.fileType === 'application/pdf') {
      this.returnFormat = 'pdf';
      this.imageHeight = null;
      this.imageWidth = null;
    } else {
      this.returnFormat = settings.expenseImage.returnFormat;
    }
    let imageSplitted = expenseItemImage.split(',');
    let base64StringSize: number = settings.expenseImage.base64SizeLimitInB;
    if (imageSplitted[1] ) {
      base64StringSize = this.getBase64StringSize(imageSplitted[1]);
      if (this.expenseId) {
        return ( base64StringSize < settings.expenseImage.base64SizeLimitInB ) ?
          {
            receipt: {
              size: {
                type: 'Large',
                length: base64StringSize,
                height: this.imageHeight,
                width: this.imageWidth,
              },
              format: this.returnFormat,
              file: imageSplitted[1],
            }
          }
        : false;
      } else {
        return ( base64StringSize < settings.expenseImage.base64SizeLimitInB ) ?
          {
            receipt: {
              size: {
                type: 'Large',
                length: base64StringSize,
                height: this.imageHeight,
                width: this.imageWidth,
              },
              format: this.returnFormat,
              file: imageSplitted[1],
            },
            reporterId: this.currentProfileId,
            companyId: this.companyId,
            policyId: this.policyId
          }
        : false;
      }
    } else {
      return false;
    }
  }
}
