import AccountStore from '@/store/account.store';
import SettingsStore from '@/modules/settings/settings.store';

const omitQueryParams = (path) => {
  const questionMarkIndex = path.lastIndexOf('?');
  if (questionMarkIndex === -1) {
    return path;
  }
  return path.substr(0, questionMarkIndex);
};

export default async (to, from, next) => {
  // only applies to settings section
  if (!to.matched.length || 'settings' !== to.matched[0].name) {
    next();
    return;
  }
  
  if (1 === to.matched.length) {
    // redirect to default company id
    let companyId = '';
    if (
      SettingsStore.currentCompany == null &&
      AccountStore.CurrentUser
    ) {
      companyId = AccountStore.CurrentUser.profile.companyId;
    } else if (SettingsStore.currentCompany != null) {
      companyId = SettingsStore.currentCompany.companyId;
    }

    if (!!companyId) {
      let redirect: any = {
        path: to.fullPath + '/' + companyId,
      };

      const groups: any = SettingsStore.menuGroups;
      if (groups && groups.length) {
        const firstLink = groups[0].items[0].path;
        redirect.path += '/' + firstLink;
      }


      if (!from.matched.length) {
        redirect.replace = true;
      }
      next(redirect);

      return;
    }
  }

  if (2 <= to.matched.length) {
    // result to get company id query param

    const path = omitQueryParams(to.fullPath);
    const result = to.matched[to.matched.length - 1].regex.exec(path);

    if (result.length && result.length >= 2) {
      // when company id === default
      if (result[1] === 'default') {
        // redirect to default user profile company id
        const currentUser = AccountStore.CurrentUser;
        if (currentUser) {
          const path = to.fullPath.replace('default', currentUser.profile.companyId);

          next({
            path,
          });
          return;
        }
      // when company id === current
      } else if (result[1] === 'current') {
        // redirect to current company id (replace `current` with company id)
        let currentCompany = SettingsStore.currentCompany;

        if (currentCompany) {
          next({
            path: to.fullPath.replace('current', currentCompany.companyId),
          });

          return;
        } else {
          const currentUser = AccountStore.CurrentUser;

          if (currentUser) {
            const path = to.fullPath.replace('default', currentUser.profile.companyId);

            next({
              path,
            });
            return;
          }
        }
      } else {
        // wait till company data loads
        SettingsStore.init(result[1]);
      }
    }
  }

  next();
};
