
export class SecurityConfiguration {
  configurationId?: string = '';
  name?: string = '';
  rootCompanyId?: string = '';
  is2faRequired: boolean = false;

  constructor(data?: any) {
    if (!data) {
      return;
    } else {
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.is2faRequired !== undefined) {
        this.is2faRequired = data.is2faRequired;
      }
    }
  }
}