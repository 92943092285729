







































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { router } from '@/router';
import MissionPreapproval from './MissionPreapproval.vue';
import ReportApproval from './ReportApproval.vue';
import AuditRules from './AuditRules.vue';

const positiveNumber = (value) => {
  if (value && value > 0 && !isNaN(value) && /^-?\d+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

@Component({
  components: {
    MissionPreapproval,
    ReportApproval,
    AuditRules,
  }
})
export default class ExpenseWorkflowSettings extends Vue {
  isApproverView: boolean = false;
  $v;

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return false;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get activeTab() {
    return ExpensePolicyStore.activeWorkflowTab;
  }

  get missionData() {
    return ExpensePolicyStore.expenseWorkflowSetting;
  }

  get rejectionOffset() {
    return this.missionData.rejectionOffset ? this.missionData.rejectionOffset : null;
  }

  set rejectionOffset(value: any) {
    this.missionData.rejectionOffset = value;
  }

  @Validation()
  validationObject() {
    return {
      rejectionOffset: {
        required,
        positiveNumber,
      }
    };
  }

  setMissionPreapprovalTab() {
    ExpensePolicyStore.setMissionPreapprovalTab();
  }

  setReportApprovalTab() {
    ExpensePolicyStore.setReportApprovalTab();
  }

  setSingleExpenseValidationTab() {
    ExpensePolicyStore.setSingleExpenseValidationTab();
  }

  addAuditRule() {
    router.push({
      name: 'audit-rule',
      params: {
        id: this.$route.params.id,
        itemId: this.$route.params.itemId,
        configurationId: this.$route.params.configurationId
      }
    });
  }

  returnToConfigurations() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'expense-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveMission() {
    if (this.activeTab === 'MissionPreapproval') {
      this.$v.rejectionOffset.$touch();
      if (this.$v.rejectionOffset.$pending || this.$v.rejectionOffset.$error) {
        return;
      }
    }
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);

    const message = {
      id: this.missionData.id,
      params: this.missionData,
    };
    await ExpensePolicyStore.updateExpenseWorkflow(message);

  }

  @Watch('activeTab', { immediate: true, deep: true})
  onTapChange(value) {
    if (value === 'SingleExpenseValidation') {
      return;
    }
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.getExpenseWorkflow({policyId: this.policyId, stage: value});
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.configuration.name) {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      ExpensePolicyStore.getExpensePolicyDetails(this.policyId);
    }
  }

}
