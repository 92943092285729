














import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import BasketTooltip from '@/modules/basket/BasketTooltip.vue';

@Component({
  components: {
    BasketTooltip,
  },
})
export default class YourLatestBasket extends Vue {
  goBack() {
    router.go(-1);
  }
}
