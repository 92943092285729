import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  PerDiemSettingsResult,
  PerDiemRatesResult,
  LengthOfStayPerDiemRuleResult,
  ReductionReasonPerDiemRulesResult,
} from './expense-per-diem.model';

class ExpensePerDiemRatesApiClass {
  public getPerDiemSettings(policyId: string): Promise<AxiosResponse<PerDiemSettingsResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/per-diem-settings',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public savePerDiemSettings(policyId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expensePolicies/{policyId}/per-diem-settings',
      method: RequestMethod.PUT,
    }, { policyId }, params );
  }

  public getPerDiemRates(policyId: string): Promise<AxiosResponse<PerDiemRatesResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/per-diem-rates?policyId={policyId}',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public getPerDiemRate(perDiemRateId: string): Promise<AxiosResponse<PerDiemRatesResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/per-diem-rates/{perDiemRateId}',
      method: RequestMethod.GET,
    }, { perDiemRateId }, {});
  }

  public createPerDiemRate(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/per-diem-rates',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updatePerDiemRate(perDiemRateId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/per-diem-rates/{perDiemRateId}',
      method: RequestMethod.PUT,
    }, { perDiemRateId }, params );
  }

  public removePerDiemRate(perDiemRateId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/per-diem-rates/{perDiemRateId}',
      method: RequestMethod.DELETE,
    }, { perDiemRateId }, {} );
  }

  public getLengthOfStayPerDiemRules(policyId: string): Promise<AxiosResponse<LengthOfStayPerDiemRuleResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/length-of-stay-per-diem-rules?policyId={policyId}',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public getLengthOfStayPerDiemRule(perDiemRateId: string): Promise<AxiosResponse<LengthOfStayPerDiemRuleResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/length-of-stay-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.GET,
    }, { perDiemRateId }, {});
  }

  public createLengthOfStayPerDiemRule(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/length-of-stay-per-diem-rules',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateLengthOfStayPerDiemRule(perDiemRateId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/length-of-stay-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.PUT,
    }, { perDiemRateId }, params );
  }

  public removeLengthOfStayPerDiemRule(perDiemRateId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/length-of-stay-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.DELETE,
    }, { perDiemRateId }, {} );
  }

  public getReductionReasonPerDiemRules(policyId: string): Promise<AxiosResponse<ReductionReasonPerDiemRulesResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/reduction-reason-per-diem-rules?policyId={policyId}',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public getReductionReasonPerDiemRule(perDiemRateId: string): Promise<AxiosResponse<ReductionReasonPerDiemRulesResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/reduction-reason-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.GET,
    }, { perDiemRateId }, {});
  }

  public createReductionReasonPerDiemRule(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/reduction-reason-per-diem-rules',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateReductionReasonPerDiemRule(perDiemRateId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/reduction-reason-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.PUT,
    }, { perDiemRateId }, params );
  }

  public removeReductionReasonPerDiemRule(perDiemRateId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/reduction-reason-per-diem-rules/{perDiemRateId}',
      method: RequestMethod.DELETE,
    }, { perDiemRateId }, {} );
  }

}

export const ExpensePerDiemRatesApi: ExpensePerDiemRatesApiClass = new ExpensePerDiemRatesApiClass();
