import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { CreateConfiguration, PnrRemarksConfiguration, Configuration, UpdateConfigurationResult } from './pnr-remarks.model';
import { Permission } from '@/const/permission.enum';

export class PnrRemarksApiClass {
  public createAir(message: CreateConfiguration): Promise<AxiosResponse<UpdateConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/pnr-remark-configurations',
      method: RequestMethod.POST,
      permission: Permission.WritePnrRemarks,
    }, {}, message);
  }

  public createCar(message: CreateConfiguration): Promise<AxiosResponse<UpdateConfigurationResult>> {
    return http.execute({
      path: settings.apiCarEngine + '/pnr-remark-configurations',
      method: RequestMethod.POST,
      permission: Permission.WritePnrRemarks,
    }, {}, { message });
  }

  public createAccommodation(message: CreateConfiguration): Promise<AxiosResponse<UpdateConfigurationResult>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/pnr-remark-configurations',
      method: RequestMethod.POST,
      permission: Permission.WritePnrRemarks,
    }, {}, { message });
  }

  public getAir(configurationId: string): Promise<AxiosResponse<CreateConfiguration>> {
    return http.execute({
      path: settings.apiAirEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.ReadPnrRemarks,
    }, { configurationId }, {});
  }

  public getCar(configurationId: string): Promise<AxiosResponse<CreateConfiguration>> {
    return http.execute({
      path: settings.apiCarEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.ReadPnrRemarks,
    }, { configurationId }, {});
  }

  public getAccommodation(configurationId: string): Promise<AxiosResponse<CreateConfiguration>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.ReadPnrRemarks,
    }, { configurationId }, {});
  }

  public updateAir(configurationId: string, params: CreateConfiguration): Promise<AxiosResponse<Configuration[]>> {
    return http.execute({
      path: settings.apiAirEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.WritePnrRemarks,
    }, { configurationId }, params);
  }

  public updateCar(configurationId: string, params: CreateConfiguration): Promise<AxiosResponse<Configuration[]>> {
    return http.execute({
      path: settings.apiCarEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.WritePnrRemarks,
    }, { configurationId }, params);
  }

  public updateAccommodation(configurationId: string, params: CreateConfiguration): Promise<AxiosResponse<Configuration[]>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/pnr-remark-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.WritePnrRemarks,
    }, { configurationId }, params);
  }
}

export const PnrRemarksApi: PnrRemarksApiClass = new PnrRemarksApiClass();