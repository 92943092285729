import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { 
  ExpenseExtensionsModel,
  ExpenseExtensionsCreateModel,
  ExpenseExtensionsUpdateModel,
} from './expense-extensions.model';

class ExpenseExtensionsApiClass {
  public getExpenseExtensionsList(policyId: string): Promise<AxiosResponse<ExpenseExtensionsModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions?policyId={policyId}',
      method: RequestMethod.GET
    }, { policyId }, {});
  }

  public addExpenseExtensions(params: ExpenseExtensionsCreateModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateExpenseExtensions(expenseExtensionId: string, params: ExpenseExtensionsUpdateModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}',
      method: RequestMethod.PUT,
    }, {expenseExtensionId}, params);
  }

  public getExpenseExtension(expenseExtensionId: string): Promise<AxiosResponse<ExpenseExtensionsModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}',
      method: RequestMethod.GET
    }, { expenseExtensionId }, {});
  }

  public removeExpenseExtension(expensePolicyId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expensePolicyId}',
      method: RequestMethod.DELETE,
    }, { expensePolicyId }, {} );
  }

}

export const ExpenseExtensionsApi: ExpenseExtensionsApiClass = new ExpenseExtensionsApiClass();
