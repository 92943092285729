import ExpenseCategoryDetails from '@/modules/settings/expense-configuration/ExpenseCategoryDetails.vue';

export default {
  path: ':configurationId?/expense-category/:itemId?/:categoryId?/:isCopy?',
  name: 'expense-category',
  component: ExpenseCategoryDetails,
  displayName: 'Expense category',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadExpenseCategories',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};