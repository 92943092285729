import axios from 'axios';

import responseInterceptor from '@/services/http/response.interceptor';
import responseErrorInterceptor from '@/services/http/response-error.interceptor';

export default () => {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

};
