import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { ContactsModel } from './contacts.model';

class ContactsApiClass {

  public updateContacts(profileId, params: ContactsModel) {
    return http.execute({
      path: settings.apiProfile + '/api/v3/profiles/{profileId}/contacts',
      method: RequestMethod.PUT,
    }, { profileId }, params);
  }

  public getContacts(profileId) {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/contacts',
      method: RequestMethod.GET
    }, { profileId }, {});
  }
}

export const ContactsApi: ContactsApiClass = new ContactsApiClass();
