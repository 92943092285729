




















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';
import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import hasAccessFn from '@/core/permissions/has-access.service';

@Component({})
export default class IntroTransition extends Vue {
  transitionViewName: string = 'intro';
  oldIntroClass: string = '';
  oldServiceClass: string = '';
  serviceChanging: boolean = false;
  serviceChangingTimeoutID: number = -1;
  setOldServiceTimeoutID: number = -1;

  get animating(): boolean {
    return layoutStore.slots.intro.transitioning;
  }

  get transition() {
    return layoutStore.slots.intro.transition;
  }

  get height(): number {
    return layoutStore.slots.intro.meta.height;
  }

  get introClass() {
    return this.$route.meta.introClass;
  }

  get classes() {
    return {
      'active': this.animating,
      'toggled': !layoutStore.slots.intro.meta.empty,
    };
  }

  get serviceClass() {
    if (this.$route.meta.introClass === 'intro-search') {
      return 'intro-' + SearchStore.serviceNavigating + '-search';
    } else if (this.$route.meta.introClass === 'intro-choose-travellers' && !hasAccessFn('SelectTravellersForSearch') && hasAccessFn('SearchOffers')) {
      return 'intro-' + SearchStore.serviceNavigating + '-search';
    }
    return '';
  }

  get styles() {
    if (!this.animating) {
      return {};
    }
    return {
      height: this.height + 'px',
    };
  }

  get introStyles() {
    if (!this.animating && !this.serviceChanging) {
      return {};
    }
    return {
      animation: 'fadein 0.7s linear',
    };
  }

  get oldIntroStyles() {
    if (!this.animating && !this.serviceChanging) {
      return {};
    }
    return {
      animation: 'fadein 0.5s linear reverse',
    };
  }

  async mounted() {
    layoutStore.setWrapper({
      slot: 'intro',
      wrapper: this.$refs.wrapper as HTMLElement,
    });
    this.updateDimensions();
  }

  entered() {
    layoutStore.animationFinished({
      slot: 'intro'
    });
  }

  entering() {
    this.updateDimensions();
  }

  updateDimensions() {
    layoutStore.updateDimensions({
      slot: 'intro',
    });
  }

  setOldBg(val) {
    setTimeout(() => {
      this.oldIntroClass = val;
    }, 1000);
  }

  setOldServiceClass(val) {
    clearTimeout(this.setOldServiceTimeoutID);
    this.setOldServiceTimeoutID = setTimeout(() => {
      this.oldServiceClass = val;
    }, 1000);
  }

  @Watch('$route.meta.introClass', {deep: true})
  onChangeIntroClass(val, oldVal) {
    this.oldIntroClass = oldVal;
    this.setOldBg(val);
  }

  @Watch('serviceClass')
  async onChangeServiceClass(val, oldVal) {
    if (oldVal !== val) {
      clearTimeout(this.serviceChangingTimeoutID);

      this.oldServiceClass = oldVal;
      this.setOldServiceClass(val);

      this.serviceChanging = true;
      this.serviceChangingTimeoutID = setTimeout(() => {
        this.serviceChanging = false;        
      }, 1500);
    }
  }

}

