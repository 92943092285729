










import { Vue, Component, Watch } from 'vue-property-decorator';
import settings from '@/settings';

@Component({})
export default class MyPools extends Vue {
  get canShowExternalSalesPictures() {
    return 'true' === settings.enableExternalSalesPictures;
  }
}

