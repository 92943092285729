













































































































import { Vue, Component } from 'vue-property-decorator';
import ExpensePolicyStore from './expense-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import ExpenseDefinitions from './ExpensePolicyDefinitions.vue';
import { Permission } from '@/const/permission.enum';

@Component({
  components: {
    ExpenseDefinitions,
  }
})
export default class ExpensePolicyConfiguration extends Vue {
  $v;
  formHasErrors: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  configuration = {
    name: null
  };

  @Validation()
  validationObject() {
    return {
      configuration: {
        name: {
          required,
          maxLength: maxLength(64)
        }
      }
    };
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get expenseConfiguration() {
    return ExpensePolicyStore.configuration;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configIsEdit() {
    return this.expenseConfiguration.id;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.expenseConfiguration.id ? this.expenseConfiguration.id : undefined;
    }
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get canShowCustomError() {
    return ExpensePolicyStore.canShowCustomError;
  }

  returnToConfigurations() {
    router.push({
      name: 'expense-configurations'
    });
  }

  addExpenseDefinition() {
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      return;
    }
    if (this.configuration.name) {
      ExpensePolicyStore.setName(this.configuration.name);
    }
    router.push({
      name: 'expense-definition',
      params: {
        configurationId: this.$route.params.configurationId
      }
    });
  }

  saveData() {
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      return;
    }
    if (this.configIsEdit) {
      ExpensePolicyStore.updateExpenseConfiguration({
        configurationId: this.expenseConfiguration.id,
        permission: Permission.WriteExpensePolicyDefinition,
        name: this.configuration.name
      });
    } else {
      ExpensePolicyStore.createExpenseConfiguration({
        permission: Permission.WriteExpensePolicyDefinition,
        rootCompanyId: this.currentCompany!.rootCompanyId,
        name: this.configuration.name,
      });
    }
  }

  async created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.configuration.name) {
        try {
          await ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
          if  (this.expenseConfiguration && this.expenseConfiguration.name) {
            this.configuration.name = this.expenseConfiguration.name;
          }
        } catch (error) {
          ExpensePolicyStore.setErrMessages(this.$handleErrors(error, true));
          ExpensePolicyStore.setShowError(true);
        }
      }
      await ExpensePolicyStore.getExpensePoliciesConfiguration(this.$route.params.configurationId);
    }
  }
}
