export const DocumentTypeList = [
  {
    value: 'Visa',
    name: 'Visa',
  },
  {
    value: 'Eta',
    name: 'ETA',
  },
  {
    value: 'Esta',
    name: 'ESTA',
  },
  {
    value: 'EVisitor',
    name: 'eVisitor',
  },
  {
    value: 'Passport',
    name: 'Passport',
  },
  {
    value: 'KnownTraveller',
    name: 'Known Traveller Number',
  },
  {
    value: 'NationalId',
    name: 'National ID',
  },
  {
    value: 'Redress',
    name: 'Redress Number',
  },
  {
    value: 'DrivingLicense',
    name: 'Driving license',
  },
  {
    value: 'GreenCard',
    name: 'Green card',
  },
];
