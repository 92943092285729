export interface ApprovalWorkflowConfigurationModel {
  configurationId?: string;
  rootCompanyId?: string;
  name: string;
  configurationLevels: ApprovalWorkflowConfigurationLevel[];
  handlingNonRefundableOffers: ApprovalWithNonRefundableOffers;
  isApprovalEnabledInPostSales: boolean;
}

export interface AllowedApprover {
  profileId?: string;
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  approvalLevel?: number;
  selectedApprover?: any;
}

export interface Types {
  value: string;
  name?: string;
}

export enum ApprovalWithNonRefundableOffers {
  BlockApprovalWorkflow = 'BlockApprovalWorkflow',
  AutoApprove = 'AutoApprove',
}

export enum ApproverSourceType {
  AllApproversFromRootCompany = 'AllApproversFromRootCompany',
  ApproversFromList = 'ApproversFromList',
  TravellerDesignatedApprover = 'TravellerDesignatedApprover',
}

export enum ApproverSelectionType {
  BookerSelectsApprover = 'BookerSelectsApprover',
  AnyAllowedApprover = 'AnyAllowedApprover',
}

export enum TravelPolicyResultType {
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant',
  Empty = 'Empty',
}

export enum ApprovalWorkflowServiceType {
  Flight = 'Flight',
  Rail = 'Rail',
  Accommodation = 'Accommodation',
  Car = 'Car',
}

export enum RejectionReasonType {
  NotRequired = 'NotRequired',
  FreeText = 'FreeText',
  Dictionary = 'Dictionary',
}

export class ApprovalWorkflowConfigurationLevel {
  allowedApprovers: AllowedApprover[] = [];
  approverSourceType: ApproverSourceType = ApproverSourceType.AllApproversFromRootCompany;
  approverSelectionType: ApproverSelectionType = ApproverSelectionType.BookerSelectsApprover;
  isTravelPolicyDependant: boolean = false;
  travelPolicyResultType: TravelPolicyResultType | null = null;
  isServiceTypeDependant: boolean = false;
  serviceTypes: ApprovalWorkflowServiceType[] = [];
  isCutoffDateEnabled: boolean = false;
  howManyDaysToCutoffDate: number = 1;
  approvalLevel: number = 1;
  travellerCategories: string[] = [];
  isTravellerCategoriesEnabled: boolean = false;
  isRejectionReasonRequired: boolean = false;
  rejectionReasonType: RejectionReasonType = RejectionReasonType.NotRequired;
  rejectionReasonValues: string[] = [];
}

export class ApprovalWorkflowConfiguration {
  configurationId: string = '';
  rootCompanyId: string = '';
  name: string = '';
  configurationLevels: ApprovalWorkflowConfigurationLevel[] = [
    new ApprovalWorkflowConfigurationLevel(),
  ];
  handlingNonRefundableOffers: ApprovalWithNonRefundableOffers = ApprovalWithNonRefundableOffers.BlockApprovalWorkflow;
  isApprovalEnabledInPostSales: boolean = false;
}

export interface DesignatedTravellersResponse {
  accessLevel: DesignatedAccessType;
  assignedTravellers: DesignatedTravellersItem[];
}

export interface DesignatedApproversResponse {
  accessLevel: DesignatedAccessType;
  assignedApprovers: DesignatedApproversItem[];
}

export interface DesignatedApproversItem {
  profileId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  businessUnitName: string;
}

export interface DesignatedTravellersItem {
  profileId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  businessUnitName: string;
}

export enum DesignatedAccessType {
  NoAccess = 'NoAccess',
  CanView = 'CanView',
  CanEdit = 'CanEdit',
}
