












































































import { Vue, Component } from 'vue-property-decorator';
import { Validation} from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';

import { router } from '@/router';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import SettingsStore from '@/modules/settings/settings.store';
import { CreateCompanyMessage } from '@/api/profile/company.model';
import { isCompanyCode } from '@/core/validation/is-company-code.validator';
import EventHandler from '@/services/event-handler';

@Component({})
export default class CreateCompany extends Vue {
  Form = new CreateCompanyMessage();
  serverErrors: any = [];
  saving: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        code: {
          required,
          isCompanyCode,
          maxLength: maxLength(32),
        },
      },
    };
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    this.saving = true;

    try {
      const response = await ProfileCompanyApi.createCompany(this.Form);
      // todo: redirect to the newly created company
      router.push({
        path: '/settings/' + response.data.id,
      });

      this.whenSaved();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  goBack() {
    router.go(-1);
  }

  whenSaved() {
    EventHandler.$emit('show-toast', {
      type: 'success',
      title: 'Saved',
      message: 'Data saved'
    });
  }
}

