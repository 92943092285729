



























import { Vue, Component, Watch } from 'vue-property-decorator';
import ExpenseTable from './ExpenseTable.vue';
import ExpenseImagePopup from './ExpenseImagePopup.vue';
import EventHandler from '@/services/event-handler';
import { router } from '@/router';
import ExpenseStore from '../expense.store';

@Component({
  components: {
    ExpenseTable,
    ExpenseImagePopup,
  },
})
export default class ExpenseList extends Vue {
  missionId: string | null = null;

  get showExpenseImagePopup() {
    return ExpenseStore.showExpenseImagePopup;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  addExpense() {
    this.missionId = this.$route.params.missionId;
    router.push({
      name: 'add-expense',
      params: {
        missionId: this.missionId,
      },
    });
  }

  showExpensePopup() {
    ExpenseStore.setShowExpenseImagePopup(true);
  }

  async initView() {
    this.missionId = this.$route.params.missionId;
  }

  @Watch('$route.params.missionId', { immediate: true })
  routeHandler() {
    this.initView();
  }
}

