import settings from '@/settings';
import SearchStore from '@/modules/search/search.store';
import { TravellersStateParams } from '@/modules/search/travellers.params';

export default async (to, from, next) => {
  if (to.name === 'home') {
    const currentTravellers = SearchStore.travellersSearch.travellers;

    if (currentTravellers.find(traveller => !!(traveller as any).isVirtual)) {
      SearchStore.updateTravellersDefaultState(new TravellersStateParams({ travellers: [] }));
    }
    if (settings.enableExternalSkipTravellers === 'true') {
      SearchStore.updateSkipTravellers(false);
    }
  }
  next();
};
