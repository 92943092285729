import LodgeCardsConfiguration from '@/modules/settings/lodge-cards/LodgeCardsConfiguration.vue';

export default {
  path: ':configurationId?/configuration',
  name: 'lodge-card-configuration',
  component: LodgeCardsConfiguration,
  displayName: 'Lodge Card Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyPaymentCards',
    parentName: 'lodge-cards-configurations',
    depthCategory: 'lodge-cards-configurations',
    depth: 2,
  }
};