import { TravellerModel } from '@/api/home/home.model';

export class TravellersStateParams {
  travellers: TravellerModel[] = [];

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.travellers !== undefined) {
      this.travellers = params.travellers;
    }
  }
}