import moment from 'moment';
import accountStore from '@/store/account.store';
import { AvailableDateFormats } from '@/api/profile/company.model';

export default (value) => {
  if (value) {
    if (accountStore.CurrentUser!.profile.shortDateFormat) {
      return moment(String(value)).format(accountStore.CurrentUser!.profile.shortDateFormat);
    } 
    return moment(String(value)).format(AvailableDateFormats.AvailableDateFormat1);
  }
};
