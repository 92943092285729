import ProvidersNotificationsList from '@/modules/settings/providers-notifications/ProvidersNotificationsList.vue';

export default {
  path: '',
  name: 'providers-notifications',
  component: ProvidersNotificationsList,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProvidersNotificationConfigurations',
    depthCategory: 'providers-notifications',
    depth: 1,
    parentName: 'providers-notifications',
  },
};