import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  WorkflowSettingModel,
} from './expense-workflow.model';

class ExpenseWorkflowApiClass {
  public getWorkflowSettings(policyId: string, stage: string): Promise<AxiosResponse<WorkflowSettingModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/workflowSettings?policyId={policyId}&stage={stage}',
      method: RequestMethod.GET,
    }, { policyId, stage }, {});
  }

  public createWorkflowSettings(params: WorkflowSettingModel): Promise<AxiosResponse<WorkflowSettingModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/workflowSettings',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateWorkflowSettings(id: string, params: WorkflowSettingModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/workflowSettings/{id}',
      method: RequestMethod.PUT,
    }, { id }, params);
  }
}

export const ExpenseWorkflowApi: ExpenseWorkflowApiClass = new ExpenseWorkflowApiClass();