import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CustomFieldExpenseModel,
  CustomFieldExpenseCreateModel,
  CustomFieldExpenseUpdateModel,
} from '@/api/expense/custom-fields-expense.model';

class CustomFieldsExpenseApiClass {
  public getCustomFieldsList(policyId: string): Promise<AxiosResponse<CustomFieldExpenseModel[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/custom-fields?policyId={policyId}',
      method: RequestMethod.GET
    }, { policyId }, {});
  }

  public addCustomFields(expenseExtensionId: string, params: CustomFieldExpenseCreateModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}/custom-field',
      method: RequestMethod.POST
    }, { expenseExtensionId }, params);
  }

  public updateCustomFields(expenseExtensionId: string, params: CustomFieldExpenseUpdateModel) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}/custom-field',
      method: RequestMethod.PUT,
    }, {expenseExtensionId}, params);
  }

  public getCustomField(expenseExtensionId: string): Promise<AxiosResponse<CustomFieldExpenseModel>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}/custom-field',
      method: RequestMethod.GET
    }, { expenseExtensionId }, {});
  }

  public removeCustomField(expensePolicyId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-extensions/{expenseExtensionId}/custom-field',
      method: RequestMethod.DELETE,
    }, { expensePolicyId }, {} );
  }

}

export const CustomFieldsExpenseApi: CustomFieldsExpenseApiClass = new CustomFieldsExpenseApiClass();
