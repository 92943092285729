
































































































































































































































































import {Vue, Component, Watch} from 'vue-property-decorator';
import {Validation} from 'vue-plugin-helper-decorator';
import {required, maxLength} from 'vuelidate/lib/validators';
import {router} from '@/router';
import {translate} from '@/i18n';

import SettingsStore from '@/modules/settings/settings.store';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import {CreateProviderMappingMessage, ProviderMappingProvider} from '@/api/custom-fields/custom-fields.model';
import {CustomFieldsProviderMappingsApi} from '@/api/custom-fields/custom-fields-provider-mappings.api';

import UiLabel from '@/controls/UiLabel.vue';
import UiForm from '@/controls/UiForm.vue';
import UiSectionTitle from '@/controls/UiSectionTitle.vue';
import UiSelect from '@/controls/UiSelect.vue';
import UiErrors from '@/controls/UiErrors.vue';
import UiButton from '@/controls/UiButton.vue';

@Component({
  components: {
    UiButton,
    UiErrors,
    UiSelect,
    UiSectionTitle,
    UiForm,
    UiLabel,
  }
})
export default class ProviderMapping extends Vue {
  $v;

  providers: any[] = [
    {
      value: ProviderMappingProvider.Teldar,
      label: translate('supplier.Teldar')
    },
    {
      value: ProviderMappingProvider.HCorpo,
      label: translate('supplier.HCorpo')
    },
    {
      value: ProviderMappingProvider.Amadeus,
      label: translate('supplier.Amadeus')
    },
  ];

  remarkTypesForAmadeus: any[] = [
    {
      value: 'RM',
      label: 'RM',
    },
    {
      value: 'RC',
      label: 'RC',
    },
    {
      value: 'RI',
      label: 'RI',
    },
    {
      value: 'RQ',
      label: 'RQ',
    },
  ];

  providerMapping: any = {
    id: null,
    customField: null,
    provider: null,
    statisticalField: null,
    remarkType: null,
    prefix: '',
    suffix: '',
  };

  loading: boolean = false;
  saving: boolean = false;

  errors: any[] = [];
  formHasErrors: boolean = false;

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/custom-fields/${this.configurationId !== undefined ? this.configurationId + '/field-configuration' : 'field-configuration'}`;
  }

  get travelAssignments() {
    return CustomFieldsStore.travelAssignments || [];
  }

  get customFields() {
    return CustomFieldsStore.customFields || [];
  }

  get customFieldOptions() {
    return this.customFields.filter(({ id }) => this.travelAssignments.find(({ customField }) => customField.id === id));
  }

  goToConfiguration() {
    CustomFieldsStore.setActiveTab('provider-mappings');

    let params: any = {
      id: this.$route.params.id
    };

    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'field-configuration',
      params
    });
  }

  validateForm() {
    this.formHasErrors = false;
    this.$v.providerMapping.$touch();
    this.formHasErrors = this.$v.providerMapping.$pending || this.$v.providerMapping.$error;
  }

  async saveMapping() {
    this.errors = [];
    this.validateForm();

    if (this.formHasErrors) {
      return;
    }

    this.loading = true;

    const request: CreateProviderMappingMessage = {
      provider: this.providerMapping.provider.value,
      configurationId: this.configurationId,
      statisticalFieldType: this.providerMapping.statisticalField,
      customFieldDefinitionId: this.providerMapping.customField ? this.providerMapping.customField.id : null,
      prefix: this.providerMapping.prefix,
      suffix: this.providerMapping.suffix,
      remarkType: this.providerMapping.remarkType ? this.providerMapping.remarkType.value : null,
    };

    try {
      if (this.providerMapping.id) {
        await CustomFieldsProviderMappingsApi.updateMapping(this.providerMapping.id, request);
      } else {
        await CustomFieldsProviderMappingsApi.createMapping(request);
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;

      if (!this.errors.length) {
        this.goToConfiguration();
      }
    }
  }

  created() {
    if (!CustomFieldsStore.configuration.name) {
      this.goToConfiguration();
    }
  }

  @Validation()
  validationObject() {
    let rest: any = {
      statisticalField: {
        required,
        maxLength: maxLength(100)
      },
    };
    if (this.providerMapping.provider && this.providerMapping.provider.value === 'Amadeus') {
      rest = {
        remarkType: {
          required,
        },
        prefix: {
          maxLength: maxLength(25),
        },
        suffix: {
          maxLength: maxLength(25),
        },
      };
    }
    return {
      providerMapping: {
        customField: {
          required
        },
        provider: {
          required,
        },
        ...rest,
      }
    };
  }

  @Watch('$route.params', {immediate: true, deep: true})
  async onParamsChange(params) {
    if (!params || !params.mappingId) {
      return;
    }

    this.loading = true;

    try {
      const response = await CustomFieldsProviderMappingsApi.getMapping(params.mappingId);
      const customField = this.customFieldOptions.find(x => x.id === response.data.customField.id);
      const provider = this.providers.find(x => x.value === response.data.provider);
      const remarkType = this.remarkTypesForAmadeus.find(x => x.value === response.data.remarkType);

      this.providerMapping = {
        id: response.data.id,
        customField,
        provider,
        statisticalField: response.data.statisticalFieldType,
        prefix: response.data.prefix,
        suffix: response.data.suffix,
        remarkType,
      };
    } catch (errors) {
      this.errors = this.$handleErrors(errors);
    } finally {
      this.loading = false;
    }
  }
}
