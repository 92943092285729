








































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import { CurrencyApi } from '@/api/currency/currency.api';

import CurrencyExchangeStore from './currency-exchange.store';

@Component({})
export default class CurrencyExchangeConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  Form: any = {
    name: '',
    displayCurrencyCode: '',
    settlementCurrencyCode: '',
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  currencyOptions: any[] = [];

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        displayCurrencyCode: {
           required,
        },
        settlementCurrencyCode:  {
           required,
        },
      }
    };
  }

  get currentConfiguration() {
    return CurrencyExchangeStore.CurrencyExchangeConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditCurrencyConfiguration');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return CurrencyExchangeStore.errMessages;
  }

  get showErrMessages() {
    return CurrencyExchangeStore.showError;
  }

  get loading() {
    return CurrencyExchangeStore.loading;
  }

  get errors() {
    return CurrencyExchangeStore.errMessages;
  }
  
  get showError() {
    return CurrencyExchangeStore.showError;
  }

  async getCurrencies() {
    this.resetStoreErrors();
    if (this.currencyOptions.length === 0) {
      try {
        const response = await CurrencyApi.getCurrencies();
        this.currencyOptions = response.data;
      } catch (error) {
        CurrencyExchangeStore.setErrMessages(this.$handleErrors(error, true));
        CurrencyExchangeStore.setShowError(true);
      }
    }
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'currency-exchange-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    CurrencyExchangeStore.setErrMessages([]);
    CurrencyExchangeStore.setShowError(false);
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await CurrencyExchangeStore.addCurrencyExchangeConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await CurrencyExchangeStore.updateCurrencyExchangeConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        configurationId: this.$route.params.configurationId,
        params: {
          name: this.Form.name,
          displayCurrencyCode: this.Form.displayCurrencyCode ? this.Form.displayCurrencyCode!.code : '',
          settlementCurrencyCode: this.Form.settlementCurrencyCode ? this.Form.settlementCurrencyCode!.code : '',
        }
      };
    } else {
      request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        displayCurrencyCode: this.Form.displayCurrencyCode ? this.Form.displayCurrencyCode!.code : '',
        settlementCurrencyCode: this.Form.settlementCurrencyCode ? this.Form.settlementCurrencyCode!.code : '',
      };
    }
    
    return request;
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        rootCompanyId: data.rootCompanyId,
        displayCurrencyCode: this.currencyOptions.find(opt => opt.code === data.displayCurrencyCode),
        settlementCurrencyCode: this.currencyOptions.find(opt => opt.code === data.settlementCurrencyCode),
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        rootCompanyId: this.$route.params.id,
        displayCurrencyCode: '',
        settlementCurrencyCode: '',
      };
    }
  }

  async created() {
    await this.getCurrencies();
    if (this.inEditMode) {
      await CurrencyExchangeStore.getCurrencyExchangeConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
      this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        displayCurrencyCode: this.currencyOptions.find(opt => opt.code === this.Form.displayCurrencyCode),
        settlementCurrencyCode: this.currencyOptions.find(opt => opt.code === this.Form.settlementCurrencyCode),
      };
    } else {
      CurrencyExchangeStore.clearData();
      this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        displayCurrencyCode: this.currencyOptions.find(opt => opt.code === this.Form.displayCurrencyCode),
        settlementCurrencyCode: this.currencyOptions.find(opt => opt.code === this.Form.settlementCurrencyCode),
      };
    }
  }
}
