































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import {
  required,
  requiredIf,
  maxLength
} from 'vuelidate/lib/validators';
import ProviderConfigurationDetail from './ProviderConfigurationDetail.vue';
import EventHandler from '@/services/event-handler';
import { CarProviderConfigurationSetApi } from '@/api/car-engine/car-provider-configuration.api';
import settingsStore from '../settings.store';
import { translate } from '@/i18n';
import { serviceClassEnum } from '@/api/home/home.model';

@Component({
  components: {
    ProviderConfigurationDetail
  }
})
export default class CarProviderConfigurationDetail extends Vue {
  inViewMode: boolean = true;

  Form: any = {
    name: '',
    providerConfigurations: []
  };
  imagesConst = '/assets/img/loader/1.gif';
  loading = true;
  saving = false;
  showError = false;
  errMessage = '';
  serverErrors: any = [];
  customError: boolean = false;
  $v;

  get configurationId() {
    return this.$route.params.configurationId;
  }

  get rootCompanyId() {
    return this.$route.params.id;
  }

  get inEditMode() {
    return !!this.configurationId;
  }

  get service() {
    return serviceClassEnum.Car;
  }

  isUrl(value) {
    const regex = RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig);
    return regex.test(value);
  }

  @Validation()
  validationObject() {
    let Form: any = {
      name: {
        required,
        maxLength: maxLength(128)
      },
      providerConfigurations: {
        $each: {
          configurationSettings: {
            $each: {
              settingValue: {
                requiredIf: requiredIf(function(this: any, vm) {
                  return vm.settingValue.length < 1 && this.Form.providerConfigurations[vm.parentIndex].isEnabled;
                }),
                SecretKey: (value, vm) => (vm.settingKey === 'SecretKey' ? value.length <= 128 : true),
                ApiKey: (value, vm) => (vm.settingKey === 'ApiKey' ? value.length <= 32 : true),
                ClientId: (value, vm) => (vm.settingKey === 'ClientId' ? value.length <= 256 : true),
                ClientPassword: (value, vm) => (vm.settingKey === 'ClientPassword' ? value.length <= 64 : true),
                Ipcc: (value, vm) => (vm.settingKey === 'Ipcc' ? value.length <= 16 : true),
                RestUrl: (value, vm) => (vm.settingKey === 'RestUrl' ? value.length ? this.isUrl(value) : true : true),
                SoapUrl: (value, vm) => (vm.settingKey === 'SoapUrl' ? value.length ? this.isUrl(value) : true : true)
              }
            }
          }
        }
      }
    };
    return {
      Form
    };
  }

  async mounted() {
    try {
      this.inViewMode = !this.$hasAccess('WriteCarProviders');
      this.showError = false;
      this.customError = false;
      let response: any;
      if (this.inEditMode) {
        response = await CarProviderConfigurationSetApi.getById( this.configurationId );
      } else {
        response = await CarProviderConfigurationSetApi.getEmpty();
      }
      this.Form.name = response.data.name;
      this.Form.rootCompanyId = this.rootCompanyId;
      this.Form.id = this.configurationId;

      response.data.configurations.forEach(configuration => {
        if (configuration.configurationSettings && configuration.configurationSettings.length) {
          configuration.configurationSettings.forEach(setting => {
            if (setting.settingType === 'Boolean') {
              setting.settingValue = setting.settingValue === 'true' ? true : false;
            }
          });
        }
      });

      this.Form.providerConfigurations = response.data.configurations;

      for (let i = 0; i < this.Form.providerConfigurations.length; i++) {
        for (let j = 0; j < this.Form.providerConfigurations[i].configurationSettings.length; j++ ) {
          this.Form.providerConfigurations[i].configurationSettings[j].parentIndex = i;
        }
      }
    }
    catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.customError = true;
      } else {
        this.serverErrors = this.$handleErrors(error, true);
      }
      this.showError = true;
    } finally {
      this.loading = false;
    }
  }

  async submitForm() {
    this.$v.Form.$touch();
    this.saving = true;
    this.showError = false;
    
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      this.saving = false;
      return;
    }
    try {
      this.errMessage = '';
      let response: any;

      for (let i = this.Form.providerConfigurations.length - 1; i >= 0; i--) {
        if (this.Form.providerConfigurations[i].hasErrors) {
          this.Form.providerConfigurations.splice(i, 1);
        }
      }

      this.Form.configurations = this.Form.providerConfigurations;
      delete this.Form.providerConfigurations;

      if (this.inEditMode) {
        response = await CarProviderConfigurationSetApi.update(this.Form.id, this.Form);
      } else {
        response = await CarProviderConfigurationSetApi.create(this.Form);
      }

      response.data.results.forEach(config => {
        if (config.hasErrors) {
          this.errMessage += ' ' + config.supplier + ',';
        }
      });

      if (this.errMessage === '') {
        settingsStore.resetCarProvidersErrors(response.data.id);
        this.success();
      } else {
        settingsStore.someErrorsInCarProvider(response.data.id);
        this.showError = true;
        this.serverErrors = [translate('common-error.provide-configuration')];
        this.reset();
      }

    } catch (error) {
      this.showError = true;
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  success() {
    EventHandler.$emit('show-toast', {
      type: translate('common.success'),
      title: translate('common.saved'),
      message: translate('settings-provider.provider-saved-car'),
    });
    this.$router.push({
      name: 'car-provider-configurations',
    });
  }

  reset() {
    this.$router.push({
      name: 'car-provider-configurations',
    });
  }
}
