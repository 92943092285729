










































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { TrainOfferResult, Fare, Train } from '@/api/train-engine/train-search.model';
import TrainTimelineInfoHeader from './TrainTimelineInfoHeader.vue';
import TrainProposals from './TrainProposals.vue';
import SearchStore from '@/modules/search/search.store';
import TrainSearchStore from './train-search.store';
import TrainTimelineDetails from './TrainTimelineDetails.vue';
import AccountStore from '@/store/account.store';
import EventBus from '@/services/event-handler';
import moment from 'moment';
import _ from 'lodash';
import formatPrice from '@/filters/format-price.filter';

@Component({
  components: {
    TrainTimelineInfoHeader,
    TrainProposals,
    TrainTimelineDetails,
  },
})
export default class TrainDetails extends Vue {
  @Prop() disabled!: boolean;
  @Prop() offer!: TrainOfferResult;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: false }) showDetails!: boolean;

  current: string = '';
  showStops: boolean = false;
  legNum: number =  0;
  selectedProposal: Fare | undefined | null = null;
  totalPrice: any = {
    amount: 0,
    currency: {
      code: 'EUR',
      symbol: '€'
    }
  };

  formatPrice(price) {
    return formatPrice(price);
  }

  get stops() {
    return this.offer.journeys[0].trains.reduce((stops, { type }) => (type === 'Stop' ? stops += 1 : stops), 0);
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get exchangeRequest() {
    if (!this.modifyMode) {
      return null;
    }
    return TrainSearchStore.exchangeRequest;
  }

  get lastLegNumber() {
    if (!this.modifyMode || !this.exchangeRequest) {
      return this.trainSearchParameters.searchMode === 'OneWay' ? 1 : 2;
    }

    let lastLegNumber = 0;
    this.exchangeRequest.legs.forEach(leg => {
      if (leg.isForExchange && leg.legNumber > lastLegNumber) {
        lastLegNumber = leg.legNumber;
      }
    });

    return lastLegNumber;
  }

  get isLastLegNow() {
    return this.$route.params.leg + '' === this.lastLegNumber + '';
  }

  get filteredLegs() {
    let trains = this.offer.journeys[0].trains;
    return trains.filter(train => train.type !== 'Stop');
  }

  get currentIndex() {
    if (this.filteredLegs) {
      return this.filteredLegs.findIndex(train => {
        return train.segmentKey === this.current;
      });
    } else {
      return 0;
    }
  }

  get uniqTrains() {
    if (this.uniqTrainById) {
      return this.uniqTrainById.filter(train => train.type !== 'Stop').map(train => {
        let selectedFare: any = null;
        let selectedProposal = this.offer.proposals.find(proposal => {
          if (proposal.segmentKey === train.segmentKey) {
            selectedFare = proposal.fares.find(f => f.isSelected);
            return selectedFare;
          }
        });
        return {
          ...train,
          selectedFare: selectedFare
        };
      });
    }
  }

  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get legNumber() {
    return this.$route.params.leg ? parseInt(this.$route.params.leg as string) : 1;
  }

  get uniqTrainById() {
    return _.uniqBy(this.offer.journeys[0].trains, 'segmentKey');
  }

  get isLast() {
    if (this.uniqTrains && this.uniqTrains.length > 0) {
      return this.current === this.uniqTrains[this.uniqTrains.length - 1].segmentKey;
    }
    return false;
  }

  get trainNumbers() {
    return this.uniqTrains && this.uniqTrains.length > 1;
  }

  get shouldBeLoading() {
    return this.offer.id === TrainSearchStore.selectedOfferId;
  }

  get canAddOfferToTrip() {
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get trainLegList() {
    return this.offer.journeys[0].trains.filter(train => {
      return train.type === 'Train';
    }) as Train[];
  }

  showStopsClick() {
    this.showStops = !this.showStops;
  }

  updateCurrent(key) {
    this.current = key;
  }

  calcTotalPrice() {
    this.totalPrice.amount = 0;
    if (this.trainLegList && this.trainLegList.length) {
      this.trainLegList.forEach((leg: any) => {
        if (leg.selectedProposal) {
          this.totalPrice.amount += leg.selectedProposal.price.amount;
        }
      });
    }

    if (this.selectedOffer && this.selectedOffer.length) {
      this.selectedOffer.forEach((offer: any) => {
        if (offer.recommendation.proposals.length) {
          this.totalPrice.amount += offer.recommendation.proposals[0].fares[0].price.amount;
        }
      });
    }
    this.$forceUpdate();
  }

  nextLeg(data) {
    this.legNum = data.index;
    this.current = data.segmentKey;
  }

  getProposalList(train) {
    return this.offer.proposals.find(proposal => {
      return proposal.segmentKey === train.segmentKey;
    });
  }

  goPrevLeg() {
    let currentIndex = this.uniqTrains.findIndex(train => train.segmentKey === this.current);
    if (currentIndex > 0) {
      this.current = this.uniqTrains[currentIndex - 1].segmentKey;
    }
  }

  goNextLeg() {
    let currentIndex = this.uniqTrains.findIndex(train => train.segmentKey === this.current);
    if (currentIndex < this.uniqTrains.length) {
      this.current = this.uniqTrains[currentIndex + 1].segmentKey;
    }
  }

  selectProp(offer) {
    this.$emit('select-prop', offer);
  }

  updateCurrentKey() {
    if (this.offer.journeys[0].trains) {
      this.current = this.offer.journeys[0].trains[0].segmentKey;
    }
  }

  onReceive() {
    this.calcTotalPrice();
  }

  created() {
    this.updateCurrentKey();
    EventBus.$on('price-proposal-update', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('price-proposal-update', this.onReceive);
  }

  @Watch('showDetails')
  onShowDetailsChange() {
    this.showStops = false;
  }
}
