export const DeepSearch = (theObject, property) => {
  let result = false;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = !!DeepSearch(theObject[i], property);
    }
  } else {
    for (let prop in theObject) {
        if (prop === property) {
          if (theObject[prop] !== null) {
            return theObject;
          }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          result = !!DeepSearch(theObject[prop], property);
        }
    }
  }

  return result;
};