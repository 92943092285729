
import PaymentMethods from '@/modules/settings/payment-methods/PaymentMethods.vue';
import PaymentMethodsList from './payment-methods/payment-methods.route';
import PaymentMethodsConfiguration from './payment-methods/payment-methods-configuration.route';
import PaymentAirConfiguration from './payment-methods/air-configuration.route';
import PaymentTrainConfiguration from './payment-methods/train-configuration.route';
import PaymentCarConfiguration from './payment-methods/car-configuration.route';
import PaymentAccommodationConfiguration from './payment-methods/accommodation-configuration.route';
import { translate } from '@/i18n';

export default {
  path: 'payment-methods-configurations',
  component: PaymentMethods,
  displayName: 'settings-sidebar.payment-methods',
  meta: {
    permission: 'ReadPaymentMethodConfiguration',
    parentName: 'payment-methods-configurations',
    introClass: 'intro-settings',
    icon: 'payments',
  },
  children: [
    PaymentMethodsList,
    PaymentMethodsConfiguration,
    PaymentAirConfiguration,
    PaymentTrainConfiguration,
    PaymentCarConfiguration,
    PaymentAccommodationConfiguration,
  ],
};
